import React, { useState } from 'react'
import { TabTitle } from './tab-title'
import { Profile } from './profile'
import { Team } from './team'
import { ClientPermission, useUser } from '~/base'
import { Uploads } from './uploads'
import { ViewStateType } from '~/state/use-drawer-state'
import { DrawerState } from '~/components'
import { useAppState } from '~/state'

enum AccountViewStateType {
  Account = 'account',
  Team = 'team',
  Uploads = 'uploads',
}

export const Account = () => {
  const { drawer } = useAppState()
  const user = useUser()
  const [accountView, setAccountView] = useState<AccountViewStateType>(AccountViewStateType.Account)
  const canSeeTeam = user.hasPermission(ClientPermission.TeamList)

  return (
    <>
      {canSeeTeam && (
        <div className='account'>
          <ul className='account-tabs'>
            <li
              className={`account-tabs-item ${accountView === AccountViewStateType.Account ? 'active' : ''}`}
              onClick={() => setAccountView(AccountViewStateType.Account)}
            >
              Account
            </li>
            <li
              className={`account-tabs-item ${accountView === AccountViewStateType.Team ? 'active' : ''}`}
              onClick={() => setAccountView(AccountViewStateType.Team)}
            >
              Team
            </li>
            <li
              className={`account-tabs-item ${accountView === AccountViewStateType.Uploads ? 'active' : ''}`}
              onClick={() => setAccountView(AccountViewStateType.Uploads)}
            >
              Uploads
            </li>
          </ul>
        </div>
      )}
      <div
        className='account-close'
        onClick={() => {
          drawer.setRightView(ViewStateType.Activity)
          drawer.set(DrawerState.Normal, DrawerState.Normal)
        }}
      >
        <span className='fa-regular fa-close'></span>
      </div>
      <TabTitle title={accountView} />
      {accountView === AccountViewStateType.Account && <Profile />}
      {accountView === AccountViewStateType.Team && <Team />}
      {accountView === AccountViewStateType.Uploads && <Uploads />}
    </>
  )
}
