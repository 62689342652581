import React from 'react'
import { useUser } from '~/base'
import {
  ActivityItemForOrgData,
  ActivityItemForOrgImagesUploadedT,
  ActivityItemForOrgOrganizationCreatedT,
  ActivityItemForOrgSitesCreatedT,
  ActivityItemForOrgSitesUpdatedT,
  ActivityItemForOrgUserJoinedOrgT,
  OrgActivityItem,
} from '~/models'
import { getImageURL } from '../helpers'
import { ActivityCard } from './activity-card'

interface ActivityCardOrgProps extends OrgActivityItem {
  goToSite: (siteID: string) => void
  goToAsset: (siteID: string, assetID: string) => void
}

export const ActivityCardOrg = (props: ActivityCardOrgProps) => {
  const user = useUser()

  if (props.data.type === ActivityItemForOrgData.ActivityItemForOrgOrganizationCreated) {
    return <ActivityCardOrgCreated {...props} val={props.data.value as ActivityItemForOrgOrganizationCreatedT} />
  }

  if (props.data.type === ActivityItemForOrgData.ActivityItemForOrgUserJoinedOrg) {
    return <ActivityCardOrgUserJoinedWorkspace {...props} val={props.data.value as ActivityItemForOrgUserJoinedOrgT} />
  }

  if (props.data.type === ActivityItemForOrgData.ActivityItemForOrgSitesCreated) {
    return <ActivityCardOrgSitesCreated {...props} val={props.data.value as ActivityItemForOrgSitesCreatedT} />
  }

  if (props.data.type === ActivityItemForOrgData.ActivityItemForOrgSitesUpdated) {
    return <ActivityCardOrgSitesUpdated {...props} val={props.data.value as ActivityItemForOrgSitesUpdatedT} />
  }

  if (props.data.type === ActivityItemForOrgData.ActivityItemForOrgImagesUploaded) {
    const data = props.data.value as ActivityItemForOrgImagesUploadedT
    return (
      <ActivityCard time={data.time} icon='image'>
        <p>{data.numImages} images were uploaded</p>
        <div className='activity-card-img-grid'>
          {data.hashes.map((hash, i) => (
            <img
              src={getImageURL({ hash }, user.org.id, i)}
              key={hash + i}
              className='activity-card-img-grid-item'
              width={48}
              height={48}
            />
          ))}
        </div>
      </ActivityCard>
    )
  }

  return null
}

function ActivityCardOrgCreated(props: ActivityCardOrgProps & { val: ActivityItemForOrgOrganizationCreatedT }) {
  return (
    <ActivityCard time={props.val.time} icon='workspaces'>
      <p>The workspace {props.val.orgName} was created</p>
    </ActivityCard>
  )
}

function ActivityCardOrgUserJoinedWorkspace(props: ActivityCardOrgProps & { val: ActivityItemForOrgUserJoinedOrgT }) {
  const data = props.val
  return (
    <ActivityCard
      icon='person'
      items={data.userNames.map((x, idx) => ({
        id: data.userIDs[idx],
        name: x,
        time: data.times[idx],
        userID: '',
        userName: '',
      }))}
    >
      <p>
        {data.userNames.length === 1
          ? data.userNames[0]
          : `${data.userNames[data.userNames.length - 1]} and ${data.userNames.length - 1} ${
              data.userNames.length === 2 ? 'other' : 'others'
            }`}{' '}
        joined the workspace
      </p>
    </ActivityCard>
  )
}

function ActivityCardOrgSitesCreated(props: ActivityCardOrgProps & { val: ActivityItemForOrgSitesCreatedT }) {
  const data = props.val
  const last = data.items[data.items.length - 1]
  return (
    <ActivityCard
      icon='business'
      items={data.items.map((x) => ({
        id: x.siteID,
        name: x.siteName,
        time: x.time,
        userID: x.userID,
        userName: x.userName,
      }))}
      link='site'
    >
      <p>
        <span onClick={() => props.goToSite(last.siteID)} className='activity-card-link'>
          {last.siteName}
        </span>
        {data.items.length === 1 ? ' was created' : ` and ${data.items.length - 1} other sites were created`}
      </p>
    </ActivityCard>
  )
}

function ActivityCardOrgSitesUpdated(props: ActivityCardOrgProps & { val: ActivityItemForOrgSitesUpdatedT }) {
  const data = props.val
  const last = data.items[data.items.length - 1]
  return (
    <ActivityCard
      icon='business'
      items={data.items.map((x) => ({
        id: x.siteID,
        name: x.siteName,
        time: x.time,
        userID: x.userID,
        userName: x.userName,
      }))}
      link='site'
    >
      <p>
        <span onClick={() => props.goToSite(last.siteID)} className='activity-card-link'>
          {last.siteName}
        </span>
        {data.items.length === 1 ? ' was updated' : ` and ${data.items.length - 1} other sites were updated`}
      </p>
    </ActivityCard>
  )
}
