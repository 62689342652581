// Returns a new array of points representing the convex hull of
// the given set of points. The convex hull excludes collinear points.
// This algorithm runs in O(n log n) time.
export function makeHull<P extends Cesium.Cartographic>(points: Readonly<Array<P>>): Array<P> {
  let newPoints: Array<P> = points.slice()
  newPoints.sort(POINT_COMPARATOR)
  return makeHullPresorted(newPoints)
}

// Returns the convex hull, assuming that each points[i] <= points[i + 1]. Runs in O(n) time.
function makeHullPresorted<P extends Cesium.Cartographic>(points: Readonly<Array<P>>): Array<P> {
  if (points.length <= 1) return points.slice()

  // Andrew's monotone chain algorithm. Positive y coordinates correspond to "up"
  // as per the mathematical convention, instead of "down" as per the computer
  // graphics convention. This doesn't affect the correctness of the result.

  let upperHull: Array<P> = []
  for (let i = 0; i < points.length; i++) {
    const p: P = points[i]
    while (upperHull.length >= 2) {
      const q: P = upperHull[upperHull.length - 1]
      const r: P = upperHull[upperHull.length - 2]
      if ((q.longitude - r.longitude) * (p.latitude - r.latitude) >= (q.latitude - r.latitude) * (p.longitude - r.longitude)) upperHull.pop()
      else break
    }
    upperHull.push(p)
  }
  upperHull.pop()

  let lowerHull: Array<P> = []
  for (let i = points.length - 1; i >= 0; i--) {
    const p: P = points[i]
    while (lowerHull.length >= 2) {
      const q: P = lowerHull[lowerHull.length - 1]
      const r: P = lowerHull[lowerHull.length - 2]
      if ((q.longitude - r.longitude) * (p.latitude - r.latitude) >= (q.latitude - r.latitude) * (p.longitude - r.longitude)) lowerHull.pop()
      else break
    }
    lowerHull.push(p)
  }
  lowerHull.pop()

  if (
    upperHull.length == 1 &&
    lowerHull.length == 1 &&
    upperHull[0].longitude == lowerHull[0].longitude &&
    upperHull[0].latitude == lowerHull[0].latitude
  )
    return upperHull
  else return upperHull.concat(lowerHull)
}

function POINT_COMPARATOR(a: Cesium.Cartographic, b: Cesium.Cartographic): number {
  if (a.longitude < b.longitude) return -1
  else if (a.longitude > b.longitude) return +1
  else if (a.latitude < b.latitude) return -1
  else if (a.latitude > b.latitude) return +1
  else return 0
}
