import React, { useState } from 'react'
import { string, object } from '~/components'
import { AuthInput, useForm, AuthButton, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import { ForgotPasswordMutation, ForgotPasswordRequestT } from '~/models/gql-types'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import mutation from './mutation-forgot-password.gql'

export const AuthResetPassword = () => {
  const [updateComplete, setUpdateComplete] = useState(false)

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    ForgotPasswordMutation,
    ForgotPasswordRequestT
  >({
    initialValues: {
      email: '',
    },
    validationSchema: object().shape({
      email: string().email('Invalid email address').required('Required'),
    }),
    mutation,
    onSuccess: (result: ForgotPasswordMutation) => {
      if (result.forgotPassword.success) {
        getTracking().event({
          category: 'Form',
          action: `User reset their password`,
          label: 'Auth',
        })
        setUpdateComplete(true)
      }
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Reset Your Password' />
      {!updateComplete && (
        <>
          <div className='subheader-dark mt-5 mb-5'>Reset Your Password</div>
          {error && <AuthError className='alert alert-warning mb-4' error={error} />}
          <form autoComplete='off' className='auth-form-container mb-3' onSubmit={handleSubmit}>
            <p className='auth-description-text mb-4'>
              Enter your email address below and we'll send you a link to reset your password.
            </p>
            <label htmlFor='email'>Email</label>
            <AuthInput
              id='email'
              label='Email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
            />
            <AuthButton disabled={isSubmitting}>{isSubmitting ? 'Loading...' : 'Submit'}</AuthButton>
          </form>
          <AuthLinks>
            <AuthLink to='/signin' className='mb-3'>
              Back to Sign In
            </AuthLink>
          </AuthLinks>
        </>
      )}
      {updateComplete && (
        <>
          <div className='subheader-dark mt-5 mb-5'>Password Reset</div>
          <p className='auth-description-text mb-4'>
            A confirmation email will be sent to {values.email}. <br />
            Follow the instructions in the email to complete the password reset.
          </p>
          <AuthLinks>
            <AuthLink to='/signin' className='mb-3'>
              Back to Sign In
            </AuthLink>
          </AuthLinks>
        </>
      )}
    </AuthLayout>
  )
}
