import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useUser } from '~/base'
import { getDistance } from '~/components/measure'
import {
  AdminMeasurementInputT,
  AdminMeasurementSetCreateOrUpdateMutation,
  AdminMeasurementSetCreateOrUpdateMutationVariables,
  AdminMeasurementSetsQuery,
  AdminMeasurementSetsQueryVariables,
  AdminMeasurementSetType,
  SiteQueryAsset,
} from '~/models'
import { ProjectionType, useAppState, useRefState } from '~/state'
import { intersectLinePolygon } from './intersection'
import MEASUREMENT_SETS_QUERY from './query-admin-measurement-sets.gql'
import MEASUREMENT_SETS_SAVE_MUTATION from './mutation-admin-measurement-sets-create-or-update.gql'
import { shortDate, useMutation, useToasts } from '~/components'

interface LinesByAssetID {
  [assetID: string]: AdminMeasurementInputT
}

enum PointType {
  Length,
  Width,
}

interface PointMapItem {
  assetID: string
  type: PointType
  index: number
  internalIndex: number
  polyLineIndex: number
}

interface PointMap {
  [key: string]: PointMapItem
}

export const LengthAndWidthMeasurements = () => {
  const { map, timeline, site, issues } = useAppState()
  const [linesByAsset, setLinesByAsset] = useRefState<LinesByAssetID>()
  const user = useUser()
  const activeSurvey = timeline.activeSurvey
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>()
  const [setID, setSetID] = useState<string>()
  const [saving, setSaving] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [assets] = useState([...site.site.assets].sort((a, b) => (a.number < b.number ? 1 : -1)))
  const toasts = useToasts()
  const measurementsQuery = useQuery<AdminMeasurementSetsQuery, AdminMeasurementSetsQueryVariables>(
    MEASUREMENT_SETS_QUERY,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'network-only',
    }
  )
  const [executeSave] = useMutation<
    AdminMeasurementSetCreateOrUpdateMutation,
    AdminMeasurementSetCreateOrUpdateMutationVariables
  >(MEASUREMENT_SETS_SAVE_MUTATION)

  const generateCSV = () => {
    const csvLines = []
    for (const assetID in linesByAsset.current) {
      const asset = site.site.assets.find((s) => s.id === assetID)
      const lines = linesByAsset.current[assetID]
      let surveyName = shortDate(activeSurvey.startDate)
      if (activeSurvey.endDate != activeSurvey.startDate) {
        surveyName = surveyName + ' - ' + shortDate(activeSurvey.endDate)
      }

      csvLines.push(
        [
          site.site.name,
          site.site.number,
          site.site.id,
          activeSurvey.id,
          surveyName,
          asset?.name || '-',
          asset?.number || '-',
          asset?.id || '-',
          new line(lines.points[0], lines.points[1]).distance(),
          new line(lines.points[2], lines.points[3]).distance(),
          lines.points[0].latitude,
          lines.points[0].longitude,
          lines.points[1].latitude,
          lines.points[1].longitude,
          lines.points[2].latitude,
          lines.points[2].longitude,
          lines.points[3].latitude,
          lines.points[3].longitude,
        ]
          .map((x) => '"' + x + '"')
          .join(',')
      )
    }

    const csv =
      'Site,Site Number,Site ID,Survey ID, Survey Date,Asset,Asset Number,Asset ID,Length,Width,Length Latitude 1,Length Longitude 1,Length Latitude 2,Length Longitude 2,Width Latitude 1,Width Longitude 1,Width Latitude 2,Width Longitude 2\n' +
      csvLines.join('\n')

    const link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
    link.setAttribute(
      'download',
      `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-${site.site.name}-lengths-and-widths.csv`
    )
    document.body.appendChild(link)
    document.getElementById('download-csv').click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      const setAsset = (a: SiteQueryAsset) => {
        setSelectedAsset(a)

        const points: Cesium.Cartographic[] = []

        for (const boundary of a.boundaries) {
          for (const p of boundary.points) {
            points.push(
              Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
            )
          }
        }

        if (points.length > 0) {
          const destinationCarto = Cesium.Rectangle.center(Cesium.Rectangle.fromCartographicArray(points))
          const samplePositions = [destinationCarto]
          Cesium.sampleTerrain(map.viewer.terrainProvider, 11, samplePositions).then(() => {
            samplePositions[0].height += 30

            map.viewer.camera.flyTo({
              destination: Cesium.Cartographic.toCartesian(samplePositions[0]),
              duration: 0.75,
            })
          })
        }
      }

      const key = e.key.toLocaleLowerCase()
      if (key === 'n') {
        if (selectedAsset) {
          const assetIndex = assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === assets.length - 1) {
            setAsset(assets[0])
          } else {
            setAsset(assets[assetIndex + 1])
          }
        } else {
          if (assets.length > 0) {
            setAsset(assets[0])
          }
        }
      } else if (key === 'p') {
        if (selectedAsset) {
          const assetIndex = assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === 0) {
            setAsset(assets[assets.length - 1])
          } else {
            setAsset(assets[assetIndex - 1])
          }
        } else {
          if (assets.length > 0) {
            setAsset(assets[0])
          }
        }
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [selectedAsset, assets])

  useEffect(() => {
    if (!site.site || map.projectionType === ProjectionType.Projection3D || !activeSurvey) {
      return
    }

    issues.setShow(false)

    measurementsQuery
      .refetch({
        input: {
          orgID: user.org.id,
          siteIDs: [site.site.id],
        },
      })
      .then((res) => {
        const set = res.data.adminMeasurementSets.find(
          (x) =>
            x.siteID === site.site.id &&
            x.type === AdminMeasurementSetType.LengthWidth &&
            x.surveyID === timeline.activeSurvey.id
        )
        if (set) {
          setSetID(set.id)
        } else {
          setSetID(undefined)
        }

        const allLines: LinesByAssetID = {}

        for (const asset of site.site.assets) {
          if (set) {
            const measurements = set.measurements.find((x) => x.assetID === asset.id)
            if (measurements) {
              allLines[asset.id] = { ...measurements, points: [...measurements.points], measurements: [] }
              continue
            }
          }

          if (asset.boundaries.length === 0) {
            continue
          }

          for (const b of asset.boundaries) {
            const lines = getPolygonLengthAndWidth2(b.points)
            if (lines.length === 0) {
              let lng = 0
              let lat = 0
              for (const p of b.points) {
                lng += p.longitude
                lat += p.latitude
              }
              lng /= b.points.length
              lat /= b.points.length

              allLines[asset.id] = {
                assetID: asset.id,
                id: '',
                measurements: [],
                points: [
                  {
                    longitude: lng - 0.00001,
                    latitude: lat,
                    altitude: 0,
                  },
                  {
                    longitude: lng + 0.00001,
                    latitude: lat,
                    altitude: 0,
                  },
                  {
                    longitude: lng,
                    latitude: lat - 0.00001,
                    altitude: 0,
                  },
                  {
                    longitude: lng,
                    latitude: lat + 0.00001,
                    altitude: 0,
                  },
                ],
              }
            } else {
              allLines[asset.id] = {
                assetID: asset.id,
                id: '',
                measurements: [],
                points: lines.map((l) => ({ ...l, altitude: 0 })),
              }
            }
          }
        }

        setLinesByAsset(allLines)
      })

    setSaved(false)

    return () => {
      issues.setShow(true)
    }
  }, [site.site?.id, map.projectionType, site.site?.boundaries.length, saved])

  useEffect(() => {
    if (!linesByAsset.current) {
      return
    }

    const pointMap: PointMap = {}
    const points = new Cesium.PointPrimitiveCollection()
    const polyLines = new Cesium.PolylineCollection()
    let polyLineIndex = 0
    let pointIndex = 0

    map.viewer.scene.primitives.add(points)
    map.viewer.scene.primitives.add(polyLines)

    for (const assetID of Object.keys(linesByAsset.current)) {
      const lines = linesByAsset.current[assetID]

      const l1Positions = Cesium.Cartesian3.fromDegreesArray([
        lines.points[0].longitude,
        lines.points[0].latitude,
        lines.points[1].longitude,
        lines.points[1].latitude,
      ])

      const l2Positions = Cesium.Cartesian3.fromDegreesArray([
        lines.points[2].longitude,
        lines.points[2].latitude,
        lines.points[3].longitude,
        lines.points[3].latitude,
      ])

      polyLines.add({
        id: 'length-line-' + assetID + '-' + polyLineIndex++,
        show: true,
        positions: l1Positions,
        width: 5,
        material: new Cesium.Material({
          fabric: {
            type: 'Color',
            uniforms: {
              color: Cesium.Color.RED,
            },
          },
        }),
      })

      polyLines.add({
        id: 'width-line-' + assetID + '-' + polyLineIndex++,
        show: true,
        positions: l2Positions,
        width: 3,
        material: new Cesium.Material({
          fabric: {
            type: 'Color',
            uniforms: {
              color: Cesium.Color.BLUE,
            },
          },
        }),
      })

      const p1Id = 'length-point-0-' + assetID + '-' + pointIndex
      pointMap[p1Id] = {
        assetID: assetID,
        index: pointIndex++,
        internalIndex: 0,
        type: PointType.Length,
        polyLineIndex: polyLineIndex - 2,
      }
      const p2Id = 'length-point-1-' + assetID + '-' + pointIndex
      pointMap[p2Id] = {
        assetID: assetID,
        index: pointIndex++,
        internalIndex: 1,
        type: PointType.Length,
        polyLineIndex: polyLineIndex - 2,
      }
      const p3Id = 'width-point-0-' + assetID + '-' + pointIndex
      pointMap[p3Id] = {
        assetID: assetID,
        index: pointIndex++,
        internalIndex: 0,
        type: PointType.Width,
        polyLineIndex: polyLineIndex - 1,
      }
      const p4Id = 'width-point-1-' + assetID + '-' + pointIndex
      pointMap[p4Id] = {
        assetID: assetID,
        index: pointIndex++,
        internalIndex: 1,
        type: PointType.Width,
        polyLineIndex: polyLineIndex - 1,
      }

      points.add({
        id: p1Id,
        position: l1Positions[0],
        color: Cesium.Color.RED,
        pixelSize: 16,
      })

      points.add({
        id: p2Id,
        position: l1Positions[1],
        color: Cesium.Color.RED,
        pixelSize: 16,
      })

      points.add({
        id: p3Id,
        position: l2Positions[0],
        color: Cesium.Color.BLUE,
        pixelSize: 16,
      })

      points.add({
        id: p4Id,
        position: l2Positions[1],
        color: Cesium.Color.BLUE,
        pixelSize: 16,
      })
    }
    const mouseHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    let draggingPoint: PointMapItem

    mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
        return
      }
      const picked = map.viewer.scene.pick(click.position)
      const ellipsoid = map.viewer.scene.globe.ellipsoid
      const cartesian = map.viewer.camera.pickEllipsoid(click.position, ellipsoid)

      if (!cartesian || !picked) {
        // Position on globe was not clicked.
        return
      }

      if (typeof picked.id === 'string') {
        const mappedPoint = pointMap[picked.id]
        if (mappedPoint) {
          map.viewer.scene.screenSpaceCameraController.enableInputs = false
          draggingPoint = mappedPoint
        } else {
          draggingPoint = undefined
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

    mouseHandler.setInputAction(() => {
      draggingPoint = undefined
      map.viewer.scene.screenSpaceCameraController.enableInputs = true
    }, Cesium.ScreenSpaceEventType.LEFT_UP)

    mouseHandler.setInputAction((move: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
        return
      }

      const picked = map.viewer.scene.pick(move.endPosition)
      const ellipsoid = map.viewer.scene.globe.ellipsoid
      const cartesian = map.viewer.camera.pickEllipsoid(move.endPosition, ellipsoid)

      if (!cartesian) {
        // Position on globe was not clicked.
        return
      }

      const cartographic = ellipsoid.cartesianToCartographic(cartesian)

      if (picked && typeof picked.id === 'string') {
        const mappedPoint = pointMap[picked.id]
        if (mappedPoint) {
          document.body.style.cursor = 'move'
        } else {
          document.body.style.cursor = 'default'
        }
      } else {
        document.body.style.cursor = 'default'
      }

      if (draggingPoint) {
        points.get(draggingPoint.index).position = cartesian
        const oldPositions = [...polyLines.get(draggingPoint.polyLineIndex).positions]
        oldPositions[draggingPoint.internalIndex] = cartesian.clone()
        polyLines.get(draggingPoint.polyLineIndex).positions = oldPositions
        if (draggingPoint.type === PointType.Length) {
          linesByAsset.current[draggingPoint.assetID].points[draggingPoint.internalIndex] = {
            latitude: Cesium.Math.toDegrees(cartographic.latitude),
            longitude: Cesium.Math.toDegrees(cartographic.longitude),
            altitude: 0,
          }
        } else {
          linesByAsset.current[draggingPoint.assetID].points[2 + draggingPoint.internalIndex] = {
            latitude: Cesium.Math.toDegrees(cartographic.latitude),
            longitude: Cesium.Math.toDegrees(cartographic.longitude),
            altitude: 0,
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      map.viewer.scene.primitives.remove(points)
      map.viewer.scene.primitives.remove(polyLines)
      mouseHandler.destroy()
    }
  }, [linesByAsset.current])

  const save = () => {
    setSaving(true)

    const measurements = []
    for (const assetID in linesByAsset.current) {
      const lines = linesByAsset.current[assetID]
      lines.measurements = [
        new line(lines.points[0], lines.points[1]).distance(),
        new line(lines.points[2], lines.points[3]).distance(),
      ]

      measurements.push(lines)
    }

    executeSave({
      variables: {
        input: {
          id: setID || '',
          measurements,
          orgID: user.org.id,
          siteID: site.site.id,
          surveyID: timeline.activeSurvey.id,
          type: AdminMeasurementSetType.LengthWidth,
        },
      },
    })
      .then(() => {
        setSaved(true)
        toasts.addTopLeft('Measurements saved')
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const assetIndex = assets.findIndex((x) => x.id === selectedAsset?.id)

  return (
    <>
      <button className='btn btn-sm' onClick={generateCSV}>
        Export Length &amp; Width CSV
      </button>
      <button
        className='btn btn-sm'
        disabled={saving}
        onClick={() => {
          save()
        }}
      >
        {saving ? 'Saving' : 'Save'}
      </button>
      {/* <FileSelector
        filesSelected={(files) => {
          if (files.length === 0) {
            return
          }

          const file = files[0]
          var reader = new FileReader()
          reader.onload = function () {
            const rawText = reader.result as string
            const lines = rawText.split('\n')
            lines.splice(0, 1)

            const allLines: LinesByAssetID = {}
            for (const l of lines) {
              const items = l.split(',').map((x) => x.split('"').join(''))

              const assetID = items[5]
              const lengthLat1 = parseFloat(items[8])
              const lengthLng1 = parseFloat(items[9])
              const lengthLat2 = parseFloat(items[10])
              const lengthLng2 = parseFloat(items[11])
              const widthLat1 = parseFloat(items[12])
              const widthLng1 = parseFloat(items[13])
              const widthLat2 = parseFloat(items[14])
              const widthLng2 = parseFloat(items[15])

              allLines[assetID] = [
                {
                  longitude: lengthLng1,
                  latitude: lengthLat1,
                },
                {
                  longitude: lengthLng2,
                  latitude: lengthLat2,
                },
                {
                  longitude: widthLng1,
                  latitude: widthLat1,
                },
                {
                  longitude: widthLng2,
                  latitude: widthLat2,
                },
              ]
            }

            setLinesByAsset(allLines)
          }
          reader.readAsBinaryString(file)
        }}
        singleFile
        text='Import Length & Width CSV'
        className='btn btn-sm'
      /> */}
      <br />
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Current Asset: <b>{selectedAsset?.name || 'None'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Asset number{' '}
        <b>
          {assetIndex === -1 ? '-' : assetIndex + 1} of {assets.length}
        </b>
      </div>
    </>
  )
}

interface point {
  latitude: number
  longitude: number
}

export class line {
  public p: point
  public q: point
  public a: number
  public b: number
  public c: number

  constructor(p: point, q: point) {
    this.p = p
    this.q = q
    this.updateEquation()
  }

  public updateEquation() {
    this.a = this.p.latitude - this.q.latitude
    this.b = this.q.longitude - this.p.longitude
    this.c = this.p.longitude * this.q.latitude - this.q.longitude * this.p.latitude
  }

  public clone() {
    return new line(
      {
        latitude: this.p.latitude,
        longitude: this.p.longitude,
      },
      {
        latitude: this.q.latitude,
        longitude: this.q.longitude,
      }
    )
  }

  public rotate(angleInDegrees: number): line {
    const angleInRadians = Cesium.Math.toRadians(angleInDegrees)

    const centerX = (this.q.longitude + this.p.longitude) / 2
    const centerY = (this.q.latitude + this.p.latitude) / 2

    const dx1 = this.p.longitude - centerX
    const dx2 = this.q.longitude - centerX

    const dy1 = this.p.latitude - centerY
    const dy2 = this.q.latitude - centerY

    const l = new line(
      {
        longitude: centerX + Math.cos(angleInRadians) * dx1 - Math.sin(angleInRadians) * dy1,
        latitude: centerY + Math.sin(angleInRadians) * dx1 + Math.cos(angleInRadians) * dy1,
      },
      {
        longitude: centerX + Math.cos(angleInRadians) * dx2 - Math.sin(angleInRadians) * dy2,
        latitude: centerY + Math.sin(angleInRadians) * dx2 + Math.cos(angleInRadians) * dy2,
      }
    )

    return l
  }

  public perpendicular(a: number = 90) {
    const centerX = (this.q.longitude + this.p.longitude) / 2
    const centerY = (this.q.latitude + this.p.latitude) / 2
    const p1 = Cesium.Cartesian3.fromDegrees(this.p.longitude, this.p.latitude, 0)
    const p2 = Cesium.Cartesian3.fromDegrees(this.q.longitude, this.q.latitude, 0)
    const c1 = Cesium.Cartesian3.fromDegrees(centerX, centerY, 0)

    const t1 = getRotatedPosition(p1, c1, Cesium.Math.toRadians(a))
    const t2 = getRotatedPosition(p2, c1, Cesium.Math.toRadians(a))
    const d1 = Cesium.Cartographic.fromCartesian(t1)
    const d2 = Cesium.Cartographic.fromCartesian(t2)

    const l = new line(
      {
        longitude: Cesium.Math.toDegrees(d1.longitude),
        latitude: Cesium.Math.toDegrees(d1.latitude),
      },
      {
        longitude: Cesium.Math.toDegrees(d2.longitude),
        latitude: Cesium.Math.toDegrees(d2.latitude),
      }
    )

    return l
  }

  public extend(): line {
    const factor = 0.001

    if (this.b === 0) {
      const l = new line(
        {
          longitude: this.p.longitude,
          latitude: this.p.latitude - factor,
        },
        {
          longitude: this.q.longitude,
          latitude: this.q.latitude + factor,
        }
      )

      return l
    }

    const l = new line(
      {
        longitude: this.p.longitude - factor,
        latitude: (-(this.a * (this.p.longitude - factor)) - this.c) / this.b,
      },
      {
        longitude: this.q.longitude + factor,
        latitude: (-(this.a * (this.q.longitude + factor)) - this.c) / this.b,
      }
    )

    return l
  }

  public shrink(): line {
    const factor = -0.02
    const c = -this.c / this.b
    const m = -this.a / this.b

    const l = new line(
      {
        longitude: this.p.longitude - factor,
        latitude: m * (this.p.longitude - factor) + c,
      },
      {
        longitude: this.q.longitude + factor,
        latitude: m * (this.q.longitude + factor) + c,
      }
    )

    return l
  }

  public intersection(other: line): point | undefined {
    const xNum = this.b * other.c - other.b * this.c
    const xDen = this.a * other.b - other.a * this.b
    const yNum = this.c * other.a - other.c * this.a
    const yDen = this.a * other.b - other.a * this.b

    if (xDen == 0 || yDen == 0) {
      return undefined
    }

    return {
      longitude: xNum / xDen,
      latitude: yNum / yDen,
    }
  }

  public slope() {
    const b = this.q.longitude - this.p.longitude
    if (Cesium.Math.equalsEpsilon(b, 0, Cesium.Math.EPSILON10)) {
      return 1000000
    }
    return (this.q.latitude - this.p.latitude) / b
  }

  distance() {
    return getDistance(
      new Cesium.EllipsoidGeodesic(),
      Cesium.Cartesian3.fromDegrees(this.p.longitude, this.p.latitude),
      Cesium.Cartesian3.fromDegrees(this.q.longitude, this.q.latitude)
    )
  }
}

function getLinesFromPolygon(polygonExcludingLastPoint: point[]): line[] {
  const lines: line[] = []
  for (let i = 1; i < polygonExcludingLastPoint.length; i++) {
    const p1 = polygonExcludingLastPoint[i]
    const p2 = polygonExcludingLastPoint[i - 1]
    lines.push(new line(p1, p2))

    if (i == polygonExcludingLastPoint.length - 1) {
      lines.push(new line(p1, polygonExcludingLastPoint[0]))
    }
  }

  return lines
}

// function getCenterOfPolygon(polygonExcludingLastPoint: point[]): point {
//   const center: point = {
//     latitude: 0,
//     longitude: 0,
//   }
//   for (let i = 0; i < polygonExcludingLastPoint.length; i++) {
//     const p = polygonExcludingLastPoint[i]
//     center.latitude += p.latitude
//     center.longitude += p.longitude
//   }

//   const count = polygonExcludingLastPoint.length
//   center.latitude /= count
//   center.longitude /= count

//   return center
// }

// function getPolygonLengthAndWidth(polygonExcludingLastPoint: point[]): point[] {
//   // 1. Get the line segments which form the polygon.
//   const lines = getLinesFromPolygon(polygonExcludingLastPoint)

//   // 2. Get the center of the polygon.
//   const center = getCenterOfPolygon(polygonExcludingLastPoint)

//   // 3. Initialise the search lines.
//   const searchLine1 = new line(
//     { longitude: center.longitude - 0.2, latitude: center.latitude },
//     { longitude: center.longitude + 0.2, latitude: center.latitude }
//   )

//   const searchLine2 = new line(
//     { longitude: center.longitude, latitude: center.latitude - 0.2 },
//     { longitude: center.longitude, latitude: center.latitude + 0.2 }
//   )

//   // 4. Find the maximum length and width.
//   let points = [...polygonExcludingLastPoint, polygonExcludingLastPoint[0]].map((x) => ({
//     x: x.longitude,
//     y: x.latitude,
//   }))
//   let maxD = 0
//   let maxD1 = 0
//   let maxD2 = 0
//   let bestIntersection1
//   let bestIntersection2
//   for (let rotationAngle = 0; rotationAngle < 90; rotationAngle += 0.1) {
//     const l1 = searchLine1.rotate(rotationAngle)
//     const l2 = searchLine2.rotate(rotationAngle)

//     const intersection1 = intersectLinePolygon(
//       {
//         x: l1.p.longitude,
//         y: l1.p.latitude,
//       },
//       {
//         x: l1.q.longitude,
//         y: l1.q.latitude,
//       },
//       points
//     )

//     const intersection2 = intersectLinePolygon(
//       {
//         x: l2.p.longitude,
//         y: l2.p.latitude,
//       },
//       {
//         x: l2.q.longitude,
//         y: l2.q.latitude,
//       },
//       points
//     )

//     // Both must intersect at least twice.
//     if (intersection1.points.length <= 1 && intersection2.points.length <= 1) {
//       continue
//     }

//     let ignore = false
//     for (const p of points) {
//       if (
//         isSamePoint(p, intersection1.points[0]) ||
//         isSamePoint(p, intersection1.points[1]) ||
//         isSamePoint(p, intersection2.points[0]) ||
//         isSamePoint(p, intersection2.points[1])
//       ) {
//         ignore = true
//         break
//       }
//     }

//     if (ignore) {
//       continue
//     }

//     const d1 = Math.sqrt(
//       Math.pow(intersection1.points[0].x - intersection1.points[1].x, 2) +
//         Math.pow(intersection1.points[0].y - intersection1.points[1].y, 2)
//     )
//     const d2 = Math.sqrt(
//       Math.pow(intersection2.points[0].x - intersection2.points[1].x, 2) +
//         Math.pow(intersection2.points[0].y - intersection2.points[1].y, 2)
//     )

//     if (d1 + d2 > maxD) {
//       maxD1 = d1
//       maxD2 = d2
//       maxD = d1 + d2
//       bestIntersection1 = intersection1
//       bestIntersection2 = intersection2
//     }
//   }

//   if (!bestIntersection1) {
//     return []
//   }

//   return [
//     {
//       longitude: bestIntersection1.points[0].x,
//       latitude: bestIntersection1.points[0].y,
//     },
//     {
//       longitude: bestIntersection1.points[1].x,
//       latitude: bestIntersection1.points[1].y,
//     },
//     {
//       longitude: bestIntersection2.points[0].x,
//       latitude: bestIntersection2.points[0].y,
//     },
//     {
//       longitude: bestIntersection2.points[1].x,
//       latitude: bestIntersection2.points[1].y,
//     },
//   ]

//   // let maxDistance = 0
//   // let bestAngle = 0
//   // for (let rotationAngle = 0; rotationAngle < 90; rotationAngle++) {
//   //   const l1 = searchLine1.rotate(rotationAngle)
//   //   const l2 = searchLine2.rotate(rotationAngle)

//   //   for (const line of lines) {
//   //     const intersection1 = l1.intersection(line)
//   //     const intersection2 = l2.intersection(line)

//   //     if (intersection1 === undefined || intersection2 === undefined) {
//   //       continue
//   //     }

//   //     const d1 = Math.sqrt(
//   //       Math.pow(intersection1.longitude - center.longitude, 2) + Math.pow(intersection1.latitude - center.latitude, 2)
//   //     )
//   //     const d2 = Math.sqrt(
//   //       Math.pow(intersection2.longitude - center.longitude, 2) + Math.pow(intersection2.latitude - center.latitude, 2)
//   //     )
//   //     const totalDistance = d1 + d2

//   //     if (totalDistance > maxDistance) {
//   //       maxDistance = totalDistance
//   //       bestAngle = rotationAngle
//   //     }
//   //   }
//   // }
// }

// function isSamePoint(p1: Point, p2: Point) {
//   return (
//     Cesium.Math.equalsEpsilon(p1.x, p2.x, Cesium.Math.EPSILON7) &&
//     Cesium.Math.equalsEpsilon(p1.y, p2.y, Cesium.Math.EPSILON7)
//   )
// }

function getPolygonLengthAndWidth2(polygonExcludingLastPoint: point[]): point[] {
  // 1. Get the line segments which form the polygon.
  const lines = getLinesFromPolygon(polygonExcludingLastPoint)

  let points = [...polygonExcludingLastPoint, polygonExcludingLastPoint[0]].map((x) => ({
    x: x.longitude,
    y: x.latitude,
  }))

  let maxDistance = 0
  let bestIntersection1
  // let bestLine
  let originalBestLine

  // Find the longest line.
  for (const line of lines) {
    const perpendicular = line.perpendicular().extend()

    const intersection = intersectLinePolygon(
      {
        x: perpendicular.p.longitude,
        y: perpendicular.p.latitude,
      },
      {
        x: perpendicular.q.longitude,
        y: perpendicular.q.latitude,
      },
      points
    )

    if (intersection.numPoints() < 2) {
      continue
    }

    const distance = intersection.distance()
    if (distance > maxDistance) {
      bestIntersection1 = intersection
      maxDistance = distance
      // bestLine = perpendicular
      originalBestLine = line
    }
  }

  if (!bestIntersection1) {
    return []
  }

  // Find the longest perpendicular line.

  let maxDistance2 = 0
  let bestIntersection2
  // let bestLine2
  // let originalBestLine2
  for (const testLine of lines) {
    if (testLine === originalBestLine) {
      continue
    }

    const l2 = new line(
      {
        longitude: bestIntersection1.points[0].x,
        latitude: bestIntersection1.points[0].y,
      },
      {
        longitude: bestIntersection1.points[1].x,
        latitude: bestIntersection1.points[1].y,
      }
    )

    const s1 = testLine.slope()
    const s2 = l2.slope()
    //const l2 = bestLine

    // const c1 = new Cesium.Cartesian2(
    //   testLine.q.longitude - testLine.p.longitude,
    //   testLine.q.latitude - testLine.p.latitude
    // )
    // const c2 = new Cesium.Cartesian2(l2.q.longitude - l2.p.longitude, l2.q.latitude - l2.p.latitude)

    // const dot = Cesium.Cartesian2.dot(c1, c2)
    // const m1 = Cesium.Cartesian2.magnitude(c1)
    // const m2 = Cesium.Cartesian2.magnitude(c2)
    // const angleRadians = Math.acos(dot / (m1 * m2))
    // const angleDegrees = Cesium.Math.toDegrees(angleRadians)

    if (!Cesium.Math.equalsEpsilon(Math.abs(s1), Math.abs(s2), 0.25)) {
      continue
    }

    const perpendicular = testLine.perpendicular().extend()

    const intersection = intersectLinePolygon(
      {
        x: perpendicular.p.longitude,
        y: perpendicular.p.latitude,
      },
      {
        x: perpendicular.q.longitude,
        y: perpendicular.q.latitude,
      },
      points
    )

    if (intersection.numPoints() < 2) {
      continue
    }

    const l3 = new line(
      {
        longitude: intersection.points[0].x,
        latitude: intersection.points[0].y,
      },
      {
        longitude: intersection.points[1].x,
        latitude: intersection.points[1].y,
      }
    )
    if (Cesium.Math.equalsEpsilon(Math.abs(l3.slope()), Math.abs(s2), 0.1)) {
      continue
    }

    const distance = intersection.distance()
    if (distance > maxDistance2) {
      bestIntersection2 = intersection
      maxDistance2 = distance
      //bestLine2 = perpendicular
      //originalBestLine2 = testLine
    }
  }

  // const perpendicular2 = new line(
  //   {
  //     longitude: bestIntersection1.points[0].x,
  //     latitude: bestIntersection1.points[0].y,
  //   },
  //   {
  //     longitude: bestIntersection1.points[1].x,
  //     latitude: bestIntersection1.points[1].y,
  //   }
  // )
  //   .perpendicular()
  //   .extend()

  // const perpendicular3 = new line(
  //   {
  //     longitude: bestIntersection1.points[0].x,
  //     latitude: bestIntersection1.points[0].y,
  //   },
  //   {
  //     longitude: bestIntersection1.points[1].x,
  //     latitude: bestIntersection1.points[1].y,
  //   }
  // )
  //   .perpendicular()
  //   .extend()
  // const intersection2 = intersectLinePolygon(
  //   {
  //     x: perpendicular2.p.longitude,
  //     y: perpendicular2.p.latitude,
  //   },
  //   {
  //     x: perpendicular2.q.longitude,
  //     y: perpendicular2.q.latitude,
  //   },
  //   points
  // )

  // if (intersection2.numPoints() < 2) {
  //   return []
  // }

  if (!bestIntersection2) {
    return []
  }

  return [
    {
      longitude: bestIntersection1.points[0].x,
      latitude: bestIntersection1.points[0].y,
    },
    {
      longitude: bestIntersection1.points[1].x,
      latitude: bestIntersection1.points[1].y,
    },
    {
      longitude: bestIntersection2.points[0].x,
      latitude: bestIntersection2.points[0].y,
    },
    {
      longitude: bestIntersection2.points[1].x,
      latitude: bestIntersection2.points[1].y,
    },
    // {
    //   longitude: bestLine2.p.longitude,
    //   latitude: bestLine2.p.latitude,
    // },
    // {
    //   longitude: bestLine2.q.longitude,
    //   latitude: bestLine2.q.latitude,
    // },
    // {
    //   longitude: perpendicular3.p.longitude,
    //   latitude: perpendicular3.p.latitude,
    // },
    // {
    //   longitude: perpendicular3.q.longitude,
    //   latitude: perpendicular3.q.latitude,
    // },
  ]

  // let maxDistance = 0
  // let bestAngle = 0
  // for (let rotationAngle = 0; rotationAngle < 90; rotationAngle++) {
  //   const l1 = searchLine1.rotate(rotationAngle)
  //   const l2 = searchLine2.rotate(rotationAngle)

  //   for (const line of lines) {
  //     const intersection1 = l1.intersection(line)
  //     const intersection2 = l2.intersection(line)

  //     if (intersection1 === undefined || intersection2 === undefined) {
  //       continue
  //     }

  //     const d1 = Math.sqrt(
  //       Math.pow(intersection1.longitude - center.longitude, 2) + Math.pow(intersection1.latitude - center.latitude, 2)
  //     )
  //     const d2 = Math.sqrt(
  //       Math.pow(intersection2.longitude - center.longitude, 2) + Math.pow(intersection2.latitude - center.latitude, 2)
  //     )
  //     const totalDistance = d1 + d2

  //     if (totalDistance > maxDistance) {
  //       maxDistance = totalDistance
  //       bestAngle = rotationAngle
  //     }
  //   }
  // }
}

function getRotatedPosition(position: Cesium.Cartesian3, center: Cesium.Cartesian3, angleInRadisan: number) {
  var matrix = Cesium.Transforms.eastNorthUpToFixedFrame(center, undefined, new Cesium.Matrix4())

  var inv = Cesium.Matrix4.inverseTransformation(matrix, new Cesium.Matrix4())

  var localPosition = Cesium.Matrix4.multiplyByPoint(inv, position, new Cesium.Cartesian3())

  var rotationZ = Cesium.Matrix3.fromRotationZ(angleInRadisan, new Cesium.Matrix3())

  var translation = new Cesium.Cartesian3(0, 0, 0)

  var rotationZMatrix4 = Cesium.Matrix4.fromRotationTranslation(rotationZ, translation, new Cesium.Matrix4())

  var newPosition = Cesium.Matrix4.multiplyByPoint(rotationZMatrix4, localPosition, new Cesium.Cartesian3())

  return Cesium.Matrix4.multiplyByPoint(matrix, newPosition, new Cesium.Cartesian3())
}
