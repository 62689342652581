import { Point, Rect } from 'openseadragon'
import { BaseShape } from './base-shape'

export class PointShape extends BaseShape {
  static pointDisplaySize = 3
  point: Point = new Point()

  get boundingBox() {
    const { x, y } = this.point
    return new Rect(x, y, 0, 0)
  }

  toPath2D() {
    const localPoint = this.toViewerCoords(this.point)
    const path2d = new Path2D()
    path2d.arc(localPoint.x, localPoint.y, PointShape.pointDisplaySize, 0, 2 * Math.PI)
    path2d.closePath()
    return path2d
  }

  onMouseDown(point: Point): boolean {
    if (!this.isDrawing) {
      return true
    }
    this.point = point.clone()
    this.finishDrawing()
    return true
  }

  onMouseMove(): boolean {
    return true
  }

  onMouseUp(): void {
    return
  }

  onRightClick(point: Point): void {
  }
}
