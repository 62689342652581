import React from 'react'
import { FormikTouched } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { SignUpFormType } from 'instant-assess/instant-assess'
import { AuthInput } from 'components'

type Props = {
  onMsg: (msg: Msg) => void
  orgName: string
  values: SignUpFormType
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  touched: FormikTouched<SignUpFormType>
  errors: FormikErrors<SignUpFormType>
  isSubmitting: boolean
}

type Msg = { type: 'finish_clicked' } | { type: 'back_clicked' }

export const CompanyInfo = ({
  onMsg,
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  orgName,
  isSubmitting,
}: Props) => {
  return (
    <>
      <h5 className='instant-assess-layout-header text-left mb-4 d-flex justify-content-between align-items-center'>
        Let's get to know you better
        <i
          style={{ cursor: 'pointer' }}
          className='fa-solid fa-chevron-left click-target'
          onClick={() => {
            onMsg({ type: 'back_clicked' })
          }}
        ></i>
      </h5>

      <AuthInput id='company' label='Company' type='company' value={orgName} readonly />

      <AuthInput
        id='jobTitle'
        label='Your role'
        type='jobTitle'
        value={values.jobTitle}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.jobTitle ? errors.jobTitle : ''}
        error={touched.jobTitle && Boolean(errors.jobTitle)}
      />

      <AuthInput
        id='industry'
        label='Industry'
        type='industry'
        value={values.industry}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.industry ? errors.industry : ''}
        error={touched.industry && Boolean(errors.industry)}
      />

      <AuthInput
        id='leadSource'
        label='How did you hear about us?'
        type='leadSource'
        value={values.leadSource}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.leadSource ? errors.leadSource : ''}
        error={touched.leadSource && Boolean(errors.leadSource)}
      />

      <hr />
      <button
        className='btn btn-instant-assess'
        type='button'
        disabled={hasErrors(errors) && !isSubmitting}
        onClick={() => {
          onMsg({ type: 'finish_clicked' })
        }}
      >
        {isSubmitting ? 'Signing up...' : 'Sign up'}
      </button>
    </>
  )
}

const hasErrors = (errors: FormikErrors<SignUpFormType>): boolean => {
  return !!errors.jobTitle || !!errors.leadSource || !!errors.industry
}
