import React from 'react'
import { useAppState } from '~/state'
import { CHEVRONS } from '~/images'
import { classes } from '../helpers'

interface DrawerPopoutProps {
  width: number
  title: string
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopout = (props: DrawerPopoutProps) => {
  const { drawer } = useAppState()

  return (
    <div className='drawer-popout' style={{ width: props.width + 'px', minWidth: props.width + 'px' }}>
      <div className='drawer-popout-header'>
        <p className='drawer-popout-header-text'>{props.title}</p>
        <div style={{ cursor: 'pointer' }} onClick={drawer.closeExpandedPanel}>
          <img className='drawer-popout-header-chevron' src={CHEVRONS} title='hide' />
          <span className='drawer-popout-header-hide-text'>Hide</span>
        </div>
      </div>
      <div className='drawer-popout-divider' />
      {props.children}
    </div>
  )
}

interface DrawerPopoutControlsProps {
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopoutControls = (props: DrawerPopoutControlsProps) => {
  return <div className='drawer-popout-controls'>{props.children}</div>
}

interface DrawerPopoutPageNumberProps {
  count: number
  total?: number
  pageNumberControls?: JSX.Element
}

export const DrawerPopoutPageNumber = (props: DrawerPopoutPageNumberProps) => {
  return (
    <div className='drawer-popout-page-number'>
      <div className={props.pageNumberControls ? 'inline' : ''}>
        {props.count === 0 ? '0' : '1'} - {props.count} of {props.total ? props.total : props.count}
      </div>
      {props.pageNumberControls && <div>{props.pageNumberControls}</div>}
    </div>
  )
}

interface DrawerPopoutTableContainerProps {
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopoutTableContainer = (props: DrawerPopoutTableContainerProps) => {
  return <div className='drawer-popout-table-container'>{props.children}</div>
}

interface DrawerPopoutTableProps {
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopoutTable = (props: DrawerPopoutTableProps) => {
  return <table className='drawer-popout-table'>{props.children}</table>
}

interface DrawerPopoutTableHeaderProps {
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopoutTableHeader = (props: DrawerPopoutTableHeaderProps) => {
  return <thead className='drawer-popout-table-header'>{props.children}</thead>
}

interface DrawerPopoutTableBodyProps {
  children?: JSX.Element | JSX.Element[]
}

export const DrawerPopoutTableBody = (props: DrawerPopoutTableBodyProps) => {
  return <tbody className='drawer-popout-table-body'>{props.children}</tbody>
}

interface DrawerPopoutTableThProps {
  width?: number
  center?: boolean
  right?: boolean
  children?: any
}

export const DrawerPopoutTableTh = (props: DrawerPopoutTableThProps) => {
  const style: any = {}
  if (props.width) {
    style['width'] = props.width + 'px'
  }
  return (
    <th
      className={classes({
        'drawer-popout-table-th': true,
        center: props.center,
        right: props.right,
      })}
      style={style}
    >
      {props.children}
    </th>
  )
}

interface DrawerPopoutTableTdProps {
  width?: number
  center?: boolean
  right?: boolean
  children?: any
}

export const DrawerPopoutTableTd = (props: DrawerPopoutTableTdProps) => {
  const style: any = {}
  if (props.width) {
    style['width'] = props.width + 'px'
  }
  return (
    <td
      className={classes({
        'drawer-popout-table-td': true,
        center: props.center,
        right: props.right,
      })}
      style={style}
    >
      {props.children}
    </td>
  )
}
