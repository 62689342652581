import React, { useEffect, useState } from 'react'
import { IssueBadge } from '~/issues/issue-badge'
import {
  BoundaryMarkerApprovalStatus,
  BoundaryMarkerSetStatusMutation,
  BoundaryMarkerSetStatusMutationVariables,
  BoundaryMarkerStatus,
  OrganizationRole,
  SiteQueryBoundary,
  SurveyBasedOperation,
} from '~/models'
import { BoundaryMarkerCreate } from './boundary-marker-create'
import { MarkerIconType, useAppState, Marker as MapMarker, MARKER_ICON_2D, MARKER_ICON_2D_DARK } from '~/state'
import { PolygonColorRed } from '../polygon-renderer'
import { classes } from '../helpers'
import { ClientPermission, useUser } from '~/base'
import { BoundaryMarkerRemove } from './boundary-marker-remove'
import { useToasts } from '../toast'
import { BoundaryMarkerUpdate } from './boundary-marker-update'
import BOUNDARY_MARKER_SET_STATUS from './mutation-boundary-marker-set-status.gql'
import { useMutation } from '../data'
import { BoundaryMarkerApprove } from './boundary-marker-approve'

type Marker = SiteQueryBoundary['markers'][0]
type MarkerListItem = {
  marker: Marker
  mapMarker: MapMarker
}

interface MarkerState {
  pendingApproval: MarkerListItem[]
  approved: MarkerListItem[]
  rejected: MarkerListItem[]
}

interface BoundaryMarkerListProps {
  boundary: SiteQueryBoundary
}

export const BoundaryMarkerList = (props: BoundaryMarkerListProps) => {
  const { site, map } = useAppState()
  const toasts = useToasts()
  const [adding, setAdding] = useState(false)
  const [toApprove, setToApprove] = useState<MarkerListItem>()
  const [toReject, setToReject] = useState<MarkerListItem>()
  const [toUpdate, setToUpdate] = useState<MarkerListItem>()
  const [toRemove, setToRemove] = useState<Marker>()
  const [markerState, setMarkerState] = useState<MarkerState>({
    pendingApproval: [],
    approved: [],
    rejected: [],
  })

  const markers = props.boundary?.markers || []

  useEffect(() => {
    const state: MarkerState = {
      pendingApproval: [],
      approved: [],
      rejected: [],
    }
    if (!map.initialized || markers.length === 0) {
      setMarkerState(state)
      return
    }

    for (const m of markers) {
      const mapMarker = map.addMarker(
        m.id,
        [m.location.longitude, m.location.latitude],
        undefined,
        undefined,
        32,
        PolygonColorRed,
        MarkerIconType.MapDark
      )

      const toAdd: MarkerListItem = {
        marker: m,
        mapMarker,
      }

      if (m.approvalStatus === BoundaryMarkerApprovalStatus.Pending) {
        state.pendingApproval.push(toAdd)
      } else if (m.approvalStatus === BoundaryMarkerApprovalStatus.Approved) {
        state.approved.push(toAdd)
      } else {
        state.rejected.push(toAdd)
      }
    }

    setMarkerState(state)

    return () => {
      state.pendingApproval.forEach((m) => map.removeMarker(m.mapMarker))
      state.approved.forEach((m) => map.removeMarker(m.mapMarker))
      state.rejected.forEach((m) => map.removeMarker(m.mapMarker))
    }
  }, [markers, map.initialized])

  useEffect(() => {
    if (!toUpdate) {
      return
    }

    toUpdate.mapMarker.billboard.show = false

    return () => {
      toUpdate.mapMarker.billboard.show = true
    }
  }, [toUpdate])

  return (
    <div className='boundary-marker-list'>
      <h1 className='boundary-marker-list-title'>
        Markers{' '}
        <span
          className='material-icons add dimmed'
          onClick={() => {
            setAdding(true)
          }}
        >
          add
        </span>
      </h1>
      <div className='boundary-marker-list-content'>
        {markerState.pendingApproval.length > 0 && (
          <>
            <h3 className='boundaries-category-title'>Pending Approval</h3>
            {markerState.pendingApproval.map((m) => {
              return (
                <BoundaryMarkerCard
                  key={m.marker.id}
                  marker={m}
                  onApprove={() => setToApprove(m)}
                  onReject={() => setToReject(m)}
                  onUpdate={() => setToUpdate(m)}
                  onRemove={() => setToRemove(m.marker)}
                />
              )
            })}
          </>
        )}
        {markerState.approved.length > 0 && (
          <>
            <h3 className='boundaries-category-title'>Approved</h3>
            {markerState.approved.map((m) => {
              return (
                <BoundaryMarkerCard
                  key={m.marker.id}
                  marker={m}
                  onApprove={() => setToApprove(m)}
                  onReject={() => setToReject(m)}
                  onUpdate={() => setToUpdate(m)}
                  onRemove={() => setToRemove(m.marker)}
                />
              )
            })}
          </>
        )}
        {markerState.rejected.length > 0 && (
          <>
            <h3 className='boundaries-category-title'>Rejected</h3>
            {markerState.rejected.map((m) => {
              return (
                <BoundaryMarkerCard
                  key={m.marker.id}
                  marker={m}
                  onApprove={() => setToApprove(m)}
                  onReject={() => setToReject(m)}
                  onUpdate={() => setToUpdate(m)}
                  onRemove={() => setToRemove(m.marker)}
                />
              )
            })}
          </>
        )}

        {markers.length === 0 && (
          <div style={{ marginRight: '12px' }}>
            <div className='no-item-tag' style={{ width: 'unset' }} onClick={() => setAdding(true)}>
              Click to add a new marker
            </div>
          </div>
        )}
      </div>
      {adding && (
        <BoundaryMarkerCreate
          complete={() => {
            return site
              .refetch()
              .then(() => {
                toasts.addTopLeft('Successfully created the marker')
                setAdding(false)
              })
              .catch(() => {
                toasts.addTopLeft('An error occurred and the marker could not be created')
              })
          }}
          cancel={() => {
            setAdding(false)
            return Promise.resolve()
          }}
        />
      )}
      {(toApprove || toReject) && (
        <BoundaryMarkerApprove
          boundary={props.boundary}
          marker={(toApprove || toReject).marker}
          approved={!!toApprove}
          refetch={() => {
            return site
              .refetch()
              .then(() => {
                toasts.addTopLeft('Successfully updated the marker status')
                setToUpdate(undefined)
              })
              .catch(() => {
                toasts.addTopLeft('An error occurred and the marker status could not be updated')
              })
          }}
          close={() => {
            setToApprove(undefined)
            setToReject(undefined)
            return Promise.resolve()
          }}
        />
      )}
      {toUpdate && (
        <BoundaryMarkerUpdate
          marker={toUpdate.marker}
          complete={() => {
            return site
              .refetch()
              .then(() => {
                toasts.addTopLeft('Successfully updated the marker')
                setToUpdate(undefined)
              })
              .catch(() => {
                toasts.addTopLeft('An error occurred and the marker could not be updated')
              })
          }}
          cancel={() => {
            setToUpdate(undefined)
            return Promise.resolve()
          }}
        />
      )}
      {toRemove && (
        <BoundaryMarkerRemove
          boundary={props.boundary}
          marker={toRemove}
          refetch={() => {
            return site
              .refetch()
              .then(() => {
                toasts.addTopLeft('Successfully removed the marker')
                setToRemove(undefined)
              })
              .catch(() => {
                toasts.addTopLeft('An error occurred and the marker could not be removed')
              })
          }}
          close={() => {
            setToRemove(undefined)
          }}
        />
      )}
    </div>
  )
}

interface BoundaryMarkerCardProps {
  marker: MarkerListItem
  onApprove: () => void
  onReject: () => void
  onUpdate: () => void
  onRemove: () => void
}

const BoundaryMarkerCard = (props: BoundaryMarkerCardProps) => {
  const { view, site } = useAppState()
  const user = useUser()
  const toasts = useToasts()
  const item = props.marker

  const needsApproval = item.marker.approvalStatus === BoundaryMarkerApprovalStatus.Pending
  const canApprove = user.org.role === OrganizationRole.AccountOwner || user.org.role === OrganizationRole.Admin
  const canEdit = user.hasPermission(ClientPermission.BoundariesUpdate)
  const canRemove = user.hasPermission(ClientPermission.BoundariesRemove)
  const canUpdateStatus = user.hasPermission(ClientPermission.BoundariesUpdate)

  const [setStatus] = useMutation<BoundaryMarkerSetStatusMutation, BoundaryMarkerSetStatusMutationVariables>(
    BOUNDARY_MARKER_SET_STATUS
  )

  return (
    <div
      className={classes({
        'boundary-marker-card': true,
        pending: item.marker.status === BoundaryMarkerStatus.NeedsVerification,
        visible: item.marker.status === BoundaryMarkerStatus.PhysicallyVisible,
        missing: item.marker.status === BoundaryMarkerStatus.PhysicallyMissing,
      })}
      onMouseEnter={() => {
        item.mapMarker.billboard.image = MARKER_ICON_2D
      }}
      onMouseLeave={() => {
        item.mapMarker.billboard.image = MARKER_ICON_2D_DARK
      }}
      title={item.marker.comment}
    >
      <div className='boundary-marker-card-top'>
        <div className='boundary-marker-card-left'>
          {/* <i className='material-icons'>place</i> */}
          <IssueBadge
            canEdit={canUpdateStatus}
            type='boundary-marker-status'
            subType={props.marker.marker.status}
            options={[
              {
                id: BoundaryMarkerStatus.NeedsVerification,
                text: 'Unverified',
              },
              {
                id: BoundaryMarkerStatus.PhysicallyVisible,
                text: 'Visible',
              },
              {
                id: BoundaryMarkerStatus.PhysicallyMissing,
                text: 'Missing',
              },
            ]}
            update={(v: BoundaryMarkerStatus) => {
              return setStatus({
                variables: {
                  input: {
                    siteID: view.siteID,
                    surveyID: view.surveyID,
                    boundaryID: view.boundaryID,
                    markerID: props.marker.marker.id,
                    operationType: SurveyBasedOperation.Singular,
                    status: v,
                  },
                },
              })
                .then(() => site.refetch())
                .then(() => {
                  toasts.addTopLeft('Successfully updated the marker status')
                })
                .catch(() => {
                  toasts.addTopLeft('An error occurred and the marker status was not updated')
                })
            }}
          />
        </div>
        <div className='boundary-marker-card-right'>
          {needsApproval && canApprove && (
            <i className='material-icons' title='Approve' onClick={props.onApprove}>
              done
            </i>
          )}
          {needsApproval && canApprove && (
            <i className='material-icons' title='Reject' onClick={props.onReject}>
              clear
            </i>
          )}
          {canEdit && (
            <i className='material-icons' title='Edit' onClick={props.onUpdate}>
              edit
            </i>
          )}
          {canRemove && (
            <i className='material-icons' title='Remove' onClick={props.onRemove}>
              delete
            </i>
          )}
        </div>
      </div>
      {/* <div className='boundary-marker-card-bottom'>{item.marker.comment}</div> */}
    </div>
  )
}
