import React, { useState } from 'react'
import { getRequiredHost, getTracking, Input, useMutation, useToasts } from '~/components'
import { ClientPermission, useUser } from '~/base'
import { CurrencyDropdown } from './currency-dropdown'
import SET_WORKSPACE_CURRENCY from './mutation-set-currency.gql'
import SET_WORKSPACE_MEASUREMENT_SYSTEM from './mutation-set-measurement-system.gql'
import {
  OrganizationSetCurrencyMutation,
  OrganizationSetCurrencyMutationVariables,
  OrganizationSetMeasurementSystemMutation,
  OrganizationSetMeasurementSystemMutationVariables,
} from '~/models'
import { MeasurementSystemDropdown } from 'account/measurment-system-dropdown'

export const Settings = () => {
  const user = useUser()
  const [currency, setCurrency] = useState(user.org.currency)
  const [measurementSystem, setMeasurementSystem] = useState(user.org.measurementSystem)
  const [executeSetCurrency] = useMutation<OrganizationSetCurrencyMutation, OrganizationSetCurrencyMutationVariables>(
    SET_WORKSPACE_CURRENCY
  )
  const [executeSetMeasurementSystem] = useMutation<
    OrganizationSetMeasurementSystemMutation,
    OrganizationSetMeasurementSystemMutationVariables
  >(SET_WORKSPACE_MEASUREMENT_SYSTEM)

  const [saving, setSaving] = useState<boolean>(false)
  const toasts = useToasts()
  const canManage = user.hasPermission(ClientPermission.ManageOrg)

  return (
    <div className='settings-tab-content'>
      <form autoComplete='off' className='settings-tab-content-form'>
        <label htmlFor='teamName'>Team name</label>
        <Input id='teamName' label='Team name' value={user.org.name} readonly />
        <label htmlFor='teamDomain'>Team domain</label>
        <div className='input-group'>
          <Input id='teamDomain' label='Team domain' value={user.org.domainName} readonly />
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              {getRequiredHost()}
            </span>
          </div>
        </div>
        <p className='settings-tab-content-notice restricted-width'>
          If you change your domain, make sure to notify your team members. You’ll always be able to access projects
          through their original links, but we’ll eventually release your old domain.
        </p>
        <CurrencyDropdown value={currency} onChange={setCurrency} disabled={!canManage} />
        <br />
        <MeasurementSystemDropdown value={measurementSystem} onChange={setMeasurementSystem} disabled={!canManage} />
        <br />
        <br />
        <button
          className='settings-tab-content-btn btn'
          disabled={saving || !canManage}
          type='button'
          onClick={async () => {
            getTracking().event({
              category: 'Button',
              action: `User clicked org settings save button`,
              label: 'Settings',
            })

            setSaving(true)
            try {
              await executeSetCurrency({
                variables: {
                  input: {
                    currency,
                  },
                },
              })
              user.setOrgCurrency(currency)
              await executeSetMeasurementSystem({
                variables: {
                  input: {
                    measurementSystem,
                  },
                },
              })

              user.setOrgMeasurementSystem(measurementSystem)
              toasts.addTopLeft('Workspace settings saved')
            } catch (e) {
              toasts.addTopLeft('Failed to save workspace settings')
            } finally {
              setSaving(false)
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </form>
    </div>
  )
}
