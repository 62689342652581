import React from 'react'
import { SimpleBar } from '../simple-bar'
import { classes } from '../helpers'

interface DrawerListProps {
  children?: any
  noPadding?: boolean
}

export const DrawerList = (props: DrawerListProps) => {
  return (
    <div
      className={classes({
        'drawer-list': true,
        'no-padding': props.noPadding,
      })}
    >
      <SimpleBar>{props.children}</SimpleBar>
    </div>
  )
}

export const DrawerListDivider = () => <hr className='drawer-list-divider' />
