import React, { useEffect, useState } from 'react'
import { FloatingInput, Select, useToasts } from '~/components'
import { useComponentView } from '~/components/components2/use-component-view'
import { PointInput3Dt, SiteQueryAsset } from '~/models'
import { ProjectionType, useAppState } from '~/state'
import { expandRect } from '~/state/utils'
import { useAnalysisCreate } from './analysis-data'
import { AnalysisFormCreateArea } from './analysis-form-create-area'

interface AnalysisFormCreateProps {
  onComplete: (changed: boolean, hide: boolean) => void
}

export const AnalysisFormCreate = (props: AnalysisFormCreateProps) => {
  useComponentView()
  const toasts = useToasts()
  const { timeline, view, map, components, issues, site } = useAppState()
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>(
    site.site.assets.find((a) => a.id === view.assetID)
  )
  const [nameFocused, setNameFocused] = useState(false)
  const [name, setName] = useState<string>()
  const [points, setPoints] = useState<PointInput3Dt[]>([])

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<string>()
  const [valid, setValid] = useState(false)
  const [setRoiFromComponent, setSetRoiFromComponent] = useState(false)

  useEffect(() => {
    components.setHidden(true)
    const oldDraw = issues.draw
    issues.setDraw(false)
    return () => {
      components.setHidden(false)
      issues.setDraw(oldDraw)
    }
  }, [])

  useEffect(() => {
    if (!setRoiFromComponent) {
      return
    }
    components.setHidden(false)
    const draw = components.draw
    components.setDraw(true)
    components.setSelectedAssetID(selectedAsset.id)
    return () => {
      components.setSelectedAssetID('')
      components.setHidden(true)
      components.setDraw(draw)
    }
  }, [setRoiFromComponent])

  useEffect(() => {
    if (!setRoiFromComponent || !components.selectedComponent) {
      return
    }

    setPoints([...components.selectedComponent.points])
    components.setSelectedComponent(undefined)
    setSetRoiFromComponent(false)
  }, [setRoiFromComponent, components.selectedComponent])

  useEffect(() => {
    if (!name || name === '') {
      setValid(false)
      return
    }

    if (!Array.isArray(points) || points.length < 4) {
      setValid(false)
      return
    }

    setValid(true)
  }, [name, points])

  const [createMutation] = useAnalysisCreate()

  const doSave = () => {
    setSaving(true)

    const minX = points.reduce((a, b) => (a < b.longitude ? a : b.longitude), 10000)
    const minY = points.reduce((a, b) => (a < b.latitude ? a : b.latitude), 10000)
    const maxX = points.reduce((a, b) => (a > b.longitude ? a : b.longitude), -10000)
    const maxY = points.reduce((a, b) => (a > b.latitude ? a : b.latitude), -10000)
    const centerX = maxX - (maxX - minX) / 2
    const centerY = maxY - (maxY - minY) / 2

    const origin = Cesium.Cartesian3.fromDegrees(centerX, centerY, 10000)
    const dest = Cesium.Cartesian3.fromDegrees(centerX, centerY, -10000)
    const ray = new Cesium.Ray(origin, Cesium.Cartesian3.subtract(dest, origin, new Cesium.Cartesian3()))
    const allPickedFromScene = map.viewer.scene.drillPickFromRay(ray, 150, [], 1) as Array<{
      position: Cesium.Cartesian3
      object: object
    }>
    const ids = allPickedFromScene
      .filter((pickedFromScene) => {
        return typeof (pickedFromScene.object as any)?.primitive?.reconID === 'string'
      })
      .map((x) => {
        return (x.object as any).primitive.reconID
      })

    if (ids.length > 0) {
      const recon = timeline.activeSurvey.reconstructions.find((r) => r.cesium3D?.id === ids[0])
      if (recon) {
        return createMutation({
          variables: {
            input: {
              assetID: selectedAsset?.id || view.assetID || '',
              siteID: view.siteID,
              surveyID: view.surveyID,
              reconstructionID: recon.id,
              roi: points,
              name: name || '',
              expansionInMeters: 0,
              maxPointPlaneDistance: 0.2,
              resolutionInMeters: 0.025,
            },
          },
        })
          .then(() => {
            setError(undefined)
            toasts.addTopLeft('Analysis successfully submitted')
          })
          .catch((e) => {
            setError(e)
            toasts.addTopLeft('Failed to create analysis')
          })
          .finally(() => {
            setSaving(false)
          })
      }
    } else {
      setSaving(false)
      return Promise.reject()
    }
  }

  const save = () => {
    doSave().then(() => {
      props.onComplete(true, true)
    })
  }

  const saveAndAdd = () => {
    return doSave().then(() => {
      props.onComplete(true, false)
      setName('')
      setPoints([])
    })
  }

  useEffect(() => {
    if (saving) {
      return
    }

    function onKeyDown(e: KeyboardEvent) {
      const setAsset = (a: SiteQueryAsset) => {
        setSelectedAsset(a)

        const points: Cesium.Cartographic[] = []

        for (const boundary of a.boundaries) {
          for (const p of boundary.points) {
            points.push(
              Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
            )
          }
        }

        if (points.length > 0) {
          const rect = expandRect(Cesium.Rectangle.fromCartographicArray(points))
          map.jumpTo(rect)
        }
      }

      const key = e.key.toLocaleLowerCase()
      if (valid && key === 'enter') {
        saveAndAdd().then(() => {
          if (selectedAsset) {
            const assetIndex = site.site.assets.findIndex((a) => a.id === selectedAsset.id)
            if (assetIndex === site.site.assets.length - 1) {
              setAsset(site.site.assets[0])
            } else {
              setAsset(site.site.assets[assetIndex + 1])
            }
          } else {
            if (site.site.assets.length > 0) {
              setAsset(site.site.assets[0])
            }
          }
        })
      } else if (key === 'n') {
        if (selectedAsset) {
          const assetIndex = site.site.assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === site.site.assets.length - 1) {
            setAsset(site.site.assets[0])
          } else {
            setAsset(site.site.assets[assetIndex + 1])
          }
        } else {
          if (site.site.assets.length > 0) {
            setAsset(site.site.assets[0])
          }
        }
      } else if (key === 'p') {
        if (selectedAsset) {
          const assetIndex = site.site.assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === 0) {
            setAsset(site.site.assets[site.site.assets.length - 1])
          } else {
            setAsset(site.site.assets[assetIndex - 1])
          }
        } else {
          if (site.site.assets.length > 0) {
            setAsset(site.site.assets[0])
          }
        }
      }
    }

    if (!nameFocused) {
      window.addEventListener('keydown', onKeyDown, false)
    }

    return () => {
      if (!nameFocused) {
        window.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [nameFocused, valid, saving, selectedAsset])

  const is3D = map.projectionType === ProjectionType.Projection3D
  const selectedComponents = selectedAsset
    ? timeline.activeSurvey.components.filter((c) => c.assetID === selectedAsset.id)
    : []

  return (
    <div className='components-draw'>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  if (!saving) {
                    props.onComplete(false, true)
                  }
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Analysis
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>Add Analysis</h6>
        </div>
        <div className='drawer-panel-form-container'>
          <Select<SiteQueryAsset>
            id='asset'
            label='Asset'
            placeholder='Select asset'
            onChange={(selected) => {
              setSelectedAsset(selected)
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedAsset}
            options={site.site?.assets
              .map((a) => {
                return {
                  id: a.id,
                  name: a.name,
                  value: a,
                  items: [],
                }
              })
              .sort((a, b) => (a.name > b.name ? 1 : -1))}
            disabled={!!view.assetID}
          />
          <FloatingInput
            label='Name'
            id='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setNameFocused(true)}
            onBlur={() => setNameFocused(false)}
          />
          {selectedComponents.length > 0 && (
            <button
              className='btn default small'
              onClick={() => {
                setSetRoiFromComponent(true)
              }}
            >
              Set ROI from component
            </button>
          )}
          {is3D && !setRoiFromComponent && (
            <AnalysisFormCreateArea
              points={points}
              setPoints={(points: PointInput3Dt[]) => {
                setPoints(points)
              }}
            />
          )}

          {setRoiFromComponent && (
            <div className='components-draw'>
              <div className='drawer-panel' style={{ background: '#2b2728' }}>
                <nav aria-label='breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>Site</li>
                    <li className='breadcrumb-item active' aria-current='page'>
                      Analysis
                    </li>
                  </ol>
                </nav>
                <div className='drawer-panel-title-container'>
                  <h6 className='drawer-panel-title'>Add Analysis</h6>
                </div>
                <div className='drawer-panel-form-container'>
                  <div className='analysis-select-component-text'>
                    Select a component by clicking a label on the map
                  </div>
                  <button
                    className='btn default small'
                    onClick={() => {
                      setSetRoiFromComponent(false)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className='drawer-panel-form-container-action-container'>
            {error && <div className='error'>{JSON.stringify(error, null, 2)}</div>}
            <button type='submit' className='btn submit mb-2' disabled={!valid || saving} onClick={save}>
              Save
            </button>
            <button type='submit' className='btn submit mb-2' disabled={!valid || saving} onClick={saveAndAdd}>
              Save &amp; add
            </button>
            <button className='btn cancel' disabled={saving} onClick={() => props.onComplete(false, true)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}
