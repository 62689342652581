import React, { useEffect, useRef, useState } from 'react'
import { Currency } from '~/models'

interface CurrencyDropdownProps {
  value: Currency
  onChange: (value: Currency) => void
  disabled: boolean
}

export function CurrencyDropdown(props: CurrencyDropdownProps) {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  const handleChange = (selectedValue: Currency) => {
    setOpen(false)
    props.onChange(selectedValue)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const options = [
    {
      label: 'Australian Dollar',
      value: Currency.Aud,
      flag: 'au',
    },
    {
      label: 'American Dollar',
      value: Currency.Usd,
      flag: 'us',
    },
    {
      label: 'Euro',
      value: Currency.Eur,
      flag: 'eu',
    },
    {
      label: 'British Pound',
      value: Currency.Gbp,
      flag: 'gb',
    },
    {
      label: 'New Zealand Dollar',
      value: Currency.Nzd,
      flag: 'nz',
    },
    {
      label: 'South African Rand',
      value: Currency.Zar,
      flag: 'za',
    },
  ]

  const selectedValue = options.find((o) => o.value === props.value)

  return (
    <>
      <label>Workspace Currency</label>
      <div ref={dropdownNode} className={`dropdown form-control currency-dropdown ${props.disabled ? 'disabled' : ''}`}>
        <div
          className={`dropdown-toggle ${open ? 'active' : ''}`}
          id={`dropdownMenuButton-currency`}
          data-toggle={`dropdown-currency`}
          aria-haspopup='true'
          aria-expanded={open}
          onClick={() => {
            if(!props.disabled) {
              setOpen(!open)
            }
          }}
        >
          <span className={`flag-icon flag-icon-${selectedValue.flag}`} /> {selectedValue.label}
        </div>
        <div
          className='dropdown-menu'
          aria-labelledby={`dropdownMenuButton-currency`}
          style={{ display: open ? 'block' : 'none' }}
        >
          {options.map((o) => (
            <a className='dropdown-item' onClick={() => handleChange(o.value)} key={o.label}>
              <div className={`dropdown-item-radio ${o.value === selectedValue.value ? 'checked' : ''}`}>
                <div />
              </div>
              <div className='dropdown-item-container'>
                <span className={`flag-icon flag-icon-${o.flag}`} /> {o.label}
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
