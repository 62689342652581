query ImageTimeline($input: QueryImageTimelineBySiteRequestT!, $input2: QuerySatelliteBySitesRequestT!) {
  queryImageTimeline(input: $input) {
    days {
      day
      numImages
      hashes
    }
  }
  satelliteImagesForSite(input: $input2) {
    satelliteID
    date
    tilingState
    purchaseState
    resolutionInCM
    hasRGB
    hasNED
  }
}