query AdminMeasurementSets($input: AdminMeasurementSetsGetBySiteRequestT!) {
  adminMeasurementSets(input: $input) {
    id
    orgID
    siteID
    surveyID
    type
    measurements {
      id
      assetID
      points {
        longitude
        latitude
        altitude
      }
      measurements
    }
  }
}