import React, { useState } from 'react'
import { ComponentCondition, SiteQueryAsset, SiteQuerySite } from '~/models'
import { ComponentsCreate } from './components-create'
import { ComponentsEdit } from './components-edit'
import { ClientPermission, useUser } from '~/base'
import { DrawerListContent } from '~/components'
import { ComponentFullList } from './component-full-list'
import { useAppState } from '~/state'
import { sortComponents } from './components-utils'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { ComponentRemove } from './component-remove'
import { ComponentProgressBar } from '~/sites/site-components'

interface ComponentsProps {
  site?: SiteQuerySite
  asset?: SiteQueryAsset
  refetchAsset: () => Promise<any>
}

export const Components = (props: ComponentsProps) => {
  const user = useUser()
  const { drawer, components: componentState, timeline, view, site } = useAppState()
  const loaded = view.assetID ? !!props.asset : !!timeline.activeSurvey

  const components = timeline.activeSurvey
    ? (view.assetID
        ? timeline.activeSurvey.components.filter((x) => x.assetID === view.assetID)
        : [...timeline.activeSurvey.components]
      ).sort(sortComponents)
    : []
  const haveComponents = components.length > 0
  const [show, setShow] = useState<boolean>(false)

  const isDemoSite = props.site?.isDemoSite
  const canViewComponents = user.hasPermission(ClientPermission.ComponentsRead)
  const canAddComponents = !isDemoSite && user.hasPermission(ClientPermission.ComponentsCreate)
  const haveAssetType = view.assetID && !!props.asset && typeof props.asset?.assetType?.id === 'string'

  let averageScore = 0
  let averageCondition = ''
  if (haveComponents) {
    const totalScore = components.reduce((a, c) => {
      let score = 0
      if (c.condition === ComponentCondition.Excellent) {
        score += 5
      } else if (c.condition === ComponentCondition.Good) {
        score += 4
      } else if (c.condition === ComponentCondition.Average) {
        score += 3
      } else if (c.condition === ComponentCondition.Poor) {
        score += 2
      } else if (c.condition === ComponentCondition.VeryPoor) {
        score += 1
      } else if (c.condition === ComponentCondition.EndOfLife) {
        score += 0
      }

      return a + score
    }, 0)

    averageScore = totalScore / components.length
    averageCondition = getAverageCondition(averageScore)
  }

  if (!canViewComponents) {
    return null
  }

  return (
    <>
      <DrawerListContent
        canAdd={canAddComponents && haveAssetType}
        loading={loaded}
        numEntities={components.length}
        panelType={ExpandedPanel.Components}
        plural='components'
        singular='component'
        title='Components'
        onAdd={() => {
          setShow(true)
        }}
        emptyButCanAddText='Add a component'
        emptyButCantAddText={haveAssetType ? 'No components have been added' : 'Please update the asset type'}
      >
        {haveComponents && (
          <div className='components-progress-bar-container'>
            <ComponentProgressBar condition={averageCondition} percent={Math.floor((averageScore / 5) * 100)} />
          </div>
        )}
        {/* <Transition width={120} in={loaded}>
          {haveComponents &&
            components
              .slice(0, 1)
              .map((c) => (
                <ItemTag
                  key={c.id}
                  title={(c.name || c.componentTypeName) + (c.children.length > 0 ? ` (${c.children.length})` : '')}
                  tagType='component'
                />
              ))}
        </Transition> */}
      </DrawerListContent>

      {show && (
        <ComponentsCreate
          site={props.site}
          asset={props.asset}
          onComplete={(component, changed, hide) => {
            // const p = changed ? props.refetchAsset() : Promise.resolve()
            // p.then(() => {
            //   if (hide) {
            //     setShow(false)
            //   }
            // })

            if (!changed) {
              if (hide) {
                setShow(false)
              }
              return
            }

            const newSite = { ...site.site }
            newSite.surveys = [...newSite.surveys]
            const activeSurveyIndex = newSite.surveys.findIndex((s) => s.id === view.surveyID)
            newSite.surveys[activeSurveyIndex] = { ...newSite.surveys[activeSurveyIndex] }
            newSite.surveys[activeSurveyIndex].components = [
              ...newSite.surveys[activeSurveyIndex].components,
              component,
            ]

            site.setSite(newSite)

            if (hide) {
              setShow(false)
            }
          }}
        />
      )}
      {componentState.componentToUpdate && (
        <ComponentsEdit
          asset={site.site.assets.find((a) => a.id === componentState.componentToUpdate.assetID)}
          component={componentState.componentToUpdate}
          onComplete={(changed) => {
            const p = changed ? props.refetchAsset() : Promise.resolve()
            p.then(() => {
              setShow(false)
              componentState.setComponentToUpdate(undefined)
            })
          }}
        />
      )}
      {componentState.componentToDelete && (
        <ComponentRemove
          component={componentState.componentToDelete}
          refetch={() => {
            return site.refetch().then(() => {
              if (components.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            componentState.setComponentToDelete(undefined)
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.Components && <ComponentFullList />}
    </>
  )
}

function getAverageCondition(score: number): string {
  if (score >= 4.5) {
    return 'Excellent'
  } else if (score > 4) {
    return 'Good to Excellent'
  } else if (score > 3.8) {
    return 'Good'
  } else if (score >= 3.2) {
    return 'Average to Good'
  } else if (score > 2.8) {
    return 'Average'
  } else if (score >= 2.2) {
    return 'Poor to Average'
  } else if (score > 1.8) {
    return 'Poor'
  } else if (score >= 1.4) {
    return 'Very Poor to Poor'
  } else {
    return 'Very Poor/End of Life'
  }
}
