query WeatherSummaryForSites {
  weatherSummaryForSites {
    siteID
    category
    icon
    temp
    probOfPrecipitation
    rainMM
    gustsKMH
  }
}
