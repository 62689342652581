import { useEffect } from 'react'
import { MarkerIconType, useAppState } from '~/state'
import { IssueItem, IssueItemWithPrevious } from './types'

export const useIssueMarkers = (issues: Array<IssueItem | IssueItemWithPrevious>) => {
  const { map } = useAppState()

  // Issue markers.
  useEffect(() => {
    if (!issues) {
      return
    }

    const markers = issues.map((issue) => {
      const i = (issue as IssueItemWithPrevious)?.currentIssue || issue as IssueItem
      return map.addMarker(
        i.id,
        [i.location?.longitude || 0, i.location?.latitude || 0, i.location?.altitude || 0],
        undefined,
        null,
        16,
        '#FFF',
        i.riskType === 'Low'
          ? MarkerIconType.IssueLow
          : i.riskType === 'Medium'
          ? MarkerIconType.IssueMedium
          : i.riskType === 'High'
          ? MarkerIconType.IssueHigh
          : MarkerIconType.IssueLow,
        undefined,
        new Cesium.NearFarScalar(0, 0.75, 0.01, 0.75)
      )
    })

    return () => {
      markers.forEach((m) => map.removeMarker(m))
    }
  }, [issues])
}
