query annotationsBySite($input: AnnotationGetBySiteRequestT!) {
  annotationsBySite(input: $input) {
    id
    name
    points {
      latitude
      longitude
      altitude
    }
    measurements
    type
    orgID
    siteID
    surveyID
    assetID
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}