import dayjs, { Dayjs } from 'dayjs'
import { MouseEvent } from 'react'

export function getOffsetX(months: Dayjs[], date: number | Dayjs, entrySize: number) {
  const start = typeof date === 'number' ? dayjs(date / 1000 / 1000) : date
  const startOfMonth = start.startOf('month')
  const totalDays = start.daysInMonth()
  const day = start.diff(startOfMonth, 'days')
  const index = months.findIndex((m) => m.isSame(startOfMonth))
  const left = index * entrySize + (day / totalDays) * entrySize
  return left
}

export function getDateFromClick(months: Dayjs[], e: MouseEvent, x: number, entrySize: number) {
  //const rect = e.currentTarget.getBoundingClientRect()
  //const x = e.clientX - rect.left - offset

  const totalWidth = months.length * entrySize //e.currentTarget.scrollWidth
  const scratch = (x / totalWidth) * months.length
  const monthIndex = Math.floor(scratch)
  if(monthIndex < 0 || monthIndex > months.length-1) {
    return undefined
  }
  const dayIndex = scratch - monthIndex
  const month = months[monthIndex].startOf('month')
  const date = month.add(Math.floor(dayIndex * month.daysInMonth()), 'days')
  return date
}
