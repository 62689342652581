import ASSETI_LOGO from '../images/asseti-logo-light-horizontal.webp'
import { AuthTerms } from './auth-terms'
import { Link } from 'react-router-dom'
import React from 'react'

export enum AuthLayoutHeadingType {
  SIGN_UP,
  SIGN_IN,
  SIGN_IN_OR_SIGN_UP,
  NONE,
}

interface AuthLayoutProps {
  children?: any
  headingType: AuthLayoutHeadingType
}

export const AuthLayout = (props: AuthLayoutProps) => {
  return (
    <div className='auth-layout'>
      <div className='auth-layout-logo-container mt-5'>
        <Link to='/'>
          <img width='128' height='32' src={ASSETI_LOGO} alt='Asseti Logo' title='Asseti Logo' loading='eager' />
        </Link>
      </div>
      <div className='auth-layout-card'>{props.children}</div>
      <div className='mb-5'></div>
      <div className='container auth-layout-body'>
        <div className='row justify-content-sm-center'>
          <div className='col-sm-6 text-center'>
            <AuthTerms />
          </div>
        </div>
      </div>
    </div>
  )
}
