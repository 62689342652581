import React, { useState } from 'react'
import { classes } from '../helpers/classes'

interface ActionHoverProps {
  canEdit?: boolean
  canRemove?: boolean
  canArchive?: boolean
  onClickEdit?: () => void
  onClickDelete?: () => void
  onClickArchive?: () => void
  withContainer?: boolean
}

export const ActionHover = (props: ActionHoverProps) => {
  const canEdit = props.canEdit !== false
  const canRemove = props.canRemove !== false
  const canArchive = props.canArchive === true
  const mustShowActions = canEdit || canRemove
  const [actionsHovered, setActionsHovered] = useState(false)

  if (!mustShowActions) {
    return null
  }

  return (
    <div
      className={classes({
        'item-tag-actions-container': true,
        'with-container': props.withContainer,
      })}
      onMouseEnter={() => {
        setActionsHovered(true)
      }}
      onMouseLeave={() => {
        setActionsHovered(false)
      }}
    >
      {!actionsHovered && <i className='material-icons'>more_horiz</i>}
      <div
        className={classes({
          'item-tag-actions-container-content': true,
          hovered: actionsHovered,
        })}
      >
        {canRemove && (
          <i
            title='Remove'
            className='material-icons item-tag-delete'
            onClick={(e) => {
              e.preventDefault()
              if (props.onClickDelete) {
                props.onClickDelete()
              }
            }}
          >
            delete
          </i>
        )}
        {canArchive && (
          <i
            title='Archive'
            className='material-icons item-tag-delete'
            onClick={(e) => {
              e.preventDefault()
              if (props.onClickArchive) {
                props.onClickArchive()
              }
            }}
          >
            archive
          </i>
        )}
        {canEdit && (
          <i
            title='Update'
            className='material-icons item-tag-edit'
            onClick={(e) => {
              e.preventDefault()
              if (props.onClickEdit) {
                props.onClickEdit()
              }
            }}
          >
            edit
          </i>
        )}
      </div>
    </div>
  )
}
