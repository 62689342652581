import React from 'react'
import { Select, SelectOption } from '~/components'
import { RiskType } from '~/models'

interface RiskTypeSelectProps {
  onChange: (r: RiskType) => void
  selectedValue: RiskType
}

export type RiskTypeSelectItem = {
  id: string
  value: RiskType
}

export const RISK_TYPE_OPTIONS: Array<SelectOption<RiskTypeSelectItem>> = [
  {
    id: RiskType.Low + '',
    name: 'Low risk',
    value: {
      id: RiskType.Low + '',
      value: RiskType.Low,
    },
    items: [],
  },
  {
    id: RiskType.Medium + '',
    name: 'Medium risk',
    value: {
      id: RiskType.Medium + '',
      value: RiskType.Medium,
    },
    items: [],
  },
  {
    id: RiskType.High + '',
    name: 'High risk',
    value: {
      id: RiskType.High + '',
      value: RiskType.High,
    },
    items: [],
  },
]

export const RiskTypeSelect = (props: RiskTypeSelectProps) => {
  return (
    <Select<RiskTypeSelectItem>
      id='riskType'
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Risk Type'
      placeholder={''}
      onChange={(s) => {
        props.onChange(s.value)
      }}
      options={RISK_TYPE_OPTIONS}
    />
  )
}
