import { Config } from '~/config'
import { MeasurementSystem } from '~/models'

export function getDistance(geodesic: Cesium.EllipsoidGeodesic, point1: Cesium.Cartesian3, point2: Cesium.Cartesian3) {
  const c1 = Cesium.Cartographic.fromCartesian(point1)
  const c2 = Cesium.Cartographic.fromCartesian(point2)
  geodesic.setEndPoints(c1, c2)
  const horizontalMeters = geodesic.surfaceDistance
  const heights = [c1.height, c2.height]
  const verticalMeters = Math.max(...heights) - Math.min(...heights)
  const meters = Math.sqrt(horizontalMeters * horizontalMeters + verticalMeters * verticalMeters)
  return meters
}

export function formatDistance(meters: number, decimals = 2) {
  if (Config.DefaultMeasurementSystem === MeasurementSystem.Imperial) {
    const feet = meters * 3.280839895
    if (feet >= 5280) {
      return (feet / 5280).toFixed(2) + 'mi'
    }
    return meters.toFixed(decimals) + 'ft'
  }

  if (meters >= 1000) {
    return (meters / 1000).toFixed(1) + 'km'
  }
  return meters.toFixed(decimals) + 'm'
}

export function formatArea(squareMeters: number, commas = false, measurementSystem: MeasurementSystem) {
  if (measurementSystem === MeasurementSystem.Imperial) {
    const squareFeet = squareMeters * 3.280839895 * 3.280839895

    if (commas) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'standard',
        compactDisplay: 'short',
      })
        .format(Math.round(squareFeet))
        .replace('$', '')
        .replace('K', 'k')
        .replace('.00', '')
    }

    return squareFeet.toFixed(2) + 'ft²'
  }

  if (commas) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'standard',
      compactDisplay: 'short',
    })
      .format(Math.round(squareMeters))
      .replace('$', '')
      .replace('K', 'k')
      .replace('.00', '')
  }

  if (squareMeters >= 1000) {
    return Math.round(squareMeters) + 'm²'
  }
  return squareMeters.toFixed(2) + 'm²'
}

export function formatVolume(meters: number) {
  if (Config.DefaultMeasurementSystem === MeasurementSystem.Imperial) {
    const cubicFeet = meters * 3.280839895 * 3.280839895 * 3.280839895
    return cubicFeet.toFixed(2) + 'ft³'
  }

  if (meters >= 1000) {
    return meters.toFixed(1) + 'm³'
  }
  return meters.toFixed(2) + 'm³'
}

export const handleAreaFormat = (number: number) => {
  if (Config.DefaultMeasurementSystem === MeasurementSystem.Imperial) {
    const squareFeet = number * 3.280839895 * 3.280839895
    return (
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'compact',
        compactDisplay: 'short',
      })
        .format(squareFeet)
        .replace('$', '')
        .replace('K', 'k') + ' ft²'
    )
  }

  if (number >= 1000000) {
    return (
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'compact',
        compactDisplay: 'short',
      })
        .format(number / 1000000)
        .replace('$', '')
        .replace('K', 'k') + ' km²'
    )
  }
  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number)
      .replace('$', '')
      .replace('K', 'k') + ' m²'
  )
}

export const handleDistanceFormat = (number: number) => {
  if (Config.DefaultMeasurementSystem === MeasurementSystem.Imperial) {
    const feet = number * 3.280839895
    return (
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'compact',
        compactDisplay: 'short',
      })
        .format(feet)
        .replace('$', '') + ' ft'
    )
  }

  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    })
      .format(number)
      .replace('$', '') + ' m'
  )
}
