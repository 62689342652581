import { LeftClickHandler, LeftClickPosition, useCesium } from '~/state'
import { pickFromPositionAll2, PickFromPositionResult } from '../measure/utils'

export type Map = ReturnType<typeof useCesium>

export const PolygonColorRed = '#BA160C'
export const PolygonColorOrange = '#B97F0B'

export abstract class PolygonRenderer3D {
  protected _map: Map
  protected _color: string
  protected _heightOffset: number
  protected _toExclude: any[]

  private _onLeftClick: Cesium.ScreenSpaceEventHandler
  private _onLeftUp: LeftClickHandler
  private _onLeftDown: LeftClickHandler
  private _onMiddleClick: LeftClickHandler
  private _onMouseMove: Cesium.ScreenSpaceEventHandler
  private _onKeyDown: (e: KeyboardEvent) => void

  constructor(map: Map, color: string, disableDepthTesting: boolean) {
    this._map = map
    this._color = color
    this._heightOffset = 0.075
    this._toExclude = []

    this._onKeyDown = this.onKeydown.bind(this)
    this._onLeftClick = this.onLeftClick.bind(this)
    this._onLeftUp = this.onLeftUp.bind(this)
    this._onLeftDown = this.onLeftDown.bind(this)
    this._onMiddleClick = this.onMiddleClick.bind(this)
    this._onMouseMove = this.onMouseMove.bind(this)

    window.addEventListener('keydown', this._onKeyDown, false)
    map.addLeftDownHandler(this._onLeftDown)
    map.addLeftUpHandler(this._onLeftUp)
    map.addMiddleClickHandler(this._onMiddleClick)

    /* eslint-disable */
    const _this = this
    /* eslint-enable */
    const scene = map.viewer.scene
    this._onLeftClick = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    this._onLeftClick.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
        return
      }
      const picked = pickFromPositionAll2(map.viewer, click.position, this._toExclude, 0.025)
      if (picked.length === 0) {
        return
      }

      _this.onLeftClick(picked)
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    this._onMouseMove = new Cesium.ScreenSpaceEventHandler(scene.canvas)

    if (disableDepthTesting) {
      let debounce: NodeJS.Timeout
      this._onMouseMove.setInputAction((move: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
        if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
          return
        }

        if (debounce) {
          clearTimeout(debounce)
        }

        debounce = setTimeout(() => {
          const picked = pickFromPositionAll2(map.viewer, move.endPosition, this._toExclude, 0.025)
          if (picked.length === 0) {
            return
          }

          _this.onMouseMove(picked)
        }, 4)
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    }
  }

  public destroy() {
    document.body.style.cursor = 'default'
    window.removeEventListener('keydown', this._onKeyDown)
    if(this._onLeftClick) {
      this._onLeftClick.destroy()
      this._onLeftClick = undefined
    }
    if(this._onMouseMove) {
      this._onMouseMove.destroy()
      this._onMouseMove = undefined
    }
    this._map.removeLeftDownHandler(this._onLeftDown)
    this._map.removeLeftUpHandler(this._onLeftUp)
    this._map.removeMiddleClickHandler(this._onMiddleClick)
    // ;(Cesium.PolylineCollection.prototype as any).update = this._oldPolylineUpdate
    this._map.viewer.scene.screenSpaceCameraController.enableTranslate = true
  }

  abstract onKeydown(e: KeyboardEvent): void
  abstract onLeftClick(pos: PickFromPositionResult[]): void
  abstract onLeftDown(pos: LeftClickPosition): void
  abstract onLeftUp(pos: LeftClickPosition): void
  abstract onMouseMove(pos: PickFromPositionResult[]): void
  abstract onMiddleClick(pos: LeftClickPosition): void

  protected createPoint(pos: Cesium.Cartesian3, pointIndex: number, pointSize = 12.0, customID?: string) {
    return {
      show: true,
      position: pos,
      pixelSize: pointSize,
      color: Cesium.Color.fromCssColorString(this._color),
      outlineColor: Cesium.Color.TRANSPARENT,
      outlineWidth: 0.0,
      id: customID || 'point-' + pointIndex,
      disableDepthTestDistance: 100000,
    }
  }

  protected createPoint2(
    pos: Cesium.Cartesian3,
    pointIndex: number,
    customID?: string
  ): Cesium.Entity.ConstructorOptions {
    return {
      show: true,
      position: pos,
      id: customID || 'point-' + pointIndex,
      point: {
        pixelSize: 12.0,
        color: Cesium.Color.fromCssColorString(this._color),
        outlineColor: Cesium.Color.TRANSPARENT,
        outlineWidth: 0.0,
        disableDepthTestDistance: 100000,
      },
    }
  }

  protected createMiddlePoint2(
    pos: Cesium.Cartesian3,
    pointIndex: number,
    customID?: string
  ): Cesium.Entity.ConstructorOptions {
    return {
      show: true,
      position: pos,
      id: customID || 'middle-point-' + pointIndex,
      point: {
        pixelSize: 6.0,
        color: Cesium.Color.WHITE,
        outlineColor: Cesium.Color.fromCssColorString(this._color),
        outlineWidth: 4,
        disableDepthTestDistance: 100000,
      },
    }
  }

  protected createMiddlePoint(pos: Cesium.Cartesian3, pointIndex: number) {
    return {
      show: true,
      position: pos,
      pixelSize: 4.0,
      color: Cesium.Color.WHITE,
      outlineColor: Cesium.Color.fromCssColorString(this._color),
      outlineWidth: 4,
      id: 'middle-point-' + pointIndex,
      // disableDepthTestDistance: 10,
    }
  }

  protected createPolylineConstructor(
    positions: Cesium.Property | Cesium.Cartesian3[],
    dashed = false,
    enableClassification = true
  ): Cesium.PolylineGraphics.ConstructorOptions {
    const c: Cesium.PolylineGraphics.ConstructorOptions = {
      show: true,
      positions,
      material: dashed
        ? new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            gapColor: Cesium.Color.TRANSPARENT,
            dashLength: 10,
          })
        : new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color).withAlpha(0.7),
            outlineColor: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
            outlineWidth: 1,
          }),
      width: dashed ? 3 : 3,
    }

    if (enableClassification) {
      c.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
      c.clampToGround = true
    }

    return c
  }

  protected createPolygonConstructor(
    positions: Cesium.CallbackProperty | Cesium.PolygonHierarchy,
    dashed = false,
    enableClassification = true
  ): Cesium.PolygonGraphics.ConstructorOptions {
    const c: Cesium.PolygonGraphics.ConstructorOptions = {
      show: true,
      hierarchy: positions,
      material: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
    }
    if (enableClassification) {
      c.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
      c.outline = true
      c.outlineColor = Cesium.Color.fromCssColorString(this._color)
      c.outlineWidth = 3
    }
    return c
  }

  protected createPolylineConstructor2(positions: Cesium.Cartesian3[], dashed = false): Cesium.Primitive {
    const material = Cesium.Material.fromType('Color')
    material.uniforms.color = Cesium.Color.fromCssColorString(this._color).withAlpha(0.5)
    return new Cesium.Primitive({
      allowPicking: false,
      asynchronous: true,
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.PolylineGeometry({
          positions,
          arcType: Cesium.ArcType.NONE,
          width: 3,
        }),
      }),
      appearance: new Cesium.PolylineMaterialAppearance({
        material: material,
      }),
    })
  }
}
