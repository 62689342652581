import React from 'react'
import { AuthLayout, AuthLayoutHeadingType } from '../../auth/auth-layout'
import { AuthButton } from '../buttons/auth-button'

export const ErrorPage = () => {

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.NONE}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ minWidth: 300, maxWidth: 450 }} className='subheader-dark mt-5 mb-5 p-5'>
          Something went wrong.
          <br />
          <br />
          Please reload the page or contact support if the error persists.
        </div>
        <AuthButton
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload Page
        </AuthButton>
      </div>
    </AuthLayout>
  )
}
