import React from 'react'
import { FormikTouched } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { AuthInput } from 'components'
import { SignUpFormType } from 'instant-assess/instant-assess'

type Props = {
  onMsg: (msg: Msg) => void
  values: SignUpFormType
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  touched: FormikTouched<SignUpFormType>
  errors: FormikErrors<SignUpFormType>
}

type Msg = { type: 'final_step_clicked' } | { type: 'back_clicked' }

export const PersonalInfo = ({ onMsg, values, handleChange, handleBlur, touched, errors }: Props) => {
  return (
    <>
      <h5 className='instant-assess-layout-header text-left mb-4 d-flex justify-content-between align-items-center'>
        Let's get to know you better
        <i
          style={{ cursor: 'pointer' }}
          className='fa-solid fa-chevron-left'
          onClick={() => {
            onMsg({ type: 'back_clicked' })
          }}
        ></i>
      </h5>

      <AuthInput
        id='firstName'
        label='First Name'
        type='firstName'
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.firstName ? errors.firstName : ''}
        error={touched.firstName && Boolean(errors.firstName)}
      />
      <AuthInput
        id='lastName'
        label='Last Name'
        type='lastName'
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.lastName ? errors.lastName : ''}
        error={touched.lastName && Boolean(errors.lastName)}
      />
      <AuthInput
        id='email'
        label='Email'
        type='email'
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email ? errors.email : ''}
        error={touched.email && Boolean(errors.email)}
      />
      <AuthInput
        id='password'
        label='Password'
        type='password'
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.password ? errors.password : ''}
        error={touched.password && Boolean(errors.password)}
      />
      <hr />
      <button
        className='btn btn-instant-assess'
        type='button'
        disabled={hasErrors(errors)}
        onClick={() => {
          onMsg({ type: 'final_step_clicked' })
        }}
      >
        Final step
      </button>
    </>
  )
}

const hasErrors = (errors: FormikErrors<SignUpFormType>): boolean => {
  return !!errors.email || !!errors.firstName || !!errors.lastName || !!errors.password
}
