import React from 'react'

export const CompassSVG = () => {
    return <svg version='1.1' width='446.64886' height='463.0625' viewBox='0 0 446.64886 463.0625'>
    <g
      id='icon'
      style={toCSSObject(
        'opacity:1;fill:none;fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
      )}
      transform='matrix(3.8954834,0,0,3.8954218,56.545299,20.071204)'
    >
      <path
        d='m 69.188,32.862 -7.322,11.293 c -0.232,0.358 -0.662,0.536 -1.079,0.447 l -3.273,-0.698 c -0.397,-0.085 -0.708,-0.395 -0.793,-0.793 l -0.698,-3.273 c -0.089,-0.418 0.089,-0.847 0.447,-1.079 l 11.293,-7.322 c 0.933,-0.605 2.03,0.492 1.425,1.425 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path8'
      />
      <path
        id='path10'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:3.89;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        transform='matrix(0.25706941,0,0,0.25706941,0.49985718,0.49985718)'
        d='m 173.10547,82.117188 c -1.73446,0 -3.46867,1.057659 -3.91797,3.171874 l -18.58203,87.109378 c -0.28397,1.33038 0.12511,2.71104 1.08594,3.67187 l 18.58203,18.58203 c 1.56378,1.56378 4.10419,1.56378 5.66797,0 l 18.58203,-18.58203 c 0.96083,-0.96083 1.3699,-2.34538 1.08594,-3.67187 L 177.02734,85.289062 c -0.45124,-2.114214 -2.18742,-3.171874 -3.92187,-3.171874 z'
      />
      <path
        d='m 22.237,31.436 11.293,7.322 c 0.358,0.232 0.536,0.662 0.447,1.079 l -0.698,3.273 c -0.085,0.397 -0.395,0.708 -0.793,0.793 l -3.273,0.698 c -0.418,0.089 -0.847,-0.089 -1.08,-0.447 L 20.811,32.86 c -0.604,-0.932 0.493,-2.029 1.426,-1.424 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path12'
      />
      <path
        d='m 35.138,50.119 4.777,4.777 c 0.402,0.402 0.402,1.055 0,1.457 l -4.777,4.777 c -0.247,0.247 -0.602,0.352 -0.944,0.279 L 27.643,60.011 C 27.635,60.009 27.626,60.007 27.618,60.006 L 11.802,56.632 c -1.087,-0.232 -1.087,-1.783 0,-2.015 L 34.195,49.84 c 0.341,-0.073 0.696,0.032 0.943,0.279 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path14'
      />
      <path
        d='m 20.812,78.387 7.321,-11.293 c 0.232,-0.358 0.662,-0.536 1.08,-0.447 l 3.274,0.698 c 0.397,0.085 0.708,0.395 0.793,0.793 l 0.698,3.273 c 0.089,0.418 -0.089,0.847 -0.447,1.079 l -11.293,7.322 c -0.934,0.605 -2.031,-0.492 -1.426,-1.425 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path16'
      />
      <path
        d='M 43.992,88.823 39.215,66.43 c -0.073,-0.341 0.032,-0.697 0.279,-0.944 l 4.777,-4.777 c 0.402,-0.402 1.055,-0.402 1.457,0 l 4.777,4.777 c 0.247,0.247 0.352,0.602 0.279,0.944 l -4.777,22.393 c -0.231,1.087 -1.783,1.087 -2.015,0 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path18'
      />
      <path
        d='M 67.763,79.812 56.47,72.49 c -0.358,-0.232 -0.536,-0.662 -0.447,-1.079 l 0.698,-3.273 c 0.085,-0.397 0.395,-0.708 0.793,-0.793 l 3.274,-0.698 c 0.418,-0.089 0.847,0.089 1.08,0.447 l 7.321,11.293 c 0.604,0.933 -0.493,2.03 -1.426,1.425 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path20'
      />
      <path
        d='m 62.384,60.006 c -0.013,0.002 -0.025,0.006 -0.037,0.008 l -6.541,1.395 c -0.341,0.073 -0.697,-0.032 -0.944,-0.279 l -4.777,-4.777 c -0.402,-0.402 -0.402,-1.055 0,-1.457 l 4.777,-4.777 c 0.247,-0.247 0.602,-0.352 0.944,-0.279 l 22.393,4.777 c 1.087,0.232 1.087,1.783 0,2.015 z'
        style={toCSSObject(
          'fill-rule:nonzero;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none'
        )}
        strokeLinecap='round'
        id='path22'
      />
    </g>
    <text
      xmlSpace='preserve'
      style={toCSSObject(
        'font-style:normal;font-variant:normal;font-weight:900;font-stretch:normal;font-size:128px;line-height:1.25;font-family:Roboto;stroke:none'
      )}
      x='186.96899'
      y='91'
      id='text1586'
    >
      <tspan id='tspan1584' x='186.96899' y='91'>
        N
      </tspan>
    </text>
    <text
      xmlSpace='preserve'
      style={toCSSObject(
        'font-style:normal;font-variant:normal;font-weight:900;font-stretch:normal;font-size:96px;line-height:1.25;font-family:Roboto;stroke:none'
      )}
      x='201.93799'
      y='462.125'
      id='text1586-3'
    >
      <tspan x='201.93799' y='462.125' id='tspan13773'>
        S
      </tspan>
    </text>
    <text
      xmlSpace='preserve'
      style={toCSSObject(
        'font-style:normal;font-variant:normal;font-weight:900;font-stretch:normal;font-size:96.0003px;line-height:1.25;font-family:Roboto;stroke:none;stroke-width:1'
      )}
      x='-0.98437804'
      y='270.87589'
      id='text1586-3-7'
    >
      <tspan x='-0.98437804' y='270.87589' id='tspan13773-5' style={{ strokeWidth: 1 }}>
        W
      </tspan>
    </text>
    <text
      xmlSpace='preserve'
      style={toCSSObject(
        'font-style:normal;font-variant:normal;font-weight:900;font-stretch:normal;font-size:96px;line-height:1.25;font-family:Roboto;stroke:none'
      )}
      x='394.38324'
      y='270.875'
      id='text1586-3-7-3'
    >
      <tspan x='394.38324' y='270.875' id='tspan13773-5-5'>
        E
      </tspan>
    </text>
  </svg>
}

function toCSSObject(str: string) {
  const obj: any = {}
  str.split(';').forEach((v) => {
    const parts = v.split(':')
    obj[
      parts[0].replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      })
    ] = parts[1]
  })
  return obj
}