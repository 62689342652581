import React, { MouseEvent } from 'react'

function buildFileSelector(singleOnly: boolean, accept?: string) {
  const fileSelector = document.createElement('input')
  fileSelector.setAttribute('type', 'file')
  if (!singleOnly) {
    fileSelector.setAttribute('multiple', 'multiple')
  }

  if (accept) {
    fileSelector.setAttribute('accept', accept)
  }

  return fileSelector
}

interface FileSelectorProps {
  filesSelected: (files: File[]) => void
  singleFile?: boolean
  text?: string
  accept?: string
  className?: string
}

export class FileSelector extends React.PureComponent<FileSelectorProps> {
  fileSelector: HTMLInputElement

  componentDidMount() {
    this.fileSelector = buildFileSelector(this.props.singleFile === true, this.props.accept)
    this.fileSelector.onchange = () => {
      const files = []
      for (let i = 0; i < this.fileSelector.files.length; i++) {
        files.push(this.fileSelector.files.item(i))
      }
      this.props.filesSelected(files)
    }
  }

  handleFileSelect = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    this.fileSelector.click()
  }

  render() {
    return (
      <button className={this.props.className || 'btn'} onClick={this.handleFileSelect}>
        {this.props.text || 'Browse'}
      </button>
    )
  }
}
