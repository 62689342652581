import { string, object, ref } from '~/components'

import { AuthButton, AuthInput, useForm, useLocation, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import React, { useState } from 'react'
import { ResetPasswordMutation, ResetPasswordRequestT } from '~/models/gql-types'

import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import mutation from './mutation-reset-password.gql'

export const AuthUpdatePassword = () => {
  const location = useLocation()
  const token = new URLSearchParams(window.location.search).get('token')
  const [updateComplete, setUpdateComplete] = useState(false)

  if (!token) {
    location.setLocation('/404')
    return
  }

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    ResetPasswordMutation,
    ResetPasswordRequestT & { confirmPassword: string },
    ResetPasswordRequestT
  >({
    initialValues: {
      token,
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: object().shape({
      newPassword: string()
        .required('Required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\`])(?=.{8,})/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'
        ),
      confirmPassword: string()
        .required('Required')
        .oneOf([ref('newPassword'), null], 'Passwords must match'),
    }),
    mutation,
    mapInput: (input): ResetPasswordRequestT => {
      return {
        token: input.token,
        newPassword: input.newPassword,
      }
    },
    onSuccess: (result: ResetPasswordMutation) => {
      if (result.resetPassword.success) {
        getTracking().event({
          category: 'Form',
          action: `User updated their password`,
          label: 'Auth',
        })
        setUpdateComplete(true)
      }
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Update Your Password' />
      {!updateComplete && <>
        <div className='subheader-dark mt-5 mb-5'>Update Your Password</div>
        {error && <AuthError className='alert alert-warning mb-4' error={error} />}
        <form autoComplete='off' className='auth-form-container mb-3'  onSubmit={handleSubmit}>
        <p className='auth-description-text mb-4'>Enter your new password below.</p>
          <AuthInput
            id='newPassword'
            label='Password'
            type='password'
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.newPassword ? errors.newPassword : ''}
            error={touched.newPassword && Boolean(errors.newPassword)}
          />
          <AuthInput
            id='confirmPassword'
            label='Confirm Password'
            type='password'
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.confirmPassword ? errors.confirmPassword : ''}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          />
          <AuthButton disabled={isSubmitting}>Submit</AuthButton>
        </form>
        <AuthLinks>
            <AuthLink to='/signin' className='mb-3'>
              Back to Sign In
            </AuthLink>
          </AuthLinks>
      </>}
      {updateComplete && <div className='auth-form-container mb-3' >
      <div className='subheader-dark mt-5 mb-5'>
          Password reset
        </div>
        <p className='auth-description-text mb-4'>
          Your password has been reset and a confirmation email will be sent shortly.
          Click the button below to sign in with your new password.
        </p>
        <AuthButton onClick={() => {
          location.setLocation('/signin')
        }}>Sign In</AuthButton>
        <br />
        <br />
      </div>}
    </AuthLayout>
  )
}
