import React from 'react'
import { string, object, ref, getWebsitePath } from '~/components'
import { AcceptInviteMutation, UserInvitationsAcceptRequestT } from '~/models'
import { AuthButton, AuthInput, useForm } from '../components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { useLocation, getTracking } from '~/components'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import { useUser } from '~/base'
import ACCEPT_INVITE_MUTATION from './mutation-accept-invite.gql'

interface AuthAcceptInviteNewUserProps {
  token: string
}

export const AuthAcceptInviteNewUser = (props: AuthAcceptInviteNewUserProps) => {
  const location = useLocation()
  const user = useUser()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    AcceptInviteMutation,
    UserInvitationsAcceptRequestT & { confirmPassword: string }
  >({
    initialValues: {
      token: props.token,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      jobTitle: '',
      marketingConsent: true,
    },
    validationSchema: object({
      firstName: string().max(15, 'Must be 15 characters or less').required('Required'),
      lastName: string().max(20, 'Must be 20 characters or less').required('Required'),
      password: string()
        .required('Required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\`])(?=.{8,})/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'
        ),
      confirmPassword: string()
        .required('Required')
        .oneOf([ref('password'), null], 'Passwords must match'),
      jobTitle: string().max(15, 'Must be 15 characters or less').required('Required'),
    }),
    mutation: ACCEPT_INVITE_MUTATION,
    mapInput: (input) => {
      const toRet = { ...input }
      delete toRet['confirmPassword']
      return toRet
    },
    onSuccess: (result: AcceptInviteMutation) => {
      user.setSignIn(result.acceptInvite)
      getTracking().event({
        category: 'Form',
        action: `User accepted invite`,
        label: 'Auth',
      })
      location.setLocation('/')
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Accept Team Invite' />
      <div className='subheader-dark mt-5 mb-5'>Team Invitation Confirmation</div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
      <form autoComplete='off' className='auth-form-container mb-3' onSubmit={handleSubmit}>
        Invite people to Asseti
        <div className='row'>
          <div className='col'>
            <AuthInput
              id='firstName'
              label='First Name'
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.firstName ? errors.firstName : ''}
              error={touched.firstName && Boolean(errors.firstName)}
              halfWidth
            />
          </div>
          <div className='col'>
            <AuthInput
              id='lastName'
              label='Last Name'
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.lastName ? errors.lastName : ''}
              error={touched.lastName && Boolean(errors.lastName)}
              halfWidth
            />
          </div>
        </div>
        <AuthInput
          id='password'
          label='Password'
          type='password'
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
        />
        <AuthInput
          id='confirmPassword'
          label='Confirm Password'
          type='password'
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirmPassword ? errors.confirmPassword : ''}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        />
        <AuthInput
          id='jobTitle'
          label='Job Title'
          value={values.jobTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.jobTitle ? errors.jobTitle : ''}
          error={touched.jobTitle && Boolean(errors.jobTitle)}
        />
        <AuthButton disabled={isSubmitting}>Sign Up</AuthButton>
        <p className='auth-links body2 mb-4'>
          By clicking the "Sign Up" button, you are creating a Asseti account, and you agree to Asseti's
          <br />
          &nbsp;
          <a
            style={{ overflowWrap: 'anywhere' }}
            href={`${getWebsitePath()}/terms`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Terms of Use
          </a>
          &nbsp;and&nbsp;
          <a
            href={`${getWebsitePath()}/privacy-policy`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </AuthLayout>
  )
}
