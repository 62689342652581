import React, { useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { DrawerListContentShort } from '~/components'
import { useAppState } from '~/state'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { AnalysisFormCreate } from './analysis-form-create'
import { AnalysisFullList } from './analysis-list-full'

export const AnalysisDrawerList = () => {
  const { site, drawer, analysis } = useAppState()
  const user = useUser()

  if(!user.org.canAccessDeflections) {
    return null
  }

  const isDemoSite = site.site?.isDemoSite
  const canRead = !isDemoSite && user.hasPermission(ClientPermission.AnalysisRead)

  if (!canRead) {
    return null
  }

  const canAdd = !isDemoSite && user.hasPermission(ClientPermission.AnalysisCreate)
  const [adding, setAdding] = useState(false)

  return (
    <>
      <DrawerListContentShort
        canAdd={canAdd}
        loading={false}
        numEntities={analysis.analysis.length}
        panelType={ExpandedPanel.Analysis}
        plural='analyses'
        singular='analysis'
        title='Analysis'
        onAdd={() => {
          drawer.closeExpandedPanel()
          setAdding(true)
        }}
        onShow={() => {
          if (analysis.analysis.length === 0) {
            drawer.closeExpandedPanel()
            setAdding(true)
          }
        }}
        emptyButCanAddText='Add an analysis'
        emptyButCantAddText='No analyses have been added'
      />

      {(adding) && (
        <AnalysisFormCreate
          onComplete={(changed, hide) => {
            changed && analysis.reload()
            hide && setAdding(false)
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.Analysis && <AnalysisFullList />}
    </>
  )
}
