import { useEffect, useState } from 'react'
import { ImagesByIdQuery, ImagesByIdQueryVariables, ImagesByModelQuery, ImagesByModelQueryVariables } from '~/models'

import { useQuery } from '~/components'
import { IssueItemWithPrevious, PhotoItem } from './types'
import { useUser } from '~/base'
import { useAppState, ProjectionType } from '~/state'
import QUERY_IMAGES_BY_MODEL from '~/sites/query-images-by-model.gql'
import QUERY_IMAGES_BY_ID from './query-images-by-id.gql'

interface IssuesFilmStripProps {
  issue: IssueItemWithPrevious
}

export const useIssuesFilmStripImagesByModel = (props: IssuesFilmStripProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [photos, setPhotos] = useState<PhotoItem[]>([])
  const [previousPhoto, setPreviousPhoto] = useState<PhotoItem>()

  const { map, site, timeline } = useAppState()
  const user = useUser()

  const imagesByModelQuery = useQuery<ImagesByModelQuery, ImagesByModelQueryVariables>(QUERY_IMAGES_BY_MODEL, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const imagesByIDQuery = useQuery<ImagesByIdQuery, ImagesByIdQueryVariables>(QUERY_IMAGES_BY_ID, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  // Get position on the model.
  useEffect(() => {
    if (!props.issue || !props.issue.previousIssue.location || map.projectionType !== ProjectionType.Projection3D) {
      setLoading(false)
      return
    }

    const modelPosition = Cesium.Cartesian3.fromDegrees(
      props.issue.previousIssue.location.longitude,
      props.issue.previousIssue.location.latitude,
      props.issue.previousIssue.location.altitude
    )

    const entities: Cesium.Entity[] = []
    let primitives: Cesium.Primitive[] = []
    const samplePositions = [Cesium.Cartographic.fromCartesian(modelPosition)]
    Cesium.sampleTerrainMostDetailed(map.viewer.terrainProvider, samplePositions).then((res) => {
      const heightOffset = res[0].height + map.imageOffsetForBulkTagging.current

      setLoading(true)
      setError(false)

      const recon = timeline.activeSurvey.reconstructions[0]
      const cesium3D = recon.cesium3D
      
      imagesByModelQuery
        .refetch({
          input: {
            longitude: Cesium.Math.toDegrees(samplePositions[0].longitude),
            latitude: Cesium.Math.toDegrees(samplePositions[0].latitude),
            altitude: Cesium.Cartographic.fromCartesian(modelPosition).height,
            terrainAltitude: heightOffset,
            reconstructionID: recon.id,
            siteID: site.site.id,
            orgID: user.org.id,
            yaw: 0,
            pitch: 0,
            roll: 0,
          },
        })
        .then((res) => {
          setLoading(false)
          const toSet = []
          const clickPosition = modelPosition

          for (const i of res.data.queryImagesByModel.images) {
            const imageOrigin = Cesium.Cartesian3.fromDegrees(
              i.longitude,
              i.latitude,
              i.altitude + cesium3D.translationZ + map.imageOffsetForBulkTagging.current
            )
            toSet.push({
              i,
              distance: Cesium.Cartesian3.distance(clickPosition, imageOrigin),
            })
          }

          const sorted = toSet
            .sort((a, b) => {
              const aDistance = Math.abs(a.distance)
              const bDistance = Math.abs(b.distance)
              return aDistance < bDistance ? -1 : 1
            })
            .map((p, idx) => {
              return {
                ...p.i,
                index: idx,
              }
            })

          setPhotos(sorted)
        })
        .catch(() => {
          setError(true)
        })
    })

    return () => {
      entities.forEach((e) => map.viewer.entities.remove(e))
      primitives.forEach((e) => map.viewer.scene.primitives.remove(e))
    }
  }, [
    props.issue?.previousIssue?.location?.longitude,
    props.issue?.previousIssue?.location?.latitude,
    props.issue?.previousIssue?.location?.altitude,
    map.projectionType,
    map.imageOffsetForBulkTagging.current,
  ])

  useEffect(() => {
    if (!props.issue || !props.issue.previousIssue.imageID || map.projectionType !== ProjectionType.Projection3D) {
      return
    }
    imagesByIDQuery.refetch({
      input: {
        ids: [props.issue.previousIssue.imageID],
      },
    }).then((res) => {
      setPreviousPhoto({
        ...res.data.queryImagesByID.images[0],
        index: 0,
      })
    })
  }, [props.issue?.previousIssue?.imageID, map.projectionType, map.imageOffsetForBulkTagging.current])

  return {
    photos,
    reset: () => {
      setPhotos(undefined)
    },
    reload: () => Promise.resolve(),
    loading,
    error,
    previousPhoto,
  }
}
