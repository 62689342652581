import { PointT } from "~/models"

export function pointInPolygon(vs: PointT[], point: PointT) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  
  const x = point.longitude, y = point.latitude
  
  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i].longitude, yi = vs[i].latitude
      const xj = vs[j].longitude, yj = vs[j].latitude
      
      const intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
  }
  
  return inside
}
