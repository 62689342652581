import React, { useState, useEffect, useRef } from 'react'
import { default as CustomSimpleBar } from 'simplebar-react'

interface SimpleBarProps {
  children: any
  onScroll?: (scrollTop: number, scrollHeight: number) => void
  onScrollRaw?: (e: Event) => void
  excludeBottom?: boolean
  ref2?: (r: any) => void
  style?: any
  ignoreMaxHeight?: boolean
  autoHide?: boolean
  setMaxHeightToParent?: boolean
}

export const SimpleBar = (props: SimpleBarProps) => {
  const ref = useRef<any>(null)
  const scrollNodeRef = React.useRef<HTMLDivElement>()
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
  const [maxHeight, setMaxHeight] = useState<number>(0)

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    ref.current.recalculate()
    if(props.setMaxHeightToParent) {
      setMaxHeight(ref.current.el.parentNode.clientHeight)
      return
    }
    let offset = ref.current.el.getBoundingClientRect().top
    if (props.excludeBottom) {
      offset += ref.current.el.getBoundingClientRect().bottom
    }
    setMaxHeight(windowHeight - offset)
  }, [windowHeight, ref.current, props.setMaxHeightToParent])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (props.ref2) {
      props.ref2(ref)
    }

  }, [ref.current])

  useEffect(() => {
    if (!scrollNodeRef.current || (!props.onScroll && !props.onScrollRaw)) {
      return
    }

    const fn = (e: Event) => {
      if (props.onScroll) {
        const target = e.target as HTMLDivElement
        props.onScroll(target.scrollTop, target.scrollHeight)
      }

      if (props.onScrollRaw) {
        props.onScrollRaw(e)
      }
    }

    scrollNodeRef.current.addEventListener('scroll', fn)

    return () => {
      if (scrollNodeRef.current) {
        scrollNodeRef.current.removeEventListener('onscroll', fn)
      }
    }
  }, [scrollNodeRef])

  const style = {
    maxHeight: props.ignoreMaxHeight ? 'unset' : maxHeight,
    ...(props.style || {}),
  }

  return (
    <CustomSimpleBar style={style} ref={ref} scrollableNodeProps={{ ref: scrollNodeRef }} autoHide={props.autoHide}>
      {props.children}
    </CustomSimpleBar>
  )
}
