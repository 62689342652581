import React, { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { pointInPolygon } from '../helpers/point-in-polygon'
import { Scheduler, Task } from './task'
import { FillColor, LabelTemplate, pickFromPosition, Point, PointAndLineColor, pointFromPosition } from './utils'
import { formatVolume } from './measurement-formats'

interface VolumeResult {
  items: Array<{
    d1: number
    d2: number
    height: number
  }>
  minHeight: number
}

export const MeasureVolume = () => {
  const { map } = useAppState()
  const [points] = useState(new Cesium.PointPrimitiveCollection())
  const [tmpPoints] = useState(new Cesium.PointPrimitiveCollection())
  const [polyLines] = useState(new Cesium.PolylineCollection())
  const [tmpPolyLines] = useState(new Cesium.PolylineCollection())
  const [labels] = useState(
    new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
  )

  useEffect(() => {
    const viewer = map.viewer
    const scene = viewer.scene
    //const geodesic = new Cesium.EllipsoidGeodesic()
    let fillPolygon: Cesium.Entity

    let isDrawing = true
    let labelIndex = 3

    scene.primitives.add(points)
    scene.primitives.add(tmpPoints)
    scene.primitives.add(polyLines)
    scene.primitives.add(tmpPolyLines)
    scene.primitives.add(labels)

    //map.viewer.scene.globe.depthTestAgainstTerrain = false
    // const oldPolylineUpdate = Cesium.PolylineCollection.prototype.update
    // ;(Cesium.PolylineCollection.prototype as any).update = function (frameState: any) {
    //   const oldMorphTime = frameState.morphTime
    //   frameState.morphTime = 0.0
    //   oldPolylineUpdate.call(this, frameState)
    //   frameState.morphTime = oldMorphTime
    // }

    setTimeout(() => {
      for (let i = 0; i < 100; i++) {
        polyLines.add({
          show: false,
          positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
          width: 1,
          material: new Cesium.Material({
            fabric: {
              type: 'Color',
              uniforms: {
                color: PointAndLineColor,
              },
            },
          }),
          clampToGround: true,
          classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
        })

        labels.add({
          ...LabelTemplate,
          position: new Cesium.Cartesian3(0, 0, 0),
        })
      }

      tmpPolyLines.add({
        show: false,
        positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
        width: 1,
        material: new Cesium.Material({
          fabric: {
            type: 'PolylineDash',
            uniforms: {
              color: PointAndLineColor,
            },
          },
        }),
        clampToGround: true,
        classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
      })

      const cb = new Cesium.CallbackProperty(() => {
        if (points.length < 2) {
          return new Cesium.PolygonHierarchy([])
        }

        const polyPoints = []

        for (let i = 0; i < points.length; i++) {
          const p = points.get(i).position
          const p2 = Cesium.Cartographic.fromCartesian(p)
          polyPoints.push(Cesium.Cartesian3.fromRadians(p2.longitude, p2.latitude, p2.height + 0.075))
        }

        if (isDrawing) {
          const p = tmpPoints.get(0).position
          const p2 = Cesium.Cartographic.fromCartesian(p)
          polyPoints.push(Cesium.Cartesian3.fromRadians(p2.longitude, p2.latitude, p2.height + 0.075))
        }

        return new Cesium.PolygonHierarchy(polyPoints)
      }, false)

      fillPolygon = viewer.entities.add({
        polygon: {
          hierarchy: cb,
          material: FillColor,
          classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
        },
      })
    }, 0)

    function volumeTask(cb: (result: VolumeResult) => void): Task {
      const task = new Task()
      task.setFn(doVolume(task, cb))
      return task
    }

    function* doVolume(task: Task, cb: (result: VolumeResult) => void) {
      // Get all the positions.
      const allPositions = []
      for (let i = 0; i < points.length; i++) {
        allPositions.push(points.get(i).position)
      }
      if (isDrawing) {
        allPositions.push(tmpPoints.get(0).position)
      }

      const polygon = allPositions.map((p) => Cesium.Cartographic.fromCartesian(p))
      const heightRef = Math.max(...polygon.map((c) => c.height)) * 2

      // Compute the rectangle generated by the points.
      const rect = Cesium.Rectangle.fromCartesianArray(allPositions)

      const bottomLeft = Cesium.Cartographic.fromRadians(rect.west, rect.south, heightRef)
      const topRight = Cesium.Cartographic.fromRadians(rect.east, rect.north, heightRef)

      const meters = 0.5
      const deltaX = Cesium.Math.toRadians((meters / 111111) * Math.cos(bottomLeft.latitude))
      const deltaY = Cesium.Math.toRadians(meters / 111111)

      const toIgnore = getIgnoredEntities()

      const result: VolumeResult = {
        items: [],
        minHeight: 1e99,
      }

      const scratch1 = new Cesium.Cartesian3()
      const scratch2 = new Cesium.Cartesian3()
      const scratch3 = new Cesium.Cartesian3()
      const scratch4 = new Cesium.Cartographic()
      const scratch5 = new Cesium.Cartographic()
      const scratch6 = new Cesium.Cartesian3()
      const scratch7 = new Cesium.Cartesian3()

      const bottomLeftCartesian = Cesium.Cartesian3.fromRadians(bottomLeft.longitude, bottomLeft.latitude, heightRef)

      for (let x = bottomLeft.longitude; x <= topRight.longitude; x += deltaX) {
        for (let y = bottomLeft.latitude; y <= topRight.latitude; y += deltaY) {
          scratch4.longitude = x
          scratch4.latitude = y
          if (
            !pointInPolygon(polygon, {
              longitude: x,
              latitude: y,
            })
          ) {
            continue
          }

          const center1 = Cesium.Cartesian3.fromRadians(
            x + deltaX / 2,
            y + deltaY / 2,
            heightRef,
            Cesium.Ellipsoid.WGS84,
            scratch1
          )
          const center2 = Cesium.Cartesian3.fromRadians(
            x + deltaX / 2,
            y + deltaY / 2,
            -heightRef,
            Cesium.Ellipsoid.WGS84,
            scratch2
          )
          const unit = Cesium.Cartesian3.subtract(center2, center1, scratch3)
          const ray = new Cesium.Ray(center1, Cesium.Cartesian3.normalize(unit, unit))
          const picked = scene.pickFromRay(ray, toIgnore, 0.1)
          if (picked) {
            const height = Cesium.Cartographic.fromCartesian(picked.position, Cesium.Ellipsoid.WGS84, scratch5).height
            if (height < result.minHeight) {
              result.minHeight = height
            }

            const posX = Cesium.Cartesian3.fromRadians(
              bottomLeft.longitude + deltaX,
              bottomLeft.latitude,
              heightRef,
              Cesium.Ellipsoid.WGS84,
              scratch6
            )
            const posY = Cesium.Cartesian3.fromRadians(
              bottomLeft.longitude,
              bottomLeft.latitude + deltaY,
              heightRef,
              Cesium.Ellipsoid.WGS84,
              scratch7
            )
            const d1 = Cesium.Cartesian3.distance(bottomLeftCartesian, posX)
            const d2 = Cesium.Cartesian3.distance(bottomLeftCartesian, posY)

            result.items.push({
              d1,
              d2,
              height,
            })

            if (task.switch()) yield result
          }
        }
      }

      cb(result)
    }

    function calculateVolume() {
      return new Promise<number>((resolve) => {
        if (isDrawing) {
          if (points.length < 2) {
            resolve(0)
            return
          }
        }

        const scheduler = new Scheduler()
        scheduler.addTask(
          volumeTask((res) => {
            let volume = 0

            for (const item of res.items) {
              volume += item.d1 * item.d2 * (item.height - res.minHeight)
            }

            scheduler.stop()
            resolve(volume)
          })
        )
        scheduler.run()
      })
    }

    function drawArea() {
      let count = 0
      const total = new Cesium.Cartesian3()

      for (let i = 0; i < points.length; i++) {
        const p1 = points.get(i).position
        count++
        Cesium.Cartesian3.add(total, p1, total)
      }

      if (isDrawing) {
        const p1 = tmpPoints.get(0).position
        count++
        Cesium.Cartesian3.add(total, p1, total)
      }

      const centerOfGravity = Cesium.Cartesian3.divideByScalar(total, count, new Cesium.Cartesian3())
      const carto = Cesium.Cartographic.fromCartesian(centerOfGravity)
      const c2 = Cesium.Cartesian3.fromRadians(carto.longitude, carto.latitude, carto.height + 100)

      const unit = Cesium.Cartesian3.subtract(
        new Cesium.Cartesian3(centerOfGravity.x, centerOfGravity.y, centerOfGravity.z),
        c2,
        new Cesium.Cartesian3()
      )
      Cesium.Cartesian3.normalize(unit, unit)

      const ray = new Cesium.Ray(c2, unit)

      const picked = scene.pickFromRay(ray, [], 0.1)
      const pickedCarto = Cesium.Cartographic.fromCartesian(picked.position)

      const label = labels.get(0)
      label.position = Cesium.Cartesian3.fromRadians(carto.longitude, carto.latitude, pickedCarto.height + 0.5)
      label.text = 'Calculating...'
      label.font = '14px monospace'
      label.pixelOffset = new Cesium.Cartesian2(0, 0)
      label.eyeOffset = new Cesium.Cartesian3(0, 1, 0)

      calculateVolume().then((volume) => {
        label.text = formatVolume(volume)

        for (let i = 0; i < labelIndex; i++) {
          labels.get(i).eyeOffset = new Cesium.Cartesian3(0.25, Math.min(Math.max(volume / 1000, 0.5), 15), -1)
        }
      })
    }

    function getIgnoredEntities() {
      const allIgnored: any[] = [fillPolygon]
      for (let i = 0; i < labelIndex; i++) {
        allIgnored.push(labels.get(i))
      }
      for (let i = 0; i < points.length; i++) {
        allIgnored.push(points.get(i))
      }
      allIgnored.push(tmpPoints.get(0))
      return allIgnored
    }

    function reset() {
      points.removeAll()
      tmpPoints.removeAll()
      tmpPolyLines.get(0).show = false
      for (let i = 0; i < polyLines.length; i++) {
        polyLines.get(i).show = false
      }

      for (let i = 0; i < labels.length; i++) {
        labels.get(i).position = new Cesium.Cartesian3(0, 0, 0)
      }
      labelIndex = 3
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        reset()
      } else if (e.key === 'Enter') {
        if (points.length >= 3) {
          isDrawing = false
          for (let i = 0; i < points.length; i++) {
            points.get(i).show = false
          }

          tmpPolyLines.get(0).show = false
          tmpPoints.get(0).show = false

          drawArea()

          labels.get(1).position = new Cesium.Cartesian3(0, 0, 0)
          labels.get(2).position = new Cesium.Cartesian3(0, 0, 0)
        }
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    // Mouse over the globe to see the cartographic position
    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
        return
      }

      pickFromPosition(viewer, click.position, getIgnoredEntities()).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }

        if (!isDrawing) {
          reset()
          isDrawing = true
          if (tmpPoints.length === 0) {
            const p = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point
            p.isOnModel = picked.onModel
          }
        }

        if (points.length === 0) {
          // Just add the first point.
          const p = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
          p.isOnModel = picked.onModel
          return
        }

        // Add the point and the next line.
        const p = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
        p.isOnModel = picked.onModel
        labels.get(1).position = new Cesium.Cartesian3(0, 0, 0)
      })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    const mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    mouseMoveHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported || !isDrawing) {
        return
      }

      pickFromPosition(viewer, click.endPosition, getIgnoredEntities()).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }
        // We haven't added any points yet.
        if (points.length === 0) {
          if (tmpPoints.length === 0) {
            const p = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point

            p.isOnModel = picked.onModel
          } else {
            ;(tmpPoints.get(0) as Point).isOnModel = picked.onModel
            tmpPoints.get(0).position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          }
        } else {
          ;(tmpPoints.get(0) as Point).isOnModel = picked.onModel
          tmpPoints.get(0).position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          if (points.length === 1) {
            const polyline = tmpPolyLines.get(0)
            polyline.show = true
            polyline.positions = [points.get(points.length - 1).position, tmpPoints.get(0).position]
          } else {
            const polyline = tmpPolyLines.get(0)
            polyline.show = false

            // drawArea()
          }
        }
      })
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      viewer.entities.remove(fillPolygon)
      scene.primitives.remove(points)
      scene.primitives.remove(tmpPoints)
      scene.primitives.remove(polyLines)
      scene.primitives.remove(tmpPolyLines)
      scene.primitives.remove(labels)
      leftClickHandler.destroy()
      mouseMoveHandler.destroy()
      window.removeEventListener('keydown', onKeyDown)
      // ;(Cesium.PolylineCollection.prototype as any).update = oldPolylineUpdate
    }
  }, [])

  return <></>
}
