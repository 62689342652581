import React from 'react'

interface IssueCounterDotsProps {
  numHighIssues: number
  numMediumIssues: number
  numLowIssues: number
  alwaysShowIssueCounts?: boolean
}

export const IssueCounterDots = (props: IssueCounterDotsProps) => {

  const numHighIssues = props.numHighIssues
  const numMediumIssues = props.numMediumIssues
  const numLowIssues = props.numLowIssues

  return (
    <>
      {(props.alwaysShowIssueCounts || numHighIssues > 0) && (
        <span
          className='issue-counter-dots'
          title={`${numHighIssues} high priority issue${numHighIssues === 1 ? '' : 's'}`}
        >
          {numHighIssues}
        </span>
      )}
      {(props.alwaysShowIssueCounts || numMediumIssues > 0) && (
        <span
          className='issue-counter-dots medium'
          title={`${numMediumIssues} medium priority issue${numMediumIssues === 1 ? '' : 's'}`}
        >
          {numMediumIssues}
        </span>
      )}
      {(props.alwaysShowIssueCounts || numLowIssues > 0) && (
        <span
          className='issue-counter-dots low'
          title={`${numLowIssues} low priority issue${numLowIssues === 1 ? '' : 's'}`}
        >
          {numLowIssues}
        </span>
      )}
    </>
  )
}
