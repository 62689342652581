import { MutableRefObject } from 'react'
import { PolygonRenderer } from './polygon-renderer'
import { Offset, Point, ScreenState } from './screen-state'

export class PolygonRendererCreate extends PolygonRenderer {
  private _points: Point[]
  private _onChange: (points?: Point[]) => void
  private _mousePosition: Point
  private _done: boolean

  constructor(
    canvas: HTMLCanvasElement,
    screenState: MutableRefObject<ScreenState>,
    color: string,
    onChange: (points?: Point[]) => void
  ) {
    super(canvas, screenState, color)
    this._points = []
    this._onChange = onChange
    this._done = false
  }

  public onLeftClick(pos: Offset) {
    if (this._isOnFirstPoint(pos, this._points)) {
      this._done = true
      this.render()
      this._onChange(this._points)
      return
    }

    if (this._done) {
      return
    }

    const newPoint = this._screenState.current.getImagePosition({
      x: pos.left,
      y: pos.top,
    })

    if (
      newPoint.x < 0 ||
      newPoint.y < 0 ||
      newPoint.x > this._screenState.current.img.width ||
      newPoint.y > this._screenState.current.img.height
    ) {
      return
    }
    this._points.push(newPoint)

    this.render()
  }

  public onMiddleClick() {
    /* Ignore */
  }

  public onRightClick() {
    if (this._points.length > 0) {
      this._points.pop()
      this.render()
    }
  }

  public onLeftDown() {
    /* Ignore */
  }

  public onLeftUp() {
    /* Ignore */
  }

  public onMouseMove(pos: Offset) {
    if (this._done) {
      return
    }
    this._mousePosition = this._screenState.current.getImagePosition({
      x: pos.left,
      y: pos.top,
    })

    if (this._isOnFirstPoint(pos, this._points)) {
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'default'
    }

    this.render()
  }

  public onKeydown() {
    /* Ignore */
  }

  public _render() {
    if (this._points.length === 0 || this._mousePosition === undefined) {
      return
    }

    const ctx = this._screenState.current.c.getContext('2d')
    const pointsToUse = [...this._points, this._done ? this._points[0] : this._mousePosition]

    for (let i = 1; i < pointsToUse.length; i++) {
      const prevPoint = pointsToUse[i - 1]
      const point = pointsToUse[i]

      const previousPosition = this._screenState.current.getCanvasPosition(prevPoint)
      const currentPosition = this._screenState.current.getCanvasPosition(point)

      if (previousPosition.left < 0) {
        previousPosition.left = 0
      }

      if (previousPosition.top < 0) {
        previousPosition.top = 0
      }

      if (currentPosition.left < 0) {
        currentPosition.left = 0
      }

      if (currentPosition.top < 0) {
        currentPosition.top = 0
      }

      ctx.save()
      ctx.fillStyle = this._color
      ctx.strokeStyle = this._color
      ctx.setLineDash([10, 10])
      ctx.lineWidth = 3

      ctx.beginPath()
      ctx.arc(previousPosition.left, previousPosition.top, 4.5, 0, 2 * Math.PI, false)
      ctx.fill()

      if (!this._done && i === pointsToUse.length - 1) {
        ctx.beginPath()
        ctx.arc(currentPosition.left, currentPosition.top, 4.5, 0, 2 * Math.PI, false)
        ctx.fill()
      }

      ctx.beginPath()
      ctx.moveTo(previousPosition.left, previousPosition.top)
      ctx.lineTo(currentPosition.left, currentPosition.top)
      ctx.stroke()
      ctx.restore()
    }
  }
}
