import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom'

export interface UseLocation {
  location: string
  setLocation: (location: string) => void
  replaceLocation: (location: string) => void
}

export function useLocation(): UseLocation {
  const navigate = useNavigate()
  const location = useRouterLocation()

  return {
    location: location.pathname || window.location.pathname,
    setLocation: (location: string) => {
      navigate(location)
      // console.trace('Set location to ' + location)
    },
    replaceLocation: (location: string) => {
      navigate(location, { replace: true })
    },
  }
}
