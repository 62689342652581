import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLinks } from './auth-links'

import { Helmet } from '~/components'
import React from 'react'

export const AuthReactivate = () => {
  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Reactivate Subscription' />
      <div className='p-4'>
        <div className='subheader-dark mt-5 mb-5'>Reactivate Asseti Subscription</div>
        <p className='auth-description-text mb-4'>
          Please get in touch with support to reactivate your Asseti subscription.
        </p>
        <AuthLinks>
          <p className='body2 mb-3'>
            <a
              className='auth-links-text'
              href='mailto:support@asseti.co'
            >
              Email Support
            </a>
          </p>
        </AuthLinks>
      </div>
    </AuthLayout>
  )
}
