import { useEffect, useMemo } from 'react'
import { useLocation, PolygonColorRed, PolygonRendererView } from '~/components'
import { ProjectionType, useAppState, BoundaryRenderState, MarkerIconType, Marker, useRefState } from '~/state'
import { SiteQueryBoundary, SiteQuerySite } from '~/models'
import { ClientPermission, useUser } from '~/base'
import {
  BoundaryCategory,
  BoundarySubcategory,
  getBoundaryCategory,
  getBoundaryColor,
  getBoundarySubcategory,
} from '~/components/boundaries/boundary-categories'

interface Boundary {
  additional: SiteQueryBoundary['additional']
  fullCategory: BoundaryCategory
  fullSubcategory: BoundarySubcategory
  renderer: PolygonRendererView
}

export interface BoundaryState {
  site: {
    [boundaryID: string]: Boundary
  }
  siteMarker: Marker
  assets: {
    [assetID: string]: PolygonRendererView[]
  }
}

export function useSiteBoundaries(site?: SiteQuerySite) {
  const user = useUser()
  const { map, boundaryState, view, asset, timeline } = useAppState()
  const location = useLocation()
  const [polygonRenderers, setPolygonRenderers] = useRefState<BoundaryState>({
    site: {},
    siteMarker: undefined,
    assets: {},
  })
  const canViewAssets = user.hasPermission(ClientPermission.AssetsList)

  function destroy() {
    Object.keys(polygonRenderers.current.site).forEach((p) => {
      polygonRenderers.current.site[p].renderer.destroy()
    })
    Object.keys(polygonRenderers.current.assets).forEach((k) => {
      if (Array.isArray(polygonRenderers.current.assets[k])) {
        polygonRenderers.current.assets[k].forEach((p) => p.destroy())
      }
    })
    if (polygonRenderers.current.siteMarker) {
      map.removeMarker(polygonRenderers.current.siteMarker)
    }
  }

  function setAllVisible(visible: boolean, labels: boolean) {
    if (Object.keys(polygonRenderers.current.site).length > 0) {
      Object.keys(polygonRenderers.current.site).forEach((p) => {
        const b = polygonRenderers.current.site[p]
        const subCategory = b.fullSubcategory?.name || 'No Subcategory'
        const switchName = 'show-' + subCategory
        const localStorageItem = localStorage.getItem(switchName)
        if (localStorageItem) {
          const isChecked = localStorageItem !== 'false'
          if (isChecked) {
            b.renderer.setVisible(visible, labels)
          } else {
            b.renderer.setVisible(false, false)
          }
        } else {
          b.renderer.setVisible(visible, labels)
        }
      })
    }
    Object.keys(polygonRenderers.current.assets).forEach((k) => {
      if (Array.isArray(polygonRenderers.current.assets[k])) {
        polygonRenderers.current.assets[k].forEach((p) => {
          p.setVisible(visible, labels)
        })
      }
    })
  }

  const boundaries = useMemo(() => {
    if (!Array.isArray(site?.boundaries) || !view.siteID) {
      return 0
    }

    let total = site.boundaries.reduce((a, b) => {
      return a + b.points.reduce((c, d) => c + d.latitude + d.longitude, 0)
    }, 0)

    for (const asset of site.assets) {
      total += asset.boundaries.reduce((a, b) => {
        return a + b.points.reduce((c, d) => c + d.latitude + d.longitude, 0)
      }, 0)
    }
    return total
  }, [site?.boundaries, asset.asset?.boundaries, view.siteID])

  // Create the boundary polygons but do not show them.
  useEffect(() => {
    destroy()

    if (map.morphing || !site) {
      setPolygonRenderers({ site: {}, siteMarker: undefined, assets: {} })
      return
    }

    const siteBoundaries: {
      [boundaryID: string]: Boundary
    } = {}
    site.boundaries.forEach((b) => {
      const category = getBoundaryCategory(b.additional?.category)
      const subcategory = getBoundarySubcategory(category, b.additional?.subcategory)
      const color = getBoundaryColor(category, subcategory)

      siteBoundaries[b.id] = {
        renderer: new PolygonRendererView({
          points: b.points,
          color: color.boundary,
          map,
          renderPoints: false,
          hide: true,
          label: b.additional
            ? b.name + ' - ' + (category?.name || 'NA') + ' - ' + (subcategory?.name || 'NA')
            : undefined,
          hideMarker: true,
          fill: true,
          onClick: () => {
            if (category) {
              location.setLocation(`/${site.id}/${timeline.activeSurvey.id}/boundary/${b.id}`)
            }
          },
        }),
        additional: b.additional,
        fullCategory: category,
        fullSubcategory: subcategory,
      }
    })

    const newState: BoundaryState = {
      site: siteBoundaries,
      siteMarker: undefined,
      assets: {},
    }

    // Create Icon for site if there's no Boundary
    if (site.boundaries.length === 0) {
      newState.siteMarker = map.addMarker(
        site.address.id,
        [site.address.center[0], site.address.center[1]],
        () => {}, // No action on click
        site.name,
        32,
        '#FF0000',
        MarkerIconType.Map
      )
    }

    if (canViewAssets) {
      for (const asset of site.assets) {
        newState.assets[asset.id] = asset.boundaries.map(
          (b) =>
            new PolygonRendererView({
              points: b.points,
              color: PolygonColorRed,
              map,
              renderPoints: false,
              hide: true,
              label: asset.name,
              onClick: () => {
                location.setLocation(`/${site.id}/${timeline.activeSurvey.id}/assets/${asset.id}`)
              },
              hideMarker: true,
            })
        )
      }
    }

    setPolygonRenderers(newState)
  }, [site?.id, boundaries, timeline.activeSurvey?.id, map.morphing, view.siteID, canViewAssets])

  // Remove all polygons when this hook is unmounted.
  useEffect(() => {
    return () => {
      destroy()
      setPolygonRenderers({ site: {}, siteMarker: undefined, assets: {} })
    }
  }, [])

  // Highlight active boundary.
  useEffect(() => {
    const b = polygonRenderers.current.site[view.boundaryID]
    if (b) {
      b.renderer.setActive(true)
      b.renderer.setFill(true)
    }
    return () => {
      if (b) {
        b.renderer.setActive(false)
        b.renderer.setFill(false)
      }
    }
  }, [view.boundaryID, polygonRenderers.current])

  useEffect(() => {
    window.__assetiBoundaryPolygonRenderers = polygonRenderers.current
    return () => {
      window.__assetiBoundaryPolygonRenderers = undefined
    }
  }, [polygonRenderers.current])

  // Set visibility based on state.
  useEffect(() => {
    if (!polygonRenderers.current) {
      return
    }

    // Hide all if we mustn't draw in 2D.
    if (map.projectionType === ProjectionType.Projection2D && !boundaryState.drawIn2D) {
      setAllVisible(false, false)
      return
    }

    // Hide all if we mustn't draw in 3D.
    if (map.projectionType === ProjectionType.Projection3D && !boundaryState.drawIn3D) {
      setAllVisible(false, false)
      return
    }

    // Hide the singular boundary if we're editing.
    if (boundaryState.boundaryToEdit) {
      setAllVisible(true, boundaryState.renderLabels)
      Object.keys(polygonRenderers.current.site).forEach((k) => {
        if (k === boundaryState.boundaryToEdit.id) {
          polygonRenderers.current.site[k].renderer.setVisible(false, boundaryState.renderLabels)
        }
      })
      if (polygonRenderers.current.assets[view.assetID]) {
        polygonRenderers.current.assets[view.assetID].forEach((p) => p.setVisible(false, boundaryState.renderLabels))
      }
      return
    }

    // Hide all if we mustn't draw any boundaries.
    if (boundaryState.renderState === BoundaryRenderState.None) {
      setAllVisible(false, boundaryState.renderLabels)
      return
    }

    // Hide all assets if we must render the site only.
    if (boundaryState.renderState === BoundaryRenderState.Site) {
      setAllVisible(false, boundaryState.renderLabels)
      Object.keys(polygonRenderers.current.site).forEach((p) =>
        polygonRenderers.current.site[p].renderer.setVisible(true, boundaryState.renderLabels)
      )
      return
    }

    // Draw everything.
    if (boundaryState.renderState === BoundaryRenderState.SiteAndAssets) {
      setAllVisible(true, boundaryState.renderLabels)
      if (polygonRenderers.current.assets[view.assetID]) {
        polygonRenderers.current.assets[view.assetID].forEach((p) => p.setActive(true))
      }
      return () => {
        if (polygonRenderers.current.assets[view.assetID]) {
          polygonRenderers.current.assets[view.assetID].forEach((p) => p.setActive(false))
        }
      }
    }

    // Draw the site and the single asset.
    if (boundaryState.renderState === BoundaryRenderState.SiteAndAsset) {
      setAllVisible(false, boundaryState.renderLabels)
      Object.keys(polygonRenderers.current.site).forEach((p) =>
        polygonRenderers.current.site[p].renderer.setVisible(true, boundaryState.renderLabels)
      )
      if (polygonRenderers.current.assets[view.assetID]) {
        polygonRenderers.current.assets[view.assetID].forEach((p) => p.setVisible(true, boundaryState.renderLabels))
      }
      return
    }

    // Draw a single asset.
    if (boundaryState.renderState === BoundaryRenderState.Asset) {
      setAllVisible(false, boundaryState.renderLabels)
      Object.keys(polygonRenderers.current.site).forEach((p) =>
        polygonRenderers.current.site[p].renderer.setVisible(true, boundaryState.renderLabels)
      )
      if (polygonRenderers.current.assets[view.assetID]) {
        polygonRenderers.current.assets[view.assetID].forEach((p) => p.setVisible(true, boundaryState.renderLabels))
      }
      return
    }
  }, [
    polygonRenderers.current,
    map.projectionType,
    boundaryState.renderState,
    boundaryState.renderLabels,
    boundaryState.drawIn2D,
    boundaryState.drawIn3D,
    boundaryState.boundaryToEdit,
    view.assetID,
  ])
}
