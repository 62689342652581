import React from 'react'
import { ComponentCondition } from '~/models'
import { Select } from '../forms'

interface ComponentConditionSelectProps {
  condition?: ComponentCondition
  onChange?: (c?: ComponentCondition) => void
  label?: string
  disabled?: boolean
}

export const ComponentConditionSelect = (props: ComponentConditionSelectProps) => {
  return (
    <Select<{
      id: ComponentCondition,
      name: string
    }>
      disabled={props.disabled}
      id='condition'
      label={props.label || 'Condition'}
      placeholder='Select a condition'
      canSelectGroup
      options={[
        {
          id: ComponentCondition.Excellent,
          name: ComponentCondition.Excellent,
          value: {
            id: ComponentCondition.Excellent,
            name: ComponentCondition.Excellent,
          },
          items: [],
        },
        {
          id: ComponentCondition.Good,
          name: ComponentCondition.Good,
          value: {
            id: ComponentCondition.Good,
            name: ComponentCondition.Good,
          },
          items: [],
        },
        {
          id: ComponentCondition.Average,
          name: ComponentCondition.Average,
          value: {
            id: ComponentCondition.Average,
            name: ComponentCondition.Average,
          },
          items: [],
        },
        {
          id: ComponentCondition.Poor,
          name: ComponentCondition.Poor,
          value: {
            id: ComponentCondition.Poor,
            name: ComponentCondition.Poor,
          },
          items: [],
        },
        {
          id: ComponentCondition.VeryPoor,
          name: 'Very poor',
          value: {
            id: ComponentCondition.VeryPoor,
            name: 'Very poor',
          },
          items: [],
        },
        {
          id: ComponentCondition.EndOfLife,
          name: 'End of life',
          value: {
            id: ComponentCondition.EndOfLife,
            name: 'End of life',
          },
          items: [],
        }
      ]}
      onChange={(selected) => {
        props.onChange(selected.id)
      }}
      selectedValue={{
        id: props.condition,
        name: props.condition,
      }}
    />
  )
}
