import React, { useState } from 'react'
import { DrawerListContent, DrawerListContentShort, ItemTag, Transition } from '~/components'
import { BoundariesCreate } from './boundaries-create'
import { ClientPermission, useUser } from '~/base'
import { useAppState } from '~/state'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { BoundariesFullList } from './boundaries-full-list'
import { BoundariesEdit } from './boundaries-edit'

interface BoundariesProps {
  refetch: () => Promise<void>
}

export const Boundaries = (props: BoundariesProps) => {
  const user = useUser()
  const { timeline, view, drawer, site, boundaryState } = useAppState()
  const entity = site.site?.assets.find((a) => a.id === view.assetID) || site.site
  const [showBoundaryCreate, setShowBoundaryCreate] = useState<boolean>(false)
  const haveBoundaries = entity && entity.boundaries.length > 0
  const boundaries = haveBoundaries ? entity.boundaries : []
  const isDemoSite = site.site?.isDemoSite
  const canAddBoundaries = timeline.activeSurvey && !isDemoSite && user.hasPermission(ClientPermission.BoundariesCreate)

  return (
    <>
      {user.id !== '01ES2WQEPK7G9ZWSVC3MFB9GTH' && (
        <DrawerListContentShort
          canAdd={canAddBoundaries}
          loading={false}
          numEntities={boundaries.length}
          panelType={ExpandedPanel.Boundaries}
          plural='boundaries'
          singular='boundary'
          title='Boundaries'
          onAdd={() => {
            setShowBoundaryCreate(true)
          }}
          onShow={() => {
            if (boundaries.length === 0) {
              drawer.closeExpandedPanel()
              setShowBoundaryCreate(true)
            }
          }}
          emptyButCanAddText='Add a boundary'
          emptyButCantAddText='No boundaries have been added'
        />
      )}
      {user.id === '01ES2WQEPK7G9ZWSVC3MFB9GTH' && (
        <DrawerListContent
          loading={false}
          numEntities={boundaries.length}
          panelType={ExpandedPanel.Boundaries}
          plural='boundaries'
          singular='boundary'
          title='Boundaries'
          canAdd={canAddBoundaries}
          onAdd={() => {
            setShowBoundaryCreate(true)
          }}
          emptyButCanAddText='Add a boundary'
          emptyButCantAddText='There are no boundaries to display.'
        >
          <Transition width={120} in={!!entity}>
            {[...boundaries]
              .reverse()
              .slice(0, 3)
              .map((b) => {
                return (
                  <ItemTag
                    title={b.name}
                    key={b.id}
                    tagType='component'
                    onClick={() => {
                      boundaryState.setBoundaryToEdit(b)
                    }}
                  />
                )
              })}
          </Transition>
        </DrawerListContent>
      )}
      {showBoundaryCreate && (
        <BoundariesCreate
          onComplete={(changed) => {
            if (changed) {
              return props.refetch().then(() => {
                setShowBoundaryCreate(false)
              })
            } else {
              setShowBoundaryCreate(false)
              return Promise.resolve()
            }
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.Boundaries && (
        <BoundariesFullList
          boundaries={boundaries}
          refetch={props.refetch}
          boundaryToEdit={boundaryState.boundaryToEdit}
          setBoundaryToEdit={boundaryState.setBoundaryToEdit}
        />
      )}
      {boundaryState.boundaryToEdit && (
        <BoundariesEdit
          boundary={boundaryState.boundaryToEdit}
          onComplete={(changed) => {
            if (changed) {
              return props.refetch().then(() => {
                boundaryState.setBoundaryToEdit(undefined)
              })
            } else {
              boundaryState.setBoundaryToEdit(undefined)
              return Promise.resolve()
            }
          }}
        />
      )}
    </>
  )
}
