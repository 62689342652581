import { useEffect, useState } from 'react'
import { DrawerState } from '~/components'
import { BaseLayerType, useAppState } from '~/state'

export const useIssuesDrawers = (bulkTagging: boolean) => {
  const { drawer, map, imageTagger } = useAppState()
  const [transitioningTo3D, setTransitioningTo3D] = useState(true)

  // Setup drawers.
  useEffect(() => {
    drawer.set(DrawerState.Normal, DrawerState.Hidden)
    map.setShowControls(false)
    if(bulkTagging) {
      map.setPositionForBulkImageTagging(true)
    } else {
      map.setPositionForBulkImageMoving(true)
    }
    map.setBaseLayerType(BaseLayerType.PhotoModel).then(() => {
      setTransitioningTo3D(false)
    })
    imageTagger.hide()

    return () => {
      drawer.set(DrawerState.Normal, DrawerState.Normal)
      map.setShowControls(true)
      map.setPositionForBulkImageTagging(false)
      map.setPositionForBulkImageMoving(false)
      map.setBaseLayerType(BaseLayerType.Satellite)
    }
  }, [])

  return transitioningTo3D
}
