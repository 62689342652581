mutation signInSwitchSupplier($input: SignInSwitchSupplierRequestT!) {
  signInSwitchSupplier(input: $input) {
    token
    firstName
    lastName
    email
    phoneNumber
    systemRole
    userId
    currentOrgId
    currentSupplierID
    orgs {
      id
      name
      role
      domainName
      requiresSubscription
      canManageAdvancedAccess
      canAccessDeflections
      requires2FA
      currency
      measurementSystem
      isBasic
      isIA
    }
    suppliers {
      id
      name
      role
      requires2FA
    }
    policy
    showDemoDashboards
    showDemoSites
    dataKeyID
    dataPolicy
    dataSignature
    requires2FA
    waitFor2FA
    orgPendingApproval
    supplierPendingApproval
  }
}
