import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'

import { Helmet } from '~/components'
import React from 'react'

export const AuthPreConfirmation = () => {
  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Confirm Your Email' />
      <div className='p-4'>
        <div className='subheader-dark mt-5 mb-5'>Thank you for joining Asseti</div>
        <p className='auth-description-text mb-4'>
          We've sent an email to confirm your email address. Please follow the link to complete your registration.
        </p>
        <AuthLinks>
          <AuthLink to='/signin' className='mb-3'>
            Back to Sign In
          </AuthLink>
        </AuthLinks>
      </div>
    </AuthLayout>
  )
}
