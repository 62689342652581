import React, { CSSProperties } from 'react'

interface ItemTagProps {
  title: string
  tagType: 'asset' | 'issue' | 'component' | 'none'
  onClick?: () => void
  onHover?: () => void
  onBlur?: () => void
  riskType?: string
  tagStyle?: CSSProperties
  inline?: boolean
}

export const ItemTag = (props: ItemTagProps) => {
  return (
    <>
      <div
        className={`item-tag item-tag-${props.tagType}`}
        style={props.inline ? { display: 'inline-block', width: 'fit-content' } : undefined}
        onClick={(e) => {
          if (e.defaultPrevented || !props.onClick) {
            return
          }

          props.onClick()
        }}
        onMouseEnter={props.onHover}
        onMouseLeave={props.onBlur}
        onDoubleClick={(e) => {
          e.preventDefault()
        }}
      >
        {props.tagStyle && <div className='item-tag-color-key' style={props.tagStyle} />}
        {!props.tagStyle && props.tagType === 'asset' && <i className='item-tag-asset material-icons'>domain</i>}
        {!props.tagStyle && props.tagType === 'issue' && (
          <div className={`item-tag-color-priority-${props.riskType.toLowerCase()}`} />
        )}
        {!props.tagStyle && props.tagType === 'none' && <div className={`item-tag-color-key none`} />}
        {!props.tagStyle && props.tagType !== 'asset' && props.tagType !== 'issue' && props.tagType !== 'none' && (
          <div className='item-tag-color-key' />
        )}
        <div className='item-tag-title'>
          <span title={props.title}>{props.title}</span>
        </div>
      </div>
    </>
  )
}
