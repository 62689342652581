import { MutableRefObject } from 'react'
import { Offset, Point, ScreenState } from './screen-state'

export const PolygonColorRed = '#BA160C'
export const PolygonColorOrange = '#B97F0B'


export abstract class PolygonRenderer {
  protected _screenState: MutableRefObject<ScreenState>
  protected _canvas: HTMLCanvasElement
  protected _color: string
  private _startMouseDown: Offset

  private _onKeyDown: (e: KeyboardEvent) => void

  constructor(canvas: HTMLCanvasElement, screenState: MutableRefObject<ScreenState>, color: string) {
    this._canvas = canvas
    this._screenState = screenState
    this._color = color

    this._onKeyDown = this.onKeydown.bind(this)
    this._onMouseDown = this._onMouseDown.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this._onMouseMove = this._onMouseMove.bind(this)

    window.addEventListener('keydown', this._onKeyDown, false)
    canvas.addEventListener('mousedown', this._onMouseDown)
    canvas.addEventListener('mousemove', this._onMouseMove)
    canvas.addEventListener('mouseup', this._onMouseUp)
  }

  public destroy() {
    document.body.style.cursor = 'default'
    window.removeEventListener('keydown', this._onKeyDown)
    this._canvas.removeEventListener('mousedown', this._onMouseDown)
    this._canvas.removeEventListener('mousemove', this._onMouseMove)
    this._canvas.removeEventListener('mouseup', this._onMouseUp)
  }

  private _onMouseDown(e: MouseEvent) {
    if (e.which === 1) {
      this._startMouseDown = {
        left: e.offsetX,
        top: e.offsetY,
      }
      this.onLeftDown(this._startMouseDown)
    } else if (e.which === 2) {
      this._startMouseDown = {
        left: e.offsetX,
        top: e.offsetY,
      }
    }
  }

  private _onMouseMove(e: MouseEvent) {
    this.onMouseMove({
      left: e.offsetX,
      top: e.offsetY,
    })
  }

  private _onMouseUp(e: MouseEvent) {
    if(!this._startMouseDown) {
      return
    }
    
    const deltaX = e.offsetX - this._startMouseDown.left
    const deltaY = e.offsetY - this._startMouseDown.top

    const o = {
      left: e.offsetX,
      top: e.offsetY,
    }

    if (e.which === 1) {
      if (Math.abs(deltaX) < 15 && Math.abs(deltaY) < 15) {
        this.onLeftClick(o)
      }

      this.onLeftUp(o)
    } else if (e.which === 2) {
      if (Math.abs(deltaX) < 15 && Math.abs(deltaY) < 15) {
        this.onMiddleClick(o)
      }
    } else if (e.which === 3) {
      e.preventDefault()
      this.onRightClick(o)
    }
  }

  public render() {
    if(this._screenState.current) {
      this._screenState.current.render()
    }
    // this._render()
  }

  public abstract _render(): void
  abstract onKeydown(e: KeyboardEvent): void
  abstract onLeftClick(pos: Offset): void
  abstract onLeftDown(pos: Offset): void
  abstract onLeftUp(pos: Offset): void
  abstract onMouseMove(pos: Offset): void
  abstract onMiddleClick(pos: Offset): void
  abstract onRightClick(pos: Offset): void

  protected _isOnFirstPoint(pos: Offset, points: Point[]) {
    if (points.length > 0) {
      const p = points[0]
      const imagePos = this._screenState.current.getImagePosition({
        x: pos.left,
        y: pos.top,
      })

      const deltaX = Math.abs(imagePos.x - p.x)
      const deltaY = Math.abs(imagePos.y - p.y)

      if (deltaX <= 15 && deltaY <= 15) {
        return true
      }
    }

    return false
  }

  protected _getPointIndex(pos: Offset, points: Point[]) {
    for (let i = 0; i < points.length; i++) {
      const p = points[i]
      const imagePos = this._screenState.current.getImagePosition({
        x: pos.left,
        y: pos.top,
      })

      const deltaX = Math.abs(imagePos.x - p.x)
      const deltaY = Math.abs(imagePos.y - p.y)

      if (deltaX <= 40 && deltaY <= 40) {
        return i
      }
    }

    return -1
  }
}

// if (Math.abs(deltaX) == 0 && Math.abs(deltaY) == 0) {
//   const imagePos = screenState.current.getImagePosition({
//     x: e.offsetX,
//     y: e.offsetY,
//   })

//   // Constrain click.
//   if (
//     imagePos.x >= 0 &&
//     imagePos.y >= 0 &&
//     imagePos.x <= screenState.current.img.width &&
//     imagePos.y <= screenState.current.img.height
//   ) {
//     setPoints(() => [...points.current, imagePos])
//   }
// }
