import { useMutation, useQuery } from '~/components'
import React, { useState } from 'react'
import { AuthStatusQuery, SetShowDemoSitesMutation, SetShowDemoSitesMutationVariables } from '~/models'
import SET_SHOW_DEMO_SITES from './mutation-sites-set-show-demo-sites.gql'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { useLocation } from '~/components'
import AUTH_STATUS_QUERY from '~/auth/query-auth-status.gql'
import { useUser } from '~/base'

interface SetShowDemoModalProps {
  mustEnable: boolean
  onComplete: () => Promise<any>
  onCancel: () => void
}

export const SetShowDemoModal = (props: SetShowDemoModalProps) => {
  const [hideMutation] = useMutation<SetShowDemoSitesMutation, SetShowDemoSitesMutationVariables>(SET_SHOW_DEMO_SITES)
  const authStatusQuery = useQuery<AuthStatusQuery>(AUTH_STATUS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const user = useUser()

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content higher'>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>
            {props.mustEnable ? 'Show' : 'Hide'} all demo sites?
            <br />
            <span style={{ fontSize: '0.75rem' }}>This can be changed later in your profile settings</span>
          </h5>
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button className='btn btn-secondary' onClick={props.onCancel} disabled={loading}>
            No
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              setLoading(true)
              hideMutation({
                variables: {
                  input: {
                    showDemoSites: props.mustEnable,
                  },
                },
              })
                .then(() => {
                  return authStatusQuery
                    .refetch()
                    .then((res) => {
                      if (location.location !== '/') {
                        location.setLocation('/')
                      }
                      user.setSignIn(res.data.authStatus)
                      return props.onComplete()
                    })
                    .catch(() => {
                      window.location.pathname = '/signed-out'
                    })
                })
                .finally(() => {
                  setLoading(false)
                  props.onCancel()
                })
            }}
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Yes'}
          </button>
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
