import { string, object } from '~/components'
import { CreateSiteMutation, LocationInputT, SiteCreateRequestT } from '../models/gql-types'
import {
  GeoInput,
  FloatingInput,
  useForm,
  getTracking,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormContent,
  Helmet,
  DrawerFormHeading,
  DrawerFormButtons,
  DrawerFormSubmitButton,
  DrawerFormCancelButton,
} from '~/components'
import React, { useEffect, useState } from 'react'
import { LeftClickPosition, useAppState } from '~/state'
import { getDefaultLocation, mergeLocations } from './location-helpers'
import { Config } from '~/config'
import CREATE_SITE_MUTATION from './mutation-sites-create.gql'

interface SiteCreateProps {
  onComplete: (created: boolean, siteID: string) => void
}

export const SiteCreate = (props: SiteCreateProps) => {
  const { map } = useAppState()

  const [geocoderResult, setGeocoderResult] = useState<LocationInputT | undefined>()
  const [clickPosition, setClickPosition] = useState<Cesium.Cartesian3 | undefined>()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    CreateSiteMutation,
    SiteCreateRequestT
  >({
    enableReinitialize: true,
    initialValues: {
      name: '',
      location: getDefaultLocation(),
    },
    validationSchema: object().shape({
      name: string().required('Required'),
      location: object().shape({}).required('Required'),
    }),
    mutation: CREATE_SITE_MUTATION,
    mapInput: (input) => {
      return {
        ...input,
        location: mergeLocations(getDefaultLocation(), geocoderResult),
      }
    },
    onSuccess: (result: CreateSiteMutation) => {
      getTracking().event({
        category: 'Form',
        action: `User created a site`,
        label: 'Site',
      })
      const siteID = result.createSite.id
      props.onComplete(true, siteID)
    },
  })

  const onLeftClick = (pos: LeftClickPosition) => {
    setClickPosition(pos.cartesian)
    return true
  }

  useEffect(() => {
    map.addLeftClickHandler(onLeftClick)

    return () => {
      map.removeLeftClickHandler(onLeftClick)
    }
  }, [])

  useEffect(() => {
    if (!clickPosition) {
      return
    }

    const pos = Cesium.Cartographic.fromCartesian(clickPosition)
    const lng = (pos.longitude / Math.PI) * 180
    const lat = (pos.latitude / Math.PI) * 180

    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${Config.MapBoxToken}`)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.features) && data.features.length > 0) {
          setGeocoderResult(data.features[0])
        } else {
          setGeocoderResult(undefined)
        }
      })

    map.removeMarkerById('site-create')

    const marker = map.addMarker('site-create', [lng, lat])

    return () => {
      map.removeMarker(marker)
    }
  }, [clickPosition])

  const onSelectHandler = (result: LocationInputT) => {
    setGeocoderResult(result)

    map.removeMarkerById('site-create')
    map.addMarker('site-create', geocoderResult.center)

    if ((result as any).center) {
      map.flyTo((result as any).center[1], (result as any).center[0], 2e4)
    }
  }

  return (
    <DrawerForm>
      <Helmet title='Add site' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem title='Home' onClick={() => props.onComplete(false, '')} />
        <DrawerFormBreadcrumbItem title='Add site' />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading title='Add a site' />
      <DrawerFormContent>
        <form autoComplete='off' id='create-site-form' onSubmit={handleSubmit}>
          {error && <div className='error'>{error}</div>}
          <FloatingInput
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            label='Name'
          />
          <GeoInput
            inputLabel='address'
            inputValue={geocoderResult ? geocoderResult.place_name || geocoderResult.address : undefined}
            onSelect={onSelectHandler}
            helperText='Search for an address or click on the map to set the address'
          />
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        <DrawerFormSubmitButton form='create-site-form' disabled={isSubmitting || typeof geocoderResult !== 'object'}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton onClick={() => props.onComplete(false, '')}>Cancel</DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
