import { ItemTagDelete, useMutation } from '~/components'
import React from 'react'
import { getTracking } from '~/components'
import { useAppState } from '~/state'
import REMOVE_SITE from './mutation-sites-remove.gql'

interface SiteRemoveProps {
  onRemove: () => void
  hide: () => void
}

export const SiteRemove = (props: SiteRemoveProps) => {
  const { view } = useAppState()

  const [removeSite] = useMutation(REMOVE_SITE)

  const doRemoveSite = () => removeSite({ variables: { input: { siteID: view.siteID } } }).then(props.onRemove)

  return (
    <ItemTagDelete
      title='this site'
      cancel={props.hide}
      hideOperationType
      onClickDelete={() => {
        return doRemoveSite().then(() => {
          getTracking().event({
            category: 'Button',
            action: `User removed a site`,
            label: 'Site',
          })
        })
      }}
    />
  )
}
