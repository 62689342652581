import dayjs from 'dayjs'

export function timeSince(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).fromNow()
}

export function timeSinceShort(date: number) {
  if (date === 0) {
    return '-'
  }

  let shortString = dayjs(date / 1000 / 1000).fromNow(true)

  shortString = shortString.replace(/\s+/ig, '')
  shortString = shortString.replace('months', 'm')
  shortString = shortString.replace('amonth', '1m')
  shortString = shortString.replace('years', 'y')
  shortString = shortString.replace('ayear', '1y')
  shortString = shortString.replace('seconds', 's')
  shortString = shortString.replace('asecond', '1s')
  shortString = shortString.replace('minutes', 'm')
  shortString = shortString.replace('aminute', '1m')
  shortString = shortString.replace('aday', '1d')
  shortString = shortString.replace('days', 'd')
  shortString = shortString.replace('hours', 'h')
  shortString = shortString.replace('anhour', '1h')

  if(shortString === 'afews') {
    return '0m'
  }

  return shortString
}

export function formalDate(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).toISOString()
}

export function fullDate(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).format('dddd, MMMM D, YYYY h:mm A')
}

export function shortDate(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).format('DD MMM YYYY')
}

export function shortDateFullMonth(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).format('DD MMMM YYYY')
}

export function mediumDate(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).format('D MMM YYYY')
}

export function tinyDate(date: number) {
  if (date === 0) {
    return '-'
  }
  return dayjs(date / 1000 / 1000).format('DD/MM/YY')
}

