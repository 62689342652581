query instantAssessmentSummaryDemo($input: InstantAssessmentDemoRequestT!) {
  instantAssessmentSummaryDemo(input: $input) {
    orgName
    numSites
    numSitesTotal
    numAssets
    numAssetsTotal
    areaSqm
    areaUnit
    replacementCost
    currency
    conditionPercent
    conditionString
    sites {
      siteName
      numAssets
      areaSqm
      replacementCost
      conditionPercent
      conditionString
      assets {
        assetName
        areaSqm
        replacementCost
        conditionPercent
        conditionString
        components {
          componentName
          quantity
          unit
          materialCost
          conditionPercent
          conditionString
        }
      }
    }
    locations {
      name
      latitude
      longitude
    }
  }
}
