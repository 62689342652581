query analysisResult($input: QueryAnalysisRequestT!) {
  analysisResult(input: $input) {
    items {
      id
      assetID
      name
      state
      roi {
        longitude
        latitude
        altitude
      }
      errorMessage
      subErrorMessage
      expansionInMeters
      resolutionInMeters
      maxPointPlaneDistance
      thumbnailURLs
      imageURLs
      updatedAt
    }
  }
}