query instantAssessmentsBySite($input: QueryInstantAssessmentsBySiteRequestT!) {
  instantAssessmentsBySite(input: $input) {
    id
    status
    orgID
    orgName
    siteID
    siteName
    surveyDate
    surveyResourceID
    externalImageIDs
    reconstruction {
      id
      orgId
      siteId
      orgName
      siteName
      visible
      modelPriority
      streamNumber
      queuePriority
      queueSubPriority
      boundary {
        longitude
        latitude
      }
      imageGroups {
        day
        images {
          id
          hash
          dateTaken
          latitude
          longitude
          altitude
        }
      }
      imageCount
      state
      steps {
        id
        type
        state
        createdById
        createdAt
        updatedById
        updatedAt
        metadata {
          value {
            ... on ReconstructionMetadataATT {
              blockType
              imageDownsamplingPercentage
              keypointDensity
            }
            ... on ReconstructionMetadataCesium3DT {
              textureCompressionQuality
              maximumTextureSize
              geometricSimplification
              maxTileSizeInGB
            }
            ... on ReconstructionMetadataOrthophotoT {
              resolutionInMeters
              maximumImagePartDimensions
            }
            ... on ReconstructionMetadataOBJT {
              textureCompressionQuality
              maximumTextureSize
            }
          }
        }
      }
      replacesReconstructionID
      createdById
      createdAt
      updatedById
      updatedAt
      instantAssessment
    }
    aois {
      points {
        latitude
        longitude
      }
    }
    createdByID
    createdAt
    updatedByID
    updatedAt
  }
}
