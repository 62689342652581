import { MeasurementSystem, MeasurementType, SiteQueryComponent } from '~/models'
import { formatArea, formatDistance } from '../measure'

export function calculatePolygonArea(points: Cesium.Cartesian3[]) {
  const indices = (Cesium as any).PolygonPipeline.triangulate(points, [])
  let area = 0
  for (let i = 0; i < indices.length; i += 3) {
    const vector1 = points[indices[i]]
    const vector2 = points[indices[i + 1]]
    const vector3 = points[indices[i + 2]]

    // These vectors define the sides of a parallelogram (double the size of the triangle)
    const vectorC = Cesium.Cartesian3.subtract(vector2, vector1, new Cesium.Cartesian3())
    const vectorD = Cesium.Cartesian3.subtract(vector3, vector1, new Cesium.Cartesian3())

    // Area of parallelogram is the cross product of the vectors defining its sides
    const areaVector = Cesium.Cartesian3.cross(vectorC, vectorD, new Cesium.Cartesian3())

    // Area of the triangle is just half the area of the parallelogram, add it to the sum.
    area += Cesium.Cartesian3.magnitude(areaVector) / 2.0
  }

  return area
}

export function calculateComponentMeasurement(
  component: SiteQueryComponent,
  measurementSystem: MeasurementSystem
): string {
  if (component.measurement === MeasurementType.Area) {
    const measurement = calculatePolygonArea(
      component.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude))
    )
    return formatArea(measurement, false, measurementSystem)
  } else if (component.measurement === MeasurementType.Distance) {
    let measurement = 0
    const geodesic = new Cesium.EllipsoidGeodesic()

    for (let x = 1; x < component.points.length; x++) {
      const p0 = component.points[x - 1]
      const p1 = component.points[x]

      const c0 = Cesium.Cartographic.fromDegrees(p0.longitude, p0.latitude, p0.altitude)
      const c1 = Cesium.Cartographic.fromDegrees(p1.longitude, p1.latitude, p1.altitude)

      geodesic.setEndPoints(c0, c1)
      measurement += geodesic.surfaceDistance
    }
    return formatDistance(measurement)
  }

  return 'No measurement'
}

export function calculateComponentMeasurementWithoutLabel(component: SiteQueryComponent): number {
  if (component.measurement === MeasurementType.Area) {
    const measurement = calculatePolygonArea(
      component.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude))
    )
    return measurement
  } else if (component.measurement === MeasurementType.Distance) {
    let measurement = 0
    const geodesic = new Cesium.EllipsoidGeodesic()

    for (let x = 1; x < component.points.length; x++) {
      const p0 = component.points[x - 1]
      const p1 = component.points[x]

      const c0 = Cesium.Cartographic.fromDegrees(p0.longitude, p0.latitude, p0.altitude)
      const c1 = Cesium.Cartographic.fromDegrees(p1.longitude, p1.latitude, p1.altitude)

      geodesic.setEndPoints(c0, c1)
      measurement += geodesic.surfaceDistance
    }
    return measurement
  }

  return 0
}

export function formatComponentMeasurement(
  component: SiteQueryComponent,
  measurement: number,
  measurementSystem: MeasurementSystem
): string {
  if (component.measurement === MeasurementType.Area) {
    return formatArea(measurement, false, measurementSystem)
  } else if (component.measurement === MeasurementType.Distance) {
    return formatDistance(measurement)
  }

  return 'No measurement'
}
