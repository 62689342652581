import React, { useEffect } from 'react'
import { formatDistance, getDistance } from '~/components'
import {
  getMidpoint,
  LabelTemplate,
  pickFromPosition,
  Point,
  PointAndLineColor,
  pointFromPointInput,
  pointFromPosition,
} from '~/components/measure/utils'
import { PointInput3Dt } from '~/models'
import { useAppState } from '~/state'

interface AnnotationRulerDistanceProps {
  pointsChanged: (points: Cesium.Cartographic[], annotations: number[]) => void
  points: PointInput3Dt[]
  disableEdits?: boolean
  onlyZ?: boolean
}

export const AnnotationRulerDistance = (props: AnnotationRulerDistanceProps) => {
  const { map } = useAppState()

  useEffect(() => {
    const viewer = map.viewer
    const geodesic = new Cesium.EllipsoidGeodesic()

    const points = new Cesium.PointPrimitiveCollection()
    const tmpPoints = new Cesium.PointPrimitiveCollection()
    const tmpPolyLines = new Cesium.PolylineCollection()
    const labels = new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
    const mouseHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)

    map.viewer.scene.primitives.add(tmpPoints)
    map.viewer.scene.primitives.add(tmpPolyLines)
    map.viewer.scene.primitives.add(labels)

    let point1: Point, point2: Point
    let tmpPoint1: Point, tmpPoint2: Point, draggingPoint: Point
    let draggingPointMoved = false

    setTimeout(() => {
      init(tmpPolyLines, labels)

      if (props.points && props.points.length === 2) {
        point1 = points.add(pointFromPointInput(props.points[0])) as Point
        point2 = points.add(pointFromPointInput(props.points[1])) as Point
        tmpPoint1 = tmpPoints.add(pointFromPointInput(props.points[0])) as Point
        tmpPoint2 = tmpPoints.add(pointFromPointInput(props.points[1])) as Point
        draw(tmpPoint1, tmpPoint2, tmpPolyLines, labels)
      }

      if (props.disableEdits) {
        return
      }

      mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        if (
          map.viewer.scene.mode === Cesium.SceneMode.MORPHING ||
          !map.viewer.scene.pickPositionSupported ||
          points.length === 2
        ) {
          return
        }
        pickFromPosition(viewer, click.position, getIgnoredEntities()).then((picked) => {
          const cartesian = picked.cartesian
          if (!Cesium.defined(cartesian)) {
            return
          }

          if (points.length === 0) {
            point1 = points.add(pointFromPosition(cartesian.clone())) as Point
          } else if (points.length === 1) {
            point2 = points.add(pointFromPosition(cartesian.clone())) as Point
            pointsChanged(point1, point2)
          }
        })
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
        if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
          return
        }
        if (points.length !== 2) {
          return
        }
        pickFromPosition(viewer, click.position, getIgnoredEntities(true)).then((picked) => {
          const cartesian = picked.cartesian
          if (!Cesium.defined(cartesian)) {
            return
          }

          if (picked.model?.primitive === tmpPoint1 || picked.model?.primitive === tmpPoint2) {
            draggingPoint = picked.model.primitive
            map.viewer.scene.screenSpaceCameraController.enableInputs = false
          }
        })
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

      mouseHandler.setInputAction(() => {
        if (draggingPointMoved) {
          pointsChanged(tmpPoint1, tmpPoint2)
        }
        draggingPoint = undefined
        draggingPointMoved = false
        map.viewer.scene.screenSpaceCameraController.enableInputs = true
      }, Cesium.ScreenSpaceEventType.LEFT_UP)

      mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
        if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
          return
        }

        if (points.length === 2) {
          pickFromPosition(viewer, click.endPosition, getIgnoredEntities()).then((picked) => {
            const cartesian = picked.cartesian
            if (!Cesium.defined(cartesian)) {
              return
            }

            if (picked.model?.primitive === tmpPoint1 || picked.model?.primitive === tmpPoint2) {
              document.body.style.cursor = 'move'
            } else {
              document.body.style.cursor = 'default'
            }

            if (draggingPoint) {
              draggingPoint.position = picked.cartesian
              draggingPointMoved = true
              draw(tmpPoint1, tmpPoint2, tmpPolyLines, labels)
            }
          })
          return
        }

        pickFromPosition(viewer, click.endPosition, getIgnoredEntities()).then((picked) => {
          const cartesian = picked.cartesian
          if (!Cesium.defined(cartesian)) {
            return
          }

          // We haven't added any points yet.
          if (points.length === 0) {
            if (tmpPoints.length === 0) {
              tmpPoint1 = tmpPoints.add(
                pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
              ) as Point
            } else {
              tmpPoint1.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
            }
          }

          // We've added one point.
          if (points.length === 1) {
            if (tmpPoints.length === 1) {
              tmpPoint2 = tmpPoints.add(
                pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
              ) as Point
            } else {
              tmpPoint2.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
            }

            draw(tmpPoint1, tmpPoint2, tmpPolyLines, labels)
          }
        })
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    }, 0)

    function getIgnoredEntities(excludePoints?: boolean) {
      const toRet = [labels.get(0), labels.get(1), labels.get(2), labels.get(3), labels.get(4), tmpPolyLines, points]
      if (!excludePoints) {
        toRet.push(tmpPoints)
      }
      return toRet
    }

    function pointsChanged(point1: Point, point2: Point) {
      const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
      const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
      if (!props.onlyZ) {
        const distance = getDistance(geodesic, point1.position, point2.position)
        props.pointsChanged([point1GeoPosition, point2GeoPosition], [distance])
      } else {
        const carto1 = Cesium.Cartographic.fromCartesian(point1.position)
        const carto2 = Cesium.Cartographic.fromCartesian(point2.position)
        props.pointsChanged(
          [point1GeoPosition, point2GeoPosition],
          [Math.max(carto2.height, carto1.height) - Math.min(carto2.height, carto1.height)]
        )
      }
    }

    return () => {
      map.viewer.scene.primitives.remove(points)
      map.viewer.scene.primitives.remove(tmpPoints)
      map.viewer.scene.primitives.remove(tmpPolyLines)
      map.viewer.scene.primitives.remove(labels)
      mouseHandler.destroy()
    }
  }, [JSON.stringify(props.points), props.disableEdits])

  return <></>
}

function draw(point1: Point, point2: Point, polylines: Cesium.PolylineCollection, labels: Cesium.LabelCollection) {
  const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
  const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)

  polylines.get(0).positions = [
    Cesium.Cartesian3.fromRadians(point1GeoPosition.longitude, point1GeoPosition.latitude, point1GeoPosition.height),
    Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point2GeoPosition.height),
  ]
  polylines.get(1).positions = [
    Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point2GeoPosition.height),
    Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point1GeoPosition.height),
  ]
  polylines.get(2).positions = [
    Cesium.Cartesian3.fromRadians(point1GeoPosition.longitude, point1GeoPosition.latitude, point1GeoPosition.height),
    Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point1GeoPosition.height),
  ]

  let labelZ
  if (point2GeoPosition.height >= point1GeoPosition.height) {
    labelZ = point1GeoPosition.height + (point2GeoPosition.height - point1GeoPosition.height) / 2.0
  } else {
    labelZ = point2GeoPosition.height + (point1GeoPosition.height - point2GeoPosition.height) / 2.0
  }

  addDistanceLabel(point1, point2, labelZ, labels)
}

function addDistanceLabel(point1: Point, point2: Point, height: number, labels: Cesium.LabelCollection) {
  const ellipsoid = Cesium.Ellipsoid.WGS84
  const geodesic = new Cesium.EllipsoidGeodesic()
  const c1 = ellipsoid.cartesianToCartographic(point1.position)

  const distance = getDistance(geodesic, point1.position, point2.position)
  const horizontalDistance = getHorizontalDistance(point1, point2)
  const verticalDistance = getVerticalDistance(point1, point2)

  const angle = Cesium.Math.toDegrees(Math.asin(verticalDistance / distance))

  // Always add the hypotenuse distance.
  const label0 = labels.get(0)
  const label1 = labels.get(1)
  const label2 = labels.get(2)
  const label3 = labels.get(3)
  const label4 = labels.get(4)

  label0.text = formatDistance(distance)
  label0.position = getMidpoint(geodesic, point1, point2, height)

  const is3D = verticalDistance > 1
  if (is3D) {
    label1.text = formatDistance(horizontalDistance)
    label1.position = getMidpoint(geodesic, point1, point2, c1.height)
    label2.text = formatDistance(verticalDistance)
    label2.position = getMidpoint(geodesic, point2, point2, height)
    label3.text = `${(90 - angle).toFixed(1)}°`
    label3.position = point2.position
    label4.text = `${angle.toFixed(1)}°`
    label4.position = point1.position
  } else {
    label1.position = new Cesium.Cartesian3(0, 0, 0)
    label2.position = new Cesium.Cartesian3(0, 0, 0)
    label3.position = new Cesium.Cartesian3(0, 0, 0)
    label4.position = new Cesium.Cartesian3(0, 0, 0)
  }
}

function getHorizontalDistance(point1: Point, point2: Point): number {
  const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
  const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
  const geodesic = new Cesium.EllipsoidGeodesic()
  geodesic.setEndPoints(point1GeoPosition, point2GeoPosition)
  const meters = geodesic.surfaceDistance
  return meters
}

function getVerticalDistance(point1: Point, point2: Point): number {
  const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
  const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
  const heights = [point1GeoPosition.height, point2GeoPosition.height]
  const meters = Math.max(...heights) - Math.min(...heights)
  return meters
}

function init(tmpPolyLines: Cesium.PolylineCollection, labels: Cesium.LabelCollection) {
  tmpPolyLines.add({
    show: true,
    positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
    width: 1,
    material: new Cesium.Material({
      fabric: {
        type: 'Color',
        uniforms: {
          color: PointAndLineColor,
        },
      },
    }),
  })
  tmpPolyLines.add({
    show: true,
    positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
    width: 1,
    material: new Cesium.Material({
      fabric: {
        type: 'PolylineDash',
        uniforms: {
          color: PointAndLineColor,
        },
      },
    }),
  })
  tmpPolyLines.add({
    show: true,
    positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
    width: 1,
    material: new Cesium.Material({
      fabric: {
        type: 'PolylineDash',
        uniforms: {
          color: PointAndLineColor,
        },
      },
    }),
  })

  labels.add({
    ...LabelTemplate,
    font: '13px monospace',
    position: new Cesium.Cartesian3(0, 0, 0),
  })

  labels.add({
    ...LabelTemplate,
    position: new Cesium.Cartesian3(0, 0, 0),
  })

  labels.add({
    ...LabelTemplate,
    position: new Cesium.Cartesian3(0, 0, 0),
  })

  labels.add({
    ...LabelTemplate,
    position: new Cesium.Cartesian3(0, 0, 0),
  })

  labels.add({
    ...LabelTemplate,
    position: new Cesium.Cartesian3(0, 0, 0),
  })
}
