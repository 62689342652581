import { Point, Rect } from 'openseadragon'
import { PolygonShape } from '~/map/image-tagger-shape'
import { BaseShape, ShapeConstructor } from '../shapes/base-shape'


export class Drawer {
  readonly shapes: Map<string, ShapeConstructor> = new Map()
  private drawerActiveShape: string
  public activeShape?: BaseShape
  shapeChanged: (rect: Rect, shape: BaseShape) => void

  constructor(private viewer: OpenSeadragon.Viewer) {
    this.addShape('custom', PolygonShape)
    this.setDrawerShape('custom')
  }

  get drawing() {
    return !!this.activeShape && this.activeShape.isDrawing
  }

  addShape(name: string, shapeConstructor: ShapeConstructor) {
    this.shapes.set(name, shapeConstructor)
  }

  setDrawerShape(name: string) {
    if (this.shapes.has(name)) {
      this.drawerActiveShape = name
    } else {
      throw new Error(`no shape found with name ${name}`)
    }
  }

  private getNewShape() {
    const shapeConstructor = this.shapes.get(this.drawerActiveShape)
    if (!shapeConstructor) {
      throw new Error(`no constructor found for ${this.drawerActiveShape}`)
    }
    return new shapeConstructor(this.viewer, this.shapeChanged)
  }

  onMouseDown(point: Point): boolean {
    if (this.activeShape && !this.activeShape.isDrawing) {
      this.activeShape = undefined
    }
    if (!this.activeShape) {
      this.activeShape = this.getNewShape()
      this.activeShape.startDrawing()
    }

    return this.activeShape.onMouseDown(point)
  }

  onRightClick(point: Point) {
    if (this.activeShape && !this.activeShape.isDrawing) {
      this.activeShape = undefined
    }
    if (!this.activeShape) {
      this.activeShape = this.getNewShape()
      this.activeShape.startDrawing()
    }

    this.activeShape.onRightClick(point)
  }

  onMouseMove(point: Point): boolean {
    if (!this.activeShape || !this.activeShape.isDrawing) {
      return true
    }

    return this.activeShape.onMouseMove(point)
  }

  onMouseUp(point: Point): BaseShape {
    if (!this.activeShape || !this.activeShape.isDrawing) {
      return
    }

    this.activeShape.onMouseUp(point)
    const drawnShape = this.activeShape

    return drawnShape
  }

  reset() {
    this.activeShape = undefined
  }
}
