import React from 'react'

type Props = {
  size: number
}

export const IconPictures = ({ size }: Props) => {
  return (
    <svg width={size} height={size} viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.27702 3.00732H27.6108C29.2952 3.00732 30.6666 4.39391 30.6666 6.09676V19.6894C30.6666 21.3923 29.2952 22.7788 27.6108 22.7788H9.27702C7.59295 22.7788 6.22146 21.3923 6.22146 19.6894V6.09676C6.22146 4.39391 7.59295 3.00732 9.27702 3.00732ZM27.6108 5.47882H9.27702C8.93979 5.47882 8.66605 5.75553 8.66605 6.0967V17.7606L12.0394 14.3511C12.8742 13.506 14.2346 13.506 15.0706 14.3511L16.5495 15.8427L21.089 10.334C21.4948 9.84212 22.0888 9.55794 22.7219 9.55435C23.3586 9.56901 23.9514 9.82856 24.361 10.3141L28.222 14.869V6.09676C28.222 5.75558 27.9483 5.47882 27.6108 5.47882ZM4.64239 21.4799C5.27445 23.3656 7.0149 24.6322 8.97158 24.6322H26.715L25.6139 28.1723C25.3021 29.3761 24.1898 30.1929 22.9726 30.1929C22.735 30.1927 22.4985 30.162 22.2686 30.1015L3.36871 24.9843C1.91066 24.5777 1.04164 23.0518 1.42298 21.5799L4.38813 11.5731V19.9983C4.38813 20.4185 4.45058 20.8384 4.59965 21.3378L4.64239 21.4799ZM14.7772 9.18575C14.7772 10.5488 13.6811 11.6573 12.3328 11.6573C10.9847 11.6573 9.88827 10.5487 9.88827 9.18575C9.88827 7.8228 10.9846 6.71425 12.3328 6.71425C13.6811 6.71425 14.7772 7.82275 14.7772 9.18575Z'
        fill='#A3A3A3'
      />
    </svg>
  )
}
