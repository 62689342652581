import { useEffect } from 'react'
import { MarkerIconType, useAppState } from '~/state'
import { IssueManuallyTaggedImageDtoT, Issue, IssueStatus } from '~/models'
import { useLocation } from '~/components'
import { ClientPermission, useUser } from '~/base'

export function useIssueLayers(addNumbers = false) {
  const { map, view, issues, timeline } = useAppState()
  const location = useLocation()
  const user = useUser()
  const canViewIssues = user.hasPermission(ClientPermission.IssuesList)

  useEffect(() => {
    if (!issues.issues || issues.addingBulk || issues.movingBulk || !issues.show || !issues.draw || !canViewIssues) {
      return
    }

    const markers = issues.issues.map((i: Issue) =>
      map.addMarker(
        i.id,
        [i.location?.longitude || 0, i.location?.latitude || 0, i.location?.altitude || 0],
        () => {
          if (i.assetID) {
            location.setLocation(`/${view.siteID}/${timeline.activeSurvey.id}/assets/${i.assetID}/issue/${i.id}`)
          } else {
            location.setLocation(`/${view.siteID}/${timeline.activeSurvey.id}/issue/${i.id}`)
          }
        },
        addNumbers ? i.number + '' : null,
        addNumbers ? 32 : i.id === view.issueID ? 32 : 16,
        '#FFFFFF00',
        i.status === IssueStatus.Complete
          ? MarkerIconType.IssueCompleted
          : i.riskType === 'Low'
          ? MarkerIconType.IssueLow
          : i.riskType === 'Medium'
          ? MarkerIconType.IssueMedium
          : i.riskType === 'High'
          ? MarkerIconType.IssueHigh
          : MarkerIconType.IssueLow
      )
    )

    return () => {
      markers.forEach((m) => map.removeMarker(m))
    }
  }, [
    issues.issues?.length,
    map.projectionType,
    issues.addingBulk,
    issues.movingBulk,
    issues.show,
    view.issueID,
    timeline.activeSurvey?.id,
    issues.draw,
    canViewIssues,
  ])
}

type EntityWithIssues = {
  id: string
  issues: Issue[]
}

export function useIssueLayersForEntity(entity: EntityWithIssues, addNumbers: boolean, issueIDs: string[]) {
  const { map, view, timeline, issues } = useAppState()
  const location = useLocation()
  const user = useUser()
  const canViewIssues = user.hasPermission(ClientPermission.IssuesList)

  useEffect(() => {
    if (!entity || issues.addingBulk || issues.movingBulk || !issues.draw || !canViewIssues) {
      return
    }

    const markers = entity.issues
      .filter((i) => i.surveyID === timeline.activeSurvey?.id)
      .filter(i => {
        if (issueIDs.length === 0) {
          return true
        }

        return issueIDs.findIndex(x => x === i.id) !== -1
      })
      .map((i: IssueManuallyTaggedImageDtoT) =>
        map.addMarker(
          i.id,
          [i.location?.longitude || 0, i.location?.latitude || 0, i.location?.altitude || 0],
          () => {
            if (i.assetID) {
              location.setLocation(`/${view.siteID}/${timeline.activeSurvey.id}/assets/${i.assetID}/issue/${i.id}`)
            } else {
              location.setLocation(`/${view.siteID}/${timeline.activeSurvey.id}/issue/${i.id}`)
            }
          },
          addNumbers ? i.number + '' : null,
          addNumbers ? 32 : i.id === view.issueID ? 32 : 16,
          '#FFFFFF00',
          i.status === IssueStatus.Complete
            ? MarkerIconType.IssueCompleted
            : i.riskType === 'Low'
            ? MarkerIconType.IssueLow
            : i.riskType === 'Medium'
            ? MarkerIconType.IssueMedium
            : i.riskType === 'High'
            ? MarkerIconType.IssueHigh
            : MarkerIconType.IssueLow
        )
      )

    return () => {
      markers.forEach((m) => map.removeMarker(m))
    }
  }, [
    entity?.id,
    entity?.issues,
    map.projectionType,
    issues.addingBulk,
    issues.movingBulk,
    view.issueID,
    timeline.activeSurvey?.id,
    issues.draw,
    canViewIssues,
  ])
}
