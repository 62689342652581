import { useState, useEffect } from 'react'
import { useLocation, useRoute } from '../components/helpers'

interface ViewState {
  siteID?: string
  assetID?: string
  issueID?: string
  boundaryID?: string
  surveyID?: string
  lidarHash?: string
  groupID?: string
  showingGroups: boolean
  showingInstantAssessmentDemo: boolean
}

function computeViewState(route: any): ViewState {
  const a = route.params['a']
  const b = route.params['b']
  const c = route.params['c']
  const d = route.params['d']
  const e = route.params['e']
  const f = route.params['f']

  let siteID = ''
  let surveyID = ''
  let assetID = ''
  let issueID = ''
  let boundaryID = ''
  let showingGroups = false
  let lidarHash = ''
  let groupID = ''
  let showingInstantAssessmentDemo = false

  if (!a) {
    // Use defaults. This means we're viewing all sites.
  } else if (a === 'instant-assess') {
    showingInstantAssessmentDemo = true
  } else if (a === 'groups') {
    groupID = b
    showingGroups = true
  } else if (a === '_reports') {
    siteID = c
    surveyID = d
    assetID = e
  } else {
    // Viewing a site.
    siteID = a

    if (b === 'lidar') {
      lidarHash = c
    } else {
      surveyID = b

      if (c === 'assets') {
        assetID = d

        if (e === 'issue') {
          issueID = f
        }

        if (e === 'boundary') {
          boundaryID = f
        }
      } else if (c === 'issue') {
        issueID = d
      } else if (c === 'boundary') {
        boundaryID = d
      }
    }
  }

  return {
    siteID,
    surveyID,
    assetID,
    issueID,
    boundaryID,
    lidarHash,
    groupID,
    showingGroups,
    showingInstantAssessmentDemo,
  }
}

export function useViewState() {
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location.location)

  const route = useRoute<{
    siteID?: string
    surveyID?: string
    entityType?: string
    entityID?: string
    entitySubType?: string
    entitySubID?: string
    operation?: string
  }>('/:a/:b?/:c?/:d?/:e?/:f?')

  const [currentState, setCurrentState] = useState<ViewState>(computeViewState(route))

  useEffect(() => {
    if (currentLocation !== location.location) {
      setCurrentLocation(location.location)
      setCurrentState(computeViewState(route))
    }
  }, [location.location])

  return currentState
}
