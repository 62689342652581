import { ComponentDtoT } from '~/models'

export function sortComponents(a: ComponentDtoT, b: ComponentDtoT) {
  const aFullName = (a.name || a.componentTypeName).toLowerCase().replace(/-+/ig, '').replace(/\s+/ig, '')
  const bFullName = (b.name || b.componentTypeName).toLowerCase().replace(/-+/ig, '').replace(/\s+/ig, '')

  if (aFullName === bFullName) {
    return 0
  }

  return aFullName > bFullName ? -1 : 1
}
