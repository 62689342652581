import React, { useEffect, useState } from 'react'
import { BaseLayerType, useAppState, useRefState } from '~/state'

import { PolygonColorRed, PolygonRenderer, PolygonRendererCreate, PolygonRendererEdit } from '../polygon-renderer'
import { BoundaryCreateForm } from './boundaries-create-form'

interface BoundariesCreateProps {
  onComplete: (points?: boolean) => Promise<void>
}

export const BoundariesCreate = (props: BoundariesCreateProps) => {
  const { map, timeline } = useAppState()
  const [points, setPoints] = useRefState<Cesium.Cartesian3[]>([])
  const [pointsFromKML, setPointsFromKML] = useRefState<Cesium.Cartesian3[]>([])
  const [havePointsFromKML, setHavePointsFromKML] = useState(false)

  // Set map state.
  useEffect(() => {
    map.setShowControls(false)
    timeline.setHidden(true)
    map.setBaseLayerType(BaseLayerType.Satellite)

    return () => {
      map.setShowControls(true)
      timeline.setHidden(false)
    }
  }, [])

  // Draing polygons.
  useEffect(() => {
    let renderer: PolygonRenderer
    if (havePointsFromKML) {
      renderer = new PolygonRendererEdit(
        pointsFromKML.current.map((p) => {
          const pos = Cesium.Cartographic.fromCartesian(p)
          return {
            latitude: (pos.latitude / Math.PI) * 180,
            longitude: (pos.longitude / Math.PI) * 180,
          }
        }),
        map,
        PolygonColorRed,
        (points) => {
          setPointsFromKML(() => points)
        }
      )
    } else {
      renderer = new PolygonRendererCreate(map, PolygonColorRed, (points) => {
        if (!points) {
          props.onComplete()
          return
        }
        setPoints(() => points)
        renderer.destroy()
        renderer = new PolygonRendererEdit(
          points.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
          map,
          PolygonColorRed,
          (points) => {
            setPoints(() => points)
          }
        )
      })
    }

    return () => {
      renderer.destroy()
    }
  }, [havePointsFromKML])

  return (
    <div className='boundaries-draw'>
      <BoundaryCreateForm
        points={havePointsFromKML ? pointsFromKML : points}
        setPointsFromKML={(points) => {
          setPointsFromKML(points)
          setHavePointsFromKML(true)
        }}
        complete={() => props.onComplete(true)}
        cancel={() => props.onComplete()}
      />
      <div></div>
    </div>
  )
}
