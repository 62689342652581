import React from 'react'
import { Select } from '~/components'
import { DefectType, DefectSubType } from '~/models'

interface DefectTypeSelectProps {
  defectType?: DefectType
  defectSubType?: DefectSubType
  defectTypeID?: string
  defectSubTypeID?: string
  defectTypes: DefectType[]
  onChange: (defectType: DefectType, defectSubType?: DefectSubType) => void
}

export const DefectTypeSelect = (props: DefectTypeSelectProps) => {
  let selected = props.defectSubType || props.defectType
  if (props.defectTypeID) {
    let tmpSelected = props.defectTypes.find(x => x.id === props.defectTypeID)

    if(tmpSelected && props.defectSubTypeID) {
      selected = tmpSelected.subTypes.find(x => x.id === props.defectSubTypeID)
    } else {
      selected = tmpSelected
    }
  }

  return (
    <Select<DefectType | DefectSubType>
      id='defect-types'
      label='Defect Type'
      placeholder='Select a defect type'
      options={props.defectTypes
        .filter((defectType) => !defectType.archived)
        .map((defectType) => {
          return {
            id: defectType.id,
            name: defectType.name,
            value: defectType,
            items: defectType.subTypes.map((s) => ({
              id: s.id,
              name: s.name,
              value: s,
              items: [],
            })),
          }
        })}
      onChange={(selected) => {
        for (const defectType of props.defectTypes) {
          if (defectType.id === selected.id) {
            props.onChange(defectType, undefined)
            return
          }

          for (const defectSubType of defectType.subTypes) {
            if (defectSubType.id === selected.id) {
              props.onChange(defectType, defectSubType)
              return
            }
          }
        }
      }}
      selectedValue={selected}
    />
  )
}
