import React from 'react'
import { SupplierUserExistsForInvitationQuery, SupplierUserExistsForInvitationQueryVariables } from '~/models'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { useLocation } from '~/components'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import { useQuery } from '~/components'
import { AuthAcceptSupplierInviteExists } from './auth-accept-supplier-invite-exists'
import { AuthAcceptSupplierInviteNewUser } from './auth-accept-supplier-invite-new-user'
import userExistsForInvitationQuery from './query-supplier-user-exists-for-invitation.gql'

export const AuthAcceptSupplierInvite = () => {
  const location = useLocation()
  const token = new URLSearchParams(window.location.search).get('token')

  if (!token) {
    location.setLocation('/404')
  }

  const query = useQuery<SupplierUserExistsForInvitationQuery, SupplierUserExistsForInvitationQueryVariables>(
    userExistsForInvitationQuery,
    {
      variables: {
        input: {
          token,
        },
      },
    }
  )

  const loading = query.loading
  const exists = query.data?.supplierUserExistsForInvitation?.exists

  return (
    <>
      {loading && (
        <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
          <Helmet title='Accept Team Invite' />
          <div className='subheader-dark mt-5 mb-5'>Team Invitation Confirmation</div>
          {query.error && <AuthError className='alert alert-warning mb-4' error={query.error} />}
          <div className='subheader-dark mt-5 mb-5'>Loading...</div>
        </AuthLayout>
      )}

      {exists === true && <AuthAcceptSupplierInviteExists token={token} />}
      {exists === false && <AuthAcceptSupplierInviteNewUser token={token} />}
    </>
  )
}
