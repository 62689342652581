import { LeftClickPosition, MouseMovePosition } from '~/state'

import { PolygonRenderer, Map } from './polygon-renderer'

export class PolygonRendererCreate extends PolygonRenderer {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _polylineEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _onChange: (points?: Cesium.Cartesian3[]) => void
  private _mousePosition: Cesium.Cartesian3
  private _lastMousePoint: Cesium.PointPrimitive
  private _done: boolean

  constructor(map: Map, color: string, onChange: (points?: Cesium.Cartesian3[]) => void) {
    super(map, color)

    this._positions = []
    this._points = new Cesium.PointPrimitiveCollection()
    this._onChange = onChange
    this._done = false

    const polyline = this.createPolylineConstructor(
      new Cesium.CallbackProperty(() => {
        if (this._done) {
          return [...this._positions, this._positions[0]]
        }
        return [...this._positions, this._mousePosition]
      }, false),
      true
    )

    this._polylineEntity = map.viewer.entities.add({ polyline })
    this._pointsEntity = map.viewer.scene.primitives.add(this._points)
  }

  public destroy() {
    this._map.viewer.scene.primitives.remove(this._pointsEntity)
    this._map.viewer.entities.remove(this._polylineEntity)
    this._pointsEntity = undefined
    this._polylineEntity = undefined
    super.destroy()
  }

  public onLeftClick(pos: LeftClickPosition) {
    if(!this._pointsEntity) {
      return
    }
    if (pos.picked && pos.picked.id === 'point-0') {
      this._done = true
      this._onChange(this._positions)
      return
    }

    if (this._done) {
      return
    }

    this._points.add(this.createPoint(pos.cartesian, this._positions.length))
    this._positions.push(pos.cartesian)
  }

  public onMiddleClick() {
    // Ignored.
  }

  public onRightClick() {
    // Ignored.
  }

  public onLeftDown() {
    // Ignored.
  }

  public onLeftUp() {
    // Ignored.
  }

  public onMouseMove(pos: MouseMovePosition) {
    if(!this._pointsEntity) {
      return
    }
    if (this._lastMousePoint) {
      this._points.remove(this._lastMousePoint)
    }
    if (this._done) {
      return
    }

    this._mousePosition = pos.cartesian

    if (pos.picked && pos.picked.id === 'point-0') {
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'default'
      this._lastMousePoint = this._points.add(this.createPoint(pos.cartesian, -1))
    }
  }

  public onKeydown(e: KeyboardEvent) {
    if(!this._pointsEntity) {
      return
    }
    
    // Complete Site on Enter
    if (e.key === 'Enter') {
      this._done = true
      this._onChange(this._positions)
      return
    }
  }
}
