import React, { useState } from 'react'
import { Avatar, timeSince, Transition, useToasts } from '~/components'
import { ClientPermission, useUser } from '~/base'
import { useMutation } from '~/components'
import {
  OrganizationRole,
  ResendInviteEmailMutation,
  MutationResendInviteEmailArgs,
  orgRoleToString,
  orgRoleAccess,
  UserInvitationDtoT,
  UserDtoT,
  UserOrganizationDtoT,
  UserInvitationStatus,
} from '~/models'
import { RoleDropdown } from './people-role-dropdown'
import RESEND_INVITE_EMAIL from './mutation-resend-invite-email.gql'
 
interface PersonRowProps {
  reload: () => Promise<any>
  invite?: Pick<UserInvitationDtoT, 'role' | 'id' | 'firstName' | 'lastName' | 'email' | 'status' | 'createdAt'>
  user?: Pick<UserOrganizationDtoT, 'role'> & {
    user: Pick<UserDtoT, 'id' | 'firstName' | 'lastName' | 'email' | 'emailConfirmedAt'>
  }
}

export function PersonRow(props: PersonRowProps) {
  const user = useUser()
  const [sendingMail, setSendingMail] = useState(false)
  const toasts = useToasts()

  const [execute] = useMutation<ResendInviteEmailMutation, MutationResendInviteEmailArgs>(RESEND_INVITE_EMAIL)

  const executeResendInviteEmail = (id: string) => {
    execute({
      variables: {
        input: {
          inviteId: id,
        },
      },
    })
      .then(() => {
        toasts.addTopRight('Email resent to user.')
        setSendingMail(false)
      })
      .catch((err) => {
        toasts.addTopRight(err.message)
      })
  }

  const id = props.user?.user?.id || props.invite?.id || ''
  const email = props.user?.user?.email || props.invite?.email || ''
  const firstName = props.user?.user?.firstName || props.invite?.firstName || ''
  const lastName = props.user?.user?.lastName || props.invite?.lastName || ''
  const role = props.user?.role || props.invite?.role || OrganizationRole.Guest
  const createdAt = props.user?.user?.emailConfirmedAt || props.invite?.createdAt || 0

  const title = (firstName ? `${firstName} ${lastName}` : email) || ''
  const subtitle = (firstName ? email : `${firstName} ${lastName}`) || ''
  const isPending = props.invite?.status === UserInvitationStatus.Pending
  const hasLoaded = props.invite !== undefined || props.user !== undefined
  const canChangeRole = hasLoaded && user.hasPermission(ClientPermission.TeamUpdate)

  return (
    <li className='people-tab-content-list-item'>
      <Avatar className='people-tab-content-avatar' title={title} width={48} height={48} />
      <div className='mr-auto'>
        <div className='people-tab-content-username'>
          <Transition width={200} in={hasLoaded}>
            {title} {email === user.email ? '(you)' : ''}
          </Transition>
        </div>
        {isPending && (
          <div className='people-tab-content-email'>
            Invited {timeSince(createdAt)} &middot;{' '}
            <span
              style={{ textDecoration: sendingMail ? 'none' : 'underline' }}
              onClick={() => {
                setSendingMail(true)
                executeResendInviteEmail(id)
              }}
            >
              {sendingMail ? 'Sending...' : 'Resend'}
            </span>
          </div>
        )}
        {!isPending && <div className='people-tab-content-email'>{subtitle}</div>}
      </div>
      <div className='people-tab-content-role'>
        {canChangeRole && <RoleDropdown value={role} userID={id} pending={isPending} reload={props.reload} />}
        {!canChangeRole && hasLoaded && (
          <>
            {<Transition in={hasLoaded}>{orgRoleToString(role)}</Transition>}
            <div>
              <Transition in={hasLoaded}>{orgRoleAccess(role)}</Transition>
            </div>
          </>
        )}
        {!canChangeRole && !hasLoaded && (
          <Transition in={hasLoaded} width={100}>
            {orgRoleToString(role)}
          </Transition>
        )}
      </div>
    </li>
  )
}
