import React from 'react'
import { useLocation, getTracking } from '~/components'

export const AssetError = () => {
  const location = useLocation()
  getTracking().event({
    category: 'Error',
    action: `User received an asset error`,
    label: 'Asset',
  })

  return (
    <div className='site-erorr'>
      Error
      <button className='btn mt-3' onClick={() => location.setLocation('/')}>
        Back
      </button>
    </div>
  )
}
