import React, { CSSProperties } from 'react'
import { classes } from '../helpers'
import { SimpleBar } from '../simple-bar'
import { DrawerPopout, DrawerPopoutControls, DrawerPopoutPageNumber, DrawerPopoutTableContainer } from './drawer-popout'

interface DrawerPopoutListProps {
  width: number
  title: string
  count: number
  total: number
  emptyText: string
  children: any
  controls?: JSX.Element
  pageNumberControls?: JSX.Element
}

export const DrawerPopoutList = (props: DrawerPopoutListProps) => {
  const Controls = props.controls
  return (
    <DrawerPopout width={props.width} title={props.title}>
      {Controls && <DrawerPopoutControls>{props.controls}</DrawerPopoutControls>}
      <DrawerPopoutPageNumber total={props.total} count={props.count} pageNumberControls={props.pageNumberControls} />
      <DrawerPopoutTableContainer>
        <SimpleBar>
          <div className='drawer-popout-list'>
            {props.children}
            {props.count === 0 && <p className='drawer-popout-list-empty'>{props.emptyText}</p>}
          </div>
        </SimpleBar>
      </DrawerPopoutTableContainer>
    </DrawerPopout>
  )
}

interface DrawerPopoutListCardProps {
  className?: string
  children?: any
  active?: boolean
  onClick?: (e: React.MouseEvent) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  style?: CSSProperties
  title?: string
}

export const DrawerPopoutListCard = (props: DrawerPopoutListCardProps) => {
  return (
    <div
      className={classes({
        'drawer-popout-list-card': true,
        [props.className]: typeof props.className === 'string',
        active: props.active,
      })}
      style={props.style}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      title={props.title}
    >
      {props.children}
    </div>
  )
}
