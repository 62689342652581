import { Point } from 'openseadragon'
import { Drawer } from '../drawer/draw-shape'
import { BaseShape } from '../shapes/base-shape'
import { CanvasBase } from './canvas-base'

export type DrawEndCallback = (shape: BaseShape) => void | Promise<void>

export class FrontCanvas extends CanvasBase {
  drawer: Drawer

  private onDrawEndCallbacks: DrawEndCallback[] = []

  constructor(private viewer: OpenSeadragon.Viewer) {
    super()
    this.drawer = new Drawer(viewer)
    this.init()
  }

  private executeDrawEndCallback(shape: BaseShape) {
    this.onDrawEndCallbacks.map((cb) => cb(shape))
  }

  private init() {
    ;(this.viewer as any).zoomPerClick = 1
    this.canvas.draggable = false
    this.canvas.addEventListener('wheel', this.onWheel.bind(this), false)
    this.canvas.addEventListener('pointerdown', this.onMouseDown.bind(this), false)
    this.canvas.addEventListener('pointermove', this.onMouseMove.bind(this), false)
    this.canvas.addEventListener('pointerup', this.onPointerEvent.bind(this), false)
    this.canvas.addEventListener('pointerenter', this.onPointerEvent.bind(this), false)
    this.canvas.addEventListener('pointerleave', this.onPointerEvent.bind(this), false)
    this.canvas.addEventListener('pointerout', this.onPointerEvent.bind(this), false)
    this.canvas.addEventListener('pointerover', this.onPointerEvent.bind(this), false)
    this.canvas.addEventListener('contextmenu', (e) => {
      e.preventDefault()
    })
    window.addEventListener('pointerup', this.onMouseUp.bind(this))
  }

  dispose() {
    ;(this.viewer as any).zoomPerClick = 2
    this.canvas.removeEventListener('wheel', this.onWheel.bind(this))
    this.canvas.removeEventListener('pointerdown', this.onMouseDown.bind(this))
    this.canvas.removeEventListener('pointermove', this.onMouseMove.bind(this))
    this.canvas.removeEventListener('pointerup', this.onPointerEvent.bind(this))
    this.canvas.removeEventListener('pointerenter', this.onPointerEvent.bind(this))
    this.canvas.removeEventListener('pointerleave', this.onPointerEvent.bind(this))
    this.canvas.removeEventListener('pointerout', this.onPointerEvent.bind(this))
    this.canvas.removeEventListener('pointerover', this.onPointerEvent.bind(this), false)
    window.removeEventListener('pointerup', this.onMouseUp.bind(this))
  }

  private onWheel(e: WheelEvent) {
    this.dispatchEvent(new WheelEvent(e.type, e))
  }

  private onPointerEvent(e: PointerEvent) {
    this.dispatchEvent(new PointerEvent(e.type, e))
  }

  private dispatchEvent(e: Event) {
    ;((this.viewer as any).outerTracker.element.firstChild as HTMLElement).dispatchEvent(e)
    this.requestUpdate()
  }

  private onMouseDown(e: PointerEvent) {
    if (e.button !== 0 && e.button !== 2) {
      return
    }
    const point = this.getCoordsFromMouseEvent(e)

    const boundPoint = this.keepInBounds(point.clone())
    if (point.x !== boundPoint.x || point.y !== boundPoint.y) {
      return
    }

    if (e.button === 0) {
      const mustPublish = this.drawer.onMouseDown(point)
      this.add(this.drawer.activeShape)
      this.checkIfDrawingFinished(this.drawer.activeShape)
      this.requestUpdate()
      if (mustPublish) {
        this.dispatchEvent(new PointerEvent(e.type, e))
      }
    } else {
      this.drawer.onRightClick(point)
      this.requestUpdate()
    }
  }

  private onMouseUp(e: PointerEvent) {
    if (e.button !== 0) {
      return
    }
    const point = this.getCoordsFromMouseEvent(e)
    if(!point) {
      return
    }
    this.keepInBounds(point)
    this.drawer.onMouseUp(point)

    this.checkIfDrawingFinished(this.drawer.activeShape)
    this.requestUpdate()
    //this.dispatchEvent(new PointerEvent(e.type, e))
  }

  private onMouseMove(e: PointerEvent) {
    if (!this.drawer.drawing) {
      return
    }
    const point = this.getCoordsFromMouseEvent(e)
    this.keepInBounds(point)
    const mustPublish = this.drawer.onMouseMove(point)
    this.checkIfDrawingFinished(this.drawer.activeShape)
    this.requestUpdate()
    if (mustPublish) {
      this.dispatchEvent(new PointerEvent(e.type, e))
    }
  }

  private keepInBounds(point: Point) {
    const item = this.viewer.world.getItemAt(0)
    if(!item) {
      return point
    }
    const { x: width, y: height } = item.getContentSize()
    point.x = Math.max(0, Math.min(point.x, width ?? 0))
    point.y = Math.max(0, Math.min(point.y, height ?? 0))
    return point
  }

  deactivate(): void {
    this.drawer?.reset()
    this.clear()
    return super.deactivate()
  }

  onDrawEnd(callback: DrawEndCallback) {
    this.onDrawEndCallbacks.push(callback)
    return () => this.offDrawEnd.apply(this, [callback])
  }

  offDrawEnd(callback: DrawEndCallback) {
    this.onDrawEndCallbacks = this.onDrawEndCallbacks.filter((cb) => cb !== callback)
  }

  getCoordsFromMouseEvent(event: MouseEvent) {
    const point = new Point(event.pageX, event.pageY)
    try {
      return this.viewer?.viewport.windowToImageCoordinates(point)
    } catch {
      return undefined
    }
  }

  checkIfDrawingFinished(drawnShape: BaseShape) {
    if (!this.drawer.drawing && drawnShape) {
      this.remove(drawnShape)
      this.executeDrawEndCallback(drawnShape)
    }
  }
}
