import Offset from 'polygon-offset'
import { PointT } from '~/models'
import simplify from 'simplify-js'

export function getOffsetPolygon(points: PointT[], factor: number, inMeters = true, inset = false): PointT[] {
  if (points.length === 0) {
    return []
  }

  let pointsToUse = [...points].map((p) => [p.longitude, p.latitude])
  if (inMeters === true) {
    factor /= 111111 * Math.cos((points[0].latitude * Math.PI) / 180)
  }

  pointsToUse = simplify(pointsToUse.map((p) => ({ x: p[0], y: p[1] })), factor/4).map(p => ([p.x, p.y]))

  const offset = new Offset()
  const margined = inset ? offset.data(pointsToUse).padding(factor) : offset.data(pointsToUse).margin(factor)

  return margined[0].map((p) => {
    return {
      longitude: p[0],
      latitude: p[1],
    }
  })
}
