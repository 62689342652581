import React from 'react'
import './column.scss'

type Spacing = 0 | 2 | 3 | 4 | 6 | 8 | 12 | 16 | 20 | 24 | 28 | 30 | 32

type AlignX = 'start' | 'center' | 'end' | 'stretch'
type AlignY = 'start' | 'center' | 'end' | 'stretch'

type Props = {
  spacing: Spacing
  alignX?: AlignX
  alignY?: AlignY
  fill?: boolean
  shrink?: boolean
  children: React.ReactNode
}

export const Column = ({ spacing, alignX, alignY, fill, shrink, children }: Props) => {
  const classNames = [
    'column',
    alignX && `alignX_${alignX}`,
    alignY && `alignY_${alignY}`,
    fill && 'fill',
    shrink && 'shrink',
    `spacing-${spacing}`,
  ]
    .filter(Boolean)
    .join(' ')

  return <div className={classNames}>{children}</div>
}
