import React, { useEffect, useState } from 'react'
import { NoItemTag } from '~/components'
import { AnnotationsFormCreateProps } from './annotations-form-create-props'
import { AnnotationRulerDistance } from './rulers/annotations-ruler-distance'

enum MeasureGEVState {
  None,
  GroundToEaves,
  EavesToRidge,
}

export const AnnotationsFormCreateGroundEavesRidge = (props: AnnotationsFormCreateProps) => {
  const [currentState, setCurrentState] = useState(MeasureGEVState.GroundToEaves)

  const groundPoints =
    Array.isArray(props.model.points) &&
    props.model.points.length >= 2 &&
    props.model.points[0] !== undefined &&
    props.model.points[1] !== undefined
      ? [...props.model.points].splice(0, 2)
      : undefined

  const eavesPoints =
    Array.isArray(props.model.points) &&
    props.model.points.length >= 4 &&
    props.model.points[2] !== undefined &&
    props.model.points[3] !== undefined
      ? [...props.model.points].splice(2, 2)
      : undefined

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      const key = e.key.toLocaleLowerCase()
      if (key === 'g') {
        setCurrentState(MeasureGEVState.GroundToEaves)
      } else if (key === 'e') {
        setCurrentState(MeasureGEVState.EavesToRidge)
      }
    }

    if (!props.nameFocused) {
      window.addEventListener('keydown', onKeyDown, false)
    }

    return () => {
      if (!props.nameFocused) {
        window.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [props.nameFocused, props.asset])

  return (
    <div>
      <NoItemTag
        className='annotations-no-item-tag'
        canAdd
        canAddText={
          currentState === MeasureGEVState.GroundToEaves ? 'Measuring Ground to Eaves' : 'Measure Ground to Eaves (G)'
        }
        cantAddText=''
        onClick={() => {
          setCurrentState(MeasureGEVState.GroundToEaves)
          const newPoints = [...(props.model.points || [])]
          const newMesurements = [...(props.model.measurements || [])]
          newPoints[0] = undefined
          newPoints[1] = undefined
          newMesurements[0] = undefined
          props.setModel({
            ...props.model,
            points: newPoints,
            measurements: newMesurements,
          })
        }}
      />
      <NoItemTag
        className='annotations-no-item-tag'
        canAdd
        canAddText={
          currentState === MeasureGEVState.EavesToRidge ? 'Measuring Eaves to Ridge' : 'Measure Eaves to Ridge (E)'
        }
        cantAddText=''
        onClick={() => {
          setCurrentState(MeasureGEVState.EavesToRidge)
          const newPoints = [...(props.model.points || [])]
          const newMesurements = [...(props.model.measurements || [])]
          newPoints[2] = undefined
          newPoints[3] = undefined
          newMesurements[1] = undefined
          props.setModel({
            ...props.model,
            points: newPoints,
            measurements: newMesurements,
          })
        }}
      />
      <AnnotationRulerDistance
        onlyZ
        disableEdits={currentState !== MeasureGEVState.GroundToEaves}
        points={groundPoints}
        pointsChanged={(points, annotations) => {
          if (currentState !== MeasureGEVState.GroundToEaves) {
            return
          }
          const newPoints = [...(props.model.points || [])]
          const newMesurements = [...(props.model.measurements || [])]

          if (points.length === 0) {
            newPoints[0] = undefined
            newPoints[1] = undefined
            newMesurements[0] = undefined
          } else {
            const mappedPoints = points.map((p) => ({
              longitude: Cesium.Math.toDegrees(p.longitude),
              latitude: Cesium.Math.toDegrees(p.latitude),
              altitude: p.height,
            }))

            newPoints[0] = mappedPoints[0]
            newPoints[1] = mappedPoints[1]
            newMesurements[0] = annotations[0]
          }

          props.setModel({
            ...props.model,
            points: newPoints,
            measurements: newMesurements,
          })
        }}
      />
      <AnnotationRulerDistance
        onlyZ
        disableEdits={currentState !== MeasureGEVState.EavesToRidge}
        points={eavesPoints}
        pointsChanged={(points, annotations) => {
          if (currentState !== MeasureGEVState.EavesToRidge) {
            return
          }
          const newPoints = [...(props.model.points || [])]
          const newMesurements = [...(props.model.measurements || [])]

          if (points.length === 0) {
            newPoints[2] = undefined
            newPoints[3] = undefined
            newMesurements[1] = undefined
          } else {
            const mappedPoints = points.map((p) => ({
              longitude: Cesium.Math.toDegrees(p.longitude),
              latitude: Cesium.Math.toDegrees(p.latitude),
              altitude: p.height,
            }))

            newPoints[2] = mappedPoints[0]
            newPoints[3] = mappedPoints[1]
            newMesurements[1] = annotations[0]
          }

          props.setModel({
            ...props.model,
            points: newPoints,
            measurements: newMesurements,
          })
        }}
      />
    </div>
  )
}
