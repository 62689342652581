import { useEffect, useState } from 'react'
import { DefectType, GetDefectTypesQuery } from '~/models'

import { useQuery } from '~/components'
import DEFECT_TYPES_QUERY from '../query-defect-types.gql'

export const useDefectTypes = () => {
  const defectTypesQuery = useQuery<GetDefectTypesQuery>(DEFECT_TYPES_QUERY)
  const [defectTypeList, setDefectTypeList] = useState<DefectType[]>([])

  // Load defect types.
  useEffect(() => {
    if (defectTypesQuery.data?.defectTypes[0]) {
      const defectTypesArray = defectTypesQuery.data.defectTypes

      const sortedDefectTypes = [...defectTypesArray].sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })

      setDefectTypeList(sortedDefectTypes)
    }
  }, [defectTypesQuery.data])

  return defectTypeList
}
