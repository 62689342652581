query instantAssessmentsMetadata($input: InstantAssessmentsMetadataRequestT!) {
  instantAssessmentsMetadata(input: $input) {
    surveyResources {
      surveyResourceID
      aoi
      photos {
        surveyResourceID
        id
        sensor {
          focalLengthMm
          pixelSizeMm
          widthPx
          heightPx
        }
        createdTime
        gpsCoordinate {
          type
          coordinates
          bbox
        }
        groundHeightM
        altitudeM
      }
    }
  }
}
