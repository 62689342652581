import React, { useEffect, useRef, useState } from 'react'
import { MeasurementSystem } from '~/models'

interface MeasurementSystemDropdownProps {
  value: MeasurementSystem
  onChange: (value: MeasurementSystem) => void
  disabled: boolean
}

export function MeasurementSystemDropdown(props: MeasurementSystemDropdownProps) {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  const handleChange = (selectedValue: MeasurementSystem) => {
    setOpen(false)
    props.onChange(selectedValue)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const options = [
    {
      label: 'Metric',
      value: MeasurementSystem.Metric,
      icon: 'fa-ruler',
    },
    {
      label: 'Imperial',
      value: MeasurementSystem.Imperial,
      icon: 'fa-flag-usa',
    },
  ]

  const selectedValue = options.find((o) => o.value === props.value)

  return (
    <>
      <label>Workspace Measurement System</label>
      <div
        ref={dropdownNode}
        className={`dropdown form-control measurement-system-dropdown ${props.disabled ? 'disabled' : ''}`}
      >
        <div
          className={`dropdown-toggle ${open ? 'active' : ''}`}
          id={`dropdownMenuButton-measurement-system`}
          data-toggle={`dropdown-measurement-system`}
          aria-haspopup='true'
          aria-expanded={open}
          onClick={() => {
            if (!props.disabled) {
              setOpen(!open)
            }
          }}
        >
          <span className={`flag-icon fa-solid ${selectedValue.icon}`} />
          {selectedValue.label}
        </div>
        <div
          className='dropdown-menu'
          aria-labelledby={`dropdownMenuButton-measurement-system`}
          style={{ display: open ? 'block' : 'none' }}
        >
          {options.map((o) => (
            <a className='dropdown-item' onClick={() => handleChange(o.value)} key={o.label}>
              <div className={`dropdown-item-radio ${o.value === selectedValue.value ? 'checked' : ''}`}>
                <div />
              </div>
              <div className='dropdown-item-container'>
                <span className={`flag-icon fa-solid ${o.icon}`} /> {o.label}
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
