import { useEffect, useState } from 'react'
import { useQuery } from '~/components'
import { AnnotationsBySiteQuery, AnnotationsBySiteQueryVariables, AnnotationDtoT } from '~/models'
import ANNOTATIONS_QUERY from './query-annotations.gql'

export function useAnnotations(surveyID?: string, siteID?: string, assetID?: string) {
  const drawAnnotations = localStorage.getItem('asseti-annotations-draw')
  const [annotationToUpdate, setAnnotationToUpdate] = useState<AnnotationDtoT>()
  const [annotationToDelete, setAnnotationToDelete] = useState<AnnotationDtoT>()
  const [selectedAnnotation, setSelectedAnnotation] = useState<AnnotationDtoT>()
  const [draw, setDraw] = useState<boolean>(drawAnnotations === 'true' || drawAnnotations === null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [annotations, setAnnotations] = useState<AnnotationsBySiteQuery['annotationsBySite']>([])

  const query = useQuery<AnnotationsBySiteQuery, AnnotationsBySiteQueryVariables>(ANNOTATIONS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  const reload = () => {
    setLoading(true)
    return query
      .refetch({
        input: {
          siteID: siteID,
          surveyID: surveyID,
        },
      })
      .then((res) => {
        if (assetID) {
          setAnnotations(res.data.annotationsBySite.filter((m) => m.assetID === assetID))
        } else {
          setAnnotations(res.data.annotationsBySite)
        }
        if (selectedAnnotation) {
          const newAnnotation = res.data.annotationsBySite.find((x) => x.id === selectedAnnotation.id)
          if (newAnnotation) {
            setSelectedAnnotation(newAnnotation)
          } else {
            setSelectedAnnotation(undefined)
          }
        }
        setError(false)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!surveyID) {
      return
    }

    reload()
  }, [surveyID, assetID])

  return {
    annotations,
    loading,
    error,
    reload,
    annotationToUpdate,
    setAnnotationToUpdate: (annotation: AnnotationDtoT) => {
      setAnnotationToUpdate(annotation)
    },
    draw,
    setDraw: (b: boolean) => {
      localStorage.setItem('asseti-annotations-draw', b + '')
      setDraw(b)
    },
    selectedAnnotation,
    setSelectedAnnotation,
    annotationToDelete,
    setAnnotationToDelete,
  }
}
