import { useQuery, SimpleBar } from '~/components'
import React, { useEffect, useState } from 'react'
import { OrganizationAccessQuery, OrganizationQuery } from '~/models'
import { AdvancedAccessTableFull } from './advanced-access-tables'
import { Accordion } from '~/dashboards/edit/controls'
import { AdvancedAccess2FA } from './advanced-access-2fa'
import { AdvancedAccessTableUser } from './advanced-access-user-table'
import { useUser } from '~/base'
import ORG_ACCESS_QUERY from './query-org-access.gql'
import ORG_QUERY from './query-org.gql'

export const AdvancedAccess = () => {
  const [guestPolicy, setGuestPolicy] = useState<string[]>(["0","0","0","0"])
  const [memberPolicy, setMemberPolicy] = useState<string[]>(["0","0","0","0"])
  const [adminPolicy, setAdminPolicy] = useState<string[]>(["0","0","0","0"])
  const query = useQuery<OrganizationAccessQuery>(ORG_ACCESS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })
  const orgQuery = useQuery<OrganizationQuery>(ORG_QUERY)
  const users = [...((!orgQuery.loading && !orgQuery.error && orgQuery.data?.organization?.users) || [])].sort(
    (a, b) => {
      const aName = a.user.firstName + ' ' + a.user.lastName
      const bName = b.user.firstName + ' ' + b.user.lastName
      return aName > bName ? 1 : -1
    }
  )
  const sites = [...((!orgQuery.loading && !orgQuery.error && orgQuery.data?.organization?.sites) || [])].sort(
    (a, b) => (a.name > b.name ? 1 : -1)
  )

  const user = useUser()

  useEffect(() => {
    query.refetch().then((res) => {
      setGuestPolicy(res.data.organizationAccess.guestPolicy)
      setMemberPolicy(res.data.organizationAccess.memberPolicy)
      setAdminPolicy(res.data.organizationAccess.adminPolicy)
    })
  }, [])

  return (
      <SimpleBar>
      <div className='settings-tab-content'>
      <form autoComplete='off'>
        <p className='settings-tab-content-notice'>
          In this panel, you have fine grained control over what abilities team members and guests have within the
          Asseti platform.
        </p>
        <AdvancedAccess2FA />
        <Accordion title='Workspace Permissions' defaultClosed>
          <AdvancedAccessTableFull
            guestPolicy={guestPolicy}
            setGuestPolicy={setGuestPolicy}
            memberPolicy={memberPolicy}
            setMemberPolicy={setMemberPolicy}
            adminPolicy={adminPolicy}
            setAdminPolicy={setAdminPolicy}
            canSeeDeflections={user.org.canAccessDeflections}
          />
        </Accordion>
        <br />
        {users
          .map((u) => {
            return (
              <Accordion key={u.user.id} title={`${u.user.firstName} ${u.user.lastName} Permissions`} defaultClosed>
                <AdvancedAccessTableUser
                  user={u}
                  sites={sites}
                  guestPolicy={guestPolicy}
                  memberPolicy={memberPolicy}
                  adminPolicy={adminPolicy}
                  canSeeDeflections={user.org.canAccessDeflections}
                />
              </Accordion>
            )
          })}
      </form>
      <br />
      <br />
      <br />
      <br />
    </div>
      </SimpleBar>
  )
}
