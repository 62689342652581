import { Config } from "~/config"

let searchTimeout: ReturnType<typeof setTimeout>

export const search = (
  endpoint: string,
  source: string,
  query: string,
  onResult: (err: any, res: Response | null, searchTime: Date) => void,
  proximity?: { longitude: number; latitude: number },
  country?: string,
  bbox?: number[],
  types?: string,
  limit?: number,
  autocomplete?: boolean,
  language?: string
) => {
  if (searchTimeout) {
    clearTimeout(searchTimeout)
  }

  searchTimeout = setTimeout(() => {
    const searchTime = new Date()
    const baseUrl = `${endpoint}/geocoding/v5/${source}/${query}.json`

    const searchParams: any = {
      access_token: Config.MapBoxToken,
      proximity:
        proximity && Object.keys(proximity).length === 2 ? `${proximity.longitude},${proximity.latitude}` : null,
      bbox: bbox && bbox.length > 0 ? bbox.join(',') : null,
      types,
      country,
      limit,
      autocomplete,
      language,
    }

    // Remove empty keys.
    Object.keys(searchParams).forEach((k) => {
      if (!searchParams[k]) {
        delete searchParams[k]
      }
    })

    const url = `${baseUrl}?${toUrlString(searchParams)}`
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        onResult(null, data, searchTime)
      })
      .catch((err) => {
        onResult(err, null, searchTime)
      })
  }, 250)
}

function toUrlString(params: any) {
  return Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&')
}
