import React from 'react'
import { classes } from '~/components'
import REPORT_BG from '../../images/reports/report-top-right-bg.png'

interface ReportPageProps {
  children?: any
  withTopRightBackground?: boolean
  withBottomRightBackground?: boolean
  darkBackground?: boolean
  className?: string
}

export const ReportPage = (props: ReportPageProps) => {
  return (
    <div className={classes({
      'report-page': true,
      'dark-bg': props.darkBackground,
      [props.className || '']: true
    })}>
      {props.withTopRightBackground && <img className='top-right-bg' src={REPORT_BG} />}
      {props.withBottomRightBackground && <img className='bottom-right-bg' src={REPORT_BG} />}
      {props.children}
    </div>
  )
}
