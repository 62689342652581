import React from 'react'
import { UserExistsForInvitationQuery, UserExistsForInvitationQueryVariables } from '~/models'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { useLocation } from '~/components'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import { useQuery } from '~/components'
import { AuthAcceptInviteExists } from './auth-accept-invite-exists'
import { AuthAcceptInviteNewUser } from './auth-accept-invite-new-user'
import userExistsForInvitationQuery from './query-user-exists-for-invitation.gql'

export const AuthAcceptInvite = () => {
  const location = useLocation()
  const token = new URLSearchParams(window.location.search).get('token')

  if (!token) {
    location.setLocation('/404')
  }

  const query = useQuery<UserExistsForInvitationQuery, UserExistsForInvitationQueryVariables>(
    userExistsForInvitationQuery,
    {
      variables: {
        input: {
          token,
        },
      },
    }
  )

  const loading = query.loading
  const exists = query.data?.userExistsForInvitation?.exists

  return (
    <>
      {loading && (
        <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
          <Helmet title='Accept Team Invite' />
          <div className='subheader-dark mt-5 mb-5'>Team Invitation Confirmation</div>
          {query.error && <AuthError className='alert alert-warning mb-4' error={query.error} />}
          <div className='subheader-dark mt-5 mb-5'>Loading...</div>
        </AuthLayout>
      )}

      {exists === true && <AuthAcceptInviteExists token={token} />}
      {exists === false && <AuthAcceptInviteNewUser token={token} />}
    </>
  )
}
