import React from 'react'
import { useAppState } from '~/state'
import { ItemTagDelete, useMutation, getTracking } from '~/components'
import { RemoveAssetMutation, RemoveAssetMutationVariables, SurveyBasedOperation } from '~/models'
import REMOVE_ASSET_MUTATION from './mutation-asset-remove.gql'

interface AssetRemoveProps {
  onRemove: () => void
  hide: () => void
}

export const AssetRemove = (props: AssetRemoveProps) => {
  const { view, timeline } = useAppState()
  const [removeAsset] = useMutation<RemoveAssetMutation, RemoveAssetMutationVariables>(REMOVE_ASSET_MUTATION)
  const doRemoveAsset = () =>
    removeAsset({
      variables: {
        input: {
          assetID: view.assetID,
          operationType: SurveyBasedOperation.GoingForward,
          siteID: view.siteID,
          surveyID: timeline.activeSurvey.id,
        },
      },
    }).then(props.onRemove)

  if (!timeline.activeSurvey) {
    props.hide()
    return null
  }

  return (
    <ItemTagDelete
      title='this asset'
      cancel={props.hide}
      hideOperationType
      onClickDelete={() => {
        return doRemoveAsset().then(() => {
          getTracking().event({
            category: 'Button',
            action: `User removed a asset`,
            label: 'Asset',
          })
        })
      }}
    />
  )
}
