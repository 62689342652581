import React from 'react'
import { PolygonRenderer } from './polygon-renderer'

export interface Point {
  x: number
  y: number
}

export interface Offset {
  left: number
  top: number
}

export class ScreenState {
  public readonly c: HTMLCanvasElement
  public readonly img: HTMLImageElement
  public readonly zoom: number
  public readonly offset: Offset
  public dragAllowed: boolean
  public polygonRenderers: React.MutableRefObject<PolygonRenderer[]>

  constructor(
    c: HTMLCanvasElement,
    img: HTMLImageElement,
    zoom = 1,
    offset: Offset = {
      top: 0,
      left: 0,
    },
    polygonRenderers: React.MutableRefObject<PolygonRenderer[]>
  ) {
    this.c = c
    this.img = img
    this.zoom = zoom
    this.offset = offset
    this.dragAllowed = true
    this.polygonRenderers = polygonRenderers
  }

  public getCanvasPosition(p: Point): Offset {
    const w = this.img.width * this.zoom
    const h = this.img.height * this.zoom
    const left = this.c.width / 2 - w / 2 + p.x * w
    const top = this.c.height / 2 - h / 2 + p.y * h
    const leftOffset = left + this.offset.left
    const topOffset = top + this.offset.top

    return {
      left: leftOffset,
      top: topOffset,
    }
  }

  public getImagePosition(p: Point): Point {
    const cx = this.c.width / 2
    const cl = this.offset.left
    const cw = this.img.width * this.zoom
    const cdx = 0.5 - (cx - (p.x * this.img.width) + cl) / cw
    const cpx = cdx * this.img.width

    const cy = this.c.height / 2
    const ch = this.img.height * this.zoom
    const ct = this.offset.top
    const cz = cy - (p.y * this.img.height) + ct
    const cdy = 0.5 - cz / ch
    const cpy = cdy * this.img.height

    return {
      x: cpx,
      y: cpy,
    }
  }

  public getZoomDelta(p: Point, newZoom: number): Offset {
    const getDeltaLeft = () => {
      const cx = this.c.width / 2
      const ox = p.x
      const cl = this.offset.left
      const cw = this.img.width * this.zoom
      const ct = cx - ox + cl
      const nw = this.img.width * newZoom
      const nl = (nw / cw) * ct - cx + ox
      const dl = nl - cl
      return dl
    }

    const getDeltaTop = () => {
      const cy = this.c.height / 2
      const oy = p.y
      const ch = this.img.height * this.zoom
      const ct = this.offset.top
      const cz = cy - oy + ct
      const nh = this.img.height * newZoom
      const nt = (nh / ch) * cz - cy + oy
      const dt = nt - ct
      return dt
    }

    return {
      left: getDeltaLeft(),
      top: getDeltaTop(),
    }
  }

  public constrainOffsets(o: Offset): Offset {
    const w = this.img.width * this.zoom
    const h = this.img.height * this.zoom

    let topOffset = 0
    if (h > this.c.height) {
      topOffset = o.top
      if (topOffset > (h - this.c.height) / 2) {
        topOffset = (h - this.c.height) / 2
      } else if (Math.abs(topOffset) > (h - this.c.height) / 2) {
        topOffset = -((h - this.c.height) / 2)
      }
    }

    let leftOffset = 0
    if (w > this.c.width) {
      leftOffset = o.left
      if (leftOffset > (w - this.c.width) / 2) {
        leftOffset = (w - this.c.width) / 2
      } else if (Math.abs(leftOffset) > (w - this.c.width) / 2) {
        leftOffset = -((w - this.c.width) / 2)
      }
    }
    return {
      top: topOffset,
      left: leftOffset,
    }
  }

  public getConstrainedOffsets(p: Point, newZoom: number): Offset {
    const zoomDelta = this.getZoomDelta(p, newZoom)
    const newOffset = {
      left: this.offset.left + zoomDelta.left,
      top: this.offset.top + zoomDelta.top,
    }

    return this.constrainOffsets(newOffset)
  }

  public fromDelta(deltaX: number, deltaY: number): ScreenState {
    return new ScreenState(
      this.c,
      this.img,
      this.zoom,
      this.constrainOffsets({
        left: this.offset.left + deltaX,
        top: this.offset.top + deltaY,
      }),
      this.polygonRenderers
    )
  }

  public fromOffsetAndZoom(left: number, top: number, zoom: number): ScreenState {
    return new ScreenState(
      this.c,
      this.img,
      zoom,
      this.getConstrainedOffsets(
        {
          x: left,
          y: top,
        },
        zoom
      ),
      this.polygonRenderers
    )
  }

  public render() {
    const c = this.c
    const ctx = c.getContext('2d')
    // ctx.canvas.width = container.current.clientWidth
    // ctx.canvas.height = container.current.clientHeight
    const w = this.img.width * this.zoom
    const h = Math.floor(this.img.height * this.zoom)

    const left = c.width / 2 - w / 2
    const top = Math.floor(c.height / 2 - h / 2)
    const leftOffset = left + this.offset.left
    const topOffset = top + this.offset.top
    ctx.clearRect(0, 0, c.width, c.height)
    ctx.drawImage(this.img, leftOffset, topOffset, w, h)
    if (this.polygonRenderers.current) {
      this.polygonRenderers.current.forEach((p) => p._render())
    }
  }
}
