import React from 'react'

import {
  MonitoringZoneUpdateRequestT,
  SiteQueryMonitoringZone,
  SurveyBasedOperation,
  UpdateMonitoringZoneMutation,
} from '~/models'
import { FloatingInput, FloatingTextArea, useForm } from '../forms'
import { getTracking } from '../tracking'
import { string, object, kmlToPolygon } from '~/components'
import { useAppState } from '~/state'
import { OperationTypeSelect } from '../operation-type'
import UPDATE_MONITORING_ZONE from './mutation-monitoring-zones-update.gql'
import { MonitoringZoneTargetSelect } from './monitoring-zone-target-select'
import { FileSelector } from '../upload-panel/upload-panel-file-selector'

interface MonitoringZoneEditFormProps {
  cancel: () => void
  complete: () => void
  monitoringZone: SiteQueryMonitoringZone
  newPoints: React.MutableRefObject<Cesium.Cartesian3[]>
  setPointsFromKML: React.Dispatch<React.SetStateAction<Cesium.Cartesian3[]>>
}

export const MonitoringZoneEditForm = (props: MonitoringZoneEditFormProps) => {
  const { view, timeline } = useAppState()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<UpdateMonitoringZoneMutation, MonitoringZoneUpdateRequestT>({
      enableReinitialize: true,
      initialValues: {
        monitoringZoneID: props?.monitoringZone?.id || '',
        name: props?.monitoringZone?.name || '',
        description: props?.monitoringZone?.description || '',
        points: props?.monitoringZone?.points || [],
        siteID: view.siteID,
        assetID: view.assetID || '',
        operationType: SurveyBasedOperation.GoingForward,
        surveyID: '',
        monitoringZoneTargetID: props?.monitoringZone?.targetID || '',
        monitoringZoneSubTargetID: props?.monitoringZone?.subTargetID || '',
      },
      validationSchema: object().shape({
        name: string().required('Required').min(3),
        monitoringZoneTargetID: string().required('Required'),
      }),
      mutation: UPDATE_MONITORING_ZONE,
      mapInput: (input) => {
        return {
          ...input,
          surveyID: timeline.activeSurvey.id,
          points: props.newPoints.current.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
        }
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Monitoring Zone',
          action: `User updated Monitoring Zone`,
          label: 'Monitoring Zone',
        })
        props.complete()
      },
    })

  if (!timeline.activeSurvey) {
    props.cancel()
    return null
  }

  return (
    <div className='drawer-panel'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                props.cancel()
              }}
            >
              Site
            </a>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            Monitoring Zone
          </li>
        </ol>
      </nav>
      <div className='drawer-panel-title-container'>
        <h6 className='drawer-panel-title'>
          Update
          <br />
          Monitoring Zone
        </h6>
      </div>
      <div className='drawer-panel-form-container'>
        {error && <div className='error'>{error}</div>}
        <form autoComplete='off' onSubmit={handleSubmit}>
          <FloatingInput
            label='Name'
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
          <MonitoringZoneTargetSelect
            onChange={(t, s) => {
              setFieldValue('monitoringZoneTargetID', t?.id || '')
              setFieldValue('monitoringZoneSubTargetID', s?.id || '')
            }}
            monitoringZoneTargetID={values.monitoringZoneTargetID}
            monitoringZoneSubTargetID={values.monitoringZoneSubTargetID}
          />
          <FloatingTextArea
            label='Description'
            id='description'
            value={values.description}
            onChange={handleChange}
            helperText={touched.description ? errors.description : ''}
            rows={3}
          />
          <p className='drawer-panel-hint'>
            Use your cursor to modify the monitoring zone on the map or upload the boundary from a KML file. <br />
            <FileSelector
              singleFile
              accept='.kml'
              text='Select KML file'
              className='profile-form-btn btn boundaries-file-select'
              filesSelected={(files) => {
                const reader = new FileReader()
                reader.onload = function () {
                  const xml = reader.result.toString()
                  const allCoords = kmlToPolygon(xml)
                  if (allCoords && allCoords.length === 1) {
                    props.setPointsFromKML(allCoords[0])
                  }
                }
                reader.readAsText(files[0])
              }}
            />
            <br />
          </p>
          <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          />

          <button type='submit' className='btn submit mb-2' disabled={isSubmitting}>
            Save
          </button>
          <button className='btn cancel' onClick={() => props.cancel()}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  )
}
