import { string, object, DrawerState, SimpleBar, FloatingInput } from '~/components'
import {
  CreateSiteGroupMutation,
  SiteGroupCreateRequestT,
  SiteGroupUpdateRequestT,
  UpdateSiteGroupMutation,
} from '../../models/gql-types'
import {
  useForm,
  getTracking,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  Helmet,
  DrawerFormHeading,
  DrawerFormButtons,
  DrawerFormSubmitButton,
  DrawerFormCancelButton,
} from '~/components'
import React, { useEffect, useMemo, useState } from 'react'
import { useAppState } from '~/state'
import { SitesQuerySiteWithExtra, SitesQuerySiteGroupWithExtra } from '~/models'
import { SITE_GROUP_CREATE_MUTATION, SITE_GROUP_UPDATE_MUTATION } from './data'

interface ColumnHeadingProps {
  title: string
  search: string
  searchChanged: (s: string) => void
  buttonType: string
  onButton: () => void
}

const ColumnHeading = (props: ColumnHeadingProps) => {
  return (
    <div className='site-groups-form-lower-heading'>
      <div className='site-groups-form-lower-heading-search'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>{props.title}</span>
          </div>
          <input
            onChange={(e) => {
              props.searchChanged(e.target.value || '')
            }}
            type='text'
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            value={props.search}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>
              <button
                className='btn btn-secondary btn-sm'
                onClick={(e) => {
                  e.preventDefault()
                  props.onButton()
                }}
              >
                {props.buttonType} All
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CardProps {
  site: SitesQuerySiteWithExtra
  move: () => void
}

const Card = (props: CardProps) => {
  const { sites } = useAppState()
  const groups = (sites.groups || []).filter((g) => g.sites.findIndex((s) => s.s.id === props.site.s.id) !== -1)
  return (
    <div
      className='sites-card'
      style={{ width: 'initial', height: 'initial', maxHeight: 'initial', marginRight: '15px', userSelect: 'none' }}
      onClick={props.move}
    >
      <div className='sites-card-content mb-1'>{props.site.s.name}</div>
      <div className='overview-stats-card'>
        {groups.length === 0 && <span className='overview-stats-card-asset-count'>Not in other groups</span>}
        {groups.length > 0 && (
          <div className='overview-card-groups-container'>
            {groups.map((g) => {
              return (
                <div className='overview-card-group' title={g.group.name}>
                  {g.group.name}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

enum ColumnType {
  Excluded,
  Included,
}

interface ColumnProps {
  sites: SitesQuerySiteWithExtra[]
  columnType: ColumnType
  move: (index: number) => void
}

const Column = (props: ColumnProps) => {
  return (
    <div className='site-groups-form-lower-content'>
      <div className='site-groups-form-lower-content-container'>
        <SimpleBar autoHide={false} setMaxHeightToParent>
          {props.sites.map((s, idx) => {
            return (
              <Card
                site={s}
                move={() => {
                  props.move(idx)
                }}
                key={s.s.id}
              />
            )
          })}
          <div style={{ height: '20px' }}></div>
        </SimpleBar>
      </div>
    </div>
  )
}

interface SiteGroupCreateProps {
  onComplete: (created: boolean) => void
  updating: boolean
  group?: SitesQuerySiteGroupWithExtra
}

export const SiteGroupCreate = (props: SiteGroupCreateProps) => {
  const { map, drawer, sites } = useAppState()
  const [excludedSearch, setExcludedSearch] = useState('')
  const [includedSearch, setIncludedSearch] = useState('')

  const sitesToUse = useMemo(() => {
    if (!sites?.data?.sites) {
      return undefined
    }

    const siteList = sites.sites

    return siteList.filter((s) => !s.s.isDemoSite)
  }, [sites?.data?.sites, sites.summaryQuery.data])

  const sort = (sites: SitesQuerySiteWithExtra[]): SitesQuerySiteWithExtra[] =>
    sites.sort((a, b) => (a.s.name < b.s.name ? -1 : 1))
  const sortedSites = sort(sitesToUse)

  const [excludedSites, setExcludedSites] = useState<SitesQuerySiteWithExtra[]>(
    props.group ? sortedSites.filter((s) => props.group.sites.findIndex((x) => x.s.id === s.s.id) === -1) : sortedSites
  )
  const [includedSites, setIncludedSites] = useState<SitesQuerySiteWithExtra[]>(
    props.group ? sortedSites.filter((s) => props.group.sites.findIndex((x) => x.s.id === s.s.id) !== -1) : []
  )

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = props.updating
    ? useForm<UpdateSiteGroupMutation, SiteGroupUpdateRequestT>({
        enableReinitialize: true,
        initialValues: {
          name: props.group.group.name,
          siteIDs: [],
          id: props.group.group.id,
        },
        validationSchema: object().shape({
          name: string().required('Required'),
        }),
        mutation: SITE_GROUP_UPDATE_MUTATION,
        mapInput: (input) => {
          return {
            ...input,
            siteIDs: includedSites.map((s) => s.s.id),
            id: props.group.group.id,
          }
        },
        onSuccess: () => {
          getTracking().event({
            category: 'Form',
            action: `User updated a site group`,
            label: 'Site Group',
          })
          props.onComplete(true)
        },
      })
    : useForm<CreateSiteGroupMutation, SiteGroupCreateRequestT>({
        enableReinitialize: true,
        initialValues: {
          name: '',
          siteIDs: [],
        },
        validationSchema: object().shape({
          name: string().required('Required'),
        }),
        mutation: SITE_GROUP_CREATE_MUTATION,
        mapInput: (input) => {
          return {
            ...input,
            siteIDs: includedSites.map((s) => s.s.id),
          }
        },
        onSuccess: (result: CreateSiteGroupMutation) => {
          getTracking().event({
            category: 'Form',
            action: `User created a site group`,
            label: 'Site Group',
          })
          props.onComplete(true)
        },
      })

  useEffect(() => {
    const left = drawer.left
    const right = drawer.right
    map.setShowControls(false)
    drawer.set(left, DrawerState.Hidden)

    return () => {
      map.setShowControls(true)
      drawer.set(left, right)
    }
  }, [])

  const excludedSearchValue = excludedSearch.toLocaleLowerCase().trim()
  const includedSearchValue = includedSearch.toLocaleLowerCase().trim()

  const excludedSitesValue = excludedSites.filter((s) => {
    if (excludedSearchValue === '') {
      return true
    }

    return s.s.name.toLocaleLowerCase().includes(excludedSearchValue)
  })

  const includedSitesValue = includedSites.filter((s) => {
    if (includedSearchValue === '') {
      return true
    }

    return s.s.name.toLocaleLowerCase().includes(includedSearchValue)
  })

  return (
    <DrawerForm hideRightCover wide>
      <Helmet title={props.updating ? 'Update site group' : 'Add a site group'} />
      <DrawerFormBreadcrumbs wide>
        <DrawerFormBreadcrumbItem title='Home' onClick={() => props.onComplete(false)} />
        <DrawerFormBreadcrumbItem title={props.updating ? 'Update site group' : 'Add site group'} />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading title={props.updating ? 'Update site group' : 'Add a site group'} />
      <div className='drawer-form-content site-groups-form'>
        <div className='site-groups-form-upper'>
          <form autoComplete='off' id='create-site-form' onSubmit={handleSubmit}>
            {error && <div className='error'>{error}</div>}
            <FloatingInput
              id='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
              label='Name'
            />
          </form>
        </div>
        <div className='site-groups-form-lower'>
          <div className='site-groups-form-lower-left'>
            <ColumnHeading
              title='Excluded'
              search={excludedSearch}
              searchChanged={setExcludedSearch}
              buttonType='Add'
              onButton={() => {
                const sitesToExclude = [...excludedSitesValue]
                let newExcludedSites = [...excludedSites]
                const newIncludedSites = [...includedSites]
                for (const s of sitesToExclude) {
                  newIncludedSites.push(s)
                  newExcludedSites = newExcludedSites.filter((x) => x.s.id !== s.s.id)
                }
                setExcludedSites(sort(newExcludedSites))
                setIncludedSites(sort(newIncludedSites))
              }}
            />
            {sitesToUse && (
              <Column
                sites={excludedSitesValue}
                columnType={ColumnType.Excluded}
                move={(index) => {
                  const s = excludedSitesValue[index]
                  setExcludedSites(sort(excludedSites.filter((x) => x.s.id !== s.s.id)))
                  setIncludedSites(sort([...includedSites, s]))
                }}
              />
            )}
          </div>
          <div className='site-groups-form-lower-right'>
            <ColumnHeading
              title='Included'
              search={includedSearch}
              searchChanged={setIncludedSearch}
              buttonType='Remove'
              onButton={() => {
                const sitesToInclude = [...includedSitesValue]
                const newExcludedSites = [...excludedSites]
                let newIncludedSites = [...includedSites]
                for (const s of sitesToInclude) {
                  newExcludedSites.push(s)
                  newIncludedSites = newIncludedSites.filter((x) => x.s.id !== s.s.id)
                }
                setExcludedSites(sort(newExcludedSites))
                setIncludedSites(sort(newIncludedSites))
              }}
            />
            {sitesToUse && (
              <Column
                sites={includedSitesValue}
                columnType={ColumnType.Included}
                move={(index) => {
                  const s = includedSitesValue[index]
                  setIncludedSites(sort(includedSitesValue.filter((x) => x.s.id !== s.s.id)))
                  setExcludedSites(sort([...excludedSites, s]))
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DrawerFormButtons>
        <DrawerFormSubmitButton form='create-site-form' disabled={isSubmitting || includedSites.length === 0}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton onClick={() => props.onComplete(false)}>Cancel</DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
