import { useUser } from '~/base'
import { handleCurrencyFormatFull } from '~/components'

export function getIssueEstimatedRepairCost(
  user: ReturnType<typeof useUser>,
  issue?: {
    priority: number
    repairEstimate: number 
    defectCostToRepair: number
    defectCostPriorityScalar: number
  }
) {
  if (!issue) {
    return {
      cost: 0,
      formatted: '-',
      byAsseti: false,
    }
  }

  let cost = issue?.repairEstimate || issue?.defectCostToRepair || 0
  const byAsseti = (issue && (issue.defectCostToRepair || 0)) === cost

  if (byAsseti) {
    cost = cost + (issue?.defectCostPriorityScalar || 0) * issue.priority
  }

  return {
    cost,
    formatted: handleCurrencyFormatFull(cost, user),
    byAsseti,
  }
}
