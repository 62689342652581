import React, { useEffect, useState } from 'react'
import { LeftClickPosition, useAppState } from '~/state'
import { FloatingTextArea, useForm } from '../forms'
import { BoundaryUpdateMarkerRequestT, UpdateBoundaryMarkerMutation, SurveyBasedOperation, SiteQueryBoundary } from '~/models'
import { number, object, string } from '../validation'
import BOUNDARY_MARKER_UPDATE_MUTATION from './mutation-boundary-marker-update.gql'
import { getTracking } from '../tracking'
import { OperationTypeSelect } from '../operation-type'

interface BoundaryMarkerUpdateProps {
  marker: SiteQueryBoundary['markers'][0]
  complete: () => Promise<void>
  cancel: () => Promise<void>
}

export const BoundaryMarkerUpdate = (props: BoundaryMarkerUpdateProps) => {
  const { view, timeline, map } = useAppState()

  const [waiting, setWaiting] = useState(false)

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<UpdateBoundaryMarkerMutation, BoundaryUpdateMarkerRequestT>({
      enableReinitialize: false,
      initialValues: {
        siteID: view.siteID,
        surveyID: timeline.activeSurvey?.id,
        operationType: SurveyBasedOperation.GoingForward,
        boundaryID: view.boundaryID,
        comment: props.marker.comment,
        location: {
          longitude: props.marker.location.longitude,
          latitude: props.marker.location.latitude,
        },
        markerID: props.marker.id,
      },
      validationSchema: object().shape({
        comment: string().required('Required'),
        location: object().shape({
          longitude: number().required('Location is required'),
          latitude: number().required('Location is required'),
        }),
      }),
      mutation: BOUNDARY_MARKER_UPDATE_MUTATION,
      mapInput: (input) => {
        const toRet: BoundaryUpdateMarkerRequestT = {
          siteID: view.siteID,
          surveyID: timeline.activeSurvey.id,
          operationType: input.operationType,
          boundaryID: view.boundaryID,
          comment: input.comment || '',
          location: values.location,
          markerID: props.marker.id,
        }
        return toRet
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Boundary Marker',
          action: `User Updated Boundary Marker`,
          label: 'Boundary Marker',
        })
        setWaiting(true)
        props.complete().finally(() => {
          setWaiting(false)
        })
      },
    })

  useEffect(() => {
    const onLeftClick = (pos: LeftClickPosition) => {
      setFieldValue('location', {
        longitude: Cesium.Math.toDegrees(pos.cartographic.longitude),
        latitude: Cesium.Math.toDegrees(pos.cartographic.latitude),
      })
      return true
    }

    map.addLeftClickHandler(onLeftClick)

    return () => {
      map.removeLeftClickHandler(onLeftClick)
    }
  }, [])

  useEffect(() => {
    if (!values.location) {
      return
    }

    const marker = map.addMarker(view.boundaryID + 'tmp-marker', [values.location.longitude, values.location.latitude])
    return () => {
      map.removeMarker(marker)
    }
  }, [values.location])

  return (
    <div className='boundaries-draw'>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.cancel()
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Boundary Marker
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>
            Update
            <br />
            Boundary Marker
          </h6>
        </div>
        <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
          {error && <div className='error'>{error}</div>}
          <p className='drawer-panel-hint'>
            Use your cursor to select a point on the map where the marker should be. <br />
          </p>
          <FloatingTextArea
            label='Comment'
            id='comment'
            value={values.comment}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.comment ? errors.comment : ''}
            error={touched.comment && Boolean(errors.comment)}
            rows={8}
            ignoreHeight
          />
          <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          />
          <div className='drawer-panel-form-container-action-container'>
            <button type='submit' className='btn submit mb-2' disabled={isSubmitting || waiting}>
              {isSubmitting || waiting ? 'Saving...' : 'Save'}
            </button>
            <button className='btn cancel' onClick={() => props.cancel()}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div></div>
    </div>
  )
}
