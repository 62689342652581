import React from 'react'
import { classes } from '~/components/helpers'

export interface FloatingTextAreaProps {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: any
  onFocus?: any
  onKeyDown?: any
  helperText?: any
  error?: any
  halfWidth?: boolean
  inputRef?: React.RefObject<HTMLTextAreaElement>
  readonly?: boolean
  autoComplete?: string
  rows: number
  ignoreHeight?: boolean
}

export const FloatingTextArea = (props: FloatingTextAreaProps) => {
  const { label, id, value, onChange, onBlur, inputRef, readonly, helperText, onFocus, onKeyDown, autoComplete, rows } =
    props

  return (
    <div className='inputContainer'>
      <div>
        <textarea
          rows={rows}
          className={classes({
            'form-control': true,
            'ignore-height': props.ignoreHeight,
          })}
          placeholder=' '
          id={id}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          ref={inputRef}
          disabled={readonly}
          autoComplete={autoComplete}
          name={id}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {helperText && <div className='invalid-feedback mb-3'>{helperText}</div>}
    </div>
  )
}
