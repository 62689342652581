import { useCesium } from '~/state'

type Map = ReturnType<typeof useCesium>

export abstract class PolygonRenderer3DWithoutEvents {
  protected _map: Map
  protected _color: string
  protected _heightOffset: number
  protected _toExclude: any[]


  constructor(map: Map, color: string) {
    this._map = map
    this._color = color
    this._heightOffset = 0.075
    this._toExclude = []
  }

  public destroy() {
    document.body.style.cursor = 'default'
  }

  protected createPoint(pos: Cesium.Cartesian3, pointIndex: number, pointSize = 8.0, customID?: string) {
    return {
      show: true,
      position: pos,
      pixelSize: pointSize,
      color: Cesium.Color.fromCssColorString(this._color),
      outlineColor: Cesium.Color.TRANSPARENT,
      outlineWidth: 0.0,
      id: customID || 'point-' + pointIndex,
      disableDepthTestDistance: 100000,
    }
  }

  protected createPoint2(
    pos: Cesium.Cartesian3,
    pointIndex: number,
    customID?: string
  ): Cesium.Entity.ConstructorOptions {
    return {
      show: true,
      position: pos,
      id: customID || 'point-' + pointIndex,
      point: {
        pixelSize: 4.0,
        color: Cesium.Color.fromCssColorString(this._color),
        outlineColor: Cesium.Color.TRANSPARENT,
        outlineWidth: 0.0,
        disableDepthTestDistance: 100000,
        scaleByDistance: new Cesium.NearFarScalar(1, 1, 100, 2),
      },
    }
  }

  protected createMiddlePoint2(
    pos: Cesium.Cartesian3,
    pointIndex: number,
    customID?: string
  ): Cesium.Entity.ConstructorOptions {
    return {
      show: true,
      position: pos,
      id: customID || 'middle-point-' + pointIndex,
      point: {
        pixelSize: 2.0,
        color: Cesium.Color.WHITE,
        outlineColor: Cesium.Color.fromCssColorString(this._color),
        outlineWidth: 2,
        disableDepthTestDistance: 100000,
        scaleByDistance: new Cesium.NearFarScalar(1, 1, 100, 2),
      },
    }
  }

  protected createMiddlePoint(pos: Cesium.Cartesian3, pointIndex: number) {
    return {
      show: true,
      position: pos,
      pixelSize: 4.0,
      color: Cesium.Color.WHITE,
      outlineColor: Cesium.Color.fromCssColorString(this._color),
      outlineWidth: 4,
      id: 'middle-point-' + pointIndex,
      // disableDepthTestDistance: 10,
    }
  }

  protected createPolylineConstructor(
    positions: Cesium.Property | Cesium.Cartesian3[],
    dashed = false,
    enableClassification = true
  ): Cesium.PolylineGraphics.ConstructorOptions {
    const c: Cesium.PolylineGraphics.ConstructorOptions = {
      show: true,
      positions,
      material: dashed
        ? new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            gapColor: Cesium.Color.TRANSPARENT,
            dashLength: 10,
          })
        : new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color).withAlpha(0.7),
            outlineColor: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
            outlineWidth: 1,
          }),
      width: dashed ? 3 : 3,
    }

    if (enableClassification) {
      c.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
      c.clampToGround = true
    }

    return c
  }

  protected createPolygonConstructor(
    positions: Cesium.CallbackProperty | Cesium.PolygonHierarchy,
    dashed = false,
    enableClassification = true
  ): Cesium.PolygonGraphics.ConstructorOptions {
    const c: Cesium.PolygonGraphics.ConstructorOptions = {
      show: true,
      hierarchy: positions,
      material: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
      
    }
    if(enableClassification) {
      c.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
      c.outline = true
      c.outlineColor = Cesium.Color.fromCssColorString(this._color)
      c.outlineWidth = 3
    }
    return c
  }

  protected createPolylineConstructor2(positions: Cesium.Cartesian3[], dashed = false): Cesium.Primitive {
    const material = Cesium.Material.fromType('Color')
    material.uniforms.color = Cesium.Color.fromCssColorString(this._color).withAlpha(0.5)
    return new Cesium.Primitive({
      allowPicking: false,
      asynchronous: true,
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.PolylineGeometry({
          positions,
          arcType: Cesium.ArcType.NONE,
          width: 3,
        }),
      }),
      appearance: new Cesium.PolylineMaterialAppearance({
        material: material,
      }),
    })
  }
}
