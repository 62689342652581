import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from '~/components'
import {
  ChangeRoleMutation,
  MutationUserChangeRoleInOrganizationArgs,
  OrganizationRole,
  orgRoleAccess,
  orgRoleToString,
} from '~/models'
import { useUser } from '~/base'
import CHANGE_ROLE from './mutation-change-role.gql'

interface RoleDropdownProps {
  value: OrganizationRole
  pending: boolean
  userID: string
  reload: () => Promise<any>
}

export function RoleDropdown(props: RoleDropdownProps) {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<OrganizationRole>(props.value)

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  const handleChange = (selectedValue: OrganizationRole, userID: string) => {
    setSelectedValue(selectedValue)
    executeRoleChange(userID, selectedValue)
    setOpen(false)
    props.reload()
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const [execute] = useMutation<ChangeRoleMutation, MutationUserChangeRoleInOrganizationArgs>(CHANGE_ROLE)

  const executeRoleChange = (userID: string, newRole: OrganizationRole) => {
    execute({
      variables: {
        input: {
          userID,
          newRole,
        },
      },
    })
  }

  const user = useUser()
  const currentOrg = user.orgs.find((o) => o.id === user.currentOrgId)

  const options = [
    {
      label: orgRoleToString(OrganizationRole.AccountOwner),
      value: OrganizationRole.AccountOwner,
    },
    {
      label: orgRoleToString(OrganizationRole.Admin),
      value: OrganizationRole.Admin,
    },
    {
      label: orgRoleToString(OrganizationRole.Member),
      value: OrganizationRole.Member,
    },
  ]

  if (currentOrg.canManageAdvancedAccess) {
    options.push({
      label: orgRoleToString(OrganizationRole.Guest),
      value: OrganizationRole.Guest,
    })
  }

  return (
    <div ref={dropdownNode} className='dropdown'>
      <button
        className={`btn dropdown-toggle ${open ? 'active' : ''}`}
        type='button'
        id={`dropdownMenuButton-${props.userID}`}
        data-toggle={`dropdown-${props.userID}`}
        aria-haspopup='true'
        aria-expanded={open}
        onClick={() => setOpen(!open)}
        disabled={props.pending}
      >
        {options.find((o) => o.value === selectedValue)?.label || orgRoleToString(OrganizationRole.Guest)}
      </button>
      <div
        className='dropdown-menu dropdown-menu-right'
        aria-labelledby={`dropdownMenuButton-${props.userID}`}
        style={{ display: open ? 'block' : 'none' }}
      >
        {options.map((o) => (
          <a className='dropdown-item' onClick={() => handleChange(o.value, props.userID)} key={o.label}>
            <div className={`dropdown-item-radio ${o.value === selectedValue ? 'checked' : ''}`}>
              <div />
            </div>
            <div className='dropdown-item-container'>
              {o.label}
              <div>{orgRoleAccess(o.value)}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}
