import React from 'react'
import './slim-loader.scss'

type Props = {
  progress?: number
  height?: number
}

export const SlimLoader = ({ progress = 0, height = 5 }: Props) => {
  return (
    <div className='slim-loader' style={{ height: `${height}px` }}>
      <div className='slim-loader-progress' style={{ width: `${progress}%` }}></div>
    </div>
  )
}
