import { useState, useEffect } from 'react'
import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import { useUser } from './use-user'
import REPORT_ERROR_MUTATION from '../components/errors/mutation-report-error.gql'

interface PollingQueryResult<TData, TVariables> {
  error: ApolloError
  data: TData
  startPolling: (pollInterval: number) => void
  stopPolling: () => void
  loading: boolean
  retry: () => void
  refetch: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>
}

export function usePollingQuery<TData, TVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): PollingQueryResult<TData, TVariables> {
  const [isLoading, setLoading] = useState(true)
  const [loggedError, setLoggedError] = useState(false)

  const { loading, error, data, startPolling, stopPolling, refetch } = useQuery(query, options)
  const user = useUser()

  // Poll query every 30 seconds.
  useEffect(() => {
    if (data) {
      setLoading(false)
      startPolling(options?.pollInterval || Math.floor(Math.random() * (90000 - 60000 + 1) + 60000))
    }
    return (): void => stopPolling()
  }, [data])

  useEffect(() => {
    if (!error || loggedError) {
      return
    }

    if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
      const err = error.graphQLErrors[0]

      let message = `${user.id} ${user.fullName} ${user.org?.id || ''} ${user.org?.name || ''} ${
        error.message
      }`
      if (Array.isArray(err.path) && err.path.length > 0) {
        message = message + ' ' + err.path.join(', ')
      }

      if (!message.includes('You need to sign in')) {
        window.__apolloClient.mutate({
          mutation: REPORT_ERROR_MUTATION,
          variables: {
            input: {
              url: window.location.href,
              message: message,
              stack: 'No stack available',
            },
          },
        })
      }

      setLoggedError(true)
    }
  }, [error, loggedError])

  // Keep track of loading state since apollo does not
  // set the loading variable to true if you call refetch.
  const currentlyLoading = (isLoading || loading) && !error
  const retry = () => {
    setLoading(true)
    refetch().finally(() => setLoading(false))
  }

  return {
    error,
    data,
    startPolling,
    stopPolling,
    loading: currentlyLoading,
    retry,
    refetch,
  }
}
