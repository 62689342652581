import React, { useState } from 'react'
import { SurveyBasedOperation } from '~/models'
import { useAppState } from '~/state'
import { OperationTypeSelect } from '../operation-type'

interface ItemTagDeleteProps {
  title: string
  onClickDelete?: (operationType: SurveyBasedOperation) => Promise<any>
  cancel: () => void
  hideCaution?: boolean
  hideOperationType?: boolean
}

export const ItemTagDelete = (props: ItemTagDeleteProps) => {
  const { timeline } = useAppState()
  const [deleting, setDeleting] = useState(false)
  const [operationType, setOperationType] = useState(SurveyBasedOperation.GoingForward)

  return (
    <div className='item-tag-delete-confirm'>
      <h6>Delete {props.title}?</h6>
      {props.hideOperationType !== true && (
        <OperationTypeSelect
          name='operationType'
          selectedValue={operationType}
          onChange={(e) => {
            setOperationType((e.target as any).value)
          }}
          survey={timeline.activeSurvey}
        />
      )}
      <button
        className='item-tag-delete-confirm-delete btn'
        disabled={deleting}
        onClick={() => {
          setDeleting(true)
          props.onClickDelete(operationType).finally(() => {
            setDeleting(false)
          })
        }}
      >
        {deleting ? 'Deleting...' : 'Yes, delete'}
      </button>
      <button className='item-tag-delete-confirm-cancel btn' disabled={deleting} onClick={props.cancel}>
        Cancel
      </button>
      {!props.hideCaution && <p>Caution: This cannot be undone.</p>}
    </div>
  )
}
