import React from 'react'
import { useUser } from '~/base'
import { getLargeImageURL } from '~/components'

export const PhotoDownload = () => {
  const user = useUser()
  // Get the hash from the URL.
  const urlParams = new URLSearchParams(window.location.search)
  const photoHash = urlParams.get('h')

  // Make sure the hash is an MD5 string.
  const hashMatchRegex = /^[a-f0-9]{32}$/gi
  if (!hashMatchRegex.test(photoHash)) {
    window.location.href = '/404'
    return null
  }

  // Make sure the user is logged in.
  if (!user.isLoggedIn) {
    window.location.href = '/signin?r=' + window.location.pathname
    return null
  }

  // Redirect to the photo.
  window.location.href = getLargeImageURL(
    {
      hash: photoHash,
    },
    user.org.id
  )

  return <div>Redirecting...</div>
}
