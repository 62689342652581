import React, { useEffect } from 'react'
import { ToastInfo, ToastPosition } from './use-toast-state'

interface ToastProps {
  toastInfo?: ToastInfo
  removeMe: () => void
}

export const Toast = (props: ToastProps) => {
  const { toastInfo } = props

  useEffect(() => {
    if (!toastInfo) {
      return
    }

    setTimeout(() => {
      if (toastInfo.autoDismiss) {
        props.removeMe()
      }
    }, toastInfo.timeout || 3000)
  }, [toastInfo])

  return (
    <div className={`notification-container ${toastPositionToString(toastInfo.position)}`}>
      <div className={`notification toast ${toastPositionToString(toastInfo.position)}`}>
        <p className='notification-text'>{toastInfo.text}</p>
      </div>
    </div>
  )
}

function toastPositionToString(pos: ToastPosition) {
  switch (pos) {
    case ToastPosition.TopLeft:
      return 'top-left'
    case ToastPosition.BottomLeft:
      return 'bottom-left'
    case ToastPosition.TopRight:
      return 'top-right'
    case ToastPosition.BottomRight:
      return 'bottom-right'
  }
}
