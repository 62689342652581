query adminGetMonitoringZoneTargets {
  monitoringZoneTargets {
    id
    name
    description
    archived
    createdAt
    createdBy
    updatedAt
    updatedBy
    archivedAt
    archivedBy
    numExisting
    subTypes {
      id
      name
      description
      archived
      createdAt
      createdBy
      updatedAt
      updatedBy
      archivedAt
      archivedBy
      numExisting
    }
  }
}