query getAssetTypeByID($assetTypeID: String!) {
  assetTypeByID(assetID: $assetTypeID) {
    id
    name
    description
    archived
    createdAt
    createdBy
    updatedAt
    updatedBy
    componentTypes {
      id
      name
      description
      archived
      createdAt
      createdBy
      updatedAt
      updatedBy
      measurement
      materialTypes {
        id
        name
        description
        archived
      }
      children {
        id
        name
        description
        archived
        createdAt
        createdBy
        updatedAt
        updatedBy
        measurement
        materialTypes {
          id
          name
          description
          archived
        }
        children {
          id
          name
          description
          archived
          createdAt
          createdBy
          updatedAt
          updatedBy
          measurement
          materialTypes {
            id
            name
            description
            archived
          }
          children {
            id
            name
            description
            archived
            createdAt
            createdBy
            updatedAt
            updatedBy
            measurement
            materialTypes {
              id
              name
              description
              archived
            }
          }
        }
      }
    }
  }
}
