import {
  ApolloError,
  FetchResult,
  MutationOptions,
  MutationResult,
  MutationUpdaterFn,
  useMutation as useMutationImpl,
} from '@apollo/client'
import { useState } from 'react'
import { ulid } from 'ulid'

interface BaseMutationHookOptions<TData, TVariables>
  extends Omit<MutationOptions<TData, TVariables>, 'mutation' | 'update'> {
  update?: MutationUpdaterFn<TData>
  rethrow?: boolean
}

type MutationFn<TData, TVariables> = (
  options?: BaseMutationHookOptions<TData, TVariables>
) => Promise<FetchResult<TData>>

export function useMutation<TResult, TVariables>(
  mutation: any
): [MutationFn<TResult, TVariables>, MutationResult<TResult>] {
  const [doMutation, res] = useMutationImpl<TResult, { input: TVariables }>(mutation)
  const [loading, setLoading] = useState(res.loading)

  const doExecuteMutation = ({ input, resolve, reject, attempt, correlationId }: any) => {
    doMutation({
      variables: input.variables,
      context: { correlationId },
    })
      .then((data: any) => {
        if (data) {
          resolve(data)
        }
      })
      .catch((err: ApolloError) => {
        console.log('Error', JSON.stringify(err, null, 2))
        const message = err.message || 'We have encountered an error, please try again.'
        const code = '500'

        if (message.indexOf('already exists') !== -1 || message.indexOf('must be defined') !== -1) {
          reject({
            code: code,
            message: message === 'must be defined' ? 'A validation error has occured.' : message,
            correlationId,
          } as any)
          return
        }

        if (attempt < 2) {
          setTimeout(() => {
            doExecuteMutation({ input, resolve, reject, attempt: attempt + 1, correlationId })
          }, 1000)
        } else {
          reject({
            code: code,
            message: message,
            correlationId,
          } as any)
        }
      })
  }

  const executeMutation = (variables: TVariables) =>
    new Promise<TResult>((resolve, reject) => {
      setLoading(true)
      doExecuteMutation({
        input: variables,
        resolve,
        reject,
        attempt: 0,
        correlationId: ulid(),
      })
    }).finally(() => {
      setLoading(false)
    })

  return [executeMutation, { ...res, loading }]
}

export { FetchResult } from '@apollo/client'
