query ActivityStreamForOrg($input: QueryOrganizationActivityStreamRequestT!) {
  activityStreamForOrg(input: $input) {
    items {
      data {
        type
        value {
          ... on ActivityItemForOrgOrganizationCreatedT {
            time
            orgID
            orgName
          }
          ... on ActivityItemForOrgUserJoinedOrgT {
            times
            userIDs
            userNames
          }
          ... on ActivityItemForOrgSitesCreatedT {
            items {
              userID
              userName
              siteID
              siteName
              time
            }
          }
          ... on ActivityItemForOrgSitesUpdatedT {
            items {
              userID
              userName
              siteID
              siteName
              time
            }
          }
          ... on ActivityItemForOrgImagesUploadedT {
            numImages
            hashes
            time
          }
        }
      }
    }
    total
  }
}
