query Search($input: SearchRequestT!) {
  search(input: $input) {
    items {
      siteID
      siteName
      siteNumber
      siteAddress
      assets {
        surveyID
        surveyStartDate
        surveyEndDate
        assetID
        assetName
      }
    }
  }
}
