import { PointT } from '~/models'
import { getPolygonCenter } from '~/components'

interface PolygonRendererViewConstructor {
  points?: PointT[] | number[][]
  color: string
  viewer: Cesium.Viewer
  label?: string
  onClick?: () => void
  renderPoints?: boolean
  index?: number
  id?: string
}

export class PolygonRendererView {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _polylineEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _pointsLngLat: number[][]
  private _centerLngLat: number[]
  private _labelEntity: Cesium.Entity
  private _label?: string
  private _index?: number
  private _id?: string
  private _renderPoints: boolean
  protected _viewer: Cesium.Viewer
  protected _color: string

  constructor(args: PolygonRendererViewConstructor) {
    this._viewer = args.viewer
    this._color = args.color

    this._pointsLngLat = []
    if(args.points.length > 0) {
      const first = args.points[0]
      if(Array.isArray(first)) {
        this._pointsLngLat = args.points as number[][]
      } else {
        this._pointsLngLat = (args.points as PointT[]).map((p: PointT) => {
          return [p.longitude, p.latitude]
        })
      }
    }
    
    this._positions = this._pointsLngLat.map((p) => Cesium.Cartesian3.fromDegrees(p[0], p[1], 0))
    this._points = new Cesium.PointPrimitiveCollection()
    this._positions.forEach((p, i) => {
      this._points.add(this.createPoint(p, i, 6))
    })
    this._centerLngLat = getPolygonCenter(this._pointsLngLat)
    this._label = args.label
    this._index = args.index
    this._id = args.id
    this._renderPoints = args.renderPoints === undefined ? true : args.renderPoints

    const polyline = this.createPolylineConstructor(
      new Cesium.CallbackProperty(() => {
        return [...this._positions, this._positions[0]]
      }, false)
    )
    this._polylineEntity = args.viewer.entities.add({ polyline })
    if (this._renderPoints) {
      this._pointsEntity = args.viewer.scene.primitives.add(this._points)
    }

    if (this._label) {
      this._labelEntity = this._viewer.entities.add({
        id: (this._id ? this._id : this._label + (this._index || '0')) + '-label',
        position: Cesium.Cartesian3.fromDegrees(this._centerLngLat[0], this._centerLngLat[1]),
        label: {
          text: this._label,
          font: '16px Roboto',
          backgroundColor: Cesium.Color.fromCssColorString(this._color + "AA"),
          showBackground: true,
          fillColor: Cesium.Color.fromCssColorString("#FFFFFF"),
          horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          style: Cesium.LabelStyle.FILL,
          scaleByDistance: new Cesium.NearFarScalar(1e1, 1, 1e3, 0.5),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
      })
    }
  }

  public destroy() {
    this._viewer.entities.remove(this._polylineEntity)
    if (this._pointsEntity) {
      this._viewer.scene.primitives.remove(this._pointsEntity)
    }
    if (this._label) {
     this._viewer.entities.remove(this._labelEntity)
    }
  }
  protected createPoint(pos: Cesium.Cartesian3, pointIndex: number, pointSize = 8.0) {
    return {
      show: true,
      position: pos,
      pixelSize: pointSize,
      color: Cesium.Color.fromCssColorString(this._color),
      outlineColor: Cesium.Color.TRANSPARENT,
      outlineWidth: 0.0,
      id: 'point-' + pointIndex,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    }
  }

  protected createPolylineConstructor(
    positions: Cesium.Property,
    dashed = false
  ): Cesium.PolylineGraphics.ConstructorOptions {
    return {
      show: true,
      positions,
      material: dashed
        ? new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            gapColor: Cesium.Color.TRANSPARENT,
            dashLength: 10,
          })
        : new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            outlineColor: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
            outlineWidth: 2,
          }),
      width: dashed ? 3 : 4,
      clampToGround: true,
    }
  }
}
