import React from 'react'
import { useUser } from '~/base'
import { SiteQuerySurvey } from '~/models'
import { getIssueEstimatedRepairCost } from './issue-repair-cost-calculator'

interface IssueRepairEstimateProps {
  issue?: SiteQuerySurvey['issues'][0]
}

export const IssueRepairEstimate = (props: IssueRepairEstimateProps) => {
  const user = useUser()

  const estimate = getIssueEstimatedRepairCost(user, props.issue)

  return (
    <>
      {estimate.formatted}
      {estimate.byAsseti && (
        <div
          style={{
            fontSize: '9px',
            position: 'relative',
            top: '2px',
            opacity: '0.7',
          }}
        >
          {/* <i
            className='material-icons'
            style={{
              fontSize: '14px',
              display: 'inline-block',
              marginLeft: '0px',
              position: 'relative',
              top: '4px',
              opacity: '0.7',
            }}
          >
            bolt
          </i> */}
          Estimated by Asseti
        </div>
      )}
    </>
  )
}
