import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { classes } from '~/components'
import { IssueStatus, RiskType } from '~/models'

type IssueFilterTarget = RiskType | IssueStatus | number | string

interface IssueFilterProps<T extends IssueFilterTarget> {
  type: 'risk' | 'status' | 'priority' | 'component-type' | 'defect-type'
  subType: T
  options: Array<{
    id: T
    text: string
    alt?: boolean
  }>
  update: (v: T) => void
  inline?: boolean
}

export function IssueFilter<T extends IssueFilterTarget>(props: IssueFilterProps<T>) {
  const [open, setOpen] = useState(false)
  const badge = useRef<HTMLDivElement>(null)
  const dropdown = useRef<HTMLDivElement>(null)

  const computeStyle = (): CSSProperties => {
    if (!badge.current) {
      return {}
    }

    const rect = badge.current.getBoundingClientRect()

    return {
      position: 'fixed',
      top: rect.bottom + 'px',
      left: rect.left + 'px',
      minWidth: rect.width + 'px',
    }
  }

  useEffect(() => {
    if (!open) {
      return
    }
    
    function handleClickOutside(e: MouseEvent) {
      if(e.defaultPrevented) {
        return
      }
      if (dropdown.current && !dropdown.current.contains(e.target as any)) {
        setOpen(false)
      }
    }
    window.addEventListener('click', handleClickOutside, true)
    return () => {
      window.removeEventListener('click', handleClickOutside, true)
    }
  }, [open, dropdown])

  const option = props.options.find((x) => x.id === props.subType)
  let text = option?.text
  if (option?.alt) {
    text = option.id as string
  }

  return (
    <>
      <div
        ref={badge}
        className={classes({
          'issue-filter': true,
          [props.type]: true,
          [props.subType || '']: true,
          inline: props.inline,
        })}
        onClick={(e) => {
          if(!open) {
            e.preventDefault()
          }
          setOpen(!open)
        }}
      >
        {text || props.subType}
        <span>arrow_left</span>
      </div>
      {open && badge.current && (
        <div className='issue-filter-options' style={computeStyle()} ref={dropdown}>
          {props.options.map((o) => {
            return (
              <div
                className='issue-filter-option'
                key={o.id}
                onClick={() => {
                  setOpen(false)
                  props.update(o.id)
                }}
              >
                {o.text}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
