import React, { ReactNode } from 'react'
import './label.scss'

type Props = {
  id?: string
  children: ReactNode
}

export const Label = ({ id, children }: Props) => {
  return (
    <label htmlFor={id} className='input-label'>
      {children}
    </label>
  )
}
