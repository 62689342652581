import React, { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { getMidpoint, LabelTemplate, pickFromPosition, Point, PointAndLineColor, pointFromPosition } from './utils'
import { formatDistance, getDistance } from './measurement-formats'

export const MeasureDistance = () => {
  const { map } = useAppState()
  const [points] = useState(new Cesium.PointPrimitiveCollection())
  const [tmpPoints] = useState(new Cesium.PointPrimitiveCollection())
  const [polyLines] = useState(new Cesium.PolylineCollection())
  const [tmpPolyLines] = useState(new Cesium.PolylineCollection())
  const [labels] = useState(
    new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
  )

  useEffect(() => {
    const viewer = map.viewer
    const scene = viewer.scene
    const ellipsoid = Cesium.Ellipsoid.WGS84
    const geodesic = new Cesium.EllipsoidGeodesic()

    scene.primitives.add(points)
    scene.primitives.add(tmpPoints)
    scene.primitives.add(polyLines)
    scene.primitives.add(tmpPolyLines)
    scene.primitives.add(labels)

    setTimeout(() => {
      tmpPolyLines.add({
        show: false,
        positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
        width: 1,
        material: new Cesium.Material({
          fabric: {
            type: 'Color',
            uniforms: {
              color: PointAndLineColor,
            },
          },
        }),
      })
      tmpPolyLines.add({
        show: false,
        positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
        width: 1,
        material: new Cesium.Material({
          fabric: {
            type: 'PolylineDash',
            uniforms: {
              color: PointAndLineColor,
            },
          },
        }),
      })
      tmpPolyLines.add({
        show: false,
        positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
        width: 1,
        material: new Cesium.Material({
          fabric: {
            type: 'PolylineDash',
            uniforms: {
              color: PointAndLineColor,
            },
          },
        }),
      })

      labels.add({
        ...LabelTemplate,
        font: '13px monospace',
        position: new Cesium.Cartesian3(0, 0, 0),
      })

      labels.add({
        ...LabelTemplate,
        position: new Cesium.Cartesian3(0, 0, 0),
      })

      labels.add({
        ...LabelTemplate,
        position: new Cesium.Cartesian3(0, 0, 0),
      })

      labels.add({
        ...LabelTemplate,
        position: new Cesium.Cartesian3(0, 0, 0),
      })

      labels.add({
        ...LabelTemplate,
        position: new Cesium.Cartesian3(0, 0, 0),
      })
    }, 0)

    let point1: Point, point2: Point
    let tmpPoint1: Point, tmpPoint2: Point

    function addDistanceLabel(point1: Point, point2: Point, height: number) {
      const c1 = ellipsoid.cartesianToCartographic(point1.position)

      const distance = getDistance(geodesic, point1.position, point2.position)
      const horizontalDistance = getHorizontalDistance(point1, point2)
      const verticalDistance = getVerticalDistance(point1, point2)

      const angle = Cesium.Math.toDegrees(Math.asin(verticalDistance / distance))

      // Always add the hypotenuse distance.
      const label0 = labels.get(0)
      const label1 = labels.get(1)
      const label2 = labels.get(2)
      const label3 = labels.get(3)
      const label4 = labels.get(4)

      label0.text = formatDistance(distance)
      label0.position = getMidpoint(geodesic, point1, point2, height)

      const is3D = verticalDistance > 1
      if (is3D) {
        label1.text = formatDistance(horizontalDistance)
        label1.position = getMidpoint(geodesic, point1, point2, c1.height)
        label2.text = formatDistance(verticalDistance)
        label2.position = getMidpoint(geodesic, point2, point2, height)
        label3.text = `${(90 - angle).toFixed(1)}°`
        label3.position = point2.position
        label4.text = `${angle.toFixed(1)}°`
        label4.position = point1.position
      } else {
        label1.position = new Cesium.Cartesian3(0, 0, 0)
        label2.position = new Cesium.Cartesian3(0, 0, 0)
        label3.position = new Cesium.Cartesian3(0, 0, 0)
        label4.position = new Cesium.Cartesian3(0, 0, 0)
      }
    }

    function getHorizontalDistance(point1: Point, point2: Point): number {
      const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
      const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
      geodesic.setEndPoints(point1GeoPosition, point2GeoPosition)
      const meters = geodesic.surfaceDistance
      return meters
    }

    function getVerticalDistance(point1: Point, point2: Point): number {
      const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
      const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)
      const heights = [point1GeoPosition.height, point2GeoPosition.height]
      const meters = Math.max(...heights) - Math.min(...heights)
      return meters
    }

    function getIgnoredEntities() {
      return [labels.get(0), labels.get(1), labels.get(2), labels.get(3), labels.get(4)]
    }

    // Mouse over the globe to see the cartographic position
    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
        return
      }
      pickFromPosition(viewer, click.position, getIgnoredEntities()).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }

        if (points.length === 2) {
          points.removeAll()
          polyLines.removeAll()
          tmpPoints.removeAll()

          tmpPoint1 = tmpPoints.add(
            pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
          ) as Point
        }
        //add first point
        if (points.length === 0) {
          point1 = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
        } //add second point and lines
        else if (points.length === 1) {
          point2 = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
          const point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position)
          const point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position)

          const pl1Positions = [
            Cesium.Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
          ]
          const pl2Positions = [
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]
          const pl3Positions = [
            Cesium.Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]

          polyLines.add({
            show: true,
            positions: pl1Positions,
            width: 1,
            material: new Cesium.Material({
              fabric: {
                type: 'Color',
                uniforms: {
                  color: PointAndLineColor,
                },
              },
            }),
          })
          polyLines.add({
            show: true,
            positions: pl2Positions,
            width: 1,
            material: new Cesium.Material({
              fabric: {
                type: 'PolylineDash',
                uniforms: {
                  color: PointAndLineColor,
                },
              },
            }),
          })
          polyLines.add({
            show: true,
            positions: pl3Positions,
            width: 1,
            material: new Cesium.Material({
              fabric: {
                type: 'PolylineDash',
                uniforms: {
                  color: PointAndLineColor,
                },
              },
            }),
          })
          let labelZ
          if (point2GeoPosition.height >= point1GeoPosition.height) {
            labelZ = point1GeoPosition.height + (point2GeoPosition.height - point1GeoPosition.height) / 2.0
          } else {
            labelZ = point2GeoPosition.height + (point1GeoPosition.height - point2GeoPosition.height) / 2.0
          }

          addDistanceLabel(point1, point2, labelZ)
        }
      })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    const mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    mouseMoveHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
        return
      }

      if (points.length === 2) {
        tmpPoint1.show = false
        tmpPoint2.show = false
        tmpPolyLines.get(0).show = false
        tmpPolyLines.get(1).show = false
        tmpPolyLines.get(2).show = false
        return
      }

      pickFromPosition(viewer, click.endPosition, getIgnoredEntities()).then((picked) => {
        const cartesian = picked.cartesian
        if (!Cesium.defined(cartesian)) {
          return
        }

        // We haven't added any points yet.
        if (points.length === 0) {
          if (tmpPoints.length === 0) {
            tmpPoint1 = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point
          } else {
            tmpPoint1.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          }
        }

        // We've added one point.
        if (points.length === 1) {
          if (tmpPoints.length === 1) {
            tmpPoint2 = tmpPoints.add(
              pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
            ) as Point
          } else {
            tmpPoint2.position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
          }

          const point1GeoPosition = Cesium.Cartographic.fromCartesian(tmpPoint1.position)
          const point2GeoPosition = Cesium.Cartographic.fromCartesian(tmpPoint2.position)

          const pl1Positions = [
            Cesium.Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
          ]
          const pl2Positions = [
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]
          const pl3Positions = [
            Cesium.Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            Cesium.Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]

          tmpPolyLines.get(0).positions = pl1Positions
          tmpPolyLines.get(1).positions = pl2Positions
          tmpPolyLines.get(2).positions = pl3Positions
          tmpPolyLines.get(0).show = true
          tmpPolyLines.get(1).show = true
          tmpPolyLines.get(2).show = true

          let labelZ
          if (point2GeoPosition.height >= point1GeoPosition.height) {
            labelZ = point1GeoPosition.height + (point2GeoPosition.height - point1GeoPosition.height) / 2.0
          } else {
            labelZ = point2GeoPosition.height + (point1GeoPosition.height - point2GeoPosition.height) / 2.0
          }

          addDistanceLabel(tmpPoint1, tmpPoint2, labelZ)
        }
      })
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        points.removeAll()
        polyLines.removeAll()
        tmpPoints.removeAll()
        tmpPolyLines.get(0).show = false
        tmpPolyLines.get(1).show = false
        tmpPolyLines.get(2).show = false
        const label0 = labels.get(0)
        const label1 = labels.get(1)
        const label2 = labels.get(2)
        const label3 = labels.get(3)
        const label4 = labels.get(4)
        label0.position = new Cesium.Cartesian3(0, 0, 0)
        label1.position = new Cesium.Cartesian3(0, 0, 0)
        label2.position = new Cesium.Cartesian3(0, 0, 0)
        label3.position = new Cesium.Cartesian3(0, 0, 0)
        label4.position = new Cesium.Cartesian3(0, 0, 0)
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      scene.primitives.remove(points)
      scene.primitives.remove(tmpPoints)
      scene.primitives.remove(polyLines)
      scene.primitives.remove(tmpPolyLines)
      scene.primitives.remove(labels)
      leftClickHandler.destroy()
      mouseMoveHandler.destroy()
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return <></>
}
