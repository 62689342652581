import { HomeLayout } from './home-layout'
import { LeftDrawer } from './left-drawer'
import { MapContainer } from '../map'
import React, { useEffect } from 'react'
import { RightDrawer } from './right-drawer'
import { useAppState } from '~/state'
import { useAuthStatus, useUser } from '~/base'
import { ToastManager, ToastStateProvider } from '~/components'
import { MainBar } from './main-bar'

export const Home = () => {
  const { map } = useAppState()
  const user = useUser()
  useAuthStatus()
  
  useEffect(() => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user.id,
          full_name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          orgRole: user.org?.role || 'No role set',
        },
        account: {
          id: user.org?.id,
          accountName: user.org?.name || 'No Org Name',
          workspaceDomain: user.org?.domainName || 'No Domain Name',
          requiresSubscription: user.org?.requiresSubscription === true,
          canManageAdvancedAccess: user.org?.canManageAdvancedAccess === true,
          orgRequires2FA: user.org?.requires2FA === true,
          systemRole: user.systemRole,
        },
      })
    }
  }, [user])

  return (
    <ToastStateProvider>
      <HomeLayout>
        {map.initialized && <MainBar />}
        {map.initialized && <RightDrawer />}
        {map.initialized && <LeftDrawer />}
        <MapContainer />
        <div id='interior-model-container'></div>
      </HomeLayout>
      <ToastManager />
    </ToastStateProvider>
  )
}

