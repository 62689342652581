import React from 'react'
import { getTracking } from '~/components'
import { useAppState } from '~/state'

export const SiteError = () => {
  const { site } = useAppState()
  getTracking().event({
    category: 'Error',
    action: `User site error`,
    label: 'Site',
  })

  return (
    <div className='site-not-found'>
      An error occurred while loading the site
      <button className='btn mt-3 danger' onClick={() => site.refetch()}>
        Retry
      </button>
    </div>
  )
}
