import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import React, { useEffect } from 'react'

import { useUser } from '~/base'
import { getTracking } from '~/components'

export const AuthSignedOut = () => {
  getTracking().event({
    category: 'Button',
    action: `User signed in`,
    label: 'Auth',
  })
  const user = useUser()
  user.logout()
  
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.pathname = '/signin'
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <div className='subheader-dark mt-5 mb-5'>You've been signed out</div>
      <p className='auth-description-text mb-4'>
        Redirecting to sign in...
      </p>
    </AuthLayout>
  )
}
