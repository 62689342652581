import { useCallback, useEffect, useRef, useState } from 'react'
import { useUser } from '~/base'
import { Config } from '~/config'
import { getTracking } from '../components/tracking'

export const useHubspotChat = () => {
  if(!(window as any).allowHubspot || window.location.host.includes('localhost')) {
    return
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const [activeConversation, setActiveConversation] = useState<boolean>(false)
  const eventRef = useRef<any>(null)
  const userInfo = useUser()

  useEffect(() => {
    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true)
      },
    ]

    // Don't show widget immediately.
    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    // Create script component.
    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${Config.HubspotPortalId}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      window.hsConversationsOnReady = []
    }
  }, [])

  // Subscripe to conversation events.
  useEffect(() => {
    eventRef.current = (payload: any) => {
      setActiveConversation(payload.conversation.conversationId)
    }

    if (hasLoaded && window.HubSpotConversations) {
      window.HubSpotConversations.on('conversationStarted', eventRef.current)
    }

    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.off('conversationStarted', eventRef.current)
      }
    }
  }, [hasLoaded])

  // Identify user.
  useEffect(() => {
    if (hasLoaded) {
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          id: userInfo.id,
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          orgID: userInfo.org.id,
          orgName: userInfo.org.name,
          orgRole: userInfo.org.role,
        },
      ])
      _hsq.push(['trackPageView'])
    }
  }, [hasLoaded])

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.load()
      setTimeout(() => {
        window.HubSpotConversations.widget.open()
      }, 6000)
      setIsOpen(true)
      getTracking().event({
        category: 'Button',
        action: `User clicked open live chat support button`,
        label: 'Support',
      })
    }
  }, [hasLoaded, isOpen])

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.remove()
      setIsOpen(false)
      getTracking().event({
        category: 'Button',
        action: `User clicked close live chat support button`,
        label: 'Support',
      })
    }
  }, [hasLoaded, isOpen])

  return {
    hasLoaded,
    isOpen,
    activeConversation,
    openHandler,
    closeHandler,
  }
}
