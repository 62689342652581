import { useEffect, MutableRefObject, Dispatch, SetStateAction } from 'react'
import { useRefState } from '~/state'
import { Point, ScreenState } from './screen-state'

export function useCanvasInteractions(
  canvas: MutableRefObject<HTMLCanvasElement>,
  screenState: MutableRefObject<ScreenState>,
  setScreenState: Dispatch<SetStateAction<ScreenState>>
) {

  const [leftMouseButtonDown, setLeftMouseButtonDown] = useRefState(false)
  const [start, setStart] = useRefState<Point>()

  useEffect(() => {
    if (!screenState.current) {
      return
    }

    const wheelHandler = (e: WheelEvent) => {
      const newZoom =
        e.deltaY < 0
          ? Math.min(screenState.current.zoom + 0.05, 4)
          : Math.max(screenState.current.zoom - 0.05, canvas.current.height / screenState.current.img.height)

      setScreenState(() => screenState.current.fromOffsetAndZoom(e.offsetX, e.offsetY, newZoom))
    }

    const mouseDownHandler = (e: MouseEvent) => {
      if (e.which !== 1) {
        return
      }

      setStart(() => ({
        x: e.screenX,
        y: e.screenY
      }))
      setLeftMouseButtonDown(() => true)
    }

    const mouseMoveHandler = (e: MouseEvent) => {
      if (e.which !== 1 || !leftMouseButtonDown.current || !screenState.current.dragAllowed) {
        return
      }

      const deltaX = e.screenX - start.current.x
      const deltaY = e.screenY - start.current.y
      setScreenState(() => screenState.current.fromDelta(deltaX, deltaY))

      setStart(() => ({
        x: e.screenX,
        y: e.screenY
      }))
    }

    const mouseUpHandler = (e: MouseEvent) => {
      if (e.which !== 1) {
        return
      }

      setLeftMouseButtonDown(() => false)
    }

    canvas.current.addEventListener('wheel', wheelHandler)
    canvas.current.addEventListener('mousedown', mouseDownHandler)
    canvas.current.addEventListener('mousemove', mouseMoveHandler)
    canvas.current.addEventListener('mouseup', mouseUpHandler)

    return () => {
      if (!canvas.current) {
        return
      }

      canvas.current.removeEventListener('wheel', wheelHandler)
      canvas.current.removeEventListener('mousedown', mouseDownHandler)
      canvas.current.removeEventListener('mousemove', mouseMoveHandler)
      canvas.current.removeEventListener('mouseup', mouseUpHandler)
    }
  }, [screenState.current, leftMouseButtonDown.current])
}
