import React from 'react'
import { Select, SelectOption } from '~/components'
import { IssueStatus } from '~/models'

interface StatusSelectProps {
  onChange: (r: IssueStatus) => void
  selectedValue: IssueStatus
}

export type StatusSelectItem = {
  id: IssueStatus
  value: string
}

export const STATUS_OPTIONS: Array<SelectOption<StatusSelectItem>> = [
  {
    id: IssueStatus.AwaitingAction,
    name: 'Awaiting Action',
    value: {
      id: IssueStatus.AwaitingAction,
      value: IssueStatus.AwaitingAction,
    },
    items: [],
  },
  {
    id: IssueStatus.InProgress,
    name: 'In Progress',
    value: {
      id: IssueStatus.InProgress,
      value: IssueStatus.InProgress,
    },
    items: [],
  },
  {
    id: IssueStatus.Complete,
    name: 'Complete',
    value: {
      id: IssueStatus.Complete,
      value: IssueStatus.Complete,
    },
    items: [],
  },
]

export const StatusSelect = (props: StatusSelectProps) => {
  return (
    <Select<StatusSelectItem>
      id='status'
      selectedValue={{
        id: props.selectedValue,
        value: props.selectedValue,
      }}
      label='Current Status'
      placeholder={''}
      onChange={(s) => {
        props.onChange(s.id)
      }}
      options={STATUS_OPTIONS}
    />
  )
}
