import { MutableRefObject } from 'react'
import { PolygonRenderer } from './polygon-renderer'
import { Point, ScreenState } from './screen-state'

export class PolygonRendererView extends PolygonRenderer {
  private _points: Point[]

  constructor(canvas: HTMLCanvasElement, screenState: MutableRefObject<ScreenState>, color: string, points: Point[]) {
    super(canvas, screenState, color)
    this._points = points
  }

  public onLeftClick() {
    /* Ignore */
  }

  public onMiddleClick() {
    /* Ignore */
  }

  public onRightClick() {
    /* Ignore */
  }

  public onLeftDown() {
    /* Ignore */
  }

  public onLeftUp() {
    /* Ignore */
  }

  public onMouseMove() {
    /* Ignore */
  }

  public onKeydown() {
    /* Ignore */
  }

  public _render() {
    const ctx = this._screenState.current.c.getContext('2d')
    const pointsToUse = [...this._points, this._points[0]]

    ctx.save()
    ctx.fillStyle = this._color
    ctx.strokeStyle = this._color
    ctx.lineWidth = 2

    for (let i = 1; i < pointsToUse.length; i++) {
      const prevPoint = pointsToUse[i - 1]
      const point = pointsToUse[i]

      const previousPosition = this._screenState.current.getCanvasPosition(prevPoint)
      const currentPosition = this._screenState.current.getCanvasPosition(point)

      ctx.beginPath()
      ctx.arc(previousPosition.left, previousPosition.top, 4.5, 0, 2 * Math.PI, false)
      ctx.closePath()
      ctx.fill()

      ctx.beginPath()
      ctx.moveTo(previousPosition.left, previousPosition.top)
      ctx.lineTo(currentPosition.left, currentPosition.top)
      ctx.closePath()
      ctx.stroke()
    }

    ctx.restore()
  }
}
