import React from 'react'
import { Config } from '~/config'
import { LocationInputT } from '~/models'
import { useRefState } from '~/state'
import SelectSearch from 'react-select-search'

interface AuthGeocoderProps {
  value?: LocationInputT
  setSelected: (sel: LocationInputT) => void
  error: boolean
  helperText?: string
}

export const AuthGeocoder = (props: AuthGeocoderProps) => {
  const [options] = useRefState<
    Array<{
      name: string
      value: string
      full: LocationInputT
    }>
  >([])

  return (
    <div>
      <SelectSearch
        id='address'
        options={options.current}
        getOptions={(s) => {
          if (s === '' || !s) {
            return Promise.resolve([])
          }

          return fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${s}.json?access_token=${Config.MapBoxToken}`)
            .then((res) => res.json())
            .then((data) => {
              if (Array.isArray(data.features) && data.features.length > 0) {
                const o = data.features.map((f: LocationInputT) => {
                  return {
                    name: f.place_name,
                    value: f.id,
                    full: f,
                  }
                })
                options.current = o
                return o
              } else {
                options.current = []
                return []
              }
            })
        }}
        value={
          ((props.value && {
            name: props.value.place_name,
            value: props.value.id,
          }) ||
            undefined) as any
        }
        onChange={(selectedValue, selectedOption) => {
          props.setSelected((selectedOption as any)?.full)
        }}
        placeholder='Address'
        autoComplete='off'
        className='auth-select'
        debounce={200}
        disabled={false}
        search
      />
      {props.helperText && <div className='invalid-feedback visible'>{props.helperText}</div>}
    </div>
  )
}
