import { useEffect, useState } from 'react'
import { UserInfo } from '~/base'
import { FileUploadState, UploadableFile } from 'ui'
import { post } from 'components'
import { Config } from 'config'
import { ulid } from 'ulid'
import { computeChecksumMd5, concurrentPromises, retryablePromise } from 'ui/utils'

type Props = {
  files: UploadableFile[]
  user: UserInfo & { token: string }
  uploadPath?: string
  chunkedUploadPath?: string
  chunkedUploadConfirmPath?: string
  maxConcurrentChunkUpload?: number
  maxConcurrentFileUpload?: number
  chunkSize?: number
}

type UploadQuery = {
  orgID: string
  n?: number // current chunk
  t?: number // total chunks
  h?: string // file hash
}

type State = { type: 'idle' } | { type: 'loading'; files: string[] } | { type: 'error'; error: string }

export const useFileUploadWithProgress = ({
  files,
  user,
  uploadPath = '/upload',
  chunkedUploadPath = '/upload-chunked',
  chunkedUploadConfirmPath = '/complete-chunks',
  maxConcurrentChunkUpload = 4,
  maxConcurrentFileUpload = 2,
  chunkSize = 10 * 1024 * 1024, // 10MB,
}: Props) => {
  const [filesWithUploadStates, setFilesWithUploadStates] = useState<UploadableFile[]>(files)
  const [totalProgress, setTotalProgress] = useState(0)
  const [uploaderState, setUploaderState] = useState<State>({ type: 'idle' })

  useEffect(() => {
    if (files.length === 0) return

    const updatedFilesWithUploadStates = filesWithUploadStates.filter((existingFile) =>
      files.some((file) => file.file === existingFile.file)
    )

    const filesToUpload = files.filter((file) => {
      const existingFile = updatedFilesWithUploadStates.find((existingFile) => existingFile.file === file.file)
      return !existingFile || existingFile.uploadState.state === 'pending'
    })

    setFilesWithUploadStates([...updatedFilesWithUploadStates, ...filesToUpload])

    uploadFiles(filesToUpload)
  }, [files])

  // Calculate total progress
  useEffect(() => {
    if (filesWithUploadStates.length === 0) {
      setTotalProgress(0)
      return
    }

    const totalFiles = filesWithUploadStates.length
    const totalProgressValue = filesWithUploadStates.reduce((acc, uploadableFile) => {
      const { uploadState } = uploadableFile
      let progress = 0

      switch (uploadState.state) {
        case 'pending':
        case 'loading':
          progress = uploadState.progress
          break
        case 'loaded':
        case 'error':
          progress = 100
          break
        default:
          break
      }

      return acc + progress
    }, 0)

    setTotalProgress(totalProgressValue / totalFiles)
  }, [filesWithUploadStates])

  const updateFileState = (file: File, state: FileUploadState) => {
    setFilesWithUploadStates((prevFiles) =>
      prevFiles.map((f) =>
        f.file === file
          ? {
              ...f,
              uploadState: state,
            }
          : f
      )
    )
  }

  const uploadFiles = async (files: UploadableFile[]) => {
    const pendingFiles = files.filter((file) => file.uploadState.state === 'pending')
    setUploaderState({ type: 'loading', files: pendingFiles.map((file) => file.file.name) })

    try {
      const uploadTasks = pendingFiles.map((file) => async () => {
        await uploadFile(file)
      })

      await concurrentPromises(uploadTasks, maxConcurrentFileUpload)
      setUploaderState({ type: 'idle' })
    } catch (error) {
      setUploaderState({ type: 'error', error: 'Failed to upload files: ' + error })
    }
  }

  const uploadFile = async (uploadableFile: UploadableFile) => {
    console.log('Uploading file:', uploadableFile.file.name)
    const currentFile = uploadableFile.file
    updateFileState(currentFile, { state: 'loading', progress: 0 })

    const chunkCount = Math.ceil(currentFile.size / chunkSize)
    const chunksProgress: { [chunkNumber: number]: number } = {}
    const correlationID = ulid()

    if (chunkCount === 1) {
      const fileHash = await computeChecksumMd5(currentFile as Blob)
      const query: UploadQuery = {
        orgID: user.org.id,
        h: fileHash,
      }
      const formData = new FormData()
      formData.append('files', currentFile, currentFile.name)
      try {
        await retryablePromise(() =>
          post<void, FormData>(
            {
              url: Config.BaseUploadUrl + uploadPath,
              data: formData,
              query,
              uploadProgressCallback: (percent: number) => {
                updateFileState(currentFile, { state: 'loading', progress: percent })
              },
            },
            user.token
          )
        )

        updateFileState(currentFile, { state: 'loaded' })
      } catch (e) {
        updateFileState(currentFile, { state: 'error', error: 'Failed to upload file: ' + e })
      }

      return
    }

    const chunkUploadTasks = Array.from({ length: chunkCount }, (_, chunkNumber) => async () => {
      const chunkStart = chunkNumber * chunkSize
      const chunkEnd = Math.min(chunkStart + chunkSize, currentFile.size)
      const chunkBlob = currentFile.slice(chunkStart, chunkEnd)

      const formData = new FormData()
      formData.append('files', chunkBlob, currentFile.name)

      const query: UploadQuery = {
        orgID: user.org.id,
        n: chunkNumber,
        t: chunkCount,
      }

      const headers = {
        'Correlation-ID': correlationID,
        'Actual-Content-Type': currentFile.type,
      }

      await retryablePromise(() =>
        post<void, FormData>(
          {
            url: Config.BaseUploadUrl + chunkedUploadPath,
            data: formData,
            headers,
            query,
            uploadProgressCallback: (_: number, bytesUploadedInChunk: number) => {
              chunksProgress[chunkNumber] = bytesUploadedInChunk
              const totalBytesUploaded = Object.values(chunksProgress).reduce((a, b) => a + b, 0)
              const progressPercent = (totalBytesUploaded / currentFile.size) * 100
              updateFileState(currentFile, { state: 'loading', progress: progressPercent })
            },
          },
          user.token
        )
      )
    })

    try {
      await concurrentPromises(chunkUploadTasks, maxConcurrentChunkUpload)
      await post(
        {
          url: Config.BaseUploadUrl + chunkedUploadConfirmPath,
          data: JSON.stringify([correlationID]),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        user.token
      )

      updateFileState(currentFile, { state: 'loaded' })
    } catch (error) {
      console.error(error)
      updateFileState(currentFile, { state: 'error', error: 'Failed to upload file: ' + error })
    }
  }

  return {
    filesWithUploadStates,
    totalProgress,
    uploaderState,
  }
}
