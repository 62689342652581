import React from 'react'
import {
  ActivityItemForSurveyAssetsCreatedT,
  ActivityItemForSurveyAssetsRemovedT,
  ActivityItemForSurveyAssetsUpdatedT,
  ActivityItemForSurveyBoundariesCreatedT,
  ActivityItemForSurveyBoundariesRemovedT,
  ActivityItemForSurveyBoundariesUpdatedT,
  ActivityItemForSurveyData,
  ActivityItemForSurveyIssuesCreatedT,
  ActivityItemForSurveyIssuesRemovedT,
  ActivityItemForSurveyIssuesUpdatedT,
  ActivityItemForSurveyAnnotationsCreatedT,
  ActivityItemForSurveyAnnotationsRemovedT,
  ActivityItemForSurveyAnnotationsUpdatedT,
  ActivityItemForSurveyMonitoringZonesCreatedT,
  ActivityItemForSurveyMonitoringZonesRemovedT,
  ActivityItemForSurveyMonitoringZonesUpdatedT,
  ActivityItemForSurveySiteCreatedT,
  SurveyActivityItem,
} from '~/models'
import { ActivityCard } from './activity-card'

interface ActivityCardSurveyProps extends SurveyActivityItem {
  goToSite: (siteID: string) => void
  goToAsset: (siteID: string, assetID: string) => void
  canListIssues: boolean
}

export const ActivityCardSurvey = (props: ActivityCardSurveyProps) => {
  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveySiteCreated) {
    const data = props.data.value as ActivityItemForSurveySiteCreatedT
    return (
      <ActivityCard time={data.time} icon='all_out'>
        <p>The survey was created</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyBoundariesCreated) {
    const data = props.data.value as ActivityItemForSurveyBoundariesCreatedT
    return (
      <ActivityCard items={data.items} icon='fence' link='boundary'>
        <p>{data.items.length === 1 ? 'A boundary was created' : `${data.items.length} boundaries were created`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyBoundariesUpdated) {
    const data = props.data.value as ActivityItemForSurveyBoundariesUpdatedT
    return (
      <ActivityCard items={data.items} icon='fence' link='boundary'>
        <p>{data.items.length === 1 ? 'A boundary was updated' : `${data.items.length} boundaries were updated`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyBoundariesRemoved) {
    const data = props.data.value as ActivityItemForSurveyBoundariesRemovedT
    return (
      <ActivityCard items={data.items} icon='fence'>
        <p>{data.items.length === 1 ? 'A boundary was removed' : `${data.items.length} boundaries were removed`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyMonitoringZonesCreated) {
    const data = props.data.value as ActivityItemForSurveyMonitoringZonesCreatedT
    return (
      <ActivityCard items={data.items} icon='map' link='monitoring-zone'>
        <p>
          {data.items.length === 1
            ? 'A monitoring zone was created'
            : `${data.items.length} monitoring zones were created`}
        </p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyMonitoringZonesUpdated) {
    const data = props.data.value as ActivityItemForSurveyMonitoringZonesUpdatedT
    return (
      <ActivityCard items={data.items} icon='map' link='monitoring-zone'>
        <p>
          {data.items.length === 1
            ? 'A monitoring zone was updated'
            : `${data.items.length} monitoring zones were updated`}
        </p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyMonitoringZonesRemoved) {
    const data = props.data.value as ActivityItemForSurveyMonitoringZonesRemovedT
    return (
      <ActivityCard items={data.items} icon='map'>
        <p>
          {data.items.length === 1
            ? 'A monitoring zone was removed'
            : `${data.items.length} monitoring zones were removed`}
        </p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyIssuesCreated) {
    if(!props.canListIssues) {
      return null
    }
    const data = props.data.value as ActivityItemForSurveyIssuesCreatedT
    return (
      <ActivityCard items={data.items} icon='warning' link='issue'>
        <p>{data.items.length === 1 ? 'An issue was created' : `${data.items.length} issues were created`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyIssuesUpdated) {
    if(!props.canListIssues) {
      return null
    }
    const data = props.data.value as ActivityItemForSurveyIssuesUpdatedT
    return (
      <ActivityCard items={data.items} icon='warning' link='issue'>
        <p>{data.items.length === 1 ? 'An issue was updated' : `${data.items.length} issues were updated`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyIssuesRemoved) {
    if(!props.canListIssues) {
      return null
    }
    const data = props.data.value as ActivityItemForSurveyIssuesRemovedT
    return (
      <ActivityCard items={data.items} icon='warning'>
        <p>{data.items.length === 1 ? 'An issue was removed' : `${data.items.length} issues were removed`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAssetsCreated) {
    const data = props.data.value as ActivityItemForSurveyAssetsCreatedT
    return (
      <ActivityCard items={data.items} icon='apartment' link='asset'>
        <p>{data.items.length === 1 ? 'An asset was created' : `${data.items.length} assets were created`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAssetsUpdated) {
    const data = props.data.value as ActivityItemForSurveyAssetsUpdatedT
    return (
      <ActivityCard items={data.items} icon='apartment' link='asset'>
        <p>{data.items.length === 1 ? 'An asset was updated' : `${data.items.length} assets were updated`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAssetsRemoved) {
    const data = props.data.value as ActivityItemForSurveyAssetsRemovedT
    return (
      <ActivityCard items={data.items} icon='apartment'>
        <p>{data.items.length === 1 ? 'An asset was removed' : `${data.items.length} assets were removed`}</p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAnnotationsCreated) {
    const data = props.data.value as ActivityItemForSurveyAnnotationsCreatedT
    return (
      <ActivityCard items={data.items} icon='straighten' link='annotation'>
        <p>
          {data.items.length === 1 ? 'An annotation was created' : `${data.items.length} annotations were created`}
        </p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAnnotationsUpdated) {
    const data = props.data.value as ActivityItemForSurveyAnnotationsUpdatedT
    return (
      <ActivityCard items={data.items} icon='straighten' link='annotation'>
        <p>
          {data.items.length === 1 ? 'An annotation was updated' : `${data.items.length} annotations were updated`}
        </p>
      </ActivityCard>
    )
  }

  if (props.data.type === ActivityItemForSurveyData.ActivityItemForSurveyAnnotationsRemoved) {
    const data = props.data.value as ActivityItemForSurveyAnnotationsRemovedT
    return (
      <ActivityCard items={data.items} icon='straighten'>
        <p>
          {data.items.length === 1 ? 'An annotation was removed' : `${data.items.length} annotations were removed`}
        </p>
      </ActivityCard>
    )
  }

  return null
}
