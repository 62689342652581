import React, { useState } from 'react'
import { Switch } from '~/components'
import { ProjectionType, useAppState } from '~/state'
import { ToolsRoofTypes } from './tools-roof-types'
import { LengthAndWidthMeasurements } from './use-lw-measurements'
import { ToolsInstantAssessmentsCoverage } from './tools-instant-assessments-coverage'

interface ToolsContainerProps {
  show: boolean
}

export const ToolsContainer = (props: ToolsContainerProps) => {
  const { map, timeline, components } = useAppState()
  const [drawLengthAndWidthMeasurements, setDrawLengthAndWidthMeasurements] = useState(false)
  const [showRoofTypes, setShowRoofTypes] = useState(false)
  const [showInstantAssessmentsCoverage, setShowInstantAssessmentsCoverage] = useState(true)

  if (!props.show || !timeline.activeSurvey) {
    if (drawLengthAndWidthMeasurements) {
      setDrawLengthAndWidthMeasurements(false)
    }
    if (showRoofTypes) {
      setShowRoofTypes(false)
    }
    if (showInstantAssessmentsCoverage) {
      setShowInstantAssessmentsCoverage(false)
    }
    return null
  }

  return (
    <div className='tools-container'>
      <div className='tools-container-header'>Tools</div>
      <div className='tools-container-content'>
        <Switch
          left
          name='draw-lw-measurements'
          label='Draw L&W measurements'
          checked={drawLengthAndWidthMeasurements}
          onChange={() => {
            setDrawLengthAndWidthMeasurements(!drawLengthAndWidthMeasurements)
          }}
          disabled={showRoofTypes || map.projectionType === ProjectionType.Projection3D}
        />
        <Switch
          left
          name='draw-roof-types'
          label='Roof Types'
          checked={showRoofTypes}
          onChange={() => {
            setShowRoofTypes(!showRoofTypes)
          }}
          disabled={drawLengthAndWidthMeasurements || map.projectionType === ProjectionType.Projection2D}
        />
        <Switch
          left
          name='components-by-condition'
          label='Color Components by Condition'
          checked={components.colorByCondition}
          onChange={() => {
            components.setColorByCondition(!components.colorByCondition)
          }}
        />
        <Switch
          left
          name='instant-assessments-coverage'
          label='Instant Assesments Coverage'
          checked={showInstantAssessmentsCoverage}
          onChange={() => {
            setShowInstantAssessmentsCoverage(!showInstantAssessmentsCoverage)
          }}
        />
        {drawLengthAndWidthMeasurements && <LengthAndWidthMeasurements />}
        {showRoofTypes && <ToolsRoofTypes />}
        {showInstantAssessmentsCoverage && <ToolsInstantAssessmentsCoverage />}
      </div>
    </div>
  )
}
