import React, { useMemo } from 'react'
import { useLocation, Transition, ItemTag, NoItemTag } from '~/components'
import { SiteQueryAsset, SiteQuerySite, SitesQuerySite } from '~/models'
import { ClientPermission, useUser } from '~/base'
import { useAppState } from '~/state'
import { ExpandedPanel } from '~/state/use-drawer-state'

interface SiteAssetsProps {
  showCreate: () => void
  showUpdateAsset: (assetId: SiteQueryAsset) => void
  assetRemoved: (asset: SiteQueryAsset) => void
  site?: SiteQuerySite
  basicSite?: SitesQuerySite
}

export const SiteAssets = (props: SiteAssetsProps) => {
  const location = useLocation()
  const user = useUser()
  const { timeline, site, drawer } = useAppState()
  const loaded = timeline?.activeSurvey !== undefined
  const assets = useMemo(() => {
    if (!loaded) {
      return []
    }

    if (site.site?.assets) {
      return [...site.site.assets].sort((a, b) => (a.number < b.number ? -1 : 1))
    }

    return []
  }, [loaded, timeline?.activeSurvey, props.basicSite, site.site])

  const isDemoSite = props.site?.isDemoSite || !loaded
  const canViewAssets = user.hasPermission(ClientPermission.AssetsList)
  const canAddAssets = !isDemoSite && user.hasPermission(ClientPermission.AssetsCreate)

  if(!canViewAssets) {
    return null
  }

  return (
    <div className='drawer-list-assets'>
      <h5>
        ASSETS{' '}
        {canAddAssets && (
          <span className='material-icons add dimmed' onClick={props.showCreate}>
            add
          </span>
        )}
      </h5>
      <Transition width={120} in={Array.isArray(assets)}>
        {assets.length > 0 &&
          assets.slice(0, 1).map((a) => (
            <ItemTag
              title={a.name}
              key={a.id}
              tagType='asset'
              onClick={() => {
                location.setLocation(`/${props.site.id}/${timeline.activeSurvey.id}/assets/${a.id}`)
              }}
            />
          ))}

        {drawer.expandedPanel !== ExpandedPanel.Asset && assets.length > 1 && (
          <div
            onClick={() => {
              drawer.setExpandedPanel(ExpandedPanel.Asset)
            }}
            className='drawer-list-content-more'
          >
            {`View ${assets.length} assets`} {/* <img src={CHEVRONS} title='View more' /> */}
          </div>
        )}
        {drawer.expandedPanel === ExpandedPanel.Asset && assets.length > 1 && (
          <div
            onClick={() => {
              drawer.closeExpandedPanel()
            }}
            className='drawer-list-content-more flipped'
          >
            Hide assets
            {/* <img src={CHEVRONS} title='Hide all' /> */}
          </div>
        )}

        {assets.length === 0 && (
          <NoItemTag
            canAdd={canAddAssets}
            canAddText='Add an asset'
            cantAddText='No assets have been added'
            onClick={() => props.showCreate()}
          />
        )}
      </Transition>
    </div>
  )
}
