import React, { useState } from 'react'
import { useAppState } from '~/state'

import {
  RemoveComponentMutation,
  RemoveComponentMutationVariables,
  SiteQueryComponent,
  SurveyBasedOperation,
} from '~/models'
import REMOVE_COMPONENT from './mutation-component-remove.gql'
import { OperationTypeSelect } from '../operation-type'
import { useMutation } from '../data'
import { getTracking } from '../tracking'

interface ComponentRemoveProps {
  component: SiteQueryComponent
  refetch: () => Promise<void>
  close: () => void
}

export const ComponentRemove = (props: ComponentRemoveProps) => {
  const { timeline, view } = useAppState()
  const [deleting, setDeleting] = useState(false)
  const [operationType, setOperationType] = useState(SurveyBasedOperation.GoingForward)
  const [removeComponent] = useMutation<RemoveComponentMutation, RemoveComponentMutationVariables>(REMOVE_COMPONENT)

  return (
    <div className='boundaries-draw internal'>
      <div
        className='item-tag-delete-confirm'
        style={{ backgroundColor: 'rgb(43, 39, 40)', justifyContent: 'unset', paddingTop: '2rem' }}
      >
        <h6>Delete component?</h6>
        <OperationTypeSelect
          name='operationType'
          selectedValue={operationType}
          onChange={(e) => {
            setOperationType((e.target as any).value)
          }}
          survey={timeline.activeSurvey}
        />
        <button
          className='item-tag-delete-confirm-delete btn'
          disabled={deleting}
          onClick={() => {
            setDeleting(true)
            removeComponent({
              variables: {
                input: {
                  ID: props.component.id,
                  surveyID: timeline.activeSurvey.id,
                  operationType,
                  assetID: props.component.assetID || '',
                  siteID: view.siteID,
                },
              },
            })
              .then(() => {
                getTracking().event({
                  category: 'Component',
                  action: `User Deleted Component`,
                  label: 'Component',
                })
                return props.refetch().then(() => {
                  props.close()
                })
              })
              .finally(() => {
                setDeleting(false)
              })
          }}
        >
          {deleting ? 'Deleting...' : 'Yes, delete'}
        </button>
        <button
          className='item-tag-delete-confirm-cancel btn'
          disabled={deleting}
          onClick={props.close}
        >
          Cancel
        </button>
        <p>Caution: This cannot be undone.</p>
      </div>
      <div></div>
    </div>
  )
}
