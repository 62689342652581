import React, { useState } from 'react'
import { SimpleBar } from '~/components'
import { SitesQuerySiteGroupWithExtra } from '~/models'
import { useAppState } from '~/state'

interface ColumnHeadingProps {
  title: string
  search: string
  searchChanged: (s: string) => void
  buttonType: string
  onButton: () => void
}

const ColumnHeading = (props: ColumnHeadingProps) => {
  return (
    <div className='site-assignment-heading'>
      <div className='site-assignment-heading-search'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>{props.title}</span>
          </div>
          <input
            onChange={(e) => {
              props.searchChanged(e.target.value || '')
            }}
            type='text'
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            value={props.search}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>
              <button
                className='btn btn-secondary btn-sm'
                onClick={(e) => {
                  e.preventDefault()
                  props.onButton()
                }}
              >
                {props.buttonType} All
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CardProps {
  group: SitesQuerySiteGroupWithExtra
  move: () => void
}

const Card = (props: CardProps) => {
  return (
    <div
      className='sites-card'
      style={{ width: 'initial', height: 'initial', marginRight: '15px', userSelect: 'none' }}
      onClick={props.move}
    >
      <div className='sites-card-content'>{props.group.group.name}</div>
    </div>
  )
}

enum ColumnType {
  Excluded,
  Included,
}

interface ColumnProps {
  groups: SitesQuerySiteGroupWithExtra[]
  columnType: ColumnType
  move: (index: number) => void
  showEmpty?: boolean
}

const Column = (props: ColumnProps) => {
  return (
    <div className='site-groups-form-lower-content'>
      <div className='site-groups-form-lower-content-container'>
        <SimpleBar autoHide={false} setMaxHeightToParent>
          {props.groups.map((s, idx) => {
            return (
              <Card
                group={s}
                move={() => {
                  props.move(idx)
                }}
                key={s.group.id}
              />
            )
          })}
          {props.showEmpty && props.groups.length === 0 && (
            <div className='sites-list-container-empty'>
              <div className='body2'>All groups are currently accessible</div>
            </div>
          )}
          <div style={{ height: '20px' }}></div>
        </SimpleBar>
      </div>
    </div>
  )
}

interface GroupAccessTableProps {
  includedGroups: SitesQuerySiteGroupWithExtra[]
  excludedGroups: SitesQuerySiteGroupWithExtra[]
  setIncludedGroups: (sites: SitesQuerySiteGroupWithExtra[]) => void
  setExcludedGroups: (sites: SitesQuerySiteGroupWithExtra[]) => void
  height?: number | string
}

export const GroupAccessTable = (props: GroupAccessTableProps) => {
  const { sites } = useAppState()
  const [excludedSearch, setExcludedSearch] = useState('')
  const [includedSearch, setIncludedSearch] = useState('')

  const excludedSearchValue = excludedSearch.toLocaleLowerCase().trim()
  const includedSearchValue = includedSearch.toLocaleLowerCase().trim()

  const excludedGroupsValue = props.excludedGroups.filter((s) => {
    if (excludedSearchValue === '') {
      return true
    }

    return s.group.name.toLocaleLowerCase().includes(excludedSearchValue)
  })

  const includedGroupsValue = props.includedGroups.filter((s) => {
    if (includedSearchValue === '') {
      return true
    }

    return s.group.name.toLocaleLowerCase().includes(includedSearchValue)
  })

  return (
    <>
      <p className='settings-tab-content-notice'>
        In this panel, you can restrict access to only specific site groups by adding the groups to the right column.
        <br />
        By default, if no groups are added to the right column, all groups will be visible.
      </p>
      <div className='site-groups-form-lower' style={{ minHeight: props.height ? props.height : '40vh' }}>
        <div className='site-groups-form-lower-left'>
          <ColumnHeading
            title='Groups'
            search={excludedSearch}
            searchChanged={setExcludedSearch}
            buttonType='Add'
            onButton={() => {
              const groupsToExclude = [...excludedGroupsValue]
              let newExcludedGroups = [...props.excludedGroups]
              const newIncludedGroups = [...props.includedGroups]
              for (const s of groupsToExclude) {
                newIncludedGroups.push(s)
                newExcludedGroups = newExcludedGroups.filter((x) => x.group.id !== s.group.id)
              }
              props.setExcludedGroups(sites.sortGroups(newExcludedGroups))
              props.setIncludedGroups(sites.sortGroups(newIncludedGroups))
            }}
          />
          <Column
            groups={excludedGroupsValue}
            columnType={ColumnType.Excluded}
            move={(index) => {
              const s = excludedGroupsValue[index]
              props.setExcludedGroups(sites.sortGroups(props.excludedGroups.filter((x) => x.group.id !== s.group.id)))
              props.setIncludedGroups(sites.sortGroups([...props.includedGroups, s]))
            }}
          />
        </div>
        <div className='site-groups-form-lower-right'>
          <ColumnHeading
            title='Limit To'
            search={includedSearch}
            searchChanged={setIncludedSearch}
            buttonType='Remove'
            onButton={() => {
              const groupsToInclude = [...includedGroupsValue]
              const newExcludedGroups = [...props.excludedGroups]
              let newIncludedGroups = [...props.includedGroups]
              for (const s of groupsToInclude) {
                newExcludedGroups.push(s)
                newIncludedGroups = newIncludedGroups.filter((x) => x.group.id !== s.group.id)
              }
              props.setExcludedGroups(sites.sortGroups(newExcludedGroups))
              props.setIncludedGroups(sites.sortGroups(newIncludedGroups))
            }}
          />
          <Column
            groups={includedGroupsValue}
            columnType={ColumnType.Included}
            move={(index) => {
              const s = includedGroupsValue[index]
              props.setIncludedGroups(sites.sortGroups(includedGroupsValue.filter((x) => x.group.id !== s.group.id)))
              props.setExcludedGroups(sites.sortGroups([...props.excludedGroups, s]))
            }}
            showEmpty
          />
        </div>
      </div>
    </>
  )
}
