export enum NetworkErrorType {
  Unauthorized,
  NotFound,
  InternalServer,
  BadRequest,
  Unknown,
}

export class NetworkError {
  constructor(
    public readonly code: number,
    public readonly type: NetworkErrorType,
    public readonly message: string,
    public readonly rawText: string
  ) {}
}

export function getQueryString(data: any) {
  if (!data) {
    return ''
  }
  const components = []
  for (const key in data) {
    components.push(encodeURIComponent(key) + '=' + encodeURIComponent((data as any)[key]))
  }

  return '?' + components.join('&')
}

export interface RequestInput<TData extends Document | BodyInit> {
  url: string
  query?: any
  data: TData
  headers?: { [key: string]: string }
  uploadProgressCallback?: (percent: number, bytesUploaded: number) => void
}

export function get<TReturn>(url: string, token: string): Promise<TReturn> {
  return Requestor.doRequest<TReturn, any>(
    'GET',
    {
      url,
      data: {},
    },
    token
  )
}

export function post<TReturn, TData extends Document | BodyInit>(
  input: RequestInput<TData>,
  token: string
): Promise<TReturn> {
  return Requestor.doRequest<TReturn, TData>('POST', input, token)
}

export class Requestor {
  static getNewXhr() {
    return new XMLHttpRequest()
  }

  static doRequest<TReturn, TData extends Document | BodyInit>(
    type: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    input: RequestInput<TData>,
    token: string
  ) {
    return new Promise<TReturn>((resolve, reject) => {
      const xhr = Requestor.getNewXhr()

      xhr.open(type, `${input.url}${getQueryString(input.query)}`)
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
          return
        }

        if (xhr.status >= 200 && xhr.status < 300) {
          if ((xhr.getResponseHeader('Content-Type') || '').includes('application/json')) {
            resolve(JSON.parse(xhr.responseText))
          } else {
            resolve(xhr.responseText as any)
          }
        } else {
          if (xhr.status === 400) {
            reject(new NetworkError(400, NetworkErrorType.BadRequest, xhr.responseText, xhr.responseText))
            return
          }

          if (xhr.status === 401) {
            // TODO: handle sign out.
            return
          }

          if (xhr.status === 403) {
            // TODO: handle permission denied.
            return
          }

          if (xhr.status === 404) {
            reject(new NetworkError(404, NetworkErrorType.NotFound, xhr.responseText, xhr.responseText))
            return
          }

          if (xhr.status >= 500) {
            reject(
              new NetworkError(xhr.status, NetworkErrorType.InternalServer, 'Internal server error', xhr.responseText)
            )
            return
          }

          // TODO: better handling of unknown errors.
          reject(new NetworkError(xhr.status, NetworkErrorType.Unknown, xhr.responseText, xhr.responseText))
        }
      }

      if (input.uploadProgressCallback && xhr.upload) {
        xhr.upload.onprogress = (event: ProgressEvent) => {
          if (event.lengthComputable) {
            const progress = Math.ceil((event.loaded / event.total) * 100)
            ;(input.uploadProgressCallback as any)(progress, event.loaded)
          }
        }
      }

      xhr.setRequestHeader('Authorization', 'Bearer ' + token)

      for (const header in input.headers || {}) {
        xhr.setRequestHeader(header, input.headers[header])
      }

      xhr.send(input.data as any)
    })
  }
}
