import React, { useEffect } from 'react'
import { PolygonColorAnalysis } from '~/components'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'
import { pickFromPosition } from '~/components/visualizations/picking'
import { AnnotationType, PointInput3Dt } from '~/models'
import { ProjectionType, useAppState } from '~/state'

interface AnnotationRulerPointProps {
  pointsChanged: (points: Cesium.Cartographic[], measurements: number[], name: string) => void
  points: PointInput3Dt[]
  disableEdits?: boolean
  onlyZ?: boolean
  name?: string
}

export const AnnotationRulerPoint = (props: AnnotationRulerPointProps) => {
  const { map } = useAppState()

  useEffect(() => {
    if (map.projectionType !== ProjectionType.Projection3D || props.disableEdits) {
      return
    }
    const points = new Cesium.PointPrimitiveCollection()
    map.viewer.scene.primitives.add(points)
    const point = points.add({
      position: Cesium.Cartesian3.ZERO.clone(),
      color: Cesium.Color.fromCssColorString(PolygonColorAnalysis),
      pixelSize: 12,
    })


    const mouseHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      const picked = pickFromPosition(map.viewer, click.position, [])
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      point.position = cartesian

      const carto = Cesium.Cartographic.fromCartesian(cartesian)
      props.pointsChanged([carto], [], props.name)
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    mouseHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      const picked = pickFromPosition(map.viewer, click.endPosition, [])
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      point.position = cartesian
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      mouseHandler.destroy()
      map.viewer.scene.primitives.remove(points)
    }
  }, [map.projectionType, props.disableEdits, props.name])

  useEffect(() => {
    let pr : PolygonRenderer3DView
    if(Array.isArray(props.points) && props.points.length > 0) {
      pr = new PolygonRenderer3DView({
        points: props.points,
        color: PolygonColorAnalysis,
        map,
        renderPoints: false,
        label: props.name || 'Enter a name',
        onClick: () => {
        },
        annotationType: AnnotationType.Point,
        id: Math.random() + '',

      })
    }

    return () => {
      if(pr) {
        pr.destroy()
        pr = undefined
      }
    }
  }, [JSON.stringify(props.points), props.disableEdits, props.name])

  return <></>
}
