import { Card, CardContent, CardHeader, CardTitle } from 'ui/card'
import { Row } from 'ui'
import React from 'react'
import { IconAiSparkle } from 'ui/icons'
import './cost-estimate-card.scss'

type Props = {
  cost: number
}

// TODO how we deal with currencies TBD
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

export const CostEstimateCard = ({ cost }: Props) => {
  return (
    <Card variant={'muted'} className={'cost-estimate-card text-center'}>
      <CardHeader className={'header'}>
        <CardTitle>Estimate Repair Costs</CardTitle>
      </CardHeader>
      <CardContent className={'content'}>
        <Row spacing={8} alignX={'center'}>
          <IconAiSparkle size={24} />
          <span className={'cost'}>{formatter.format(cost)}</span>
        </Row>
      </CardContent>
    </Card>
  )
}
