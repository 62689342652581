import { SupplierRole } from "./gql-types"

export function supplierRoleToString(role: SupplierRole) {
  switch (role) {
    case SupplierRole.Admin:
      return 'Administrator'
    case SupplierRole.Member:
      return 'Member'
  }
}

export function supplierRoleStringToEnum(role: string) {
  switch (role) {
    case 'Admin':
      return SupplierRole.Admin
    case 'Member':
      return SupplierRole.Member
  }
}

export function supplierRoleAccess(role: SupplierRole) {
  switch (role) {
    case SupplierRole.Admin:
      return 'Full access'
    case SupplierRole.Member:
      return 'Edit access'
  }
}
