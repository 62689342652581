import { useMatch } from 'react-router-dom'

export function useRoute<Params extends Record<string, unknown> = Record<string, unknown>>(location: string) {
  const match = useMatch(location)

  return {
    match: match !== null,
    params: (match?.params || {}) as Params,
  }
}
