import React from 'react'
import { Range } from 'react-range'

interface RangeSliderProps {
  min: number
  max: number
  step: number
  value: number
  onChange: (v: number) => void
}

export const RangeSlider = (props: RangeSliderProps) => {
  return (
    <Range
      min={props.min}
      max={props.max}
      step={props.step}
      values={[props.value]}
      onChange={(values) => {
        props.onChange(values[0])
      }}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginBottom: '16px',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              background: '#474647',
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '24px',
            width: '12px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA',
          }}
        >
          <div
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#548BF4' : '#CCC',
            }}
          />
        </div>
      )}
    />
  )
}
