import { useMutation } from '~/components'
import React, { useEffect, useState } from 'react'
import { useToasts } from '~/components'
import { MoveManuallyTaggedIssueMutation, MoveManuallyTaggedIssueMutationVariables, SiteQuerySurvey } from '~/models'
import { useAppState } from '~/state'
import {
  IssuesFilmStripByModel,
  PhotoItem,
  IssuesCreateForm,
  useDefectTypes,
  useIssuesDrawers,
  IssueItemWithPrevious,
  IssueCreationStatus,
  IssueItem,
} from '../components'
import MOVE_MANUALLY_TAGGED_ISSUE from '../mutation-issues-move.gql'
import { IssuesBulkMovingTable } from './issues-bulk-moving-table'
import { ImageTaggerImpl } from '~/map/image-tagger-impl'

export const IssuesBulkMoving = () => {
  const toasts = useToasts()
  const { view, site, timeline, asset, issues: issuesState, map } = useAppState()
  const transitioningTo3D = useIssuesDrawers(false)
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoItem>()
  const [photos, setPhotos] = useState<PhotoItem[]>()
  const defectTypeList = useDefectTypes()
  const target = view.assetID ? 'asset' : 'site'
  const [issues, setIssues] = useState<IssueItemWithPrevious[]>()
  const [currentIssue, setCurrentIssue] = useState<IssueItemWithPrevious>()
  const [comparisonSurvey, setComparisonSurvey] = useState<SiteQuerySurvey>()

  const [save] = useMutation<MoveManuallyTaggedIssueMutation, MoveManuallyTaggedIssueMutationVariables>(
    MOVE_MANUALLY_TAGGED_ISSUE
  )

  // Set starting comparison survey.
  useEffect(() => {
    if (!timeline.activeSurvey || timeline.previousSurveys.length === 0) {
      setComparisonSurvey(undefined)
      return
    }

    if (comparisonSurvey) {
      setComparisonSurvey(timeline.previousSurveys.find((x) => x.id === comparisonSurvey.id))
      return
    }

    const lastIndex = [...timeline.previousSurveys].reverse().findIndex((x) => x.issues.length > 0)
    const lastSurvey = timeline.previousSurveys[timeline.previousSurveys.length - lastIndex - 1]
    setComparisonSurvey(lastSurvey)
  }, [timeline.previousSurveys])

  // Set issues.
  useEffect(() => {
    if (!comparisonSurvey) {
      setIssues([])
      return
    }

    const issues = comparisonSurvey.issues
      .map((previousIssue) => {
        const currentIssue = timeline.activeSurvey.issues.find((ci) => ci.id === previousIssue.id)
        if (currentIssue) {
          return undefined
        }

        return {
          previousIssue,
          currentIssue: {
            ...previousIssue,
            photoIndex: -1,
            creationStatus: IssueCreationStatus.New,
            coordinates: [],
          },
        }
      })
      .filter((i) => !!i)

    setIssues(
      issues.sort((a, b) => {
        return a.previousIssue.number > b.previousIssue.number ? 1 : -1
      })
    )

    if (issues.length > 0) {
      setCurrentIssue(issues[0])
    }
  }, [comparisonSurvey])

  // Set coordinates when current image changes.
  useEffect(() => {
    if (!currentIssue) {
      return
    }

    setCurrentIssue({
      previousIssue: currentIssue.previousIssue,
      currentIssue: {
        ...currentIssue.currentIssue,
        coordinates: [],
      },
    })
  }, [selectedPhoto])

  if (!defectTypeList || transitioningTo3D) {
    return <div className='issues-bulk-moving'></div>
  }

  return (
    <div className='issues-bulk-moving'>
      <IssuesFilmStripByModel
        site={site.site}
        asset={asset.asset}
        target={target}
        issues={issues}
        onClose={() => issuesState.setMovingBulk(false)}
        selectedPhoto={selectedPhoto}
        selectedPhotoChanged={setSelectedPhoto}
        setPhotos={setPhotos}
        selectedIssue={currentIssue}
        activeSurvey={comparisonSurvey}
      />
      <IssuesCreateForm
        canSave={false}
        site={site.site}
        target={target}
        surveys={timeline.previousSurveys.filter((x) => x.issues.length > 0)}
        activeSurvey={comparisonSurvey}
        setActiveSurvey={setComparisonSurvey}
        onComplete={(item: IssueItem) => {
          return save({
            variables: {
              input: {
                siteID: view.siteID,
                sourceSurveyID: comparisonSurvey.id,
                targetSurveyID: view.surveyID,
                items: [
                  {
                    id: item.id,
                    name: item.name,
                    priority: item.priority,
                    riskType: item.riskType,
                    notes: item.notes,
                    repairEstimate: item.repairEstimate,
                    status: item.status,
                    location: {
                      altitude: item.location.altitude,
                      latitude: item.location.latitude,
                      longitude: item.location.longitude,
                    },
                    coordinates: item.coordinates.map((c) => {
                      return {
                        x: c.x,
                        y: c.y,
                      }
                    }),
                    defectTypeID: item.defectTypeID,
                    defectSubTypeID: item.defectSubTypeID || '',
                    componentID: item.component?.id || '',
                    componentTypeID: item.componentTypeID,
                    assetTypeID: item.assetTypeID,
                    assetID: item.assetID || '',
                    imageID: selectedPhoto.imageID,
                    materialTypeID: item.materialTypeID || '',
                  },
                ],
              },
            },
          })
            .then(() => {
              return site.refetch()
            })
            .then(() => {
              const newIssues = issues.filter((x) => x.currentIssue.id !== item.id)
              if (newIssues.length > 0) {
                setCurrentIssue(newIssues[0])
              } else {
                setCurrentIssue(undefined)
              }
              // toasts.addTopLeft('Issues successfully saved')
            })
            .catch((e) => {
              console.log(e)
              toasts.addTopLeft('An error occurred while saving the issues')
            })
        }}
        onClear={() => {
          const cur = issues.find((x) => x.currentIssue.id === currentIssue?.currentIssue?.id)
          if(cur) {
            setCurrentIssue({...cur})
          }
        }}
        issue={currentIssue?.currentIssue}
        defectTypes={defectTypeList}
        showOperationType={false}
      />
      <div className='issues-bulk-moving-vertical-split'>
        {/* <IssueImageTagger
          photo={selectedPhoto}
          previousIssue={currentIssue?.previousIssue}
          polygonDrawn={(p) => {
            setCurrentIssue({
              ...currentIssue,
              currentIssue: {
                ...currentIssue.currentIssue,
                coordinates: p,
              },
            })
          }}
          currentPolygon={currentIssue?.currentIssue?.coordinates || []}
          state={
            currentIssue?.currentIssue?.coordinates && currentIssue.currentIssue?.coordinates.length > 0
              ? IssueImageTaggerState.Edit
              : IssueImageTaggerState.New
          }
          issues={timeline.activeSurvey.issues?.filter((i) => i.imageID === selectedPhoto?.imageID)}
        /> */}
        <ImageTaggerImpl
          isVisible
          isBulk
          canFullscreen
          photo={selectedPhoto}
          addingIssue={!!!currentIssue?.currentIssue?.id}
          updatingIssue={!!currentIssue?.currentIssue?.id}
          fullscreen={map.bulkTaggingFullscreen.current}
          setFullScreen={() => map.setBulkTaggingFullscreen(!map.bulkTaggingFullscreen.current)}
          canAddIssue={false}
          setAddingIssue={() => {}}
          canHide={false}
          hide={() => {}}
          setAddingPoints={(p) => {
            setCurrentIssue({
              ...currentIssue,
              currentIssue: {
                ...currentIssue.currentIssue,
                coordinates: p,
              },
            })
          }}
          viewingIssue={false}
          existingIssues={timeline.activeSurvey.issues?.filter((i) => i.imageID === selectedPhoto?.imageID)}
          currentIssue={currentIssue?.currentIssue}
          previousIssue={currentIssue?.previousIssue}
        />
        <IssuesBulkMovingTable
          issues={issues}
          selectedIssue={currentIssue}
          setSelectedIssue={(i) => {
            const p = photos?.find((p) => p.imageID === i.currentIssue?.imageID)
            setSelectedPhoto(p)
            setCurrentIssue(i)
          }}
          defectTypes={defectTypeList}
          photos={photos || []}
        />
      </div>
    </div>
  )
}
