import React, { useEffect, useState } from 'react'
import { useToasts } from '~/components'
import { useAppState } from '~/state'
import {
  CreateAndUpdateManuallyTaggedIssueMutation,
  CreateAndUpdateManuallyTaggedIssueMutationVariables,
  SurveyBasedOperation,
} from '~/models'
import {
  IssuesFilmStrip,
  PhotoItem,
  IssueItemWithIndex,
  newEmptyIssue,
  IssueCreationStatus,
  IssuesCreateForm,
  useDefectTypes,
  useIssuesDrawers,
  sortIssues,
  useIssueMarkers,
} from '../components'
import { IssuesBulkTaggingTable } from './issues-bulk-tagging-table'
import { useMutation } from '~/components'
import CREATE_AND_UPDATE_MANUALLY_TAGGED_ISSUE from '../mutation-issues-create-and-update.gql'
import { ImageTaggerImpl } from '~/map/image-tagger-impl'

export const IssuesBulkTagging = () => {
  const { view, site, issues, timeline, asset, map } = useAppState()
  const toasts = useToasts()
  const transitioningTo3D = useIssuesDrawers(true)
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoItem>()
  const [photos, setPhotos] = useState<PhotoItem[]>()
  const [issuesWithIndex, setIssuesWithIndex] = useState<IssueItemWithIndex[]>([])
  const [issuesWithoutCurrent, setIssuesWithoutCurrent] = useState<IssueItemWithIndex[]>([])
  const [issuesWithoutCurrentForPhoto, setIssuesWithoutCurrentForPhoto] = useState<IssueItemWithIndex[]>([])
  const defectTypeList = useDefectTypes()
  const target = view.assetID ? 'asset' : 'site'
  const [currentIssue, setCurrentIssue] = useState<IssueItemWithIndex>()

  // Set initial issue.
  useEffect(() => {
    if (defectTypeList && selectedPhoto && !currentIssue) {
      setCurrentIssue(
        newEmptyIssue(selectedPhoto.imageID, view.assetID, currentIssue, defectTypeList[0].id, selectedPhoto.index)
      )
    }
  }, [defectTypeList, selectedPhoto, currentIssue])

  // Set base issues.
  useEffect(() => {
    if (!photos) {
      return
    }
    setIssuesWithIndex(
      sortIssues(
        issues.issues.map((i) => {
          const photoIndex = photos.findIndex((p) => i.imageID === p.imageID)
          return {
            ...i,
            photoIndex,
            creationStatus: IssueCreationStatus.Existing,
            operationType: SurveyBasedOperation.Singular,
          }
        })
      )
    )
  }, [site.site?.id, timeline.activeSurvey?.issues?.length, asset.asset?.id, target, photos])

  // Issue markers.
  useIssueMarkers(issuesWithoutCurrent)

  // Filtered issues
  useEffect(() => {
    if (!currentIssue || !issuesWithIndex || !selectedPhoto) {
      return
    }

    const withoutCurrent = issuesWithIndex.filter((p) => p.id !== currentIssue.id)
    setIssuesWithoutCurrent(withoutCurrent)
    setIssuesWithoutCurrentForPhoto(withoutCurrent.filter((i) => i.imageID === selectedPhoto.imageID))
  }, [currentIssue, issuesWithIndex, selectedPhoto])

  const [save] = useMutation<
    CreateAndUpdateManuallyTaggedIssueMutation,
    CreateAndUpdateManuallyTaggedIssueMutationVariables
  >(CREATE_AND_UPDATE_MANUALLY_TAGGED_ISSUE)

  if (!defectTypeList || transitioningTo3D) {
    return null
  }

  return (
    <div className='issues-bulk-tagging'>
      <IssuesFilmStrip
        site={site.site}
        asset={asset.asset}
        target={target}
        issues={issues.issues}
        onClose={() => issues.setAddingBulk(false)}
        selectedPhoto={selectedPhoto}
        selectedPhotoChanged={(p) => {
          setSelectedPhoto(p)
          if (defectTypeList?.length > 0) {
            setTimeout(() => {
              setCurrentIssue(
                newEmptyIssue(
                  p.imageID,
                  currentIssue?.assetID || view.assetID,
                  currentIssue,
                  defectTypeList[0].id,
                  p.index
                )
              )
            }, 0)
          }
        }}
        setPhotos={setPhotos}
      />
      <IssuesCreateForm
        canSave={
          issuesWithIndex.filter(
            (i) => i.creationStatus === IssueCreationStatus.New || i.creationStatus === IssueCreationStatus.Updated
          ).length > 0
        }
        site={site.site}
        target={target}
        onComplete={(item) => {
          let issuesToUse: IssueItemWithIndex[]
          if (item.creationStatus === IssueCreationStatus.New && !issuesWithIndex.find((i) => i.id === item.id)) {
            issuesToUse = sortIssues([...issuesWithIndex, item])
          } else {
            const idx = issuesWithIndex.findIndex((i) => i.id === item.id)
            if (item.creationStatus !== IssueCreationStatus.New) {
              item.creationStatus = IssueCreationStatus.Updated
            }
            issuesWithIndex[idx] = item
            issuesToUse = sortIssues([...issuesWithIndex])
          }

          return save({
            variables: {
              input: {
                siteID: view.siteID,
                surveyID: view.surveyID,
                operationType: SurveyBasedOperation.Singular,
                ItemsToCreate: issuesToUse
                  .filter((i) => i.creationStatus === IssueCreationStatus.New)
                  .map((i) => {
                    return {
                      name: i.name,
                      priority: i.priority,
                      riskType: i.riskType,
                      notes: i.notes,
                      repairEstimate: i.repairEstimate,
                      status: i.status,
                      location: {
                        altitude: i.location.altitude,
                        latitude: i.location.latitude,
                        longitude: i.location.longitude,
                      },
                      imageID: i.imageID,
                      coordinates: i.coordinates.map((c) => {
                        return {
                          x: c.x,
                          y: c.y,
                        }
                      }),
                      defectTypeID: i.defectTypeID,
                      defectSubTypeID: i.defectSubTypeID || '',
                      componentID: i.component?.id || '',
                      componentTypeID: i.componentTypeID,
                      assetTypeID: i.assetTypeID,
                      assetID: i.assetID || '',
                      materialTypeID: i.materialTypeID || '',
                    }
                  }),
                ItemsToUpdate: issuesToUse
                  .filter((i) => i.creationStatus === IssueCreationStatus.Updated)
                  .map((i) => {
                    return {
                      id: i.id,
                      name: i.name,
                      priority: i.priority,
                      riskType: i.riskType,
                      notes: i.notes,
                      repairEstimate: i.repairEstimate,
                      status: i.status,
                      location: {
                        altitude: i.location.altitude,
                        latitude: i.location.latitude,
                        longitude: i.location.longitude,
                      },
                      coordinates: i.coordinates.map((c) => {
                        return {
                          x: c.x,
                          y: c.y,
                        }
                      }),
                      defectTypeID: i.defectTypeID,
                      defectSubTypeID: i.defectSubTypeID || '',
                      componentID: i.component?.id || '',
                      componentTypeID: i.componentTypeID,
                      assetTypeID: i.assetTypeID,
                      assetID: i.assetID || '',
                      materialTypeID: i.materialTypeID || '',
                    }
                  }),
              },
            },
          })
            .then(() => {
              return site.refetch()
            })
            .then(() => {
              setCurrentIssue(
                newEmptyIssue(
                  selectedPhoto?.imageID,
                  currentIssue?.assetID || view.assetID,
                  currentIssue,
                  defectTypeList[0].id,
                  selectedPhoto.index
                )
              )
              // toasts.addTopLeft('Issues successfully saved')
            })
            .catch((e) => {
              console.log(e)
              toasts.addTopLeft('An error occurred while saving the issues')
            })
        }}
        onClear={() => {
          setCurrentIssue(
            newEmptyIssue(
              selectedPhoto?.imageID,
              currentIssue?.assetID || view.assetID,
              currentIssue,
              defectTypeList[0].id,
              selectedPhoto.index
            )
          )
        }}
        issue={currentIssue}
        defectTypes={defectTypeList}
      />
      <div className='issues-bulk-tagging-vertical-split'>
        <ImageTaggerImpl
          isVisible
          isBulk
          canFullscreen
          photo={selectedPhoto}
          addingIssue={!!!(currentIssue?.id)}
          updatingIssue={!!(currentIssue?.id)}
          fullscreen={map.bulkTaggingFullscreen.current}
          setFullScreen={() => map.setBulkTaggingFullscreen(!map.bulkTaggingFullscreen.current)}
          canAddIssue={false}
          setAddingIssue={() => {}}
          canHide={false}
          hide={() => {}}
          setAddingPoints={(p) => {
            setCurrentIssue({
              ...currentIssue,
              coordinates: p,
            })
          }}
          viewingIssue={false}
          existingIssues={issuesWithoutCurrentForPhoto}
          currentIssue={currentIssue}
          noZIndex
        />
        <IssuesBulkTaggingTable
          issues={issuesWithIndex}
          selectedIssue={currentIssue}
          setSelectedIssue={(i) => {
            const p = photos.find((p) => p.imageID === i.imageID)
            setSelectedPhoto(p)
            setCurrentIssue(i)
          }}
          defectTypes={defectTypeList}
          photos={photos || []}
        />
      </div>
    </div>
  )
}
