import { useMutation } from '~/components'
import {
  AnnotationsCreateMutation,
  AnnotationsCreateMutationVariables,
  AnnotationsRemoveMutation,
  AnnotationsRemoveMutationVariables,
  AnnotationsUpdateMutation,
  AnnotationsUpdateMutationVariables,
} from '~/models'
import ANNOTATIONS_CREATE_MUTATION from './mutation-annotations-create.gql'
import ANNOTATIONS_REMOVE_MUTATION from './mutation-annotations-remove.gql'
import ANNOTATIONS_UPDATE_MUTATION from './mutation-annotations-update.gql'

export function useAnnotationsCreate() {
  return useMutation<AnnotationsCreateMutation, AnnotationsCreateMutationVariables>(ANNOTATIONS_CREATE_MUTATION)
}

export function useAnnotationsUpdate() {
  return useMutation<AnnotationsUpdateMutation, AnnotationsUpdateMutationVariables>(ANNOTATIONS_UPDATE_MUTATION)
}

export function useAnnotationsRemove() {
  return useMutation<AnnotationsRemoveMutation, AnnotationsRemoveMutationVariables>(ANNOTATIONS_REMOVE_MUTATION)
}
