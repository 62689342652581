import { useEffect, useState } from 'react'
import { PolygonRenderer3DView, PolygonColorOrange, Map } from '../polygon-renderer-3d'
import { useAppState, useRefState } from '~/state'
import {
  AnnotationType,
  ComponentCondition,
  ComponentDtoT,
  MeasurementType,
  SiteQueryComponent,
} from '~/models'
import { ClientPermission, useUser } from '~/base'
import { useComponentState } from '~/state/use-component-state'
import { useEntityContext } from '~/state/use-entity-context'

interface PolygonData {
  view: PolygonRenderer3DView
  componentCondition: ComponentCondition
  color: string
  colorByCondition: boolean
}

type PolygonDataMap = {
  [id: string]: PolygonData
}

export function useComponentLayers() {
  const user = useUser()
  const { map, components: componentState, entityContext, site, timeline } = useAppState()
  const [components, setComponents] = useState<SiteQueryComponent[]>([])
  const [polygonRenderers, setPolygonRenderers] = useRefState<PolygonDataMap>({})
  const canViewComponents = user.hasPermission(ClientPermission.ComponentsRead)

  useEffect(() => {
    Object.keys(polygonRenderers.current).forEach((p) => polygonRenderers.current[p].view.destroy())
    setPolygonRenderers({})
  }, [timeline.activeSurvey?.id])

  // Set the components to the current entities components.
  useEffect(() => {
    if (!site.site || !canViewComponents || !timeline.activeSurvey) {
      return
    }

    if (componentState.selectedAssetID && componentState.selectedAssetID !== '') {
      const components = timeline.activeSurvey.components
        .filter((c) => c.assetID === componentState.selectedAssetID)
        .filter((c) => c.measurement === MeasurementType.Area) as ComponentDtoT[]
      setComponents(components)
      return
    }

    const components = [...timeline.activeSurvey.components]
    setComponents(components)
  }, [site.site, timeline.activeSurvey, componentState.selectedAssetID, canViewComponents])

  // Draw initial polygons.
  useEffect(() => {
    if (map.morphing || components.length === 0 || componentState.hidden || !canViewComponents) {
      // setPolygonRenderers({})
      return
    }

    for (const component of components) {
      let pr = polygonRenderers.current[component.id]
      const color = componentState.colorByCondition ? getColorByCondition(component.condition) : PolygonColorOrange

      if (!pr) {
        pr = createNewComponentView(component, color, componentState, map, entityContext)
      } else {
        if (
          pr.color !== color ||
          pr.colorByCondition !== componentState.colorByCondition ||
          pr.componentCondition !== component.condition
        ) {
          pr.view.destroy()
          pr = createNewComponentView(component, color, componentState, map, entityContext)
        }
      }

      pr.view.setVisible(componentState.draw)
      polygonRenderers.current[component.id] = pr
    }

    return () => {
      Object.keys(polygonRenderers.current).forEach((p) => polygonRenderers.current[p].view.setVisible(false))
    }
  }, [components, componentState.hidden, map.morphing, canViewComponents, componentState.colorByCondition])

  useEffect(() => {
    return () => {
      Object.keys(polygonRenderers.current).forEach((p) => polygonRenderers.current[p].view.destroy())
    }
  }, [])

  // Draw selected polygon
  useEffect(() => {
    if (map.morphing || !componentState.selectedComponent || componentState.hidden) {
      return
    }

    const c = components.find((x) => x.id === componentState.selectedComponent.id)
    if (!c) {
      return
    }

    const pr = new PolygonRenderer3DView({
      id: c.id + '-selected',
      label:
        c.materialTypeName +
        ' ' +
        (c.name || c.componentTypeName) +
        (componentState.colorByCondition ? ` (${c.condition.replace(/([A-Z])/g, ' $1').trim()})` : ''),
      points: c.points,
      map,
      renderPoints: c.measurement === MeasurementType.Point,
      color: '#f1a50e',
      annotationType: c.measurement as unknown as AnnotationType,
      fill: true,
      onClick: () => {
        entityContext.clear()
      },
    })

    return () => {
      pr.destroy()
    }
  }, [componentState.selectedComponent?.id, componentState.hidden, map.morphing, components])

  useEffect(() => {
    const selectedID = (componentState.selectedComponent?.id || '') + '-component'
    for (const id of Object.keys(polygonRenderers.current)) {
      const p = polygonRenderers.current[id]
      if (p.view.id === selectedID) {
        p.view.setVisible(false)
      } else {
        p.view.setFill(componentState.fill)
        p.view.setVisible(componentState.draw)
      }
    }
  }, [componentState.fill, polygonRenderers, componentState.draw, componentState.selectedComponent?.id])
}

function getColorByCondition(condition: ComponentCondition): string {
  switch (condition) {
    case ComponentCondition.Average:
      return '#efba28'
    case ComponentCondition.EndOfLife:
      return '#c12a2a'
    case ComponentCondition.Excellent:
      return '#36e023'
    case ComponentCondition.Good:
      return '#0d9de5'
    case ComponentCondition.Poor:
      return '#ee0064'
    case ComponentCondition.VeryPoor:
      return '#ea3a3a'
  }
}

function createNewComponentView(
  component: SiteQueryComponent,
  color: string,
  componentState: ReturnType<typeof useComponentState>,
  map: Map,
  entityContext: ReturnType<typeof useEntityContext>
): PolygonData {
  const haveChildren = component.children.length !== 0
  return {
    view: new PolygonRenderer3DView({
      id: component.id + '-component',
      label:
        component.materialTypeName +
        ' ' +
        (component.name || component.componentTypeName) +
        (componentState.colorByCondition ? ` (${component.condition.replace(/([A-Z])/g, ' $1').trim()})` : ''),
      points: component.points,
      map,
      renderPoints: component.measurement === MeasurementType.Point,
      color: color,
      scaleByDistance: new Cesium.NearFarScalar(10, haveChildren ? 0.8 : 0.8, 40, 0.8),
      translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
      annotationType: component.measurement as unknown as AnnotationType,
      fill: componentState.fill,
      onClick: () => {
        entityContext.set(component)
      },
      onRightClick: (pos) => {
        componentState.setComponentContext({
          component,
          windowPos: pos.windowPos,
        })
        return false
      },
    }),
    color: color,
    colorByCondition: componentState.colorByCondition,
    componentCondition: component.condition,
  }
}
