/**
 * Convert a local heading/pitch/roll rotation to an earth-axis-relative Quaternion
 * @param position a Cartesian3 point in space
 * @param heading degrees from North, rotation around Earth-normal vector
 * @param pitch degrees from horizontal, rotation around earth-tangent X axis
 * @param roll degrees from vertical, rotation around earth-tangent Y axis
 * @returns Quaternion with the specified orientation.
 */
export function relativeOrientation(
  position: Cesium.Cartesian3,
  heading: number,
  pitch: number,
  roll: number
): Cesium.Quaternion {
  // Build a Quaternion that represents the rotation between absolute and
  // local-surface frames of reference
  const transform4 = Cesium.Transforms.eastNorthUpToFixedFrame(position)
  const transform3 = Cesium.Matrix4.getMatrix3(transform4, new Cesium.Matrix3())
  const transformQ = Cesium.Quaternion.fromRotationMatrix(transform3)
  Cesium.Quaternion.normalize(transformQ, transformQ)
  // Convert the h/p/r values to a (local-reference) Quaternion
  const localHpr = Cesium.HeadingPitchRoll.fromDegrees(heading, pitch, roll)
  const localQ = Cesium.Quaternion.fromHeadingPitchRoll(localHpr)
  Cesium.Quaternion.normalize(localQ, localQ)
  // Result is local times transform
  const ret = new Cesium.Quaternion()
  Cesium.Quaternion.multiply(transformQ, localQ, ret)
  Cesium.Quaternion.normalize(ret, ret)

  return ret
}

/**
 * Convert a local heading/pitch/roll rotation to an earth-axis-relative direction vector.
 * @param origin a Cartesian3 point in space
 * @param heading degrees from North, rotation around Earth-normal vector
 * @param pitch degrees from horizontal, rotation around earth-tangent X axis
 * @param roll degrees from vertical, rotation around earth-tangent Y axis
 * @returns A vector with the specified direction.
 */
export function hprToDirectionVector(origin: Cesium.Cartesian3, heading: number, pitch: number) {
  // Deg to rad.
  heading = Cesium.Math.toRadians(heading)
  pitch = Cesium.Math.toRadians(pitch)
  // roll = Cesium.Math.toRadians(roll)

  // Declare shortcuts.
  const ch = Math.cos(heading)
  const sh = Math.sin(heading)
  const ct = Math.cos(pitch)
  const st = Math.sin(pitch)
  //const cr = Math.cos(roll)
  //const sr = Math.sin(roll)

  // Calc rot mat in terms of local ENU frame.
  //const obj = new Cesium.Cartesian3(ch * cr + sh * ct * sr, sh * cr * -1 + ch * ct * sr, st * sr)
  const dir = new Cesium.Cartesian3(sh * st, ch * st, ct * -1)
  //const up = new Cesium.Cartesian3(sh * ct * cr + ch * sr * -1, ch * ct * cr + sh * sr, st * cr)

  // Transform rot mat to world coordinates.
  const transformMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
    origin,
    Cesium.Ellipsoid.WGS84,
    new Cesium.Matrix4()
  )
  const rotationMatrix = Cesium.Matrix4.getMatrix3(transformMatrix, new Cesium.Matrix3())
  //Cesium.Matrix3.multiplyByVector(rotationMatrix, obj, obj)
  Cesium.Matrix3.multiplyByVector(rotationMatrix, dir, dir)
  //Cesium.Matrix3.multiplyByVector(rotationMatrix, up, up)

  return dir
}

/**
 * Gets the signed angle between two vectors.
 * @param a first vector.
 * @param b second vector.
 * @returns The signed angle between a and b.
 */
export function getSignedAngle(a: Cesium.Cartesian3, b: Cesium.Cartesian3) {
  const cross = Cesium.Cartesian3.cross(a, b, new Cesium.Cartesian3())
  const s = Cesium.Cartesian3.magnitude(cross)
  const c = Cesium.Cartesian3.dot(a, b)
  const angle = Math.atan2(s, c)
  const signedAngle = angle * Math.sign(Cesium.Cartesian3.dot(a, cross))
  return signedAngle
}

/**
 * Get the normal vector of a point on specified by window coordinates.
 * @param windowPosition Cartesian2 representing a pick position on the window.
 * @param scene The Cesium Scene to use for picking.
 * @returns The 3D normal vector at that position.
 */
export function get3DNormalVector(windowPosition: Cesium.Cartesian2, scene: Cesium.Scene) {
  // Choose the clicked screen coordinates and two other screen coordinates nearby
  // mostly these three screen coordinates will be on the same feature surface).
  const screenP0 = windowPosition
  const screenP1 = new Cesium.Cartesian2(windowPosition.x - 5, windowPosition.y - 5)
  const screenP2 = new Cesium.Cartesian2(windowPosition.x + 5, windowPosition.y + 5)

  // Get the cartesian coordinates for each of them.
  const P0 = scene.pickPosition(screenP0)
  const P1 = scene.pickPosition(screenP1)
  const P2 = scene.pickPosition(screenP2)

  // calculate the Normal Vector by Cesium.Cartesian3.cross()
  const vec1 = Cesium.Cartesian3.subtract(P1, P0, new Cesium.Cartesian3())
  const vec2 = Cesium.Cartesian3.subtract(P2, P0, new Cesium.Cartesian3())
  const normalVec = Cesium.Cartesian3.cross(vec1, vec2, new Cesium.Cartesian3())
  Cesium.Cartesian3.normalize(normalVec, normalVec)
  return normalVec
}
