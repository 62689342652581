import React, { useEffect } from 'react'
import { useAppState } from '~/state'
import { getMidpoint, LabelTemplate, Point, PointAndLineColor, pointFromPosition } from './utils'
import { formatDistance, getDistance } from './measurement-formats'

export const MeasureDistance2D = () => {
  const { map } = useAppState()

  useEffect(() => {
    const geodesic = new Cesium.EllipsoidGeodesic()

    const points = new Cesium.PointPrimitiveCollection()
    const polyLines = new Cesium.PolylineCollection()
    const labels = new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })

    map.viewer.scene.primitives.add(points)
    map.viewer.scene.primitives.add(polyLines)
    map.viewer.scene.primitives.add(labels)

    const p1 = points.add(pointFromPosition(new Cesium.Cartesian3(10000, 10000, 10000))) as Point
    const p2 = points.add(pointFromPosition(new Cesium.Cartesian3(10000, 10000, 10000))) as Point
    const mousePoint = points.add(pointFromPosition(new Cesium.Cartesian3(10000, 10000, 10000))) as Point
    const pointState = {
      haveP1: false,
      haveP2: false,
    }

    polyLines.add({
      show: false,
      positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(0, 0, 0)],
      width: 1,
      material: new Cesium.Material({
        fabric: {
          type: 'Color',
          uniforms: {
            color: PointAndLineColor,
          },
        },
      }),
    })

    labels.add({
      ...LabelTemplate,
      font: '13px monospace',
      position: new Cesium.Cartesian3(0, 0, 0),
    })

    // Mouse over the globe to see the cartographic position
    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING) {
        return
      }
      const cartesian = map.viewer.camera.pickEllipsoid(click.position, map.viewer.scene.globe.ellipsoid)
      if (!Cesium.defined(cartesian)) {
        return
      }

      if (!pointState.haveP1) {
        p1.position = cartesian
        pointState.haveP1 = true
        polyLines.get(0).positions = [cartesian.clone(), cartesian.clone()]
        polyLines.get(0).show = true
      } else if (!pointState.haveP2) {
        p2.position = cartesian
        pointState.haveP2 = true
      }

      // Don't show the mouse anymore.
      if (pointState.haveP1 && pointState.haveP2) {
        mousePoint.show = false
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    const mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    mouseMoveHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || (pointState.haveP1 && pointState.haveP2)) {
        return
      }

      const cartesian = map.viewer.camera.pickEllipsoid(click.endPosition, map.viewer.scene.globe.ellipsoid)
      if (!Cesium.defined(cartesian)) {
        return
      }
      mousePoint.position = cartesian

      if (pointState.haveP1 && !pointState.haveP2) {
        polyLines.get(0).positions = [p1.position.clone(), cartesian.clone()]

        const distance = getDistance(geodesic, p1.position, cartesian)
        const label0 = labels.get(0)
        label0.show = true
        label0.text = formatDistance(distance)
        label0.position = getMidpoint(geodesic, p1, mousePoint, 0)
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        p1.position = new Cesium.Cartesian3(0, 0, 0)
        p2.position = new Cesium.Cartesian3(0, 0, 0)
        mousePoint.position = new Cesium.Cartesian3(0, 0, 0)
        mousePoint.show = true
        polyLines.get(0).positions = [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(0, 0, 0)]
        pointState.haveP1 = false
        pointState.haveP2 = false
        const label0 = labels.get(0)
        label0.position = new Cesium.Cartesian3(0, 0, 0)
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      map.viewer.scene.primitives.remove(points)
      map.viewer.scene.primitives.remove(polyLines)
      map.viewer.scene.primitives.remove(labels)
      leftClickHandler.destroy()
      mouseMoveHandler.destroy()
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return <></>
}
