import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from 'ui/utils'
import './popover.scss'
import { RefAttributes } from 'react'
import { PopoverContentProps } from '@radix-ui/react-popover'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverAnchor = PopoverPrimitive.Anchor

type Props = Omit<PopoverContentProps & RefAttributes<HTMLDivElement>, 'ref'> & {
  size?: 'small' | 'medium'
}

const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, Props>(
  ({ className, align = 'center', sideOffset = 4, size = 'small', ...props }: Props, ref) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn('ui-popover-content', `ui-popover-content-${size}`, className)}
        {...props}
      />
    </PopoverPrimitive.Portal>
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor }
