import React from 'react'
import { CSSTransition } from 'react-transition-group'

export interface TransitionProps extends React.PropsWithChildren<{ in: boolean; width?: number }> {
  in: boolean
  width?: number
}

function getChild(c: any) {
  if (typeof c === 'number') {
    return <TransitionText>&nbsp;</TransitionText>
  }

  if (typeof c === 'string') {
    if (c.trim() === '') {
      return <TransitionText>{''.padStart(c.length, '\u00A0')}</TransitionText>
    }
  }

  return c
}

export const Transition = (props: TransitionProps) => {
  const children = props.children
  let toRender = <span>{props.in ? children : ''}</span>
  if (!props.in) {
    if (props.width) {
      toRender = (
        <span className='transition-pending' style={{ width: props.width + 'px' }}>
          <TransitionText>&nbsp;</TransitionText>
        </span>
      )
    } else {
      let inner = <TransitionText>&nbsp;</TransitionText>
      if (children) {
        inner = React.Children.map(props.children, getChild)
      }
      toRender = <span className='transition-pending'>{inner}</span>
    }
  }

  return (
    <CSSTransition in={props.in} timeout={150} classNames='text'>
      {toRender}
    </CSSTransition>
  )
}

const TransitionText = (props: any) => {
  return <span className='transition-text'>{props.children}</span>
}
