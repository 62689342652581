import { useEffect, useState } from 'react'
import { ulid } from 'ulid'
import { ArrayBuffer } from 'spark-md5'
import { useUser } from '~/base'
import { Config } from '~/config'
import { post } from '../helpers'

const CONCURRENT_UPLOAD_COUNT = 6
const CHUNK_SIZE = 48 * 1000 * 1000
const RETRY_COUNT = 3

// Taken from https://dev.to/qortex/compute-md5-checksum-for-a-file-in-typescript-59a4
function computeChecksumMd5(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunkSize = 12 * 1000 * 1000 // Read in chunks of ~12MB
    const spark = new ArrayBuffer()
    const fileReader = new FileReader()

    let cursor = 0 // current cursor in file

    fileReader.onerror = function (): void {
      reject('MD5 computation failed - error reading the file')
    }

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize)
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end))
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = function (e: any): void {
      spark.append(e.target.result) // Accumulate chunk to md5 computation
      cursor += chunkSize // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor)
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        resolve(spark.end())
      }
    }

    processChunk(0)
  })
}

function oneSuccess(promises: Promise<any>[]) {
  return Promise.all(
    promises.map((p) => {
      // If a request fails, count that as a resolution so it will keep
      // waiting for other possible successes. If a request succeeds,
      // treat it as a rejection so Promise.all immediately bails out.
      return p.then(
        (val) => Promise.reject(val),
        (err) => Promise.resolve(err)
      )
    })
  ).then(
    // If '.all' resolved, we've just got an array of errors.
    (errors) => Promise.reject(errors),
    // If '.all' rejected, we've got the result we wanted.
    (val) => Promise.resolve(val)
  )
}

export enum FileUploadState {
  Pending,
  Uploading,
  CompleteChunks,
  Complete,
  Error,
}

type addBytesCallback = (bytes: number) => void

interface UploadQuery {
  orgID: string
  n?: number
  t?: number
  h?: string
}

class FileUpload {
  private readonly _file: File
  private _state: FileUploadState
  private readonly _addBytesCallback: addBytesCallback
  private _bytesAdded: number
  private _errorCount: number
  private _chunkNumber: number
  private _chunkCount: number
  private _correlationId: string
  private _orgId: string
  private _token: string
  private _hash: string
  private readonly _size: number
  private _promise: Promise<void>

  constructor(
    file: File,
    chunkNumber: number,
    chunkCount: number,
    correlationId: string,
    orgId: string,
    token: string,
    addBytesCallback: addBytesCallback
  ) {
    this._file = file
    this._addBytesCallback = addBytesCallback
    this._state = FileUploadState.Pending
    this._errorCount = 0
    this._chunkNumber = chunkNumber
    this._chunkCount = chunkCount
    this._correlationId = correlationId
    this._orgId = orgId
    this._token = token

    if (chunkCount > 0) {
      this._size = CHUNK_SIZE
    } else {
      this._size = this._file.size
    }
  }

  public isChunked() {
    return this._chunkCount !== 0
  }

  public isFirstChunk() {
    return this._chunkCount !== 0 && this._chunkNumber === 0
  }

  public isUploading() {
    return this._state === FileUploadState.Uploading
  }

  public shouldUpload() {
    if (this._state === FileUploadState.Pending) {
      return true
    }

    if (this._state === FileUploadState.Error && this._errorCount < RETRY_COUNT) {
      return true
    }

    return false
  }

  public isInError() {
    return this._state === FileUploadState.Error && this._errorCount >= RETRY_COUNT
  }

  public isComplete() {
    return this._state === FileUploadState.Complete
  }

  public resetErrors() {
    this._state = FileUploadState.Pending
    this._errorCount = 0
  }

  public get hash() {
    return this._hash
  }

  public get correlationId() {
    return this._correlationId
  }

  public upload() {
    if (this._promise) {
      return this._promise
    }
    this._bytesAdded = 0
    this._state = FileUploadState.Uploading

    const blob = this._file as Blob
    const formData = new FormData()
    if (this._chunkCount === 0) {
      formData.append('files', this._file, this._file.name)
    } else {
      const chunkStart = this._chunkNumber * CHUNK_SIZE
      const chunkEnd = Math.min(chunkStart + CHUNK_SIZE, this._file.size)
      formData.append('files', this._file.slice(chunkStart, chunkEnd), this._file.name)
      // blob = this._file.slice(chunkStart, chunkEnd)
    }

    let url = Config.BaseUploadUrl + '/upload'
    let query: UploadQuery = {
      orgID: this._orgId,
    }
    let headers = {}

    // Computing hashes of large files is slow...
    let prePromise = Promise.resolve('')
    if (this._chunkCount > 0) {
      url = Config.BaseUploadUrl + '/upload-chunked'
      query = {
        orgID: this._orgId,
        n: this._chunkNumber,
        t: this._chunkCount,
      }

      const contentType =
        this._file.type === ''
          ? this._file.name.toLocaleLowerCase().endsWith('.las')
            ? 'application/vnd.las'
            : this._file.name.toLocaleLowerCase().endsWith('.laz')
            ? 'application/vnd.laz'
            : ''
          : this._file.type

      headers = {
        'Correlation-ID': this._correlationId,
        'Actual-Content-Type': contentType,
      }
    } else {
      prePromise = computeChecksumMd5(blob)
    }

    this._promise = prePromise.then((hash) => {
      this._hash = hash

      if (hash !== '') {
        query['h'] = hash
      }

      return new Promise<void>((resolve) => {
        post(
          {
            url,
            query,
            data: formData,
            headers,
            uploadProgressCallback: (percent, bytes) => {
              if (bytes > this._size) {
                this._addBytesCallback(this._size - this._bytesAdded)
                this._bytesAdded = this._size
              } else {
                const bytesToAdd = bytes - this._bytesAdded
                this._addBytesCallback(bytesToAdd)
                this._bytesAdded = bytes
              }
            },
          },
          this._token
        )
          .then(() => {
            this._state = FileUploadState.Complete
            this._promise = undefined
            resolve()
          })
          .catch(() => {
            this._state = FileUploadState.Error
            this._addBytesCallback(-this._bytesAdded)
            this._errorCount += 1
            this._promise = undefined
            resolve()
          })
      })
    })

    return this._promise
  }
}

export const useFileUpload = (inputFiles: File[]) => {
  const user = useUser()
  let [currentBytes, setCurrentBytes] = useState(0)

  const totalBytes = inputFiles.map((x) => x.size).reduce((a, b) => a + b, 0)
  const addBytes = (bytes: number) => {
    currentBytes += bytes
    setCurrentBytes(currentBytes)
  }
  const [state, setState] = useState(FileUploadState.Pending)

  const [fileUploads] = useState<FileUpload[]>(
    inputFiles
      .map((f) => {
        if (f.size <= CHUNK_SIZE) {
          return [new FileUpload(f, 0, 0, ulid(), user.org.id, user.token, addBytes)]
        } else {
          const toReturn = []
          let size = 0
          let chunkNumber = 0
          const chunkCount = Math.ceil(f.size / CHUNK_SIZE)
          const correlationId = ulid()

          while (size < f.size) {
            toReturn.push(new FileUpload(f, chunkNumber, chunkCount, correlationId, user.org.id, user.token, addBytes))
            chunkNumber++
            size += CHUNK_SIZE
          }
          return toReturn
        }
      })
      .reduce((a, b) => [...a, ...b], [])
  )

  const upload = () => {
    if (state === FileUploadState.CompleteChunks || state === FileUploadState.Complete) {
      return
    }

    if (state === FileUploadState.Pending) {
      setState(FileUploadState.Uploading)
    }

    if (state === FileUploadState.Error) {
      fileUploads.filter((f) => f.isInError()).forEach((f) => f.resetErrors())
      setState(FileUploadState.Uploading)
    }

    // Get the number currently uploading.
    const currentlyUploadingCount = fileUploads.filter((f) => f.isUploading()).length
    const numberToAdd = Math.max(CONCURRENT_UPLOAD_COUNT - currentlyUploadingCount, 0)

    // Take the first CONCURRENT_UPLOAD_COUNT to upload.
    const toUpload = fileUploads.filter((f) => f.shouldUpload()).slice(0, numberToAdd)
    const currentlyUploading = fileUploads.filter((f) => f.isUploading())
    const promises = [...currentlyUploading, ...toUpload].map((u) => u.upload())

    const numLeft = fileUploads.filter((f) => f.isUploading() || f.shouldUpload()).length
    let promiseFn = oneSuccess
    if (numLeft <= CONCURRENT_UPLOAD_COUNT) {
      promiseFn = Promise.all.bind(Promise)
    }

    promiseFn(promises).then(() => {
      const isMoreToUpload = fileUploads.filter((f) => f.shouldUpload()).length > 0
      if (isMoreToUpload) {
        upload()
        return
      }

      const isAnyWithError = fileUploads.filter((f) => f.isInError()).length > 0
      if (isAnyWithError) {
        setState(FileUploadState.Error)
        return
      }

      const allComplete = fileUploads.filter((f) => !f.isComplete()).length === 0
      if (allComplete) {
        setState(FileUploadState.CompleteChunks)
        return
      }
    })
  }

  useEffect(() => {
    if (state !== FileUploadState.CompleteChunks) {
      return
    }

    const doSendCompletedChunks = () => {
      const chunkedUploads = fileUploads.filter((f) => f.isFirstChunk()).map((f) => f.correlationId)
      if (chunkedUploads.length === 0) {
        setState(FileUploadState.Complete)
        return
      }

      post(
        {
          url: Config.BaseUploadUrl + '/complete-chunks',
          data: JSON.stringify(chunkedUploads),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        user.token
      )
        .then(() => {
          setState(FileUploadState.Complete)
        })
        .catch(() => {
          doSendCompletedChunks()
        })
    }

    doSendCompletedChunks()
  }, [state])

  return {
    upload,
    state,
    numComplete: fileUploads.filter((f) => f.isComplete()).length,
    currentBytes,
    totalBytes,
    percent: Math.floor((currentBytes / totalBytes) * 100),
  }
}
