import React, { useEffect } from 'react'
import CHEVRONS from '../../images/chevrons-right.svg'
import { classes, formatBytes } from '../helpers'
import { FileUploadState, useFileUpload } from './use-file-upload'

interface UploadPanelUploaderProps {
  files: File[]
  toggleOpen: () => void
  onComplete: () => void
}

export const UploadPanelUploader = (props: UploadPanelUploaderProps) => {
  const fileUpload = useFileUpload(props.files)

  useEffect(() => {
    fileUpload.upload()
  }, [props.files])

  return (
    <div className='upload-panel-uploader'>
      <div className='upload-panel-uploader-icon' onClick={props.toggleOpen}>
        <img src={CHEVRONS} />
      </div>
      <div className='upload-panel-uploader-content'>
        <div className='text-progress mb-3'>
          {fileUpload.state === FileUploadState.Complete && 'Upload complete'}
          {fileUpload.state !== FileUploadState.Complete &&
            `Uploading ${Math.min(fileUpload.numComplete + 1, props.files.length)} of ${props.files.length}`}
        </div>
        {fileUpload.state !== FileUploadState.Complete && (
          <div className='progress'>
            <div className='progress-text'>
              {formatBytes(fileUpload.currentBytes)} of {formatBytes(fileUpload.totalBytes)}
            </div>
            <div
              className={classes({
                'progress-bar': true,
                'bg-danger': fileUpload.state === FileUploadState.Error,
              })}
              role='progressbar'
              style={{ width: fileUpload.percent + '%' }}
            ></div>
          </div>
        )}
        {fileUpload.state === FileUploadState.Complete && (
          <>
            <span style={{fontSize: '12px', opacity: '0.7'}}>Asseti will automatically process uploaded data</span>
            <div className='complete'>
              <button className='btn' onClick={props.onComplete}>
                Ok
              </button>
            </div>
          </>
        )}
        {fileUpload.state === FileUploadState.Error && (
          <div className='error'>
            <button className='btn' onClick={() => fileUpload.upload()}>
              Retry
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
