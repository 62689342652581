import React from 'react'
import { getTracking } from '~/components'

interface AuthErrorProps {
  error?: any
  className: string
}

export const AuthError = (props: AuthErrorProps) => {
  getTracking().event({
    category: 'Error',
    action: `User authentication error: ${props.error}`,
    label: 'Auth',
  })

  return (
    <div className={`auth-error ${props.className}`} role='alert'>
      {props.error}
    </div>
  )
}

export const AuthInfo = (props: AuthErrorProps) => {
  getTracking().event({
    category: 'Info',
    action: `User authentication requested token resend: ${props.error}`,
    label: 'Auth',
  })

  return (
    <div className={`auth-info ${props.className}`} role='alert'>
      {props.error}
    </div>
  )
}
