import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import React, { useEffect, useState } from 'react'
import { SignInSwitchOrgMutation, SignInSwitchOrgMutationVariables, SignInSwitchSupplierMutation, SignInSwitchSupplierMutationVariables } from '~/models/gql-types'
import { getTracking, useMutation } from '~/components'
import { AuthError } from './auth-error'
import { useUser } from '~/base'
import SIGN_IN_MUTATION from './mutation-sign-in-switch-org.gql'
import SIGN_IN_SUPPLIER_MUTATION from './mutation-sign-in-switch-supplier.gql'

interface AuthSwitchOrgProps {
  token: string
  orgId: string
  supplierId: string
}

export const AuthSwitchOrg = (props: AuthSwitchOrgProps) => {
  const user = useUser()
  const [execute] = useMutation<SignInSwitchOrgMutation, SignInSwitchOrgMutationVariables>(SIGN_IN_MUTATION)
  const [executeSupplier] = useMutation<SignInSwitchSupplierMutation, SignInSwitchSupplierMutationVariables>(SIGN_IN_SUPPLIER_MUTATION)
  const [error, setError] = useState('')
  const tracking = getTracking()

  useEffect(() => {
    user.setToken(props.token)

    let promise
    if(props.orgId) {
      promise = execute({
        variables: {
          input: {
            orgID: props.orgId,
            token: props.token,
          },
        },
      }).then((res) => {
        user.setSignIn(res.data.signInSwitchOrg)
      })
    } else {
      promise = executeSupplier({
        variables: {
          input: {
            supplierID: props.supplierId,
            token: props.token,
          },
        },
      }).then((res) => {
        user.setSignIn(res.data.signInSwitchSupplier)
      })
    }
    
      promise.then(() => {
        tracking.event({
          category: 'Form',
          action: `User signed in from base app`,
          label: 'Auth',
        })
        window.location.href = '/'
      })
      .catch(() => {
        // TODO: Implement proper error codes and messages when standardized.
        setError('There has been an error, please try again.')
      })
  }, [])

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.NONE}>
      <div style={{ minWidth: 300 }} className='subheader-dark mt-5 mb-5 p-5'>
        Signing in...
      </div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
    </AuthLayout>
  )
}
