import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useUser } from '~/base'
import { SatellitePurchaseState, SatelliteTilingState, SiteQuerySatelliteTimelineItem, SystemRole } from '~/models'
import { useAppState } from '~/state'
import { classes } from '../helpers'

const PADDING_OFFSET = 20

interface SatelliteItemTooltipProps {
  image: SiteQuerySatelliteTimelineItem
}

enum ActiveImageryType {
  RGB = 'rgb',
  NED = 'ned',
}

const SatelliteItemTooltip = (props: SatelliteItemTooltipProps) => {
  const start = dayjs(props.image.date / 1000 / 1000)
  const title = start.format('DD MMMM YYYY')

  const formatLabel = () => {
    const parts = [props.image.resolutionInCM + 'cm']
    if (props.image.hasRGB) {
      parts.push('RGB')
    }

    if (props.image.hasNED) {
      parts.push('NED')
    }

    return parts.join(' - ')
  }

  return (
    <>
      <li>{title}</li>
      <li>
        {props.image.purchaseState === SatellitePurchaseState.Available ? 'Available for purchase' : formatLabel()}
      </li>
      {props.image.purchaseState === SatellitePurchaseState.Available && <li>Please contact asseti support</li>}
    </>
  )
}

interface TimelineSatelliteProps {
  haveTimeline: boolean
}

export const TimelineSatellite = (props: TimelineSatelliteProps) => {
  const { timeline, drawer, imageTagger, map, site } = useAppState()
  const contentRef = useRef<HTMLDivElement>(null)
  const datesRef = useRef<HTMLDivElement>(null)
  const [years, setYears] = useState<dayjs.Dayjs[]>([])
  const [activeType, setActiveType] = useState<ActiveImageryType>(ActiveImageryType.RGB)
  const [leftByID, setLeftByID] = useState<{ [key: string]: number }>()
  const [tooltipItems, setTooltipItems] = useState<SiteQuerySatelliteTimelineItem[]>([])
  const [animating, setAnimating] = useState<boolean>(false)
  const user = useUser()

  useEffect(() => {
    const allDates = timeline.satelliteTimeline.map((i) => {
      return i.date / 1000 / 1000
    })
    allDates.sort((a, b) => {
      return a < b ? -1 : 1
    })

    const startDate = dayjs(allDates[0]).startOf('year')
    const endDate = dayjs().endOf('year')

    const years = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      years.push(currentDate)
      currentDate = currentDate.add(1, 'year')
    }

    setYears(years)
    const lastPurchased = [...timeline.satelliteTimeline]
      .reverse()
      .find((x) => x.purchaseState === SatellitePurchaseState.Purchased)
    if (
      lastPurchased &&
      timeline.satelliteTimeline.findIndex((x) => x.satelliteID === timeline.activeSatellite?.satelliteID) === -1
    ) {
      timeline.setActiveSatellite(lastPurchased)
    }
  }, [timeline.satelliteTimeline])

  useEffect(() => {
    if (!contentRef.current || !datesRef.current || timeline.satelliteTimeline.length === 0) {
      return
    }

    const first = timeline.satelliteTimeline[0]
    const last = timeline.satelliteTimeline[timeline.satelliteTimeline.length - 1]
    if (first === last) {
      // TODO
    }

    const startDate = dayjs(first.date / 1000 / 1000).startOf('month')
    const endDate = dayjs(last.date / 1000 / 1000).endOf('month')

    const totalWidth = contentRef.current.clientWidth - PADDING_OFFSET * 2
    const totalDiffInMilliseconds = endDate.diff(startDate)

    const itemLefts: { [key: string]: number } = {}
    for (const item of timeline.satelliteTimeline) {
      const diff = endDate.diff(dayjs(item.date / 1000 / 1000))
      const left = totalWidth - (diff / totalDiffInMilliseconds) * totalWidth
      itemLefts[item.satelliteID] = left
    }

    setLeftByID(itemLefts)
  }, [contentRef.current, datesRef.current, timeline.satelliteTimeline])

  useEffect(() => {
    if (!timeline.activeSatellite) {
      return
    }

    const layer = map.addSatelliteLayer({
      id: timeline.activeSatellite.satelliteID,
      type: activeType,
      siteID: site.site.id,
      initialOpacity: 1,
    })

    return () => {
      map.removeSatelliteLayer(layer)
    }
  }, [timeline.activeSatellite, activeType])

  useEffect(() => {
    if (!animating || years.length === 0) {
      return
    }

    const layers = timeline.satelliteTimeline
      .filter((x) => {
        if (activeType === ActiveImageryType.RGB) {
          return x.hasRGB
        }
        return x.hasNED
      })
      .map((s) =>
        map.addSatelliteLayer({
          id: s.satelliteID,
          type: activeType,
          initialOpacity: 0,
          orgID: site.site.orgID,
          siteID: site.site.id,
        })
      )

    const timeBetweenFrames = 33.33
    const fadeDurationInMS = 1500
    const fadeFactor = fadeDurationInMS / 1000 / timeBetweenFrames
    const timeToHoldInMS = 1500

    const animateLayerIn = (index: number) => {
      layers[index].layer.alpha += fadeFactor
      const startIndex = index === 0 ? 0 : index - 1
      const startLeft = leftByID[layers[startIndex].id]
      const endLeft = leftByID[layers[index].id]
      document.getElementById('animation-cursor').style.left =
        startLeft + (endLeft - startLeft) * Math.min(layers[index].layer.alpha, 1) + 2.5 + 'px'
      setTimeout(() => {
        if (layers[index].layer.alpha >= 1) {
          document.getElementById('animation-cursor').style.left = endLeft + 2.5 + 'px'
          setTimeout(() => {
            setTimeout(() => {
              animateLayerOut(index)
            }, fadeDurationInMS / 2)
            showLayer(index + 1)
          }, timeToHoldInMS)
        } else {
          animateLayerIn(index)
        }
      }, timeBetweenFrames)
    }

    const animateLayerOut = (index: number) => {
      layers[index].layer.alpha -= fadeFactor
      setTimeout(() => {
        if (layers[index].layer.alpha <= 0) {
          layers[index].layer.alpha = 0
        } else {
          animateLayerOut(index)
        }
      }, timeBetweenFrames)
    }

    const showLayer = (index: number) => {
      if (index === layers.length) {
        index = 0
      }
      animateLayerIn(index)
    }

    Promise.all(layers.map((l) => l.provider)).then(() => {
      layers[0].layer.alpha = 0.5
      showLayer(0)
    })

    return () => {
      layers.forEach((l) => map.removeSatelliteLayer(l))
    }
  }, [animating, years])

  const handleMapControlsPosition = () => {
    const screenWidth = window.innerWidth
    const screenRemaining = screenWidth - 724 - 250
    const controlsPosition = 724 + (screenRemaining - 300) / 2

    if (drawer.rightExpanded) {
      return { right: controlsPosition, left: 'auto' }
    }

    if (drawer.leftExpanded) {
      return { left: controlsPosition }
    }

    return null
  }

  function showTooltip(e: React.MouseEvent) {
    const positionElem = document.getElementById('timeline-satellite-content-hover-date')
    const tooltipElem = document.getElementById('timeline-satellite-content-hover-date-tooltip')
    const tooltipDateElem = document.getElementById('timeline-satellite-content-hover-date-tooltip-date')
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left - PADDING_OFFSET
    const date = getDateFromPosition(contentRef, timeline.satelliteTimeline, x)

    if (date) {
      positionElem.style.left = x + 'px'
      positionElem.style.display = 'inline-block'
      if (tooltipDateElem) {
        tooltipDateElem.innerText = date.format('DD MMMM YYYY')
      }
      const left = e.pageX - tooltipElem.offsetWidth / 2
      tooltipElem.style.display = 'inline-block'

      if (left < 260) {
        tooltipElem.style.left = '260px'
      } else if (left > document.body.clientWidth - 350) {
        tooltipElem.style.left = document.body.clientWidth - 350 + 'px'
      } else {
        tooltipElem.style.left = left + 'px'
      }
    } else {
      positionElem.style.display = 'none'
      tooltipElem.style.display = 'none '
    }
  }

  function onClick(e: React.MouseEvent) {
    const positionElem = document.getElementById('timeline-satellite-content-hover-date')
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left - PADDING_OFFSET
    positionElem.style.left = x + 'px'
    positionElem.style.display = 'inline-block'

    const elems = document.elementsFromPoint(e.clientX, e.clientY)
    for (const elem of elems) {
      if (elem.classList.contains('timeline-satellite-content-item')) {
        const activeItem = timeline.satelliteTimeline.find((x) => x.satelliteID === elem.id)
        if (activeItem.tilingState === SatelliteTilingState.Processed) {
          if (activeType === ActiveImageryType.RGB) {
            if (!activeItem.hasRGB) {
              setActiveType(ActiveImageryType.NED)
            }
          } else if (activeType === ActiveImageryType.NED) {
            if (!activeItem.hasNED) {
              setActiveType(ActiveImageryType.RGB)
            }
          }
          timeline.setActiveSatellite(activeItem)
          return
        }
      }
    }

    // Find closest matching date.
    const date = getDateFromPosition(contentRef, timeline.satelliteTimeline, x)
    let minDiff = Number.POSITIVE_INFINITY
    let closest: SiteQuerySatelliteTimelineItem
    for (const s of timeline.satelliteTimeline) {
      if (s.tilingState !== SatelliteTilingState.Processed) {
        continue
      }
      const d = date.diff(dayjs(s.date / 1000 / 1000), 's')
      if (Math.abs(d) < minDiff) {
        minDiff = Math.abs(d)
        closest = s
      }
    }

    if (closest) {
      if (activeType === ActiveImageryType.RGB) {
        if (!closest.hasRGB) {
          setActiveType(ActiveImageryType.NED)
        }
      } else if (activeType === ActiveImageryType.NED) {
        if (!closest.hasNED) {
          setActiveType(ActiveImageryType.RGB)
        }
      }
      timeline.setActiveSatellite(closest)
    }
  }

  const showActiveControls =
    timeline.activeSatellite && timeline.activeSatellite.hasRGB && timeline.activeSatellite.hasNED
  const showAnimateControls =
    user.systemRole === SystemRole.Staff &&
    timeline.satelliteTimeline.filter((x) => {
      if (activeType === ActiveImageryType.RGB) {
        return x.hasRGB
      }
      return x.hasNED
    }).length > 1
  const showControls = map.showControls && (showActiveControls || showAnimateControls)

  return (
    <>
      <div
        className={classes({
          'timeline-satellite': true,
          'left-expanded': drawer.leftExpanded,
          'film-strip-expanded': imageTagger.filmStripOpen,
          'right-expanded': drawer.rightExpanded,
          lower: !props.haveTimeline,
        })}
      >
        <div
          className={classes({
            'timeline-satellite-container': true,
            hovered: false,
          })}
        >
          <div
            className={classes({
              'timeline-satellite-container-content': true,
              hovered: false,
            })}
            onMouseMove={(e) => {
              showTooltip(e)
            }}
            onClick={(e) => {
              onClick(e)
            }}
          >
            <div className='timeline-satellite-dates'>
              <div
                className={classes({
                  'timeline-satellite-dates-entries': true,
                })}
                ref={datesRef}
              >
                {years.map((year) => {
                  return (
                    <div
                      key={year.format('YYYY')}
                      className={classes({
                        'timeline-satellite-dates-entry': true,
                        bold: true,
                      })}
                      style={{ flex: `1` }}
                    >
                      {year.format('YYYY')}
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              className={classes({
                'timeline-satellite-content': true,
              })}
            >
              <div
                className='timeline-satellite-content-entries'
                id='timeline-satellite-content-entries'
                ref={contentRef}
              >
                {years.map((m, i) => {
                  const formatted = m.format('MMM YYYY')
                  return (
                    <div key={formatted} className='timeline-satellite-content-month' style={{ flex: `1` }}>
                      &nbsp;
                    </div>
                  )
                })}

                {leftByID &&
                  timeline.satelliteTimeline.map((s) => {
                    const left = leftByID[s.satelliteID]
                    const key = s.satelliteID
                    return (
                      <div
                        key={key}
                        id={s.satelliteID}
                        className={classes({
                          'timeline-satellite-content-item': true,
                          active: timeline.activeSatellite?.satelliteID === s.satelliteID,
                          processing:
                            s.purchaseState === SatellitePurchaseState.Purchased &&
                            s.tilingState === SatelliteTilingState.Processing,
                          processed:
                            s.purchaseState === SatellitePurchaseState.Purchased &&
                            s.tilingState === SatelliteTilingState.Processed,
                        })}
                        onMouseEnter={() => {
                          if (tooltipItems.findIndex((x) => x.satelliteID === s.satelliteID) === -1) {
                            setTooltipItems([s])
                          }
                        }}
                        onMouseLeave={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.satelliteID !== s.satelliteID)])
                        }}
                        onBlur={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.satelliteID !== s.satelliteID)])
                        }}
                        onMouseOut={() => {
                          setTooltipItems([...tooltipItems.filter((i) => i.satelliteID !== s.satelliteID)])
                        }}
                        style={{ left: left + 'px' }}
                      ></div>
                    )
                  })}
                <div id='timeline-satellite-content-hover-date' className='timeline-satellite-content-hover-date'></div>
                {leftByID && timeline.activeSatellite && (
                  <div
                    className='timeline-satellite-content-active-date'
                    style={{ left: leftByID[timeline.activeSatellite.satelliteID] + 2.5 + 'px' }}
                  ></div>
                )}
                {animating && <div id='animation-cursor' className='timeline-satellite-content-active-date'></div>}
              </div>
            </div>
            <div
              id='timeline-satellite-content-hover-date-tooltip'
              className='timeline-satellite-content-hover-date-tooltip'
            >
              {tooltipItems.length === 0 && (
                <div
                  id='timeline-satellite-content-hover-date-tooltip-date'
                  className={classes({
                    'timeline-satellite-content-hover-date-tooltip-date': true,
                  })}
                ></div>
              )}
              {tooltipItems.length > 0 && (
                <div className='timeline-content-image-upload-tooltip'>
                  <div className='timeline-content-image-upload-tooltip-title'>Satellite Image</div>
                  <ul className='timeline-content-image-upload-tooltip-points'>
                    {...tooltipItems.map((i) => <SatelliteItemTooltip key={i.satelliteID} image={i} />)}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showControls && (
        <div className='map-controls-lower' style={handleMapControlsPosition()}>
          {showActiveControls && (
            <>
              <div
                className={`map-control fullscreen text ${activeType === ActiveImageryType.RGB ? 'active' : ''}`}
                onClick={() => {
                  setActiveType(ActiveImageryType.RGB)
                }}
                title='RGB Imagery'
              >
                RGB
              </div>
              <div
                className={`map-control fullscreen text ${activeType === ActiveImageryType.NED ? 'active' : ''}`}
                onClick={() => {
                  setActiveType(ActiveImageryType.NED)
                }}
                title='NED Imagery'
              >
                NED
              </div>
            </>
          )}
          {showAnimateControls && (
            <div
              className={`map-control fullscreen ${animating ? 'active' : ''}`}
              onClick={() => {
                setAnimating(!animating)
              }}
              title='Animate'
            >
              <i className='material-icons'>play_arrow</i>
            </div>
          )}
        </div>
      )}
    </>
  )
}
function getDateFromPosition(
  contentRef: React.MutableRefObject<HTMLDivElement>,
  satelliteTimeline: SiteQuerySatelliteTimelineItem[],
  x: number
) {
  const totalWidth = contentRef.current.clientWidth - PADDING_OFFSET * 2

  const first = satelliteTimeline[0]
  const last = satelliteTimeline[satelliteTimeline.length - 1]
  const startDate = dayjs(first.date / 1000 / 1000).startOf('month')
  const endDate = dayjs(last.date / 1000 / 1000).endOf('month')
  const totalDiffInMilliseconds = endDate.diff(startDate)

  const deltaMS = (x / totalWidth) * totalDiffInMilliseconds
  const date = startDate.add(deltaMS, 'ms')
  return date
}
