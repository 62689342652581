import React, { useState } from 'react'
import { SimpleBar } from '~/components'
import { SitesQuerySiteWithExtra } from '~/models'
import { useAppState } from '~/state'

interface ColumnHeadingProps {
  title: string
  search: string
  searchChanged: (s: string) => void
  buttonType: string
  onButton: () => void
}

const ColumnHeading = (props: ColumnHeadingProps) => {
  return (
    <div className='site-assignment-heading'>
      <div className='site-assignment-heading-search'>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>{props.title}</span>
          </div>
          <input
            onChange={(e) => {
              props.searchChanged(e.target.value || '')
            }}
            type='text'
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            value={props.search}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>
              <button
                className='btn btn-secondary btn-sm'
                onClick={(e) => {
                  e.preventDefault()
                  props.onButton()
                }}
              >
                {props.buttonType} All
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CardProps {
  site: SitesQuerySiteWithExtra
  move: () => void
}

const Card = (props: CardProps) => {
  return (
    <div
      className='sites-card'
      style={{ width: 'initial', height: 'initial', marginRight: '15px', userSelect: 'none' }}
      onClick={props.move}
    >
      <div className='sites-card-content'>{props.site.s.name}</div>
    </div>
  )
}

enum ColumnType {
  Excluded,
  Included,
}

interface ColumnProps {
  sites: SitesQuerySiteWithExtra[]
  columnType: ColumnType
  move: (index: number) => void
  showEmpty?: boolean
}

const Column = (props: ColumnProps) => {
  return (
    <div className='site-groups-form-lower-content'>
      <div className='site-groups-form-lower-content-container'>
        <SimpleBar autoHide={false} setMaxHeightToParent>
          {props.sites.map((s, idx) => {
            return (
              <Card
                site={s}
                move={() => {
                  props.move(idx)
                }}
                key={s.s.id}
              />
            )
          })}
          {props.showEmpty && props.sites.length === 0 && (
            <div className='sites-list-container-empty'>
              <div className='body2'>All sites are currently accessible</div>
            </div>
          )}
          <div style={{ height: '20px' }}></div>
        </SimpleBar>
      </div>
    </div>
  )
}

interface SiteAccessTableProps {
  includedSites: SitesQuerySiteWithExtra[]
  excludedSites: SitesQuerySiteWithExtra[]
  setIncludedSites: (sites: SitesQuerySiteWithExtra[]) => void
  setExcludedSites: (sites: SitesQuerySiteWithExtra[]) => void
  height?: number | string
}

export const SiteAccessTable = (props: SiteAccessTableProps) => {
  const { sites } = useAppState()
  const [excludedSearch, setExcludedSearch] = useState('')
  const [includedSearch, setIncludedSearch] = useState('')

  const excludedSearchValue = excludedSearch.toLocaleLowerCase().trim()
  const includedSearchValue = includedSearch.toLocaleLowerCase().trim()

  const excludedSitesValue = props.excludedSites.filter((s) => {
    if (excludedSearchValue === '') {
      return true
    }

    return s.s.name.toLocaleLowerCase().includes(excludedSearchValue)
  })

  const includedSitesValue = props.includedSites.filter((s) => {
    if (includedSearchValue === '') {
      return true
    }

    return s.s.name.toLocaleLowerCase().includes(includedSearchValue)
  })

  return (
    <>
      <p className='settings-tab-content-notice'>
        In this panel, you can restrict access to only specific sites by adding sites to the right column.
        <br />
        By default, if no sites are added to the right column, all sites will be visible.
      </p>
      <div className='site-groups-form-lower' style={{ minHeight: props.height ? props.height : '40vh' }}>
        <div className='site-groups-form-lower-left'>
          <ColumnHeading
            title='Sites'
            search={excludedSearch}
            searchChanged={setExcludedSearch}
            buttonType='Add'
            onButton={() => {
              const sitesToExclude = [...excludedSitesValue]
              let newExcludedSites = [...props.excludedSites]
              const newIncludedSites = [...props.includedSites]
              for (const s of sitesToExclude) {
                newIncludedSites.push(s)
                newExcludedSites = newExcludedSites.filter((x) => x.s.id !== s.s.id)
              }
              props.setExcludedSites(sites.sortSites(newExcludedSites))
              props.setIncludedSites(sites.sortSites(newIncludedSites))
            }}
          />
          <Column
            sites={excludedSitesValue}
            columnType={ColumnType.Excluded}
            move={(index) => {
              const s = excludedSitesValue[index]
              props.setExcludedSites(sites.sortSites(props.excludedSites.filter((x) => x.s.id !== s.s.id)))
              props.setIncludedSites(sites.sortSites([...props.includedSites, s]))
            }}
          />
        </div>
        <div className='site-groups-form-lower-right'>
          <ColumnHeading
            title='Limit To'
            search={includedSearch}
            searchChanged={setIncludedSearch}
            buttonType='Remove'
            onButton={() => {
              const sitesToInclude = [...includedSitesValue]
              const newExcludedSites = [...props.excludedSites]
              let newIncludedSites = [...props.includedSites]
              for (const s of sitesToInclude) {
                newExcludedSites.push(s)
                newIncludedSites = newIncludedSites.filter((x) => x.s.id !== s.s.id)
              }
              props.setExcludedSites(sites.sortSites(newExcludedSites))
              props.setIncludedSites(sites.sortSites(newIncludedSites))
            }}
          />
          <Column
            sites={includedSitesValue}
            columnType={ColumnType.Included}
            move={(index) => {
              const s = includedSitesValue[index]
              props.setIncludedSites(sites.sortSites(includedSitesValue.filter((x) => x.s.id !== s.s.id)))
              props.setExcludedSites(sites.sortSites([...props.excludedSites, s]))
            }}
            showEmpty
          />
        </div>
      </div>
    </>
  )
}
