import React, { useState } from 'react'
import { useAppState } from '~/state'

import { Analysis } from '~/models'
import { getTracking } from '~/components'
import { useAnalysisRemove } from './analysis-data'

interface AnalysisRemoveProps {
  analysis: Analysis
  refetch: () => Promise<void>
  close: () => void
}

export const AnalysisRemove = (props: AnalysisRemoveProps) => {
  const { timeline, view } = useAppState()
  const [deleting, setDeleting] = useState(false)
  const [remove] = useAnalysisRemove()

  return (
    <div className='boundaries-draw'>
      <div
        className='item-tag-delete-confirm'
        style={{ backgroundColor: 'rgb(43, 39, 40)', justifyContent: 'unset', paddingTop: '2rem' }}
      >
        <h6>Delete Analysis?</h6>
        <button
          className='item-tag-delete-confirm-delete btn'
          disabled={deleting}
          onClick={() => {
            setDeleting(true)
            remove({
              variables: {
                input: {
                  id: props.analysis.id,
                  surveyID: timeline.activeSurvey.id,
                  siteID: view.siteID,
                },
              },
            })
              .then(() => {
                getTracking().event({
                  category: 'Analysis',
                  action: `User Deleted Analysis`,
                  label: 'Analysis',
                })
                return props.refetch().then(() => {
                  props.close()
                })
              })
              .finally(() => {
                setDeleting(false)
              })
          }}
        >
          {deleting ? 'Deleting...' : 'Yes, delete'}
        </button>
        <button className='item-tag-delete-confirm-cancel btn' disabled={deleting} onClick={props.close}>
          Cancel
        </button>
        <p>Caution: This cannot be undone.</p>
      </div>
      <div></div>
    </div>
  )
}
