import React, { useState } from 'react'
import { usePollingQuery, useUser } from '~/base'
import { getImageURL, getTracking, SimpleBar } from '~/components'
import { ImageSummariesByDayQuery } from '~/models'
import dayjs from 'dayjs'
import { FileUploadModal } from './file-upload-modal'
import IMAGE_SUMMARIES_BY_DAY from './query-image-summaries-by-day.gql'

enum UploadsViewStateType {
  History = 'history',
}

export const Uploads = () => {
  const user = useUser()
  const [profileView, setView] = useState<any>(UploadsViewStateType.History)
  const [showFileUploadModal, setShowFileUploadModal] = useState(false)
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const query = usePollingQuery<ImageSummariesByDayQuery>(IMAGE_SUMMARIES_BY_DAY)
  const showLoading = query.loading
  const showError = !query.loading && query.error
  const showList = !showLoading && !showError

  return (
    <>
      <ul className='profile-tabs'>
        <li
          className={`profile-tabs-item ${profileView === UploadsViewStateType.History ? 'active' : ''}`}
          onClick={() => setView(UploadsViewStateType.History)}
        >
          History
        </li>
      </ul>
      <div className='profile-tab-content'>
        {profileView === UploadsViewStateType.History && (
          <SimpleBar>
            <div className='profile uploads-container'>
              {showLoading && <div>Loading</div>}
              {showError && <div>Error</div>}
              {showList && (
                <div className='uploads-table-container'>
                  {[...query.data.queryImageSummariesByDay]
                    .sort((a, b) => (dayjs(a.day).isBefore(dayjs(b.day)) ? 1 : -1))
                    .map((img) => {
                      return (
                        <div>
                          <div>
                            <div>{dayjs(img.day).format('DD MMMM YYYY')}</div>
                            <div>
                              <div>
                                <i className='material-icons'>photo_camera_outline</i>
                                {img.numImages}
                              </div>
                              <div>
                                <i className='material-icons'>{img.processing ? 'cached_outline' : 'check'}</i>
                                {img.processing ? 'Processing' : 'Processing Complete'}
                              </div>
                            </div>
                          </div>
                          <div>
                            {img.imageHashes.map((hash, i) => {
                              return <img src={getImageURL({ hash }, user.org.id, i)} key={hash} />
                            })}
                          </div>
                        </div>
                      )
                    })}
                  {query.data.queryImageSummariesByDay.length === 0 && <div>No data has been uploaded</div>}
                </div>
              )}
              <br />
              <br />
              <button
                className='profile-form-btn btn'
                onClick={() => {
                  setShowFileUploadModal(true)
                  getTracking().event({
                    category: 'Button',
                    action: `User clicked upload files from URL button`,
                    label: 'Uploads',
                  })
                }}
              >
                Import from URLs
              </button>
            </div>
          </SimpleBar>
        )}
        <FileUploadModal
          show={showFileUploadModal}
          loading={fileUploadLoading}
          changeLoading={setFileUploadLoading}
          reload={() => {
            query.refetch().finally(() => {
              setShowFileUploadModal(false)
            })
          }}
          close={() => {
            setShowFileUploadModal(false)
            //setInviteModal(false)
          }}
        />
      </div>
    </>
  )
}
