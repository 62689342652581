import React, { useEffect } from 'react'
import { useUser } from '~/base'
import { DrawerState, getHelpWebsitePath } from '~/components'
import { useAppState } from '~/state'
import { ViewStateType } from '~/state/use-drawer-state'
// import CHEVRONS from '../images/chevrons-right.svg'
// import { ViewStateType } from '~/state/use-drawer-state'

interface SupportCardProps {
  link: string
  title: string
}

const SupportCard = (props: SupportCardProps) => (
  <a href={props.link} target='blank' rel='noopener noreferrer'>
    <div className='support-card'>
      <p className='support-card-body2'>
        {props.title} <span className='material-icons'>arrow_forward</span>
      </p>
    </div>
  </a>
)

export const Support = () => {
  const user = useUser()
  const { hubspotChat, drawer } = useAppState()

  useEffect(() => {
    console.log('drawer right', drawer.right)
    if (drawer.right === DrawerState.Normal) {
      return
    }
    drawer.set(drawer.left, DrawerState.Normal)
  }, [drawer.right])

  return (
    <>
      {/* <nav className='right-drawer-nav'>
        <div className='right-drawer-nav-items'>
          <div className='right-drawer-nav-items-item'>
            <img
              src={CHEVRONS}
              className='right-drawer-nav-items-item-expand expanded'
              alt='Expand right drawer'
              onClick={() => {
                drawer.setRightView(ViewStateType.Activity)
              }}
            />
          </div>
        </div>
      </nav> */}
      <div className='support'>
        <div
          className='support-close'
          onClick={() => {
            drawer.setRightView(ViewStateType.Activity)
            drawer.set(DrawerState.Normal, DrawerState.Normal)
          }}
        >
          <span className='fa-regular fa-close'></span>
        </div>
        <div className='support-chat'>
          <h6 className='support-chat-title'>Hi {user.firstName}.</h6>
          <p className='support-chat-body2'>Let us know how we can help you by starting a chat.</p>
          <button
            className='support-chat-btn btn'
            onClick={!hubspotChat ? () => {} : !hubspotChat.isOpen ? hubspotChat.openHandler : hubspotChat.closeHandler}
          >
            {!hubspotChat?.isOpen ? 'Live chat' : 'Close chat'}
          </button>
        </div>
        <div className='support-docs'>
          <h6 className='support-docs-title'>Need help? Checkout our knowledge base.</h6>
          <SupportCard link={`${getHelpWebsitePath()}/add-a-site`} title='Learn how to add a site' />
          <SupportCard
            link={`${getHelpWebsitePath()}/invite-team-members-to-your-company-workspace`}
            title='Learn how to invite your colleagues'
          />
          <SupportCard link={`${getHelpWebsitePath()}/add-a-boundary`} title='Learn how to add a boundary' />
          <div className='support-docs-link'>
            <a href={getHelpWebsitePath()} target='blank' rel='noopener noreferrer'>
              View more
            </a>
            <span className='material-icons'>arrow_forward</span>
          </div>
        </div>
        <div style={{ borderBottom: 0 }} className='support-chat'>
          <a href={getHelpWebsitePath()} target='blank' rel='noopener noreferrer'>
            <button className='support-chat-btn btn'>Help Center</button>
          </a>
        </div>
      </div>
    </>
  )
}
