import React from 'react'

type Props = {
  onMsg: (msg: Msg) => void
}

type Msg = { type: 'view_your_portfolio' }

export const SignUpSuccess = ({ onMsg }: Props) => {
  return (
    <>
      <h5 className='instant-assess-layout-header'>Your account is ready!</h5>

      <hr />

      <button
        className='btn btn-instant-assess'
        type='submit'
        onClick={() => {
          onMsg({ type: 'view_your_portfolio' })
        }}
      >
        View your portfolio
      </button>
    </>
  )
}
