import { ItemTagDelete, useMutation } from '~/components'
import React from 'react'
import { getTracking } from '~/components'
import { useAppState } from '~/state'
import { SITE_GROUP_REMOVE_MUTATION } from './data'
import { RemoveSiteGroupMutation, RemoveSiteGroupMutationVariables } from '~/models'

interface SiteGroupRemoveProps {
  onRemove: () => void
  hide: () => void
}

export const SiteGroupRemove = (props: SiteGroupRemoveProps) => {
  const { view } = useAppState()

  const [remove] = useMutation<RemoveSiteGroupMutation, RemoveSiteGroupMutationVariables>(SITE_GROUP_REMOVE_MUTATION)

  const doRemove = () => remove({ variables: { input: { id: view.groupID } } }).then(props.onRemove)

  return (
    <ItemTagDelete
      title='this group'
      cancel={props.hide}
      hideOperationType
      onClickDelete={() => {
        return doRemove().then(() => {
          getTracking().event({
            category: 'Button',
            action: `User removed a group`,
            label: 'Group',
          })
        })
      }}
    />
  )
}
