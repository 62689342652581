import React from 'react'
import { handleCurrencyFormatFull } from '../helpers'
import { useUser } from '~/base'

interface AnalyticsPanelReplacementCostProps {
  replacementCost: number
}

export const AnalyticsPanelReplacementCost = (props: AnalyticsPanelReplacementCostProps) => {
  const user = useUser()
  return (
    <div className='analytics-panel' style={{paddingBottom: '0.6rem'}}>
      <div className='analytics-panel-title'>COMPONENT REPLACEMENT COST</div>
      <div className='analytics-panel-container'>
        <div
          className='analytics-panel-container-inner-container-data-bar'
          style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}
        >
          <pre style={{margin: '0 auto'}}>{handleCurrencyFormatFull(props.replacementCost, user)}</pre>
        </div>
      </div>
    </div>
  )
}
