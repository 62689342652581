import React, { useState } from 'react'
import './image-gallery.scss'
import { ImageLightbox } from 'ui/image-gallery/image-lightbox'
import { notReachable } from 'components'
import { Image } from 'ui'

export type LightboxState = { currentImageIndex: number; open: boolean }

type Props = {
  images: Image[]
  imagesPerRow?: number
  maxImages?: number
  onImageClick?: (image: Image) => void
}

export const ImageGallery = ({ imagesPerRow = 4, images, onImageClick, maxImages = 4 }: Props) => {
  const [lightBoxState, setLightBoxState] = useState<LightboxState>({
    currentImageIndex: 0,
    open: false,
  })

  const displayedImages = images.slice(0, maxImages)
  const extraImagesCount = images.length - maxImages

  const handleImageClick = (image: Image, index: number) => {
    setLightBoxState({
      currentImageIndex: index,
      open: !lightBoxState.open,
    })
    onImageClick?.(image)
  }

  return (
    <>
      <div className='ui-image-gallery' style={{ '--images-per-row': imagesPerRow } as React.CSSProperties}>
        {displayedImages.map((image, index) => (
          <div key={index} className='ui-image-gallery-item' onClick={() => handleImageClick(image, index)}>
            <img src={image.src} alt={image.alt || ''} title={image.title || ''} />
            {index === maxImages - 1 && extraImagesCount > 0 && (
              <div className='ui-image-gallery-item-overlay'>
                <span>+{extraImagesCount}</span>
              </div>
            )}
          </div>
        ))}
      </div>
      <ImageLightbox
        images={images}
        lightBoxState={lightBoxState}
        onMsg={(msg) => {
          switch (msg.type) {
            case 'close':
              setLightBoxState({ ...lightBoxState, open: false })
              break
            case 'on_preview_clicked':
              setLightBoxState({ ...lightBoxState, currentImageIndex: msg.imageIndex })
              break
            default:
              return notReachable(msg)
          }
        }}
      />
    </>
  )
}
