import { useQuery } from '~/components'
import React from 'react'
import { Select } from '~/components'
import { AssetTypeDtoT, GetAssetTypesFullQuery } from '~/models'
import ASSET_TYPES_FULL_QUERY from '../query-asset-types-full.gql'

interface AssetTypeSelectProps {
  assetTypeID?: string
  onChange: (assetType: AssetTypeDtoT) => void
  helperText?: string
  disabled?: boolean
}

export const AssetTypeSelect = (props: AssetTypeSelectProps) => {
  const assetTypesQuery = useQuery<GetAssetTypesFullQuery>(ASSET_TYPES_FULL_QUERY)
  const assetTypes = (assetTypesQuery.data?.assetTypes || []) as AssetTypeDtoT[]

  return (
    <Select<AssetTypeDtoT | AssetTypeDtoT>
      id='asset-types'
      label='Asset Type'
      placeholder='Select an asset type'
      options={(assetTypes ? [...assetTypes] : [])
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
        .filter((assetType) => !assetType.archived)
        .map((assetType) => {
          return {
            id: assetType.id,
            name: assetType.name,
            value: assetType,
            items: [],
          }
        })}
      onChange={props.onChange}
      selectedValue={(assetTypes || []).find(i => i.id === props.assetTypeID)}
      helperText={props.helperText}
      disabled={props.disabled}
    />
  )
}
