import React from 'react'
import { Toast } from './toast'
import { useToasts } from './use-toast-state'

export const ToastManager = () => {
  const toasts = useToasts()
  return (
    <>
      {toasts.items.map((t, i) => (
        <Toast
          key={t.text}
          toastInfo={t}
          removeMe={() => {
            const newItems = [...toasts.items]
            newItems.splice(i, 1)
            toasts.setItems(newItems)
          }}
        />
      ))}
    </>
  )
}
