/**
 * Create frustum visualizations for the specified images.
 * @param images The images to visualize.
 * @param maximumAmount The maximum number of images to visualize.
 * @returns an array of Cesium.Primitives.
 */
export function visualizeFrustums(
  images: Array<{
    imageID: string
    longitude: number
    latitude: number
    altitude: number
    yaw: number
    pitch: number
    roll: number
    qx: number
    qy: number
    qz: number
    qw: number
  }>,
  color = Cesium.Color.RED,
  maximumAmount = 1,
  heightOffset = 0,
  selectedID: string = ''
) {
  const primitives: Cesium.Primitive[] = []
  const entities: any[] = []

  for (let i = 0; i < Math.min(images.length, maximumAmount); i++) {
    const frustum = new Cesium.PerspectiveFrustum({
      fov: Cesium.Math.toRadians(74),
      aspectRatio: 4.0 / 3.0,
      near: 0.0001,
      far: 1,
    })

    const origin = Cesium.Cartesian3.fromDegrees(
      images[i].longitude,
      images[i].latitude,
      images[i].altitude + heightOffset
    )

    let colorToUse = color
    if (images[i].imageID === selectedID) {
      colorToUse = Cesium.Color.fromCssColorString('#0F0')
    }

    const frustumGeometryInstance = new Cesium.GeometryInstance({
      geometry: new Cesium.FrustumGeometry({
        frustum,
        origin: origin,
        orientation: new Cesium.Quaternion(images[i].qx, images[i].qy, images[i].qz, images[i].qw),
        vertexFormat: Cesium.VertexFormat.POSITION_ONLY,
      }),
      attributes: {
        color: Cesium.ColorGeometryInstanceAttribute.fromColor(colorToUse.withAlpha(0.2)),
      },
    })

    const frustumOutlineGeometryInstance = new Cesium.GeometryInstance({
      geometry: new Cesium.FrustumOutlineGeometry({
        frustum,
        origin: origin,
        orientation: new Cesium.Quaternion(images[i].qx, images[i].qy, images[i].qz, images[i].qw),
      }),
      attributes: {
        color: Cesium.ColorGeometryInstanceAttribute.fromColor(colorToUse.withAlpha(1)),
      },
    })

    primitives.push(
      new Cesium.Primitive({
        geometryInstances: frustumGeometryInstance,
        appearance: new Cesium.PerInstanceColorAppearance({
          closed: true,
          flat: true,
        }),
        //modelMatrix: [1,0,0,0, 0,0,-1,0, 0,1,0,0, 0,0,0,1],
      })
    )

    primitives.push(
      new Cesium.Primitive({
        geometryInstances: frustumOutlineGeometryInstance,
        appearance: new Cesium.PerInstanceColorAppearance({
          flat: true,
        }),
        //modelMatrix: [1,0,0,0, 0,0,-1,0, 0,1,0,0, 0,0,0,1],
      })
    )

    //const quat = new Cesium.Quaternion(images[i].qx, images[i].qy, images[i].qz, images[i].qw)
    // let dirForward = new Cesium.Cartesian3()
    // dirForward.x = 2 * (quat.x * quat.z + quat.w * quat.y)
    // dirForward.y = 2 * (quat.y * quat.z - quat.w * quat.x)
    // dirForward.z = 1 - 2 * (quat.x * quat.x + quat.y * quat.y)
    // let dirUp = new Cesium.Cartesian3()
    // dirUp.x = 2 * (quat.x * quat.y - quat.w * quat.z)
    // dirUp.y = 1 - 2 * (quat.x * quat.x + quat.z * quat.z)
    // dirUp.z = 2 * (quat.y * quat.z + quat.w * quat.x)

    // const camera = new Cesium.Camera(scene)
    // camera.frustum.near = 0.5
    // camera.frustum.far = 1
    // camera.flyTo({
    //   destination: origin,
    //   orientation: Cesium.HeadingPitchRoll.fromQuaternion(quat),
    //   duration: 0,
    // })

    // primitives.push(new Cesium.DebugCameraPrimitive({
    //   camera : camera,
    //   color : Cesium.Color.RED,
    // }) as any);

    // var position = Cesium.Matrix4.getTranslation(transform, new Cesium.Cartesian3())
    // var XDir = Cesium.Cartesian3.fromElements(X.x, X.y, X.z, new Cesium.Cartesian3())
    // var YDir = Cesium.Cartesian3.fromElements(Y.x, Y.y, Y.z, new Cesium.Cartesian3())
    // var ZDir = Cesium.Cartesian3.fromElements(Z.x, Z.y, Z.z, new Cesium.Cartesian3())
    // entities.push({
    //   name: 'X Axis',
    //   polyline: {
    //     positions: [position, Cesium.Cartesian3.add(position, XDir, new Cesium.Cartesian3())],
    //     width: 5,
    //     arcType: Cesium.ArcType.NONE,
    //     material: Cesium.Color.RED,
    //     clampToGround: false,
    //   },
    // })

    // entities.push({
    //   name: 'Y Axis',
    //   polyline: {
    //     positions: [position, Cesium.Cartesian3.add(position, YDir, new Cesium.Cartesian3())],
    //     width: 5,
    //     arcType: Cesium.ArcType.NONE,
    //     material: Cesium.Color.GREEN,
    //     clampToGround: false,
    //   },
    // })

    // entities.push({
    //   name: 'Z Axis',
    //   polyline: {
    //     positions: [position, Cesium.Cartesian3.add(position, ZDir, new Cesium.Cartesian3())],
    //     width: 5,
    //     arcType: Cesium.ArcType.NONE,
    //     material: Cesium.Color.BLUE,
    //     clampToGround: false,
    //   },
    // })
  }

  return {
    primitives,
    entities,
  }
}
