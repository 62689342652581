import { useEffect } from 'react'
import { useRefState } from '~/state'

type Props = {
  imageUrls: string[]
}

export const ImagePreloader = ({ imageUrls }: Props): null => {
  const [preloadedImages] = useRefState<{ [url: string]: boolean }>({})

  useEffect(() => {
    imageUrls
      .filter((url) => {
        return !preloadedImages.current[url]
      })
      .forEach((url) => {
        const img = new Image()
        img.src = url
        img.crossOrigin = "Anonymous"
        preloadedImages.current[url] = true
      })
  }, [imageUrls])

  return null
}
