import { useEffect, useState } from 'react'

export function handleSimpleBarHeight(offset?: number) {
  const [height, setHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
  }, [height])

  if (offset) {
    return height - offset
  }

  return height
}
