import React from 'react'
import { classes } from '../../helpers'

export interface FloatingInputProps<T> {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: any
  onFocus?: any
  onLabelFocus?: any
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  helperText?: any
  error?: any
  type?: any
  halfWidth?: boolean
  inputRef?: React.RefObject<T> | React.Ref<T>
  readonly?: boolean
  autoFocus?: boolean
  prefix?: string
  disabled?: boolean
  focused?: boolean
  min?: number
  max?: number
  step?: number
  className?: string
}

export function FloatingInput<T extends HTMLInputElement = HTMLInputElement>(props: FloatingInputProps<T>) {
  return (
    <div
      onFocus={props.onFocus}
      className={classes({
        'input-container': true,
        [props.className + '']: !!props.className,
      })}
    >
      <div onFocus={props.onFocus}>
        <input
          autoFocus={props.autoFocus}
          className={classes({
            'form-control': true,
            'have-prefix': !!props.prefix,
            'is-focused': !!props.focused,
          })}
          type={props.type}
          placeholder={props.placeholder || ' '}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          ref={props.inputRef}
          disabled={props.readonly || props.disabled}
          autoComplete='off'
          name={props.id}
          min={props.min}
          max={props.max}
          step={props.step}
        />
        {props.prefix && <span className='input-container-prefix'>{props.prefix}</span>}
        <label onFocus={props.onLabelFocus} htmlFor={props.id}>
          {props.label}
        </label>
      </div>
      {props.helperText && <div className='invalid-feedback mb-3 visible'>{props.helperText}</div>}
    </div>
  )
}
