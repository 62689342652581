query getDefectTypes {
  defectTypes {
    id
    name
    description
    archived
    suggestedRemedy
    costToRepair
    costPriorityScalar
    subTypes {
      id
      name
      description
      archived
      suggestedRemedy
      costToRepair
      costPriorityScalar
    }
  }
}