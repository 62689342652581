import React, { useState } from 'react'
import { useAppState } from '~/state'
import { classes } from '../helpers'
import CHEVRONS from '../../images/chevrons-right.svg'
import { Switch } from '../forms'
import dayjs from 'dayjs'
import { useUser } from '~/base'
import { SystemRole } from '~/models'

interface FilmStripSettingsPanelProps {
  showPhotoLocations: boolean
  setShowPhotoLocations: (show: boolean) => void
  showPhotoDirections: boolean
  setShowPhotoDirections: (show: boolean) => void
  imageDays: string[]
  activeImageDays: string[]
  setActiveImageDays: (days: string[]) => void
}

export const FilmStripSettingsPanel = (props: FilmStripSettingsPanelProps) => {
  const { drawer } = useAppState()
  const [open, setOpen] = useState(false)
  const user = useUser()

  return (
    <div
      className={classes({
        'film-strip-settings-panel': true,
        open,
        expanded: drawer.leftExpanded,
      })}
    >
      <div
        className='film-strip-settings-panel-toggle'
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className='material-icons-outlined'>settings</div>
      </div>
      <div className='film-strip-settings-panel-box'>
        <div
          className='film-strip-settings-panel-box-icon'
          onClick={() => {
            setOpen(!open)
          }}
        >
          <img src={CHEVRONS} />
        </div>
        <div className='film-strip-settings-panel-box-content'>
          <Switch
            name='show-locations'
            label='Photo locations'
            checked={props.showPhotoLocations}
            onChange={props.setShowPhotoLocations}
          />
          <Switch
            name='show-directions'
            label='Photo directions'
            checked={props.showPhotoDirections}
            onChange={props.setShowPhotoDirections}
          />
          {user.systemRole === SystemRole.Staff && props.imageDays.map((day) => {
            const checked = props.activeImageDays.findIndex(x => x === day) !== -1
            const date = dayjs(day, {
              format: 'YYYYMMDD',
            })
            return (
              <Switch
                key={day}
                name={'image-day-' + day}
                label={date.format("DD MMM YYYY")}
                checked={checked}
                onChange={() => {
                  if(checked) {
                    props.setActiveImageDays(props.activeImageDays.filter(x => x !== day))
                  } else {
                    props.setActiveImageDays([...props.activeImageDays, day])
                  }
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
