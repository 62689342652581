import React, { useEffect, useMemo, useState } from 'react'
import { Analysis, AnalysisState, AnnotationType, SystemRole } from '~/models'
import { ClientPermission, useUser } from '~/base'
import { DrawerPopoutListCard, DrawerPopoutList, Select, Switch, classes, PolygonColorAnalysis } from '~/components'
import { useAppState } from '~/state'
import { AnalysisRemove } from './analysis-remove-modal'
import { useAnalysisVisualization } from './use-analysis-visualization'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'
import { AnalysisReprocess } from './analysis-reprocess'

export const AnalysisFullList = () => {
  const { drawer, analysis, map } = useAppState()
  const [removeAnalysis, setRemoveAnalysis] = useState<Analysis>()
  const [reprocessAnalysis, setReprocessAnalysis] = useState<Analysis>()
  const [polygonRenderers, setPolygonRenderers] = useState<PolygonRenderer3DView[]>([])

  // Keep polling for updates.
  useEffect(() => {
    const reload = () => {
      setTimeout(() => {
        analysis.reload().then(reload)
      }, 5000)
    }

    reload()
  }, [])

  useAnalysisVisualization()

  const analysesToUse = useMemo(() => {
    const sorted = [...analysis.analysis].sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    })
    return sorted
  }, [analysis.analysis])

  useEffect(() => {
    if (analysis.analysis.length === 0) {
      setPolygonRenderers([])
      return
    }

    const newPolygonRenderers: PolygonRenderer3DView[] = []
    for (const a of analysis.analysis) {
      const pr = new PolygonRenderer3DView({
        id: a.id + '-analysis',
        points: a.roi,
        map,
        renderPoints: false,
        color: PolygonColorAnalysis,
        scaleByDistance: new Cesium.NearFarScalar(10, 1, 40, 0.8),
        translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 1),
        annotationType: AnnotationType.Area,
        fill: true,
        onClick: () => {},
      })

      pr.setVisible(false)
      pr.setFill(false)
      newPolygonRenderers.push(pr)
    }

    setPolygonRenderers(newPolygonRenderers)

    return () => {
      newPolygonRenderers.forEach((p) => p.destroy())
      setPolygonRenderers([])
    }
  }, [analysis.analysis])

  const Controls = useMemo(() => {
    return (
      <>
        <div className='analysis-controls'>
          <Select<{
            id: string
            name: string
          }>
            id='height'
            label='Tolerance'
            placeholder='Select tolerance'
            canSelectGroup
            options={[
              {
                id: '5',
                name: '5cm',
                value: {
                  id: '5',
                  name: '5cm',
                },
                items: [],
              },
              {
                id: '10',
                name: '10cm',
                value: {
                  id: '10',
                  name: '10cm',
                },
                items: [],
              },
              {
                id: '20',
                name: '20cm',
                value: {
                  id: '20',
                  name: '20cm',
                },
                items: [],
              },
            ]}
            onChange={(selected) => {
              analysis.setSelectedHeightIndex(selected.id === '5' ? 0 : selected.id === '10' ? 1 : 2)
            }}
            selectedValue={{
              id: analysis.selectedHeightIndex === 0 ? '5' : analysis.selectedHeightIndex === 1 ? '10' : '20',
              name: analysis.selectedHeightIndex === 0 ? '5cm' : analysis.selectedHeightIndex === 1 ? '10cm' : '20cm',
            }}
          />

          <Switch
            name='show-all'
            label='Show all'
            checked={
              analysis.selectedAnalyses.length ===
              analysis.analysis.filter((x) => x.state === AnalysisState.Processed).length
            }
            onChange={() => {
              const allSelected =
                analysis.selectedAnalyses.length ===
                analysis.analysis.filter((x) => x.state === AnalysisState.Processed).length
              analysis.setSelectedAnalyses(
                allSelected ? [] : [...analysis.analysis.filter((x) => x.state === AnalysisState.Processed)]
              )
            }}
          />
        </div>
      </>
    )
  }, [analysis.selectedAnalyses, analysis.selectedHeightIndex])

  return (
    <>
      <DrawerPopoutList
        width={240}
        title='Analysis'
        total={analysis.analysis.length}
        count={analysesToUse.length}
        emptyText='There are no analyses to display.'
        controls={Controls}
      >
        {analysesToUse.map((a) => {
          const selectedIndex = analysis.selectedAnalyses.findIndex((x) => a.id === x.id)
          const isSelected = selectedIndex !== -1
          return (
            <AnalysisCard
              active={isSelected || removeAnalysis?.id === a.id}
              key={a.id}
              analysis={a}
              setAnalysisToDelete={setRemoveAnalysis}
              setAnalysisToReprocess={setReprocessAnalysis}
              selected={isSelected}
              setSelected={() => {
                if (a.state !== AnalysisState.Processed) {
                  return
                }
                if (isSelected) {
                  const newAnalyses = [...analysis.selectedAnalyses]
                  newAnalyses.splice(selectedIndex, 1)
                  analysis.setSelectedAnalyses(newAnalyses)
                } else {
                  analysis.setSelectedAnalyses([...analysis.selectedAnalyses, a])
                }
              }}
              onMouseEnter={() => {
                const pr = polygonRenderers.find((p) => p.id === a.id + '-analysis')
                if (pr) {
                  pr.setVisible(true)
                  pr.setFill(true)
                }
              }}
              onMouseLeave={() => {
                const pr = polygonRenderers.find((p) => p.id === a.id + '-analysis')
                if (pr) {
                  pr.setVisible(false)
                  pr.setFill(false)
                }
              }}
            />
          )
        })}
      </DrawerPopoutList>
      {removeAnalysis && (
        <AnalysisRemove
          analysis={removeAnalysis}
          refetch={() => {
            return analysis.reload().then((res) => {
              if (analysis.analysis.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            setRemoveAnalysis(undefined)
          }}
        />
      )}
      {reprocessAnalysis && (
        <AnalysisReprocess
          analysis={reprocessAnalysis}
          refetch={() => {
            return analysis.reload()
          }}
          close={() => {
            setReprocessAnalysis(undefined)
          }}
        />
      )}
      {analysis.selectedAnalyses.length > 0 && (
        <>
          <div
            className={classes({
              'analysis-scale': true,
              'drawer-open': drawer.rightExpanded,
            })}
          >
            <div>
              <div>
                {analysis.selectedHeightIndex === 0 ? '5' : analysis.selectedHeightIndex === 1 ? '10' : '20'} cm
              </div>
              <div>0 cm</div>
              <div>
                -{analysis.selectedHeightIndex === 0 ? '5' : analysis.selectedHeightIndex === 1 ? '10' : '20'} cm
              </div>
            </div>
          </div>
          <div id='analysis-scale-cursor'></div>
        </>
      )}
    </>
  )
}

interface AnalysisCardProps {
  active: boolean
  analysis: Analysis
  setAnalysisToDelete: (toDelete: Analysis) => void
  setAnalysisToReprocess: (toReprocess: Analysis) => void
  selected?: boolean
  setSelected?: (c: Analysis) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

function AnalysisCard(props: AnalysisCardProps) {
  const { view, site } = useAppState()
  const user = useUser()
  const isDemoSite = site.site?.isDemoSite
  const canRemove =
    !isDemoSite &&
    user.hasPermission(ClientPermission.AnnotationsRemove) &&
    props.analysis.state !== AnalysisState.Pending &&
    props.analysis.state !== AnalysisState.Processing
  const a = props.analysis

  return (
    <DrawerPopoutListCard
      key={a.id + view.surveyID + view.assetID}
      className='analysis-card'
      active={props.active}
      onClick={() => {
        props.setSelected(a)
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className='analysis-card-upper'>
        {a.name || 'Unnamed'}
        {canRemove && (
          <i
            title='Remove'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              if (props.setAnalysisToDelete) {
                props.setAnalysisToDelete(a)
              }
            }}
          >
            delete
          </i>
        )}
        {(a.state === AnalysisState.Invalid || a.state === AnalysisState.Processed) &&
          user.systemRole === SystemRole.Staff && (
            <i
              title='Process'
              className='material-icons'
              onClick={(e) => {
                e.preventDefault()
                if (props.setAnalysisToReprocess) {
                  props.setAnalysisToReprocess(a)
                }
              }}
            >
              replay
            </i>
          )}
      </div>
      <div className='analysis-card-middle'>{a.state}</div>
      {/* <div className='analysis-card-lower'></div> */}
    </DrawerPopoutListCard>
  )
}
