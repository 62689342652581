import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { Config } from './config'

const authLink = () =>
  setContext((_: any, ctx: any) => {
    const token = localStorage.getItem('asseti_token_new')
    const extraHeaders: any = {}

    if (ctx.correlationId) {
      extraHeaders['Correlation-ID'] = ctx.correlationId
    }

    if (token && token !== '') {
      extraHeaders.authorization = `Bearer ${token}`
    }

    return {
      headers: {
        ...ctx.headers,
        ...extraHeaders,
      },
    }
  })

const logoutLink = onError((res) => {
  if (
    res.graphQLErrors &&
    res.graphQLErrors.length > 0 &&
    (res.graphQLErrors[0].message.includes('not_authenticated') ||
      res.graphQLErrors[0].message.includes('You need to sign in or sign up before continuing'))
  ) {
    console.log(res.graphQLErrors)
    // If reports timeout, force a stop.
    if (window.location.href.includes('frontend:82')) {
      window.location.href = 'about:blank'
    } else {
      window.location.pathname = '/signed-out'
    }
  }
})

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }
  return forward(operation).map((data) => {
    return data
  })
})

export const initClient = (): ApolloClient<any> => {
  let uri = Config.GraphQLBaseURL + '/api/graphql/internal'
  if (Config.GraphQLBaseURL === '/') {
    uri = `https://${window.location.hostname}/api/graphql/internal`
  }
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        BasicSurveyDtoT: {
          fields: {
            reconstructions: {
              merge(existing, incoming) {
                return incoming
              },
            },
          },
        },
        BoundaryDtoT: {
          keyFields: false,
        },
        MonitoringZoneDtoT: {
          keyFields: false,
        },
        AssetDtoT: {
          keyFields: false,
        },
        ComponentDtoT: {
          keyFields: false,
        },
        MaterialTypeDtoT: {
          keyFields: false,
        },
        IssueManuallyTaggedImageDtoT: {
          keyFields: false,
        },
        AnnotationDtoT: {
          keyFields: false,
        },
        SatelliteImageSiteT: {
          keyFields: false,
        },
        AnalysisDtoT: {
          keyFields: false,
        },
        QueryAnalysisResponseT: {
          keyFields: false,
        },
        OrderDroneSurveyBoundaryT: {
          keyFields: false,
        },
        OrderDroneSurveyBoundaryRequirementT: {
          keyFields: false,
        },
        OrderContactT: {
          keyFields: false,
        },
        OrderDroneSurveyT: {
          keyFields: false,
        },
        OrderCostT: {
          keyFields: false,
        },
        OrderStatusChangeDtoT: {
          keyFields: false,
        },
        SupplierDtoT: {
          keyFields: false,
        },
      },
    }),
    link: authLink().concat(cleanTypeName).concat(logoutLink).concat(
      new HttpLink({
        uri,
      })
    ),
    ssrMode: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  })

  window.__apolloClient = client
  return client
}
