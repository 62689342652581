import React, { useEffect, useState } from 'react'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'
import { classes } from '~/components'

type DatePickerPropsMeh = Omit<DatePickerProps, 'value'> & { value?: Date | number; onChange?: (d: Date) => void }

export const DatePicker = (props: DatePickerPropsMeh) => {
  const [date, setDate] = useState(new Date())
  const [minDate, setMinDate] = useState<Date | undefined>()

  useEffect(() => {
    if (typeof props.value === 'number') {
      const s = new Date()
      s.setTime(props.value / 1000000)
      setDate(s)
    } else {
      setDate(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (typeof props.minDate === 'number') {
      const md = new Date()
      md.setTime(props.minDate / 1000000)
      setMinDate(md)
    }
  }, [])

  return (
    <div
      className={classes({
        'input-container': true,
        [props.className + '']: !!props.className,
      })}
    >
      <div>
        <ReactDatePicker
          className='datepicker-form-control'
          selected={date}
          minDate={minDate}
          onChange={props.onChange}
          dateFormat='d MMMM yyyy'
        />
        <label htmlFor={props.id}>{props.label}</label>
      </div>
      {props.helperText && <div className='invalid-feedback mb-3'>{props.helperText}</div>}
      {props.error && <div className='invalid-feedback visible'>{props.error}</div>}
    </div>
  )
}
