query instantAssessmentsCoverageByPolygon($input: InstantAssessmentsCoverageByPolygonRequestT!) {
  instantAssessmentsCoverageByPolygon(input: $input) {
    surveys {
      captureDate
      firstPhotoTime
      id
      lastPhotoTime
      location {
        country
        region
        state
      }
      onlineTime
      pixelSize
      resources {
        photos {
          id
          scale
          type
        }
      }
      timezone
      utcOffset
    }
    limit
    offset
    total
  }
}
