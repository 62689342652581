import { useEffect } from 'react'
import { useUser } from '~/base'
import { getAnalysisImage } from '~/components'
import { Analysis, AnalysisState } from '~/models'
import { useAppState, useRefState } from '~/state'
import { AnalysisWorkerData } from './analysis-image-worker-data'

export function useAnalysisVisualization() {
  const { map, analysis, issues } = useAppState()
  const user = useUser()
  const [analysisImages] = useRefState<
    Array<{
      entity: Cesium.Entity
      analysis: Analysis
      heightIndex: number
    }>
  >(
    analysis.analysis.map((analysis) => {
      return {
        entity: undefined,
        analysis,
        heightIndex: -1,
      }
    })
  )

  useEffect(() => {
    issues.setShow(false)
    return () => {
      issues.setShow(true)
      analysisImages.current.forEach((a) => {
        if (a.entity) {
          map.viewer.entities.remove(a.entity)
          a.entity = undefined
          a.heightIndex = -1
        }
      })
    }
  }, [])

  // Render all.
  useEffect(() => {
    if (analysis.selectedAnalyses.length === 0 || map.morphing) {
      analysisImages.current.forEach((a) => {
        if (a.entity) {
          map.viewer.entities.remove(a.entity)
          a.entity = undefined
        }
      })
      return
    }

    let status = {
      cancel: false,
    }

    for (const a of analysisImages.current.filter((a) => a.analysis.state === AnalysisState.Processed)) {
      const mustRender = analysis.selectedAnalyses.findIndex((x) => x.id === a.analysis.id) !== -1
      if (mustRender) {
        if (a.entity && a.heightIndex === analysis.selectedHeightIndex) {
          continue
        }

        if (a.entity) {
          map.viewer.entities.remove(a.entity)
          a.entity = undefined
          a.heightIndex = -1
        }

        const worker = new window.__assetiWorker('/analysis-image-worker.js')
        worker.postMessage([
          {
            analysisID: a.analysis.id,
            imageURL: getAnalysisImage(a.analysis.imageURLs[analysis.selectedHeightIndex], user.org.id),
          },
        ])
        worker.addEventListener(
          'message',
          async function (event: MessageEvent<AnalysisWorkerData[]>) {
            if (status.cancel) {
              return
            }

            for (const item of event.data) {
              const img = new Image()
              img.src = item.imageURL
              img.onload = () => {
                if (status.cancel) {
                  return
                }
                const a = analysisImages.current.find((a) => a.analysis.id === item.analysisID)
                const ctor = getAnalysisEntityConstructor(a.analysis, analysis.selectedHeightIndex, img)
                a.entity = map.viewer.entities.add(ctor)
                a.heightIndex = analysis.selectedHeightIndex
              }
            }
          },
          false
        )
      } else {
        if (a.entity) {
          map.viewer.entities.remove(a.entity)
          a.entity = undefined
          a.heightIndex = -1
        }
      }
    }

    return () => {
      status.cancel = true
    }
  }, [analysis.selectedAnalyses, map.morphing, analysis.selectedHeightIndex])

  // Mouse events.
  useEffect(() => {
    if (analysis.selectedAnalyses.length === 0 || map.morphing) {
      return
    }
    const heightScale = [5, 10, 20][analysis.selectedHeightIndex]
    const c = map.viewer.canvas.getContext('webgl2', { willReadFrequently: true }) as WebGLRenderingContext
    const cursor = document.getElementById('analysis-scale-cursor')

    let moustState = {
      down: false,
    }
    const onMouseDown = () => {
      cursor.style.display = 'none'
      document.body.style.cursor = 'default'
      moustState.down = true
    }
    const onMouseUp = () => {
      moustState.down = false
    }

    const onMouseMove = (e: MouseEvent) => {
      if (moustState.down) {
        return
      }

      const rect = map.viewer.canvas.getBoundingClientRect()
      const x = e.clientX - rect.left
      const canvasY = e.clientY - rect.top
      const y = map.viewer.canvas.clientHeight - canvasY
      const pixels = new Uint8Array(4 * 1)
      c.readPixels(x, y, 1, 1, c.RGBA, c.UNSIGNED_BYTE, pixels)

      const hsl = rgbToHsl(pixels[0], pixels[1], pixels[2])

      // Not part of the deflection.
      if (hsl.s < 0.9) {
        cursor.style.display = 'none'
        document.body.style.cursor = 'default'
        return
      }
      let distance = 0
      if (hsl.h > 0.33) {
        const normalizedDistance = -(hsl.h - 0.33) / 0.33
        distance = normalizedDistance * heightScale
        if (distance >= heightScale) {
          distance = -heightScale
        }

        if (hsl.l <= 0.4) {
          cursor.innerText = '< ' + -heightScale.toFixed(2) + ' cm'
        } else {
          cursor.innerText = distance.toFixed(2) + ' cm'
        }
      } else if (hsl.h < 0.33) {
        const normalizedDistance = (0.33 - hsl.h) / 0.33
        distance = normalizedDistance * heightScale

        if (distance >= heightScale) {
          distance = heightScale
          hsl.l = 0.3
        }

        if (hsl.l <= 0.4) {
          cursor.innerText = '> ' + heightScale.toFixed(2) + ' cm'
        } else {
          cursor.innerText = distance.toFixed(2) + ' cm'
        }
      }

      cursor.style.display = 'block'
      cursor.style.left = e.clientX - 20 + 'px'
      cursor.style.top = e.clientY - 40 + 'px'
      document.body.style.cursor = 'crosshair'
    }
    map.viewer.canvas.addEventListener('pointerdown', onMouseDown)
    map.viewer.canvas.addEventListener('pointerup', onMouseUp)
    map.viewer.canvas.addEventListener('mousemove', onMouseMove)

    return () => {
      map.viewer.canvas.removeEventListener('mousedown', onMouseDown)
      map.viewer.canvas.removeEventListener('mouseup', onMouseUp)
      map.viewer.canvas.removeEventListener('mousemove', onMouseMove)
    }
  }, [analysis.selectedAnalyses, analysis.selectedHeightIndex, map.morphing])
}

function getPointsForAnalysis(a: Analysis) {
  const points = a.roi.map((r) => Cesium.Cartographic.fromDegrees(r.longitude, r.latitude, r.altitude))

  const minX = points.reduce((a, b) => Math.min(a, b.longitude), 10000)
  const maxX = points.reduce((a, b) => Math.max(a, b.longitude), -10000)
  const minY = points.reduce((a, b) => Math.min(a, b.latitude), 10000)
  const maxY = points.reduce((a, b) => Math.max(a, b.latitude), -10000)
  // const minZ = points.reduce((a, b) => Math.min(a, b.height), 10000)
  const maxZ = points.reduce((a, b) => Math.max(a, b.height), -10000)

  return [
    Cesium.Cartesian3.fromRadians(minX, minY, maxZ),
    Cesium.Cartesian3.fromRadians(maxX, minY, maxZ),
    Cesium.Cartesian3.fromRadians(maxX, maxY, maxZ),
    Cesium.Cartesian3.fromRadians(minX, maxY, maxZ),
  ]
}

function getAnalysisEntityConstructor(a: Analysis, selectedHeightIndex: number, image: HTMLImageElement) {
  return {
    name: 'analysis-image-' + a.id + '-' + selectedHeightIndex,
    polygon: {
      hierarchy: new Cesium.PolygonHierarchy(getPointsForAnalysis(a)),
      classificationType: Cesium.ClassificationType.BOTH,
      material: new Cesium.ImageMaterialProperty({
        image,
        transparent: true,
      }),
    },
  }
}

function rgbToHsl(r: number, g: number, b: number) {
  ;(r /= 255), (g /= 255), (b /= 255)

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return { h, s, l }
}
