import React from 'react'
import { classes } from '../helpers/classes'

interface NoItemTagProps {
  canAdd: boolean
  canAddText: string
  cantAddText: string
  onClick: () => void
  className?: string
}

export const NoItemTag = (props: NoItemTagProps) => {
  return (
    <div
      className={classes({
        'no-item-tag': true,
        disabled: !props.canAdd,
      }) + (props.className ? ' ' + props.className : '')}
      onClick={() => {
        if (props.canAdd) {
          props.onClick()
        }
      }}
    >
      {props.canAdd ? props.canAddText : props.cantAddText}
    </div>
  )
}
