import constate from 'constate'
import { useState } from 'react'

export enum ToastPosition {
  TopRight,
  TopLeft,
  BottomRight,
  BottomLeft,
}

export interface ToastInfo {
  text: string
  position: ToastPosition
  autoDismiss?: boolean
  content?: any
  timeout?: number
}

function useToastState() {
  const [toasts, setToasts] = useState<ToastInfo[]>([])

  function addToastToPosition(text: string, position: ToastPosition, timeout?: number) {
    setToasts([...toasts, {
      text,
      position,
      autoDismiss: true,
      timeout: timeout,
    }])
  }

  return {
    addTopLeft: (text: string, timeout?: number) => addToastToPosition(text, ToastPosition.TopLeft, timeout),
    addTopRight: (text: string, timeout?: number) => addToastToPosition(text, ToastPosition.TopRight, timeout),
    addBottomLeft: (text: string, timeout?: number) => addToastToPosition(text, ToastPosition.BottomLeft, timeout),
    addBottomRight: (text: string, timeout?: number) => addToastToPosition(text, ToastPosition.BottomRight, timeout),
    items: toasts,
    setItems: setToasts,
  }
}

export const [ToastStateProvider, useToasts] = constate(useToastState)
