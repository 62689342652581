import { Point, Rect } from 'openseadragon'
import { uid } from '../utils'

export interface ShapeConstructor {
  new (
    viewer: OpenSeadragon.Viewer,
    shapeChanged: (rect: Rect, shape: BaseShape) => void
  ): BaseShape
}

export abstract class BaseShape {
  protected _isDisposed = false
  protected _isDrawing = false

  id: string = uid()
  hidden = false

  get isDisposed() {
    return this._isDisposed
  }

  get isDrawing() {
    return this._isDrawing
  }
  abstract readonly boundingBox: Rect

  constructor(
    protected viewer: OpenSeadragon.Viewer,
    protected shapeChanged: (rect: Rect, shape: BaseShape) => void
  ) {}

  dispose() {
    this._isDisposed = true
  }

  draw(context2d: CanvasRenderingContext2D): void {
    const svg = this.toPath2D()

    context2d.stroke(svg)
    context2d.fill(svg, 'evenodd')
    context2d.save()
  }

  startDrawing() {
    this._isDrawing = true
  }

  isPointOver(localPoint: Point, context2d: CanvasRenderingContext2D) {
    return context2d.isPointInStroke(this.toPath2D(), localPoint.x, localPoint.y)
  }

  protected toViewerCoords(point: Point) {
    return this.viewer.viewport.imageToViewerElementCoordinates(point.clone())
  }

  protected finishDrawing() {
    this._isDrawing = false
  }

  abstract toPath2D(): Path2D

  abstract onMouseDown(point: Point): boolean
  abstract onMouseMove(point: Point): boolean
  abstract onMouseUp(point?: Point): void
  abstract onRightClick(point?: Point): void
}
