import React, { useEffect, useRef, useState } from 'react'
import {
  ActivityCardOrg,
  ActivityCardSurvey,
  AnalyticsPanelIssues,
  useQuery,
  useLocation,
  AnalyticsPanel,
  SimpleBar,
  useToasts,
  AnalyticsPanelReplacementCost,
  calculateComponentMeasurement,
  calculateComponentMeasurementWithoutLabel,
  classes,
  formatTemp,
  formatWeatherIcon,
  formatRain,
  formatWindSpeed,
  AnalyticsPanelPortfolioSnapshot,
} from '~/components'
import { ClientPermission, useUser } from '~/base'
import {
  ActivityStreamForOrgQuery,
  ActivityStreamForSurveyQuery,
  ComponentCondition,
  ComponentDtoT,
  IssueStatus,
  OrgActivityItem,
  OrganizationForActivityQuery,
  QueryActivityStreamForOrgArgs,
  QueryActivityStreamForSurveyArgs,
  RiskType,
  SurveyActivityItem,
  WeatherForSiteQuery,
  WeatherForSiteQueryVariables,
} from '~/models'
import { useAppState } from '~/state'
import ACTIVITY_STREAM_FOR_ORG_QUERY from './query-activity-stream-for-org.gql'
import ACTIVITY_STREAM_FOR_SURVEY_QUERY from './query-activity-stream-for-survey.gql'
import QUERY_WEATHER from './query-weather-for-site.gql'
import ORG_QUERY from './query-org-for-activity.gql'
import { getIssueEstimatedRepairCost } from '~/issues'
import dayjs from 'dayjs'
import { ViewStateType } from '~/state/use-drawer-state'

let activityScrollTimeout: ReturnType<typeof setTimeout> = undefined

enum TabType {
  Weather,
  Activity,
}

interface ComponentGroup {
  score: number
  replacementCost: number
  components: ComponentDtoT[]
}

interface ActivityProps {
  setView: React.Dispatch<React.SetStateAction<ViewStateType>>
  weather: WeatherForSiteQuery['weatherForSite']
  setWeather: (w: WeatherForSiteQuery['weatherForSite']) => void
}

export const Activity = (props: ActivityProps) => {
  const activityStreamQuery = useQuery<ActivityStreamForOrgQuery, QueryActivityStreamForOrgArgs>(
    ACTIVITY_STREAM_FOR_ORG_QUERY,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'cache-first',
    }
  )
  const activityStreamForSurveyQuery = useQuery<ActivityStreamForSurveyQuery, QueryActivityStreamForSurveyArgs>(
    ACTIVITY_STREAM_FOR_SURVEY_QUERY,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'cache-first',
    }
  )
  const weatherQuery = useQuery<WeatherForSiteQuery, WeatherForSiteQueryVariables>(QUERY_WEATHER, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const user = useUser()
  const toasts = useToasts()
  const query = useQuery<OrganizationForActivityQuery>(ORG_QUERY)
  const { sites, view, timeline, map, site, asset } = useAppState()
  const location = useLocation()
  const totalRef = useRef<number>()
  const loadingRef = useRef<boolean>(true)
  const changedRef = useRef<boolean>(false)
  const surveyIDRef = useRef<string>(view.surveyID)
  const dataRef = useRef<OrgActivityItem[]>()
  const [data, setData] = useState<OrgActivityItem[]>([])
  const surveyDataRef = useRef<SurveyActivityItem[]>()
  const [surveyData, setSurveyData] = useState<SurveyActivityItem[]>([])
  const canListIssues = user.hasPermission(ClientPermission.IssuesList)
  const canSeeActivity = user.hasPermission(ClientPermission.ActivityList)
  const [tabType, setTabType] = useState<TabType>(TabType.Weather)
  const [loadingWeather, setLoadingWeather] = useState(true)

  let issueCounters = {
    ...(query.data?.organizationSummary?.issueCounters || {
      high: 0,
      medium: 0,
      low: 0,
    }),
  }
  let issueCosts = {
    ...(query.data?.organizationSummary?.issueCosts || {
      high: 0,
      medium: 0,
      low: 0,
    }),
  }

  const reportedVsResolved = {
    reported: 0,
    resolved: 0,
  }

  let replacementCost = query.data?.organizationSummary?.componentReplacementCost || 0

  if (view.showingGroups) {
    const groups = sites?.groups || []
    if (view.groupID) {
      const group = groups.find((g) => g.group.id === view.groupID)
      if (group) {
        issueCounters = group.group.issueCounters
        issueCosts = group.group.issueCosts
        replacementCost = group.group.componentReplacementCost
        reportedVsResolved.resolved = group.group.issueStatusCount.complete || 0
      }
    } else {
      issueCounters.high = 0
      issueCounters.medium = 0
      issueCounters.low = 0
      issueCosts.high = 0
      issueCosts.medium = 0
      issueCosts.low = 0
      replacementCost = 0
      for (const g of groups) {
        issueCounters.high += g.group.issueCounters.high
        issueCounters.medium += g.group.issueCounters.medium
        issueCounters.low += g.group.issueCounters.low
        issueCosts.high += g.group.issueCosts.high
        issueCosts.medium += g.group.issueCosts.medium
        issueCosts.low += g.group.issueCosts.low
        replacementCost += g.group.componentReplacementCost
        reportedVsResolved.resolved += g.group.issueStatusCount.complete
      }
    }
  } else if (view.siteID) {
    if (timeline.activeSurvey && site.site) {
      const groups: {
        [key: string]: ComponentGroup
      } = {}

      const components = (
        view.assetID
          ? timeline.activeSurvey.components.filter((x) => x.assetID === view.assetID)
          : [...timeline.activeSurvey.components]
      ) as ComponentDtoT[]

      for (const c of components) {
        const key = c.componentTypeFullName + c.materialTypeName

        if (!groups[key]) {
          groups[key] = {
            score: 0,
            replacementCost: 0,
            components: [c],
          }
        }

        const m = calculateComponentMeasurementWithoutLabel(c)
        const formatted = calculateComponentMeasurement(c, user.org.measurementSystem)

        let score = 0
        if (c.condition === ComponentCondition.Excellent) {
          score += 5
        } else if (c.condition === ComponentCondition.Good) {
          score += 4
        } else if (c.condition === ComponentCondition.Average) {
          score += 3
        } else if (c.condition === ComponentCondition.Poor) {
          score += 2
        } else if (c.condition === ComponentCondition.VeryPoor) {
          score += 1
        } else if (c.condition === ComponentCondition.EndOfLife) {
          score += 0
        }

        if (c.costPerUnit !== 0) {
          if (formatted === 'No measurement') {
            groups[key].replacementCost = c.costPerUnit * (groups[key].components.length + 1)
          } else {
            groups[key].replacementCost += c.costPerUnit * m
          }
        }
        groups[key].score += score
        groups[key].components.push({
          ...c,
        })
      }
      replacementCost = Object.keys(groups).reduce((a, b) => {
        return a + groups[b].replacementCost
      }, 0)

      if (view.assetID) {
        const asset = site.site.assets.find((a) => a.id === view.assetID)
        if (asset) {
          const high = timeline.activeSurvey.issues.filter(
            (i) => i.assetID === asset.id && i.riskType === RiskType.High
          )
          const medium = timeline.activeSurvey.issues.filter(
            (i) => i.assetID === asset.id && i.riskType === RiskType.Medium
          )
          const low = timeline.activeSurvey.issues.filter((i) => i.assetID === asset.id && i.riskType === RiskType.Low)
          const complete = timeline.activeSurvey.issues.filter(
            (i) => i.assetID === asset.id && i.status === IssueStatus.Complete
          )
          issueCounters.high = high.length
          issueCounters.medium = medium.length
          issueCounters.low = low.length
          issueCosts.high = high.reduce(
            (a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost),
            0
          )
          issueCosts.medium = medium.reduce(
            (a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost),
            0
          )
          issueCosts.low = low.reduce((a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost), 0)
          reportedVsResolved.resolved = complete.length
        }
      } else {
        const high = timeline.activeSurvey.issues.filter((i) => i.riskType === RiskType.High)
        const medium = timeline.activeSurvey.issues.filter((i) => i.riskType === RiskType.Medium)
        const low = timeline.activeSurvey.issues.filter((i) => i.riskType === RiskType.Low)
        const complete = timeline.activeSurvey.issues.filter((i) => i.status === IssueStatus.Complete)
        issueCounters.high = high.length
        issueCounters.medium = medium.length
        issueCounters.low = low.length
        issueCosts.high = high.reduce((a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost), 0)
        issueCosts.medium = medium.reduce(
          (a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost),
          0
        )
        issueCosts.low = low.reduce((a, b) => a + (b.repairEstimate || getIssueEstimatedRepairCost(user, b).cost), 0)
        reportedVsResolved.resolved = complete.length
      }
    }
  } else {
    reportedVsResolved.resolved = query.data?.organizationSummary?.issueStatusCounts?.complete || 0
  }

  reportedVsResolved.reported = issueCounters.high + issueCounters.medium + issueCounters.low

  useEffect(() => {
    surveyIDRef.current = view.surveyID
    changedRef.current = true
    loadingRef.current = true
    if (view.surveyID) {
      activityStreamForSurveyQuery
        .refetch({
          input: {
            start: 0,
            siteID: view.siteID,
            surveyID: view.surveyID,
          },
        })
        .then((res) => {
          const d = [...(res?.data?.activityStreamForSurvey?.items || [])]
          setSurveyData(d)
          surveyDataRef.current = d
          totalRef.current = res?.data?.activityStreamForSurvey?.total || 0
        })
        .finally(() => {
          setTimeout(() => {
            loadingRef.current = false
            changedRef.current = false
          }, 0)
        })
    } else {
      activityStreamQuery
        .refetch({
          input: {
            start: 0,
          },
        })
        .then((res) => {
          const d = [...(res?.data?.activityStreamForOrg?.items || [])]
          setData(d)
          dataRef.current = d
          totalRef.current = res?.data?.activityStreamForOrg?.total || 0
        })
        .finally(() => {
          setTimeout(() => {
            loadingRef.current = false
            changedRef.current = false
          }, 0)
        })
    }
  }, [view.surveyID])

  useEffect(() => {
    if (!view.siteID) {
      return
    }

    weatherQuery
      .refetch({
        input: {
          siteID: view.siteID,
        },
      })
      .then((res) => {
        if (res?.data?.weatherForSite) {
          props.setWeather(res.data.weatherForSite)
        }
      })
      .finally(() => {
        setLoadingWeather(false)
      })

    return () => {
      setLoadingWeather(true)
    }
  }, [view.siteID])

  const goToSite = (siteID: string) => {
    const siteData = sites.data.sites
    const specificSite = siteData.filter((siteItem) => siteItem.id === siteID)

    if (!specificSite[0]) {
      toasts.addTopRight('Site no longer exists.')
    } else {
      location.setLocation('/' + siteID)
    }
  }

  const goToAsset = (siteID: string, assetID: string) => {
    // Get the asset data..
    const siteData = sites.data.sites
    const specificSite = siteData.filter((siteItem) => siteItem.id === siteID)
    if (!specificSite[0]) {
      toasts.addTopRight('Asset no longer exists.')
    } else {
      location.setLocation('/' + siteID + '/assets/' + assetID)
    }
  }

  const onScroll = (scrollTop: number, scrollHeight: number) => {
    if (loadingRef.current === true || changedRef.current === true) {
      if (activityScrollTimeout) {
        clearTimeout(activityScrollTimeout)
      }
      return
    }
    if (data.length >= totalRef.current) {
      return
    }

    if (scrollHeight - scrollTop > 120 * 5) {
      return
    }

    if (activityScrollTimeout) {
      clearTimeout(activityScrollTimeout)
    }

    activityScrollTimeout = setTimeout(() => {
      if (surveyIDRef.current) {
        if (changedRef.current) {
          return
        }
        loadingRef.current = true
        console.table({
          start: surveyDataRef.current.length,
          siteID: view.siteID,
          surveyID: surveyIDRef.current,
        })
        activityStreamForSurveyQuery
          .refetch({
            input: {
              start: surveyDataRef.current.length,
              siteID: view.siteID,
              surveyID: surveyIDRef.current,
            },
          })
          .then((res) => {
            if (changedRef.current) {
              return
            }
            const items = [...(res?.data?.activityStreamForSurvey?.items || [])]
            const toAdd = items //items.filter((i) => dataRef.current.findIndex((i2) => i2.data.value.time === i.data.value.time) === -1)

            const d = [...surveyDataRef.current, ...toAdd]
            setSurveyData(d)
            surveyDataRef.current = d
            totalRef.current = res?.data?.activityStreamForSurvey?.total || 0
          })
          .finally(() => {
            setTimeout(() => {
              loadingRef.current = false
            }, 0)
          })
      } else {
        if (changedRef.current) {
          return
        }
        loadingRef.current = true
        activityStreamQuery
          .refetch({
            input: {
              start: dataRef.current.length,
            },
          })
          .then((res) => {
            if (changedRef.current) {
              return
            }
            const items = [...(res?.data?.activityStreamForOrg?.items || [])]
            const toAdd = items //items.filter((i) => dataRef.current.findIndex((i2) => i2.data.value.time === i.data.value.time) === -1)

            const d = [...dataRef.current, ...toAdd]
            setData(d)
            dataRef.current = d
            totalRef.current = res?.data?.activityStreamForOrg?.total || 0
          })
          .finally(() => {
            setTimeout(() => {
              loadingRef.current = false
            }, 0)
          })
      }
    }, 250)
  }

  const summary = sites.iaQuery?.data?.instantAssessmentSummaryAllSites
  const haveSummary = !!summary

  return (
    <div className='activity-container'>
      {(canListIssues || !canSeeActivity) && (
        <div className='main-panel' style={{ paddingLeft: '0.5rem' }}>
          {!user.isInstantAssessmentOrg && (
            <>
              <AnalyticsPanelReplacementCost replacementCost={replacementCost} />
              <AnalyticsPanel title='Issues volume' counts={issueCounters} />
              <AnalyticsPanel title='Issues cost' counts={issueCosts} isCurrency />
              <AnalyticsPanelIssues reported={reportedVsResolved.reported} resolved={reportedVsResolved.resolved} />
            </>
          )}
          {user.isInstantAssessmentOrg && haveSummary && (
            <AnalyticsPanelPortfolioSnapshot
              summary={summary}
              siteName={site?.site?.name}
              assetName={asset?.asset?.name}
            />
          )}
        </div>
      )}
      {!map.showLidar && (
        <div className='activity-container-feed'>
          <div className='activity-container-feed-tabs'>
            {props.weather && canSeeActivity && (
              <h6
                className={classes({
                  'activity-container-feed-title': true,
                  active: tabType === TabType.Weather,
                  dimmed: tabType !== TabType.Weather,
                })}
                onClick={() => {
                  setTabType(TabType.Weather)
                }}
              >
                WEATHER
              </h6>
            )}
            {props.weather && canSeeActivity && (
              <h6
                className={classes({
                  'activity-container-feed-title': true,
                  active: tabType === TabType.Activity,
                  dimmed: tabType !== TabType.Activity,
                })}
                onClick={() => {
                  setTabType(TabType.Activity)
                }}
              >
                ACTIVITY
              </h6>
            )}
          </div>

          {view.siteID && tabType === TabType.Weather && (
            <div>
              {!props.weather && !loadingWeather && <div>No weather data found</div>}
              {!props.weather && loadingWeather && <div></div>}
              {props.weather && <Weather weather={props.weather} setView={props.setView} />}
            </div>
          )}

          {canSeeActivity && (tabType === TabType.Activity || !view.siteID) && (
            <SimpleBar onScroll={onScroll}>
              <div className='activity-container-feed-items'>
                {!view.surveyID &&
                  data.map((i, index) => (
                    <ActivityCardOrg goToSite={goToSite} goToAsset={goToAsset} {...i} key={index} />
                  ))}
                {view.surveyID &&
                  surveyData.map((i, index) => (
                    <ActivityCardSurvey
                      goToSite={goToSite}
                      goToAsset={goToAsset}
                      canListIssues={canListIssues}
                      {...i}
                      key={index}
                    />
                  ))}
              </div>
            </SimpleBar>
          )}
        </div>
      )}
    </div>
  )
}

function ceilDate(date: dayjs.Dayjs): dayjs.Dayjs {
  const minutes = date.minute()
  if (minutes === 0) {
    return date
  }
  if (minutes < 30) {
    return date.add(30 - minutes, 'minutes')
  }
  return date.add(60 - minutes, 'minutes')
}

interface WeatherProps {
  weather: WeatherForSiteQuery['weatherForSite']
  setView: React.Dispatch<React.SetStateAction<ViewStateType>>
}

const Weather = (props: WeatherProps) => {
  const { sites, site } = useAppState()
  const extra = sites.sites?.find((s) => s.s.id === site.site?.id)

  // Get place name.
  const context = site?.site?.address?.context || []
  const locality = context.find((x) => x.id.startsWith('locality'))
  const neighborhood = context.find((x) => x.id.startsWith('neighborhood'))
  const place = context.find((x) => x.id.startsWith('place'))
  const suburb = neighborhood?.text || locality?.text || place?.text || site?.site?.address?.place_name || ''

  // Get todays forecast item.
  const hourly = props.weather.hourly?.items || []
  const daily = props.weather.daily?.items || []

  if (hourly.length === 0 && daily.length === 0) {
    return (
      <div className='weather-sidebar'>
        <div className='weather-sidebar-loading'>
          <img src={`https://openweathermap.org/img/wn/10d@2x.png`} />
          <div>
            Weather data is
            <br />
            currently loading.
            <br />
            <br />
          </div>
        </div>
      </div>
    )
  }

  const now = dayjs()
  let hourIndex = hourly.findIndex((x) => {
    const hourDate = dayjs(x.timestamp / 1000 / 1000)
    return now.isBefore(hourDate)
  })
  if (hourIndex !== 0) {
    hourIndex--
  }
  const hour = hourly[hourIndex]
  const today = daily.length > 0 && daily[0]
  const dailyItems = [...daily]

  const forecastDescription = hour?.forecastDescription || today?.forecastDescription || 'Clear'
  const iconCode = hour?.forecastIcon || today?.forecastIcon || '10'
  const currentTemp = Math.round(hour?.temp || today?.tempDay || 15)
  const time = ceilDate(dayjs((hour?.timestamp || today?.timestamp || 0) / 1000 / 1000))
  const windSpeed = hour?.windSpeed || today?.windSpeed || 0
  const gustSpeed = hour?.windGust || today?.windGust || 0
  const windDeg = hour?.windDeg || today?.windDeg || 0
  const rainMM = hour?.rainMM || today?.rainMM || 0
  const snowMM = hour?.snowMM || today?.snowMM || 0
  const haveRain = true // Math.round(rainMM) > 0
  const haveSnow = Math.round(snowMM) > 0

  return (
    <SimpleBar>
      <div className='weather-sidebar'>
        <div className='weather-sidebar-heading'>
          <div className='weather-sidebar-heading-left'>
            <div className='weather-sidebar-heading-left-today'>{hour ? 'Today' : 'Today'}</div>
            <div className='weather-sidebar-heading-left-location'>{suburb}</div>
            <div className='weather-sidebar-heading-left-time'>
              {forecastDescription
                .replace(' intensity ', ' ')
                .replace(': 85-100%', '')
                .replace(': 51-84%', '')
                .replace(': 25-50%', '')
                .replace(': 11-25%', '')}
              <br />
              {time.format('HH:mm')} • {time.format('DD/MM')}
            </div>
            <div className='weather-sidebar-heading-left-temp'>{formatTemp(currentTemp, true)}</div>
          </div>
          <div className='weather-sidebar-heading-right'>
            <img src={formatWeatherIcon(iconCode, extra?.extra?.center)} />
          </div>
        </div>
        <div className='weather-sidebar-details'>
          <table>
            <tr>
              <td>
                <i className='material-icons' style={{ transform: `rotate(${windDeg}deg)` }}>
                  north
                </i>
              </td>
              <td>
                <i className='material-icons'>air</i>
              </td>
              {haveRain && (
                <td>
                  <i className='material-icons'>water_drop</i>
                </td>
              )}
              {haveSnow && (
                <td>
                  <i className='material-icons'>ac_unit</i>
                </td>
              )}
            </tr>
            <tr>
              <td>{formatWindSpeed(windSpeed, true)}</td>
              <td>{formatWindSpeed(gustSpeed, true)}</td>
              {haveRain && <td>{formatRain(rainMM, true)}</td>}
              {haveSnow && <td>{formatRain(snowMM, true, true)}</td>}
            </tr>
          </table>

          {/* <div>
            <i className='material-icons'>air</i>
            {formatWindSpeed(windSpeed, true)}
          </div>
          <div>
            <i className='material-icons'>wind_power</i>
            {formatWindSpeed(gustSpeed, true)}
          </div>
          <div>
            <i className='material-icons'>water_drop</i>
            {formatRain(rainMM, true, true)}
          </div>
          <div>
            <i className='material-icons'>ac_unit</i>
            {formatRain(snowMM, true, true)}
          </div> */}
        </div>
        <div className='weather-sidebar-forecast'>
          {dailyItems.length > 0 &&
            dailyItems.map((w) => {
              const time = dayjs(w.timestamp / 1000 / 1000)
              const rain: number = formatRain(w.rainMM, false) as number
              return (
                <div className='weather-sidebar-forecast-item'>
                  <div className='weather-sidebar-forecast-item-date'>{time.format('ddd')}</div>
                  <div className='weather-sidebar-forecast-item-rain' title='Probability of rain'>
                    {w.rainMM === 0 ? (
                      '-'
                    ) : (
                      <span>
                        {rain}
                        <span>&nbsp;mm</span>
                      </span>
                    )}
                  </div>
                  <div className='weather-sidebar-forecast-item-icon' title={w.forecastDescription}>
                    <img src={formatWeatherIcon(w.forecastIcon, extra?.extra?.center)} />
                  </div>
                  <div className='weather-sidebar-forecast-item-min'>{formatTemp(w.tempMin, true)}</div>
                  <div className='weather-sidebar-forecast-item-max'>{formatTemp(w.tempMax, true)}</div>
                </div>
              )
            })}
          {dailyItems.length === 0 && (
            <div className='weather-sidebar-loading'>
              <div>
                Daily forecast data
                <br />
                is currently loading.
              </div>
            </div>
          )}
        </div>
        {/* {dailyItems.length > 0 && hourly.length > 0 && (
          <div
            className='weather-sidebar-view-detailed'
            onClick={() => {
              props.setView(ViewStateType.Weather)
              drawer.rightExpanded ? null : drawer.toggleRight()
              drawer.rightClosed ? drawer.set(drawer.left, DrawerState.Expanded) : null
            }}
          >
            View detailed forecast
          </div>
        )} */}
      </div>
    </SimpleBar>
  )
}
