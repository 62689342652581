import React, { useMemo, useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { SiteQueryMonitoringZone } from '~/models'
import { useAppState } from '~/state'
import { DrawerPopoutList, DrawerPopoutListCard } from '../drawer'
import { SearchBox } from '../forms'
import { calculatePolygonArea } from '../helpers'
import { handleAreaFormat } from '../measure'

interface MonitoringZonesFullListProps {
  monitoringZones: SiteQueryMonitoringZone[]
}

export const MonitoringZonesFullList = (props: MonitoringZonesFullListProps) => {
  const { monitoringZoneState } = useAppState()
  const [search, setSearch] = useState<string | undefined>()

  const monitoringZones = useMemo(() => {
    const lowerSearch = (search || '').toLocaleLowerCase()
    const sorted = [...props.monitoringZones]
      .filter((a) => {
        if (lowerSearch === '') {
          return true
        }

        return a.name.toLocaleLowerCase().includes(lowerSearch)
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    return sorted
  }, [props.monitoringZones, search])

  const Controls = useMemo(() => {
    return <SearchBox search={search} setSearch={setSearch} />
  }, [search])

  return (
    <DrawerPopoutList
      width={360}
      title='Monitoring Zones'
      total={props.monitoringZones.length}
      count={monitoringZones.length}
      emptyText='There are no monitoring zones to display.'
      controls={Controls}
    >
      {monitoringZones.map((m) => {
        return (
          <MonitoringZoneCard
            monitoringZone={m}
            setMonitoringZoneToEdit={monitoringZoneState.setMonitoringZoneToEdit}
            setMonitoringZoneToRemove={monitoringZoneState.setMonitoringZoneToDelete}
            active={
              m.id === monitoringZoneState.monitoringZoneToEdit?.id ||
              m.id === monitoringZoneState.monitoringZoneToDelete?.id ||
              m.id === monitoringZoneState.selectedMonitoringZone?.id
            }
          />
        )
      })}
    </DrawerPopoutList>
  )
}

interface MonitoringZoneCardProps {
  active: boolean
  monitoringZone: SiteQueryMonitoringZone
  setMonitoringZoneToEdit: (b: SiteQueryMonitoringZone) => void
  setMonitoringZoneToRemove: (b: SiteQueryMonitoringZone) => void
}

function MonitoringZoneCard(props: MonitoringZoneCardProps) {
  const user = useUser()
  const { view, timeline, site, monitoringZoneState } = useAppState()
  const isDemoSite = site.site?.isDemoSite
  const canEdit = timeline.activeSurvey && !isDemoSite && user.hasPermission(ClientPermission.BoundariesUpdate)
  const canRemove = timeline.activeSurvey && !isDemoSite && user.hasPermission(ClientPermission.BoundariesRemove)
  const m = props.monitoringZone
  const area = handleAreaFormat(
    calculatePolygonArea(m.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0)))
  )
  return (
    <DrawerPopoutListCard
      key={m.id + view.surveyID + view.assetID}
      className='mz-card'
      active={props.active}
      onClick={() => {
        monitoringZoneState.setSelectedMonitoringZone(m)
      }}
    >
      <div className='mz-card-upper'>
        <div>{m.name}</div>
        <div>{area}</div>
        <div>
          {canEdit && (
            <i
              title='Update'
              className='material-icons'
              onClick={(e) => {
                e.preventDefault()
                props.setMonitoringZoneToEdit(m)
              }}
            >
              edit
            </i>
          )}
          {canRemove && (
            <i
              title='Remove'
              className='material-icons'
              onClick={(e) => {
                e.preventDefault()
                props.setMonitoringZoneToRemove(m)
              }}
            >
              delete
            </i>
          )}
        </div>
      </div>
      <div className='mz-card-lower'>
        {m.targetName === '' ? 'No target' : m.targetName}
        {m.subTargetName === '' ? '' : ' - ' + m.subTargetName}
      </div>
    </DrawerPopoutListCard>
  )
}
