import { Map } from './polygon-renderer'

export abstract class PolygonRendererWithoutEvents {
  protected _map: Map
  protected _color: string

  constructor(map: Map, color: string) {
    this._map = map
    this._color = color
  }

  public destroy() {
    document.body.style.cursor = 'default'
  }

  protected createPoint(pos: Cesium.Cartesian3, pointIndex: number, pointSize = 8.0) {
    return {
      show: true,
      position: pos,
      pixelSize: pointSize,
      color: Cesium.Color.fromCssColorString(this._color),
      outlineColor: Cesium.Color.TRANSPARENT,
      outlineWidth: 0.0,
      id: 'point-' + pointIndex,
    }
  }

  protected createMiddlePoint(pos: Cesium.Cartesian3, pointIndex: number) {
    return {
      show: true,
      position: pos,
      pixelSize: 3.0,
      color: Cesium.Color.WHITE,
      outlineColor: Cesium.Color.fromCssColorString(this._color),
      outlineWidth: 3,
      id: 'middle-point-' + pointIndex,
    }
  }

  protected createPolylineConstructor(
    positions: Cesium.Property | Cesium.Cartesian3[],
    dashed = false
  ): Cesium.PolylineGraphics.ConstructorOptions {
    return {
      show: true,
      positions,
      material: dashed
        ? new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            gapColor: Cesium.Color.TRANSPARENT,
            dashLength: 10,
          })
        : new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            outlineColor: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
            outlineWidth: 2,
          }),
      width: dashed ? 3 : 4,
      clampToGround: true,
    }
  }
}
