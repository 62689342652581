import React, { useState } from 'react'
import AUTH_STATUS_QUERY from '~/auth/query-auth-status.gql'
import { useUser } from '~/base'
import { Switch, useQuery, useMutation } from '~/components'
import { AuthStatusQuery, SetRequire2FaMutation, SetRequire2FaMutationVariables, SystemRole } from '~/models'
import SET_REQUIRE_2FA from './mutation-requires-2fa.gql'

export const AdvancedAccess2FA = () => {
  const user = useUser()
  const [require2FA, setRequire2FA] = useState(user.org.requires2FA)
  const authStatusQuery = useQuery<AuthStatusQuery>(AUTH_STATUS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const [executeRequires2FA] = useMutation<SetRequire2FaMutation, SetRequire2FaMutationVariables>(SET_REQUIRE_2FA)
  const cantChange2FA = user.org.requires2FA && user.systemRole !== SystemRole.Staff
  
  return (
    <p style={{ paddingRight: '290px' }}>
      <Switch
        name='force-2fa'
        label='Require 2FA for all users'
        checked={require2FA}
        disabled={cantChange2FA}
        onChange={() => {
          setRequire2FA(!require2FA)
          executeRequires2FA({
            variables: {
              input: {
                requires2FA: !require2FA,
              },
            },
          })
            .then(() => {
              return authStatusQuery
                .refetch()
                .then((res) => {
                  user.setSignIn(res.data.authStatus)
                  setRequire2FA(res.data.authStatus.requires2FA)
                })
                .catch(() => {
                  window.location.pathname = '/signed-out'
                })
            })
        }}
      />
      {cantChange2FA && <small className='advanced-access-cant-disable-2fa'>Contact Asseti to disable 2FA</small>}
    </p>
  )
}
