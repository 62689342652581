import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  HTMLAttributes,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  KeyboardEventHandler,
  useState,
} from 'react'
import './input.scss'

type Props = {
  value?: HTMLAttributes<HTMLInputElement>['defaultValue']
  type?: HTMLInputTypeAttribute
  placeholder?: string
  id?: string
  label?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  inputRef?: React.RefObject<HTMLInputElement>
  readOnly?: boolean
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
  helperText?: string
  error?: string
  size?: 'small' | 'medium' | 'large'
  icon?: React.ReactNode
  clearable?: boolean
}

export const Input = ({
  value,
  type = 'text',
  id,
  label,
  inputRef,
  readOnly,
  helperText,
  error,
  size = 'medium',
  icon,
  onChange,
  clearable = false,
  ...others
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const isPasswordType = type === 'password'
  const [inputValue, setInputValue] = useState(value || '')

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const handleClear = () => {
    setInputValue('')
    if (onChange) {
      const event = {
        target: { value: '' },
      } as unknown as React.ChangeEvent<HTMLInputElement>
      onChange(event)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <div className={`ui-input ${size} ${error ? 'input-error' : ''} ${readOnly ? 'disabled' : ''}`}>
      {label && (
        <label htmlFor={id} className='input-label'>
          {label}
        </label>
      )}
      <div className={`input-wrapper ${icon ? 'has-icon' : ''}`}>
        {icon && <span className='input-icon'>{icon}</span>}
        <input
          type={isPasswordType && showPassword ? 'text' : type}
          className='input-field'
          value={inputValue}
          ref={inputRef}
          disabled={readOnly}
          onChange={handleChange}
          {...others}
        />
        {isPasswordType && (
          <button type='button' className='input-action' onClick={togglePasswordVisibility}>
            <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden='true' />
          </button>
        )}

        {clearable && inputValue && !readOnly && !isPasswordType && (
          <button type='button' className='input-action' onClick={handleClear}>
            <i className={`fa fa-light fa-xmark`} aria-hidden='true' />
          </button>
        )}
      </div>
      {helperText && !error && <div className='helper-text'>{helperText}</div>}
      {error && <div className='error-text'>{error}</div>}
    </div>
  )
}
