function calculateAllCrossProduct(points: number[][]) {
  let lastSign = null

  for (let i = 2; i < points.length; i++) {
    //calculate crossproduct from 3 consecutive points
    const crossproduct = calculateCrossProduct(points[i - 2], points[i - 1], points[i])
    const currentSign = Math.sign(crossproduct)
    if (lastSign == null) {
      //last sign init
      lastSign = currentSign
    }

    const checkResult = checkCrossProductSign(lastSign, currentSign)
    if (checkResult == false) {
      //different sign in cross products,no need to check the remaining points --> concave polygon --> return function
      return false
    }
    lastSign = currentSign
  }

  //first point must check between second and last point, this is the last 3 points that can break convexity
  const crossProductFirstPoint = calculateCrossProduct(points[points.length - 2], points[0], points[1])
  return checkCrossProductSign(lastSign, Math.sign(crossProductFirstPoint))
}

function checkCrossProductSign(lastSign: number, newSign: number) {
  if (lastSign !== newSign) {
    //checked sign differs from the previous one --> concave polygon
    return false
  }
  return true
}

function calculateCrossProduct(p1: number[], p2: number[], p3: number[]) {
  const dx1 = p2[0] - p1[0]
  const dy1 = p2[1] - p1[1]
  const dx2 = p3[0] - p2[0]
  const dy2 = p3[1] - p2[1]

  const zcrossproduct = dx1 * dy2 - dy1 * dx2
  return zcrossproduct
}

export function isPolygonConvex(points: number[][]) {
  return calculateAllCrossProduct(points)
}
