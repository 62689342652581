import React, { DragEvent } from 'react'
import { ClientPermission, useUser } from '~/base'
import CHEVRONS from '../../images/chevrons-right.svg'
import { FileSelector } from './upload-panel-file-selector'

interface UploadPanelBoxProps {
  setDragOver: (val: boolean) => void
  toggleOpen: () => void
  filesSelected: (files: File[]) => void
}

export const UploadPanelBox = (props: UploadPanelBoxProps) => {
  const user = useUser()
  // Taken from https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files: File[] = []

    if (event.dataTransfer.items) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile() as File
          files.push(file)
        }
      }

      event.dataTransfer.items.clear()
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        files.push(event.dataTransfer.files[i])
      }

      event.dataTransfer.clearData()
    }

    props.filesSelected(files)
    props.setDragOver(false)
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    props.setDragOver(true)
    return false
  }

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    props.setDragOver(true)
    return false
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    props.setDragOver(false)
    return false
  }

  const havePermissionToUpload = user.hasPermission(ClientPermission.DataUpload)

  return (
    <div className='upload-panel-box'>
      <div className='upload-panel-box-icon' onClick={props.toggleOpen}>
        <img src={CHEVRONS} />
      </div>
      {havePermissionToUpload && (
        <div
          className='upload-panel-box-content'
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
        >
          <div className='material-icons-outlined'>cloud_upload</div>
          <div className='overline'>DROP FILE TO UPLOAD, OR</div>
          <div>
            <FileSelector filesSelected={props.filesSelected} />
          </div>
        </div>
      )}
      {!havePermissionToUpload && (
        <div className='upload-panel-box-content'>
          <div className='material-icons-outlined'>cloud_upload</div>
          <div className='overline'>Data uploads are disabled for this account. Please contact your workspace administrator</div>
        </div>
      )}
    </div>
  )
}
