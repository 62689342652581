import React, { useEffect, useState } from 'react'
import { useUser } from '~/base'
import {
  AdminRoofTypeInputT,
  AdminRoofTypeSetCreateOrUpdateMutation,
  AdminRoofTypeSetCreateOrUpdateMutationVariables,
  AdminRoofTypeSetsQuery,
  AdminRoofTypeSetsQueryVariables,
  AdminRoofType,
  SiteQueryAsset,
} from '~/models'
import { ProjectionType, useAppState, useRefState } from '~/state'
import ROOF_TYPE_SETS_QUERY from './query-admin-roof-type-sets.gql'
import ROOF_TYPE_SETS_SAVE_MUTATION from './mutation-admin-roof-type-sets-create-or-update.gql'
import { useMutation, useQuery, useToasts } from '~/components'

interface TypesByAssetID {
  [assetID: string]: AdminRoofTypeInputT
}

export const ToolsRoofTypes = () => {
  const { map, timeline, site, issues } = useAppState()
  const [typesByAsset, setTypesByAsset] = useRefState<TypesByAssetID>()
  const user = useUser()
  const activeSurvey = timeline.activeSurvey
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>()
  const [setID, setSetID] = useState<string>()
  const [saving, setSaving] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [assets] = useState([...(site.site?.assets || [])].sort((a, b) => (a.number < b.number ? 1 : -1)))
  const toasts = useToasts()
  const typesQuery = useQuery<AdminRoofTypeSetsQuery, AdminRoofTypeSetsQueryVariables>(ROOF_TYPE_SETS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })
  const [executeSave] = useMutation<
    AdminRoofTypeSetCreateOrUpdateMutation,
    AdminRoofTypeSetCreateOrUpdateMutationVariables
  >(ROOF_TYPE_SETS_SAVE_MUTATION)

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      const setAsset = (a: SiteQueryAsset) => {
        const marker = map.viewer.entities.getById('roof-type-' + selectedAsset?.id)
        const line = map.viewer.entities.getById('roof-type-line-' + selectedAsset?.id)
        if (marker && line) {
          marker.label.backgroundColor = Cesium.Color.RED as any as Cesium.Property
          line.polyline.material = Cesium.Color.RED as any as Cesium.MaterialProperty
        }
        setSelectedAsset(a)

        const points: Cesium.Cartographic[] = []

        for (const boundary of a.boundaries) {
          for (const p of boundary.points) {
            points.push(
              Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
            )
          }
        }

        if (points.length > 0) {
          const marker = map.viewer.entities.getById('roof-type-' + a.id)
          const line = map.viewer.entities.getById('roof-type-line-' + a.id)
          if (marker && line) {
            marker.label.backgroundColor = Cesium.Color.BLUE as any as Cesium.Property
            line.polyline.material = Cesium.Color.BLUE as any as Cesium.MaterialProperty
          }

          const destinationCarto = Cesium.Rectangle.center(Cesium.Rectangle.fromCartographicArray(points))
          const samplePositions = [destinationCarto]

          Cesium.sampleTerrain(map.viewer.terrainProvider, 11, samplePositions).then(() => {
            const base = samplePositions[0].clone()
            samplePositions[0].height += 100

            const origin = Cesium.Cartographic.toCartesian(samplePositions[0])
            const direction = new Cesium.Cartesian3()
            Cesium.Cartesian3.subtract(Cesium.Cartographic.toCartesian(base), origin, direction)
            Cesium.Cartesian3.normalize(direction, direction)
            const ray = new Cesium.Ray(origin, direction)
            const intersection = map.viewer.scene.pickFromRay(ray, [], 1)

            const dest = Cesium.Cartographic.fromCartesian(intersection.position.clone())
            dest.height -= 2

            map.viewer.camera.flyTo({
              destination: Cesium.Cartographic.toCartesian(dest),
              duration: 0,
              orientation: Cesium.HeadingPitchRoll.fromDegrees(0, -30, 0),
            })
            map.viewer.camera.moveBackward(60)
          })
        }
      }

      const key = e.key.toLocaleLowerCase()
      if (key === 'n') {
        if (selectedAsset) {
          const assetIndex = assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === assets.length - 1) {
            setAsset(assets[0])
          } else {
            setAsset(assets[assetIndex + 1])
          }
        } else {
          if (assets.length > 0) {
            setAsset(assets[0])
          }
        }
      } else if (key === 'p') {
        if (selectedAsset) {
          const assetIndex = assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === 0) {
            setAsset(assets[assets.length - 1])
          } else {
            setAsset(assets[assetIndex - 1])
          }
        } else {
          if (assets.length > 0) {
            setAsset(assets[0])
          }
        }
      } else if (key === 't') {
        if (selectedAsset) {
          typesByAsset.current[selectedAsset.id].type = AdminRoofType.Pitched
          const marker = map.viewer.entities.getById('roof-type-' + selectedAsset.id)
          marker.label.text = AdminRoofType.Pitched as any
          setTypesByAsset({ ...typesByAsset.current })
        }
      } else if (key === 'f') {
        if (selectedAsset) {
          typesByAsset.current[selectedAsset.id].type = AdminRoofType.Flat
          const marker = map.viewer.entities.getById('roof-type-' + selectedAsset.id)
          marker.label.text = AdminRoofType.Flat as any
          setTypesByAsset({ ...typesByAsset.current })
        }
      } else if (key === 'c') {
        if (selectedAsset) {
          typesByAsset.current[selectedAsset.id].type = AdminRoofType.Curved
          const marker = map.viewer.entities.getById('roof-type-' + selectedAsset.id)
          marker.label.text = AdminRoofType.Curved as any
          setTypesByAsset({ ...typesByAsset.current })
        }
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [selectedAsset, assets])

  useEffect(() => {
    if (!site.site || map.projectionType === ProjectionType.Projection2D || !activeSurvey) {
      return
    }

    issues.setShow(false)
    const markers: Cesium.Entity[] = []

    const cancelled = {
      cancelled: false,
    }

    typesQuery
      .refetch({
        input: {
          orgID: user.org.id,
          siteIDs: [site.site.id],
        },
      })
      .then((res) => {
        const set = res.data.adminRoofTypeSets.find(
          (x) => x.siteID === site.site.id && x.surveyID === timeline.activeSurvey.id
        )
        if (set) {
          setSetID(set.id)
        } else {
          setSetID(undefined)
        }

        const allLines: TypesByAssetID = {}

        for (const asset of site.site.assets) {
          if (set) {
            const measurements = set.types.find((x) => x.assetID === asset.id)
            if (measurements) {
              allLines[asset.id] = { ...measurements }
              continue
            }
          }

          if (asset.boundaries.length === 0) {
            continue
          }

          allLines[asset.id] = {
            assetID: asset.id,
            id: '',
            type: AdminRoofType.Unknown,
          }
        }

        setTypesByAsset(allLines)

        for (const assetID of Object.keys(allLines)) {
          const asset = site.site.assets.find((a) => a.id === assetID)
          if (!asset) {
            continue
          }
          const points: Cesium.Cartographic[] = []

          for (const boundary of asset.boundaries) {
            for (const p of boundary.points) {
              points.push(
                Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
              )
            }
          }

          if (points.length > 0) {
            const center = Cesium.Rectangle.center(Cesium.Rectangle.fromCartographicArray(points))
            const samples = [center.clone()]
            Cesium.sampleTerrain(map.viewer.terrainProvider, 11, samples).then(() => {
              if(cancelled.cancelled) {
                return
              }
              const base = samples[0].clone()
              samples[0].height += 100

              const origin = Cesium.Cartographic.toCartesian(samples[0])
              const direction = new Cesium.Cartesian3()
              Cesium.Cartesian3.subtract(Cesium.Cartographic.toCartesian(base), origin, direction)
              Cesium.Cartesian3.normalize(direction, direction)
              const ray = new Cesium.Ray(origin, direction)
              const intersection = map.viewer.scene.pickFromRay(ray, [], 1)

              const dest = Cesium.Cartographic.fromCartesian(intersection.position.clone())
              dest.height += 2

              const label = map.viewer.entities.add({
                id: 'roof-type-' + assetID,
                position: Cesium.Cartographic.toCartesian(dest),
                label: {
                  text: allLines[assetID].type,
                  font: '12px Roboto',
                  backgroundColor: Cesium.Color.fromCssColorString('#f00'),
                  showBackground: true,
                  fillColor: Cesium.Color.WHITE,
                  horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                  verticalOrigin: Cesium.VerticalOrigin.CENTER,
                  style: Cesium.LabelStyle.FILL,
                  //eyeOffset: new Cesium.Cartesian3(0, 15, 0),
                  heightReference: Cesium.HeightReference.NONE,
                },
              })

              const line = map.viewer.entities.add({
                id: 'roof-type-line-' + assetID,
                polyline: {
                  positions: [Cesium.Cartographic.toCartesian(dest), Cesium.Cartographic.toCartesian(base)],
                  width: 5,
                  arcType: Cesium.ArcType.NONE,
                  material: Cesium.Color.RED,
                  clampToGround: false,
                },
              })
              markers.push(line)
              markers.push(label)
            })
          }
        }
      })

    setSaved(false)

    return () => {
      cancelled.cancelled = true
      issues.setShow(true)
      markers.forEach((m) => map.viewer.entities.remove(m))
    }
  }, [site.site?.id, map.projectionType, site.site?.boundaries.length, saved])

  const save = () => {
    setSaving(true)

    const types = []
    for (const assetID in typesByAsset.current) {
      types.push(typesByAsset.current[assetID])
    }

    executeSave({
      variables: {
        input: {
          id: setID || '',
          orgID: user.org.id,
          siteID: site.site.id,
          surveyID: timeline.activeSurvey.id,
          types,
        },
      },
    })
      .then(() => {
        setSaved(true)
        toasts.addTopLeft('Roof types saved')
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const assetIndex = assets.findIndex((x) => x.id === selectedAsset?.id)

  return (
    <>
      <button
        className='btn btn-sm'
        disabled={saving}
        onClick={() => {
          save()
        }}
      >
        {saving ? 'Saving' : 'Save'}
      </button>
      <br />
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Current Asset: <b>{selectedAsset?.name || 'None'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Asset number{' '}
        <b>
          {assetIndex === -1 ? '-' : assetIndex + 1} of {assets.length}
        </b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Roof Type: <b>{typesByAsset.current && typesByAsset.current[selectedAsset?.id || '']?.type}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem', opacity: 0.75 }}>
        <br />
        <small>n = next</small>
        <br />
        <small>p = previous</small>
        <br />
        <small>t = pitched</small>
        <br />
        <small>f = flat</small>
        <br />
        <small>c = curved</small>
      </div>
    </>
  )
}
