import React, { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { FillColor, getMidpoint, LabelTemplate, Point, PointAndLineColor, pointFromPosition } from './utils'
import { formatArea, formatDistance, getDistance } from './measurement-formats'
import { useUser } from 'base'

export const MeasureArea2D = () => {
  const { map } = useAppState()
  const user = useUser()
  const [points] = useState(new Cesium.PointPrimitiveCollection())
  const [tmpPoints] = useState(new Cesium.PointPrimitiveCollection())
  const [polyLines] = useState(new Cesium.PolylineCollection())
  const [tmpPolyLines] = useState(new Cesium.PolylineCollection())
  const [labels] = useState(
    new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
  )

  useEffect(() => {
    const viewer = map.viewer
    const scene = viewer.scene
    const geodesic = new Cesium.EllipsoidGeodesic()
    let fillPolygon: Cesium.Entity
    let fillPolygonClamped: Cesium.Entity

    let isDrawing = true
    let labelIndex = 3

    scene.primitives.add(points)
    scene.primitives.add(tmpPoints)
    scene.primitives.add(polyLines)
    scene.primitives.add(tmpPolyLines)
    scene.primitives.add(labels)

    setTimeout(() => {
      for (let i = 0; i < 100; i++) {
        polyLines.add({
          show: false,
          positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(0, 0, 0)],
          width: 1,
          material: new Cesium.Material({
            fabric: {
              type: 'Color',
              uniforms: {
                color: PointAndLineColor,
              },
            },
          }),
        })

        labels.add({
          ...LabelTemplate,
          position: new Cesium.Cartesian3(0, 0, 0),
        })
      }

      tmpPolyLines.add({
        show: false,
        positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(0, 0, 0)],
        width: 1,
        material: new Cesium.Material({
          fabric: {
            type: 'PolylineDash',
            uniforms: {
              color: PointAndLineColor,
            },
          },
        }),
      })

      const cb = new Cesium.CallbackProperty(() => {
        if (points.length < 2) {
          return new Cesium.PolygonHierarchy()
        }

        const polyPoints = []

        for (let i = 0; i < points.length; i++) {
          const p = points.get(i).position
          const p2 = Cesium.Cartographic.fromCartesian(p)
          polyPoints.push(Cesium.Cartesian3.fromRadians(p2.longitude, p2.latitude, p2.height + 0.075))
        }

        if (isDrawing) {
          const p = tmpPoints.get(0).position
          const p2 = Cesium.Cartographic.fromCartesian(p)
          polyPoints.push(Cesium.Cartesian3.fromRadians(p2.longitude, p2.latitude, p2.height + 0.075))
        }

        return new Cesium.PolygonHierarchy(polyPoints)
      }, false)

      fillPolygon = viewer.entities.add({
        polygon: {
          hierarchy: cb,
          perPositionHeight: true,
          material: FillColor,
          outline: true,
          outlineColor: PointAndLineColor,
          outlineWidth: 1,
        },
        show: true,
      })

      fillPolygonClamped = viewer.entities.add({
        polygon: {
          hierarchy: cb,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          material: FillColor,
          outline: true,
          outlineColor: PointAndLineColor,
          outlineWidth: 1,
        },
        show: false,
      })
    }, 0)

    function addDistanceLabel(point1: Cesium.PointPrimitive, point2: Cesium.PointPrimitive, pointIndex?: number) {
      let height = 0
      const c1 = Cesium.Cartographic.fromCartesian(point1.position)
      const c2 = Cesium.Cartographic.fromCartesian(point2.position)
      if (c2.height >= c1.height) {
        height = c1.height + (c2.height - c1.height) / 2.0
      } else {
        height = c2.height + (c1.height - c2.height) / 2.0
      }

      const distance = getDistance(geodesic, point1.position, point2.position)
      const label = labels.get(pointIndex || labelIndex)
      label.text = formatDistance(distance)
      label.position = getMidpoint(geodesic, point1, point2, height)

      if (!pointIndex) {
        labelIndex++
      }
    }

    function calculateArea() {
      if (isDrawing) {
        if (points.length < 2) {
          return 0
        }
      }

      const p0 = points.get(0).position
      const total = new Cesium.Cartesian3()
      for (let i = 1; i < points.length - 1; i++) {
        const p1 = points.get(i).position
        const p2 = points.get(i + 1).position

        const s1 = Cesium.Cartesian3.multiplyByScalar(
          Cesium.Cartesian3.subtract(p0, p1, new Cesium.Cartesian3()),
          0.5,
          new Cesium.Cartesian3()
        )
        const s2 = Cesium.Cartesian3.subtract(p0, p2, new Cesium.Cartesian3())
        const x = Cesium.Cartesian3.cross(s1, s2, new Cesium.Cartesian3())
        Cesium.Cartesian3.add(total, x, total)
      }

      if (isDrawing) {
        const p1 = points.get(points.length - 1).position
        const p2 = tmpPoints.get(0).position

        const s1 = Cesium.Cartesian3.multiplyByScalar(
          Cesium.Cartesian3.subtract(p0, p1, new Cesium.Cartesian3()),
          0.5,
          new Cesium.Cartesian3()
        )
        const s2 = Cesium.Cartesian3.subtract(p0, p2, new Cesium.Cartesian3())
        const x = Cesium.Cartesian3.cross(s1, s2, new Cesium.Cartesian3())
        Cesium.Cartesian3.add(total, x, total)
      }

      return Cesium.Cartesian3.magnitude(total)
    }

    function drawArea() {
      let count = 0
      const total = new Cesium.Cartesian3()

      for (let i = 0; i < points.length; i++) {
        const p1 = points.get(i).position
        count++
        Cesium.Cartesian3.add(total, p1, total)
      }

      if (isDrawing) {
        const p1 = tmpPoints.get(0).position
        count++
        Cesium.Cartesian3.add(total, p1, total)
      }

      const centerOfGravity = Cesium.Cartesian3.divideByScalar(total, count, new Cesium.Cartesian3())

      const area = calculateArea()
      const label = labels.get(0)
      label.position = centerOfGravity
      label.text = formatArea(area, false, user.org.measurementSystem)
      label.font = '14px monospace'
      label.pixelOffset = new Cesium.Cartesian2(0, 0)
      label.eyeOffset = new Cesium.Cartesian3(0.25, 0.5, -1)

      for (let i = 0; i < labelIndex; i++) {
        labels.get(i).eyeOffset = new Cesium.Cartesian3(0.25, Math.min(Math.max(area / 1000, 0.5), 15), -1)
      }
    }

    function anyOnModel() {
      if (isDrawing && tmpPoints.length > 0) {
        if ((tmpPoints.get(0) as Point).isOnModel) {
          return true
        }
      }

      for (let i = 0; i < points.length; i++) {
        if ((points.get(i) as Point).isOnModel) {
          return true
        }
      }

      return false
    }

    function updatePolygons() {
      if (anyOnModel()) {
        fillPolygon.show = true
        fillPolygonClamped.show = false
      } else {
        fillPolygon.show = false
        fillPolygonClamped.show = true
      }
    }

    function reset() {
      points.removeAll()
      tmpPoints.removeAll()
      tmpPolyLines.get(0).show = false
      for (let i = 0; i < polyLines.length; i++) {
        polyLines.get(i).show = false
      }

      for (let i = 0; i < labels.length; i++) {
        labels.get(i).position = new Cesium.Cartesian3(0, 0, 0)
      }
      labelIndex = 3
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        reset()
      } else if (e.key === 'Enter') {
        if (points.length >= 2) {
          isDrawing = false
          for (let i = 0; i < points.length; i++) {
            points.get(i).show = false
          }

          tmpPolyLines.get(0).show = false
          tmpPoints.get(0).show = false

          const p1 = points.get(0)
          const p2 = points.get(points.length - 1)
          addDistanceLabel(p1, p2)
          updatePolygons()
          drawArea()

          labels.get(1).position = new Cesium.Cartesian3(0, 0, 0)
          labels.get(2).position = new Cesium.Cartesian3(0, 0, 0)
        }
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    // Mouse over the globe to see the cartographic position
    let leftClickHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported) {
        return
      }

      const cartesian = map.viewer.camera.pickEllipsoid(click.position, map.viewer.scene.globe.ellipsoid)
      if (!Cesium.defined(cartesian)) {
        return
      }

      if (!isDrawing) {
        reset()
        isDrawing = true
        if (tmpPoints.length === 0) {
          const p = tmpPoints.add(
            pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
          ) as Point
          p.isOnModel = true
        }
      }

      if (points.length === 0) {
        // Just add the first point.
        const p = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
        p.isOnModel = true
        updatePolygons()
        return
      }

      // Add the point and the next line.
      const p = points.add(pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))) as Point
      p.isOnModel = true

      const p1 = points.get(points.length - 2)
      const p2 = points.get(points.length - 1)
      addDistanceLabel(p1, p2)
      updatePolygons()

      labels.get(1).position = new Cesium.Cartesian3(0, 0, 0)
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    let mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    mouseMoveHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (scene.mode === Cesium.SceneMode.MORPHING || !scene.pickPositionSupported || !isDrawing) {
        return
      }

      const cartesian = map.viewer.camera.pickEllipsoid(click.endPosition, map.viewer.scene.globe.ellipsoid)
      if (!Cesium.defined(cartesian)) {
        return
      }
      // We haven't added any points yet.
      if (points.length === 0) {
        if (tmpPoints.length === 0) {
          const p = tmpPoints.add(
            pointFromPosition(new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z))
          ) as Point

          p.isOnModel = true
        } else {
          ;(tmpPoints.get(0) as Point).isOnModel = true
          tmpPoints.get(0).position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
        }
      } else {
        ;(tmpPoints.get(0) as Point).isOnModel = true
        tmpPoints.get(0).position = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z)
        if (points.length === 1) {
          const polyline = tmpPolyLines.get(0)
          polyline.show = true
          polyline.positions = [points.get(points.length - 1).position, tmpPoints.get(0).position]
        } else {
          const polyline = tmpPolyLines.get(0)
          polyline.show = false

          drawArea()

          addDistanceLabel(points.get(points.length - 1), tmpPoints.get(0), 1)
          addDistanceLabel(tmpPoints.get(0), points.get(0), 2)
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      viewer.entities.remove(fillPolygon)
      viewer.entities.remove(fillPolygonClamped)
      scene.primitives.remove(points)
      scene.primitives.remove(tmpPoints)
      scene.primitives.remove(polyLines)
      scene.primitives.remove(tmpPolyLines)
      scene.primitives.remove(labels)
      if (leftClickHandler) {
        leftClickHandler.destroy()
        leftClickHandler = undefined
      }
      if (mouseMoveHandler) {
        mouseMoveHandler.destroy()
        mouseMoveHandler = undefined
      }
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return <></>
}
