import { useCallback, useEffect, useState } from 'react'
import { ulid } from 'ulid'
import { useQuery } from '~/components'
import { SearchQuery, SearchQueryVariables, SearchResult } from '~/models'
import SEARCH_QUERY from './query-search.gql'
import { useRefState } from './use-cesium'
import { search as searchPlaces } from '~/components/forms/geo-input/search'

interface MapBoxResult {
  label: string
  center: number[]
}

export function useSearch() {
  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [haveResults, setHaveResults] = useState(false)
  const [results, setResults] = useState<SearchResult[]>([])
  const [debounce] = useRefState<NodeJS.Timeout>()
  const [unique] = useRefState<string>()

  const [searchingForLocation, setSearchingForLocation] = useState(false)
  const [searchTime, setSearchTime] = useState<Date>(new Date())
  const [busySearchingForLocation, setBusySearchingForLocation] = useState(true)
  const [locationResults, setLocationResults] = useState<MapBoxResult[]>([])

  const searchQuery = useQuery<SearchQuery, SearchQueryVariables>(SEARCH_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const haveSearch = typeof search === 'string' && search.length >= 3

  useEffect(() => {
    if (!haveSearch) {
      setHaveResults(false)
      return
    }

    if (debounce.current) {
      clearTimeout(debounce.current)
    }

    setLoading(true)
    setHaveResults(false)

    debounce.current = setTimeout(() => {
      const thisOne = ulid()
      unique.current = thisOne
      searchQuery
        .refetch({
          input: {
            text: search,
          },
        })
        .then((res) => {
          if (unique.current !== thisOne) {
            return
          }
          setResults(res.data.search.items || [])
          setHaveResults(true)
        })
        .catch(() => {
          if (unique.current !== thisOne) {
            return
          }
          setError(true)
        })
        .finally(() => {
          if (unique.current !== thisOne) {
            return
          }
          setLoading(false)
        })
    }, 250)
  }, [search])

  // Search places.
  const onResult = useCallback(
    (err: any, fc: any, st: Date) => {
      setBusySearchingForLocation(false)
      if (!err && fc && fc.features && searchTime <= st) {
        setSearchTime(st)
        setLocationResults(
          fc.features
            .map((feature: any) => ({
              center: feature.center,
              label: feature.place_name,
            }))
            .filter((feature: any) => feature.label)
        )
      }
    },
    [searchTime]
  )

  useEffect(() => {
    if (!searchingForLocation || search.length < 3) {
      return
    }

    setBusySearchingForLocation(true)
    searchPlaces('https://api.mapbox.com', 'mapbox.places', search, onResult)
  }, [search, searchingForLocation])

  function reset() {
    setSearch('')
    setHaveResults(false)
    setSearchingForLocation(false)
    setBusySearchingForLocation(false)
    setLocationResults([])
  }

  return {
    results,
    haveResults,
    loading,
    error,
    search,
    setSearch,
    searchingForLocation,
    setSearchingForLocation,
    busySearchingForLocation,
    locationResults,
    reset,
  }
}
