import React from 'react'
import { OverviewStatsCard } from '~/components'
import { SitesQuerySiteWithExtra } from '~/models'

interface SitesCardProps {
  site: SitesQuerySiteWithExtra
  lastUploadTime: number | undefined
  style?: React.CSSProperties
  setShowHideDemoSites: (s: boolean) => void
  onClickHandler: (s: SitesQuerySiteWithExtra) => void
  canListIssues: boolean
}

export const SitesCard = (props: SitesCardProps) => {
  return (
    <div
      className='sites-card'
      onClick={(e) => {
        if (!e.defaultPrevented) {
          props.onClickHandler(props.site)
        }
      }}
      style={props.style}
    >
      <div className='sites-card-content'>
        {props.site.s.name} {props.site.s.isDemoSite && <span className='sites-card-content-demo'>DEMO SITE</span>}
        {props.site.s.isDemoSite && (
          <div
            className='sites-card-content-hide-demo'
            onClick={(e) => {
              e.preventDefault()
              props.setShowHideDemoSites(true)
            }}
          >
            <i className='material-icons'>visibility_off</i>
          </div>
        )}
      </div>
      <OverviewStatsCard
        weather={props.site.extra.weather}
        position={props.site.extra.center}
        lastUploadTime={props.lastUploadTime}
        numAssets={props.site.s.assetCount}
        numHighIssues={props.site.s.highRiskIssues}
        numMediumIssues={props.site.s.mediumRiskIssues}
        numLowIssues={props.site.s.lowRiskIssues}
        canListIssues={props.canListIssues}
      />
    </div>
  )
}
