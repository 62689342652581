import { Marker, MarkerIconType } from '~/state'

import { PointT } from '~/models'
import { Map, PolygonColorRed } from './polygon-renderer'
import { getPolygonCenter } from '../helpers'
import { PolygonRendererWithoutEvents } from './polygon-renderer-without-events'

interface PolygonRendererViewConstructor {
  points: PointT[]
  color: string
  map: Map
  label?: string
  onClick?: () => void
  renderPoints?: boolean
  entityCollection?: Cesium.EntityCollection
  hide?: boolean
  hideMarker?: boolean
  fill?: boolean
}

export class PolygonRendererView extends PolygonRendererWithoutEvents {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _polylineEntity: Cesium.Entity
  private _polygonEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _pointsLngLat: number[][]
  private _centerLngLat: number[]
  private _marker: Marker
  private _label?: string
  private _renderPoints: boolean

  constructor(args: PolygonRendererViewConstructor) {
    super(args.map, args.color)

    this._positions = args.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0))
    this._points = new Cesium.PointPrimitiveCollection()
    this._positions.forEach((p, i) => {
      this._points.add(this.createPoint(p, i, 6))
    })
    this._pointsLngLat = args.points.map((p) => {
      return [p.longitude, p.latitude]
    })
    this._centerLngLat = getPolygonCenter(this._pointsLngLat)
    this._label = args.label
    this._renderPoints = args.renderPoints === undefined ? true : args.renderPoints

    const polyline = this.createPolylineConstructor([...this._positions, this._positions[0]])
    this._polylineEntity = args.map.viewer.entities.add({ polyline, show: !args.hide })
    if (this._renderPoints) {
      this._pointsEntity = args.map.viewer.scene.primitives.add(this._points)
    }

    if (args.fill) {
      this._polygonEntity = args.map.viewer.entities.add({
        polygon: this.createPolygonConstructor(
          new Cesium.PolygonHierarchy([...this._positions, this._positions[0]].map((p) => p.clone()))
        ),
      })
      this._polygonEntity.show = false
    }

    if (this._label) {
      this._marker = args.map.addMarker(
        this._label + Math.random(),
        this._centerLngLat,
        args.onClick,
        this._label,
        32,
        this._color,
        args.hideMarker === true
          ? MarkerIconType.None
          : this._color === PolygonColorRed
          ? MarkerIconType.Map
          : MarkerIconType.MonitoringZone
      )
    }
  }

  public destroy() {
    if (this._polylineEntity) {
      this._map.viewer.entities.remove(this._polylineEntity)
      this._polylineEntity = undefined
    }
    if (this._pointsEntity) {
      this._map.viewer.scene.primitives.remove(this._pointsEntity)
      this._pointsEntity = undefined
    }
    if (this._label) {
      this._map.removeMarker(this._marker)
      this._label = undefined
    }
    super.destroy()
  }

  public setVisible(visible: boolean, labels: boolean) {
    if (this._polylineEntity) {
      this._polylineEntity.show = visible
    }
    if (this._pointsEntity) {
      this._pointsEntity.show = visible
    }
    if (this._marker) {
      this._marker.billboard.show = labels
      this._marker.label.show = labels
    }
  }

  public setActive(active: boolean) {
    if (this._polylineEntity) {
      if (active) {
        ;(this._polylineEntity.polyline.width as any) = 6
      } else {
        ;(this._polylineEntity.polyline.width as any) = 4
      }
    }
  }

  public setFill(fill: boolean) {
    if (this._polygonEntity) {
      this._polygonEntity.show = fill
    }
  }

  protected createPolygonConstructor(
    positions: Cesium.CallbackProperty | Cesium.PolygonHierarchy
  ): Cesium.PolygonGraphics.ConstructorOptions {
    const c: Cesium.PolygonGraphics.ConstructorOptions = {
      show: true,
      hierarchy: positions,
      material: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
      height: 0,
    }
    return c
  }
}
