import React, { useEffect, useState } from 'react'
import {
  FloatingInput,
  FloatingTextArea,
  Select,
  DrawerForm,
  DrawerFormContent,
  DrawerFormButtons,
  DrawerFormBreadcrumbs,
  DrawerFormBreadcrumbItem,
  pickFromPosition,
  getCurrencyPrefix,
} from '~/components'
import { MarkerIconType, ProjectionType, useAppState } from '~/state'
import { PrioritySelect, DefectTypeSelect, RiskTypeSelect, StatusSelect } from '~/issues/selects'
import {
  RiskType,
  DefectType,
  ComponentDtoT,
  SiteQueryAsset,
  SiteQuerySite,
  IssueStatus,
  SiteQuerySurvey,
  ComponentTypeSmallDtoT,
  MaterialTypeSmallDtoT,
  ComponentTypeDtoT,
  AssetTypeDtoT,
} from '~/models'
import { ComponentTypeSelectLoadTypes } from '~/components/components2'
import { AssetTypeSelect } from '../selects/asset-types-select'
import { IssueCreationStatus, IssueItemWithIndex } from '../components'
import { SurveyDropdown } from './issues-survey-select'
import { useUser } from '~/base'
import { getIssueEstimatedRepairCost } from '../issue-repair-cost-calculator'

interface IssuesCreateFormProps {
  onComplete: (i: IssueItemWithIndex) => Promise<void>
  onClear: () => void
  issue: IssueItemWithIndex
  site: SiteQuerySite
  target: 'site' | 'asset'
  defectTypes: DefectType[]
  canSave: boolean
  showOperationType?: boolean
  activeSurvey?: SiteQuerySurvey
  surveys?: SiteQuerySurvey[]
  setActiveSurvey?: (s: SiteQuerySurvey) => void
}

export const IssuesCreateForm = (props: IssuesCreateFormProps) => {
  const { view, map, timeline, site } = useAppState()
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>()
  const [issue, setIssue] = useState<IssueItemWithIndex>()
  const [saving, setSaving] = useState<boolean>(false)
  const user = useUser()
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<ComponentTypeSmallDtoT[]>([])
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialTypeSmallDtoT>(undefined)
  const [assetType, setAssetType] = useState<AssetTypeDtoT>()

  // Set form based on selected issue.
  useEffect(() => {
    if (!props.issue) {
      setSelectedComponentTypes([])
      setSelectedMaterialType(undefined)
      return
    }

    const issue = { ...props.issue }
    const assetID = issue.assetID || view.assetID
    if (assetID) {
      const asset = site.site.assets.find((a) => a.id === assetID)
      setSelectedAsset(asset)
      issue.assetTypeID = asset?.assetType?.id || issue.assetTypeID || ''
    } else {
      setSelectedAsset(undefined)
    }

    setIssue(issue)
  }, [props.issue?.id])

  // Auto change risk type based on priority.
  useEffect(() => {
    if (!issue) {
      return
    }
    let newRiskType = RiskType.High
    if (props.issue.priority <= 5) {
      newRiskType = RiskType.Low
    } else if (props.issue.priority > 5 && props.issue.priority <= 8) {
      newRiskType = RiskType.Medium
    }

    setIssue({
      ...issue,
      riskType: newRiskType,
    })
  }, [issue?.priority])

  // Handle 3D clicks.
  useEffect(() => {
    if (map.projectionType !== ProjectionType.Projection3D) {
      return
    }

    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    leftClickHandler.setInputAction((click: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      const picked = pickFromPosition(map.viewer, click.position, [])
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      const carto = Cesium.Cartographic.fromCartesian(cartesian)
      const lng = Cesium.Math.toDegrees(carto.longitude)
      const lat = Cesium.Math.toDegrees(carto.latitude)

      let toSet = {
        ...issue,
        location: {
          longitude: lng,
          latitude: lat,
          altitude: carto.height,
        },
      }

      for (const asset of site.site.assets) {
        for (const b of asset.boundaries) {
          if (
            polygonContains(
              [lng, lat],
              b.points.map((p) => [p.longitude, p.latitude])
            )
          ) {
            toSet = {
              ...toSet,
              ...{
                assetID: asset.id,
                assetTypeID: asset?.assetType?.id || '',
                assetTypeName: asset?.assetType?.name || '',
                component: issue?.assetTypeID === asset?.assetType?.id ? issue.component : undefined,
                componentTypeID: issue?.assetTypeID === asset?.assetType?.id ? issue.componentTypeID : '',
                componentTypeName: issue?.assetTypeID === asset?.assetType?.id ? issue.componentTypeName : '',
              },
            }

            setSelectedAsset(asset)
            break
          }
        }
      }

      setIssue(toSet)
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    return () => {
      leftClickHandler.destroy()
    }
  }, [map.projectionType, issue])

  // Render 3D Marker.
  useEffect(() => {
    if (!issue?.location) {
      return
    }

    const pos = Cesium.Cartographic.fromDegrees(
      issue.location.longitude,
      issue.location.latitude,
      issue.location.altitude
    )
    const marker = map.addMarker(
      `tmp-issue-marker-bulk-tagging`,
      [Cesium.Math.toDegrees(pos.longitude), Cesium.Math.toDegrees(pos.latitude), pos.height],
      undefined,
      null,
      24,
      '#FFF',
      issue.riskType === RiskType.Low
        ? MarkerIconType.IssueLow
        : issue.riskType === RiskType.Medium
        ? MarkerIconType.IssueMedium
        : issue.riskType === RiskType.High
        ? MarkerIconType.IssueHigh
        : MarkerIconType.IssueLow
    )

    return () => {
      map.removeMarker(marker)
    }
  }, [issue?.location, issue?.riskType])

  useEffect(() => {
    if (!issue || !assetType) {
      setSelectedComponentTypes([])
      setSelectedMaterialType(undefined)
      return
    }
    const newSelectedComponentTypes: ComponentTypeDtoT[] = []

    function evaluateCT(c: ComponentTypeDtoT): boolean {
      if (c.id === issue.componentTypeID) {
        newSelectedComponentTypes.push(c)
        return true
      }
      const shouldAdd = (c.children || []).map(evaluateCT).reduce((a, b) => a || b, false)
      if (shouldAdd) {
        newSelectedComponentTypes.push(c)
      }

      return shouldAdd
    }

    assetType.componentTypes.forEach(evaluateCT)

    setSelectedComponentTypes(newSelectedComponentTypes.reverse())
    if (newSelectedComponentTypes.length > 0) {
      const last = newSelectedComponentTypes[newSelectedComponentTypes.length - 1]
      const mt = last.materialTypes.find((x) => x.id === (issue.component?.materialTypeID || issue.materialTypeID))
      setSelectedMaterialType(mt)
    }
  }, [issue, assetType])

  const defectType = props.defectTypes.find((d) => d.id === issue?.defectTypeID)
  const defectSubType = defectType ? defectType.subTypes.find((s) => s.id === issue.defectSubTypeID) : undefined

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setSaving(true)
    props
      .onComplete({
        ...issue,
        coordinates: props.issue.coordinates,
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const repairEstimate = getIssueEstimatedRepairCost(user, {
    priority: issue?.priority || 5,
    repairEstimate: issue?.repairEstimate || 0,
    defectCostToRepair: (defectSubType || defectType)?.costToRepair || 0,
    defectCostPriorityScalar: (defectSubType || defectType)?.costToRepair || 0,
  })

  return (
    <DrawerForm inline>
      {!props.activeSurvey && (
        <DrawerFormBreadcrumbs>
          <DrawerFormBreadcrumbItem title='Issue Tagging' />
        </DrawerFormBreadcrumbs>
      )}
      {props.activeSurvey && (
        <SurveyDropdown
          setActiveSurvey={props.setActiveSurvey}
          surveys={props.surveys}
          activeSurvey={props.activeSurvey}
        />
      )}
      <DrawerFormContent>
        <form autoComplete='off' id='create-new-issue-form' name='create-new-issue-form' onSubmit={handleSubmit}>
          {!view.assetID && (
            <Select<SiteQueryAsset>
              id='asset'
              label='Asset'
              placeholder='Select asset'
              onChange={(selected) => {
                setIssue({
                  ...issue,
                  assetID: (selected as any) === '' ? undefined : selected.id,
                  assetTypeID: selected?.assetType?.id || '',
                  assetTypeName: selected?.assetType?.name || '',
                  component: issue?.assetTypeID === selected?.assetType?.id ? issue.component : undefined,
                  componentTypeID: issue?.assetTypeID === selected?.assetType?.id ? issue.componentTypeID : '',
                  componentTypeName: issue?.assetTypeID === selected?.assetType?.id ? issue.componentTypeName : '',
                })
                setSelectedAsset((selected as any) === '' ? undefined : selected)
              }}
              canSelectGroup
              canSelectNone
              selectedValue={selectedAsset}
              options={site.site.assets.map((a) => {
                return {
                  id: a.id,
                  name: a.name,
                  value: a,
                  items: [],
                }
              })}
            />
          )}
          {!view.assetID && (
            <AssetTypeSelect
              onChange={(s) => {
                setIssue({
                  ...issue,
                  assetTypeID: s.id,
                  assetTypeName: s.name,
                  componentTypeID: '',
                  componentTypeName: '',
                  component: undefined,
                })
              }}
              assetTypeID={selectedAsset?.assetType?.id || issue?.assetTypeID}
              disabled={!!selectedAsset}
            />
          )}
          <Select<ComponentDtoT>
            id='component'
            label='Component'
            placeholder='Select component'
            disabled={!selectedAsset}
            onChange={(selected) => {
              const c = timeline.activeSurvey?.components?.find((x) => x.id === selected?.id)
              setIssue({
                ...issue,
                component: (selected as any) === '' ? undefined : c,
                componentTypeID: c?.componentTypeID,
                componentTypeName: c?.componentTypeName,
                componentTypeFullName: c?.componentTypeFullName,
                materialTypeID: c?.materialTypeID,
                materialTypeName: c?.materialTypeName,
              })
            }}
            canSelectGroup
            canSelectNone
            selectedValue={issue?.component as ComponentDtoT}
            options={((timeline.activeSurvey?.components || []) as ComponentDtoT[]).map((c) => {
              return {
                id: c.id,
                name: (c.materialTypeName === '' ? '' : c.materialTypeName + ' ') + (c.name || c.componentTypeName),
                value: c,
                items: c.children.map((child) => {
                  return {
                    id: child.id,
                    name: child.name || child.componentTypeName,
                    value: child,
                    items: child.children.map((subChild) => ({
                      id: subChild.id,
                      name: subChild.name || subChild.componentTypeName,
                      value: subChild,
                      items: [],
                    })),
                  }
                }),
              }
            })}
          />
          <ComponentTypeSelectLoadTypes
            label='Component Type'
            onChange={(selectedComponentTypes, selectedMaterialType) => {
              const newIssue = {
                ...issue,
                componentTypeID: '',
                componentTypeName: '',
                materialTypeID: '',
                materialTypeName: '',
              }

              if (selectedComponentTypes.length > 0) {
                const last = selectedComponentTypes[selectedComponentTypes.length - 1]
                newIssue.componentTypeID = last.id
                newIssue.componentTypeName = last.name
                newIssue.componentTypeFullName = selectedComponentTypes.map((x) => x.name).join(' - ')
                if (selectedMaterialType) {
                  newIssue.materialTypeID = selectedMaterialType.id
                  newIssue.materialTypeName = selectedMaterialType.name
                }
              }

              setIssue(newIssue)
              setSelectedComponentTypes(selectedComponentTypes)
              setSelectedMaterialType(selectedMaterialType)
            }}
            assetTypeID={issue?.assetTypeID}
            disabled={!!issue?.component}
            selectedComponentTypes={selectedComponentTypes}
            selectedMaterialType={selectedMaterialType}
            onAssetTypeLoaded={setAssetType}
          />
          <PrioritySelect
            selectedValue={issue?.priority || 5}
            onChange={(v) => {
              setIssue({
                ...issue,
                priority: v,
              })
            }}
          />
          <DefectTypeSelect
            defectType={defectType}
            defectSubType={defectSubType}
            defectTypes={props.defectTypes}
            onChange={(d, s) => {
              setIssue({
                ...issue,
                defectTypeID: d.id,
                defectType: d.name,
                defectSubTypeID: s.id,
                defectSubType: s.name,
              })
            }}
          />
          <RiskTypeSelect
            selectedValue={issue?.riskType || RiskType.Low}
            onChange={(v) => {
              setIssue({
                ...issue,
                riskType: v,
              })
            }}
          />
          <StatusSelect
            selectedValue={issue?.status || IssueStatus.AwaitingAction}
            onChange={(v) => {
              setIssue({
                ...issue,
                status: v,
              })
            }}
          />
          <FloatingInput
            label='Repair Estimate'
            id='repairEstimate'
            type='number'
            value={(issue?.repairEstimate || 0) === 0 ? undefined : issue.repairEstimate / 100}
            onChange={(e) => {
              setIssue({
                ...issue,
                repairEstimate: Math.floor((parseFloat(e.target.value) || 0) * 100),
              })
            }}
            helperText={''}
            prefix={getCurrencyPrefix(user)}
            placeholder={repairEstimate.cost / 100 + ''}
          />
          <div className='mb-3'>
            <FloatingTextArea
              label='Notes'
              id='notes'
              value={issue?.notes || ''}
              onChange={(e) => {
                setIssue({
                  ...issue,
                  notes: e.target.value,
                })
              }}
              helperText={''}
              rows={3}
            />
          </div>
          {/* {props.showOperationType && <OperationTypeSelect
            name='operationType'
            selectedValue={issue.operationType}
            onChange={(e) => {
              setIssue({
                ...issue,
                operationType: (e.target as any).value,
              })
            }}
            survey={timeline.activeSurvey}
          />} */}
        </form>
        <p className='drawer-panel-hint'>Use your cursor to draw the issue on the image.</p>
      </DrawerFormContent>
      <DrawerFormButtons>
        <button
          type='submit'
          form='create-new-issue-form'
          className='issues-create-form-submit btn'
          disabled={
            props.issue?.coordinates?.length < 3 ||
            !issue?.location ||
            issue?.location?.altitude === 0 ||
            !issue.assetTypeID ||
            !issue.componentTypeID ||
            saving
          }
          title={
            props.issue?.coordinates?.length < 3
              ? 'Polygon needs to be drawn on the image'
              : !issue?.location || issue.location?.altitude === 0
              ? 'A location needs to be selected on the 3D model'
              : !issue.assetTypeID
              ? 'An asset or asset type needs to be selected'
              : !issue.componentTypeID
              ? 'A component or component type needs to be selected'
              : ''
          }
        >
          {issue?.creationStatus === IssueCreationStatus.New ? 'Add' : 'Update'}
        </button>
        <button
          className='issues-create-form-cancel btn'
          type='button'
          onClick={() => {
            props.onClear()
          }}
          disabled={saving}
        >
          Clear
        </button>
      </DrawerFormButtons>
    </DrawerForm>
  )
}

function polygonContains(point: number[], vs: number[][]) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  var x = point[0],
    y = point[1]

  var inside = false
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1]
    var xj = vs[j][0],
      yj = vs[j][1]

    var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}
