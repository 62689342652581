import { PointT } from '~/models'

const kmlTemplate = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
<Document>
	<name>{{name}}</name>
	<Style id="s_ylw-pushpin_hl">
		<IconStyle>
			<scale>1.3</scale>
			<Icon>
				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
			</Icon>
			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
		</IconStyle>
		<LineStyle>
			<color>ff0000ff</color>
		</LineStyle>
		<PolyStyle>
			<color>ff0000ff</color>
			<fill>0</fill>
		</PolyStyle>
	</Style>
	<Style id="s_ylw-pushpin">
		<IconStyle>
			<scale>1.1</scale>
			<Icon>
				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
			</Icon>
			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
		</IconStyle>
		<LineStyle>
			<color>ff0000ff</color>
		</LineStyle>
		<PolyStyle>
			<color>ff0000ff</color>
			<fill>0</fill>
		</PolyStyle>
	</Style>
	<StyleMap id="m_ylw-pushpin">
		<Pair>
			<key>normal</key>
			<styleUrl>#s_ylw-pushpin</styleUrl>
		</Pair>
		<Pair>
			<key>highlight</key>
			<styleUrl>#s_ylw-pushpin_hl</styleUrl>
		</Pair>
	</StyleMap>
  <Placemark>
    <name>{{name}}</name>
    <styleUrl>#m_ylw-pushpin</styleUrl>
    <Polygon>
      <extrude>1</extrude>
      <tessellate>1</tessellate>
      <altitude>10000</altitude>
      <outerBoundaryIs>
        <LinearRing>
          <coordinates>
            {{coordinates}}
          </coordinates>
        </LinearRing>
      </outerBoundaryIs>
    </Polygon>
  </Placemark>
</Document>
</kml>`

function isClockwise(points: PointT[]): boolean {
  let sum = 0.0
  let v1 = points[points.length - 1]
  for (let i = 0; i < points.length; i++) {
    const v2 = points[i]
    sum += (v2.longitude - v1.longitude) * (v2.latitude + v1.latitude)
    v1 = v2
  }
  return sum > 0.0
}

export function getRoiKML(aoiName: string, points: PointT[]): string {
  let coordinates = ''

  const pointsToUse = [...points]
  pointsToUse.push(points[0])

  if (isClockwise(points)) {
    pointsToUse.reverse()
  }

  for (const p of pointsToUse) {
    coordinates += `${p.longitude},${p.latitude},0,`
  }

  coordinates = coordinates.slice(0, -1)

  return kmlTemplate.replace('{{name}}', aoiName).replace('{{name}}', aoiName).replace('{{coordinates}}', coordinates)
}

export const generateKML = (aoiName: string, points: PointT[]) => {
  const link = document.createElement('a')
  link.id = 'download-kml'
  link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(getRoiKML(aoiName, points)))
  link.setAttribute('download', `${aoiName}.kml`)
  document.body.appendChild(link)
  document.getElementById('download-kml').click()
  document.body.removeChild(link)
}
