import React, { useEffect } from 'react'
import { useAppState, useRefState } from '~/state'

import { SiteQueryAsset, SiteQueryComponent } from '~/models'
import { PolygonRendererEdit3D } from '../polygon-renderer-3d'
import { ComponentEditForm } from './components-edit-form'
import { useComponentView } from './use-component-view'
import { PolygonColorRed } from '../polygon-renderer'

interface ComponentsEditProps {
  asset: SiteQueryAsset
  component: SiteQueryComponent
  onComplete: (changed?: boolean) => void
}

export const ComponentsEdit = (props: ComponentsEditProps) => {
  useComponentView()
  const { map } = useAppState()
  const [points, setPoints] = useRefState<Cesium.Cartesian3[]>(
    props.component.points.map((p) => {
      return Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude)
    })
  )

  useEffect(() => {
    const renderer = new PolygonRendererEdit3D(
      points.current.map((p) => {
        const pos = Cesium.Cartographic.fromCartesian(p)
        return {
          latitude: Cesium.Math.toDegrees(pos.latitude),
          longitude: Cesium.Math.toDegrees(pos.longitude),
          altitude: pos.height,
        }
      }),
      map,
      PolygonColorRed,
      props.component.measurement,
      (points) => {
        setPoints(points)
      }
    )

    return () => {
      renderer.destroy()
    }
  }, [])

  return (
    <div className='components-draw'>
      <ComponentEditForm
        assetTypeID={props.asset.assetType?.id}
        component={props.component}
        newPoints={points}
        complete={() => props.onComplete(true)}
        cancel={() => props.onComplete(false)}
      />
      <div></div>
    </div>
  )
}
