import React from 'react'

export interface InputProps {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  onChange?: any
  onBlur?: any
  onFocus?: any
  onKeyDown?: any
  helperText?: any
  error?: any
  type?: any
  halfWidth?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  readonly?: boolean
  autoComplete?: string
  min?: number
  max?: number
  step?: number
}

export const Input = (props: InputProps) => {
  const {
    type,
    label,
    id,
    value,
    onChange,
    onBlur,
    inputRef,
    readonly,
    helperText,
    onFocus,
    onKeyDown,
    halfWidth,
    min,
    max,
    step,
  } = props

  return (
    <div className={halfWidth ? 'form-container-half-width mb-3 inputText' : 'mb-3 inputText'}>
      <input
        type={type}
        className='form-control'
        placeholder={label}
        id={id}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={inputRef}
        disabled={readonly}
        autoComplete='off'
        min={min}
        max={max}
        step={step}
      />
      {helperText && <div className='invalid-feedback visible'>{helperText}</div>}
    </div>
  )
}
