query ImageByHash($input: QueryImageByHashRequestT!) {
  queryImageByHash(input: $input) {
    image {
      imageID
      hash
      latitude
      longitude
      altitude
      yaw
      pitch
      roll
      dateTaken
    }
  }
}