import React from 'react'
import './row.scss'

type Spacing = 0 | 2 | 3 | 4 | 6 | 8 | 12 | 16 | 20 | 24 | 28 | 30 | 32

type AlignX = 'center' | 'end' | 'start' | 'stretch' | 'stretchCenter'
type AlignY = 'center' | 'end' | 'start' | 'stretch' | 'baseline'

type Props = {
  spacing: Spacing
  children: React.ReactNode
  id?: string
  alignX?: AlignX
  alignY?: AlignY
  ignoreContentWidth?: true
  fullWidth?: true
  grow?: true
  shrink?: true
  wrap?: true
  wrapSpacing?: Spacing
  'aria-labelledby'?: string
  'aria-label'?: string
  'aria-describedby'?: string
}

export const Row = ({
  id,
  spacing,
  children,
  alignX,
  alignY,
  ignoreContentWidth,
  fullWidth,
  grow,
  shrink,
  wrap,
  wrapSpacing,
  'aria-describedby': ariaDescribedBy,
  'aria-labelledby': ariaLabelledBy,
  'aria-label': ariaLabel,
}: Props) => {
  const classNames = [
    'ui-row',
    alignX && `ui-alignX_${alignX}`,
    alignY && `ui-alignY_${alignY}`,
    ignoreContentWidth && 'ui-noWidth',
    fullWidth && 'ui-fullWidth',
    grow && 'ui-grow',
    shrink && 'ui-shrink',
    wrap && 'ui-wrap',
    wrapSpacing && `ui-wrapSpacing-${wrapSpacing}`,
    `ui-spacing-${spacing}`,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <div
      id={id}
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
      className={classNames}
    >
      {children}
    </div>
  )
}
