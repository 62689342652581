import React from 'react'
import { Select, SelectOption } from '~/components'

interface PrioritySelectProps {
  onChange: (r: number) => void
  selectedValue: number
}

export type PrioritySelectItem = {
  id: string
  value: number
}

export const PRIORITY_OPTIONS: Array<SelectOption<PrioritySelectItem>> = [
  {
    id: '1',
    name: '1',
    value: {
      id: '1',
      value: 1,
    },
    items: [],
  },
  {
    id: '2',
    name: '2',
    value: {
      id: '2',
      value: 2,
    },
    items: [],
  },
  {
    id: '3',
    name: '3',
    value: {
      id: '3',
      value: 3,
    },
    items: [],
  },
  {
    id: '4',
    name: '4',
    value: {
      id: '4',
      value: 4,
    },
    items: [],
  },
  {
    id: '5',
    name: '5',
    value: {
      id: '5',
      value: 5,
    },
    items: [],
  },
  {
    id: '6',
    name: '6',
    value: {
      id: '6',
      value: 6,
    },
    items: [],
  },
  {
    id: '7',
    name: '7',
    value: {
      id: '7',
      value: 7,
    },
    items: [],
  },
  {
    id: '8',
    name: '8',
    value: {
      id: '8',
      value: 8,
    },
    items: [],
  },
  {
    id: '9',
    name: '9',
    value: {
      id: '9',
      value: 9,
    },
    items: [],
  },
  {
    id: '10',
    name: '10',
    value: {
      id: '10',
      value: 10,
    },
    items: [],
  },
]

export const PrioritySelect = (props: PrioritySelectProps) => {
  return (
    <Select<PrioritySelectItem>
      id='priority'
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Priority'
      placeholder={''}
      onChange={(s) => {
        props.onChange(s.value)
      }}
      options={PRIORITY_OPTIONS}
    />
  )
}
