import React from 'react'
import { MonitoringZoneCreateRequestT, CreateMonitoringZoneMutation, SurveyBasedOperation } from '~/models'
import { FloatingInput, FloatingTextArea, useForm } from '../forms'
import { getTracking } from '../tracking'
import { string, object, kmlToPolygon } from '~/components'
import { useAppState } from '~/state'
import { OperationTypeSelect } from '../operation-type'
import CREATE_MONITORING_ZONE from './mutation-monitoring-zones-create.gql'
import { MonitoringZoneTargetSelect } from './monitoring-zone-target-select'
import { FileSelector } from '../upload-panel/upload-panel-file-selector'

interface MonitoringZoneCreateFormProps {
  cancel: () => void
  complete: () => void
  points: React.MutableRefObject<Cesium.Cartesian3[]>
  setPointsFromKML: React.Dispatch<React.SetStateAction<Cesium.Cartesian3[]>>
}

export const MonitoringZoneCreateForm = (props: MonitoringZoneCreateFormProps) => {
  const { view, timeline } = useAppState()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<CreateMonitoringZoneMutation, MonitoringZoneCreateRequestT>({
      enableReinitialize: true,
      initialValues: {
        siteID: view.siteID,
        assetID: view.assetID,
        name: '',
        description: '',
        points: [],
        surveyID: timeline.activeSurvey?.id,
        operationType: SurveyBasedOperation.GoingForward,
        monitoringZoneTargetID: '',
        monitoringZoneSubTargetID: '',
      },
      validationSchema: object().shape({
        name: string().required('Required').min(3),
        monitoringZoneTargetID: string().required('Required'),
      }),
      mutation: CREATE_MONITORING_ZONE,
      mapInput: (input) => {
        return {
          siteID: view.siteID,
          assetID: view.assetID || '',
          name: input.name,
          description: input.description,
          points: props.points.current.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
          surveyID: timeline.activeSurvey.id,
          operationType: input.operationType,
          monitoringZoneTargetID: input.monitoringZoneTargetID,
          monitoringZoneSubTargetID: input.monitoringZoneSubTargetID,
        }
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Monitoring Zone',
          action: `User created Monitoring Zone`,
          label: 'Monitoring Zone',
        })
        props.complete()
      },
    })

  if (!timeline.activeSurvey) {
    props.cancel()
    return null
  }

  return (
    <div className='drawer-panel'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                props.cancel()
              }}
            >
              Site
            </a>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            Monitoring Zone
          </li>
        </ol>
      </nav>
      <div className='drawer-panel-title-container'>
        <h6 className='drawer-panel-title'>
          Create
          <br />
          Monitoring Zone
        </h6>
      </div>
      <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
        {error && <div className='error'>{error}</div>}
        <FloatingInput
          label='Name'
          id='name'
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
        />
        <MonitoringZoneTargetSelect
          onChange={(t, s) => {
            setFieldValue('monitoringZoneTargetID', t?.id || '')
            setFieldValue('monitoringZoneSubTargetID', s?.id || '')
          }}
          monitoringZoneTargetID={values.monitoringZoneTargetID}
          monitoringZoneSubTargetID={values.monitoringZoneSubTargetID}
        />
        <FloatingTextArea
          label='Description'
          id='description'
          value={values.description}
          onChange={handleChange}
          helperText={touched.description ? errors.description : ''}
          rows={3}
        />
        <p className='drawer-panel-hint'>
          Use your cursor to draw the monitoring zone on the map or upload the boundary from a KML file. <br />
          <FileSelector
            singleFile
            accept='.kml'
            text='Select KML file'
            className='profile-form-btn btn boundaries-file-select'
            filesSelected={(files) => {
              const reader = new FileReader()
              reader.onload = function () {
                const xml = reader.result.toString()
                const allCoords = kmlToPolygon(xml)
                if (allCoords && allCoords.length === 1) {
                  props.setPointsFromKML(allCoords[0])
                }
              }
              reader.readAsText(files[0])
            }}
          />
        </p>
        <OperationTypeSelect
          name='operationType'
          selectedValue={values.operationType}
          onChange={handleChange}
          survey={timeline.activeSurvey}
        />
        <div style={{ flex: 1 }} />
        <div className='drawer-panel-form-container-action-container'>
          <button
            type='submit'
            className='btn submit mb-2'
            disabled={isSubmitting || props.points.current.length < 3}
          >
            Save
          </button>
          <button className='btn cancel' onClick={() => props.cancel()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}
