import React, { CSSProperties, useRef, useState } from 'react'
import { classes } from '~/components'
import { BoundaryMarkerStatus, IssueStatus, RiskType } from '~/models'

interface IssueBadgeProps {
  type: 'risk' | 'status' | 'boundary-marker-status'
  subType: RiskType | IssueStatus | BoundaryMarkerStatus
  canEdit: boolean
  options: Array<{
    id: RiskType | IssueStatus | BoundaryMarkerStatus
    text: string
  }>
  update: (v: RiskType | IssueStatus | BoundaryMarkerStatus) => Promise<any>
}

export const IssueBadge = (props: IssueBadgeProps) => {
  const [open, setOpen] = useState(false)
  const [updating, setUpdating] = useState(false)
  const badge = useRef<HTMLDivElement>(null)

  const computeStyle = (): CSSProperties => {
    if (!badge.current) {
      return {}
    }

    const rect = badge.current.getBoundingClientRect()

    return {
      position: 'fixed',
      top: rect.bottom + 'px',
      left: rect.left + 'px',
      minWidth: rect.width + 'px',
    }
  }

  return (
    <>
      <div
        ref={badge}
        className={classes({
          'issue-badge': true,
          editable: props.canEdit,
          [props.type]: true,
          [props.subType || '']: true,
          updating,
        })}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {props.options.find((x) => x.id === props.subType)?.text}
        {props.canEdit && <span>arrow_left</span>}
      </div>
      {open && badge.current && (
        <div className='issue-badge-options' style={computeStyle()}>
          {props.options.map((o) => {
            return (
              <div
                className='issue-badge-option'
                key={o.id}
                onClick={(e) => {
                  setUpdating(true)
                  setOpen(false)
                  e.preventDefault()
                  props.update(o.id).then(() => {
                    
                  }).finally(()=>{
                    setUpdating(false)
                  })
                }}
              >
                {o.text}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

interface IssueBadgeContainerProps {
  children: any
}

export const IssueBadgeContainer = (props: IssueBadgeContainerProps) => {
  return (
    <div className='issue-badges'>
      {props.children}
      <div className='issue-badges-filler'></div>
    </div>
  )
}
