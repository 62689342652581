const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const retryablePromise = async <T>(fn: () => Promise<T>, retries = 3, delayMs = 1000): Promise<T> => {
  let lastError: any
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await fn()
    } catch (error) {
      lastError = error
      console.warn(`Attempt ${attempt} failed. Retrying in ${delayMs}ms...`)
      if (attempt === retries) {
        throw lastError
      }
      await delay(delayMs)
    }
  }
  throw lastError
}
