import React, { useEffect, useState } from 'react'
import { string, object } from '~/components'
import {
  FloatingInput,
  useForm,
  GeoInput,
  getTracking,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormContent,
  DrawerFormHeading,
  Helmet,
  DrawerFormButtons,
  DrawerFormSubmitButton,
  DrawerFormCancelButton,
} from '~/components'
import { UpdateSiteMutation, SiteUpdateRequestT, SiteQuerySite, LocationT } from '~/models'
import { LeftClickPosition, useAppState } from '~/state'
import { getDefaultLocation, mergeLocations } from './location-helpers'
import { Config } from '~/config'
import UPDATE_SITE_MUTATION from './mutation-sites-update.gql'

interface SiteUpdateProps {
  site: SiteQuerySite
  onComplete: (updated: boolean) => void
}

export const SiteUpdate = (props: SiteUpdateProps) => {
  const { map, sites } = useAppState()
  const [geocoderResult, setGeocoderResult] = useState<LocationT | undefined>(props.site.address as LocationT)
  const [clickPosition, setClickPosition] = useState<Cesium.Cartesian3 | undefined>()

  useEffect(() => {
    sites.setEditing(true)

    // TODO: There is a memory leak here that we need to fix. (Setting the state in a cleanup function)
    return () => {
      sites.setEditing(false)
    }
  }, [])

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    UpdateSiteMutation,
    SiteUpdateRequestT
  >({
    enableReinitialize: true,
    initialValues: {
      siteID: props?.site?.id || '',
      name: props?.site?.name || '',
      location: mergeLocations(getDefaultLocation(), props.site.address),
    },
    validationSchema: object().shape({
      name: string().required('Required'),
      location: object().shape({}).required('Required'),
    }),
    mutation: UPDATE_SITE_MUTATION,
    mapInput: (input) => {
      return {
        ...input,
        location: mergeLocations(
          getDefaultLocation(),
          typeof geocoderResult === 'object' ? geocoderResult : props.site.address
        ),
      }
    },
    onSuccess: () => {
      getTracking().event({
        category: 'Form',
        action: `User updated a site`,
        label: 'Site',
      })
      props.onComplete(true)
    },
  })

  const onLeftClick = (pos: LeftClickPosition) => {
    setClickPosition(pos.cartesian)
    return true
  }

  useEffect(() => {
    map.addLeftClickHandler(onLeftClick)

    return () => {
      map.removeLeftClickHandler(onLeftClick)
    }
  }, [])

  useEffect(() => {
    if (!clickPosition) {
      return
    }

    const pos = Cesium.Cartographic.fromCartesian(clickPosition)
    const lng = (pos.longitude / Math.PI) * 180
    const lat = (pos.latitude / Math.PI) * 180

    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${Config.MapBoxToken}`)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.features) && data.features.length > 0) {
          data.features[0].center = [lng, lat]
          setGeocoderResult(data.features[0])
        } else {
          setGeocoderResult(undefined)
        }
      })

    map.removeMarkerById('site-update')
    const marker = map.addMarker('site-update', [lng, lat])

    return () => {
      map.removeMarker(marker)
    }
  }, [clickPosition])

  useEffect(() => {
    if (!geocoderResult || !Array.isArray(geocoderResult.center) || geocoderResult.center.length != 2) {
      return
    }

    map.removeMarkerById('site-update')
    const marker = map.addMarker('site-update', geocoderResult.center)

    return () => {
      map.removeMarker(marker)
    }
  }, [geocoderResult])

  const onSelectHandler = (result: LocationT) => {
    setGeocoderResult(result)
    if ((result as any).center) {
      map.flyTo((result as any).center[1], (result as any).center[0], 2e4)
    }
  }

  return (
    <DrawerForm>
      <Helmet title='Update site' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem title='Home' onClick={() => props.onComplete(false)} />
        <DrawerFormBreadcrumbItem title='Update site' />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading title='Update site' />
      <DrawerFormContent>
        <form autoComplete='off' id='create-update-form' onSubmit={handleSubmit}>
          {error && <div className='error'>{error}</div>}
          <FloatingInput
            label='Name'
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
          <GeoInput
            inputLabel='address'
            inputValue={geocoderResult ? geocoderResult.place_name || geocoderResult.address : undefined}
            onSelect={onSelectHandler}
            helperText='Search for an address or click on the map to set the address'
          />
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        <DrawerFormSubmitButton form='create-update-form' disabled={isSubmitting || typeof geocoderResult !== 'object'}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton onClick={() => props.onComplete(false)}>Cancel</DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
