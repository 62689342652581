import React from 'react'
import { BaseLayerType, useAppState } from '~/state'
import { getTracking, useLocation } from '~/components'
import { SiteQuerySite } from '~/models'

interface SiteNavProps {
  site: SiteQuerySite
  loading: boolean
}

export const SiteNav = (props: SiteNavProps) => {
  const { view, sites, map } = useAppState()
  const location = useLocation()

  const resetView = () => {
    map.setBaseLayerType(BaseLayerType.Satellite).then(()=> {
      map.jumpTo(sites.getBounds())
      location.setLocation('/')
    })
    getTracking().event({
      category: 'Breadcrumb',
      action: `User clicked home breadcrumb`,
      label: 'Site',
    })
  }

  const basicSite = sites.data?.sites?.find(x => x.id === view.siteID)
  const siteToUseForBasicInfo = props.site || basicSite

  return (
    <nav style={{marginBottom: '0.5rem'}}>
      <ol className='breadcrumb' style={{height: 'unset', padding: '0.5rem'}}>
        <li className='breadcrumb-item'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
              resetView()
            }}
          >
            Home
          </a>
        </li>
        <li className='breadcrumb-item active' aria-current='page'>
          {siteToUseForBasicInfo && siteToUseForBasicInfo.name}
        </li>
      </ol>
    </nav>
  )
}
