import React, { useState } from 'react'
import { string, object } from '~/components'
import {
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormButtons,
  DrawerFormCancelButton,
  DrawerFormContent,
  DrawerFormHeading,
  DrawerFormSubmitButton,
  Helmet,
  OperationTypeSelect,
} from '~/components'
import { FloatingInput, useForm, getTracking } from '~/components'
import { CreateAssetMutation, AssetCreateRequestT, AssetTypeDtoT, SurveyBasedOperation } from '~/models'
import { useAppState } from '~/state'
import CREATE_ASSET_MUTATION from './mutation-asset-create.gql'
import { AssetTypeSelect } from './asset-types-select'

interface AssetCreateProps {
  onComplete: (assetId?: string) => void
}

export const AssetCreate = (props: AssetCreateProps) => {
  const { timeline, site } = useAppState()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<CreateAssetMutation, AssetCreateRequestT>({
      enableReinitialize: true,
      initialValues: {
        siteID: site.site.id || '',
        name: '',
        assetTypeID: '',
        operationType: SurveyBasedOperation.GoingForward,
        surveyID: timeline.activeSurvey?.id,
      },
      validationSchema: object().shape({
        name: string().required('Required'),
        assetTypeID: string().required('Required'),
      }),
      mutation: CREATE_ASSET_MUTATION,
      onSuccess: (result: CreateAssetMutation) => {
        getTracking().event({
          category: 'Form',
          action: `User created a asset`,
          label: 'Asset',
        })
        props.onComplete(result.createAsset.id)
      },
    })

  if (!timeline.activeSurvey) {
    props.onComplete()
    return null
  }

  return (
    <DrawerForm>
      <Helmet title='Add asset' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem title='Site' onClick={() => props.onComplete()} />
        <DrawerFormBreadcrumbItem title='Add asset' />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading title='Add an asset' />
      <DrawerFormContent>
        <form autoComplete='off' onSubmit={handleSubmit} id='asset-create-form'>
          <FloatingInput
            label='Asset Name'
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
          <AssetTypeSelect
            onChange={(s) => {
              setSelectedAssetType(s)
              setFieldValue('assetTypeID', s?.id || '')
            }}
            assetType={selectedAssetType}
            helperText={touched.assetTypeID ? errors.assetTypeID : undefined}
          />
          <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          />
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        {error && <div className='alert alert-warning ml-3 mr-3'>{error}</div>}
        <DrawerFormSubmitButton form='asset-create-form' disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton onClick={() => props.onComplete()}>Cancel</DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
