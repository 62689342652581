import { Link } from 'react-router-dom'
import React from 'react'

interface AuthLinksProps {
  children?: any
}

export const AuthLinks = (props: AuthLinksProps) => {
  return <div className='auth-links'>{props.children}</div>
}

interface AuthLinkProps {
  children?: string
  to: string
  className: string
  onClick?: () => void
  disabled?: boolean
}

export const AuthLink = (props: AuthLinkProps) => {
  return (
    <p className={`body2 ${props.className}`}>
      <Link className="auth-links-text" to={props.to} onClick={(e) => {
        if (props.onClick || props.disabled) {
          e.preventDefault()
          props.onClick()
          return
        }
      }}>{props.children}</Link>
    </p>
  )
}
