import React from 'react'
import { SimpleBar } from '../simple-bar'

interface DropdownProps {
  title: string;
  open: boolean
  toggleClicked?: React.MouseEventHandler<HTMLDivElement>
  children: any
  ref?: React.Ref<HTMLDivElement>
}

export const Dropdown = (props: DropdownProps) => {
  return (
    <div className='dropdown' ref={props.ref}>
      <div className={`dropdown-toggle ${props.open ? 'active' : ''}`} onClick={props.toggleClicked}>
        {props.title}
      </div>
      <div className='dropdown-menu' style={{ display: props.open ? 'block' : 'none' }}>
        <SimpleBar>
          {props.children}
        </SimpleBar>
      </div>
    </div>
  )
}

interface DropdownItemProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  children: any
}

export const DropdownItem = (props: DropdownItemProps) => {
  return (
    <div className='dropdown-item' onClick={props.onClick}>
      {props.children}
    </div>
  )
}
