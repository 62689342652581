import React, { useState, useEffect } from 'react'
import {
  BoundaryRenderState,
  BoundaryRenderType,
  MonitoringZoneRenderState,
  MonitoringZoneRenderType,
  useAppState,
  useRefState,
} from '~/state'
import { SiteQueryAsset, Photo } from '~/models'
import {
  FilmStrip,
  Boundaries,
  MonitoringZones,
  Photos,
  ErrorBoundary,
  getTracking,
  useToasts,
  useComponentLayers,
  useLocation,
  ActivityIndicator,
  Components,
  ComponentsContextMenu,
  BoundaryDetails,
  BOUNDARIES_ALLOWED_ORG_IDS,
} from '~/components'
import { Helmet } from '~/components'
import { Asset, AssetCreate, AssetFullList, AssetUpdate } from '../assets'
import { SiteUpdate } from './site-update'
import { SiteAssets } from './site-assets'
import { SiteInfo } from './site-info'
import { SiteNav } from './site-nav'
import { SiteError } from './site-error'
import { SiteNotFound } from './site-not-found'
import { useSiteLayers } from './use-site-layers'
import { useSiteBoundaries } from './use-site-boundaries'
import { useSiteMonitoringZones } from './use-site-monitoring-zones'
import { IssuesBulkTagging, IssuesDrawerList } from '~/issues'
import { useIssueLayers } from '~/issues/use-issue-layers'
import { DrawerList } from '~/components/drawer/drawer-list'
import { AnnotationsDrawerList } from '~/annotations'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { IssuesCreateForm } from '~/issues/issues-create-form'
import { IssuesUpdateForm } from '~/issues/issues-update-form'
import { IssueCardList } from '~/issues/issue-card-list'
import { IssuesBulkMoving } from '~/issues/bulk-moving'
import { IssueDetail } from '~/issues/issue-details'
import { AnalysisDrawerList } from '~/analysis/analysis-list-drawer'
import { useUser } from '~/base'
import { SiteComponents } from './site-components'

export const Site = () => {
  const toasts = useToasts()
  const location = useLocation()
  const user = useUser()
  const { view, sites, boundaryState, monitoringZoneState, imageTagger, site, drawer, issues, map } = useAppState()
  const [loading, setLoading] = useState(false)

  const [showUpdateSite, setShowUpdateSite] = useState<boolean>(false)
  const [showCreateAsset, setShowCreateAsset] = useState<boolean>(false)
  const [showUpdateAsset, setShowUpdateAsset] = useState<SiteQueryAsset | undefined>()
  const [photos, setPhotos] = useRefState<Photo[]>([])

  // Set the default boundary states.
  useEffect(() => {
    boundaryState.setRenderState(BoundaryRenderState.SiteAndAssets)
    boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
    monitoringZoneState.setRenderState(MonitoringZoneRenderState.SiteAndAssets)
    monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
  }, [])

  // Jump to specific site.
  useEffect(() => {
    if (!view.siteID || !site.site) {
      return
    }

    map.jumpTo(sites.getBoundsForSite(site.site))

    return () => {}
  }, [view?.siteID, site.site?.id])

  // Load lidar if basic org and has lidar
  useEffect(() => {
    if (!site.site || !user.org.isBasic) {
      return
    }

    if (site.site.lidar.length > 0) {
      map.setShowLidar(true)
    }
  }, [site.site?.id])

  useSiteLayers(site.site) // Renders orthos or 3D tiles.
  useComponentLayers() // Renders components.
  useSiteMonitoringZones(site.site) // Renders monitoring zones.
  useIssueLayers() // Renders issues.
  useSiteBoundaries(site.site) // Renders boundaries.

  const refetch = (): Promise<any> => {
    setLoading(true)
    return Promise.all([sites.refetch(), site.refetch()]).finally(() => {
      setLoading(false)
    })
  }

  if (site.error) {
    return <SiteError />
  }

  if (!site.loading && !site.site) {
    return <SiteNotFound />
  }

  if (showUpdateSite) {
    return (
      <SiteUpdate
        site={site.site}
        onComplete={(updated) => {
          setShowUpdateSite(false)
          if (updated) {
            toasts.addTopLeft('Site Updated')
            refetch()
          }
        }}
      />
    )
  }

  if (showCreateAsset) {
    return (
      <AssetCreate
        onComplete={(assetId) => {
          if (assetId) {
            refetch().then(() => {
              setShowCreateAsset(false)
              toasts.addTopLeft('Asset Created.')
              location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${assetId}`)
            })
          } else {
            setShowCreateAsset(false)
          }
        }}
      />
    )
  }

  if (showUpdateAsset) {
    return (
      <AssetUpdate
        asset={showUpdateAsset}
        onComplete={(b) => {
          setShowUpdateAsset(undefined)
          if (b) {
            toasts.addTopLeft('Asset Updated.')
            refetch()
          }
        }}
      />
    )
  }

  const forAsset = !!view.assetID
  const forSite = !forAsset
  const isIA = user.isInstantAssessmentOrg

  return (
    <>
      {forAsset && <Asset site={site.site} refetch={refetch} />}
      {forSite && (
        <>
          <Helmet title={site.site && site.site.name} />
          <div className='site-panel' style={{ paddingBottom: '0.1rem' }}>
            <SiteNav site={site.site} loading={site.loading} />
            <SiteInfo
              site={site.site}
              loading={site.loading}
              updateSite={() => {
                if (map.showLidar) {
                  map.setShowLidar(false)
                }
                setTimeout(() => {
                  setShowUpdateSite(true)
                  getTracking().event({
                    category: 'Button',
                    action: `User clicked edit site button`,
                    label: 'Site',
                  })
                }, 0)
              }}
            />
          </div>
          <DrawerList noPadding>
            {loading && !site && <ActivityIndicator />}
            {(!loading || site) && !map.showLidar && !user.org.isBasic && (
              <>
                <div className='site-panel'>
                  <SiteAssets
                    site={site.site}
                    basicSite={sites.data?.sites?.find((x) => x.id === view.siteID)}
                    showCreate={() => setShowCreateAsset(true)}
                    showUpdateAsset={(asset: SiteQueryAsset) => {
                      setShowUpdateAsset(asset)
                    }}
                    assetRemoved={() => refetch()}
                  />
                </div>
                <div className='site-panel'>
                  {isIA && <SiteComponents />}
                  {!isIA && <Components site={site.site} refetchAsset={refetch} />}
                </div>
                {!isIA && (
                  <div className='site-panel'>
                    <IssuesDrawerList target='site' site={site.site} refetch={refetch} />
                  </div>
                )}
                {!isIA && (
                  <div className='site-panel'>
                    <Photos type='site' site={site.site} siteId={view.siteID} photos={photos} />
                  </div>
                )}
                {!isIA && (
                  <div className='site-panel' style={{ paddingTop: '1rem', paddingBottom: '0.25rem' }}>
                    <AnnotationsDrawerList />
                    <AnalysisDrawerList />
                    <Boundaries refetch={refetch} />
                    <MonitoringZones refetch={refetch} />
                  </div>
                )}
              </>
            )}
            {(!loading || site) && !map.showLidar && user.org.isBasic && (
              <>
                {/* <Photos type='site' site={site.site} siteId={view.siteID} photos={photos} /> */}
                <Boundaries refetch={refetch} />
                <br />
                <br />
                <br />
              </>
            )}
            <div id='site-left-drawer-portal'></div>
          </DrawerList>
          {drawer.expandedPanel === ExpandedPanel.Photos && (!loading || site.site) && (
            <ErrorBoundary
              fallbackComponent={() => {
                boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
                monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
                drawer.closeExpandedPanel()
                return <></>
              }}
            >
              <FilmStrip
                site={site.site}
                photos={photos}
                addToData={(photos) => {
                  setPhotos(photos)
                }}
                dataLoaded={(photos, reload) => {
                  setPhotos(photos)
                  if (imageTagger.photo) {
                    const selectedId = imageTagger.photo.imageID
                    const newPhoto = photos.find((p) => p.imageID === selectedId)
                    if (newPhoto) {
                      imageTagger.setPhoto(newPhoto)
                    }
                  }

                  imageTagger.setChangedCallback(() => () => {
                    return Promise.all([site.refetch(), reload()])
                  })
                }}
                onHide={() => {
                  boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
                  monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
                  drawer.closeExpandedPanel()
                }}
              />
            </ErrorBoundary>
          )}
        </>
      )}
      {drawer.expandedPanel === ExpandedPanel.Asset && <AssetFullList />}
      {imageTagger.addingIssue && <IssuesCreateForm />}
      {issues.issueToUpdate && <IssuesUpdateForm selectedIssue={issues.issueToUpdate} />}
      {drawer.expandedPanel === ExpandedPanel.Issues && <IssueCardList />}
      {issues.addingBulk && <IssuesBulkTagging />}
      {issues.movingBulk && <IssuesBulkMoving />}
      {view.issueID && <IssueDetail />}
      {view.boundaryID &&
        !view.assetID &&
        BOUNDARIES_ALLOWED_ORG_IDS.findIndex((x) => x === user.currentOrgId) !== -1 && <BoundaryDetails />}
      <ComponentsContextMenu />
    </>
  )
}
