import React, { useState, useEffect } from 'react'
import { useAppState, ProjectionType, BaseLayerType } from '~/state'
import { SitesListViewType } from '../sites-list-types'
import { SitesListGroups } from './site-groups-list'

export const SiteGroups = () => {
  const { sites, map } = useAppState()
  const [viewType, setViewType] = useState(SitesListViewType.Loading)

  useEffect(() => {
    if (!sites.sites || !sites.groups || viewType !== SitesListViewType.Loading) {
      return
    }

    setViewType(SitesListViewType.Groups)
  }, [sites.sites, sites.groups, viewType])

  useEffect(() => {
    if (map.projectionType === ProjectionType.Projection3D) {
      map.toggleProjection()
    }
  }, [map.projectionType])

  // Jump to all sites.
  useEffect(() => {
    if (!sites.data || viewType === SitesListViewType.Loading) {
      return
    }

    map.setBaseLayerType(BaseLayerType.Satellite)

    map.jumpTo(sites.getBoundsForGroups())
  }, [sites.sites, viewType])

  const canShow = sites && viewType === SitesListViewType.Groups

  return (
    <div className='sites'>
      {canShow && <SitesListGroups sites={sites.sites} groups={sites.groups} />}
    </div>
  )
}
