import React from 'react'
import './modal.scss'
import { createPortal } from 'react-dom'

type Props = {
  onClose?: () => void
  children: React.ReactNode
  backdrop?: 'default' | 'glass'
  background?: 'default' | 'dark'
  size?: 'small' | 'medium' | 'large' | 'full'
  isOpen?: boolean
  showCloseButton?: boolean
}

export const Modal = ({
  onClose,
  children,
  backdrop = 'default',
  background = 'default',
  size = 'medium',
  isOpen = false,
  showCloseButton = true,
}: Props) => {
  if (!isOpen) {
    return null
  }

  return createPortal(
    <div className={`ui-modal ui-modal-${size} ui-modal-${background}`}>
      <div className={`ui-modal-background-overlay ui-modal-${backdrop}`} onClick={onClose} />
      <div className='ui-modal-container'>
        <div className='ui-modal-content ui-slimscroll'>
          {showCloseButton && (
            <div className='ui-modal-close-button' onClick={onClose}>
              <i className='fa-light fa-xmark fa-lg'></i>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>,
    document.body
  )
}
