import React, { CSSProperties } from 'react'

interface SearchBoxprops {
  search: string
  setSearch: (s: string) => void
  style?: CSSProperties
}

export const SearchBox = (props: SearchBoxprops) => {
  return (
    <div className='search-box' style={props.style}>
      <form
        autoComplete='off'
        className='form-group'
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div className='input-group'>
          <input
            onChange={(e) => {
              props.setSearch(e.target.value)
            }}
            type='text'
            className='form-control'
            placeholder='Search'
            aria-label='Search'
          />
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              <span className='material-icons'>search</span>
            </span>
          </div>
        </div>
      </form>
    </div>
  )
}
