import React, { useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { classes } from '../../helpers/classes'
import { FloatingInput } from '../floating-input/floating-input'

export interface MultiSelectItem {
  id: string
}

export interface MultiSelectOption<T> {
  id: string
  name: string
  value: T
}

export interface MultiSelectProps<T> {
  options: Array<MultiSelectOption<T>>
  selectedValues?: T[]
  onChange: (selectedValues: T[]) => void
  id: string
  label?: string
  placeholder?: string
  helperText?: string
  small?: boolean
  canSelectGroup?: boolean
  canSelectNone?: boolean
  disabled?: boolean
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const [focused, setFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [highlightIndex, setHighlightIndex] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()

  const close = () => {
    setFocused(false)
    setSearchValue('')
    setHighlightIndex(0)
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, close)

  const lowerCaseSearchValue = searchValue.toLocaleLowerCase()

  // const setFocused = (b: boolean) => setFocused2(true)
  const none = {
    name: 'None',
    id: '',
    value: '',
    items: [],
  } as any as MultiSelectOption<T>

  const options: MultiSelectOption<T>[] = props.canSelectNone ? [none, ...props.options] : props.options

  const setSelected = (selected: MultiSelectOption<T>) => {
    const existingIndex = props.selectedValues.findIndex((x) => x === selected.value)
    if (existingIndex === -1) {
      props.onChange([...(props.selectedValues || []), selected.value])
    } else {
      const n = [...(props.selectedValues || [])]
      n.splice(existingIndex, 1)
      props.onChange(n)
    }

    setSearchValue('')
  }

  let valueToUse = searchValue
  if (!focused && props.selectedValues && props.selectedValues.length > 0) {
    valueToUse = props.selectedValues
      .map((v) => {
        return props.options.find((x) => x.value === v)
      })
      .filter((x) => !!x)
      .map((x) => x.name)
      .join(', ')
    // for (const group of options) {
    //   if (group.id === props.options.find(o => o.value === props.selectedValue)) {
    //     valueToUse = group.name
    //     break
    //   }
    // }
  }

  let renderIndex = 0

  return (
    <div
      className={classes({
        'asseti-select': true,
        'small-select': props.small,
      })}
      onFocus={() => {
        if (props.disabled) {
          return
        }
        setFocused(true)
        inputRef.current.focus()
      }}
      ref={containerRef}
    >
      <FloatingInput
        disabled={props.disabled}
        label={props.label}
        // placeholder={props.placeholder}
        id={props.id}
        value={valueToUse}
        inputRef={inputRef}
        onChange={(e) => {
          setSearchValue(e.target.value || '')
        }}
        focused={focused}
        onFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onLabelFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onBlur={() => {}}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            setHighlightIndex(Math.min(highlightIndex + 1, renderIndex - 1))
          } else if (e.key === 'ArrowUp') {
            setHighlightIndex(Math.max(highlightIndex - 1, 0))
          } else if (e.key === 'Enter') {
            const elements = document.getElementsByClassName('asseti-select-highlighted')
            if (elements.length > 0) {
              const div = elements[0] as HTMLDivElement
              div.click()
              // close()
            }
          } else if (e.key === 'Escape') {
            close()
          }
        }}
        helperText={props.helperText}
      />
      {focused && (
        <div
          className='asseti-select-options-container'
          ref={wrapperRef}
          onBlur={() => {
            // setTimeout(() => {
            //   close()
            // }, 150)
          }}
        >
          <SimpleBar style={{ maxHeight: '310px' }}>
            {options
              .filter((o) => {
                // Filter options.
                if (lowerCaseSearchValue !== '') {
                  const matchesSearch = o.name.toLocaleLowerCase().includes(lowerCaseSearchValue)
                  if (!matchesSearch) {
                    return false
                  }
                }

                return true
              })
              .map((o) => {
                renderIndex++

                return (
                  <div
                    key={o.id}
                    className={classes({
                      'asseti-select-options-item': true,
                      selected: props.selectedValues.includes(o.value),
                      'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                    })}
                    onClick={(e) => {
                      e.preventDefault()
                      setSelected(o)
                    }}
                  >
                    {props.selectedValues.includes(o.value) && <i className='material-icons'>check</i>} {o.name}
                  </div>
                )
              })}
          </SimpleBar>
        </div>
      )}
    </div>
  )
}

function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement>, fn: () => void) {
  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
