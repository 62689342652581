import React, { useState } from 'react'
import { BaseLayerType, useAppState } from '~/state'
import { MeasureArea } from './measure-area'
import { MeasureDistance } from './measure-distance'
import { MeasureVolume } from './measure-volume'

import { MeasureDistance2D } from './measure-distance-2d'
import { MeasureArea2D } from './measure-area-2d'
import { MeasureProfile } from './measure-profile'

enum MeasurementType {
  DISTANCE,
  AREA,
  VOLUME,
  PROFILE,
}

export const Measure = () => {
  const { drawer, map } = useAppState()
  const [measurementType, setMeasurementType] = useState<MeasurementType>(MeasurementType.DISTANCE)

  const handleMapControlsPosition = () => {
    const screenWidth = window.innerWidth
    const screenRemaining = screenWidth - 724 - 250
    const controlsPosition = 724 + (screenRemaining - 300) / 2

    if (drawer.rightExpanded) {
      return { right: controlsPosition, left: 'auto' }
    }

    if (drawer.leftExpanded) {
      return { left: controlsPosition }
    }

    return null
  }

  const isDistance = measurementType === MeasurementType.DISTANCE
  const isArea = measurementType === MeasurementType.AREA
  const isVolume = measurementType === MeasurementType.VOLUME
  const isProfile = measurementType === MeasurementType.PROFILE

  return (
    <>
      <div className='map-controls-lower' style={handleMapControlsPosition()}>
        <div
          className={`map-control fullscreen ${isDistance ? 'active' : ''}`}
          onClick={() => {
            if (!isDistance) {
              setMeasurementType(MeasurementType.DISTANCE)
            }
          }}
          title='Distance'
        >
          <i className='material-icons'>straighten</i>
        </div>
        <div
          className={`map-control fullscreen ${isArea ? 'active' : ''}`}
          onClick={() => {
            if (!isArea) {
              setMeasurementType(MeasurementType.AREA)
            }
          }}
          title='Area'
        >
        <i className='material-icons'>crop_landscape</i>
        </div>
        {map.baseLayer === BaseLayerType.PhotoModel && (
          <div
            className={`map-control fullscreen ${isVolume ? 'active' : ''}`}
            onClick={() => {
              if (!isVolume) {
                setMeasurementType(MeasurementType.VOLUME)
              }
            }}
            title='Volume'
          >
          <i className='material-icons'>view_in_ar</i>
          </div>
        )}
        {map.baseLayer === BaseLayerType.PhotoModel && (
          <div
            className={`map-control fullscreen ${isProfile ? 'active' : ''}`}
            onClick={() => {
              if (!isVolume) {
                setMeasurementType(MeasurementType.PROFILE)
              }
            }}
            title='Profile'
          >
          <i className='material-icons'>landscape</i>
          </div>
        )}
      </div>

      {isDistance && map.baseLayer !== BaseLayerType.PhotoModel && <MeasureDistance2D />}
      {isDistance && map.baseLayer === BaseLayerType.PhotoModel && <MeasureDistance />}
      {isArea && map.baseLayer === BaseLayerType.PhotoModel && <MeasureArea />}
      {isArea && map.baseLayer !== BaseLayerType.PhotoModel && <MeasureArea2D />}
      {isVolume && map.baseLayer === BaseLayerType.PhotoModel && <MeasureVolume />}
      {isProfile && map.baseLayer === BaseLayerType.PhotoModel && <MeasureProfile />}
    </>
  )
}
