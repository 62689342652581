import { SystemRole } from "./gql-types"

export function systemRoleToString(role: SystemRole) {
  switch (role) {
    case SystemRole.Client:
      return 'Client'
    case SystemRole.Staff:
      return 'Staff'
    case SystemRole.Supplier:
      return 'Supplier'
  }
}
