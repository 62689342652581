interface HelmetProps {
  title?: string
  defaultTitle?: string
  titleTemplate?: string
}

let helmetDefaultTitle = ''
let helmetTitleTemplate = ''

export const Helmet = (props: HelmetProps): JSX.Element => {
  if(props.defaultTitle && props.defaultTitle !== '') {
    helmetDefaultTitle = props.defaultTitle
  }
  if(props.titleTemplate && props.titleTemplate !== '') {
    helmetTitleTemplate = props.titleTemplate
  }

  if(props.title) {
    if(helmetTitleTemplate !== '') {
      document.title = helmetTitleTemplate.replace('%s', props.title)
    } else {
      document.title = props.title || 'Asseti'
    }
  } else {
    document.title = props.defaultTitle || helmetDefaultTitle || 'Asseti'
  }
  return null
}