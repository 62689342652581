import { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { AnnotationType } from '~/models'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'
import { formatAnnotationsName } from './annotations-utils'
import { useUser } from 'base'

export function useAnnotationLayers() {
  const { map, entityContext, annotations } = useAppState()
  const user = useUser()
  const [polygonRenderers, setPolygonRenderers] = useState<PolygonRenderer3DView[]>([])

  // Draw initial polygons.
  useEffect(() => {
    if (map.morphing || annotations.annotations.length === 0 || annotations.annotationToUpdate !== undefined) {
      setPolygonRenderers([])
      return
    }

    const newPolygonRenderers: PolygonRenderer3DView[] = []

    for (const annotation of annotations.annotations) {
      const haveChildren = false
      const pr = new PolygonRenderer3DView({
        id: annotation.id,
        label: formatAnnotationsName(annotation, user.org.measurementSystem),
        points: annotation.type === AnnotationType.LengthWidth ? annotation.points.slice(0, 3) : annotation.points,
        map,
        renderPoints: false,
        color: '#057cb8',
        scaleByDistance: new Cesium.NearFarScalar(10, haveChildren ? 0.8 : 0.8, 40, 0.8),
        translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
        annotationType:
          annotation.type === AnnotationType.Point
            ? AnnotationType.Point
            : annotation.type === AnnotationType.Area || annotation.type === AnnotationType.Volume
            ? AnnotationType.Area
            : AnnotationType.Distance,
        fill:
          annotation.type !== AnnotationType.GroundEavesRidge &&
          annotation.type !== AnnotationType.Distance &&
          annotation.type !== AnnotationType.LengthWidth,
        onClick: () => {
          entityContext.set(annotation)
        },
        clamp: false,
      })

      pr.setVisible(annotations.draw)
      if (annotations.draw && annotations.selectedAnnotation) {
        if (annotations.selectedAnnotation.id === annotation.id) {
          pr.setVisible(false)
        }
      }
      newPolygonRenderers.push(pr)
    }

    setPolygonRenderers(newPolygonRenderers)

    return () => {
      newPolygonRenderers.forEach((p) => p.destroy())
      setPolygonRenderers([])
    }
  }, [annotations.annotations, annotations.annotationToUpdate?.id, map.morphing])

  // Draw selected polygon
  useEffect(() => {
    if (map.morphing || !annotations.selectedAnnotation || annotations.annotationToUpdate !== undefined) {
      return
    }

    const a = annotations.annotations.find((x) => x.id === annotations.selectedAnnotation.id)
    if (!a) {
      return
    }

    const pr = new PolygonRenderer3DView({
      id: a.id + '-selected',
      label: formatAnnotationsName(a, user.org.measurementSystem),
      points: a.type === AnnotationType.LengthWidth ? a.points.slice(0, 3) : a.points,
      map,
      renderPoints: false,
      color: '#057cb8',
      annotationType:
        a.type === AnnotationType.Point
          ? AnnotationType.Point
          : a.type === AnnotationType.Area || a.type === AnnotationType.Volume
          ? AnnotationType.Area
          : AnnotationType.Distance,
      fill:
        a.type !== AnnotationType.GroundEavesRidge &&
        a.type !== AnnotationType.Distance &&
        a.type !== AnnotationType.LengthWidth,
      onClick: entityContext.clear,
    })

    const existingPrIndex = polygonRenderers.findIndex((p) => p.id === annotations.selectedAnnotation.id)
    if (existingPrIndex !== -1) {
      polygonRenderers[existingPrIndex].setVisible(false)
    }

    return () => {
      pr.destroy()
      if (existingPrIndex !== -1) {
        polygonRenderers[existingPrIndex].setVisible(true)
      }
    }
  }, [annotations.selectedAnnotation?.id, annotations.annotationToUpdate?.id, map.morphing, annotations.annotations])

  useEffect(() => {
    for (const p of polygonRenderers) {
      if (p.id !== annotations.selectedAnnotation?.id) {
        p.setVisible(annotations.draw)
        p.setFill(annotations.draw)
      }
    }
  }, [polygonRenderers, annotations.draw, annotations.selectedAnnotation])
}
