import React, { useState, useRef, useEffect } from 'react'
import { Popover, PopoverAnchor, PopoverContent } from 'ui/popover/popover'

export const MapWithPopover = () => {
  const [open, setOpen] = useState(false)
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const mapRef = useRef<HTMLDivElement>(null)

  const handleMapClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const x = event.clientX
    const y = event.clientY

    setCoords({ x, y })
    setOpen(true)
  }

  const virtualElementRef = useRef<{
    getBoundingClientRect: () => DOMRect
  }>({
    getBoundingClientRect: () => new DOMRect(0, 0, 0, 0),
  })

  useEffect(() => {
    virtualElementRef.current.getBoundingClientRect = () => new DOMRect(coords.x, coords.y, 0, 0)
  }, [coords])

  return (
    <div>
      <div
        ref={mapRef}
        onClick={handleMapClick}
        style={{ width: '100%', height: '500px', position: 'relative', background: 'blue' }}
      ></div>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverAnchor virtualRef={virtualElementRef} />
        <PopoverContent align='center' onInteractOutside={() => setOpen(false)}>
          <div>Your popover content</div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
