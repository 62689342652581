import React, { useState } from 'react'
import Collapse from 'reactstrap/es/Collapse'
import { classes } from '~/components'

interface AccordionProps {
  title: string
  children: any
  defaultClosed?: boolean
}

export const Accordion = (props: AccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.defaultClosed === true ? false : true)

  return (
    <>
      <div
        className={classes({
          'accordion-heading': true,
          open: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className='material-icons'>{isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</i>
        {props.title}
      </div>
      <Collapse isOpen={isOpen}>
        <div className='accordion-content'>{isOpen && props.children}</div>
      </Collapse>
    </>
  )
}
