import React from 'react'
import { AnnotationsFormCreateProps } from './annotations-form-create-props'
import { AnnotationRulerDistance } from './rulers'

export const AnnotationsFormCreateDistance = (props: AnnotationsFormCreateProps) => {
  return (
    <AnnotationRulerDistance
      pointsChanged={(points, measurements) => {
        props.setModel({
          ...props.model,
          points: points.map((p) => ({
            longitude: Cesium.Math.toDegrees(p.longitude),
            latitude: Cesium.Math.toDegrees(p.latitude),
            altitude: p.height,
          })),
          measurements,
        })
      }}
      points={props.model.points}
    />
  )
}
