import banner from 'images/instant-assess-sign-up-banner.png'
import React from 'react'

type Props = {
  onMsg: (msg: Msg) => void
}

type Msg = { type: 'get_started_clicked' }

export const Welcome = ({ onMsg }: Props) => {
  return (
    <>
      <div className='instant-assess-layout-banner'>
        <img alt='instant-assess-sign-up-banner' src={banner} />
      </div>

      <h5 className='instant-assess-layout-header'>Welcome to Asseti Instant Assess</h5>

      <p>Happy to have you onboard!</p>
      {/* <p>Let's create an account to get you started</p> */}

      <hr />

      <button
        className='btn btn-instant-assess'
        type='button'
        onClick={() => {
          onMsg({ type: 'get_started_clicked' })
        }}
      >
        Let's get started
      </button>
    </>
  )
}
