import React from 'react'
import { SimpleBar } from '../simple-bar'
import { classes } from '../helpers'

interface DrawerFormProps {
  children: any[]
  inline?: boolean
  shifted?: boolean
  wide?: boolean
  hideRightCover?: boolean
}

export const DrawerForm = (props: DrawerFormProps) => {
  return (
    <div
      className={classes({
        'drawer-form': true,
        inline: props.inline,
        shifted: props.shifted,
        wide: props.wide,
      })}
    >
      {props.children}
      {!props.inline && !props.shifted && !props.hideRightCover && <div className='drawer-form-right-cover'></div>}
    </div>
  )
}

interface DrawerFormBreadcrumbsProps {
  children: any | any[]
  wide?: boolean
}

export const DrawerFormBreadcrumbs = (props: DrawerFormBreadcrumbsProps) => {
  return (
    <nav aria-label='breadcrumb'>
      <ol
        className={classes({
          breadcrumb: true,
          wide: props.wide,
        })}
      >
        {props.children}
      </ol>
    </nav>
  )
}

interface DrawerFormBreadcrumbItemProps {
  title: string
  onClick?: () => void
}

export const DrawerFormBreadcrumbItem = (props: DrawerFormBreadcrumbItemProps) => {
  if (props.onClick) {
    return (
      <li className='breadcrumb-item'>
        <a href='#' onClick={props.onClick}>
          {props.title}
        </a>
      </li>
    )
  }

  return <li className='breadcrumb-item active'>{props.title}</li>
}

interface DrawerFormHeadingProps {
  title: string
  helpText?: string
}

export const DrawerFormHeading = (props: DrawerFormHeadingProps) => {
  return (
    <div className='drawer-form-heading'>
      <h6>{props.title}</h6>
      {props.helpText && (
        <i className='material-icons' title={props.helpText}>
          help_outline
        </i>
      )}
    </div>
  )
}

interface DrawerFormContentProps {
  children: any
  noPaddingRight?: boolean
}

export const DrawerFormContent = (props: DrawerFormContentProps) => {
  return (
    <div
      className={classes({
        'drawer-form-content': true,
        'no-right-padding': props.noPaddingRight,
      })}
    >
      <SimpleBar>
        {props.children}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </SimpleBar>
    </div>
  )
}

interface DrawerFormButtonsProps {
  children: any
}

export const DrawerFormButtons = (props: DrawerFormButtonsProps) => {
  return <div className='drawer-form-buttons'>{props.children}</div>
}

interface DrawerFormButtonProps {
  children: any
  disabled?: boolean
  form?: string
  title?: string
  onClick?: () => void
}

export const DrawerFormSubmitButton = (props: DrawerFormButtonProps) => {
  return (
    <button
      type='submit'
      className='btn drawer-form-buttons-submit'
      disabled={props.disabled}
      form={props.form}
      title={props.title}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export const DrawerFormCancelButton = (props: DrawerFormButtonProps) => {
  return (
    <button
      type='button'
      className='btn drawer-form-buttons-cancel'
      disabled={props.disabled}
      form={props.form}
      title={props.title}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
