import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'

interface ConfirmModalProps {
  close: () => void
  onConfirm: () => Promise<any>
  title: string
  text: string
  confirmText: string
  confirmSubmittingText: string
  rejectText?: string
  color?: string
  note?: string
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | undefined>()

  return (
    <Modal isOpen={true} toggle={props.close} centered>
      <ModalHeader toggle={props.close}>{props.title}</ModalHeader>
      <ModalBody>
        {error && <Alert color='danger'>{error}</Alert>}
        <p>{props.text}</p>
        {props.note && <p>Note: {props.note}</p>}
      </ModalBody>
      <ModalFooter>
        <Button
          color={props.color || 'primary'}
          disabled={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            setError(undefined)
            props
              .onConfirm()
              .then(() => props.close())
              .catch((err) => {
                setError(err)
              })
              .finally(() => {
                setIsSubmitting(false)
              })
          }}
        >
          {isSubmitting ? props.confirmSubmittingText : props.confirmText}
        </Button>{' '}
        <Button color='secondary' disabled={isSubmitting} onClick={props.close}>
          {props.rejectText || 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
