import { useEffect, useMemo } from 'react'
import { SiteQueryComponent, SiteQueryMonitoringZone, AnnotationDtoT } from '~/models'
import { useComponentState } from './use-component-state'
import { useAnnotations } from './use-annotations'
import { useMonitoringZoneState } from './use-monitoring-zone-state'
import { useViewState } from './view-state'

interface UseEntityContextProps {
  view: ReturnType<typeof useViewState>
  components: ReturnType<typeof useComponentState>
  annotations: ReturnType<typeof useAnnotations>
  monitoringZones: ReturnType<typeof useMonitoringZoneState>
}

export function useEntityContext(props: UseEntityContextProps) {
  const clear = useMemo(() => {
    return () => {
      props.components.setSelectedComponent(undefined)
      props.annotations.setSelectedAnnotation(undefined)
      props.monitoringZones.setSelectedMonitoringZone(undefined)
    }
  }, [
    props.components.selectedComponent,
    props.annotations.selectedAnnotation,
    props.monitoringZones.selectedMonitoringZone,
  ])

  const set = useMemo(() => {
    return (entity: SiteQueryComponent | SiteQueryMonitoringZone | AnnotationDtoT) => {
      props.components.setSelectedComponent(undefined)
      props.annotations.setSelectedAnnotation(undefined)
      props.monitoringZones.setSelectedMonitoringZone(undefined)

      setTimeout(() => {
        if ('condition' in entity) {
          props.components.setSelectedComponent(entity)
        } else if ('targetID' in entity) {
          props.monitoringZones.setSelectedMonitoringZone(entity)
        } else if ('measurements' in entity) {
          props.annotations.setSelectedAnnotation(entity)
        }
      }, 0)
    }
  }, [
    props.components.selectedComponent,
    props.annotations.selectedAnnotation,
    props.monitoringZones.selectedMonitoringZone,
  ])

  useEffect(() => {
    props.components.setSelectedComponent(undefined)
    props.annotations.setSelectedAnnotation(undefined)
    props.monitoringZones.setSelectedMonitoringZone(undefined)
  }, [props.view.siteID, props.view.surveyID])

  return {
    clear,
    set,
  }
}
