import { LeftClickHandler, Marker, MarkerIconType } from '~/state'
import { AnnotationType, Point3Dt } from '~/models'
import { Map } from './polygon-renderer'
import { getPolygonCenter3DMaxHeight, getPolygonLongestLineCenter3DMaxHeight } from '../helpers'
import { PolygonRenderer3DWithoutEvents } from './polygon-renderer-without-events'

interface PolygonRenderer3DViewConstructor {
  id: string
  points: Point3Dt[]
  color: string
  map: Map
  label?: string
  onClick?: () => void
  onRightClick?: LeftClickHandler
  renderPoints?: boolean
  scaleByDistance?: Cesium.NearFarScalar
  translucencyByDistance?: Cesium.NearFarScalar
  annotationType: AnnotationType
  fill?: boolean
  clamp?: boolean
}

export class PolygonRenderer3DView extends PolygonRenderer3DWithoutEvents {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _polylineEntity: Cesium.Entity
  private _polygonEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _pointsLngLat: number[][]
  private _centerLngLat: number[]
  private _marker: Marker
  private _label?: string
  private _renderPoints: boolean
  private _scaleByDistance: Cesium.NearFarScalar
  private _translucencyByDistance: Cesium.NearFarScalar
  private _annotationType: AnnotationType
  public id: string

  constructor(input: PolygonRenderer3DViewConstructor) {
    super(input.map, input.color)

    this.id = input.id

    this._annotationType = input.annotationType
    this._positions = input.points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude + 0.05))
    this._points = new Cesium.PointPrimitiveCollection()
    this._positions.forEach((p, i) => {
      this._points.add(this.createPoint(p, i, 6, 'viewing-point-' + input.id + '-' + i))
    })
    this._pointsLngLat = input.points.map((p) => {
      return [p.longitude, p.latitude, p.altitude]
    })
    this._centerLngLat = getPolygonCenter3DMaxHeight(this._pointsLngLat)
    this._label = input.label
    this._scaleByDistance = input.scaleByDistance
    this._translucencyByDistance = input.translucencyByDistance
    this._renderPoints = input.renderPoints === undefined ? true : input.renderPoints

    // const is3D = this._map.viewer.scene.mode !== Cesium.SceneMode.SCENE2D
    if (this._label) {
      if (this._annotationType === AnnotationType.Distance) {
        const midpoint = getPolygonLongestLineCenter3DMaxHeight(this._pointsLngLat)
        const center = Cesium.Cartographic.fromCartesian(midpoint)
        this._marker = input.map.addMarker(
          input.id,
          [Cesium.Math.toDegrees(center.longitude), Cesium.Math.toDegrees(center.latitude), center.height],
          input.onClick,
          this._label,
          32,
          this._color,
          MarkerIconType.None,
          this._scaleByDistance,
          this._translucencyByDistance,
          input.clamp
            ? (new Cesium.CallbackProperty(computeHeight(input.map, this._centerLngLat), false) as any)
            : undefined,
          undefined,
          input.onRightClick
        )
      } else {
        this._marker = input.map.addMarker(
          input.id,
          this._centerLngLat,
          input.onClick,
          this._label,
          32,
          this._color,
          MarkerIconType.None,
          this._scaleByDistance,
          this._translucencyByDistance,
          input.clamp
            ? (new Cesium.CallbackProperty(computeHeight(input.map, this._centerLngLat), false) as any)
            : undefined,
          undefined,
          input.onRightClick
        )
      }
    }

    const polyline = this.createPolylineConstructor(
      this._annotationType === AnnotationType.Area ? [...this._positions, this._positions[0]] : [...this._positions],
      false,
      input.clamp === true
    )
    this._polylineEntity = input.map.viewer.entities.add({ polyline, id: input.id })

    if (this._annotationType === AnnotationType.Area) {
      this._polygonEntity = input.map.viewer.entities.add({
        polygon: this.createPolygonConstructor(
          new Cesium.PolygonHierarchy([...this._positions, this._positions[0]].map((p) => p.clone())),
          false,
          false
        ),
      })
      this._polygonEntity.show = input.fill
    }

    if (this._renderPoints) {
      // this._pointsEntity = input.map.viewer.scene.primitives.add(this._points)
    }
  }

  public destroy() {
    this._map.viewer.entities.removeById(this.id)
    if (this._polygonEntity) {
      this._map.viewer.entities.remove(this._polygonEntity)
      this._polygonEntity = undefined
    }
    if (this._pointsEntity) {
      this._map.viewer.scene.primitives.remove(this._pointsEntity)
      this._pointsEntity = undefined
    }
    if (this._label) {
      this._map.removeMarker(this._marker)
      this._label = undefined
    }
    super.destroy()
  }

  public setFill(fill: boolean) {
    if (this._polygonEntity && this._annotationType === AnnotationType.Area) {
      this._polygonEntity.show = fill
    }
  }

  public setVisible(visible: boolean) {
    this._polylineEntity.show = visible
    if (this._marker && this._marker.label) {
      this._marker.label.show = visible
    }
    if (this._pointsEntity) {
      this._pointsEntity.show = visible
    }
  }

  public setLabelPosition(pos: Cesium.Cartesian3) {
    ;(this._marker.label.position as any) = pos
  }
}

function computeHeight(map: Map, position: number[]): (time?: Cesium.JulianDate, result?: any) => Cesium.Cartesian3 {
  let currentPos = Cesium.Cartesian3.fromDegrees(position[0], position[1], position[2] || 0)
  const originalCartographic = Cesium.Cartographic.fromCartesian(currentPos)

  setTimeout(() => {
    try {
      const height = map.viewer.scene.sampleHeight(originalCartographic, [])
      const newPos = originalCartographic.clone()
      newPos.height = height
      currentPos = Cesium.Cartesian3.fromRadians(newPos.longitude, newPos.latitude, newPos.height + 2)
    } catch {}
  }, 2000)

  setTimeout(() => {
    try {
      const height = map.viewer.scene.sampleHeight(originalCartographic, [])
      const newPos = originalCartographic.clone()
      newPos.height = height
      currentPos = Cesium.Cartesian3.fromRadians(newPos.longitude, newPos.latitude, newPos.height + 2)
    } catch {}
  }, 15000)

  return () => {
    return currentPos
  }
}
