import React, { useEffect, useRef, useState } from 'react'
// import ASSETI_LOGO from '../images/asseti-logo.svg'
// import { Link } from 'react-router-dom'
import CHEVRONS from '../images/chevrons-right.svg'
import { ClientPermission, useUser } from '~/base'
import { useAppState } from '~/state'
import {
  ActionHover,
  classes,
  getCorrestHostURL,
  getRegionalSubdomain,
  getRequiredHost,
  useLocation,
} from '~/components'
import { SitesQuerySiteWithExtra } from '~/models'
import { SitesListViewType } from './sites-list-types'

interface SitesHeaderProps {
  onCreateSite: () => void
  filterSites: (site: string) => void
  siteResults: SitesQuerySiteWithExtra[]
  haveSites: boolean
  viewType: SitesListViewType
  name?: string
  extraLinks?: Array<{
    name: string
    description: string
    url: string
  }>
  canUpdate?: boolean
  canRemove?: boolean
  onClickEdit?: () => void
  onClickDelete?: () => void
  permission: ClientPermission
}

export const SitesHeader = (props: SitesHeaderProps) => {
  const { drawer, search, sites } = useAppState()
  const location = useLocation()
  const user = useUser()
  const isIA = user.isInstantAssessmentOrg

  const resetView = () => {
    location.setLocation('/')
  }

  const canCreateSites = user.hasPermission(props.permission)

  return (
    <div className='sites-header'>
      <div className='sites-header-top'>
        {/* <Link to='/' className='link'>
          <img
            onClick={() => {
              resetView()
            }}
            src={ASSETI_LOGO}
            alt='Asseti Logo'
          />
          <hr className='sites-header-top-divider' />
        </Link> */}
        <div
          onClick={() => {
            resetView()
          }}
          className='sites-header-top-title-container'
        >
          <span className='sites-header-top-org-title'>
            <OrgDropdown />
          </span>
        </div>
        {props.viewType === SitesListViewType.Sites && (
          <div
            className='sites-header-top-expand'
            onClick={() => {
              drawer.toggleLeft()
              if (search.searchingForLocation) {
                search.reset()
              }
            }}
          >
            {drawer.leftExpanded ? (
              <img src={CHEVRONS} alt='Expand Drawer' className='sites-header-top-expand-expanded' />
            ) : (
              <img src={CHEVRONS} alt='Close Drawer' />
            )}
          </div>
        )}
      </div>
      <div className='sites-header-middle'>
        <i
          className={classes({
            'sites-header-middle-add material-icons': true,
            disabled: !canCreateSites,
          })}
          onClick={() => {
            if (canCreateSites) {
              props.onCreateSite()
            }
          }}
        >
          add
        </i>
        <h6 className='sites-header-middle-sites-title'>
          {sites.canSeeGroups && !isIA && (
            <SiteViewDropdown viewType={props.viewType} name={props.name} extraLinks={props.extraLinks} />
          )}
          {(!sites.canSeeGroups || isIA) && 'Sites'}
        </h6>
        {(props.canUpdate || props.canRemove) && (
          <div className='sites-header-middle-actions-container'>
            <ActionHover
              withContainer
              canEdit={props.canUpdate}
              canRemove={props.canRemove}
              onClickEdit={props.onClickEdit}
              onClickDelete={props.onClickDelete}
            />
          </div>
        )}
        {/* <i className='sites-header-middle-dropdown material-icons'>arrow_drop_down</i> */}
      </div>
      <div className='sites-header-bottom'>
        <div className='sites-header-bottom-sites-search'>
          <form
            autoComplete='off'
            className='form-group'
            onSubmit={(e) => {
              if (props.siteResults.length === 1) {
                if (drawer.leftExpanded) {
                  drawer.toggleLeft()
                }
                location.setLocation('/' + props.siteResults[0].s.id)
              }
              e.preventDefault()
            }}
          >
            <div className='input-group'>
              <input
                onChange={(e) => {
                  props.filterSites(e.target.value)
                }}
                type='text'
                className='form-control'
                placeholder='Search'
                aria-label='Search'
                disabled={!props.haveSites}
                value={search.search}
              />
              <div className='input-group-append'>
                <span
                  className={classes({
                    'input-group-text': true,
                    toggled: search.searchingForLocation,
                  })}
                  onClick={() => {
                    search.setSearchingForLocation(!search.searchingForLocation)
                  }}
                >
                  <span className='material-icons'>{drawer.leftExpanded ? 'travel_explore' : 'search'}</span>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function OrgDropdown() {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)
  const user = useUser()
  const { drawer } = useAppState()

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  if (user.orgs.length === 1 && user.suppliers.length === 0) {
    return <span>{user.orgs[0].name.toLocaleUpperCase()}</span>
  }

  return (
    <div className='org-dropdown-content'>
      <div ref={dropdownNode} className='dropdown org-dropdown'>
        <button
          className={`btn dropdown-toggle ${open ? 'active' : ''}`}
          type='button'
          id={`dropdownMenuButton-org`}
          data-toggle={`dropdown-org`}
          aria-haspopup='true'
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          {user.orgs.find((o) => o.id === user.currentOrgId).name}
        </button>
        <div
          className={classes({
            'dropdown-menu': true,
            expanded: drawer.leftExpanded,
          })}
          aria-labelledby={`dropdownMenuButton-orgs`}
          style={{ display: open ? 'block' : 'none' }}
        >
          {user.orgs
            .filter((o) => o.id !== user.currentOrgId)
            .map((o) => {
              const url = getCorrestHostURL(o.domainName, `/auth?t=${user.token}&id=${o.id}`)
              return (
                <a className='dropdown-item' href={url} key={o.name}>
                  <div className='dropdown-item-container'>
                    {o.name}
                    <div style={{ textTransform: 'none' }}>
                      {o.domainName}
                      {getRequiredHost()}
                    </div>
                  </div>
                </a>
              )
            })}
          {user.suppliers.map((o) => {
            const url = getCorrestHostURL(getRegionalSubdomain(), `/auth?t=${user.token}&sid=${o.id}`)
            return (
              <a className='dropdown-item' href={url} key={o.name}>
                <div className='dropdown-item-container'>
                  {o.name}
                  <div style={{ textTransform: 'none' }}>Supplier</div>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

interface SiteViewDropdownProps {
  viewType: SitesListViewType
  name?: string
  extraLinks?: Array<{
    name: string
    description: string
    url: string
  }>
}

function SiteViewDropdown(props: SiteViewDropdownProps) {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)
  const { drawer, search } = useAppState()
  const location = useLocation()

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    return () => {
      search.setSearch('')
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const showSites = props.viewType !== SitesListViewType.Sites
  const showGroups = props.viewType !== SitesListViewType.Groups

  const name = props.name
    ? props.name
    : props.viewType === SitesListViewType.Sites
    ? 'Sites'
    : props.viewType === SitesListViewType.Groups
    ? 'Groups'
    : 'Group'

  return (
    <div className='site-view-dropdown-content'>
      <div ref={dropdownNode} className='dropdown site-view-dropdown'>
        <button className={`btn dropdown-toggle ${open ? 'active' : ''}`} type='button' onClick={() => setOpen(!open)}>
          {name}
        </button>
        <div
          className={classes({
            'dropdown-menu': true,
            expanded: drawer.leftExpanded,
          })}
          style={{ display: open ? 'block' : 'none' }}
        >
          {showGroups && (
            <div
              className='dropdown-item'
              onClick={() => {
                location.setLocation('/groups')
                setOpen(false)
              }}
            >
              <div className='dropdown-item-container'>
                Groups
                <div style={{ textTransform: 'none' }}>View site groups</div>
              </div>
            </div>
          )}
          {showSites && (
            <div
              className='dropdown-item'
              onClick={() => {
                location.setLocation('/')
                setOpen(false)
              }}
            >
              <div className='dropdown-item-container'>
                Sites
                <div style={{ textTransform: 'none' }}>View all sites</div>
              </div>
            </div>
          )}
          {Array.isArray(props.extraLinks) &&
            props.extraLinks.map((x) => {
              return (
                <div
                  key={x.url}
                  className='dropdown-item'
                  onClick={() => {
                    location.setLocation(x.url)
                    setOpen(false)
                  }}
                >
                  <div className='dropdown-item-container'>
                    {x.name}
                    <div style={{ textTransform: 'none' }}>{x.description}</div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
