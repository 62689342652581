import React, { useMemo } from 'react'
import { Select2, Select2Option } from '../forms'
import { ComponentTypeSmallDtoT, MaterialTypeSmallDtoT } from '~/models'

interface ComponentTypeSelectProps {
  componentTypes: ComponentTypeSmallDtoT[]
  selectedComponentTypes: ComponentTypeSmallDtoT[]
  selectedMaterialType: MaterialTypeSmallDtoT
  onChange?: (selectedComponentTypes: ComponentTypeSmallDtoT[], selectedMaterialType: MaterialTypeSmallDtoT) => void

  label?: string
  disabled?: boolean
}

export const ComponentTypeSelect = (props: ComponentTypeSelectProps) => {
  const selected = props.selectedMaterialType
    ? [...props.selectedComponentTypes, props.selectedMaterialType]
    : props.selectedComponentTypes

  const options = useMemo(() => {
    const base = (props.componentTypes || []).filter((componentType) => !componentType.archived)

    function getItems(
      c: ComponentTypeSmallDtoT | MaterialTypeSmallDtoT
    ): Select2Option<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT> {
      const toReturn: Select2Option<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT> = {
        id: c.id,
        name: c.name,
        value: c,
        items: [],
      }

      const children = (c as any).children as ComponentTypeSmallDtoT[]
      const materialTypes = (c as any).materialTypes as MaterialTypeSmallDtoT[]
      const haveChildren = Array.isArray(children) && children.length > 0
      const haveMaterialTypes = Array.isArray(materialTypes) && materialTypes.length > 0
      if (haveChildren) {
        toReturn.items = children.map(getItems)
      } else if (haveMaterialTypes) {
        toReturn.items = materialTypes.map(getItems)
      }

      return toReturn
    }

    return base.map(getItems)
  }, [props.componentTypes])

  return (
    <Select2<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT>
      disabled={!props.componentTypes || props.disabled}
      id='component-types'
      label={props.label || 'Type'}
      placeholder='Select a type'
      options={options}
      onChange={(selected, tree) => {
        let mt : MaterialTypeSmallDtoT = undefined
        const cts = [...tree] as ComponentTypeSmallDtoT[]
        if((selected as any).__typename === 'MaterialTypeDtoT') {
          mt = selected
        } else {
          cts.push(selected as ComponentTypeSmallDtoT)
        }

        props.onChange(cts, mt)
      }}
      selectedValues={selected}
    />
  )
}
