import { LocationInputT } from '~/models'

export function getDefaultLocation(): LocationInputT {
  return {
    id: '',
    type: '',
    place_type: [],
    relevance: 0,
    properties: {
      accuracy: '',
      address: '',
      category: '',
      foursquare: '',
      landmark: false,
    },
    text: '',
    place_name: '',
    center: [],
    geometry: {
      type: '',
      coordinates: [],
    },
    address: '',
    context: [],
    bbox: [],
  }
}

function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function mergeLocations(target: any, source: any): LocationInputT {
  if (target['address'] !== undefined && (source['address'] === undefined || source['address'] === '')) {
    if ((source as any)['matching_place_name'] !== undefined) {
      source['address'] = (source as any)['matching_place_name']
    }
  }

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        }
        mergeLocations(target[key], source[key])
      } else {
        if (target[key] !== undefined) {
          if (Array.isArray(target[key])) {
            target[key] = [...source[key]]
            for (let i = 0; i < target[key].length; i++) {
              if(typeof target[key][i].__typename === 'string') {
                target[key][i] = {...target[key][i]}
                delete target[key][i]['__typename']
              }
            }
          } else {
            target[key] = source[key]
          }
        }
      }
    }
  }

  if (Array.isArray(target['context'])) {
    for(const item of target['context']) {
      if(!item['short_code']) {
        item['short_code'] = ''
      }
      if(!item['wikidata']) {
        item['wikidata'] = ''
      }
      if(!item['id']) {
        item['id'] = ''
      }
      if(!item['text']) {
        item['text'] = ''
      }
    }
  }

  return target as LocationInputT
}
