import { string, object, isOnCorrectHost, redirectToCorrectHost } from '~/components'

import { AuthButton, AuthInput, useForm, useLocation, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import {
  Resend2FaTokenMutation,
  Resend2FaTokenMutationVariables,
  SignInMutation,
  SignInRequestT,
  SignInWith2FaTokenMutation,
  SignInWith2FaTokenRequestT,
} from '~/models/gql-types'
import { useUser } from '~/base'

import { AuthError, AuthInfo } from './auth-error'
import { Helmet } from '~/components'
import React, { useEffect, useState } from 'react'
import { useMutation } from '~/components'
import mutation from './mutation-sign-in.gql'
import mutationWith2FAToken from './mutation-sign-in-with-2fa-token.gql'
import mutationResend2FAToken from './mutation-resend-2fa-token.gql'

export const AuthSignIn = () => {
  const location = useLocation()
  const tracking = getTracking()
  const user = useUser()
  const urlParams = new URLSearchParams(window.location.search)
  const [waitingFor2FA, setWaitingFor2FA] = useState(false)
  const [orgPendingApproval, setOrgPendingApproval] = useState(false)
  const [supplierPendingApproval, setSupplierPendingApproval] = useState(false)
  const [twoFAError, setTwoFAError] = useState<string | undefined>()
  const [executeResend2FAToken] = useMutation<Resend2FaTokenMutation, Resend2FaTokenMutationVariables>(
    mutationResend2FAToken
  )

  const signInForm = useForm<SignInMutation, SignInRequestT>({
    alwaysGenerateNewCorrelationId: true,
    initialValues: {
      email: '',
      password: '',
      domainName: window.location.hostname.split('.')[0] || 'test',
      supplierID: urlParams.has('sid') ? urlParams.get('sid') : '',
    },
    validationSchema: object().shape({
      email: string().email('Invalid email address').required('Required'),
      password: string().required('Required'),
    }),
    mutation,
    onSuccess: (result: SignInMutation) => {
      if (result.signIn.waitFor2FA) {
        setWaitingFor2FA(true)
        setTwoFAError(undefined)
        return
      }

      if (result.signIn.orgPendingApproval) {
        setOrgPendingApproval(true)
        return
      }

      if (result.signIn.supplierPendingApproval) {
        setSupplierPendingApproval(true)
        return
      }

      tracking.event({
        category: 'Form',
        action: `User signed in`,
        label: 'Auth',
      })

      // Redirect if required.
      const currentOrg = result.signIn.orgs.find((o) => o.id === result.signIn.currentOrgId)
      if (currentOrg && !isOnCorrectHost(currentOrg.domainName)) {
        const path = `/auth?t=${result.signIn.token}&id=${result.signIn.currentOrgId}`
        redirectToCorrectHost(currentOrg.domainName, path)
        return
      }

      user.setSignIn(result.signIn)
      if (urlParams.has('r')) {
        window.location.href = urlParams.get('r')
      } else {
        location.setLocation('/')
      }
    },
  })

  const signInWith2FATokenForm = useForm<SignInWith2FaTokenMutation, SignInWith2FaTokenRequestT>({
    alwaysGenerateNewCorrelationId: true,
    initialValues: {
      email: '',
      token: '',
      domainName: window.location.hostname.split('.')[0] || 'test',
    },
    validationSchema: object().shape({
      token: string().required('Required').length(6),
    }),
    mapInput: (i) => {
      return {
        email: signInForm.values.email,
        token: i.token,
        domainName: window.location.hostname.split('.')[0] || 'test',
      }
    },
    mutation: mutationWith2FAToken,
    onSuccess: (result: SignInWith2FaTokenMutation) => {
      tracking.event({
        category: 'Form',
        action: `User signed in`,
        label: 'Auth',
      })

      // Redirect if required.
      const currentOrg = result.signInWith2FAToken.orgs.find((o) => o.id === result.signInWith2FAToken.currentOrgId)
      if (currentOrg && !isOnCorrectHost(currentOrg.domainName)) {
        const path = `/auth?t=${result.signInWith2FAToken.token}&id=${result.signInWith2FAToken.currentOrgId}`
        redirectToCorrectHost(currentOrg.domainName, path)
        return
      }

      user.setSignIn(result.signInWith2FAToken)
      if (urlParams.has('r')) {
        window.location.href = urlParams.get('r')
      } else {
        location.setLocation('/')
      }
    },
  })

  const [resendingToken, setResendingToken] = useState(false)
  const [resentToken, setResentToken] = useState(false)

  useEffect(() => {
    if (typeof signInWith2FATokenForm.errors.token === 'string' || signInWith2FATokenForm.isSubmitting) {
      return
    }

    signInWith2FATokenForm.submitForm()
  }, [signInWith2FATokenForm.errors, signInWith2FATokenForm.isSubmitting])

  if (user.isLoggedIn) {
    location.setLocation('/')
    return null
  }

  if (orgPendingApproval || supplierPendingApproval) {
    return (
      <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
        <Helmet title='Sign In' />
        <>
          <div className='subheader-dark mt-5 mb-5'>Workspace Pending Approval</div>
          <div className='auth-description-text padding'>
            An email will be sent to your registered email address as soon as this is complete.
          </div>
        </>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
      <Helmet title='Sign In' />
      {waitingFor2FA && (
        <>
          <div className='subheader-dark mt-5 mb-5'>Two Factor Authentication</div>
          <div className='auth-description-text padding'>
            A sign in token has been emailed
            <br />
            to <b>{signInForm.values.email}</b>. Enter it below
          </div>
          {signInWith2FATokenForm.error && (
            <AuthError className='alert alert-warning mb-4' error={signInWith2FATokenForm.error} />
          )}
          {resentToken && <AuthInfo className='alert alert-warning mb-4' error={'The token was resent'} />}
          <form autoComplete='off' className='auth-form-container mb-3' onSubmit={signInWith2FATokenForm.handleSubmit}>
            <AuthInput
              id='token'
              label='Token'
              value={signInWith2FATokenForm.values.token}
              onChange={signInWith2FATokenForm.handleChange}
              onBlur={signInWith2FATokenForm.handleBlur}
              helperText={signInWith2FATokenForm.touched.token ? signInWith2FATokenForm.errors.token : ''}
              error={signInWith2FATokenForm.touched.token && Boolean(signInWith2FATokenForm.errors.token)}
            />
            <AuthButton disabled={signInWith2FATokenForm.isSubmitting}>
              {signInWith2FATokenForm.isSubmitting ? 'Signing in...' : 'Sign In'}
            </AuthButton>
            <AuthLinks>
              <AuthLink
                to='/resend-token'
                className='mb-3'
                disabled={resendingToken}
                onClick={() => {
                  setResendingToken(true)
                  setResentToken(false)
                  executeResend2FAToken({
                    variables: {
                      input: {
                        email: signInForm.values.email,
                      },
                    },
                  })
                    .then((res) => {
                      if (!res.data.resend2FAToken.success) {
                        setWaitingFor2FA(false)
                        setTwoFAError('All sign in tokens have expired. Please sign in again')
                      }
                    })
                    .finally(() => {
                      setResendingToken(false)
                      setResentToken(true)
                    })
                }}
              >
                {resendingToken ? 'Resending...' : "Haven't receieved the token? Click to resend"}
              </AuthLink>
            </AuthLinks>
          </form>
        </>
      )}
      {!waitingFor2FA && (
        <>
          <div className='subheader-dark mt-5 mb-5'>Sign in to your account</div>
          {signInForm.error && <AuthError className='alert alert-warning mb-4' error={signInForm.error} />}
          {twoFAError && <AuthError className='alert alert-warning mb-4' error={twoFAError} />}
          {urlParams.has('sc') && (
            <AuthInfo
              className='alert alert-warning mb-4'
              error={'Your supplier workspace has been successfully created. Sign in to access it'}
            />
          )}
          <form autoComplete='off' className='auth-form-container mb-3' onSubmit={signInForm.handleSubmit}>
            <AuthInput
              id='email'
              label='Email'
              value={signInForm.values.email}
              onChange={signInForm.handleChange}
              onBlur={signInForm.handleBlur}
              helperText={signInForm.touched.email ? signInForm.errors.email : ''}
              error={signInForm.touched.email && Boolean(signInForm.errors.email)}
            />
            <AuthInput
              id='password'
              label='Password'
              type='password'
              value={signInForm.values.password}
              onChange={signInForm.handleChange}
              onBlur={signInForm.handleBlur}
              helperText={signInForm.touched.password ? signInForm.errors.password : ''}
              error={signInForm.touched.password && Boolean(signInForm.errors.password)}
            />
            <AuthButton disabled={signInForm.isSubmitting}>
              {signInForm.isSubmitting ? 'Signing in...' : 'Sign In'}
            </AuthButton>
          </form>
          <AuthLinks>
            <AuthLink to='/reset-password' className='mb-3'>
              Forgot your password?
            </AuthLink>
            <AuthLink to='/signup' className='mb-3'>
              Create Account
            </AuthLink>
            <AuthLink to='/resend-confirmation' className='mb-4'>
              Didn't receive confirmation instructions?
            </AuthLink>
          </AuthLinks>
        </>
      )}
    </AuthLayout>
  )
}
