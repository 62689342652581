import {
  useQuery as useQueryImpl,
  OperationVariables,
  TypedDocumentNode,
  QueryHookOptions,
  QueryResult,
  DocumentNode,
} from '@apollo/client'
import { useEffect, useState } from 'react'
import { useUser } from '~/base'
import REPORT_ERROR_MUTATION from '../errors/mutation-report-error.gql'

export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const [loggedError, setLoggedError] = useState(false)
  const q = useQueryImpl<TData, TVariables>(query, options)
  const user = useUser()

  useEffect(() => {
    if (!q.error || loggedError) {
      return
    }

    if (Array.isArray(q.error.graphQLErrors) && q.error.graphQLErrors.length > 0) {
      const err = q.error.graphQLErrors[0]

      let message = `${user.id} ${user.fullName} ${user.org?.id || ''} ${user.org?.name || ''} ${
        q.error.message
      }`
      if (Array.isArray(err.path) && err.path.length > 0) {
        message = message + ' ' + err.path.join(', ')
      }

      if (!message.includes('You need to sign in')) {
        window.__apolloClient.mutate({
          mutation: REPORT_ERROR_MUTATION,
          variables: {
            input: {
              url: window.location.href,
              message: message,
              stack: 'No stack available',
            },
          },
        })
      }

      setLoggedError(true)
    }
  }, [q.error, loggedError])

  return q
}
