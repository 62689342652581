import React from 'react'
import { Select } from '~/components'
import { MonitoringZoneTarget, MonitoringZoneSubTarget } from '~/models'
import { useMonitoringZoneTargets } from './use-mz-targets'

interface MonitoringZoneTargetSelectProps {
  monitoringZoneTarget?: MonitoringZoneTarget
  monitoringZoneSubTarget?: MonitoringZoneSubTarget
  monitoringZoneTargetID?: string
  monitoringZoneSubTargetID?: string
  onChange: (monitoringZoneTarget: MonitoringZoneTarget, monitoringZoneSubTarget?: MonitoringZoneSubTarget) => void
}

export const MonitoringZoneTargetSelect = (props: MonitoringZoneTargetSelectProps) => {
  const monitoringZoneTargets = useMonitoringZoneTargets()

  let selected = props.monitoringZoneSubTarget || props.monitoringZoneTarget
  if (props.monitoringZoneTargetID) {
    let tmpSelected = monitoringZoneTargets.find(x => x.id === props.monitoringZoneTargetID)

    if(tmpSelected && props.monitoringZoneSubTargetID) {
      selected = tmpSelected.subTypes.find(x => x.id === props.monitoringZoneSubTargetID)
    } else {
      selected = tmpSelected
    }
  }
  return (
    <Select<MonitoringZoneTarget | MonitoringZoneSubTarget>
      id='monitoring-zone-target'
      label='Type'
      placeholder='Select a type'
      options={monitoringZoneTargets
        .filter((monitoringZoneTarget) => !monitoringZoneTarget.archived)
        .map((monitoringZoneTarget) => {
          return {
            id: monitoringZoneTarget.id,
            name: monitoringZoneTarget.name,
            value: monitoringZoneTarget,
            items: monitoringZoneTarget.subTypes.map((s) => ({
              id: s.id,
              name: s.name,
              value: s,
              items: [],
            })),
          }
        })}
      onChange={(selected) => {
        for (const monitoringZoneTarget of monitoringZoneTargets) {
          if (monitoringZoneTarget.id === selected.id) {
            props.onChange(monitoringZoneTarget, undefined)
            return
          }

          for (const monitoringZoneSubTarget of monitoringZoneTarget.subTypes) {
            if (monitoringZoneSubTarget.id === selected.id) {
              props.onChange(monitoringZoneTarget, monitoringZoneSubTarget)
              return
            }
          }
        }
      }}
      selectedValue={selected}
    />
  )
}
