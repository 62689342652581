import { useEffect } from 'react'
import { ProjectionType, BaseLayerType, useAppState } from '~/state'
import { DrawerState } from '../drawer'

export function useComponentView() {
  const { map, timeline, drawer } = useAppState()

  useEffect(() => {
    map.setShowControls(false)
    timeline.setHidden(true)
    drawer.set(drawer.left, DrawerState.Normal)
    const originalMapState = map.projectionType
    if (map.projectionType !== ProjectionType.Projection3D) {
      map.setBaseLayerType(BaseLayerType.PhotoModel)
    }

    return () => {
      map.setShowControls(true)
      timeline.setHidden(false)
      if (originalMapState !== ProjectionType.Projection3D) {
        map.setBaseLayerType(BaseLayerType.Satellite)
      }
    }
  }, [])
}
