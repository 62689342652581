import { useQuery } from '~/components'
import { useState } from 'react'
import { ImageByHashQuery, ImageByHashQueryVariables, ImagesByModelImageT, Issue, Photo } from '~/models'
import { Point } from '~/issues/components'
import IMAGE_BY_HASH_QUERY from './query-image-by-hash.gql'

export function useImageTaggerState() {
  const [photo, setPhoto] = useState<Photo>()
  const [filmStripOpen, setFilmStripOpen] = useState<boolean>(false)
  const [addingIssue, setAddingIssue] = useState<boolean>(false)
  const [updatingIssue, setUpdatingIssue] = useState<Issue>()
  const [addingPoints, setAddingPoints] = useState<Point[]>([])
  const [fullscreen, setFullScreen] = useState(false)

  const [changedCallback, setChangedCallback] = useState<() => Promise<any>>(() => Promise.resolve())

  const imageQuery = useQuery<ImageByHashQuery, ImageByHashQueryVariables>(IMAGE_BY_HASH_QUERY, {
    variables: {
      input: {
        hash: '',
      },
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  return {
    isVisible: !!photo,
    photo,
    setPhoto,
    hide: () => {
      setPhoto(undefined)
      setFullScreen(false)
    },
    addingIssue,
    setAddingIssue: (val: boolean) => {
      setAddingIssue(val)
      setAddingPoints([])
      setFullScreen(false)
    },
    addingPoints,
    setAddingPoints,
    updatingIssue,
    setUpdatingIssue: (val: Issue) => {
      setFullScreen(false)
      if (val) {
        setUpdatingIssue(val)
        setAddingPoints(
          (val.coordinates || []).map((c) => ({
            x: c.x,
            y: c.y,
          }))
        )
      } else {
        setUpdatingIssue(undefined)
        setAddingPoints([])
      }
    },
    filmStripOpen,
    setFilmStripOpen,
    setChangedCallback,
    changedCallback,
    loadImageByHash: (hash: string) => {
      return imageQuery
        .refetch({
          input: {
            hash,
          },
        })
        .then((res) => {
          const photo = res.data.queryImageByHash.image as ImagesByModelImageT
          setPhoto(photo)

          return res.data.queryImageByHash
        })
    },
    fullscreen,
    setFullScreen,
  }
}
