export class Task {
  private _sliceStart: number
  private _slice: number
  private _taskSwitcher: Generator<boolean, boolean, boolean>
  public _fn: Generator

  public constructor(priority = 1) {
    this._sliceStart = 0
    this._slice = 0
    this.setPriority(priority)
    this._taskSwitcher = this._switcher()
  }

  private *_switcher(): Generator<boolean, boolean, boolean> {
    while (true) {
      if (new Date().getTime() - this._sliceStart >= this._slice) {
        yield true
        this._sliceStart = new Date().getTime()
      }
      yield false
    }
  }

  public setPriority(priority: number) {
    this._slice = priority << 2
  }

  public setFn(fn: Generator) {
    this._fn = fn
  }

  public switch() {
    return this._taskSwitcher.next().value
  }
}

export class Scheduler {
  private _taskList: Task[]
  private _interval: any

  constructor() {
    this._taskList = []
  }

  public addTask(task: Task) {
    this._taskList.push(task)
  }

  public run() {
    /* eslint-disable */
    const self = this
    /* eslint-enable */
    this._interval = setInterval(function () {
      let length = self._taskList.length
      for (let i = 0; i < length; i++) {
        if (self._taskList[i]._fn) {
          const res = self._taskList[i]._fn.next().done
          if (res) {
            self._taskList.splice(i, 1)
            length--
            i--
            break
          }
        }
      }
    }, 16) // <-- 16 ms is the minimum time slice for a task with priority 1
  }

  public stop() {
    clearInterval(this._interval)
  }
}
