query WeatherForSite($input: WeatherForSiteRequestT!) {
  weatherForSite(input: $input) {
    hourly {
      updatedAt
      items {
        timestamp
        temp
        feelsLike
        tempMin
        tempMax
        pressure
        pressureSeaLevel
        pressureGroundLevel
        humidityPercentage
        windSpeed
        windDeg
        windGust
        cloudPercentage
        rainMM
        snowMM
        probOfPrecipitation
        visibilityMeters
        forecastCategory
        forecastDescription
        forecastIcon
      }
    }
    daily {
      updatedAt
      items {
        timestamp
        tempDay
        tempMin
        tempMax
        tempNight
        tempEvening
        tempMorning
        feelsLikeDay
        feelsLikeNight
        feelsLikeEve
        feelsLikeMorn
        pressureSeaLevel
        humidityPercentage
        windSpeed
        windDeg
        windGust
        cloudPercentage
        rainMM
        snowMM
        probOfPrecipitation
        forecastCategory
        forecastDescription
        forecastIcon
      }
    }
  }
}
