import { useMutation } from '~/components'
import {
  AnalysisCreateMutation,
  AnalysisCreateMutationVariables,
  AnalysisRemoveMutation,
  AnalysisRemoveMutationVariables,
  AnalysisReprocessMutation,
  AnalysisReprocessMutationVariables,
  AnalysisUpdateMutation,
  AnalysisUpdateMutationVariables,
} from '~/models'
import ANALYSIS_CREATE_MUTATION from './mutation-create-analysis.gql'
import ANALYSIS_UPDATE_MUTATION from './mutation-update-analysis.gql'
import ANALYSIS_REMOVE_MUTATION from './mutation-remove-analysis.gql'
import ANALYSIS_REPROCESS_MUTATION from './mutation-reprocess-analysis.gql'

export function useAnalysisCreate() {
  return useMutation<AnalysisCreateMutation, AnalysisCreateMutationVariables>(ANALYSIS_CREATE_MUTATION)
}

export function useAnalysisUpdate() {
  return useMutation<AnalysisUpdateMutation, AnalysisUpdateMutationVariables>(ANALYSIS_UPDATE_MUTATION)
}

export function useAnalysisRemove() {
  return useMutation<AnalysisRemoveMutation, AnalysisRemoveMutationVariables>(ANALYSIS_REMOVE_MUTATION)
}

export function useAnalysisReprocess() {
  return useMutation<AnalysisReprocessMutation, AnalysisReprocessMutationVariables>(ANALYSIS_REPROCESS_MUTATION)
}
