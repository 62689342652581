import React from 'react'

export interface AuthInputProps {
  id?: string
  label?: string
  placeholder?: string
  value?: any
  onChange?: any
  onBlur?: any
  onFocus?: any
  onKeyDown?: any
  helperText?: any
  error?: any
  type?: any
  halfWidth?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  readonly?: boolean
  autoComplete?: string
}

export const AuthInput = (props: AuthInputProps) => {
  const {
    type,
    label,
    id,
    value,
    onChange,
    onBlur,
    inputRef,
    readonly,
    helperText,
    onFocus,
    onKeyDown,
    autoComplete,
    halfWidth,
  } = props

  return (
    <div
      className={
        halfWidth
          ? 'form-container-half-width mb-3 mb-3 inputText auth-input-container'
          : 'mb-3 inputText auth-input-container'
      }
    >
      <input
        type={type}
        className={halfWidth ? 'auth-input-half' : 'auth-input'}
        placeholder={label}
        id={id}
        defaultValue={value}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={inputRef}
        disabled={readonly}
        autoComplete={autoComplete}
      />
      {helperText && <div className='invalid-feedback visible'>{helperText}</div>}
    </div>
  )
}
