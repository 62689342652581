import dayjs from 'dayjs'
import React from 'react'
import { WeatherForSiteQuery } from '~/models'
import { formatTemp, formatWeatherIcon, formatWindSpeed, SimpleBar } from '~/components'
import { useAppState } from '~/state'

interface WeatherProps {
  weather: WeatherForSiteQuery['weatherForSite']
}

export const Weather = (props: WeatherProps) => {
  const { sites, site } = useAppState()
  const extra = sites.sites.find(s => s.s.id === site.site.id)
  const hourlyAll = [...props.weather.hourly.items]
  const now = dayjs()
  let hourIndex = hourlyAll.findIndex((x) => {
    const hourDate = dayjs(x.timestamp / 1000 / 1000)
    return now.isBefore(hourDate)
  })
  if (hourIndex !== 0) {
    hourIndex--
  }

  const hourly = hourlyAll.splice(hourIndex)

  return (
    <div className='account'>
      <SimpleBar>
        <table className='weather-table'>
          <thead>
            <th>Date</th>
            <th>Time</th>
            <th className='desc'>Description</th>
            <th>Temp</th>
            <th>Wind</th>
            <th>Gusts</th>
            <th>Humidity</th>
            <th>Rain</th>
            <th>Snow</th>
          </thead>
          <br />
          <tbody>
            {hourly.map((w, idx) => {
              const time = dayjs(w.timestamp / 1000 / 1000)
              // const rainPercent = Math.round(w.probOfPrecipitation * 100)

              const forecastDescription = w.forecastDescription
              let forecastDescriptionRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (prev.forecastDescription === forecastDescription) {
                    forecastDescriptionRowSpan--
                  }
                }

                if (forecastDescriptionRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (next.forecastDescription === forecastDescription) {
                  forecastDescriptionRowSpan++
                } else {
                  break
                }
              }

              const temp = Math.round(w.temp)
              let tempRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.round(prev.temp) === temp) {
                    tempRowSpan--
                  }
                }

                if (tempRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.round(next.temp) === temp) {
                  tempRowSpan++
                } else {
                  break
                }
              }

              const wind = Math.round(w.windSpeed) + '-' + w.windDeg
              let windRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.round(prev.windSpeed) + '-' + w.windDeg === wind) {
                    windRowSpan--
                  }
                }

                if (windRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.round(next.windSpeed) + '-' + w.windDeg === wind) {
                  windRowSpan++
                } else {
                  break
                }
              }

              const windGust = Math.round(w.windGust)
              let windGustRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.round(prev.windGust) === windGust) {
                    windGustRowSpan--
                  }
                }

                if (windGustRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.round(next.windGust) === windGust) {
                  windGustRowSpan++
                } else {
                  break
                }
              }

              const humidityPercentage = Math.round(w.humidityPercentage)
              let humidityPercentageRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.round(prev.humidityPercentage) === humidityPercentage) {
                    humidityPercentageRowSpan--
                  }
                }

                if (humidityPercentageRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.round(next.humidityPercentage) === humidityPercentage) {
                  humidityPercentageRowSpan++
                } else {
                  break
                }
              }

              const rainMM = Math.ceil(w.rainMM)
              let rainMMRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.ceil(prev.rainMM) === rainMM) {
                    rainMMRowSpan--
                  }
                }

                if (rainMMRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.ceil(next.rainMM) === rainMM) {
                  rainMMRowSpan++
                } else {
                  break
                }
              }

              const snowMM = Math.ceil(w.snowMM)
              let snowMMRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if (Math.ceil(prev.snowMM) === snowMM) {
                    snowMMRowSpan--
                  }
                }

                if (snowMMRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if (Math.ceil(next.snowMM) === snowMM) {
                  snowMMRowSpan++
                } else {
                  break
                }
              }

              const date = time.isSame(dayjs(), 'day') ? 'Today' : 'Tomorrow'
              let dateRowSpan = 1
              for (let i = idx + 1; i <= hourly.length; i++) {
                if (idx !== 0) {
                  const prev = hourly[idx - 1]
                  if ((dayjs(prev.timestamp / 1000000).isSame(dayjs(), 'day') ? 'Today' : 'Tomorrow') === date) {
                    dateRowSpan--
                  }
                }

                if (dateRowSpan === 0 || i === hourly.length) {
                  break
                }

                const next = hourly[i]
                if ((dayjs(next.timestamp / 1000000).isSame(dayjs(), 'day') ? 'Today' : 'Tomorrow') === date) {
                  dateRowSpan++
                } else {
                  break
                }
              }

              return (
                <tr key={w.timestamp}>
                  {dateRowSpan >= 1 && (
                    <td className='date bordered' rowSpan={dateRowSpan}>
                      {date}
                    </td>
                  )}
                  <td className='time'>{time.format('HH:mm')}</td>
                  {forecastDescriptionRowSpan >= 1 && (
                    <td className='desc icon bordered' rowSpan={forecastDescriptionRowSpan}>
                      <img src={formatWeatherIcon(w.forecastIcon, extra?.extra?.center)} />
                      <div>
                        {w.forecastDescription
                          .replace(': 85-100%', '')
                          .replace(': 51-84%', '')
                          .replace(': 25-50%', '')
                          .replace(': 11-25%', '')}
                      </div>
                    </td>
                  )}
                  {tempRowSpan >= 1 && (
                    <td className='temp bordered' rowSpan={tempRowSpan}>
                      {formatTemp(temp, true)}
                    </td>
                  )}
                  {windRowSpan >= 1 && (
                    <td className='wind bordered' rowSpan={windRowSpan}>
                      {formatWindSpeed(w.windSpeed, true)}&nbsp;&nbsp;
                      <span className='wind-dir' style={{ transform: `rotate(${w.windDeg}deg)` }}>
                        &uarr;
                      </span>
                    </td>
                  )}
                  {windGustRowSpan >= 1 && (
                    <td className='wind bordered' rowSpan={windGustRowSpan}>
                      {formatWindSpeed(windGust, true)}
                    </td>
                  )}
                  {humidityPercentageRowSpan >= 1 && (
                    <td className='humidity bordered' rowSpan={humidityPercentageRowSpan}>
                      {humidityPercentage}%
                    </td>
                  )}
                  {rainMMRowSpan >= 1 && (
                    <td className='rain bordered' rowSpan={rainMMRowSpan}>
                      {w.rainMM === 0 ? '-' : Math.ceil(w.rainMM)}
                      {w.rainMM ? 'mm' : ''}
                    </td>
                  )}
                  {snowMMRowSpan >= 1 && (
                    <td className='snow bordered' rowSpan={snowMMRowSpan}>
                      {w.snowMM === 0 ? '-' : Math.ceil(w.snowMM)}
                      {w.snowMM ? 'mm' : ''}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </SimpleBar>
    </div>
  )
}
