import React, { useMemo } from 'react'
import { Select, SelectOption } from '../forms'
import { SiteQuerySurvey, SurveyBasedOperation } from '~/models'

interface OperationTypeSelectProps {
  name: string
  onChange: React.ChangeEventHandler<SurveyBasedOperation>
  selectedValue: SurveyBasedOperation
  helperText?: {
    [key in SurveyBasedOperation]: string
  }
  survey: SiteQuerySurvey
}

export type OperationTypeSelectItem = {
  id: string
  value: SurveyBasedOperation
}

export const OperationTypeSelect = (props: OperationTypeSelectProps) => {
  const options = useMemo<Array<SelectOption<OperationTypeSelectItem>>>(() => {
    return [
      {
        id: SurveyBasedOperation.Singular,
        name: 'Current survey only',
        value: {
          id: SurveyBasedOperation.Singular,
          value: SurveyBasedOperation.Singular,
        },
        items: [],
      },
      {
        id: SurveyBasedOperation.GoingForward,
        name: 'Current and future surveys',
        value: {
          id: SurveyBasedOperation.GoingForward,
          value: SurveyBasedOperation.GoingForward,
        },
        items: [],
      },
      {
        id: SurveyBasedOperation.GoingBackwards,
        name: 'Current and previous surveys',
        value: {
          id: SurveyBasedOperation.GoingBackwards,
          value: SurveyBasedOperation.GoingBackwards,
        },
        items: [],
      },
      {
        id: SurveyBasedOperation.GoingForwardsAndBackwards,
        name: 'All Surveys',
        value: {
          id: SurveyBasedOperation.GoingForwardsAndBackwards,
          value: SurveyBasedOperation.GoingForwardsAndBackwards,
        },
        items: [],
      },
    ]
  }, [])

  return (
    <Select<OperationTypeSelectItem>
      id={props.name}
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Apply To'
      placeholder={''}
      onChange={(s) => {
        props.onChange({
          type: 'change',
          target: {
            name: props.name,
            value: s.value,
          },
        } as never as React.ChangeEvent<SurveyBasedOperation>)
      }}
      options={options}
    />
  )
}
