import React from 'react'
import { useAppState } from '~/state'
import { ExpandedPanel } from '~/state/use-drawer-state'

interface DrawerListContentProps {
  panelType: ExpandedPanel
  numEntities: number
  loading: boolean
  title: string
  singular: string
  plural: string
  canAdd?: boolean
  onAdd?: () => void
  onShow?: () => void
  onHide?: () => void
  emptyButCanAddText?: string
  emptyButCantAddText?: string
}

export const DrawerListContentShort = (props: DrawerListContentProps) => {
  const { drawer } = useAppState()

  function handleOpen(e: React.MouseEvent) {
    if (e.defaultPrevented) {
      return
    }
    if (drawer.expandedPanel === props.panelType) {
      drawer.closeExpandedPanel()
      if (props.onHide) {
        props.onHide()
      }
    } else {
      if (props.numEntities > 0) {
        drawer.setExpandedPanel(props.panelType)
      }
      if (props.onShow) {
        props.onShow()
      }
    }
  }

  return (
    <div className='drawer-list-content-short'>
      <h5 onClick={handleOpen}>
        <span>
          {props.title} ({props.numEntities})
        </span>
        <span>
          {props.canAdd && (
            <span
              className='material-icons add dimmed'
              onClick={(e) => {
                e.preventDefault()
                props.onAdd()
              }}
            >
              add
            </span>
          )}
          {props.numEntities > 0 && (
            <span className='material-icons add dimmed' title='Expand' onClick={handleOpen}>
              {drawer.expandedPanel === props.panelType ? 'chevron_left' : 'chevron_right'}
            </span>
          )}
        </span>
      </h5>
      {/* {props.numEntities > 0 && drawer.expandedPanel !== props.panelType && (
        <div
          onClick={() => {
            drawer.setExpandedPanel(props.panelType)
            if (props.onShow) {
              props.onShow()
            }
          }}
          className='drawer-list-content-short-more'
        >
          {props.numEntities === 1
            ? `View ${props.numEntities} ${props.singular}`
            : `View ${props.numEntities} ${props.plural}`}{' '}
        </div>
      )} */}
      {/* {props.numEntities > 0 && drawer.expandedPanel === props.panelType && (
        <div
          onClick={() => {
            drawer.setExpandedPanel(ExpandedPanel.None)
            if (props.onHide) {
              props.onHide()
            }
          }}
          className='drawer-list-content-short-more flipped'
        >
          Hide {props.plural}
        </div>
      )} */}
      {/* {props.numEntities === 0 && props.canAdd && (
        <div className='no-item-tag' onClick={props.onAdd}>
          {props.emptyButCanAddComponent ? props.emptyButCanAddComponent : props.emptyButCanAddText}
        </div>
      )}
      {props.numEntities === 0 && !props.canAdd && (
        <div className='no-item-tag disabled'>
          {props.emptyButCantAddComponent ? props.emptyButCantAddComponent : props.emptyButCantAddText}
        </div>
      )} */}
    </div>
  )
}
