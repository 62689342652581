import React from 'react'
import { classes } from '~/components/helpers'

export type CheckboxValue = boolean

export interface SwitchProps {
  name: string
  label?: string
  checked: boolean
  onChange: (isChecked: boolean) => void
  disabled?: boolean
  title?: string
  className?: string
  labelClassName?: string
  left?: boolean
  submitting?: boolean
}

export const Switch = (props: SwitchProps) => {
  return (
    <div
      key={props.name}
      className={classes({
        'form-group switch-form-group': true,
        disabled: props.disabled || props.submitting,
        [props.className]: !!props.className,
      })}
      title={props.title}
    >
      {!props.left && (
        <div
          className={'switch-label ' + (props.labelClassName || '')}
          onClick={() => {
            if (props.disabled) {
              return
            }
            props.onChange(!props.checked)
          }}
        >
          {props.label}
        </div>
      )}
      <div
        className='switch-container'
        onClick={() => {
          if (props.disabled) {
            return
          }
          props.onChange(!props.checked)
        }}
      >
        <div className={'switch-button ' + (props.checked === true ? 'active' : '')}></div>
      </div>
      {props.left && (
        <div
          className='switch-label'
          onClick={() => {
            if (props.disabled) {
              return
            }
            props.onChange(!props.checked)
          }}
        >
          &nbsp;&nbsp;&nbsp;{props.label}
        </div>
      )}
    </div>
  )
}
