import React from 'react'
import { ComponentCreateForm } from './components-create-form'
import { SiteQueryAsset, ComponentDtoT, SiteQuerySite, SiteQueryComponent } from '~/models'
import { useComponentView } from './use-component-view'


interface ComponentsCreateProps {
  parentComponent?: ComponentDtoT
  onComplete: (component: SiteQueryComponent, changed: boolean, hide: boolean) => void
  asset?: SiteQueryAsset
  site?: SiteQuerySite
}

export const ComponentsCreate = (props: ComponentsCreateProps) => {
  useComponentView()

  return (
    <div className='components-draw'>
      <ComponentCreateForm
        parentComponent={props.parentComponent}
        site={props.site}
        asset={props.asset}
        complete={(component, hide) => props.onComplete(component, true, hide)}
        cancel={() => props.onComplete(undefined, false, true)}
      />
      <div></div>
    </div>
  )
}
