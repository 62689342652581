import { IssueItemWithIndex } from './types'

export function sortIssues(issues: IssueItemWithIndex[]): IssueItemWithIndex[] {
  return issues.sort((a, b) => {
    if (a.photoIndex > b.photoIndex) {
      return 1
    }
    if (a.photoIndex < b.photoIndex) {
      return -1
    }

    return 0
  })
}
