import React from 'react'
import { AnnotationsFormCreateProps } from './annotations-form-create-props'
import { AnnotationRulerPoint } from './rulers'

export const AnnotationsFormCreatePoint = (props: AnnotationsFormCreateProps) => {
  const pointsChanged = (points: Cesium.Cartographic[], measurements: number[], name: string) => {
    props.setModel({
      name: name,
      points: points.map((p) => ({
        longitude: Cesium.Math.toDegrees(p.longitude),
        latitude: Cesium.Math.toDegrees(p.latitude),
        altitude: p.height,
      })),
      measurements,
    })
  }
  return <AnnotationRulerPoint pointsChanged={pointsChanged} points={props.model.points} name={props.model.name} />
}
