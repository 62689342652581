import React from 'react'

interface UploadPanelToggleProps {
  toggleOpen: () => void
}

export const UploadPanelToggle = (props: UploadPanelToggleProps) => {
  return (
    <div className='upload-panel-toggle' onClick={props.toggleOpen}>
      <div className='material-icons-outlined'>cloud_upload</div>
    </div>
  )
}
