import { Rect } from 'openseadragon'
import { BaseShape } from '../shapes'

export class SelectionBase<Shape = BaseShape, Result = Rect> {
  selection: Shape
  isEnabled = false
  constructor(public onSelection?: (rect: Result, shape: Shape) => void) {}
  enable(_: (rect: Rect, shape: BaseShape) => void) {
    this.isEnabled = true
  }
  disable() {
    this.isEnabled = false
  }
}
