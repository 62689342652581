import { PolygonRenderer } from './polygon-renderer'
import { Offset, Point, ScreenState } from './screen-state'
import { MutableRefObject } from 'react'

export class PolygonRendererEdit extends PolygonRenderer {
  private _points: Point[]
  private _pointsWithMiddle: Point[]
  private _dragIndex: number
  private _onChange: (points?: Point[]) => void
  private _onComplete: (points?: Point[]) => void

  constructor(
    canvas: HTMLCanvasElement,
    points: Point[],
    screenState: MutableRefObject<ScreenState>,
    color: string,
    onChange: (points?: Point[]) => void,
    onComplete?: (points?: Point[]) => void
  ) {
    super(canvas, screenState, color)

    this._points = points
    this._dragIndex = -1
    this._onChange = onChange
    this._onComplete = onComplete
    this._recomputePoints()
  }

  public onLeftClick(pos: Offset) {
    const index = this._getPointIndex(pos, this._pointsWithMiddle)
    if (index !== -1 && index % 2 !== 0) {
      this._points.splice(Math.floor(index / 2) + 1, 0, this._pointsWithMiddle[index])
      this._recomputePoints()
      this._onChange(this._points)
      this.render()
    }
  }

  public onMiddleClick(pos: Offset) {
    const index = this._getPointIndex(pos, this._pointsWithMiddle)
    if (index !== -1 && index % 2 === 0 && this._points.length > 3) {
      this._points.splice(Math.floor(index / 2), 1)
      this._recomputePoints()
      this._onChange(this._points)
      this.render()
    }
  }

  public onRightClick() {
    /* Ignore */
  }

  public onLeftDown(pos: Offset) {
    const index = this._getPointIndex(pos, this._pointsWithMiddle)
    if (index === -1) {
      return
    } else if (index % 2 === 0) {
      this._dragIndex = Math.floor(index / 2)
      this._screenState.current.dragAllowed = false
    } else {
      const idx = Math.floor(index / 2) + 1
      this._points.splice(idx, 0, this._pointsWithMiddle[index])
      this._recomputePoints()
      this._onChange(this._points)
      this._dragIndex = idx
      this._screenState.current.dragAllowed = false
      document.body.style.cursor = 'move'
    }
  }

  public onLeftUp() {
    this._screenState.current.dragAllowed = true
    this._dragIndex = -1
    this._onChange(this._points)
  }

  public onMouseMove(pos: Offset) {
    if (this._dragIndex !== -1) {
      const imagePos = this._screenState.current.getImagePosition({
        x: pos.left,
        y: pos.top,
      })

      if (imagePos.x < 0) {
        imagePos.x = 0
      } else if (imagePos.x > this._screenState.current.img.width) {
        imagePos.x = this._screenState.current.img.width
      }

      if (imagePos.y < 0) {
        imagePos.y = 0
      } else if (imagePos.y > this._screenState.current.img.height) {
        imagePos.y = this._screenState.current.img.height
      }

      this._points[this._dragIndex] = imagePos
      this._recomputePoints()
      return
    }
    const index = this._getPointIndex(pos, this._pointsWithMiddle)
    if (index === -1) {
      document.body.style.cursor = 'default'
    } else {
      document.body.style.cursor = index % 2 === 0 ? 'move' : 'pointer'
    }
  }

  public onKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      if (this._onComplete) {
        this._onComplete()
      }
    } else if (e.key === 'Enter') {
      if (this._points.length >= 3) {
        if (this._onComplete) {
          this._onComplete(this._points)
        }
      }
    }
  }

  private _recomputePoints() {
    this._pointsWithMiddle = []

    for (let i = 1; i <= this._points.length; i++) {
      const prevPoint = this._points[i - 1]
      const curPoint = this._points[i === this._points.length ? 0 : i]
      this._pointsWithMiddle.push(prevPoint)
      this._pointsWithMiddle.push({
        x: (prevPoint.x + curPoint.x) / 2,
        y: (prevPoint.y + curPoint.y) / 2,
      })
    }

    this.render()
  }

  public _render() {
    if (this._points.length === 0) {
      return
    }

    const ctx = this._screenState.current.c.getContext('2d')

    for (let i = 1; i <= this._pointsWithMiddle.length; i++) {
      const prevPoint = this._pointsWithMiddle[i - 1]
      const point = this._pointsWithMiddle[i === this._pointsWithMiddle.length ? 0 : i]

      const previousPosition = this._screenState.current.getCanvasPosition(prevPoint)
      const currentPosition = this._screenState.current.getCanvasPosition(point)

      ctx.save()
      ctx.strokeStyle = this._color
      ctx.lineWidth = 3

      ctx.beginPath()
      ctx.moveTo(previousPosition.left, previousPosition.top)
      ctx.lineTo(currentPosition.left, currentPosition.top)
      ctx.stroke()

      ctx.beginPath()
      ctx.arc(previousPosition.left, previousPosition.top, 4, 0, 2 * Math.PI, false)
      ctx.fillStyle = i % 2 === 0 ? '#fff' : this._color
      ctx.fill()
      ctx.stroke()

      ctx.restore()
    }
  }
}
