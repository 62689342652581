import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import React from 'react'

export const MobileNotification = () => {
  return (
    <AuthLayout headingType={AuthLayoutHeadingType.NONE}>
      <div className='subheader-dark mt-5 mb-5'>Asseti is best used on a desktop or tablet.</div>
    </AuthLayout>
  )
}
