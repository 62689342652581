import React, { useState } from 'react'
import './file-uploader.scss'
import '@mantine/dropzone/styles.css'
import { Dropzone, DropzoneProps, FileWithPath } from '@mantine/dropzone'
import { SlimLoader } from 'ui/slim-loader/slim-loader'
import { classes, notReachable } from 'components'
import { ImageLightbox } from 'ui/image-gallery/image-lightbox'
import { Column, LightboxState, MimeTypeExtension, UploadableFile } from 'ui'
import { UserInfo } from 'base'

import { useFileUploadWithProgress } from 'ui/file-uploader/use-file-upload-with-progress'
import { resizeImage } from 'ui/utils'
import { IconPictures } from 'ui/icons/icon-pictures'

type State = { type: 'idle' } | { type: 'preparing' }

type Props = Partial<DropzoneProps> & {
  files: UploadableFile[]
  setFiles: React.Dispatch<React.SetStateAction<UploadableFile[]>>
  maxSize?: number
  accept?: MimeTypeExtension[]
  maxFilesShown?: number
  user: UserInfo & { token: string }
}

export const FileUploader = ({ files, setFiles, maxSize = 5 * 1024 ** 2, accept, maxFilesShown = 1, user }: Props) => {
  const [lightBoxState, setLightBoxState] = useState<LightboxState>({
    currentImageIndex: 0,
    open: false,
  })

  const [state, setState] = useState<State>({ type: 'idle' })

  const { filesWithUploadStates, totalProgress } = useFileUploadWithProgress({
    files: files,
    user,
    uploadPath: '/files/upload',
    chunkedUploadPath: '/files/upload-chunked',
  })

  const handleRemoveFile = (index: number) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index))
  }

  const displayedFiles = filesWithUploadStates.slice(0, maxFilesShown)
  const previews = displayedFiles.map(({ file, uploadState, preview }, index) => {
    const filePreviewUrl = preview || URL.createObjectURL(file)

    return (
      <div key={file.name} className='ui-file-uploader-image-preview-item'>
        <img
          className='ui-file-uploader-image-preview-item-img'
          style={{ pointerEvents: 'all' }}
          alt={file.name}
          src={filePreviewUrl}
          onClick={(e) => {
            e.stopPropagation()
            setLightBoxState({
              currentImageIndex: index,
              open: true,
            })
          }}
        />

        <div
          className={classes({
            'ui-file-uploader-image-preview-uploading-overlay': true,
            hidden: uploadState.state !== 'loading' && uploadState.state !== 'pending',
          })}
        />

        {uploadState.state !== 'loaded' && (
          <div
            style={{ pointerEvents: 'all' }}
            className='ui-file-uploader-image-preview-item-uploading'
            onClick={(e) => {
              e.stopPropagation()
              setLightBoxState({
                currentImageIndex: index,
                open: true,
              })
            }}
          >
            {(() => {
              switch (uploadState.state) {
                case 'loading':
                  return (
                    <>
                      Uploading <SlimLoader progress={uploadState.progress} />
                    </>
                  )
                case 'pending':
                  return <>Waiting</>
                case 'error':
                  return <>Failed</>

                default:
                  return notReachable(uploadState)
              }
            })()}
          </div>
        )}

        <i
          className={`ui-file-uploader-image-preview-item-remove fa-border fa-regular fa-circle-xmark ${
            uploadState.state === 'error' ? 'error' : ''
          }`}
          style={{ pointerEvents: 'all' }}
          onClick={(e) => {
            e.stopPropagation()
            handleRemoveFile(index)
          }}
        />
      </div>
    )
  })

  if (filesWithUploadStates.length > maxFilesShown) {
    const remainingCount = filesWithUploadStates.length - maxFilesShown
    const nextFile = filesWithUploadStates[maxFilesShown]
    const imageUrl = nextFile.preview || URL.createObjectURL(nextFile.file)

    previews.push(
      <div
        style={{ pointerEvents: 'all' }}
        key='more-images'
        className='ui-file-uploader-image-preview-item more-images'
        onClick={(e) => {
          e.stopPropagation()
          setLightBoxState({
            currentImageIndex: maxFilesShown,
            open: true,
          })
        }}
      >
        <img className='ui-file-uploader-image-preview-item-img' alt='More images' src={imageUrl} />
        <div className='ui-file-uploader-image-preview-item-overlay'>+{remainingCount}</div>
      </div>
    )
  }

  if (previews.length > 0) {
    previews.push(
      <div key={`add-item-1`} className='ui-file-uploader-image-add-item'>
        <i className='fa-solid fa-circle-plus'></i>
      </div>
    )
  }

  const maxSizeText = `${(maxSize / 1024 / 1024).toFixed(0)}MB`
  const acceptText = formatAcceptText(accept)

  return (
    <>
      <div className='ui-file-uploader'>
        <Dropzone
          onReject={(reason) => {
            console.log('rejected', reason)
          }}
          onDrop={async (newFiles: FileWithPath[]) => {
            setState({ type: 'preparing' })
            const filesWithState = await Promise.all(
              newFiles.map(async (file): Promise<UploadableFile> => {
                let previewUrl: string | undefined

                try {
                  const resizedBlob = await resizeImage(file, 145, 145)
                  previewUrl = URL.createObjectURL(resizedBlob)
                } catch (error) {
                  previewUrl = URL.createObjectURL(file)
                }

                return {
                  file: file,
                  uploadState: {
                    state: 'pending',
                    progress: 0,
                  },
                  preview: previewUrl,
                }
              })
            )
            setState({ type: 'idle' })
            setFiles((currentFiles) => [...currentFiles, ...filesWithState])
          }}
          maxSize={maxSize}
          accept={accept}
        >
          <Dropzone.Idle>
            {previews.length > 0 && (
              <>
                <div className='ui-file-uploader-preview-container'>{previews}</div>
                {totalProgress > 0 && totalProgress < 100 && (
                  <div className={'ui-file-uploader-preview-container-loader'}>
                    <SlimLoader progress={totalProgress} />{' '}
                  </div>
                )}
              </>
            )}

            {!previews.length && (
              <div className='ui-file-uploader-empty'>
                {(() => {
                  switch (state.type) {
                    case 'idle':
                      return (
                        <Column spacing={16} alignX={'center'}>
                          <IconPictures size={42} />

                          <p className='ui-file-uploader-empty-text-title'>
                            Drag and drop or Click to{' '}
                            <span className='ui-file-uploader-empty-text-highlight'>upload</span> image(s)
                          </p>
                        </Column>
                      )
                    case 'preparing':
                      return (
                        <>
                          <i className='fas fa-spinner-third fa-fw fa-2x fa-spin' />
                          <p className='ui-file-uploader-empty-text-title'>Preparing files for upload...</p>
                        </>
                      )

                    default:
                      return notReachable(state)
                  }
                })()}

                <p className='ui-file-uploader-empty-text-description'>
                  Only upload {acceptText} files smaller than {maxSizeText}
                </p>
              </div>
            )}
          </Dropzone.Idle>
          <Dropzone.Accept>
            <div className='ui-file-uploader-drag-container ui-file-uploader-drag-container-success'>
              <i className='fa-solid fa-check' />
            </div>
          </Dropzone.Accept>
          <Dropzone.Reject>
            <div className='ui-file-uploader-drag-container ui-file-uploader-drag-container-error'>
              <i className='fa-solid fa-circle-xmark' />
              <p className='ui-file-uploader-empty-text-title'>File not supported</p>
              <p className='ui-file-uploader-empty-text-description'>
                Only upload {acceptText} files smaller than {maxSizeText}
              </p>
            </div>
          </Dropzone.Reject>
        </Dropzone>
      </div>
      <ImageLightbox
        images={filesWithUploadStates.map((file) => ({
          preview: file.preview,
          src: URL.createObjectURL(file.file),
          alt: file.file.name,
          uploadState: file.uploadState,
        }))}
        lightBoxState={lightBoxState}
        onMsg={(msg) => {
          switch (msg.type) {
            case 'close':
              setLightBoxState({ ...lightBoxState, open: false })
              break
            case 'on_preview_clicked':
              setLightBoxState({
                ...lightBoxState,
                currentImageIndex: msg.imageIndex,
              })
              break
            default:
              return notReachable(msg)
          }
        }}
      />
    </>
  )
}

const formatAcceptText = (accept?: MimeTypeExtension[]): string => {
  if (!accept || accept.length === 0) {
    return 'any file'
  }

  const uppercased = accept.map((ext) => ext.toUpperCase())

  if (uppercased.length === 1) {
    return uppercased[0]
  }

  const last = uppercased.pop()
  return `${uppercased.join(', ')} and ${last}`
}
