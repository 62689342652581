import React, { useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { DrawerListContentShort } from '~/components'
import { useAppState } from '~/state'
import { AnnotationsFormCreate } from './annotations-form-create'
import { useAnnotationLayers } from './annotations-use-visualizations'
import { AnnotationFullList } from './annotations-list-full'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { AnnotationsRemove } from './anotations-remove-modal'

export const AnnotationsDrawerList = () => {
  const { site, drawer, annotations } = useAppState()
  const user = useUser()

  const isDemoSite = site.site?.isDemoSite
  const canRead = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsRead)

  if (!canRead) {
    return null
  }

  useAnnotationLayers()

  const canAdd = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsCreate)
  const [adding, setAdding] = useState(false)

  return (
    <>
      <DrawerListContentShort
        canAdd={canAdd}
        loading={false}
        numEntities={annotations.annotations.length}
        panelType={ExpandedPanel.Annotations}
        plural='annotations'
        singular='annotation'
        title='Annotations'
        onAdd={() => {
          drawer.closeExpandedPanel()
          setAdding(true)
        }}
        onShow={() => {
          if (annotations.annotations.length === 0) {
            drawer.closeExpandedPanel()
            setAdding(true)
          }
        }}
        emptyButCanAddText='Add an annotation'
        emptyButCantAddText='No annotations have been added'
      />

      {(adding || annotations.annotationToUpdate) && (
        <AnnotationsFormCreate
          onComplete={(changed, hide) => {
            changed && annotations.reload()
            hide && setAdding(false)
            hide && annotations.setAnnotationToUpdate(undefined)
          }}
          existing={annotations.annotationToUpdate}
        />
      )}
      {annotations.annotationToDelete && (
        <AnnotationsRemove
          annotation={annotations.annotationToDelete}
          refetch={() => {
            return annotations.reload().then((res) => {
              if (annotations.annotations.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            annotations.setAnnotationToDelete(undefined)
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.Annotations && <AnnotationFullList />}
    </>
  )
}
