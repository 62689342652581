import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { useAppState } from '~/state'
import { SiteQueryImageTimelineItem, SiteQuerySatelliteTimelineItem, SiteQuerySurvey } from '~/models'
import { classes } from '../helpers'
import { getOffsetX } from './utils'

interface TimelineContentProps {
  months: Dayjs[]
  adjustmentX: number
  activeSurvey?: SiteQuerySurvey
  activeImages: SiteQueryImageTimelineItem[]
  surveys: SiteQuerySurvey[]
  imageTimeline: SiteQueryImageTimelineItem[]
  satelliteTimeline: SiteQuerySatelliteTimelineItem[]
  addSurveyTooltip: (item: SiteQuerySurvey) => void
  removeSurveyTooltip: (item: SiteQuerySurvey) => void
  addImageTooltip: (item: SiteQueryImageTimelineItem) => void
  removeImageTooltip: (item: SiteQueryImageTimelineItem) => void
  addSatelliteTooltip: (item: SiteQuerySatelliteTimelineItem) => void
  removeSatelliteTooltip: (item: SiteQuerySatelliteTimelineItem) => void
}

export const TimelineContent = (props: TimelineContentProps) => {
  const { site, timeline } = useAppState()

  if (!site.site) {
    return <></>
  }

  const middleDate = ((props.activeSurvey?.startDate || 0) + (props.activeSurvey?.endDate || 0)) / 2

  return (
    <div
      className={classes({
        'timeline-content': true,
      })}
    >
      <div
        className='timeline-content-entries'
        id='timeline-content-entries'
        style={{ left: `${props.adjustmentX}px` }}
      >
        {props.months.map((m, i) => {
          const left = i * timeline.entrySize + props.adjustmentX + 30
          const formatted = m.format('MMM YYYY')
          return (
            <div
              key={formatted}
              className='timeline-content-month'
              style={{ left: left + 'px', flex: `0 0 ${timeline.entrySize}px` }}
            >
              &nbsp;
            </div>
          )
        })}

        {props.surveys.map((s) => {
          const left = getOffsetX(props.months, s.startDate, timeline.entrySize)
          const right = getOffsetX(props.months, s.endDate, timeline.entrySize)

          const position = (left + right) / 2

          const orthoComplete = s.reconstructions.findIndex((r) => r.ortho && r.ortho.state !== 'Processed') === -1
          const isInstantAssessment = s.reconstructions.findIndex((r) => r.instantAssessmentID !== '') !== -1

          if (left === right) {
            return (
              <div
                key={s.id}
                className={classes({
                  'timeline-content-survey': true,
                  active: s.id === props.activeSurvey?.id,
                  incomplete: !orthoComplete,
                  'instant-assessment': isInstantAssessment
                })}
                onMouseEnter={() => {
                  props.addSurveyTooltip(s)
                }}
                onMouseLeave={() => {
                  props.removeSurveyTooltip(s)
                }}
                style={{ left: position - 2 + 'px' }}
              ></div>
            )
          }

          return (
            <div
              key={s.id}
              className={classes({
                'timeline-content-survey-span': true,
                active: s.id === props.activeSurvey?.id,
                incomplete: !orthoComplete,
                'instant-assessment': isInstantAssessment
              })}
              onMouseEnter={() => {
                props.addSurveyTooltip(s)
              }}
              onMouseLeave={() => {
                props.removeSurveyTooltip(s)
              }}
              style={{ left: left + 'px', width: right - left + 'px' }}
            ></div>
          )
        })}

        {props.imageTimeline.map((s) => {
          const start = dayjs(s.day)
          const left = getOffsetX(props.months, start, timeline.entrySize)
          const key = s.day + s.numImages
          return (
            <div
              key={key}
              className={classes({
                'timeline-content-image-upload': true,
                active: props.activeImages.findIndex((i) => i.day === s.day) !== -1,
              })}
              onMouseEnter={() => {
                props.addImageTooltip(s)
              }}
              onMouseLeave={() => {
                props.removeImageTooltip(s)
              }}
              onBlur={() => {
                props.removeImageTooltip(s)
              }}
              style={{ left: left + 'px' }}
            ></div>
          )
        })}

        {/* {props.satelliteTimeline.map((s) => {
          const start = dayjs(s.date / 1000 / 1000)
          const left = getOffsetX(props.months, start, timeline.entrySize)

          const key = s.satelliteID
          return (
            <div
              key={key}
              className={classes({
                'timeline-content-satellite': true,
                active: false,
              })}
              onMouseEnter={() => {
                props.addSatelliteTooltip(s)
              }}
              onMouseLeave={() => {
                props.removeSatelliteTooltip(s)
              }}
              style={{ left: left + 'px' }}
            >
              
            </div>
          )
        })} */}

        <div
          className='timeline-content-active-date'
          style={{ left: getOffsetX(props.months, middleDate, timeline.entrySize) }}
        ></div>

        <div id='timeline-content-hover-date' className='timeline-content-hover-date'></div>
      </div>
    </div>
  )
}
