import React, { useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { getTracking } from '~/components'
import { SystemRole } from '~/models'
import { AdvancedAccess } from './advanced-access'
import { People } from './people'
import { Settings } from './settings'

enum TeamViewStateType {
  People = 'people',
  Settings = 'settings',
  AdvancedAccess = 'access',
}

export const Team = () => {
  const user = useUser()
  const [teamView, setTeamView] = useState<TeamViewStateType>(TeamViewStateType.People)
  const currentOrg = user.orgs.find(o => o.id === user.currentOrgId)
  const canManage = user.systemRole === SystemRole.Staff || (user.hasPermission(ClientPermission.ManageOrg) && currentOrg.canManageAdvancedAccess)

  return (
    <>
      <ul className='team-tabs'>
        <li
          className={`team-tabs-item ${teamView === TeamViewStateType.People ? 'active' : ''}`}
          onClick={() => {
            setTeamView(TeamViewStateType.People)
            getTracking().event({
              category: 'Tab',
              action: `User clicked people tab`,
              label: 'People',
            })
          }}
        >
          People
        </li>
        <li
          className={`team-tabs-item ${teamView === TeamViewStateType.Settings ? 'active' : ''}`}
          onClick={() => {
            setTeamView(TeamViewStateType.Settings)
            getTracking().event({
              category: 'Tab',
              action: `User clicked workspace tab in profile`,
              label: 'Workspace',
            })
          }}
        >
          Workspace
        </li>
        {canManage && (
          <li
            className={`team-tabs-item ${teamView === TeamViewStateType.AdvancedAccess ? 'active' : ''}`}
            onClick={() => {
              setTeamView(TeamViewStateType.AdvancedAccess)
              getTracking().event({
                category: 'Tab',
                action: `User clicked advanced access tab in profile`,
                label: 'Advanced Access',
              })
            }}
          >
            Advanced Access
          </li>
        )}
      </ul>
      {teamView === TeamViewStateType.People && <People />}
      {teamView === TeamViewStateType.Settings && <Settings />}
      {teamView === TeamViewStateType.AdvancedAccess && <AdvancedAccess />}
    </>
  )
}
