import React from 'react'
import { useAppState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { ImageTaggerImpl } from './image-tagger-impl'

export const ImageTagger = () => {
  const { imageTagger, view, site, issues } = useAppState()
  const user = useUser()
  const canAddIssue = !site.site?.isDemoSite && user.hasPermission(ClientPermission.IssuesCreate)

  return (
    <ImageTaggerImpl
      isVisible={imageTagger.isVisible}
      photo={imageTagger.photo}
      addingIssue={!!imageTagger.addingIssue}
      updatingIssue={!!imageTagger.updatingIssue}
      fullscreen={imageTagger.fullscreen}
      canFullscreen={!imageTagger.addingIssue && !imageTagger.updatingIssue}
      setFullScreen={imageTagger.setFullScreen}
      canAddIssue={canAddIssue}
      setAddingIssue={imageTagger.setAddingIssue}
      canHide
      hide={imageTagger.hide}
      setAddingPoints={imageTagger.setAddingPoints}
      viewingIssue={!!view.issueID}
      existingIssues={issues.issues || []}
      showCompass
    />
  )
}
