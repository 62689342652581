import React, { useEffect, useRef, useState } from 'react'
import { useUser } from '~/base'
import {
  ComponentCondition,
  SurveyBasedOperation,
  SystemRole,
  UpdateComponentMutation,
  UpdateComponentMutationVariables,
} from '~/models'
import { useAppState } from '~/state'
import UPDATE_COMPONENT from './mutation-component-update.gql'
import { useMutation } from '../data'
import { classes } from '../helpers'
import { useToasts } from '../toast'

export const ComponentsContextMenu = () => {
  const { components, site, view } = useAppState()
  const user = useUser()
  const [updateComponent] = useMutation<UpdateComponentMutation, UpdateComponentMutationVariables>(UPDATE_COMPONENT)
  const [loading, setLoading] = useState(false)
  const toasts = useToasts()
  const contextMenuNode = useRef(null)

  useEffect(() => {
    if (user.systemRole !== SystemRole.Staff) {
      if (components.componentContext) {
        components.setComponentContext(undefined)
      }
    }
  }, [components.componentContext])

  const handleClickOutside = (e: Event) => {
    if (contextMenuNode.current && contextMenuNode.current.contains(e.target)) {
      return
    }

    components.setComponentContext(undefined)
  }

  useEffect(() => {
    if (user.systemRole !== SystemRole.Staff || !components.componentContext) {
      return
    }
    if (contextMenuNode.current) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [contextMenuNode.current, components.componentContext])

  if (user.systemRole !== SystemRole.Staff || !components.componentContext) {
    return null
  }

  function doUpdateComponent(condition: ComponentCondition) {
    if (condition === components.componentContext.component.condition) {
      components.setComponentContext(undefined)
      return
    }

    const c = components.componentContext.component

    setLoading(true)
    updateComponent({
      variables: {
        input: {
          id: c.id,
          name: c.name,
          componentTypeID: c.componentTypeID,
          points: c.points,
          assetTypeID: site.site.assets.find((a) => a.id === c.assetID)?.assetType?.id,
          condition: condition,
          assetID: c.assetID,
          oldAssetID: c.assetID,
          operationType: SurveyBasedOperation.GoingForwardsAndBackwards,
          siteID: view.siteID,
          surveyID: view.surveyID,
          materialTypeID: c.materialTypeID,
        },
      },
    })
      .then(() => {
        return site.refetch()
      })
      .finally(() => {
        toasts.addTopLeft('Component condition updated')
        setLoading(false)
        components.setComponentContext(undefined)
      })
  }

  const component = components.componentContext.component

  return (
    <div
      className={classes({
        'components-context-menu': true,
        loading,
      })}
      style={{ left: components.componentContext.windowPos.x, top: components.componentContext.windowPos.y - 40 }}
      ref={contextMenuNode}
    >
      <h5>{component.materialTypeName + ' ' + (component.name || component.componentTypeName)}</h5>
      <h5>Condition</h5>
      <div className='components-context-menu-items'>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.Excellent,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.Excellent)
          }}
        >
          Excellent
        </div>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.Good,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.Good)
          }}
        >
          Good
        </div>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.Average,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.Average)
          }}
        >
          Average
        </div>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.Poor,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.Poor)
          }}
        >
          Poor
        </div>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.VeryPoor,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.VeryPoor)
          }}
        >
          Very Poor
        </div>
        <div
          className={classes({
            bold: component.condition === ComponentCondition.EndOfLife,
          })}
          onClick={(e) => {
            e.preventDefault()
            doUpdateComponent(ComponentCondition.EndOfLife)
          }}
        >
          End of Life
        </div>
      </div>
    </div>
  )
}
