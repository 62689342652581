import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.scss'

type Props = {
  selectedDate?: Date
  onChange?: (date: Date | null) => void
  placeholder?: string
  label?: string
  readOnly?: boolean
  helperText?: string
  error?: string
  size?: 'small' | 'medium' | 'large'
}

export const DatePicker = ({
  selectedDate,
  onChange,
  placeholder = 'Select a date',
  label,
  readOnly,
  helperText,
  error,
  size = 'medium',
}: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(selectedDate || null)

  const handleChange = (date: Date | null) => {
    setStartDate(date)
    if (onChange) onChange(date)
  }

  // TODO add chevron icon for open and closed state
  // <i className={`fa ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden='true' />
  return (
    <div className={`ui-datepicker ${size} ${error ? 'ui-datepicker-error' : ''} ${readOnly ? 'disabled' : ''}`}>
      {label && <label className='ui-datepicker-label'>{label}</label>}
      <ReactDatePicker
        showIcon
        icon={
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
            <path
              fill='currentColor'
              d='M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z'
            />
          </svg>
        }
        selected={startDate}
        onChange={handleChange}
        placeholderText={placeholder}
        className='ui-datepicker-input'
        disabled={readOnly}
        dateFormat='d MMMM yyyy'
      />
      {helperText && !error && <div className='helper-text'>{helperText}</div>}
      {error && <div className='error-text'>{error}</div>}
    </div>
  )
}
