import React, { useEffect, useRef, useState } from 'react'
import './select.scss'

export type Option = {
  value: string
  label: string
  disabled?: boolean
}

type Props = {
  options: Option[]
  value?: string
  onChange?: (value: string) => void
  id?: string
  label?: string
  placeholder?: string
  readOnly?: boolean
  helperText?: string
  error?: string
  size?: 'small' | 'medium' | 'large'
}

export const Select = ({
  options,
  value,
  onChange,
  id,
  label,
  placeholder = 'Select an option',
  readOnly,
  helperText,
  error,
  size = 'medium',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find((option) => option.value === value) || null
  )
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    if (readOnly) return
    setIsOpen((prev) => !prev)
  }

  const handleOptionClick = (option: Option) => {
    if (option.disabled) return
    setSelectedOption(option)
    if (onChange) onChange(option.value)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value) || null)
  }, [value, options])

  return (
    <div
      className={`ui-dropdown ${size} ${error ? 'ui-dropdown-error' : ''} ${readOnly ? 'disabled' : ''}`}
      ref={dropdownRef}
    >
      {label && (
        <label htmlFor={id} className='ui-dropdown-label'>
          {label}
        </label>
      )}
      <div className={`ui-dropdown-wrapper ${readOnly ? 'disabled' : ''}`} onClick={toggleDropdown}>
        <div className={`dropdown-field ${isOpen ? 'focus' : ''} ${isOpen ? 'focus' : ''}`}>
          {selectedOption ? selectedOption.label : <span className='placeholder'>{placeholder}</span>}
        </div>
        <span className={`ui-dropdown-icon ${!value ? '' : 'has-value'}`}>
          <i className={`fa fa-light ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden='true' />
        </span>
      </div>
      {isOpen && (
        <div className='ui-dropdown-menu'>
          {options.map((option) => (
            <div
              key={option.value}
              className={`ui-dropdown-item ${option.disabled ? 'disabled' : ''} ${
                selectedOption && selectedOption.value === option.value ? 'selected' : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {helperText && !error && <div className='helper-text'>{helperText}</div>}
      {error && <div className='error-text'>{error}</div>}
    </div>
  )
}
