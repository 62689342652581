import { AuthLayout, AuthLayoutHeadingType } from './auth'

import React from 'react'

export const NotFoundPage = () => {
  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <div className='auth-form-container mb-3'>
      <div className='subheader-dark mt-5 mb-5'>Not Found</div>
      <p className='auth-description-text mb-4'>You just hit a route that doesn&#39;t exist.</p>
      <a href='/' target='blank' rel='noopener noreferrer' className="auth-layout-link">
          Go Back
        </a>
      </div>
    </AuthLayout>
  )
}
