import { OrganizationRole } from "./gql-types"

export function orgRoleToString(role: OrganizationRole) {
  switch (role) {
    case OrganizationRole.AccountOwner:
      return 'Account Owner'
    case OrganizationRole.Admin:
      return 'Administrator'
    case OrganizationRole.Guest:
      return 'Guest'
    case OrganizationRole.Member:
      return 'Member'
  }
}

export function orgRoleStringToEnum(role: string) {
  switch (role) {
    case 'AccountOwner':
      return OrganizationRole.AccountOwner
    case 'Admin':
      return OrganizationRole.Admin
    case 'Guest':
      return OrganizationRole.Guest
    case 'Member':
      return OrganizationRole.Member
  }
}

export function orgRoleAccess(role: OrganizationRole) {
  switch (role) {
    case OrganizationRole.AccountOwner:
      return 'Full access'
    case OrganizationRole.Admin:
      return 'Full access'
    case OrganizationRole.Member:
      return 'Edit access'
    case OrganizationRole.Guest:
      return 'View and comment only'
  }
}
