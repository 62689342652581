import { BoundaryCategoryType, BoundarySubcategoryType } from "~/models"

export const BOUNDARIES_ALLOWED_ORG_IDS = ['01ES4FBAQAA9E2Z8784K68M9EP', '01F2R2S4K5DG9J1EF7NG9R8S3R']

export interface BoundaryAdditionalData {
  category: BoundaryCategoryType
  subcategory: BoundarySubcategoryType
  // pegged: boolean
  // expiry: number
  // color: string
  // description: string
  sourceInformation: string
}

export const DEFAULT_ADDITIONAL_DATA: BoundaryAdditionalData = {
  category: BoundaryCategoryType.Cadastral,
  subcategory: BoundarySubcategoryType.Owned,
  // color: PolygonColorRed,
  // description: 'No description',
  sourceInformation: 'No source',
  // expiry: 0,
  // pegged: false,
}