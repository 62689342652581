import { Image, LightboxState, Modal, ModalBody, SlimLoader } from 'ui'
import React from 'react'
import { notReachable } from 'components'

type Props = {
  lightBoxState: LightboxState
  images: Image[]
  onMsg: (msg: Msg) => void
}

type Msg = { type: 'close' } | { type: 'on_preview_clicked'; imageIndex: number }

export const ImageLightbox = ({ lightBoxState, images, onMsg }: Props) => {
  if (!lightBoxState.open || images.length === 0) {
    return null
  }

  return (
    <Modal
      isOpen={lightBoxState.open}
      onClose={() => {
        onMsg({ type: 'close' })
      }}
      size='large'
    >
      <ModalBody>
        <div className='ui-image-gallery-modal'>
          <div className='ui-image-gallery-modal-main-image'>
            <p className={'text-center'}>{images[lightBoxState.currentImageIndex].title}</p>
            <img
              // TODO optimise large preview as well
              src={images[lightBoxState.currentImageIndex].src}
              alt={images[lightBoxState.currentImageIndex].alt || ''}
              title={images[lightBoxState.currentImageIndex].title || ''}
            />
          </div>
          <div className='ui-image-gallery-modal-thumbnails ui-slimscroll'>
            {images.map((image, index) => (
              <div
                key={index}
                className={`ui-image-gallery-modal-thumbnail ${
                  index === lightBoxState.currentImageIndex ? 'active' : ''
                }`}
                onClick={() => {
                  onMsg({
                    type: 'on_preview_clicked',
                    imageIndex: index,
                  })
                }}
              >
                <img src={image.preview || image.src} alt={image.alt || ''} title={image.title || ''} />
                {(() => {
                  if (!image?.uploadState?.state) {
                    return null
                  }
                  // TODO Use similar look and feel as in file-uploader
                  switch (image.uploadState.state) {
                    case 'pending':
                      return <SlimLoader progress={0} />
                    case 'loading':
                      return <SlimLoader progress={image.uploadState.progress} />
                    case 'loaded':
                      break
                    case 'error':
                      break

                    default:
                      return notReachable(image.uploadState)
                  }
                })()}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
