import { LeftClickPosition } from '~/state'
import { MeasurementType, Point3Dt } from '~/models'
import { PickFromPositionResult } from '../measure/utils'
import { PolygonRenderer3D, Map } from './polygon-renderer'

export class PolygonRendererEdit3D extends PolygonRenderer3D {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.CustomDataSource
  private _dragIndex: number
  private _polylineEntity: Cesium.Entity
  private _polygonEntity: Cesium.Entity
  private _onChange: (points?: Cesium.Cartesian3[]) => void
  private _onComplete: (points?: Cesium.Cartesian3[]) => void
  private _hoverPoint: PickFromPositionResult
  private _measurementType: MeasurementType
  private _destroyed: boolean

  constructor(
    points: Point3Dt[],
    map: Map,
    color: string,
    measurementType: MeasurementType, 
    onChange: (points?: Cesium.Cartesian3[]) => void,
    onComplete?: (points?: Cesium.Cartesian3[]) => void
  ) {
    super(map, color, true)

    this._positions = points.map((p) => {
      return Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude)
    })
    this._points = new Cesium.CustomDataSource()
    this._dragIndex = -1
    this._onChange = onChange
    this._onComplete = onComplete
    this._measurementType = measurementType
    this._destroyed = false

    const polyline = this.createPolylineConstructor(
      new Cesium.CallbackProperty(() => {
        if(this._measurementType === MeasurementType.Distance) {
          return this._positions
        }
        return [...this._positions, this._positions[0]]
      }, false),
      false,
      false
    )
    this._polylineEntity = map.viewer.entities.add({ polyline })

    const polygon = this.createPolygonConstructor(
      new Cesium.CallbackProperty(() => {
        if(this._measurementType === MeasurementType.Distance) {
          return new Cesium.PolygonHierarchy([])
        }
        return new Cesium.PolygonHierarchy([...this._positions, this._positions[0]].map(p => p.clone()))
      }, false),
      false,
      false
    )
    this._polygonEntity = map.viewer.entities.add({ polygon })
    
    map.viewer.dataSources.add(this._points)
    this._toExclude = [this._polylineEntity, this._polygonEntity]
    this._recomputePoints()
  }

  public destroy() {
    this._destroyed = true
    if(this._polylineEntity) {
      this._map.viewer.entities.remove(this._polylineEntity)
      this._polylineEntity = undefined
    }
    if(this._polygonEntity) {
      this._map.viewer.entities.remove(this._polygonEntity)
      this._polygonEntity = undefined
    }
    if(this._points) {
      this._map.viewer.dataSources.remove(this._points)
      this._points = undefined
    }
    super.destroy()
  }

  public onLeftClick(pos: PickFromPositionResult[]) {
    if (this._destroyed) {
      return
    }
    if(this._measurementType === MeasurementType.Point) {
      const item = pos.find(p => !!p.cartesian && !p.model?.id)
      if(item) {
        this._positions = [item.cartesian]
        this._recomputePoints()
      }

      return
    }

    const middlePoint = pos.find(
      (p) => p.model && (
        typeof p.model.id === 'string' && p.model.id.startsWith('middle-point-') || 
        typeof p.model.id?.id === 'string' && p.model.id.id.startsWith('middle-point-')
      )
    )

    if (middlePoint) {
      const p = (middlePoint.model as any).primitive as Cesium.PointPrimitive
      const index = parseInt((middlePoint.model.id?.id || middlePoint.model.id).replace('middle-point-', ''), 10)
      this._positions.splice(index, 0, p.position)
      this._recomputePoints()
    }
  }

  public onMiddleClick(pos: LeftClickPosition) {
    if (this._destroyed) {
      return
    }
    if (pos.picked && (typeof pos.picked.id === 'string' || typeof (pos.picked.id as any)?._id === 'string')) {
      const id = (pos.picked.id as any)?._id || pos.picked.id
      if (id.startsWith('point-')) {
        if (this._positions.length <= (this._measurementType === MeasurementType.Area ? 3 : 2)) {
          return
        }
        const index = parseInt(id.replace('point-', ''), 10)
        this._positions.splice(index, 1)
        this._recomputePoints()
      }
    }
  }

  public onLeftDown() {
    if (this._destroyed) {
      return
    }
    if (!this._hoverPoint) {
      return
    }

    const id = this._hoverPoint.model.id?.id || this._hoverPoint.model.id

    if (id.startsWith('point-')) {
      this._dragIndex = parseInt(id.replace('point-', ''), 10)
      this._map.viewer.scene.screenSpaceCameraController.enableTranslate = false
      this._toExclude = [this._polylineEntity, this._polygonEntity, this._points]
    } else if (id.startsWith('middle-point-')) {
      const p = (this._hoverPoint.model as any)._position?._value as Cesium.Cartesian3
      const index = parseInt(id.replace('middle-point-', ''), 10)
      this._positions.splice(index, 0, p)
      this._recomputePoints()

      this._dragIndex = index
      this._map.viewer.scene.screenSpaceCameraController.enableTranslate = false
    }
  }

  public onLeftUp() {
    if (this._destroyed) {
      return
    }
    this._map.viewer.scene.screenSpaceCameraController.enableTranslate = true
    this._dragIndex = -1
    this._toExclude = [this._polylineEntity, this._polygonEntity]
  }

  public onMouseMove(pos: PickFromPositionResult[]) {
    if (this._destroyed) {
      return
    }
    if (this._dragIndex !== -1) {
      const toUse = pos.find((p) => !!p.cartesian && !p.model?.id)
      if (toUse && toUse.cartesian) {
        this._positions[this._dragIndex].x = toUse.cartesian.x
        this._positions[this._dragIndex].y = toUse.cartesian.y
        this._positions[this._dragIndex].z = toUse.cartesian.z
        this._recomputePoints()
      }
      return
    }

    this._map.viewer.scene.screenSpaceCameraController.enableTranslate = true

    const point = pos.find((p) => p.model && (
      typeof p.model.id === 'string' && p.model.id.startsWith('point-') ||
      typeof p.model.id?.id === 'string' && p.model.id?.id.startsWith('point-')
    ))
    if (point) {
      document.body.style.cursor = 'move'
      if(typeof point.model.id?.id === 'string') {
        point.model = point.model.id
      }
      this._hoverPoint = point
      this._map.viewer.scene.screenSpaceCameraController.enableInputs = false
      return
    }

    const middlePoint = pos.find(
      (p) => p.model && (
        typeof p.model.id === 'string' && p.model.id.startsWith('middle-point-') ||
        typeof p.model.id?.id === 'string' && p.model.id?.id.startsWith('middle-point-')

      )
    )
    if (middlePoint) {
      document.body.style.cursor = 'pointer'
      this._hoverPoint = middlePoint
      this._map.viewer.scene.screenSpaceCameraController.enableInputs = false
      return
    }

    document.body.style.cursor = 'default'
    this._hoverPoint = undefined
    this._map.viewer.scene.screenSpaceCameraController.enableInputs = true
  }

  public onKeydown(e: KeyboardEvent) {
    if (this._destroyed) {
      return
    }
    if (e.key === 'Escape') {
      if (this._onComplete) {
        this._onComplete()
      }
    } else if (e.key === 'Enter') {
      if (this._positions.length >= 3) {
        if (this._onComplete) {
          this._onComplete(this._positions)
        }
      }
    }
  }

  private _recomputePoints() {
    if (this._destroyed) {
      return
    }
    this._points.entities.removeAll()

    this._positions.forEach((p, i) => {
      this._points.entities.add(this.createPoint2(p, i))
    })

    if(this._measurementType === MeasurementType.Area) {
      for (let i = 1; i <= this._positions.length; i++) {
        const prevPoint = this._positions[i - 1]
        const curPoint = this._positions[i === this._positions.length ? 0 : i]
        const difference = Cesium.Cartesian3.subtract(curPoint, prevPoint, new Cesium.Cartesian3())
        const distance = Cesium.Cartesian3.magnitude(difference)
        const direction = Cesium.Cartesian3.normalize(difference, new Cesium.Cartesian3())
  
        const middlePosition = Cesium.Cartesian3.add(
          prevPoint,
          Cesium.Cartesian3.multiplyByScalar(direction, distance * 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        )
        this._points.entities.add(this.createMiddlePoint2(middlePosition, i))
      }
    } else if(this._measurementType === MeasurementType.Distance) {
      for (let i = 1; i < this._positions.length; i++) {
        const prevPoint = this._positions[i - 1]
        const curPoint = this._positions[i]
        const difference = Cesium.Cartesian3.subtract(curPoint, prevPoint, new Cesium.Cartesian3())
        const distance = Cesium.Cartesian3.magnitude(difference)
        const direction = Cesium.Cartesian3.normalize(difference, new Cesium.Cartesian3())
  
        const middlePosition = Cesium.Cartesian3.add(
          prevPoint,
          Cesium.Cartesian3.multiplyByScalar(direction, distance * 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        )
        this._points.entities.add(this.createMiddlePoint2(middlePosition, i))
      }
    }

    this._onChange(this._positions)
  }
}
