import React from 'react'
import { AnnotationRulerArea } from '~/annotations/rulers'
import { PointInput3Dt } from '~/models'

interface AnalysisFormCreateAreaProps {
  points: PointInput3Dt[]
  setPoints: (points: PointInput3Dt[]) => void
}

export const AnalysisFormCreateArea = (props: AnalysisFormCreateAreaProps) => {
  return (
    <AnnotationRulerArea
      pointsChanged={(points) => {
        props.setPoints(
          points.map((p) => ({
            longitude: Cesium.Math.toDegrees(p.longitude),
            latitude: Cesium.Math.toDegrees(p.latitude),
            altitude: p.height,
          }))
        )
      }}
      points={props.points.map((p) => Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, p.altitude))}
    />
  )
}
