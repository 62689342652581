import { string, object, ref, getWebsitePath } from '~/components'

import { AuthButton, AuthInput, useForm, useLocation, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import { SignUpMutation, SignUpRequestT } from '../models/gql-types'

import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import React from 'react'
import { useUser } from '~/base'
import mutation from './mutation-sign-up.gql'

export const AuthSignUp = () => {
  const location = useLocation()
  const user = useUser()

  if (user.isLoggedIn) {
    location.setLocation('/')
    return null
  }

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    SignUpMutation,
    SignUpRequestT & { confirmPassword: string },
    SignUpRequestT
  >({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      orgName: '',
      jobTitle: '',
      marketingConsent: false,
      phoneNumber: '',
    },
    validationSchema: object().shape({
      firstName: string().max(15, 'Must be 15 characters or less').required('Required'),
      lastName: string().max(20, 'Must be 20 characters or less').required('Required'),
      email: string().email('Invalid email address').required('Required'),
      password: string()
        .required('Required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\`])(?=.{8,})/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'
        ),
      confirmPassword: string()
        .required('Required')
        .equals([ref('password'), null], 'Passwords must match'),
      orgName: string().max(64, 'Must be 64 characters or less').required('Required'),
      jobTitle: string().max(15, 'Must be 20 characters or less').required('Required'),
      phoneNumber: string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      ),
    }),
    mutation,
    mapInput: (input: SignUpRequestT & { confirmPassword: string }): SignUpRequestT => {
      delete input['confirmPassword']
      return input
    },
    onSuccess: (result: SignUpMutation) => {
      if (result.signUp.success) {
        getTracking().event({
          category: 'Form',
          action: `User signed up`,
          label: 'Auth',
        })
        location.setLocation('/email-confirmation')
      }
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN}>
      <Helmet title='Sign Up' />
      <div className='subheader-dark mt-5 mb-5'>Join Asseti</div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
      <form autoComplete='off' name='sign-up-form' className='auth-form-container mb-3' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col'>
            <AuthInput
              id='firstName'
              label='First Name'
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.firstName ? errors.firstName : ''}
              error={touched.firstName && Boolean(errors.firstName)}
              halfWidth
            />
          </div>
          <div className='col'>
            <AuthInput
              id='lastName'
              label='Last Name'
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.lastName ? errors.lastName : ''}
              error={touched.lastName && Boolean(errors.lastName)}
              halfWidth
            />
          </div>
        </div>
        <AuthInput
          id='email'
          label='Email'
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <AuthInput
          id='phoneNumber'
          label='Phone Number'
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.phoneNumber ? errors.phoneNumber : ''}
          error={touched.phoneNumber && Boolean(errors.phoneNumber)}
        />
        <AuthInput
          id='password'
          label='Password'
          type='password'
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
        />
        <AuthInput
          id='confirmPassword'
          label='Confirm Password'
          type='password'
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.confirmPassword ? errors.confirmPassword : ''}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        />
        <AuthInput
          id='orgName'
          label='Organization Name'
          value={values.orgName}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.orgName ? errors.orgName : ''}
          error={touched.orgName && Boolean(errors.orgName)}
        />
        <AuthInput
          id='jobTitle'
          label='Job Title'
          value={values.jobTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.jobTitle ? errors.jobTitle : ''}
          error={touched.jobTitle && Boolean(errors.jobTitle)}
        />
        <AuthButton disabled={isSubmitting}>{isSubmitting ? 'Loading...' : 'Sign Up'}</AuthButton>
        <p className='auth-links body2 mb-4'>
          By clicking the "Sign Up" button, you are creating a Asseti account, and you agree to Asseti's
          <br />
          &nbsp;
          <a href={`${getWebsitePath()}/terms`} target='blank' rel='noopener noreferrer' className='auth-layout-link'>
            Terms of Use
          </a>
          &nbsp;and&nbsp;
          <a
            href={`${getWebsitePath()}/privacy-policy`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Privacy Policy
          </a>
          .
        </p>
      </form>
      <AuthLinks>
        <AuthLink to='/signin' className='mb-3'>
          I have an account
        </AuthLink>
      </AuthLinks>
    </AuthLayout>
  )
}
