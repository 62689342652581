import React, { useEffect, useState } from 'react'

import {
  ComponentUpdateRequestT,
  UpdateComponentMutation,
  ComponentTypeSmallDtoT,
  AssetTypeDtoT,
  GetAssetTypeByIdQuery,
  GetAssetTypeByIdQueryVariables,
  ComponentCondition,
  SurveyBasedOperation,
  SiteQueryAsset,
  MaterialTypeSmallDtoT,
  SiteQuerySurvey,
} from '~/models'
import { Select, useForm } from '../forms'
import { getTracking } from '../tracking'
import { useQuery } from '~/components'
import { string, object } from '~/components'
import { ComponentTypeSelect } from './component-type-select'
import UPDATE_COMPONENT from './mutation-component-update.gql'
import { FloatingInput } from '../forms'
import ASSET_TYPE_BY_ID_QUERY from '../../assets/query-asset-type-by-id.gql'
import { ComponentConditionSelect } from './component-condition-select'
import { useAppState } from '~/state'
import { OperationTypeSelect } from '../operation-type'

interface ComponentEditFormProps {
  cancel: () => void
  complete: () => void
  component: SiteQuerySurvey['components'][0]
  newPoints: React.MutableRefObject<Cesium.Cartesian3[]>
  assetTypeID: string
}

export const ComponentEditForm = (props: ComponentEditFormProps) => {
  const { view, timeline, site } = useAppState()
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<ComponentTypeSmallDtoT[]>([])
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialTypeSmallDtoT>(undefined)

  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>(
    site.site.assets.find((a) => a.id === props.component.assetID)
  )

  const assetTypeQuery = useQuery<GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables>(ASSET_TYPE_BY_ID_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    assetTypeQuery
      .refetch({
        assetTypeID: props.assetTypeID,
      })
      .then((res) => {
        setSelectedAssetType(res.data.assetTypeByID as AssetTypeDtoT)
      })
  }, [props.assetTypeID])

  useEffect(() => {
    if (!selectedAssetType) {
      return
    }

    const types = selectedAssetType.componentTypes as ComponentTypeSmallDtoT[]

    for (const levelOne of types) {
      if (levelOne.id === props.component.componentTypeID) {
        setSelectedComponentTypes([levelOne])
        const mt = levelOne.materialTypes.find((x) => x.id === props.component.materialTypeID)
        if (mt) {
          setSelectedMaterialType(mt)
        }
        return
      }

      for (const levelTwo of levelOne.children) {
        if (levelTwo.id === props.component.componentTypeID) {
          setSelectedComponentTypes([levelOne, levelTwo])
          const mt = levelTwo.materialTypes.find((x) => x.id === props.component.materialTypeID)
          if (mt) {
            setSelectedMaterialType(mt)
          }
          return
        }

        for (const levelThree of levelTwo.children) {
          if (levelThree.id === props.component.componentTypeID) {
            setSelectedComponentTypes([levelOne, levelTwo, levelThree])
            const mt = levelThree.materialTypes.find((x) => x.id === props.component.materialTypeID)
            if (mt) {
              setSelectedMaterialType(mt)
            }
            return
          }

          for (const levelFour of levelThree.children) {
            if (levelFour.id === props.component.componentTypeID) {
              setSelectedComponentTypes([levelOne, levelTwo, levelThree, levelFour])
              const mt = levelFour.materialTypes.find((x) => x.id === props.component.materialTypeID)
              if (mt) {
                setSelectedMaterialType(mt)
              }
              return
            }
          }
        }
      }
    }
  }, [selectedAssetType])

  const haveSelectedAComponentType = selectedComponentTypes.length > 0

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<UpdateComponentMutation, ComponentUpdateRequestT>({
      enableReinitialize: true,
      initialValues: {
        name: props.component?.name,
        componentTypeID: props.component?.componentTypeID,
        points: props.component?.points,
        id: props.component.id,
        assetTypeID: props.assetTypeID,
        condition: props.component?.condition || ComponentCondition.Average,
        assetID: props.component.assetID,
        oldAssetID: props.component.assetID,
        operationType: SurveyBasedOperation.GoingForward,
        siteID: view.siteID,
        surveyID: view.surveyID,
        materialTypeID: props.component.materialTypeID || '',
      },
      validationSchema: object().shape({
        name: string().optional(),
        assetTypeID: string().required(),
      }),
      mutation: UPDATE_COMPONENT,
      mapInput: (input: ComponentUpdateRequestT): ComponentUpdateRequestT => {
        return {
          id: props.component.id,
          name: input.name,
          componentTypeID: selectedComponentTypes[selectedComponentTypes.length - 1].id,
          points: props.newPoints.current.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
              altitude: pos.height,
            }
          }),
          assetTypeID: input.assetTypeID,
          condition: input.condition,
          assetID: selectedAsset.id,
          oldAssetID: props.component.assetID,
          operationType: input.operationType,
          siteID: view.siteID,
          surveyID: view.surveyID,
          materialTypeID: selectedMaterialType?.id || props.component.materialTypeID || '',
        }
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Component',
          action: `User Edited Component`,
          label: 'Component',
        })
        props.complete()
      },
    })

  return (
    <div className='drawer-panel'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                props.cancel()
              }}
            >
              Site
            </a>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            Component
          </li>
        </ol>
      </nav>
      <div className='drawer-panel-title-container'>
        <h6 className='drawer-panel-title'>
          Update
          <br />
          Component
        </h6>
      </div>
      <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
        {error && <div className='error'>{error}</div>}
        <Select<SiteQueryAsset>
          id='asset'
          label='Asset'
          placeholder='Select asset'
          onChange={(selected) => {
            setSelectedAsset(selected)
          }}
          canSelectGroup
          canSelectNone
          selectedValue={selectedAsset}
          options={site.site?.assets.map((a) => {
            return {
              id: a.id,
              name: a.name,
              value: a,
              items: [],
            }
          })}
        />
        <ComponentTypeSelect
          componentTypes={selectedAssetType?.componentTypes || []}
          onChange={(selectedComponentTypes, selectedMaterialType) => {
            setSelectedComponentTypes(selectedComponentTypes)
            setSelectedMaterialType(selectedMaterialType)
          }}
          selectedComponentTypes={selectedComponentTypes}
          selectedMaterialType={selectedMaterialType}
        />
        <FloatingInput
          label='Name (optional)'
          id='name'
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
        />
        <ComponentConditionSelect
          onChange={(c) => {
            setFieldValue('condition', c)
          }}
          condition={values.condition}
        />
        <OperationTypeSelect
          name='operationType'
          selectedValue={values.operationType}
          onChange={handleChange}
          survey={timeline.activeSurvey}
        />
        <button type='submit' className='btn submit mb-2' disabled={isSubmitting || !haveSelectedAComponentType}>
          Save
        </button>
        <button className='btn cancel' onClick={() => props.cancel()}>
          Cancel
        </button>
      </form>
    </div>
  )
}
