import { UserInfo } from '~/base'
import { handleCurrencyFormat, handleAreaFormat, handleDistanceFormat } from '~/components'
import { QuerySelect } from '~/models'
import { PanelQuery } from '../state'

export const BrowserText = (function () {
  var canvas = document.createElement('canvas')
  canvas.width = 1280
  canvas.height = 1280

  var context = canvas.getContext('2d')

  /**
   * Measures the rendered width of arbitrary text given the font size and font face
   * @param {string} text The text to measure
   * @param {number} fontSize The font size in pixels
   * @param {string} fontFace The font face ("Arial", "Helvetica", etc.)
   * @returns {number} The width of the text
   **/
  function getWidth(text: string, fontSize: number, fontFace?: string, fontWeight?: string) {
    context.font = fontSize + 'px ' + (fontWeight || 'normal') + ' ' + (fontFace || 'Roboto')
    return context.measureText(text).width
  }

  function getHeight(text: string, fontSize: number, fontFace?: string, fontWeight?: string) {
    context.font = fontSize + 'px ' + (fontWeight || 'normal') + ' ' + (fontFace || 'Roboto')
    const measure = context.measureText(text)
    return measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent
  }

  function getSize(text: string, fontSize: number, fontFace?: string, fontWeight?: string) {
    context.font = fontSize + 'px ' + (fontWeight || 'normal') + ' ' + (fontFace || 'Roboto')
    const measure = context.measureText(text)
    return {
      width: measure.width,
      height: measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent,
    }
  }

  function getFontSizeToFitWidth(text: string, width: number, fontWeight?: string, fontFace?: string) {
    if (width < 0) {
      return {
        fontSize: 0,
        width: 0,
        height: 0,
      }
    }
    let fontSize = 128
    let currentWidth = 1000000
    let currentHeight = 0
    while (currentWidth > width) {
      fontSize -= 2
      context.font = fontSize + 'px ' + (fontWeight || 'normal') + ' ' + (fontFace || 'Roboto')
      const measure = context.measureText(text)
      currentWidth = measure.width
      currentHeight = measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent
    }
    return {
      fontSize,
      width: currentWidth,
      height: currentHeight,
    }
  }

  function getFontSizeToFitSize(text: string, width: number, height: number, fontWeight?: string, fontFace?: string) {
    if (width < 0) {
      return {
        fontSize: 0,
        width: 0,
        height: 0,
      }
    }
    let fontSize = 384
    let currentWidth = 1000000
    let currentHeight = 1000000
    while (currentWidth > width || currentHeight > height) {
      fontSize -= 2
      context.font = fontSize + 'pt ' + (fontWeight || 'normal') + ' ' + (fontFace || 'Roboto')
      const measure = context.measureText(text)
      currentWidth = measure.width * 1.2
      currentHeight = parseInt(context.font) * 1.2
    }
    return {
      fontSize,
      width: currentWidth,
      height: currentHeight,
    }
  }

  return {
    getWidth,
    getHeight,
    getSize,
    getFontSizeToFitWidth,
    getFontSizeToFitSize,
  }
})()

export function computeLegendDimensions(
  labels: string[],
  panelWidth: number
): { labelsPerRow: number; numRows: number } {
  const text = labels.join('')
  const textWidth = BrowserText.getWidth(text, 13) + (15 + 18) * labels.length
  const labelsPerRow = Math.floor(textWidth / panelWidth)
  const numRows = Math.ceil(labels.length / labelsPerRow)
  return {
    labelsPerRow,
    numRows,
  }
}

function lightenColor(color: string, percent: number) {
  var num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export const groupedColors = () => {
  const shades = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'].reverse()

  return shades.map((s) => {
    const ret = [s]
    for (let i = 1; i < 100; i++) {
      ret.push(lightenColor(s, i * 3))
    }

    return ret
  })
}

export function formatValue(queries: ReadonlyArray<PanelQuery>, d: number, user: UserInfo): string {
  const qs = queries || []
  const isCurrency = qs.findIndex((q) => q.select === QuerySelect.EstimatedRepair) !== -1
  const isArea = qs.findIndex((q) => q.select === QuerySelect.TotalArea) !== -1
  const isDistance = qs.findIndex((q) => q.select === QuerySelect.TotalDistance) !== -1
  const ret = isCurrency ? handleCurrencyFormat(d, user) : isArea ? handleAreaFormat(d) : isDistance ? handleDistanceFormat(d) : (d || 0).toFixed(1)
  return ret
}

export function isCurrency(queries: ReadonlyArray<PanelQuery>): boolean {
  const isCurrency = (queries || []).findIndex((q) => q.select === QuerySelect.EstimatedRepair) !== -1
  return isCurrency
}
