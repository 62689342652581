import React from 'react'

import ASSETI_LOGO from '../images/asseti-logo.svg'

interface TabTitleProps {
  title: string
}

export const TabTitle = (props: TabTitleProps) => (
  <div className='tab-title'>
    <img src={ASSETI_LOGO} width='32' height='32' className='tab-title-logo' />
    <div className='tab-title-divider' />
    <h4 className='tab-title-title'>{props.title}</h4>
  </div>
)
