import { Point, Rect } from 'openseadragon'
import { BaseShape, PointShape } from './select'

enum PolygonShapeMode {
  View,
  Create,
  Edit,
}

export class PolygonShape extends BaseShape {
  pointShapes: PointShape[] = []
  mode: PolygonShapeMode
  dragIndex: number = -1
  mousePoint: Point
  downPoint: Point
  downTime: number
  movePoint: Point

  constructor(viewer: OpenSeadragon.Viewer, shapeChanged: (rect: Rect, shape: BaseShape) => void) {
    super(viewer, shapeChanged)
    this.mode = PolygonShapeMode.Create
    this.hidden = false
  }

  get points() {
    return this.pointShapes.map((point) => point.point)
  }

  get lastPoint() {
    const [lastPoint] = this.pointShapes.slice(-1)
    return lastPoint
  }

  get boundingBox() {
    const x = Math.min(...this.points.map((p) => p.x))
    const y = Math.min(...this.points.map((p) => p.y))
    const x1 = Math.max(...this.points.map((p) => p.x))
    const y1 = Math.max(...this.points.map((p) => p.y))
    return new Rect(x, y, x1 - x, y1 - y)
  }

  private createPoint(point: Point) {
    const newPoint = new PointShape(this.viewer, this.shapeChanged)
    newPoint.point = point
    this.pointShapes.push(newPoint)
  }

  public addPoint(point: Point) {
    this.createPoint(point)
  }

  public insertPoint(point: Point, idx: number) {
    // Add the new point.
    const newPoint = new PointShape(this.viewer, this.shapeChanged)
    newPoint.point = point
    this.pointShapes.splice(idx + 1, 0, newPoint)
  }

  private checkClose(p: Point) {
    if (this.points.length < 2) {
      return
    }
    const [firstPoint] = this.points
    const lastPoint = p //this.pointShapes[this.pointShapes.length-1].point

    if (this.mode === PolygonShapeMode.Create) {
      if (this.intersects(firstPoint, lastPoint)) {
        return true
      }
    }

    return false
  }

  private onKey(event: KeyboardEvent) {
    if (this.mode === PolygonShapeMode.Create) {
      if (event.code === 'Escape' || event.code === 'Enter') {
        this.transitionToEdit()
      }
    }
  }

  private initKeyListener() {
    document.addEventListener('keyup', this.onKey.bind(this), false)
  }

  private disposeKeyListener() {
    document.removeEventListener('keyup', this.onKey.bind(this))
  }

  startDrawing() {
    this.initKeyListener()
    super.startDrawing()
  }

  public transitionToEdit(): void {
    document.body.style.cursor = 'default'
    this.disposeKeyListener()
    this.mode = PolygonShapeMode.Edit
    this.shapeChanged(this.boundingBox, this)
    //super.finishDrawing()
    //this.viewer.selectionHandler.frontCanvas.checkIfDrawingFinished(this)
  }

  public setEditing(): void {
    this.mode = PolygonShapeMode.Edit
  }

  public setViewing(): void {
    this.mode = PolygonShapeMode.View
    this.disposeKeyListener()
    super.finishDrawing()
  }

  onMouseDown(point: Point): boolean {
    this.downPoint = point.clone()
    this.downTime = new Date().getTime()
    if (this.mode === PolygonShapeMode.Edit) {
      // Check if intersect a synthetic point.
      for (let i = 0; i < this.pointShapes.length; i++) {
        const p1 = this.pointShapes[i].point
        const p2 = this.pointShapes[i === this.pointShapes.length - 1 ? 0 : i + 1].point
        const p = new Point((p1.x + p2.x) / 2, (p1.y + p2.y) / 2)
        if (this.intersects(p, point)) {
          this.insertPoint(p.clone(), i)
          return false
        }
      }

      // Check if we've started dragging.
      for (let i = 0; i < this.pointShapes.length; i++) {
        const p = this.pointShapes[i]
        if (this.intersects(p.point, point)) {
          this.dragIndex = i
          return false
        }
      }
    }

    return true
  }

  onMouseMove(point: Point): boolean {
    this.movePoint = point
    if (!this.isDrawing && this.mode !== PolygonShapeMode.Create) {
      return true
    }

    if (this.mode === PolygonShapeMode.Create) {
      this.mousePoint = point.clone()

      document.body.style.cursor = 'crosshair'
    } else if (this.mode === PolygonShapeMode.Edit) {
      document.body.style.cursor = 'default'

      if (this.dragIndex !== -1) {
        this.pointShapes[this.dragIndex].point = point.clone()
        return false
      }
    }

    // Mouse hover states.
    if (this.mode === PolygonShapeMode.Create) {
      const [firstPoint] = this.points
      if (firstPoint && this.intersects(firstPoint, point)) {
        document.body.style.cursor = 'pointer'
      }
    } else if (this.mode === PolygonShapeMode.Edit) {
      for (const p of this.points) {
        if (this.intersects(p, point)) {
          document.body.style.cursor = 'move'
          break
        }
      }

      for (let i = 0; i < this.pointShapes.length; i++) {
        const p1 = this.pointShapes[i].point
        const p2 = this.pointShapes[i === this.pointShapes.length - 1 ? 0 : i + 1].point
        const p = new Point((p1.x + p2.x) / 2, (p1.y + p2.y) / 2)
        if (this.intersects(p, point)) {
          document.body.style.cursor = 'pointer'
          break
        }
      }
    }

    return true
  }

  onMouseUp(point: Point): boolean {
    if (!this.isDrawing) {
      return true
    }

    if ((this.downPoint || this.movePoint) && this.dragIndex === -1) {
      if (this.downPoint && this.movePoint) {
        const delta = Math.abs(this.downPoint.distanceTo(this.movePoint))
        if (delta > 50) {
          this.downPoint = undefined
          this.movePoint = undefined
          return true
        }
      }
      this.downPoint = undefined
      this.movePoint = undefined
    }

    if (this.mode === PolygonShapeMode.Create) {
      const deltaTime = new Date().getTime() - this.downTime
      if (deltaTime > 250) {
        this.downTime = new Date().getTime()
        return true
      }
      if (this.checkClose(point)) {
        this.transitionToEdit()
      } else {
        this.addPoint(point.clone())
      }
    } else if (this.mode === PolygonShapeMode.Edit) {
      if (this.dragIndex !== -1) {
        this.shapeChanged(this.boundingBox, this)
        this.dragIndex = -1
        this.downPoint = undefined
        this.movePoint = undefined
      }
    }

    return true
  }

  onRightClick(point: Point): void {
    if (!this.isDrawing) {
      return
    }
    if (this.mode === PolygonShapeMode.Create) {
      for (let i = 0; i < this.pointShapes.length; i++) {
        const p = this.pointShapes[i].point
        if (this.intersects(p, point)) {
          this.pointShapes.splice(i, 1)
          break
        }
      }
    } else if (this.mode === PolygonShapeMode.Edit) {
      if(this.pointShapes.length <= 3) {
        return
      }
      for (let i = 0; i < this.pointShapes.length; i++) {
        const p = this.pointShapes[i].point
        if (this.intersects(p, point)) {
          this.pointShapes.splice(i, 1)
          break
        }
      }
    }
  }

  setPoints(points: Point[]) {
    for (let i = 0; i < points.length; i++) {
      this.createPoint(points[i])
    }
  }

  toPath2D(): Path2D {
    return new Path2D()
  }

  draw(ctx: CanvasRenderingContext2D): void {
    if (this.mode === PolygonShapeMode.Create) {
      this.drawCreate(ctx)
    } else if (this.mode === PolygonShapeMode.Edit) {
      this.drawEdit(ctx)
    } else if (this.mode === PolygonShapeMode.View) {
      this.drawView(ctx)
    }
  }

  drawView(ctx: CanvasRenderingContext2D): void {
    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgb(255,0,0)'
    ctx.fillStyle = 'rgb(255,0,0)'

    const path2D = new Path2D()

    for (let i = 0; i < this.pointShapes.length; i++) {
      const p1 = this.pointShapes[i].point
      const p2 = this.pointShapes[i === this.pointShapes.length - 1 ? 0 : i + 1].point
      path2D.addPath(this.lineToPath2D(p1, p2))
    }
    path2D.closePath()

    ctx.stroke(path2D)
    ctx.fill(path2D, 'evenodd')
    ctx.save()
  }

  drawCreate(ctx: CanvasRenderingContext2D): void {
    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgb(255,0,0)'
    ctx.fillStyle = 'rgb(255,0,0)'

    const path2D = new Path2D()

    for (let i = 0; i < this.pointShapes.length - 1; i++) {
      const p1 = this.pointShapes[i].point
      const p2 = this.pointShapes[i + 1].point
      path2D.addPath(this.lineToPath2D(p1, p2))
      path2D.addPath(this.pointToPath2D(p1))
    }
    if (this.mousePoint) {
      if (this.pointShapes.length === 0) {
        path2D.addPath(this.pointToPath2D(this.mousePoint))
      } else {
        path2D.addPath(this.lineToPath2D(this.pointShapes[this.pointShapes.length - 1].point, this.mousePoint))
        path2D.addPath(this.pointToPath2D(this.pointShapes[this.pointShapes.length - 1].point))
      }
    }
    path2D.closePath()

    ctx.stroke(path2D)
    ctx.fill(path2D, 'evenodd')
    ctx.save()
  }

  drawEdit(ctx: CanvasRenderingContext2D): void {
    // Draw lines.
    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgb(255,0,0)'
    ctx.fillStyle = 'rgb(255,0,0)'
    const linePath = new Path2D()
    for (let i = 0; i < this.pointShapes.length; i++) {
      const p1 = this.pointShapes[i].point
      const p2 = this.pointShapes[i === this.pointShapes.length - 1 ? 0 : i + 1].point
      linePath.addPath(this.lineToPath2D(p1, p2))
      linePath.addPath(this.pointToPath2D(p1))
    }
    linePath.closePath()
    ctx.stroke(linePath)
    ctx.fill(linePath, 'evenodd')
    ctx.save()

    // Draw established points.
    const pointsPath = new Path2D()
    for (const shape of this.pointShapes) {
      pointsPath.addPath(this.pointToPath2D(shape.point))
    }
    pointsPath.closePath()
    ctx.stroke(pointsPath)
    ctx.fill(pointsPath, 'evenodd')
    ctx.save()

    // Draw middle points.
    ctx.strokeStyle = 'rgb(255, 255, 255)'
    const middlePath = new Path2D()
    for (let i = 0; i < this.pointShapes.length; i++) {
      const p1 = this.pointShapes[i].point
      const p2 = this.pointShapes[i === this.pointShapes.length - 1 ? 0 : i + 1].point
      const p = new Point((p1.x + p2.x) / 2, (p1.y + p2.y) / 2)
      middlePath.addPath(this.pointToPath2D(p))
    }

    middlePath.closePath()
    ctx.stroke(middlePath)
    ctx.fill(middlePath, 'evenodd')
    ctx.save()
  }

  pointToPath2D(point: Point) {
    const localPoint = this.toViewerCoords(point)
    const path2d = new Path2D()
    path2d.arc(localPoint.x, localPoint.y, 4, 0, 2 * Math.PI)
    path2d.closePath()
    return path2d
  }

  lineToPath2D(p1: Point, p2: Point) {
    const path2d = new Path2D()
    const localFrom = this.toViewerCoords(p1)
    const localTo = this.toViewerCoords(p2)
    path2d.moveTo(0, 0)
    path2d.moveTo(localFrom.x, localFrom.y)
    path2d.lineTo(localTo.x, localTo.y)
    path2d.closePath()
    return path2d
  }

  private intersects(p1: Point, p2: Point) {
    const CLOSING_DISTANCE = 10
    return this.toViewerCoords(p1).distanceTo(this.toViewerCoords(p2)) < CLOSING_DISTANCE
  }
}
