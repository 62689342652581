import React, { useState } from 'react'
import { useAppState } from '~/state'
import { classes } from '../helpers'
import { UploadPanelBox } from './upload-panel-box'
import { UploadPanelToggle } from './upload-panel-toggle'
import { UploadPanelUploader } from './upload-panel-uploader'

enum FileUploadState {
  Waiting,
  Uploading
}

export const UploadPanel = () => {
  const { drawer, map } = useAppState()
  const [uploadState, setUploadState] = useState(FileUploadState.Waiting)
  const [dragOver, setDragOver] = useState(false)
  const [files, setFiles] = useState<Array<File>>([])

  if(map.positionForBulkImageMoving.current || map.positionForBulkImageTagging.current) {
    return null
  }

  return (
    <div
      className={classes({
        'upload-panel': true,
        open: drawer.uploadOpen,
        expanded: drawer.leftExpanded,
        dragged: dragOver,
      })}
      // style={{display: map.positionForBulkImageTagging ? 'none' : 'initial'}}
    >
      <UploadPanelToggle toggleOpen={() => drawer.setUploadOpen(true)} />
      {uploadState === FileUploadState.Waiting && (
        <UploadPanelBox setDragOver={setDragOver} toggleOpen={() => drawer.setUploadOpen(false)} filesSelected={files => {
          setFiles(files)
          setUploadState(FileUploadState.Uploading)
        }} />
      )}
      {uploadState === FileUploadState.Uploading && (
        <UploadPanelUploader toggleOpen={() => drawer.setUploadOpen(false)} files={files} onComplete={() => setUploadState(FileUploadState.Waiting)} />
      )}
    </div>
  )
}
