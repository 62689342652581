import React, { useEffect } from 'react'
import { useAppState, useCesium } from '~/state'
import {
  LabelTemplate,
  Point,
  PointAndLineColor,
  pointFromPosition,
  pickFromPosition,
  getMidpoint,
} from '~/components/measure/utils'
import { getDistance, formatDistance } from '~/components/measure'

const polylineInput = {
  show: true,
  positions: [new Cesium.Cartesian3(0, 0, 0), new Cesium.Cartesian3(1, 1, 1)],
  width: 1,
  material: new Cesium.Material({
    fabric: {
      type: 'Color',
      uniforms: {
        color: PointAndLineColor,
      },
    },
  }),
}

interface AnnotationRulerLengthWidthProps {
  pointsChanged: (points: Cesium.Cartographic[], annotations: number[]) => void
  points: Cesium.Cartographic[]
}

export const AnnotationRulerLengthWidth = (props: AnnotationRulerLengthWidthProps) => {
  const { map } = useAppState()

  useEffect(() => {
    if (props.points.length >= 3) {
      return lengthWidthUpdate(map, props)
    } else {
      return lengthWidthCreate(map, props)
    }
  }, [JSON.stringify(props.points)])

  return <></>
}

function lengthWidthCreate(map: ReturnType<typeof useCesium>, props: AnnotationRulerLengthWidthProps) {
  const { mouseHandler, points, polyLines, labels, state } = initState(map)

  mouseHandler.setInputAction((leftClick: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
    if (!isMapReady(map)) {
      return
    }
    pickFromPosition(map.viewer, leftClick.position, getIgnoredEntities()).then((picked) => {
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian) || !picked.onModel) {
        return
      }

      if (!state.point1.isSet) {
        state.point1.isSet = true
      } else if (!state.point2.isSet) {
        state.point2.isSet = true
        state.polyLine1.positions = [state.point1.position.clone(), state.point2.position.clone()]
      } else if (!state.point3.isSet) {
        state.point3.isSet = true
        state.polyLine1.positions = [state.point1.position.clone(), state.point2.position.clone()]
        state.polyLine2.positions = [state.point2.position.clone(), state.point3.position.clone()]
        const d1 = drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
        const d2 = drawDistanceLabel(state.point2, state.point3, state.distanceLabel2)

        const ps = []
        for (let i = 0; i < points.length; i++) {
          const p1 = points.get(i).position
          ps.push(Cesium.Cartographic.fromCartesian(p1))
        }

        props.pointsChanged(ps, [d1, d2])
      }
    })
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

  mouseHandler.setInputAction((mouseMove: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
    if (!isMapReady(map)) {
      return
    }

    const ignored = getIgnoredEntities()

    pickFromPosition(map.viewer, mouseMove.endPosition, ignored).then((picked) => {
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian) || !picked.onModel) {
        return
      }

      if (!state.point1.isSet) {
        state.point1.position = cartesian
      } else if (!state.point2.isSet) {
        state.point2.position = cartesian
        drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
      } else if (!state.point3.isSet) {
        state.point3.position = cartesian
        drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
        drawDistanceLabel(state.point2, state.point3, state.distanceLabel2)
      }
    })
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

  function getIgnoredEntities() {
    const allIgnored: any[] = []
    for (let i = 0; i < labels.length; i++) {
      allIgnored.push(labels.get(i))
    }
    for (let i = 0; i < points.length; i++) {
      allIgnored.push(points.get(i))
    }
    allIgnored.push(polyLines)
    return allIgnored
  }

  return () => {
    map.viewer.scene.primitives.remove(points)
    map.viewer.scene.primitives.remove(polyLines)
    map.viewer.scene.primitives.remove(labels)
    mouseHandler.destroy()
  }
}

function lengthWidthUpdate(map: ReturnType<typeof useCesium>, props: AnnotationRulerLengthWidthProps) {
  const { mouseHandler, points, polyLines, labels, state } = initState(map)

  state.point1.position = Cesium.Cartographic.toCartesian(props.points[0])
  state.point2.position = Cesium.Cartographic.toCartesian(props.points[1])
  state.point3.position = Cesium.Cartographic.toCartesian(props.points[2])
  state.polyLine1.positions = [state.point1.position.clone(), state.point2.position.clone()]
  state.polyLine2.positions = [state.point2.position.clone(), state.point3.position.clone()]
  drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
  drawDistanceLabel(state.point2, state.point3, state.distanceLabel2)

  let draggingPoint = 0
  let draggingPointMoved = false

  mouseHandler.setInputAction((leftDown: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
    if (!isMapReady(map)) {
      return
    }

    pickFromPosition(map.viewer, leftDown.position, getIgnoredEntities(), 0.25).then((picked) => {
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      for (let i = 0; i < points.length; i++) {
        if (picked.model?.primitive === points.get(i)) {
          draggingPoint = i + 1
          map.viewer.scene.screenSpaceCameraController.enableInputs = false
          break
        }
      }
    })
  }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

  mouseHandler.setInputAction(() => {
    if (!isMapReady(map)) {
      return
    }

    if (draggingPointMoved) {
      const ps = []
      for (let i = 0; i < points.length; i++) {
        const p1 = points.get(i).position
        ps.push(Cesium.Cartographic.fromCartesian(p1))
      }

      const d1 = drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
      const d2 = drawDistanceLabel(state.point2, state.point3, state.distanceLabel2)
      props.pointsChanged(ps, [d1, d2])
    }
    draggingPoint = 0
    draggingPointMoved = false
    map.viewer.scene.screenSpaceCameraController.enableInputs = true
  }, Cesium.ScreenSpaceEventType.LEFT_UP)

  mouseHandler.setInputAction((mouseMove: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
    if (!isMapReady(map)) {
      return
    }

    pickFromPosition(map.viewer, mouseMove.endPosition, getIgnoredEntities(), 0.5).then((picked) => {
      const cartesian = picked.cartesian
      if (!Cesium.defined(cartesian)) {
        return
      }

      let have = false

      for (let i = 0; i < points.length; i++) {
        if (picked.model?.primitive === points.get(i)) {
          have = true
          break
        }
      }

      if (have) {
        document.body.style.cursor = 'move'
      } else {
        document.body.style.cursor = 'default'
      }

      if (draggingPoint === 0) {
        return
      }
      draggingPointMoved = true

      let startingPoint: Point

      if (draggingPoint === 1) {
        startingPoint = state.point1
      } else if (draggingPoint === 2) {
        startingPoint = state.point2
      } else if (draggingPoint === 3) {
        startingPoint = state.point3
      }

      startingPoint.position = cartesian
      drawDistanceLabel(state.point1, state.point2, state.distanceLabel1)
      drawDistanceLabel(state.point2, state.point3, state.distanceLabel2)
      state.polyLine1.positions = [state.point1.position.clone(), state.point2.position.clone()]
      state.polyLine2.positions = [state.point2.position.clone(), state.point3.position.clone()]
    })
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

  function getIgnoredEntities() {
    const allIgnored: any[] = []
    for (let i = 0; i < labels.length; i++) {
      allIgnored.push(labels.get(i))
    }
    allIgnored.push(polyLines)
    allIgnored.push(state.distanceLabel1)
    allIgnored.push(state.distanceLabel2)
    return allIgnored
  }

  return () => {
    map.viewer.scene.primitives.remove(points)
    map.viewer.scene.primitives.remove(polyLines)
    map.viewer.scene.primitives.remove(labels)
    mouseHandler.destroy()
  }
}

function initState(map: ReturnType<typeof useCesium>) {
  const mouseHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
  const points = map.viewer.scene.primitives.add(
    new Cesium.PointPrimitiveCollection()
  ) as Cesium.PointPrimitiveCollection
  const polyLines = map.viewer.scene.primitives.add(new Cesium.PolylineCollection()) as Cesium.PolylineCollection
  const labels = map.viewer.scene.primitives.add(
    new Cesium.LabelCollection({
      scene: map.viewer.scene,
    })
  ) as Cesium.LabelCollection

  const state = {
    point1: points.add(pointFromPosition()) as Point,
    point2: points.add(pointFromPosition()) as Point,
    point3: points.add(pointFromPosition()) as Point,
    polyLine1: polyLines.add({ ...polylineInput }),
    polyLine2: polyLines.add({ ...polylineInput }),
    distanceLabel1: labels.add({ ...LabelTemplate }),
    distanceLabel2: labels.add({ ...LabelTemplate }),
  }

  return {
    mouseHandler,
    points,
    polyLines,
    labels,
    state,
  }
}

function isMapReady(map: ReturnType<typeof useCesium>) {
  if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
    return false
  }

  return true
}

function drawDistanceLabel(point1: Point, point2: Point, label: Cesium.Label) {
  let height = 0
  const c1 = Cesium.Cartographic.fromCartesian(point1.position)
  const c2 = Cesium.Cartographic.fromCartesian(point2.position)
  if (c2.height >= c1.height) {
    height = c1.height + (c2.height - c1.height) / 2.0
  } else {
    height = c2.height + (c1.height - c2.height) / 2.0
  }

  const geodesic = new Cesium.EllipsoidGeodesic()
  const distance = getDistance(geodesic, point1.position, point2.position)
  label.text = formatDistance(distance)
  label.position = getMidpoint(geodesic, point1, point2, height)
  return distance
}
