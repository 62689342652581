import { PointInput3Dt } from '~/models'
import {
  pickFromPosition as pickFromPositionImpl,
  pickFromPositionAll as pickFromPositionAllImpl,
} from '../visualizations'

export interface Point extends Cesium.PointPrimitive {
  isOnModel: boolean
  isSet: boolean
}

export function getMidpoint(
  geodesic: Cesium.EllipsoidGeodesic,
  point1: Cesium.PointPrimitive,
  point2: Cesium.PointPrimitive,
  height: number
) {
  const c1 = Cesium.Cartographic.fromCartesian(point1.position)
  const c2 = Cesium.Cartographic.fromCartesian(point2.position)
  geodesic.setEndPoints(c1, c2)
  const midpointCartographic = geodesic.interpolateUsingFraction(0.5, new Cesium.Cartographic())
  return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height)
}

export const LabelTemplate = {
  font: '9px monospace',
  showBackground: true,
  horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
  verticalOrigin: Cesium.VerticalOrigin.CENTER,
  pixelOffset: new Cesium.Cartesian2(0, 0),
  eyeOffset: new Cesium.Cartesian3(0.25, 0.5, -1),
  fillColor: Cesium.Color.WHITE,
  text: '',
  scaleByDistance: new Cesium.NearFarScalar(10, 1, 100000, 2),
  position: new Cesium.Cartesian3(0, 0, 0),
}

export const PointAndLineColor = Cesium.Color.RED
export const FillColor = Cesium.Color.RED.withAlpha(0.4)

export function pointFromPosition(cartesian: Cesium.Cartesian3 = new Cesium.Cartesian3(0, 0, 0)) {
  return {
    position: cartesian,
    color: PointAndLineColor,
    pixelSize: 12,
    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
  }
}

export function pointFromPointInput(p: PointInput3Dt) {
  return pointFromPosition(Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude))
}

export interface PickFromPositionResult {
  cartesian?: Cesium.Cartesian3
  onModel: boolean
  model?: any
}

export function pickFromPosition(
  viewer: Cesium.Viewer,
  pos: Cesium.Cartesian2,
  ignore: any[],
  rayWidth = 0.01
): Promise<PickFromPositionResult> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(pickFromPositionImpl(viewer, pos, ignore, rayWidth))
    }, 0)
  })
}

export function pickFromPositionAll(
  viewer: Cesium.Viewer,
  pos: Cesium.Cartesian2,
  ignore: any[],
  rayWidth = 0.01
): Promise<PickFromPositionResult[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(pickFromPositionAllImpl(viewer, pos, ignore, rayWidth))
    }, 0)
  })
}
const scratchPickRay = new Cesium.Ray()

export function pickFromPositionAll2(
  viewer: Cesium.Viewer,
  windowPos: Cesium.Cartesian2,
  objectsToExclude: any[],
  rayWidth = 0.01
): PickFromPositionResult[] {
  // Get a pick raw from the camera and window coordinates.
  viewer.camera.getPickRay(windowPos, scratchPickRay)

  try {
    const allPickedFromScene = viewer.scene.drillPickFromRay(scratchPickRay, 5, objectsToExclude, rayWidth) as Array<{
      position: Cesium.Cartesian3
      object: object
    }>
    if (!Array.isArray(allPickedFromScene)) {
      return []
    }

    const toReturn = []
    for (const pickedFromScene of allPickedFromScene) {
      if (pickedFromScene.object !== undefined) {
        const p = {
          onModel: !!pickedFromScene.position,
          model:
            typeof (pickedFromScene.object as any)?.id?.id === 'string'
              ? (pickedFromScene.object as any).id
              : pickedFromScene.object,
          cartesian: pickedFromScene.position,
        }
        toReturn.push(p)
      }
    }

    return toReturn
  } catch {
    return []
  }
}
