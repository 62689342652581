import React from 'react'
import { useFormik } from 'formik'
import { useMutation } from '~/components'
import { ulid } from 'ulid'
import { FormikConfig } from 'formik/dist/types'
import { ApolloError } from '@apollo/client'

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

interface FormProps<TResult, TVariables, TSubmitVariables = TVariables>
  extends PartialBy<FormikConfig<TVariables>, 'onSubmit' | 'initialValues'> {
  mutation: any
  onSuccess: (result: TResult) => void
  mapInput?: (input: TVariables) => TSubmitVariables
  alwaysGenerateNewCorrelationId?: boolean
}

export function useForm<TResult, TVariables, TSubmitVariables = TVariables>(
  config: FormProps<TResult, TVariables, TSubmitVariables>
) {
  const [correlationId, setCorrelationId] = React.useState<string>(ulid())
  const [lastInput, setLastInput] = React.useState<string>('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<{
    code?: string
    message?: string
    correlationId?: string
  }>({})

  const [doMutation] = useMutation<TResult, { input: TVariables }>(config.mutation)

  const executeMutation = ({ input, resolve, reject, correlationId }: any) => {
    doMutation({
      variables: { input },
      context: { correlationId },
    })
      .then((data: any) => {
        if (data.data) {
          resolve(data.data)
        }
      })
      .catch((err: ApolloError) => {
        reject(err)
      })
  }

  const form = useFormik<TVariables>({
    ...config,
    initialValues: config.initialValues ? config.initialValues : ({} as any),
    onSubmit: (input, { setSubmitting }) => {
      setError({})
      let mappedInput = input as any as TSubmitVariables
      if (config.mapInput) {
        mappedInput = config.mapInput(input)
      }

      const jsonInput = JSON.stringify(mappedInput)
      let id = correlationId
      if (jsonInput != lastInput) {
        setLastInput(jsonInput)
        id = ulid()
        setCorrelationId(id)
      }

      if (config.alwaysGenerateNewCorrelationId) {
        id = ulid()
      }

      return new Promise<TResult>((resolve, reject) => {
        setIsSubmitting(true)
        executeMutation({ input: mappedInput, resolve, reject, correlationId: id })
      })
        .then((res) => {
          config.onSuccess(res)
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setSubmitting(false)
          setIsSubmitting(false)
        })
    },
  })

  return {
    values: form.values as TVariables,
    touched: form.touched,
    errors: form.errors,
    isSubmitting,
    handleChange: form.handleChange,
    handleSubmit: form.handleSubmit,
    handleBlur: form.handleBlur,
    error: error.code === 'internal_error' ? 'An unknown error occurred. Please try again.' : error.message,
    errorCode: error.code,
    correlationId: error.correlationId,
    setFieldValue: function (field: keyof TVariables, value: TVariables[keyof TVariables]) {
      setTimeout(() => {
        form.setFieldValue(field as string, value)
      }, 0)
    },
    submitForm: form.submitForm,
  }
}

type FormWithoutMutationProps<TVariables> = FormikConfig<TVariables>

export function useFormWithoutMutation<TVariables>(config: FormWithoutMutationProps<TVariables>) {
  const form = useFormik<TVariables>({
    ...config,
  })

  const { values, touched, errors, isSubmitting, handleChange, handleSubmit, resetForm } = form

  const handleBlur = (eventOrString: any) => {
    return form.handleBlur(eventOrString)
  }

  return {
    values: values as TVariables,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue: function (field: keyof TVariables, value: TVariables[keyof TVariables]) {
      setTimeout(() => {
        form.setFieldValue(field as string, value)
      }, 0)
    },
  }
}
