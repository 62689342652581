query AdminRoofTypeSets($input: AdminRoofTypeSetsGetBySiteRequestT!) {
  adminRoofTypeSets(input: $input) {
    id
    orgID
    siteID
    surveyID
    types {
      id
      assetID
      type
    }
  }
}