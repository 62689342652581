import React, { useState } from 'react'
import { ActivityItemForSurveyItemT } from '~/models'
import { useAppState } from '~/state'
import { classes, fullDate, timeSince, useLocation } from '../helpers'

interface ActivityCardProps {
  time?: number
  icon: string
  children: any
  items?: ActivityItemForSurveyItemT[]
  link?: 'site' | 'asset' | 'issue' | 'boundary' | 'monitoring-zone' | 'annotation'
}

export function ActivityCard(props: ActivityCardProps) {
  const {view } = useAppState()
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)
  const expandable = Array.isArray(props.items) && props.items.length > 0

  const time = props.time || (Array.isArray(props.items) && props.items[props.items.length - 1].time) || 0

  const navigate = (item: ActivityItemForSurveyItemT) => {
    if (props.link === 'site') {
      location.setLocation(`/${item.id}`)
    } else if (props.link === 'asset') {
      location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${item.id}`)
    } else if (props.link === 'issue') {
      location.setLocation(`/${view.siteID}/${view.surveyID}/issue/${item.id}`)
    } else if (props.link === 'boundary') {
    } else if (props.link === 'monitoring-zone') {
    } else if (props.link === 'annotation') {
    }
  }

  return (
    <>
      <div
        className={classes({
          'activity-card': true,
          expandable,
          expanded,
        })}
        onClick={() => {
          if (expandable) {
            setExpanded(!expanded)
          }
        }}
      >
        <div className='activity-card-timestamp' title={fullDate(time)}>
          {/* <i className='material-icons'>{props.icon}</i> */}
          <span>{timeSince(time)}</span>
        </div>
        <div className='activity-card-activity'>{props.children}</div>
      </div>
      {expanded && (
        <div className='activity-card-expanded'>
          <div className='activity-card-expanded-activity'>
            {[...props.items].reverse().map((x) => {
              return (
                <div key={x.id + x.time + x.userID}>
                  <div>
                    <span
                      className={classes({
                        'activity-card-expanded-link': !!props.link,
                      })}
                      onClick={() => {
                        if(props.link) {
                          navigate(x)
                        }
                      }}
                    >
                      {x.name}
                    </span>
                  </div>
                  <div>
                    <div title={fullDate(x.time)}>
                      <span className='material-icons'>access_time</span>
                      {timeSince(x.time)}
                    </div>
                    <div>
                      <span className='material-icons'>person</span>
                      {x.userName}
                    </div>
                    <div></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
