query getAssetTypesFull {
  assetTypes {
    id
    name
    description
    archived
    createdAt
    createdBy
    updatedAt
    updatedBy
    componentTypes {
      id
      name
      description
      archived
      createdAt
      createdBy
      updatedAt
      updatedBy
      children {
        id
        name
        description
        archived
        createdAt
        createdBy
        updatedAt
        updatedBy
        children {
          id
          name
          description
          archived
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
}
