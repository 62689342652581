query imagesByDay($input: QueryImagesByDaysRequestT!) {
  queryImagesByDay(input: $input) {
    images {
      imageID
      hash
      latitude
      longitude
      altitude
      yaw
      pitch
      roll
      dateTaken
      qx
      qy
      qz
      qw
    }
    haveMore
  }
}
