import { MeasurementType } from '~/models'
import { PickFromPositionResult } from '../measure/utils'
import { PolygonRenderer3D, Map } from './polygon-renderer'

export class PolygonRendererCreate3D extends PolygonRenderer3D {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _polylineEntity: Cesium.Entity
  private _polygonEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _onChange: (points?: Cesium.Cartesian3[]) => void
  private _mousePosition: Cesium.Cartesian3
  private _lastMousePoint: Cesium.PointPrimitive
  private _done: boolean
  private _measurementType: MeasurementType
  private _destroyed: boolean

  constructor(
    map: Map,
    color: string,
    measurementType: MeasurementType,
    onChange: (points?: Cesium.Cartesian3[]) => void
  ) {
    super(map, color, true)
    this._positions = []
    this._points = new Cesium.PointPrimitiveCollection()
    this._onChange = onChange
    this._done = false
    this._measurementType = measurementType
    this._destroyed = false

    const polyline = this.createPolylineConstructor(
      new Cesium.CallbackProperty(() => {
        const pos = this._positions

        if (this._done) {
          if (this._measurementType === MeasurementType.Area) {
            return [...pos, pos[0]]
          } else {
            return [...pos]
          }
        }

        if (this._mousePosition) {
          return [...pos, this._mousePosition]
        }

        return []
      }, false),
      true,
      false
    )

    const polygon = this.createPolygonConstructor(
      new Cesium.CallbackProperty(() => {
        if (this._measurementType !== MeasurementType.Area) {
          return
        }
        const pos = this._positions.map((p) => p.clone())

        if (this._done) {
          if (this._measurementType === MeasurementType.Area) {
            return new Cesium.PolygonHierarchy([...pos, pos[0]])
          } else {
            return new Cesium.PolygonHierarchy([...pos])
          }
        }

        if (this._mousePosition && pos.length > 1) {
          return new Cesium.PolygonHierarchy([...pos, this._mousePosition])
        }

        return new Cesium.PolygonHierarchy([])
      }, false),
      true,
      false
    )

    this._polylineEntity = map.viewer.entities.add({ polyline })
    this._polygonEntity = map.viewer.entities.add({ polygon })
    this._pointsEntity = map.viewer.scene.primitives.add(this._points)
    this._toExclude = [this._polylineEntity, this._polygonEntity]
  }

  public destroy() {
    this._destroyed = true
    if (this._pointsEntity) {
      this._map.viewer.scene.primitives.remove(this._pointsEntity)
      this._pointsEntity = undefined
    }
    if (this._polylineEntity) {
      this._map.viewer.entities.remove(this._polylineEntity)
      this._polylineEntity = undefined
    }
    if (this._polygonEntity) {
      this._map.viewer.entities.remove(this._polygonEntity)
      this._polygonEntity = undefined
    }

    super.destroy()
  }

  public onLeftClick(pos: PickFromPositionResult[]) {
    if (this._destroyed) {
      return
    }
    if (this._measurementType === MeasurementType.Area) {
      for (const item of pos) {
        if (item.model && item.model.id === 'point-0') {
          this._done = true
          this._onChange(this._positions)
          return
        }
      }
    }

    if (this._done) {
      return
    }

    const item = pos.find((p) => !!p.cartesian && !p.model?.id)
    if (item) {
      const position = item.cartesian
      this._points.add(this.createPoint(position, this._positions.length))
      this._positions.push(item.cartesian)

      if (this._measurementType === MeasurementType.Point) {
        this._done = true
        this._onChange(this._positions)
      }
    }
  }

  public onMouseMove(pos: PickFromPositionResult[]) {
    if (this._destroyed) {
      return
    }
    if (this._lastMousePoint) {
      this._points.remove(this._lastMousePoint)
      this._toExclude = [this._polylineEntity, this._polygonEntity]
    }
    if (this._done) {
      return
    }

    this._mousePosition = pos.find((p) => !!p.cartesian)?.cartesian
    if (!this._mousePosition) {
      return
    }

    if (this._measurementType === MeasurementType.Distance) {
      this._lastMousePoint = this._points.add(this.createPoint(this._mousePosition, -1))
      this._toExclude = [this._polylineEntity, this._polygonEntity, this._lastMousePoint]
      return
    }

    const havePoint = pos.findIndex((p) => p.model?.id === 'point-0') !== -1
    if (havePoint) {
      document.body.style.cursor = 'pointer'
      //this._toExclude = [this._polylineEntity, this._polygonEntity, this._lastMousePoint]
    } else {
      document.body.style.cursor = 'default'
      this._lastMousePoint = this._points.add(this.createPoint(this._mousePosition, -1))
      //this._toExclude = [this._polylineEntity, this._polygonEntity, this._lastMousePoint]
    }
  }

  public onMiddleClick() {
    // Ignored.
  }

  public onLeftDown() {
    // Ignored.
  }

  public onLeftUp() {
    // Ignored.
  }

  public onKeydown(e: KeyboardEvent) {
    if (this._destroyed) {
      return
    }
    // Complete Site on Enter
    if (e.key === 'Enter' && this._done === false) {
      this._done = true
      this._onChange(this._positions)
      return
    }
  }
}
