import React from 'react'

interface ButtonGroupProps {
  children: any
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  return <div className='dashboards-button-group'>{props.children}</div>
}

interface IconButtonProps {
  title: string
  icon: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  active: boolean
}

export const IconButton = (props: IconButtonProps) => {
  return <button type='button' onClick={props.onClick} className={props.active ? 'active' : ''}>
    <div>{props.title}</div>
    <i className='material-icons'>{props.icon}</i>
  </button>
}