
type classesInput = {[key: string]: boolean | undefined}

export function classes(input: classesInput): string {
  const classNames = []
  for(const k in input) {
    if(input[k] === true) {
      classNames.push(k)
    }
  }

  return classNames.join(' ')
}
