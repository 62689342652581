import React from 'react'
import dayjs from 'dayjs'

export const AuthTerms = () => {
  return (
    <div className='caption text-center mb-4'>
      Copyright © {dayjs().format('YYYY')} Asseti. All rights reserved.&nbsp;
      <a href='#' target='blank' rel='noopener noreferrer'>
        Terms of Use
      </a>
      &nbsp;and&nbsp;
      <a href='#' target='blank' rel='noopener noreferrer'>
        Privacy Policy
      </a>
      .
    </div>
  )
}
