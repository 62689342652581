import React, { useEffect } from 'react'
import { useLocation, getTracking, isOnCorrectHost, redirectToCorrectHost } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { SignInWith2FaLinkMutation, SignInWith2FaLinkMutationVariables } from '~/models/gql-types'
import { useUser } from '~/base'
import { Helmet } from '~/components'
import { useMutation } from '~/components'
import { ulid } from 'ulid'
import mutationWith2FALink from './mutation-sign-in-with-2fa-link.gql'

export const AuthCompleteSignIn = () => {
  const location = useLocation()
  const tracking = getTracking()
  const user = useUser()
  const urlParams = new URLSearchParams(window.location.search)
  if (user.isLoggedIn) {
    location.setLocation('/')
    return null
  }

  const token = urlParams.get('t')

  if (!token || token === '') {
    location.setLocation('/')
    return null
  }

  const [executeSignIn] = useMutation<SignInWith2FaLinkMutation, SignInWith2FaLinkMutationVariables>(
    mutationWith2FALink
  )

  useEffect(() => {
    executeSignIn({
      variables: {
        input: {
          token,
          domainName: window.location.hostname.split('.')[0] || 'test',
        },
      },
      context: { correlationId: ulid() },
    })
      .then((res) => {
        const result = res.data


        // Redirect if required.
        const currentOrg = result.signInWith2FALink.orgs.find((o) => o.id === result.signInWith2FALink.currentOrgId)
        if (currentOrg && !isOnCorrectHost(currentOrg.domainName)) {
          const path = `/auth?t=${result.signInWith2FALink.token}&id=${result.signInWith2FALink.currentOrgId}`
          redirectToCorrectHost(currentOrg.domainName, path)
          return
        }

        user.setSignIn(result.signInWith2FALink)
        
        tracking.event({
          category: 'Form',
          action: `User signed in with 2FA link`,
          label: 'Auth',
        })
      })
      .catch((e) => {
        location.setLocation('/')
        tracking.event({
          category: 'Form',
          action: `User signed in with 2FA link failed`,
          label: 'Auth',
        })
      })
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
      <Helmet title='Complete Sign In' />
      <div className='subheader-dark mt-5 mb-5'>Two Factor Authentication</div>
      <div className='auth-description-text padding'>Signing in...</div>
    </AuthLayout>
  )
}
