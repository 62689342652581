import React, { useEffect, useState } from 'react'
import { classes, generateKML, Helmet, useQuery } from '~/components'
import {
  ActionHover,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormContent,
  Transition,
  useLocation,
  useToasts,
} from '~/components'
import { useAppState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { getBoundaryCategory, getBoundarySubcategory } from './boundary-categories'
import { BoundariesRemove } from './boundaries-remove-modal'
import { expandRect, expandRectWithExtraLeft } from '~/state/utils'
import { BoundaryMarkerList } from './boundary-marker-list'
import { BoundaryLogsQuery, BoundaryLogsQueryVariables, OrganizationRole } from '~/models'
import QUERY_BOUNDARY_LOGS from './query-boundary-logs.gql'
import { BoundaryDownloadDXFModal } from './boundary-download-dxf-modal'

export const BoundaryDetails = () => {
  const user = useUser()
  const location = useLocation()
  const toasts = useToasts()
  const { view, map, site, boundaryState, drawer } = useAppState()
  const [showRemoveBoundary, setShowRemoveBoundary] = useState(false)
  const [showDownloadDXF, setShowDownloadDXF] = useState(false)

  const boundaryLogsQuery = useQuery<BoundaryLogsQuery, BoundaryLogsQueryVariables>(QUERY_BOUNDARY_LOGS, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })

  const activeBoundaries = [...(boundaryState?.activeBoundaries || [])].sort((a, b) => {
    if (a.additional.category === b.additional.category) {
      if (a.additional.subcategory === b.additional.subcategory) {
        return a.name > b.name ? 1 : -1
      }
      return a.additional.subcategory > b.additional.subcategory ? 1 : -1
    }

    return a.additional.category > b.additional.category ? 1 : -1
  })
  const boundaryIdx = activeBoundaries?.findIndex((i) => i.id === view.boundaryID)
  const boundary = boundaryState?.activeBoundary
  const category = getBoundaryCategory(boundary?.additional?.category)
  const subcategory = getBoundarySubcategory(category, boundary?.additional?.subcategory)
  const title = boundary?.name || 'Boundary'
  const requiresPegs = subcategory?.pegged || false

  useEffect(() => {
    if (!boundary) {
      return
    }

    setTimeout(() => {
      const points: Cesium.Cartographic[] = []

      for (const p of boundary.points) {
        points.push(Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
      }

      // Create a rectangle to containing all the points.
      const expandFn = drawer.expandedPanel === ExpandedPanel.Boundaries ? expandRectWithExtraLeft : expandRect
      const rect = expandFn(Cesium.Rectangle.fromCartographicArray(points), 0, 0.4)

      // Go to boundary location
      map.jumpTo(rect)
    }, 0)
  }, [boundary?.id, drawer.expandedPanel])

  const isDemoSite = site.site?.isDemoSite
  const canUpdate = !isDemoSite && user.hasPermission(ClientPermission.BoundariesUpdate)
  const canRemove = !isDemoSite && user.hasPermission(ClientPermission.BoundariesRemove)

  if (boundaryState.boundaryToEdit) {
    return null
  }

  return (
    <DrawerForm>
      <Helmet title={title} />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem
          title={site.site?.name}
          onClick={() => {
            if (view.assetID) {
              location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${view.assetID}`)
            } else {
              location.setLocation(`/${site.site.id}/${view.surveyID}`)
            }
          }}
        />
        <DrawerFormBreadcrumbItem title={title} />
      </DrawerFormBreadcrumbs>

      <DrawerFormContent noPaddingRight>
        <div className='issue-detail'>
          <div className='issue-detail-issue-number'>
            BOUNDARY
            <Transition width={120} in={!!boundary}>
              <div className='issue-detail-next-prev-container'>
                <div
                  onClick={() => {
                    if (boundaryIdx > 0) {
                      if (view.assetID) {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/boundary/${
                            activeBoundaries[boundaryIdx - 1].id
                          }`
                        )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/boundary/${activeBoundaries[boundaryIdx - 1].id}`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: boundaryIdx === 0,
                  })}
                >
                  <i className='material-icons'>arrow_left</i>
                </div>
                <div
                  onClick={() => {
                    if (boundaryIdx < activeBoundaries.length - 1) {
                      if (view.assetID) {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/boundary/${
                            activeBoundaries[boundaryIdx + 1].id
                          }`
                        )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/boundary/${activeBoundaries[boundaryIdx + 1].id}`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: boundaryIdx === activeBoundaries.length - 1,
                  })}
                >
                  <i className='material-icons'>arrow_right</i>
                </div>
              </div>
            </Transition>
          </div>
          <div className='issue-detail-name'>
            <Transition width={120} in={!!boundary}>
              <div className='issue-detail-content'>
                <h1>{title}</h1>
                {canUpdate && (
                  <div style={{ position: 'relative' }}>
                    <ActionHover
                      withContainer
                      canEdit={canUpdate}
                      canRemove={canRemove}
                      onClickDelete={() => {
                        setShowRemoveBoundary(true)
                      }}
                      onClickEdit={() => {
                        boundaryState.setBoundaryToEdit(boundary)
                      }}
                    />
                  </div>
                )}
              </div>
            </Transition>
          </div>
          {showRemoveBoundary && (
            <BoundariesRemove
              boundary={boundary}
              refetch={() => {
                return site.refetch().then(() => {
                  toasts.addTopLeft('Successfully removed the boundary')
                  location.setLocation(`/${view.siteID}/${view.surveyID}`)
                  setShowRemoveBoundary(false)
                })
              }}
              close={() => {
                setShowRemoveBoundary(false)
              }}
            />
          )}

          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Category</div>
            <div className='issue-detail-content'>
              {category?.name || '-'} - {subcategory?.name || '-'}
            </div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Source Information</div>
            <div className='issue-detail-content'>{boundary?.additional?.sourceInformation || '-'}</div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Requires Markers</div>
            <div className='issue-detail-content'>{requiresPegs ? 'Yes' : 'No'}</div>
          </div>
          <br />
          {drawer.expandedPanel !== ExpandedPanel.Boundaries ? (
            <div
              onClick={() => {
                drawer.setExpandedPanel(ExpandedPanel.Boundaries)
              }}
              className='drawer-list-content-more'
            >
              View all boundaries
            </div>
          ) : (
            <div
              onClick={() => {
                drawer.closeExpandedPanel()
              }}
              className='drawer-list-content-more flipped'
            >
              Hide all boundaries
            </div>
          )}
          <div
            onClick={() => {
              generateKML(`boundary-${site.site.name}-${boundary.name}`, boundary.points)
            }}
            className='drawer-list-content-more'
          >
            Download KML
          </div>
          <div
            onClick={(e) => {
              e.preventDefault()
              setShowDownloadDXF(true)
            }}
            className='drawer-list-content-more'
          >
            Download DXF
          </div>
          {(user.org.role === OrganizationRole.AccountOwner || user.org.role === OrganizationRole.Admin) && (
            <div
              onClick={() => {
                boundaryLogsQuery
                  .refetch({
                    input: {
                      boundaryID: boundary.id,
                      siteID: view.siteID,
                      surveyID: view.surveyID,
                    },
                  })
                  .then((res) => {
                    const link = document.createElement('a')
                    link.id = 'download-csv'
                    link.setAttribute(
                      'href',
                      'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.boundaryLogs)
                    )
                    link.setAttribute('download', `${boundary.id}-${boundary.name}.csv`)
                    document.body.appendChild(link)
                    document.getElementById('download-csv').click()
                    document.body.removeChild(link)
                  })
              }}
              className='drawer-list-content-more'
            >
              Download Logs
            </div>
          )}
          <br />
          {requiresPegs && <BoundaryMarkerList boundary={boundary} />}
        </div>

        {showDownloadDXF && (
          <BoundaryDownloadDXFModal
            boundary={boundary}
            complete={() => {
              setShowDownloadDXF(false)
            }}
            cancel={() => {
              setShowDownloadDXF(false)
            }}
          />
        )}
      </DrawerFormContent>
    </DrawerForm>
  )
}
