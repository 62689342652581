import { useEffect, useState } from 'react'
import AUTH_STATUS_QUERY from '../auth/query-auth-status.gql'
import { ulid } from 'ulid'
import { useUser } from './use-user'
import { useQuery } from '~/components'
import { AuthStatusQuery } from '~/models'

export function useAuthStatus() {
  const user = useUser()
  const [haveAuthStatus, setHaveAuthStatus] = useState(false)
  const [attempt, setAttempt] = useState(0)

  const authStatusQuery = useQuery<AuthStatusQuery>(AUTH_STATUS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
    context: {
      correlationId: ulid(),
    },
  })

  useEffect(() => {
    authStatusQuery
      .refetch()
      .then((res) => {
        user.setSignIn(res.data.authStatus)
        setHaveAuthStatus(true)
      })
      .catch((e) => {
        if (attempt >= 2) {
          window.location.pathname = '/signed-out'
        } else {
          setTimeout(() => {
            setAttempt(attempt + 1)
          }, 250)
        }
      })
  }, [attempt])

  return haveAuthStatus
}
