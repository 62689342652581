import { useState } from 'react'
import { SiteQueryMonitoringZone } from '~/models'

export enum MonitoringZoneRenderState {
  None,
  Site,
  SiteAndAssets,
  SiteAndAsset,
  Asset,
  LinesAndLabels,
}

export enum MonitoringZoneRenderType {
  Lines,
  LinesAndLabels,
  PointsAndLines,
  PointsLinesAndLabels,
}

export function useMonitoringZoneState() {
  const drawIn3DKey = 'asseti-sites-and-assets-draw-mz-in-3d'
  const drawIn3DState = localStorage.getItem(drawIn3DKey) === 'true'
  const [drawIn3D, setDrawIn3D] = useState<boolean>(drawIn3DState)
  const drawIn2DKey = 'asseti-sites-and-assets-draw-mz-in-2d'
  const drawIn2DLocalStorage = localStorage.getItem(drawIn2DKey)
  const drawIn2DState = drawIn2DLocalStorage === 'true' || drawIn2DLocalStorage === null
  const [drawIn2D, setDrawIn2D] = useState<boolean>(drawIn2DState)
  const [renderState, setRenderState] = useState<MonitoringZoneRenderState>(MonitoringZoneRenderState.None)
  const [renderType, setRenderType] = useState<MonitoringZoneRenderType>(MonitoringZoneRenderType.PointsLinesAndLabels)
  const [selectedMonitoringZone, setSelectedMonitoringZone] = useState<SiteQueryMonitoringZone>()
  const [monitoringZoneToEdit, setMonitoringZoneToEdit] = useState<SiteQueryMonitoringZone>()
  const [monitoringZoneToDelete, setMonitoringZoneToDelete] = useState<SiteQueryMonitoringZone>()

  return {
    renderState,
    setRenderState,
    renderType,
    setRenderType,
    renderPoints:
      renderType === MonitoringZoneRenderType.PointsAndLines ||
      renderType === MonitoringZoneRenderType.PointsLinesAndLabels,
    renderLabels:
      renderType === MonitoringZoneRenderType.LinesAndLabels ||
      renderType === MonitoringZoneRenderType.PointsLinesAndLabels,
    selectedMonitoringZone,
    setSelectedMonitoringZone,
    drawIn3D,
    setDrawIn3D: (b: boolean) => {
      localStorage.setItem(drawIn3DKey, b + '')
      setDrawIn3D(b)
    },
    drawIn2D,
    setDrawIn2D: (b: boolean) => {
      localStorage.setItem(drawIn2DKey, b + '')
      setDrawIn2D(b)
    },
    monitoringZoneToEdit,
    setMonitoringZoneToEdit,
    monitoringZoneToDelete,
    setMonitoringZoneToDelete,
  }
}
