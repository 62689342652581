query Sites {
  sites {
    id
    orgID
    isDemoSite
    name
    number
    highRiskIssues
    mediumRiskIssues
    lowRiskIssues
    maxLng
    minLng
    maxLat
    minLat
    updatedAt
    assetCount
    lastSurveyID
  }
  siteGroups {
    id
    name
    siteIDs
    issueCounters {
      high
      medium
      low
    }
    issueCosts {
      high
      medium
      low
    }
    issueStatusCount {
      awaitingAction
      inProgress
      complete
    }
    componentReplacementCost
  }
}
