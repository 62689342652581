import React from 'react'
import { MonitoringZonesCreate } from './monitoring-zones-create'
import { useAppState, useRefState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { DrawerListContentShort } from '../drawer'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { MonitoringZonesFullList } from './monitoring-zones-full-list'
import { MonitoringZonesEdit } from './monitoring-zones-edit'
import { MonitoringZonesRemove } from './monitoring-zones-remove'

interface MonitoringZonesProps {
  refetch: () => Promise<void>
}

export const MonitoringZones = (props: MonitoringZonesProps) => {
  const user = useUser()
  const { view, drawer, site, monitoringZoneState } = useAppState()

  const entity = site.site?.assets.find((a) => a.id === view.assetID) || site.site
  const haveMonitoringZones = entity && entity.monitoringZones.length > 0
  const monitoringZones = haveMonitoringZones ? entity.monitoringZones : []

  const [hideDrawMonitoringZone, setHideDrawMonitoringZone] = useRefState(true)
  const isDemoSite = site.site?.isDemoSite
  const canView = entity && !isDemoSite && user.hasPermission(ClientPermission.MonitoringZonesList)
  const canAdd = entity && !isDemoSite && user.hasPermission(ClientPermission.MonitoringZonesCreate)

  if (!canView) {
    return null
  }

  return (
    <>
      <DrawerListContentShort
        canAdd={canAdd}
        loading={false}
        numEntities={monitoringZones.length}
        panelType={ExpandedPanel.MonitoringZones}
        plural='monitoring zones'
        singular='monitoring zone'
        title='Monitoring Zones'
        onAdd={() => {
          setHideDrawMonitoringZone(false)
        }}
        onShow={() => {
          if (monitoringZones.length === 0) {
            drawer.closeExpandedPanel()
            setHideDrawMonitoringZone(false)
          }
        }}
        emptyButCanAddText='Add a monitoring zone'
        emptyButCantAddText='No monitoring zones have been added'
      />
      {!hideDrawMonitoringZone.current && (
        <MonitoringZonesCreate
          onComplete={(changed) => {
            if (changed) {
              props.refetch().then(() => {
                setHideDrawMonitoringZone(true)
              })
            } else {
              setHideDrawMonitoringZone(true)
            }
          }}
        />
      )}

      {monitoringZoneState.monitoringZoneToEdit && (
        <MonitoringZonesEdit
          monitoringZone={monitoringZoneState.monitoringZoneToEdit}
          onComplete={(changed) => {
            if (changed) {
              props.refetch().then(() => {
                monitoringZoneState.setMonitoringZoneToEdit(undefined)
              })
            } else {
              monitoringZoneState.setMonitoringZoneToEdit(undefined)
            }
          }}
        />
      )}
      {monitoringZoneState.monitoringZoneToDelete && (
        <MonitoringZonesRemove
          monitoringZone={monitoringZoneState.monitoringZoneToDelete}
          refetch={() => {
            return props.refetch().then(() => {
              if (monitoringZones.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            monitoringZoneState.setMonitoringZoneToDelete(undefined)
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.MonitoringZones && (
        <MonitoringZonesFullList monitoringZones={monitoringZones} />
      )}
    </>
  )
}
