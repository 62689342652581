import React from 'react'
import { classes } from '../helpers'

interface ActivityIndicatorProps {
  white?: boolean
}

export const ActivityIndicator = (props: ActivityIndicatorProps) => {
  return (
    <div className='activity-indicator'>
      <div
        className={classes({
          'activity-indicator-spinner': true,
          white: props.white,
        })}
      ></div>
    </div>
  )
}
