import React, { useEffect, useState } from 'react'
import { BaseLayerType, BoundaryRenderState, useAppState, useRefState } from '~/state'

import { SiteQueryBoundary } from '~/models'
import { PolygonColorRed, PolygonRendererEdit } from '../polygon-renderer'
import { BoundaryEditForm } from './boundaries-edit-form'

interface BoundariesEditProps {
  boundary: SiteQueryBoundary
  onComplete: (changed?: boolean) => Promise<void>
}

export const BoundariesEdit = (props: BoundariesEditProps) => {
  const { map, boundaryState, timeline } = useAppState()
  const [points, setPoints] = useRefState<Cesium.Cartesian3[]>(
    props.boundary.points.map((p) => {
      return Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0)
    })
  )
  const [pointsFromKML, setPointsFromKML] = useRefState<Cesium.Cartesian3[]>([])
  const [havePointsFromKML, setHavePointsFromKML] = useState(false)

  // Set map state.
  useEffect(() => {
    map.setShowControls(false)
    timeline.setHidden(true)
    map.setBaseLayerType(BaseLayerType.Satellite)
    boundaryState.setRenderState(BoundaryRenderState.None)

    return () => {
      boundaryState.setRenderState(BoundaryRenderState.SiteAndAssets)
      map.setShowControls(true)
      timeline.setHidden(false)
    }
  }, [props.boundary])

  // Draw polygons.
  useEffect(() => {
    let renderer: PolygonRendererEdit
    if (havePointsFromKML) {
      renderer = new PolygonRendererEdit(
        pointsFromKML.current.map((p) => {
          const carto = Cesium.Cartographic.fromCartesian(p)
          return {
            latitude: Cesium.Math.toDegrees(carto.latitude),
            longitude: Cesium.Math.toDegrees(carto.longitude),
          }
        }),
        map,
        PolygonColorRed,
        (newPoints) => {
          setPointsFromKML(() => newPoints)
        }
      )
    } else {
      renderer = new PolygonRendererEdit(props.boundary.points, map, PolygonColorRed, (newPoints) => {
        setPoints(() => newPoints)
      })
    }

    return () => {
      renderer.destroy()
    }
  }, [props.boundary, havePointsFromKML])

  return (
    <div className='boundaries-draw'>
      <BoundaryEditForm
        boundary={props.boundary}
        newPoints={havePointsFromKML ? pointsFromKML : points}
        setPointsFromKML={(points) => {
          setPointsFromKML(points)
          setHavePointsFromKML(true)
        }}
        complete={() => props.onComplete(true)}
        cancel={() => props.onComplete()}
      />
      <div></div>
    </div>
  )
}
