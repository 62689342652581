import React from 'react'
import { useAppState } from '~/state'
import { ExpandedPanel } from '~/state/use-drawer-state'

interface DrawerListContentProps {
  panelType: ExpandedPanel
  numEntities: number
  loading: boolean
  title: string
  singular: string
  plural: string
  canAdd?: boolean
  canBulkAdd?: boolean
  canMove?: boolean
  hideExpand?: boolean
  onAdd?: () => void
  onBulkAdd?: () => void
  onBulkMove?: () => void
  children?: JSX.Element | JSX.Element[]
  onShow?: () => void
  onHide?: () => void
  emptyButCanAddText?: string
  emptyButCantAddText?: string
  emptyButCanAddComponent?: JSX.Element
  emptyButCantAddComponent?: JSX.Element
}

export const DrawerListContent = (props: DrawerListContentProps) => {
  const { drawer } = useAppState()

  return (
    <div className='drawer-list-content'>
      <h5>
        {props.title}
        {props.canAdd && (
          <span className='material-icons add dimmed' onClick={() => props.onAdd()}>
            add
          </span>
        )}
        {props.canBulkAdd && (
          <span
            onClick={() => {
              props.onBulkAdd()
            }}
            className='material-icons add dimmed'
            title='Bulk create or update issues'
          >
            playlist_add
          </span>
        )}
        {props.canMove && (
          <span
            onClick={() => {
              props.onBulkMove()
            }}
            className='material-icons add dimmed'
            title='Bulk move issues to survey'
          >
            playlist_add_check
          </span>
        )}
      </h5>
      {props.children}
      {props.numEntities > 0 && drawer.expandedPanel !== props.panelType && (
        <div
          onClick={() => {
            drawer.setExpandedPanel(props.panelType)
            if (props.onShow) {
              props.onShow()
            }
          }}
          className='drawer-list-content-more'
        >
          {props.numEntities === 1
            ? `View ${props.numEntities} ${props.singular}`
            : `View ${props.numEntities} ${props.plural}`}{' '}
          {/* <img src={CHEVRONS} title='View more' /> */}
        </div>
      )}
      {!props.hideExpand && props.numEntities > 0 && drawer.expandedPanel === props.panelType && (
        <div
          onClick={() => {
            drawer.setExpandedPanel(ExpandedPanel.None)
            if (props.onHide) {
              props.onHide()
            }
          }}
          className='drawer-list-content-more flipped'
        >
          Hide {props.plural}
          {/* <img src={CHEVRONS} title='View more' /> */}
        </div>
      )}
      {props.numEntities === 0 && props.canAdd && (
        <div className='no-item-tag' onClick={props.onAdd}>
          {props.emptyButCanAddComponent ? props.emptyButCanAddComponent : props.emptyButCanAddText}
        </div>
      )}
      {props.numEntities === 0 && !props.canAdd && (
        <div className='no-item-tag disabled'>
          {props.emptyButCantAddComponent ? props.emptyButCantAddComponent : props.emptyButCantAddText}
        </div>
      )}
    </div>
  )
}
