import { FileWithPath } from '@mantine/dropzone'

export type FileUploadState =
  | { state: 'pending'; progress: number }
  | { state: 'loading'; progress: number }
  | { state: 'loaded' }
  | { state: 'error'; error: string }

export type Image = { src: string; alt?: string; title?: string; uploadState?: FileUploadState; preview?: string }

export const MimeTypes = {
  png: 'image/png',
  gif: 'image/gif',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  avif: 'image/avif',
  heic: 'image/heic',
  mp4: 'video/mp4',
  zip: 'application/zip',
  rar: 'application/x-rar',
  '7z': 'application/x-7z-compressed',
  csv: 'text/csv',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  exe: 'application/vnd.microsoft.portable-executable',
} as const

export type MimeTypeExtension = keyof typeof MimeTypes

export type UploadableFile = {
  file: FileWithPath
  uploadState: FileUploadState
  preview?: string
}
