import { LeftClickPosition, MouseMovePosition } from '~/state'

import { PointT } from '~/models'
import { PolygonRenderer, Map } from './polygon-renderer'

export class PolygonRendererEdit extends PolygonRenderer {
  private _positions: Cesium.Cartesian3[]
  private _points: Cesium.PointPrimitiveCollection
  private _dragIndex: number
  private _polylineEntity: Cesium.Entity
  private _pointsEntity: Cesium.Entity
  private _onChange: (points?: Cesium.Cartesian3[]) => void
  private _onComplete: (points?: Cesium.Cartesian3[]) => void

  constructor(
    points: PointT[],
    map: Map,
    color: string,
    onChange: (points?: Cesium.Cartesian3[]) => void,
    onComplete?: (points?: Cesium.Cartesian3[]) => void
  ) {
    super(map, color)

    this._positions = points.map((p) => Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, 0))
    this._points = new Cesium.PointPrimitiveCollection()
    this._dragIndex = -1
    this._onChange = onChange
    this._onComplete = onComplete

    const polyline = this.createPolylineConstructor(
      new Cesium.CallbackProperty(() => {
        return [...this._positions, this._positions[0]]
      }, false)
    )
    this._polylineEntity = map.viewer.entities.add({ polyline })
    this._pointsEntity = map.viewer.scene.primitives.add(this._points)
    this._recomputePoints(false)
  }

  public destroy() {
    this._map.viewer.entities.remove(this._polylineEntity)
    this._map.viewer.scene.primitives.remove(this._pointsEntity)
    this._pointsEntity = undefined
    this._polylineEntity = undefined
    super.destroy()
  }

  public onLeftClick(pos: LeftClickPosition) {
    if(!this._pointsEntity) {
      return
    }
    if (pos.picked && typeof pos.picked.id === 'string') {
      if (pos.picked.id.startsWith('middle-point-')) {
        const p = (pos.picked as any).primitive as Cesium.PointPrimitive
        const index = parseInt(pos.picked.id.replace('middle-point-', ''), 10)
        this._positions.splice(index, 0, p.position)
        this._recomputePoints(true)
      }
    }
  }

  public onMiddleClick(pos: LeftClickPosition) {
    if(!this._pointsEntity) {
      return
    }
    if (pos.picked && typeof pos.picked.id === 'string') {
      if (pos.picked.id.startsWith('point-')) {
        if (this._positions.length <= 3) {
          return
        }
        const index = parseInt(pos.picked.id.replace('point-', ''), 10)
        this._positions.splice(index, 1)
        this._recomputePoints(true)
      }
    }
  }

  public onLeftDown(pos: LeftClickPosition) {
    if(!this._pointsEntity) {
      return
    }
    if (pos.picked && typeof pos.picked.id === 'string') {
      if (pos.picked.id.startsWith('point-')) {
        this._dragIndex = parseInt(pos.picked.id.replace('point-', ''), 10)
        this._map.viewer.scene.screenSpaceCameraController.enableTranslate = false
      } else if (pos.picked.id.startsWith('middle-point-')) {
        const p = (pos.picked as any).primitive as Cesium.PointPrimitive
        const index = parseInt(pos.picked.id.replace('middle-point-', ''), 10)
        this._positions.splice(index, 0, p.position)
        this._recomputePoints(true)

        this._dragIndex = index
        this._map.viewer.scene.screenSpaceCameraController.enableTranslate = false
      }
    }
  }

  public onLeftUp() {
    if(!this._pointsEntity) {
      return
    }
    this._map.viewer.scene.screenSpaceCameraController.enableTranslate = true
    this._dragIndex = -1
  }

  public onMouseMove(pos: MouseMovePosition) {
    if(!this._pointsEntity) {
      return
    }
    if (this._dragIndex !== -1) {
      this._positions[this._dragIndex].x = pos.cartesian.x
      this._positions[this._dragIndex].y = pos.cartesian.y
      this._positions[this._dragIndex].z = pos.cartesian.z
      this._recomputePoints(true)
      return
    }

    if (pos.picked && typeof pos.picked.id === 'string') {
      if (pos.picked.id.startsWith('point-')) {
        document.body.style.cursor = 'move'
      } else if (pos.picked.id.startsWith('middle-point-')) {
        document.body.style.cursor = 'pointer'
      } else {
        document.body.style.cursor = 'default'
      }
    } else {
      document.body.style.cursor = 'default'
    }
  }

  public onRightClick() {
    // Ignored.
  }

  public onKeydown(e: KeyboardEvent) {
    if(!this._pointsEntity) {
      return
    }
    if (e.key === 'Escape') {
      if (this._onComplete) {
        this._onComplete()
      }
    } else if (e.key === 'Enter') {
      if (this._positions.length >= 3) {
        if (this._onComplete) {
          this._onComplete(this._positions)
        }
      }
    }
  }

  private _recomputePoints(changed: boolean) {
    this._points.removeAll()

    this._positions.forEach((p, i) => {
      this._points.add(this.createPoint(p, i))
    })

    for (let i = 1; i <= this._positions.length; i++) {
      const prevPoint = this._positions[i - 1]
      const curPoint = this._positions[i === this._positions.length ? 0 : i]
      const difference = Cesium.Cartesian3.subtract(curPoint, prevPoint, new Cesium.Cartesian3())
      const distance = Cesium.Cartesian3.magnitude(difference)
      const direction = Cesium.Cartesian3.normalize(difference, new Cesium.Cartesian3())

      const middlePosition = Cesium.Cartesian3.add(
        prevPoint,
        Cesium.Cartesian3.multiplyByScalar(direction, distance * 0.5, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )
      this._points.add(this.createMiddlePoint(middlePosition, i))
    }

    if(changed) {
      this._onChange(this._positions)
    }
  }
}
