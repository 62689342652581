export interface Point {
  x: number
  y: number
}

class Intersection {
  public status: string
  public points: Point[]

  constructor(status?: string) {
    this.status = status
    this.points = new Array()
  }

  appendPoint(point: Point) {
    this.points.push(point)
  }

  appendPoints(points: Point[]) {
    this.points = this.points.concat(points)
  }

  numPoints() {
    return this.points.length
  }

  distance() {
    if (this.numPoints() < 2) {
      return 0
    }

    const d = Math.sqrt(
      Math.pow(this.points[0].x - this.points[1].x, 2) +
        Math.pow(this.points[0].y - this.points[1].y, 2)
    )

    return d
  }
}

function intersectLineLine(a1: Point, a2: Point, b1: Point, b2: Point) {
  var result

  var ua_t = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x)
  var ub_t = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x)
  var u_b = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y)

  if (u_b != 0) {
    var ua = ua_t / u_b
    var ub = ub_t / u_b

    if (0 <= ua && ua <= 1 && 0 <= ub && ub <= 1) {
      result = new Intersection('Intersection')
      result.points.push({
        x: a1.x + ua * (a2.x - a1.x),
        y: a1.y + ua * (a2.y - a1.y),
      })
    } else {
      result = new Intersection('No Intersection')
    }
  } else {
    if (ua_t == 0 || ub_t == 0) {
      result = new Intersection('Coincident')
    } else {
      result = new Intersection('Parallel')
    }
  }

  return result
}

export function intersectLinePolygon(a1: Point, a2: Point, points: Point[]) {
  var result = new Intersection('No Intersection')
  var length = points.length

  for (var i = 0; i < length; i++) {
    var b1 = points[i]
    var b2 = points[(i + 1) % length]
    var inter = intersectLineLine(a1, a2, b1, b2)

    result.appendPoints(inter.points)
  }

  if (result.points.length > 0) result.status = 'Intersection'

  return result
}
