import { useEffect, useState } from 'react'
import { useQuery } from '~/components'
import { SiteQuery, SiteQuerySite, SiteQueryVariables } from '~/models'
import SITE_QUERY from '~/sites/query-site.gql'

export function useExtendedSite(siteID: string): {
  site: SiteQuerySite
  refetch: () => Promise<any>
  loading: boolean
  error: boolean
  setSite: (s: SiteQuerySite) => void
} {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [site, setSite] = useState<SiteQuerySite>()

  // Load additional data we may need which wasn't included in the sites query.
  const siteQuery = useQuery<SiteQuery, SiteQueryVariables>(SITE_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })

  function refetch(siteID: string) {
    setLoading(true)
    return siteQuery
      .refetch({
        input: {
          siteID,
        },
      })
      .then((res) => {
        if (Array.isArray(res.errors) && res.errors.length > 0) {
          setError(true)
          return
        }
        setError(false)
        setSite(res.data.site)
        return res.data.site
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!siteID || siteID === site?.id || loading === true || siteID.length < 10 || !!siteQuery.error) {
      return
    }

    // if(Array.isArray(sites)) {
    //   const site = sites.find(s => s.id === siteID)
    //   if(site) {
    //     setSite(site)
    //     return
    //   }
    // }

    refetch(siteID)
  }, [siteID, loading])

  if (siteID != site?.id) {
    return {
      site: undefined,
      refetch: () => refetch(siteID),
      error,
      loading: loading,
      setSite: () => {},
    }
  }

  return {
    site,
    refetch: () => refetch(siteID),
    error,
    loading,
    setSite,
  }
}
