import { Config } from '~/config'

let nextIndex = 0
export function getImageURL(photo: { hash: string }, orgId: string, index = -1) {
  const subdomains = ['a', 'b', 'c', 'd']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4])
  return `${base}${orgId}/thumbs/${photo.hash}?${orgId === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs}`
}

export function getLargeImageURL(photo: { hash: string }, orgId: string, index = -1) {
  const subdomains = ['a', 'b', 'c', 'd']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4])
  return `${base}${orgId}/${photo.hash}?${orgId === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs}`
}

export function getDownloadImageURL(photo: { hash: string }, orgId: string) {
  return `/data-download/${orgId}/${photo.hash}?${orgId === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs}`
}

export function getMediumImageURL(photo: { hash: string }, orgId: string, index = -1) {
  const subdomains = ['a', 'b', 'c', 'd']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4])
  return `${base}${orgId}/medium/${photo.hash}?${orgId === Config.DemoOrgID ? window.__asseti_demo_qs : window.__asseti_data_qs}`
}

export function getSatellitePreviewURL(satelliteImageID: string, previewHash: string, index = -1) {
  const subdomains = ['a', 'b', 'c', 'd']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4])
  return `${base}satellite/${satelliteImageID}/${previewHash}?${window.__asseti_data_qs}`
}

export function getAnalysisImage(imagePath: string, orgId: string, index = -1) {
  const subdomains = ['a', 'b', 'c', 'd']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4])
  return `${base}${orgId}/analysis/${imagePath}?${window.__asseti_data_qs}`
}

export function getInteriorModel(orgID: string, reconID: string) {
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', 'a')
  return `${base}${orgID}/interior-models/${reconID}/stripped.glb?${window.__asseti_data_qs}`
}

export function getOrderImageURL(photo: { hash: string }, orderID: string, index = -1) {
  const subdomains = ['ao', 'bo', 'co', 'do']
  const toUse = index === -1 ? nextIndex++ : index
  const base = Config.BaseFileUrlWithSubdomains.replace('{s}', subdomains[toUse % 4]) // 'http://localhost:4000' 
  return `${base}${orderID}/thumbnails/${photo.hash}?${window.__asseti_order_qs || window.__asseti_data_qs}`
}