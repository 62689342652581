import { useEffect, useState } from 'react'
import { AdminGetMonitoringZoneTargetsQuery, MonitoringZoneTarget } from '~/models'

import { useQuery } from '~/components'
import QUERY from '../../admin/metadata/monitoring-zone-targets/query-monitoring-zone-targets.gql'

export const useMonitoringZoneTargets = () => {
  const query = useQuery<AdminGetMonitoringZoneTargetsQuery>(QUERY)
  const [targets, setTargets] = useState<MonitoringZoneTarget[]>([])

  // Load defect types.
  useEffect(() => {
    if (query.data?.monitoringZoneTargets[0]) {
      const arr = query.data.monitoringZoneTargets

      const sorted = [...arr].sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })

      setTargets(sorted)
    }
  }, [query.data])

  return targets
}
