import React from 'react'
import ASSETI_LOGO_DARK from '../../images/asseti-logo-dark-horizontal.webp'

interface ReportPageFooterProps {
  pageNumber: number
  content: string
  date: string
}

export const ReportPageFooter = (props: ReportPageFooterProps) => {
  return (
    <footer className='report-page-footer'>
      <div className='report-page-footer-page-number'>{props.pageNumber}</div>
      <div className='report-page-footer-content'>{props.content}</div>
      <div className='report-page-footer-by-asseti'>
        <img src={ASSETI_LOGO_DARK} />
      </div>
      <div className='report-page-footer-page-number'>{props.pageNumber}</div>
    </footer>
  )
}

export const ReportPageHeader = (props: ReportPageFooterProps) => {
  return (
    <header className='report-page-footer header'>
      <div className='report-page-footer-page-number'>{props.pageNumber}</div>
      <div className='report-page-footer-content'>{props.content}</div>
      <div className='report-page-footer-date'>{props.date}</div>
      <div className='report-page-footer-page-number'>{props.pageNumber}</div>
    </header>
  )
}
