import React from 'react'
import { Select, SelectOption } from '~/components'
import { AnnotationType } from '~/models'

interface AnnotationsTypeSelectProps {
  onChange: (r: AnnotationType) => void
  selectedValue: AnnotationType
}

export type SelectItem = {
  id: string
  value: AnnotationType
}

const OPTIONS: Array<SelectOption<SelectItem>> = [
  {
    id: AnnotationType.Point + '',
    name: 'Point',
    value: {
      id: AnnotationType.Point + '',
      value: AnnotationType.Point,
    },
    items: [],
  },
  {
    id: AnnotationType.Distance + '',
    name: 'Distance',
    value: {
      id: AnnotationType.Distance + '',
      value: AnnotationType.Distance,
    },
    items: [],
  },
  {
    id: AnnotationType.Area + '',
    name: 'Area',
    value: {
      id: AnnotationType.Area + '',
      value: AnnotationType.Area,
    },
    items: [],
  },
  {
    id: AnnotationType.Volume + '',
    name: 'Volume',
    value: {
      id: AnnotationType.Volume + '',
      value: AnnotationType.Volume,
    },
    items: [],
  },
  {
    id: AnnotationType.GroundEavesRidge + '',
    name: 'Ground, eaves and ridge',
    value: {
      id: AnnotationType.GroundEavesRidge + '',
      value: AnnotationType.GroundEavesRidge,
    },
    items: [],
  },
  {
    id: AnnotationType.LengthWidth + '',
    name: 'Length & Width',
    value: {
      id: AnnotationType.LengthWidth + '',
      value: AnnotationType.LengthWidth,
    },
    items: [],
  },
]

export const AnnotationTypeSelect = (props: AnnotationsTypeSelectProps) => {
  return (
    <Select<SelectItem>
      id='annotationType'
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Type'
      placeholder={''}
      onChange={(s) => {
        props.onChange(s.value)
      }}
      options={OPTIONS}
    />
  )
}
