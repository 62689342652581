var setHprQuaternion = new Cesium.Quaternion()
var setHprQuaternion2 = new Cesium.Quaternion()
var setHprTranslation = new Cesium.Cartesian3()
var setHprScale = new Cesium.Cartesian3()
var setHprCenter = new Cesium.Cartesian3()
var setHprTransform = new Cesium.Matrix4()
var setHprRotation = new Cesium.Matrix3()

function setHeadingPitchRoll(e: Cesium.Matrix4, t: Cesium.HeadingPitchRoll) {
  // var i = Cesium.Quaternion.fromHeadingPitchRoll(t2, setHprQuaternion),
  //   r = Cesium.Matrix4.getTranslation(e, setHprTranslation),
  //   n = Cesium.Matrix4.getScale(e, setHprScale),
  //   a = Cesium.Matrix4.multiplyByPoint(e, Cesium.Cartesian3.ZERO, setHprCenter),
  //   t = Cesium.Transforms.eastNorthUpToFixedFrame(a, void 0, setHprTransform),
  //   a2 = Cesium.Matrix4.getMatrix3(t, setHprRotation),
  //   t3 = Cesium.Quaternion.fromRotationMatrix(a2, setHprQuaternion2),
  //   a3 = Cesium.Quaternion.multiply(t3, i, new Cesium.Quaternion())
  // return Cesium.Matrix4.fromTranslationQuaternionRotationScale(r, a3, n, new Cesium.Matrix4)
  const hpr = Cesium.Transforms.fixedFrameToHeadingPitchRoll(e)
  hpr.heading += t.heading
  hpr.pitch += t.pitch
  hpr.roll += t.roll
  const i = Cesium.Quaternion.fromHeadingPitchRoll(hpr, setHprQuaternion)
  // const i2 = Cesium.Quaternion.fromHeadingPitchRoll(t, new Cesium.Quaternion())
  //Cesium.Quaternion.multiply(i, i2, i)

  const r = Cesium.Matrix4.getTranslation(e, setHprTranslation)
  const n = Cesium.Matrix4.getScale(e, setHprScale)
  const a = Cesium.Matrix4.multiplyByPoint(e, Cesium.Cartesian3.ZERO, setHprCenter)
  const t2 = Cesium.Transforms.eastNorthUpToFixedFrame(a, Cesium.Ellipsoid.WGS84, setHprTransform)
  const a2 = Cesium.Matrix4.getMatrix3(t2, setHprRotation)
  const t3 = Cesium.Quaternion.fromRotationMatrix(a2, setHprQuaternion2)
  const a3 = Cesium.Quaternion.multiply(t3, i, new Cesium.Quaternion())
  //console.log('i', i, 'r', r, 'n', n, 'a', a, 't2', t2, 'a2', a2, 't3', t3, 'a3', a3)
  return Cesium.Matrix4.fromTranslationQuaternionRotationScale(r, a3, n)
}

export function transform3DTiles(
  transformable: Cesium.Cesium3DTileset,
  movement: {
    originX?: number
    originY?: number
    originZ?: number
    translationX: number
    translationY: number
    translationZ: number
    rotateX: number
    rotateY: number
    rotateZ: number
    scaleX: number
    scaleY: number
    scaleZ: number
    cesiumIonID?: number
  }
) {
  const transformableAny = transformable as any
  const isFromIon = typeof movement.originX !== 'undefined'

  let originalCenter = transformableAny._originalCenter as Cesium.Cartesian3
  if (!originalCenter) {
    if (isFromIon) {
      transformableAny._originalCenter = new Cesium.Cartesian3(
        transformableAny._root.transform[12],
        transformableAny._root.transform[13],
        transformableAny._root.transform[14]
      )
    } else {
      transformableAny._originalCenter = Cesium.Cartesian3.clone(transformable.boundingSphere.center)
    }
    originalCenter = transformableAny._originalCenter
  }
  let originalModelMatrix = transformableAny._originalModelMatrix as Cesium.Matrix4
  if (!originalModelMatrix) {
    transformableAny._originalModelMatrix = transformable.modelMatrix.clone()
  }

  const hpr = Cesium.HeadingPitchRoll.fromDegrees(movement.rotateX, movement.rotateY, movement.rotateZ)
  if (isFromIon) {
    const offset = Cesium.Cartesian3.fromRadians(
      Cesium.Math.toRadians(movement.originX) + movement.translationX,
      Cesium.Math.toRadians(movement.originY) + movement.translationY,
      movement.originZ + movement.translationZ
    )

    const rotation = Cesium.Transforms.headingPitchRollQuaternion(offset, hpr)
    const scale = new Cesium.Cartesian3(movement.scaleX, movement.scaleY, movement.scaleZ)
    //transformableAny._root.transform = Cesium.Matrix4.fromTranslationQuaternionRotationScale(offset, rotation, scale)
    transformable.modelMatrix = Cesium.Matrix4.fromTranslationQuaternionRotationScale(offset, rotation, scale)
  } else if (!movement.cesiumIonID) {
    const center = Cesium.Cartesian3.clone(originalCenter)
    const cartographic = Cesium.Cartographic.fromCartesian(center)
    const offset = Cesium.Cartesian3.fromRadians(
      cartographic.longitude + movement.translationX,
      cartographic.latitude + movement.translationY,
      (cartographic.height || 0) + movement.translationZ
    )
    const om = Cesium.Matrix4.fromTranslation(offset)
    // Set the root to transform to the center of the earth.
    const toCenter = Cesium.Matrix4.fromTranslation(Cesium.Cartesian3.negate(center, new Cesium.Cartesian3()))
    const rotation = setHeadingPitchRoll(om, hpr)
    transformableAny._root.transform = toCenter
    transformable.modelMatrix = rotation
  } else {
    const offset2 = Cesium.Cartographic.fromRadians(movement.translationX, movement.translationY, movement.translationZ)
    const cartographic = Cesium.Cartographic.fromCartesian(originalCenter)
    const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0)
    const offset = Cesium.Cartesian3.fromRadians(
      cartographic.longitude + offset2.longitude,
      cartographic.latitude + offset2.latitude,
      offset2.height
    )
    const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())

    transformable.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
    // transformable.modelMatrix = Cesium.Matrix4.fromTranslationRotationScale(
    //   new Cesium.TranslationRotationScale(translation, Cesium.Quaternion.IDENTITY, new Cesium.Cartesian3(1.1, 1.1, 1.1))
    // )
  }
}

export function transformModel(
  transformable: Cesium.Model,
  movement: {
    originX: number
    originY: number
    originZ: number
    translationX: number
    translationY: number
    translationZ: number
    rotateX: number
    rotateY: number
    rotateZ: number
    scaleX: number
    scaleY: number
    scaleZ: number
  }
) {
  const origin = Cesium.Cartesian3.fromDegrees(movement.originX, movement.originY, movement.originZ)
  const cartographic = Cesium.Cartographic.fromCartesian(origin)
  const offset = Cesium.Cartesian3.fromRadians(
    cartographic.longitude + movement.translationX,
    cartographic.latitude + movement.translationY,
    cartographic.height + movement.translationZ
  )
  const hpr = Cesium.HeadingPitchRoll.fromDegrees(movement.rotateX, movement.rotateY, movement.rotateZ)
  const rotation = Cesium.Transforms.headingPitchRollQuaternion(offset, hpr)
  const scale = new Cesium.Cartesian3(movement.scaleX, movement.scaleY, movement.scaleZ)
  transformable.modelMatrix = Cesium.Matrix4.fromTranslationQuaternionRotationScale(offset, rotation, scale)
}

export function getTransformMatrix(movement: {
  originX: number
  originY: number
  originZ: number
  translationX: number
  translationY: number
  translationZ: number
  rotateX: number
  rotateY: number
  rotateZ: number
  scaleX: number
  scaleY: number
  scaleZ: number
}) {
  const origin = Cesium.Cartesian3.fromDegrees(movement.originX, movement.originY, movement.originZ)
  const cartographic = Cesium.Cartographic.fromCartesian(origin)
  const offset = Cesium.Cartesian3.fromRadians(
    cartographic.longitude + movement.translationX,
    cartographic.latitude + movement.translationY,
    cartographic.height + movement.translationZ
  )
  const hpr = Cesium.HeadingPitchRoll.fromDegrees(movement.rotateX, movement.rotateY, movement.rotateZ)
  const rotation = Cesium.Transforms.headingPitchRollQuaternion(offset, hpr)
  const scale = new Cesium.Cartesian3(movement.scaleX, movement.scaleY, movement.scaleZ)
  return Cesium.Matrix4.fromTranslationQuaternionRotationScale(offset, rotation, scale)
}
