import { BoundaryCategoryType, BoundarySubcategoryType } from '~/models'
import React, { useMemo } from 'react'
import { Select, SelectOption } from '../forms'
import { PolygonColorRed } from '../polygon-renderer'

export interface BoundaryCategory {
  type: BoundaryCategoryType
  name: string
  description: string
  color: string
  subcategories: BoundarySubcategory[]
}

export interface BoundarySubcategory {
  type: BoundarySubcategoryType
  name: string
  description: string
  pegged: boolean
}

export const BOUNDARY_CATEGORIES: BoundaryCategory[] = [
  {
    type: BoundaryCategoryType.Cadastral,
    name: 'Cadastral',
    description: 'All land under control of the site',
    color: PolygonColorRed,
    subcategories: [
      {
        type: BoundarySubcategoryType.Other,
        name: 'Other',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.ThirdPartyLeaseArea,
        name: 'Third Party Lease Area',
        description: 'Leased to third parties',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Owned,
        name: 'Owned',
        description: 'Typically a cadastral boundary',
        pegged: true,
      },
      {
        type: BoundarySubcategoryType.Lease,
        name: 'Lease',
        description: 'Leased land',
        pegged: true,
      },
      {
        type: BoundarySubcategoryType.CrownLease,
        name: 'Crown Lease',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Tenement,
        name: 'Tenement',
        description: '',
        pegged: true,
      },
      {
        type: BoundarySubcategoryType.Tbc,
        name: 'TBC',
        description: '',
        pegged: false,
      },
    ],
  },
  {
    type: BoundaryCategoryType.Limits,
    name: 'Limits',
    description: 'Mining limits applied to use of the land',
    color: '#1145bf',
    subcategories: [
      {
        type: BoundarySubcategoryType.MaximumDisturbanceArea,
        name: 'Maximum Disturbance Area',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.MaximumPitDevelopmentArea,
        name: 'Maximum Pit Development Area',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.ConsentBoundary,
        name: 'Consent Boundary',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Extraction,
        name: 'Extraction',
        description: 'Approved limit of extraction',
        pegged: true,
      },
    ],
  },
  {
    type: BoundaryCategoryType.Regulatory,
    name: 'Regulatory',
    description: 'Regulatory limits applied to use of the land',
    color: '#289e94',
    subcategories: [
      {
        type: BoundarySubcategoryType.ProtectedArea,
        name: 'Protected Area',
        description: 'Boundary protected against encroachment',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.ExclusionZone,
        name: 'Exclusion Zone',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Heritage,
        name: 'Heritage',
        description: 'Heritage area',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Easement,
        name: 'Easement',
        description: '',
        pegged: false,
      },
    ],
  },
  {
    type: BoundaryCategoryType.Environmental,
    name: 'Environmental',
    description: 'Environmental limits applied to use of the land',
    color: '#21b017',
    subcategories: [
      {
        type: BoundarySubcategoryType.BufferLand,
        name: 'Buffer Land',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.ConservationArea,
        name: 'Conservation Area',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.ClearingPermit,
        name: 'Clearing Permit',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.BiodiversityOffset,
        name: 'Biodiversity Offset',
        description: '',
        pegged: false,
      },
    ],
  },
  {
    type: BoundaryCategoryType.Royalty,
    name: 'Royalty',
    description: 'Royalties',
    color: '#a534eb',
    subcategories: [
      {
        type: BoundarySubcategoryType.Government,
        name: 'Government',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.Private,
        name: 'Private',
        description: '',
        pegged: false,
      },
    ],
  },
  {
    type: BoundaryCategoryType.Other,
    name: 'Other',
    description: 'Other boundaries',
    color: '#c48e29',
    subcategories: [
      {
        type: BoundarySubcategoryType.UavSurvey,
        name: 'UAV Survey',
        description: '',
        pegged: false,
      },
      {
        type: BoundarySubcategoryType.StockFloor,
        name: 'Stock Floor',
        description: '',
        pegged: false,
      },
    ],
  },
]
  .sort((a, b) => {
    return a.name < b.name ? -1 : 1
  })
  .map((c) => {
    c.subcategories.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    })
    return c
  })

interface BoundaryCategoryTypeSelectProps {
  name: string
  onChange: React.ChangeEventHandler<BoundaryCategoryType>
  selectedValue: BoundaryCategoryType
  helperText?: {
    [key in BoundaryCategoryType]: string
  }
}

export type BoundaryCategoryTypeSelectItem = {
  id: string
  value: BoundaryCategoryType
}

export const BoundaryCategoryTypeSelect = (props: BoundaryCategoryTypeSelectProps) => {
  const options = useMemo<Array<SelectOption<BoundaryCategoryTypeSelectItem>>>(() => {
    return BOUNDARY_CATEGORIES.map((c) => {
      return {
        id: c.type,
        name: c.name,
        value: {
          id: c.type,
          value: c.type,
        },
        items: [],
      }
    })
  }, [])

  return (
    <Select<BoundaryCategoryTypeSelectItem>
      id={props.name}
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Category'
      placeholder={''}
      onChange={(s) => {
        props.onChange({
          type: 'change',
          target: {
            name: props.name,
            value: s.value,
          },
        } as never as React.ChangeEvent<BoundaryCategoryType>)
      }}
      options={options}
    />
  )
}

interface BoundarySubcategoryTypeSelectProps {
  name: string
  onChange: React.ChangeEventHandler<BoundarySubcategoryType>
  selectedCategory: BoundaryCategoryType
  selectedValue: BoundarySubcategoryType
  helperText?: {
    [key in BoundarySubcategoryType]: string
  }
}

export type BoundarySubcategoryTypeSelectItem = {
  id: string
  value: BoundarySubcategoryType
}

export const BoundarySubcategoryTypeSelect = (props: BoundarySubcategoryTypeSelectProps) => {
  const options = useMemo<Array<SelectOption<BoundarySubcategoryTypeSelectItem>>>(() => {
    const cat = BOUNDARY_CATEGORIES.find((x) => x.type === props.selectedCategory)
    if (!cat) {
      return []
    }
    return cat.subcategories.map((c) => {
      return {
        id: c.type,
        name: c.name,
        value: {
          id: c.type,
          value: c.type,
        },
        items: [],
      }
    })
  }, [props.selectedCategory])

  return (
    <Select<BoundarySubcategoryTypeSelectItem>
      id={props.name}
      selectedValue={{
        id: props.selectedValue + '',
        value: props.selectedValue,
      }}
      label='Subcategory'
      placeholder={''}
      onChange={(s) => {
        props.onChange({
          type: 'change',
          target: {
            name: props.name,
            value: s.value,
          },
        } as never as React.ChangeEvent<BoundarySubcategoryType>)
      }}
      options={options}
    />
  )
}

export function getBoundaryCategory(t: BoundaryCategoryType): BoundaryCategory | undefined {
  return BOUNDARY_CATEGORIES.find((c) => c.type === t)
}

export function getBoundarySubcategory(
  t: BoundaryCategory | undefined,
  s: BoundarySubcategoryType
): BoundarySubcategory | undefined {
  if (!t) {
    return undefined
  }
  return t.subcategories.find((c) => c.type === s)
}

export function getBoundaryColor(
  t: BoundaryCategory | undefined,
  s: BoundarySubcategory | undefined
): { boundary: string; fill: string } {
  if (!t || !s) {
    return { boundary: '#FF0000', fill: '#FF0000' }
  }

  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.Owned)
    return { boundary: '#FF0000', fill: '#FF0000' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.Lease)
    return { boundary: '#00FFFF', fill: '#00FFFF' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.CrownLease)
    return { boundary: '#00FFFF', fill: '#00FFFF' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.Tenement)
    return { boundary: '#00FFFF', fill: '#00FFFF' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.Other)
    return { boundary: '#00FFFF', fill: '#00FFFF' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.ThirdPartyLeaseArea)
    return { boundary: '#000000', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Cadastral && s.type === BoundarySubcategoryType.Tbc)
    return { boundary: '#000000', fill: '#000000' }
  if (t.type === BoundaryCategoryType.Limits && s.type === BoundarySubcategoryType.Extraction)
    return { boundary: '#0000FF', fill: '#0000FF' }
  if (t.type === BoundaryCategoryType.Limits && s.type === BoundarySubcategoryType.MaximumDisturbanceArea)
    return { boundary: '#0000FF', fill: '#0000FF' }
  if (t.type === BoundaryCategoryType.Limits && s.type === BoundarySubcategoryType.MaximumPitDevelopmentArea)
    return { boundary: '#0000FF', fill: '#0000FF' }
  if (t.type === BoundaryCategoryType.Limits && s.type === BoundarySubcategoryType.ConsentBoundary)
    return { boundary: '#0000FF', fill: '#0000FF' }
  if (t.type === BoundaryCategoryType.Regulatory && s.type === BoundarySubcategoryType.Easement)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Regulatory && s.type === BoundarySubcategoryType.ProtectedArea)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Regulatory && s.type === BoundarySubcategoryType.ExclusionZone)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Regulatory && s.type === BoundarySubcategoryType.Heritage)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Environmental && s.type === BoundarySubcategoryType.BiodiversityOffset)
    return { boundary: '#FF00FF', fill: '#FF00FF' }
  if (t.type === BoundaryCategoryType.Environmental && s.type === BoundarySubcategoryType.BufferLand)
    return { boundary: '#FF00FF', fill: '#FF00FF' }
  if (t.type === BoundaryCategoryType.Environmental && s.type === BoundarySubcategoryType.ConservationArea)
    return { boundary: '#FF00FF', fill: '#FF00FF' }
  if (t.type === BoundaryCategoryType.Environmental && s.type === BoundarySubcategoryType.ClearingPermit)
    return { boundary: '#FF00FF', fill: '#FF00FF' }
  if (t.type === BoundaryCategoryType.Royalty && s.type === BoundarySubcategoryType.Government)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Royalty && s.type === BoundarySubcategoryType.Private)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Other && s.type === BoundarySubcategoryType.UavSurvey)
    return { boundary: '#FFFF00', fill: '#FFFF00' }
  if (t.type === BoundaryCategoryType.Other && s.type === BoundarySubcategoryType.StockFloor)
    return { boundary: '#FFFF00', fill: '#FFFF00' }

  return { boundary: '#FF0000', fill: '#FF0000' }
}
