import { ArrayBuffer } from 'spark-md5'

// Taken from https://dev.to/qortex/compute-md5-checksum-for-a-file-in-typescript-59a4
export function computeChecksumMd5(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunkSize = 12 * 1000 * 1000 // Read in chunks of ~12MB
    const spark = new ArrayBuffer()
    const fileReader = new FileReader()

    let cursor = 0 // current cursor in file

    fileReader.onerror = function (): void {
      reject('MD5 computation failed - error reading the file')
    }

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize)
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end))
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = function (e: any): void {
      spark.append(e.target.result) // Accumulate chunk to md5 computation
      cursor += chunkSize // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor)
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        resolve(spark.end())
      }
    }

    processChunk(0)
  })
}
