import { LeftClickHandler, LeftClickPosition, MouseMovePosition, useCesium } from '~/state'

export type Map = ReturnType<typeof useCesium>

export const PolygonColorRed = '#BA160C'
export const PolygonColorOrange = '#B97F0B'
export const PolygonColorMZ = '#b7610b'
export const PolygonColorAnalysis = '#057cb8'

export abstract class PolygonRenderer {
  protected _map: Map
  protected _color: string

  private _onLeftClick: LeftClickHandler
  private _onRightClick: LeftClickHandler
  private _onLeftUp: LeftClickHandler
  private _onLeftDown: LeftClickHandler
  private _onMiddleClick: LeftClickHandler
  private _onMouseMove: Cesium.ScreenSpaceEventHandler
  private _onKeyDown: (e: KeyboardEvent) => void

  constructor(map: Map, color: string) {
    this._map = map
    this._color = color

    this._onKeyDown = this.onKeydown.bind(this)
    this._onRightClick = this.onRightClick.bind(this)
    this._onLeftClick = this.onLeftClick.bind(this)
    this._onLeftUp = this.onLeftUp.bind(this)
    this._onLeftDown = this.onLeftDown.bind(this)
    this._onMiddleClick = this.onMiddleClick.bind(this)
    this._onMouseMove = this.onMouseMove.bind(this)

    window.addEventListener('keydown', this._onKeyDown, false)
    map.addLeftClickHandler(this._onLeftClick)
    map.addRightClickHandler(this._onRightClick)
    map.addLeftDownHandler(this._onLeftDown)
    map.addLeftUpHandler(this._onLeftUp)
    this._onMouseMove = new Cesium.ScreenSpaceEventHandler(map.viewer.scene.canvas)
    this._onMouseMove.setInputAction((move: Cesium.ScreenSpaceEventHandler.MotionEvent) => {
      if (map.viewer.scene.mode === Cesium.SceneMode.MORPHING || !map.viewer.scene.pickPositionSupported) {
        return
      }
      const picked = map.viewer.scene.pick(move.endPosition)
      const ellipsoid = map.viewer.scene.globe.ellipsoid
      const cartesian = map.viewer.camera.pickEllipsoid(move.endPosition, ellipsoid)

      if (!cartesian) {
        // Position on globe was not clicked.
        return
      }

      const cartographic = ellipsoid.cartesianToCartographic(cartesian)

      const input: MouseMovePosition = {
        windowPos: move.endPosition,
        startWindowPos: move.startPosition,
        startCartesian: map.viewer.camera.pickEllipsoid(move.startPosition, ellipsoid),
        cartesian,
        cartographic,
        picked,
      }

      this.onMouseMove(input)

    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    map.addMiddleClickHandler(this._onMiddleClick)
  }

  public destroy() {
    document.body.style.cursor = 'default'
    window.removeEventListener('keydown', this._onKeyDown)
    this._map.removeLeftClickHandler(this._onLeftClick)
    this._map.removeRightClickHandler(this._onRightClick)
    this._map.removeLeftDownHandler(this._onLeftDown)
    this._map.removeLeftUpHandler(this._onLeftUp)
    if(this._onMouseMove) {
      this._onMouseMove.destroy()
      this._onMouseMove = undefined
    }
    this._map.removeMiddleClickHandler(this._onMiddleClick)
  }

  abstract onKeydown(e: KeyboardEvent): void
  abstract onLeftClick(pos: LeftClickPosition): void
  abstract onLeftDown(pos: LeftClickPosition): void
  abstract onLeftUp(pos: LeftClickPosition): void
  abstract onMouseMove(pos: MouseMovePosition): void
  abstract onMiddleClick(pos: LeftClickPosition): void
  abstract onRightClick(pos: LeftClickPosition): void

  protected createPoint(pos: Cesium.Cartesian3, pointIndex: number, pointSize = 8.0) {
    return {
      show: true,
      position: pos,
      pixelSize: pointSize,
      color: Cesium.Color.fromCssColorString(this._color),
      outlineColor: Cesium.Color.TRANSPARENT,
      outlineWidth: 0.0,
      id: 'point-' + pointIndex,
    }
  }

  protected createMiddlePoint(pos: Cesium.Cartesian3, pointIndex: number) {
    return {
      show: true,
      position: pos,
      pixelSize: 3.0,
      color: Cesium.Color.WHITE,
      outlineColor: Cesium.Color.fromCssColorString(this._color),
      outlineWidth: 3,
      id: 'middle-point-' + pointIndex,
    }
  }

  protected createPolylineConstructor(
    positions: Cesium.Property | Cesium.Cartesian3[],
    dashed = false
  ): Cesium.PolylineGraphics.ConstructorOptions {
    return {
      show: true,
      positions,
      material: dashed
        ? new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            gapColor: Cesium.Color.TRANSPARENT,
            dashLength: 10,
          })
        : new Cesium.PolylineOutlineMaterialProperty({
            color: Cesium.Color.fromCssColorString(this._color),
            outlineColor: Cesium.Color.fromCssColorString(this._color).withAlpha(0.2),
            outlineWidth: 2,
          }),
      width: dashed ? 3 : 4,
      clampToGround: true,
    }
  }
}
