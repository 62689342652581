import { LocationInputT } from "~/models"

export function sanitizeLocation(input: LocationInputT): LocationInputT {
  const topLevelProperties = {
    address: true,
    bbox: true,
    center: true,
    context: true,
    geometry: {
      type: true,
      coordinates: true,
    },
    id: true,
    place_name: true,
    place_type: true,
    properties: {
      accuracy: true,
      address: true,
      category: true,
      foursquare: true,
      landmark: true,
    },
    relevance: true,
    text: true,
    type: true,
  }


  const newObj: any = { ...input }

  Object.keys(newObj).forEach((k) => {
    if (!(topLevelProperties as any)[k as any]) {
      delete newObj[k]
    }
  })

  Object.keys(newObj.properties).forEach((k) => {
    if (!(topLevelProperties.properties as any)[k as any]) {
      delete newObj.properties[k]
    }
  })

  Object.keys(newObj.geometry).forEach((k) => {
    if (!(topLevelProperties.geometry as any)[k as any]) {
      delete newObj.geometry[k]
    }
  })

  if(!newObj.bbox) {
    newObj.bbox = []
  }

  if(!newObj.address) {
    newObj.address = newObj.place_name
  }

  return newObj as LocationInputT
}