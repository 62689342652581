import { useEffect, useRef, useState } from 'react'
import { PhotoItem } from './types'
import { SiteQuerySurvey } from '~/models'

interface IssuesFilmStripProps {
  selectedPhoto: PhotoItem
  photos: PhotoItem[]
  activeSurvey?: SiteQuerySurvey
}

export const useIssuesFilmStripScrolling = (props: IssuesFilmStripProps) => {
  const scrollNodeRef = useRef<HTMLElement>()
  const photos = props.photos

  const [scroll, setScroll] = useState<{
    height: number
    scroll: number
  }>()

  // Scroll to selected photo.
  useEffect(() => {
    if (props.selectedPhoto && props.activeSurvey && scrollNodeRef.current) {
      setTimeout(() => {
        scrollNodeRef.current.scrollTo({
          top: 206 * Math.max(0, props.selectedPhoto.index) - 20,
        })
      }, 10)
    }
  }, [props.selectedPhoto?.imageID, props.activeSurvey?.id, scrollNodeRef.current])

  let inViewStartIndex = scroll ? Math.floor(scroll.scroll / 206) - 2 : 0

  if (inViewStartIndex < 0) {
    inViewStartIndex = 0
  }

  let inViewEndIndex = inViewStartIndex + 20

  if (inViewEndIndex > photos.length) {
    inViewEndIndex = photos.length
  }

  const numPrefix = inViewStartIndex
  const numSuffix = photos.length - inViewEndIndex

  return {
    scrollNodeRef,
    setScroll,
    numPrefix,
    numSuffix,
    inViewStartIndex,
    inViewEndIndex,
  }
}
