import { useEffect, useState } from 'react'
import { useAppState, ProjectionType, LeftClickModelPosition } from '~/state'
import { ImagesByModelImageT, ImagesByModelQuery, ImagesByModelQueryVariables } from '~/models'
import { useQuery } from '~/components'
import { useUser } from '~/base'
import {
  getSignedAngle,
  hprToDirectionVector,
  visualizeFrustums,
} from '../components/visualizations'
import QUERY_IMAGES_BY_MODEL from './query-images-by-model.gql'

export function useModelToPhotos(clickAllowed = true) {
  const DEBUG_MODELS_TO_PHOTOS = false
  const { map, site } = useAppState()
  const [lastModelClick, setLastModelClick] = useState<LeftClickModelPosition | undefined>()
  const [images, setImages] = useState<ImagesByModelImageT[] | undefined>()
  const user = useUser()

  const imagesByModelQuery = useQuery<ImagesByModelQuery, ImagesByModelQueryVariables>(QUERY_IMAGES_BY_MODEL, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!lastModelClick) {
      return
    }

    let reconstructionID = ''
    let meta: { translationZ: number } = undefined
    site.site.surveys.forEach((s) => {
      const r = s.reconstructions.find((r) => r.cesium3D.id === lastModelClick.id)
      if (r) {
        reconstructionID = r.id
        meta = r.cesium3D
      }
    })

    const entities: Cesium.Entity[] = []
    let primitives: Cesium.Primitive[] = []
    const samplePositions = [Cesium.Cartographic.fromCartesian(lastModelClick.position)]
    Cesium.sampleTerrainMostDetailed(map.viewer.terrainProvider, samplePositions).then((res) => {
      const heightOffset = res[0].height

      imagesByModelQuery
        .refetch({
          input: {
            longitude: Cesium.Math.toDegrees(samplePositions[0].longitude),
            latitude: Cesium.Math.toDegrees(samplePositions[0].latitude),
            altitude: Cesium.Cartographic.fromCartesian(lastModelClick.position).height,
            terrainAltitude: heightOffset,
            reconstructionID: reconstructionID,
            siteID: site.site.id,
            orgID: user.org.id,
            yaw: 0,
            pitch: 0,
            roll: 0,
          },
        })
        .then((res) => {
          const toSet = []
          const clickPosition = lastModelClick.position

          for (const i of res.data.queryImagesByModel.images) {
            const imageOrigin = Cesium.Cartesian3.fromDegrees(i.longitude, i.latitude, i.altitude + meta.translationZ)
            const clickImageDirection = Cesium.Cartesian3.subtract(clickPosition, imageOrigin, new Cesium.Cartesian3())
            Cesium.Cartesian3.normalize(clickImageDirection, clickImageDirection)

            const clickToImageRay = new Cesium.Ray(imageOrigin, clickImageDirection)
            const picked = map.viewer.scene.drillPickFromRay(clickToImageRay, 3, [], 0.1) as Array<{
              position: Cesium.Cartesian3
            }>
            if (!Array.isArray(picked)) {
              continue
            }

            for (const item of picked) {
              if (!item.position) {
                continue
              }

              const distanceBetweenImageAndClickedPosition = Cesium.Cartesian3.distance(clickPosition, item.position)
              if (distanceBetweenImageAndClickedPosition > 0.25) {
                continue
              }

              const imageDirectionVector = hprToDirectionVector(imageOrigin, i.yaw, i.pitch)
              const normalAngle = getSignedAngle(lastModelClick.normal, imageDirectionVector)

              if (Math.abs(normalAngle) <= 90) {
                const directionAngle = Cesium.Cartesian3.angleBetween(clickImageDirection, imageDirectionVector)
                toSet.push({
                  i,
                  distance: Cesium.Cartesian3.distance(clickPosition, imageOrigin),
                  normalAngle,
                  directionAngle,
                })
              }
              break
            }
          }

          // For sorting the images, get the max distance and angle.
          let maxDistance = 0
          let maxAngle = 0
          let maxNormalAngle = 0

          toSet.forEach((x) => {
            if (Math.abs(x.distance) > maxDistance) {
              maxDistance = Math.abs(x.distance)
            }

            if (Math.abs(x.directionAngle) > maxAngle) {
              maxAngle = Math.abs(x.directionAngle)
            }

            if (Math.abs(x.normalAngle) > maxNormalAngle) {
              maxNormalAngle = Math.abs(x.normalAngle)
            }
          })

          const sorted = toSet.sort((a, b) => {
            const aAngle = Math.abs(a.directionAngle) / maxAngle
            const bAngle = Math.abs(b.directionAngle) / maxAngle
            const aDistance = Math.abs(a.distance) / maxDistance
            const bDistance = Math.abs(b.distance) / maxDistance
            const aNormal = Math.abs(a.normalAngle) / maxNormalAngle
            const bNormal = Math.abs(b.normalAngle) / maxNormalAngle
            const aTotal = 1 * aAngle + 1 * aDistance + 0.5 * aNormal
            const bTotal = 1 * bAngle + 1 * bDistance + 0.5 * bNormal
            return aTotal < bTotal ? -1 : 1
          })

          if (DEBUG_MODELS_TO_PHOTOS) {
            const res = visualizeFrustums(
              sorted.map((s) => s.i),
              Cesium.Color.GREEN
            )

            primitives = res.primitives.map((p) => map.viewer.scene.primitives.add(p))
          }

          setImages(sorted.map((i) => i.i))
        })
    })

    return () => {
      //map.viewer.entities.remove(entity)
      entities.forEach((e) => map.viewer.entities.remove(e))
      primitives.forEach((e) => map.viewer.scene.primitives.remove(e))
    }
  }, [lastModelClick])

  useEffect(() => {
    if (map.projectionType !== ProjectionType.Projection3D) {
      return
    }

    const onModelLeftClick = (pos: LeftClickModelPosition) => {
      if (clickAllowed) {
        setLastModelClick(pos)
      }
    }

    map.addLeftClickModelHandler(onModelLeftClick)

    return () => {
      map.removeLeftClickModelHandler(onModelLeftClick)
    }
  }, [map.projectionType, clickAllowed])

  return {
    images,
    reset: () => {
      setImages(undefined)
    },
  }
}
