import { string, object } from '~/components'
import { AuthButton, AuthInput, useForm, useLocation, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import { ForgotPasswordRequestT, ResendConfirmationMutation } from '../models/gql-types'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import React from 'react'
import mutation from './mutation-resend-confirmation.gql'

export const AuthResendConfirmation = () => {
  const location = useLocation()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    ResendConfirmationMutation,
    ForgotPasswordRequestT
  >({
    initialValues: {
      email: '',
    },
    validationSchema: object().shape({
      email: string().email('Invalid email address').required('Required'),
    }),
    mutation,
    onSuccess: (result: ResendConfirmationMutation) => {
      if (result.resendConfirmation.success) {
        getTracking().event({
          category: 'Form',
          action: `User resent confirmation email`,
          label: 'Auth',
        })
        location.setLocation('/')
      }
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Resend Confirmation Email' />
      <div className='subheader-dark mt-5 mb-5'>Resend Confirmation Email</div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
      <form autoComplete='off' className='auth-form-container mb-3' onSubmit={handleSubmit}>
        <p className='auth-description-text mb-4'>
          Enter your email address below and we'll send you a link to reset your password.
        </p>
        <AuthInput
          id='email'
          label='Email'
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <AuthButton disabled={isSubmitting}>Submit</AuthButton>
      </form>
      <AuthLinks>
        <AuthLink to='/signin' className='mb-3'>
          Back to Sign In
        </AuthLink>
      </AuthLinks>
    </AuthLayout>
  )
}
