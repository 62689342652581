import React, { useState, useMemo } from 'react'
import { DrawerPopoutList, DrawerPopoutListCard, IssueCounterDots, SearchBox, useLocation } from '~/components'
import { useAppState } from '~/state'
import { RiskType, SiteQueryAsset } from '~/models'

export const AssetFullList = () => {
  const { site, timeline } = useAppState()
  const assets = site.site?.assets || []
  const [search, setSearch] = useState<string | undefined>()

  const assetsToUse = useMemo(() => {
    const lowerSearch = (search || '').toLocaleLowerCase()
    const sortedAssets = [...assets]
      .filter((a) => {
        if (lowerSearch === '') {
          return true
        }

        return (
          a.name.toLocaleLowerCase().includes(lowerSearch) ||
          (a.assetType?.name || '').toLocaleLowerCase().includes(lowerSearch)
        )
      })
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    const toReturn = []
    for (const asset of sortedAssets) {
      let numHighIssues = 0
      let numMediumIssues = 0
      let numLowIssues = 0

      for (const i of (timeline.activeSurvey.issues || []).filter(x => x.assetID === asset.id)) {
        if (i.riskType === RiskType.High) {
          numHighIssues++
        } else if (i.riskType === RiskType.Medium) {
          numMediumIssues++
        } else if (i.riskType === RiskType.Low) {
          numLowIssues++
        }
      }

      toReturn.push({
        ...asset,
        numHighIssues,
        numLowIssues,
        numMediumIssues,
      })
    }

    return toReturn
  }, [assets, search])

  const Controls = useMemo(() => {
    return <SearchBox search={search} setSearch={setSearch} />
  }, [search])

  return (
    <DrawerPopoutList
      width={360}
      title='Assets'
      total={assets.length}
      count={assetsToUse.length}
      emptyText='There are no assets to display.'
      controls={Controls}
    >
      {assetsToUse.map((asset) => {
        return <AssetCard key={asset.id + timeline.activeSurvey.id} asset={asset} />
      })}
    </DrawerPopoutList>
  )
}

interface AssetCardProps {
  asset: SiteQueryAsset & { numHighIssues: number; numMediumIssues: number; numLowIssues: number }
}

function AssetCard(props: AssetCardProps) {
  const { view, drawer } = useAppState()
  const location = useLocation()

  const assetType = props.asset.assetType?.name || 'No asset type'

  return (
    <DrawerPopoutListCard
      className='asset-card'
      active={view.assetID === props.asset.id}
      onClick={() => {
        drawer.closeExpandedPanel()
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${props.asset.id}`)
      }}
    >
      <div className='asset-card-upper'>{props.asset.name}</div>
      <div className='asset-card-middle truncate' title={assetType}>{assetType}</div>
      <div className='asset-card-lower'>
        <IssueCounterDots
          numHighIssues={props.asset.numHighIssues}
          numMediumIssues={props.asset.numMediumIssues}
          numLowIssues={props.asset.numLowIssues}
          alwaysShowIssueCounts
        />
      </div>
    </DrawerPopoutListCard>
  )
}
