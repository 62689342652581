query organizationForActivity {
  organizationSummary {
    issueCounters {
      high
      medium
      low
    }
    issueCosts {
      high
      medium
      low
    }
    issueStatusCounts {
      awaitingAction
      inProgress
      complete
    }
    componentReplacementCost
  }
}
