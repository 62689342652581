import DxfParser from 'dxf-json'

export type Vertex = { x: number; y: number }
export type Polygon = {
  id: string
  vertices: Vertex[]
  closed: boolean
}

/**
 * Extract polygons from DXF file content.
 * @param fileContent The contents of the DXF file as a string.
 * @returns An array of polygons with vertices and closure status.
 */
export function parseDXF(dfx: string): Polygon[] {
  const parser = new DxfParser()

  try {
    const dxf = parser.parseSync(dfx)
    const polygons: Polygon[] = []

    dxf.entities.forEach((entity: any) => {
      if (entity.type === 'LWPOLYLINE' || entity.type === 'POLYLINE') {
        // Extract handle as the id
        const id = entity.handle || ''

        // Extract vertices and closed status
        const vertices = entity.vertices.map((vertex: any) => ({
          x: vertex.x,
          y: vertex.y,
        }))

        const isClosed = entity.closed || false

        polygons.push({ id, vertices, closed: isClosed })
      }

      if (entity.type == 'HATCH') {
        entity.boundaryPaths.forEach((path: any) => {
          const id = entity.handle || ''
          const vertices = path.vertices.map((vertex: any) => ({
            x: vertex.x,
            y: vertex.y,
          }))
          polygons.push({ id, vertices, closed: path.isClosed })
        })
      }
    })

    return polygons.filter((x) => x.vertices.length > 2)
  } catch (error) {
    throw new Error(`Error parsing DXF content: ${error}`)
  }
}
