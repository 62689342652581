import { useQuery } from '~/components'
import React, { useEffect, useMemo, useState } from 'react'
import ASSET_TYPE_BY_ID_QUERY from '../../assets/query-asset-type-by-id.gql'
import { Select2, Select2Option } from '../forms'
import { AssetTypeDtoT, ComponentTypeSmallDtoT, GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables, MaterialTypeSmallDtoT } from '~/models'

interface ComponentTypeSelectLoadTypesProps {
  assetTypeID?: string
  selectedComponentTypes: ComponentTypeSmallDtoT[]
  selectedMaterialType: MaterialTypeSmallDtoT
  onChange?: (selectedComponentTypes: ComponentTypeSmallDtoT[], selectedMaterialType: MaterialTypeSmallDtoT) => void
  label?: string
  disabled?: boolean
  onAssetTypeLoaded: (a: AssetTypeDtoT) => void
}

export const ComponentTypeSelectLoadTypes = (props: ComponentTypeSelectLoadTypesProps) => {
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()
  const assetTypeQuery = useQuery<GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables>(ASSET_TYPE_BY_ID_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (!props.assetTypeID || props.assetTypeID === '') {
      return
    }

    props.onAssetTypeLoaded(undefined)
    assetTypeQuery
      .refetch({
        assetTypeID: props.assetTypeID,
      })
      .then((res) => {
        props.onAssetTypeLoaded(res.data.assetTypeByID as AssetTypeDtoT)
        setSelectedAssetType(res.data.assetTypeByID as AssetTypeDtoT)
      })
  }, [props.assetTypeID])

  const selected = props.selectedMaterialType
    ? [...props.selectedComponentTypes, props.selectedMaterialType]
    : props.selectedComponentTypes

  const options = useMemo(() => {
    const base = (selectedAssetType?.componentTypes || []).filter((componentType) => !componentType.archived)

    function getItems(
      c: ComponentTypeSmallDtoT | MaterialTypeSmallDtoT
    ): Select2Option<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT> {
      const toReturn: Select2Option<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT> = {
        id: c.id,
        name: c.name,
        value: c,
        items: [],
      }

      const children = (c as any).children as ComponentTypeSmallDtoT[]
      const materialTypes = (c as any).materialTypes as MaterialTypeSmallDtoT[]
      const haveChildren = Array.isArray(children) && children.length > 0
      const haveMaterialTypes = Array.isArray(materialTypes) && materialTypes.length > 0
      if (haveChildren) {
        toReturn.items = children.map(getItems)
      } else if (haveMaterialTypes) {
        toReturn.items = materialTypes.map(getItems)
      }

      return toReturn
    }

    return base.map(getItems)
  }, [selectedAssetType?.componentTypes])

  return (
    <Select2<ComponentTypeSmallDtoT | MaterialTypeSmallDtoT>
      disabled={!selectedAssetType?.componentTypes || props.disabled}
      id='component-types'
      label={props.label || 'Type'}
      placeholder='Select a type'
      options={options}
      onChange={(selected, tree) => {
        let mt : MaterialTypeSmallDtoT = undefined
        const cts = [...tree] as ComponentTypeSmallDtoT[]
        if((selected as any).__typename === 'MaterialTypeDtoT') {
          mt = selected
        } else {
          cts.push(selected as ComponentTypeSmallDtoT)
        }

        props.onChange(cts, mt)
      }}
      selectedValues={selected}
    />
  )
}
