import React from 'react'
import { useLocation, fullDate, timeSince, IssueCounterDots } from '~/components'
import { useAppState } from '~/state'
import { ImageSummaryByOrgQuery, SearchResult, SitesQuerySite } from '~/models'

type SiteWithExtra = {
  s: SitesQuerySite
  extra: ImageSummaryByOrgQuery['queryImageSummaryByOrg']['sites'][0]
}

interface SitesListTableProps {
  sites: SiteWithExtra[]
}

export const SitesListTable = (props: SitesListTableProps) => {
  const location = useLocation()
  const { search, map, drawer } = useAppState()

  const handleTableOnClick = (site: SitesQuerySite) => {
    drawer.toggleLeft()
    search.reset()
    setTimeout(() => {
      location.setLocation('/' + site.id)
    }, 500)
  }

  return (
    <div style={{ marginBottom: 24 }}>
      <table className='site-list-table'>
        <thead>
          <tr className='site-list-table-header'>
            <th style={{ paddingLeft: 10 }}>{!search.searchingForLocation && '#'}</th>
            <th>{search.searchingForLocation ? 'Location' : 'Name'}</th>
            <th style={{ textAlign: 'right' }}>{!search.searchingForLocation && 'Assets'}</th>
            <th style={{ textAlign: 'center' }}>{!search.searchingForLocation && 'Issues'}</th>
            <th style={{ textAlign: 'center' }}>{!search.searchingForLocation && 'Last Upload'}</th>
          </tr>
        </thead>
        <tbody>
          {!search.searchingForLocation && search.haveResults && search.results.length === 0 && (
            <tr>
              <td colSpan={5}>
                <div className='site-list-table-enter-text'>
                  <span className='material-icons'>turned_in_not</span>
                  <p>No results found</p>
                  <p
                    className='site-list-table-enter-text-location'
                    onClick={() => {
                      search.setSearchingForLocation(true)
                    }}
                  >
                    Search for a location instead?
                  </p>
                </div>
              </td>
            </tr>
          )}
          {search.searchingForLocation &&
            !search.busySearchingForLocation &&
            search.locationResults &&
            search.locationResults.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <div className='site-list-table-enter-text'>
                    <span className='material-icons'>turned_in_not</span>
                    <p>No results found</p>
                  </div>
                </td>
              </tr>
            )}
          {!search.searchingForLocation &&
            search.haveResults &&
            search.results.length > 0 &&
            search.results.map((r) => {
              return <SearchCard key={r.siteID} r={r} site={props.sites.find((x) => x.s.id === r.siteID)} />
            })}
          {search.searchingForLocation &&
            !search.busySearchingForLocation &&
            search.locationResults.length > 0 &&
            search.locationResults.map((r) => {
              return (
                <tr
                  className='site-list-table-row'
                  key={r.label}
                  onClick={() => {
                    map.flyTo(r.center[1], r.center[0], 100)
                  }}
                >
                  <td></td>
                  <td colSpan={4}>{r.label}</td>
                </tr>
              )
            })}
          {!search.haveResults ||
            (search.searchingForLocation && search.busySearchingForLocation && (
              <tr>
                <td colSpan={5}>
                  <div className='site-list-table-enter-text'>
                    <span className='material-icons'>{search.loading ? 'more_horiz' : 'travel_explore'}</span>
                    <p>&nbsp;</p>
                  </div>
                </td>
              </tr>
            ))}
          {search.search.length < 4 && props.sites.map((s) => {
            const lastUpload = s.extra?.lastUpload
            return (
              <tr className='site-list-table-row' key={s.s.id} onClick={() => handleTableOnClick(s.s)}>
                <td style={{ paddingLeft: 10 }} scope='row'>
                  {s.s.isDemoSite ? '-' : s.s.number}
                </td>
                <td>{s.s.name}</td>
                <td style={{ textAlign: 'right' }}>{s.s.assetCount}</td>
                <td style={{ textAlign: 'center' }}>
                  <IssueCounterDots
                    numHighIssues={s.s.highRiskIssues}
                    numMediumIssues={s.s.mediumRiskIssues}
                    numLowIssues={s.s.lowRiskIssues}
                    alwaysShowIssueCounts
                  />
                </td>
                <td style={{ textAlign: 'center' }} title={lastUpload ? fullDate(lastUpload) : ''}>
                  {timeSince(lastUpload)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

interface SearchCardProps {
  r: SearchResult
  site: SiteWithExtra
}

function SearchCard(props: SearchCardProps) {
  const { drawer, search } = useAppState()
  const location = useLocation()
  const s = props.site
  if (!s) {
    return <></>
  }
  const lastUpload = props.site.extra?.lastUpload

  return (
    <>
      <tr
        className='site-list-table-row'
        key={s.s.id}
        onClick={() => {
          drawer.toggleLeft()
          search.reset()
          location.setLocation('/' + props.r.siteID)
        }}
      >
        <td style={{ paddingLeft: 10 }} scope='row'>
          {s.s.number}
        </td>
        <td>{s.s.name}</td>
        <td style={{ textAlign: 'right' }}>{s.s.assetCount}</td>
        <td style={{ textAlign: 'center' }}>
          <IssueCounterDots
            numHighIssues={s.s.highRiskIssues}
            numMediumIssues={s.s.mediumRiskIssues}
            numLowIssues={s.s.lowRiskIssues}
            alwaysShowIssueCounts
          />
        </td>
        <td style={{ textAlign: 'center' }} title={lastUpload ? fullDate(lastUpload) : ''}>
          {timeSince(lastUpload)}
        </td>
      </tr>
      {props.r.assets.length > 0 && (
        <tr className='site-list-table-row'>
          <td colSpan={5}>
            <div className='site-list-table-row-assets'>
              {props.r.assets.map((a) => {
                return (
                  <div
                    key={a.assetID}
                    className='site-list-table-row-asset'
                    onClick={() => {
                      drawer.toggleLeft()
                      search.reset()
                      location.setLocation('/' + props.r.siteID + '/' + a.surveyID + '/assets/' + a.assetID)
                    }}
                  >
                    {a.assetName}
                  </div>
                )
              })}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
