import { useMemo, useState } from 'react'
import { Issue, SiteQuerySite, SiteQuerySurvey } from '~/models'
import { useImageTaggerState } from './use-image-tagger-state'

export function useIssues(
  imageTagger: ReturnType<typeof useImageTaggerState>,
  site: SiteQuerySite,
  activeSurvey: SiteQuerySurvey,
  assetID?: string,
  issueID?: string
) {
  const drawIssues = localStorage.getItem('asseti-issues-draw')
  const [issueToUpdate, setIssueToUpdate] = useState<Issue>()
  const [imageTaggerWasOpen, setImageTaggerWasOpen] = useState<boolean>(false)
  const [addingBulk, setAddingBulk] = useState<boolean>(false)
  const [movingBulk, setMovingBulk] = useState<boolean>(false)
  const [draw, setDraw] = useState<boolean>(drawIssues === 'true' || drawIssues === null)
  const [show, setShow] = useState<boolean>(true)
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])

  const { issuesToUse, issue } = useMemo(() => {
    if (!activeSurvey || !site) {
      return {
        issuesToUse: [],
        issue: undefined,
      }
    }

    const issues = assetID ? activeSurvey.issues.filter(x => x.assetID === assetID) : activeSurvey.issues
    let tmpIssues = [...((assetID ? issues.filter((i) => i.assetID === assetID) : issues) || [])]

    const issuesToUse = tmpIssues.sort((a, b) => {
      return a.number > b.number ? 1 : -1
    })
    return {
      issuesToUse,
      issue: issueID ? issues.find((i) => i.id === issueID) : undefined,
    }
  }, [site, activeSurvey, issueID, assetID])

  return {
    allIssues: issuesToUse,
    issues: filteredIssues.length > 0 ? filteredIssues : issuesToUse,
    issue,

    issueToUpdate,
    setIssueToUpdate: (issue: Issue) => {
      if (issue) {
        setImageTaggerWasOpen(!!imageTagger.photo)
        imageTagger.loadImageByHash(issue.imageHash).then(() => {
          setIssueToUpdate(issue)
          imageTagger.setUpdatingIssue(issue)
        })
      } else {
        if (!imageTaggerWasOpen) {
          imageTagger.hide()
        }
        setIssueToUpdate(undefined)
        imageTagger.setUpdatingIssue(undefined)
      }
    },
    addingBulk,
    setAddingBulk,
    movingBulk,
    setMovingBulk,
    show,
    setShow,
    draw,
    setDraw: (b: boolean) => {
      localStorage.setItem('asseti-issues-draw', b + '')
      setDraw(b)
    },
    filteredIssues,
    setFilteredIssues,
  }
}
