import {
  CreateSiteGroupMutation,
  CreateSiteGroupMutationVariables,
  UpdateSiteGroupMutation,
  UpdateSiteGroupMutationVariables,
  RemoveSiteGroupMutation,
  RemoveSiteGroupMutationVariables,
} from '~/models'
import { useMutation } from '~/components'

import SITE_GROUP_CREATE_MUTATION from './mutation-site-group-create.gql'
import SITE_GROUP_UPDATE_MUTATION from './mutation-site-group-update.gql'
import SITE_GROUP_REMOVE_MUTATION from './mutation-site-group-remove.gql'

export { SITE_GROUP_CREATE_MUTATION, SITE_GROUP_UPDATE_MUTATION, SITE_GROUP_REMOVE_MUTATION }

export function useSiteGroupCreateMutation() {
  return useMutation<CreateSiteGroupMutation, CreateSiteGroupMutationVariables>(SITE_GROUP_CREATE_MUTATION)
}

export function useSiteGroupUpdateMutation() {
  return useMutation<UpdateSiteGroupMutation, UpdateSiteGroupMutationVariables>(SITE_GROUP_UPDATE_MUTATION)
}

export function useSiteGroupRemoveMutation() {
  return useMutation<RemoveSiteGroupMutation, RemoveSiteGroupMutationVariables>(SITE_GROUP_REMOVE_MUTATION)
}
