import React, { useEffect } from 'react'
import { handleSimpleBarHeight, getImageURL, ActivityIndicator, classes, useLocation } from '~/components'
import { SiteQueryAsset, SiteQuerySite, SiteQuerySurvey } from '~/models'
import { Helmet, SimpleBar } from '~/components'
import { IssueItem, IssueItemWithPrevious, PhotoItem } from './types'
import { useIssuesFilmStripScrolling } from './issues-film-strip-use-scrolling'
import { useIssuesFilmStripFlyToImage } from './issues-film-strip-use-fly-to-image'
import { useIssuesFilmStripImagesByModel } from './issues-film-strip-load-images-by-model'

interface IssuesFilmStripProps {
  onClose: () => void
  selectedPhoto: PhotoItem
  selectedPhotoChanged: (p: PhotoItem) => void
  site: SiteQuerySite
  asset?: SiteQueryAsset
  target: 'site' | 'asset'
  issues: Array<IssueItem | IssueItemWithPrevious>
  setPhotos: (p: PhotoItem[]) => void
  selectedIssue?: IssueItemWithPrevious
  activeSurvey?: SiteQuerySurvey
}

export const IssuesFilmStripByModel = (props: IssuesFilmStripProps) => {
  const entity = props.target === 'site' ? props.site : props.asset
  const location = useLocation()

  const { photos, loading, error, reload, previousPhoto } = useIssuesFilmStripImagesByModel({
    issue: props.selectedIssue,
  })

  const { scrollNodeRef, numPrefix, numSuffix, inViewStartIndex, inViewEndIndex, setScroll } =
    useIssuesFilmStripScrolling({
      photos,
      selectedPhoto: props.selectedPhoto,
      activeSurvey: props.activeSurvey,
    })

  useIssuesFilmStripFlyToImage(previousPhoto)

  // Set parent photos and selected photo after images have loaded.
  useEffect(() => {
    if (!photos || photos.length === 0) {
      return
    }

    props.selectedPhotoChanged(photos[0])
    props.setPhotos(photos)
  }, [photos])

  // Keyboard to navigate photos.
  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'ArrowLeft') {
        if (props.selectedPhoto.index > 0) {
          const newIndex = props.selectedPhoto.index - 1
          props.selectedPhotoChanged(photos[newIndex])
          setTimeout(() => {
            scrollNodeRef.current.scrollTo({
              top: 206 * Math.max(0, newIndex) - 20,
            })
          }, 10)
        }
      } else if (e.key === 'ArrowRight') {
        if (props.selectedPhoto.index < photos.length - 1) {
          const newIndex = props.selectedPhoto.index + 1
          props.selectedPhotoChanged(photos[newIndex])
          setTimeout(() => {
            scrollNodeRef.current.scrollTo({
              top: 206 * Math.max(0, newIndex) - 20,
            })
          }, 10)
        }
      }
    }

    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [props.selectedPhoto])

  const photosToUse: PhotoItem[] = photos

  return (
    <div className='issues-film-strip drawer-panel'>
      <Helmet title='Issue Tagging' />
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          {props.target === 'asset' && (
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.onClose()
                  location.setLocation(`/${props.site.id}`)
                }}
              >
                {props.site.name}
              </a>
            </li>
          )}
          <li className='breadcrumb-item'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                props.onClose()
              }}
            >
              {entity?.name}
            </a>
          </li>
        </ol>
      </nav>
      <SimpleBar
        style={{ maxHeight: handleSimpleBarHeight(50) }}
        onScrollRaw={(e: any) => {
          setScroll({
            height: e.target.clientHeight,
            scroll: e.target.scrollTop,
          })
        }}
      >
        {error && (
          <div className='issues-film-strip-list'>
            <div className='issues-film-strip-list-error'>
              <p className='body2 mb-3'>
                An error occurred
                <br />
                while loading images.
              </p>
              <button className='btn btn-link' onClick={() => reload()}>
                Click to retry
              </button>
            </div>
          </div>
        )}
        {loading && (
          <div className='issues-film-strip-list'>
            <ActivityIndicator />
          </div>
        )}
        {!loading && (
          <div className='issues-film-strip-list'>
            {numPrefix > 0 && <span style={{ height: 206 * numPrefix + 'px' }}>&nbsp;</span>}
            {photosToUse.length > 0 &&
              photosToUse.slice(inViewStartIndex, inViewEndIndex).map((d, i) => (
                <div
                  key={d.imageID}
                  className={classes({
                    'issues-film-strip-list-item': true,
                    selected: d.imageID === props.selectedPhoto?.imageID,
                  })}
                  onClick={() => {
                    props.selectedPhotoChanged(d)
                  }}
                >
                  <img src={getImageURL(d, props.site.orgID, i + inViewStartIndex)} />
                  <div>
                    <div className='issues-film-strip-list-item-label'>
                      {i + inViewStartIndex + 1} of {photosToUse.length}
                    </div>
                    <div className='issues-film-strip-list-item-chip'>
                      {
                        props.issues.filter((x) => {
                          if ((x as IssueItemWithPrevious).currentIssue) {
                            return (x as IssueItemWithPrevious).currentIssue.imageID === d.imageID
                          }

                          return (x as IssueItem).imageID === d.imageID
                        }).length
                      }
                    </div>
                  </div>
                </div>
              ))}
            {photosToUse.length === 0 && !error && (
              <div className='issues-film-strip-empty-list'>No photos added yet.</div>
            )}
            {numSuffix > 0 && <span style={{ height: 206 * numSuffix + 'px' }}>&nbsp;</span>}
          </div>
        )}
      </SimpleBar>
    </div>
  )
}
