import React, { useEffect, useRef, useState } from 'react'
import { classes, shortDate } from '~/components'
import { SiteQuerySurvey } from '~/models'

interface SurveyDropdownProps {
  activeSurvey?: SiteQuerySurvey
  surveys: SiteQuerySurvey[]
  setActiveSurvey: (s: SiteQuerySurvey) => void
}

export function SurveyDropdown(props: SurveyDropdownProps) {
  const dropdownNode = useRef(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClickOutside = (e: Event) => {
    if (dropdownNode.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const activeName = props.activeSurvey
    ? props.activeSurvey.startDate === props.activeSurvey.endDate
      ? shortDate(props.activeSurvey.startDate)
      : shortDate(props.activeSurvey.startDate) + ' - ' + shortDate(props.activeSurvey.endDate)
    : ''

  return (
    <div
      style={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        height: '48px',
        paddingTop: '6px',
        color: '#a9a9a9',
        paddingLeft: '15px',
      }}
    >
      <div className='org-dropdown-content'>
        <div ref={dropdownNode} className='dropdown org-dropdown survey-dropdown'>
          <button
            className={`btn dropdown-toggle ${open ? 'active' : ''}`}
            type='button'
            id={`dropdownMenuButton-org`}
            data-toggle={`dropdown-org`}
            aria-haspopup='true'
            aria-expanded={open}
            onClick={() => setOpen(!open)}
          >
            {activeName}
          </button>
          <div
            className={classes({
              'dropdown-menu': true,
            })}
            aria-labelledby={`dropdownMenuButton-orgs`}
            style={{ display: open ? 'block' : 'none' }}
          >
            {props.surveys.map((s) => {
              const name =
                s.startDate === s.endDate
                  ? shortDate(s.startDate)
                  : shortDate(s.startDate) + ' - ' + shortDate(s.endDate)
              return (
                <a className='dropdown-item hover' key={s.id} onClick={() => {
                  setOpen(false)
                  if (s === props.activeSurvey) {
                    return
                  }
                  props.setActiveSurvey(s)
                }}>
                  <div className='dropdown-item-container'>
                    {name}
                    <div style={{ textTransform: 'none' }}>{s.issues.length} issues</div>
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
