import { useMutation, useQuery } from '~/components'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import AUTH_STATUS_QUERY from '~/auth/query-auth-status.gql'
import { useUser } from '~/base'
import { Avatar, Input, getTracking, Switch } from '~/components'
import {
  AuthStatusQuery,
  CsvExportQuery,
  CsvExportQueryVariables,
  CsvExportType,
  orgRoleToString,
  UserSetRequire2FaMutation,
  UserSetRequire2FaMutationVariables,
} from '~/models'
import { SetShowDemoDashboardsModal } from '~/sites/set-show-demo-dashboards-modal'
import { SetShowDemoModal } from '~/sites/set-show-demo-modal'
import CSV_EXPORT_QUERY from './query-csv-export.gql'
import { useSites } from '~/state/use-sites'
import SET_REQUIRE_2FA from './mutation-set-require-2fa.gql'

enum ProfileViewStateType {
  Profile = 'profile',
  Settings = 'settings',
  Exports = 'exports',
}

export const Profile = () => {
  const user = useUser()
  const [profileView, setView] = useState<any>(ProfileViewStateType.Profile)
  const userFullName = `${user.firstName} ${user.lastName}`
  const [showHideDemoSites, setShowHideDemoSites] = useState(false)
  const [showHideDemoDashboards, setShowHideDemoDashboards] = useState(false)
  const [exportingComponentCounts, setExportingComponentCounts] = useState(false)
  const [exportingHierarchy, setExportingHierarchy] = useState(false)
  const [exportingIssueList, setExportingIssuesList] = useState(false)
  const [exportingBoundaries, setExportingBoundaries] = useState(false)
  const [requires2FA, setRequires2FA] = useState(user.requires2FA)
  const sites = useSites()

  const csvExportQuery = useQuery<CsvExportQuery, CsvExportQueryVariables>(CSV_EXPORT_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const authStatusQuery = useQuery<AuthStatusQuery>(AUTH_STATUS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })
  const [executeRequires2FA] = useMutation<UserSetRequire2FaMutation, UserSetRequire2FaMutationVariables>(
    SET_REQUIRE_2FA
  )

  const haveDemo = (sites?.data?.sites || []).findIndex((s) => s.isDemoSite) !== -1

  const generateComponentCountsCSV = () => {
    setExportingComponentCounts(true)
    csvExportQuery
      .refetch({
        input: {
          type: CsvExportType.ComponentCounts,
        },
      })
      .then((res) => {
        const link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.csvExport))
        link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-component-counts.csv`)
        document.body.appendChild(link)
        document.getElementById('download-csv').click()
        document.body.removeChild(link)
      })
      .finally(() => {
        setExportingComponentCounts(false)
      })
  }

  const generateHierarchyCSV = () => {
    setExportingHierarchy(true)
    csvExportQuery
      .refetch({
        input: {
          type: CsvExportType.Hierarchy,
        },
      })
      .then((res) => {
        const link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.csvExport))
        link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-hierarchy.csv`)
        document.body.appendChild(link)
        document.getElementById('download-csv').click()
        document.body.removeChild(link)
      })
      .finally(() => {
        setExportingHierarchy(false)
      })
  }

  const generateIssuesListCSV = () => {
    setExportingIssuesList(true)
    csvExportQuery
      .refetch({
        input: {
          type: CsvExportType.IssueList,
        },
      })
      .then((res) => {
        const link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.csvExport))
        link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-issues.csv`)
        document.body.appendChild(link)
        document.getElementById('download-csv').click()
        document.body.removeChild(link)
      })
      .finally(() => {
        setExportingIssuesList(false)
      })
  }
  
  const generateBoundariesCSV = () => {
    setExportingBoundaries(true)
    csvExportQuery
      .refetch({
        input: {
          type: CsvExportType.Boundaries,
        },
      })
      .then((res) => {
        const link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.csvExport))
        link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-boundaries.csv`)
        document.body.appendChild(link)
        document.getElementById('download-csv').click()
        document.body.removeChild(link)
      })
      .finally(() => {
        setExportingBoundaries(false)
      })
  }

  return (
    <>
      <ul className='profile-tabs'>
        <li
          className={`profile-tabs-item ${profileView === ProfileViewStateType.Profile ? 'active' : ''}`}
          onClick={() => setView(ProfileViewStateType.Profile)}
        >
          Profile
        </li>
        <li
          className={`profile-tabs-item ${profileView === ProfileViewStateType.Settings ? 'active' : ''}`}
          onClick={() => setView(ProfileViewStateType.Settings)}
        >
          Settings
        </li>
        <li
          className={`profile-tabs-item ${profileView === ProfileViewStateType.Exports ? 'active' : ''}`}
          onClick={() => setView(ProfileViewStateType.Exports)}
        >
          Exports
        </li>
      </ul>
      <div className='profile-tab-content'>
        {profileView === ProfileViewStateType.Profile && (
          <div className='profile'>
            <div className='profile-container'>
              <Avatar title={`${userFullName}`} width={120} height={120} className='profile-container-avatar' />
              <div className='profile-container-inner-container'>
                <h5 className='profile-container-inner-container-title'>{`${userFullName}`}</h5>
              </div>
            </div>
            <form autoComplete='off' className='profile-form'>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='firstName'>First Name</label>
                  <Input id='firstName' label='First Name' value={user.firstName} readonly />
                </div>
                <div className='col'>
                  <label htmlFor='lastName'>Last Name</label>
                  <Input id='lastName' label='Last Name' value={user.lastName} readonly />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='email'>Email</label>
                  <Input id='email' label='Email' value={user.email} readonly />
                </div>
                <div className='col'>
                  <label htmlFor='orgRole'>Role</label>
                  <Input id='orgRole' label='Role' value={orgRoleToString(user.org.role)} readonly />
                </div>
              </div>
            </form>

            <div>
              <br />
              <Switch
                name='force-2fa'
                label='Require 2FA for my account'
                checked={requires2FA || user.orgs.findIndex((o) => o.requires2FA) !== -1}
                disabled={user.orgs.findIndex((o) => o.requires2FA) !== -1}
                title={
                  user.orgs.findIndex((o) => o.requires2FA) !== -1
                    ? 'One of the Organizations you are part of requires 2FA'
                    : undefined
                }
                onChange={() => {
                  executeRequires2FA({
                    variables: {
                      input: {
                        requires2FA: !requires2FA,
                      },
                    },
                  }).then(() => {
                    return authStatusQuery
                      .refetch()
                      .then((res) => {
                        user.setSignIn(res.data.authStatus)
                        setRequires2FA(!requires2FA)
                      })
                      .catch(() => {
                        window.location.pathname = '/signed-out'
                      })
                  })
                }}
              />
            </div>
            <br />
            <button
              className='profile-form-btn btn'
              onClick={() => {
                user.logout(() => {
                  window.location.pathname = '/signin'
                })
                getTracking().event({
                  category: 'Button',
                  action: `User clicked sign out button`,
                  label: 'Profile',
                })
              }}
            >
              Sign out
            </button>
          </div>
        )}
        {profileView === ProfileViewStateType.Exports && (
          <div className='profile'>
            <div className='profile-container'>
              <Avatar title={`${userFullName}`} width={120} height={120} className='profile-container-avatar' />
              <div className='profile-container-inner-container'>
                <h5 className='profile-container-inner-container-title'>{`${userFullName}`}</h5>
              </div>
            </div>
            <button
              className='profile-form-btn-secondary btn'
              onClick={generateComponentCountsCSV}
              disabled={exportingComponentCounts}
            >
              {!exportingComponentCounts ? 'Export' : 'Exporting'} Component Counts
            </button>
            <br />
            <button
              className='profile-form-btn-secondary btn'
              onClick={generateHierarchyCSV}
              disabled={exportingHierarchy}
            >
              {!exportingHierarchy ? 'Export' : 'Exporting'} Hierarchy
            </button>
            <br />
            <button
              className='profile-form-btn-secondary btn'
              onClick={generateIssuesListCSV}
              disabled={exportingIssueList}
            >
              {!exportingIssueList ? 'Export' : 'Exporting'} Issues
            </button>
            <br />
            <button
              className='profile-form-btn-secondary btn'
              onClick={generateBoundariesCSV}
              disabled={exportingBoundaries}
            >
              {!exportingBoundaries ? 'Export' : 'Exporting'} Boundaries
            </button>
          </div>
        )}
        {profileView === ProfileViewStateType.Settings && (
          <div className='profile'>
            <div className='profile-container'>
              <Avatar title={`${userFullName}`} width={120} height={120} className='profile-container-avatar' />
              <div className='profile-container-inner-container'>
                <h5 className='profile-container-inner-container-title'>{`${userFullName}`}</h5>
              </div>
            </div>
            <button className='profile-form-btn btn' onClick={() => setShowHideDemoSites(true)}>
              {!haveDemo ? 'Enable' : 'Disable'} demo sites
            </button>
            <br />
            <button className='profile-form-btn btn' onClick={() => setShowHideDemoDashboards(true)}>
              {!user.showDemoDashboards ? 'Enable' : 'Disable'} example dashboards
            </button>
          </div>
        )}
        {showHideDemoSites && (
          <SetShowDemoModal
            mustEnable={!haveDemo}
            onCancel={() => setShowHideDemoSites(false)}
            onComplete={sites.refetch}
          />
        )}
        {showHideDemoDashboards && (
          <SetShowDemoDashboardsModal
            mustEnable={!user.showDemoDashboards}
            onCancel={() => setShowHideDemoDashboards(false)}
            onComplete={() => Promise.resolve()}
          />
        )}
      </div>
    </>
  )
}
