import React from 'react'
import { formatCurrencyFull } from '../helpers'
import { InstantAssessmentSummaryAllSitesQuery, MeasurementSystem } from '~/models'
import { formatArea2 } from '~/reports/ia'
import { componentColorFromPercent } from '~/sites/site-components'
import { useUser } from 'base'

type Summary = InstantAssessmentSummaryAllSitesQuery['instantAssessmentSummaryAllSites']
type SiteSummary = Summary['sites'][0]
type AssetSummary = SiteSummary['assets'][0]
type UnionSummary = Summary | SiteSummary | AssetSummary

interface AnalyticsPanelPortfolioSnapshotProps {
  summary: InstantAssessmentSummaryAllSitesQuery['instantAssessmentSummaryAllSites']
  siteName?: string
  assetName?: string
}

export const AnalyticsPanelPortfolioSnapshot = (props: AnalyticsPanelPortfolioSnapshotProps) => {
  const siteSummary = props.siteName && props.summary.sites.find((s) => s.siteName === props.siteName)
  const assetSummary = props.assetName && siteSummary.assets.find((a) => a.assetName === props.assetName)
  const user = useUser()

  let summaryToUse: UnionSummary = props.summary
  let numSites = props.summary.numSitesTotal
  let numAssets = props.summary.numAssetsTotal
  let title = 'Portfolio Snapshot'
  if (siteSummary) {
    summaryToUse = siteSummary
    numSites = 1
    numAssets = siteSummary.numAssets
    title = 'Site Condition Breakdown'
    if (assetSummary) {
      summaryToUse = assetSummary
      title = 'Asset Condition Breakdown'
      numAssets = 1
    }
  }

  const conditionScore = summaryToUse.conditionPercent
  const conditionString = summaryToUse.conditionString
  const area = summaryToUse.areaSqm
  const replacementCost = summaryToUse.replacementCost

  const score = Math.floor(conditionScore)
  const color = componentColorFromPercent(score)

  return (
    <>
      <div className='analytics-panel analytics-panel-portfolio' style={{ paddingBottom: '0.6rem' }}>
        <div className='analytics-panel-portfolio-title'>{title}</div>
        <div className='analytics-panel-portfolio-score-title'>Asseti Score</div>
        <div className='analytics-panel-portfolio-score-container'>
          <svg className='analytics-panel-portfolio-score-border'>
            <circle cx='50%' cy='50%' r='50%' pathLength='100'></circle>
            <circle
              cx='50%'
              cy='50%'
              r='50%'
              pathLength='100'
              style={{
                strokeDashoffset: 100 - score,
                stroke: color,
              }}
            ></circle>
          </svg>
          <div className='analytics-panel-portfolio-score'>
            <div className='analytics-panel-portfolio-score-spacer'></div>
            <div className='analytics-panel-portfolio-score-value'>{Math.floor(conditionScore)}%</div>
            <div className='analytics-panel-portfolio-score-condition'>{conditionString}</div>
            <div className='analytics-panel-portfolio-score-spacer'></div>
          </div>
        </div>
      </div>

      <div className='analytics-panel' style={{ marginBottom: 0, paddingBottom: 0 }}>
        <div className='analytics-panel-container'>
          <div className='analytics-panel-container-inner'>
            <div className='analytics-panel-container-inner-container-data-oval'>{numSites}</div>
            <div className='analytics-panel-container-inner-container-title' style={{ textTransform: 'unset' }}>
              Sites
            </div>
          </div>
          <div className='analytics-panel-container-inner'>
            <div className='analytics-panel-container-inner-container-data-oval'>{numAssets}</div>
            <div className='analytics-panel-container-inner-container-title' style={{ textTransform: 'unset' }}>
              Assets
            </div>
          </div>
          <div className='analytics-panel-container-inner'>
            <div className='analytics-panel-container-inner-container-data-oval'>
              {formatArea2(area, 2, user.org.measurementSystem)}
            </div>
            <div className='analytics-panel-container-inner-container-title' style={{ textTransform: 'unset' }}>
              Area ({user.org.measurementSystem === MeasurementSystem.Imperial ? 'sqft' : 'sqm'})
            </div>
          </div>
        </div>
      </div>

      <div className='analytics-panel'>
        <div className='analytics-panel-container' style={{ width: '100%' }}>
          <div className='analytics-panel-container-inner' style={{ width: '100%' }}>
            <div className='analytics-panel-container-inner-container-data-oval'>
              {formatCurrencyFull(replacementCost).replace('M', 'm')}
            </div>
            <div className='analytics-panel-container-inner-container-title' style={{ textTransform: 'unset' }}>
              Total Replacement Cost ({user.org.measurementSystem === MeasurementSystem.Imperial ? 'USD' : 'AUD'})
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
