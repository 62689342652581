import React from 'react'
import './priority-header-tag.scss'

type Props = {
  priority: 'low' | 'medium' | 'high'
  children: React.ReactNode
}

export const PriorityHeaderTag = ({ priority, children }: Props) => {
  return <div className={`ui-priority-header ui-priority-header-${priority}`}>{children}</div>
}
