import React, { useState } from 'react'
import { useAppState } from '~/state'
import { Analysis } from '~/models'
import { useAnalysisReprocess } from './analysis-data'

interface AnalysisReprocessProps {
  analysis: Analysis
  refetch: () => Promise<void>
  close: () => void
}

export const AnalysisReprocess = (props: AnalysisReprocessProps) => {
  const { timeline, view } = useAppState()
  const [executing, setExecuting] = useState(false)
  const [reprocess] = useAnalysisReprocess()

  return (
    <div className='boundaries-draw'>
      <div
        className='item-tag-delete-confirm'
        style={{ backgroundColor: 'rgb(43, 39, 40)', justifyContent: 'unset', paddingTop: '2rem' }}
      >
        <h6>Reprocess Analysis?</h6>
        <button
          className='item-tag-delete-confirm-delete btn'
          disabled={executing}
          onClick={() => {
            setExecuting(true)
            reprocess({
              variables: {
                input: {
                  id: props.analysis.id,
                  surveyID: timeline.activeSurvey.id,
                  siteID: view.siteID,
                },
              },
            })
              .then(() => {
                return props.refetch().then(() => {
                  props.close()
                })
              })
              .finally(() => {
                setExecuting(false)
              })
          }}
        >
          {executing ? 'Reprocessing...' : 'Yes, reprocess'}
        </button>
        <button className='item-tag-delete-confirm-cancel btn' disabled={executing} onClick={props.close}>
          Cancel
        </button>
      </div>
      <div></div>
    </div>
  )
}
