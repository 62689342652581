import React from 'react'

interface ToolsIconProps {
  setShowTools: () => void
}

export const ToolsIcon = (props: ToolsIconProps) => {
  return (
    <i className='tools-icon material-icons' onClick={props.setShowTools}>
      settings
    </i>
  )
}
