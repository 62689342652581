import React from 'react'
import { handleNumberFormat } from '../helpers'

interface AnalyticsPanelIssuesProps {
  reported: number
  resolved: number
}

export const AnalyticsPanelIssues = (props: AnalyticsPanelIssuesProps) => {
  return (
    <div className='analytics-panel'>
      <div className='analytics-panel-title'>
        <span>REPORTED</span> VS <span>RESOLVED</span>
      </div>
      <div className='analytics-panel-container flex-column' style={{marginTop: '8px'}}>
        <div className='analytics-panel-container-inner-container-data-bar'>
          <div className='analytics-panel-container-inner-container-data-bar-bar reported'></div>
          <div className='analytics-panel-container-inner-container-data-bar-text'>
            {handleNumberFormat(props.reported)}
          </div>
        </div>
        <div className='analytics-panel-container-inner-container-data-bar'>
          <div
            className='analytics-panel-container-inner-container-data-bar-bar resolved'
            style={{ flex: props.reported === 0 ? 1 : Math.max(0.01, props.resolved / props.reported) }}
          ></div>
          <div className='analytics-panel-container-inner-container-data-bar-text'>
            {handleNumberFormat(props.resolved)}
          </div>
          <div className='analytics-panel-container-inner-container-data-bar-bar spacer'></div>
        </div>
      </div>
    </div>
  )
}
