import React, { useMemo, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { classes } from '../../helpers/classes'
import { FloatingInput } from '../floating-input/floating-input'

export interface Select2Item {
  id: string
}

export interface Select2Option<T extends Select2Item> {
  name: string
  id: string
  value: T
  items: Select2Option<T>[]
}

export interface Select2Props<T extends Select2Item> {
  options: Array<Select2Option<T>>
  selectedValues?: T[]
  onChange: (selectedValue: T, tree: T[]) => void
  id: string
  label?: string
  placeholder?: string
  helperText?: string
  small?: boolean
  tiny?: boolean
  canSelectGroup?: boolean
  canSelectNone?: boolean
  disabled?: boolean
}

export function Select2<T extends Select2Item = Select2Item>(props: Select2Props<T>) {
  const [focused, setFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [highlightIndex, setHighlightIndex] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()

  const none = {
    name: 'None',
    id: '',
    value: '',
    items: [],
  } as any as Select2Option<T>

  const setSelected = (selected: Select2Option<T>, items: Select2Option<T>[]) => {
    props.onChange(selected.value, items.map(x => x.value))
    setFocused(false)
  }

  const valueToUse = useMemo(() => {
    if (focused || props.selectedValues.length === 0) {
      return searchValue
    }

    function getValueToUse(item: Select2Option<T>, prefixes: string[]): string {
      if (item.id === props.selectedValues[props.selectedValues.length - 1].id) {
        return prefixes.join(' - ') + ' - ' + item.name
      }

      if (item.items.length > 0 && props.selectedValues.findIndex(x => x.id === item.id) !== -1) {
        return item.items.map((o) => getValueToUse(o, [...prefixes, item.name])).join('')
      }

      return ''
    }

    return props.options.map((o) => getValueToUse(o, [])).join('')
  }, [props.options, searchValue, props.selectedValues, focused])

  const optionsToRender = useMemo(() => {
    const lowerCaseSearchValue = searchValue.toLocaleLowerCase()
    const options: Select2Option<T>[] = props.canSelectNone ? [none, ...props.options] : props.options
    if (lowerCaseSearchValue === '') {
      return options
    }

    function getOptionsFromSearch(item: Select2Option<T>): Select2Option<T> | undefined {
      if (item.items.length == 0) {
        if (item.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
          return item
        } else {
          return undefined
        }
      }

      if (item.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
        return item
      }

      const newItems = item.items.map((i) => getOptionsFromSearch(i)).filter((x) => !!x)
      if (newItems.length === 0) {
        return undefined
      }

      return {
        ...item,
        items: newItems,
      }
    }

    return options.map((o) => getOptionsFromSearch(o)).filter((x) => !!x)
  }, [props.options, searchValue, focused])

  const close = () => {
    setFocused(false)
    setSearchValue('')
    setHighlightIndex(0)
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  let renderIndex = 0

  function renderItem(item: Select2Option<T>, level: number, items: Select2Option<T>[]): any {
    let extraToRender = []
    if (item.items.length > 0) {
      for (const i of item.items) {
        extraToRender.push(renderItem(i, level + 1, [...items, item]))
      }
    }

    const selectable = item.items.length > 0 ? props.canSelectGroup : true

    return (
      <>
        <div
          key={item.id}
          className={classes({
            'asseti-select2-item': true,
            selectable,
          })}
          style={{ paddingLeft: `${level}rem` }}
          onClick={() => {
            if (selectable) {
              setSelected(item, items)
            }
          }}
        >
          {level !== 0 && <i className='material-icons sub-dir'>subdirectory_arrow_right</i>}
          {level === 0 && <span style={{ display: 'inline-block', paddingLeft: '0.5rem' }}></span>}
          {item.name}
        </div>
        {...extraToRender}
      </>
    )
  }

  return (
    <div
      className={classes({
        'asseti-select2': true,
        'small-select': props.small,
        tiny: props.tiny,
      })}
      onFocus={() => {
        if (props.disabled) {
          return
        }
        setFocused(true)
        inputRef.current.focus()
      }}
      ref={containerRef}
    >
      <FloatingInput
        disabled={props.disabled}
        label={props.label}
        // placeholder={props.placeholder}
        id={props.id}
        value={valueToUse}
        inputRef={inputRef}
        onChange={(e) => {
          setSearchValue(e.target.value || '')
        }}
        onFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onLabelFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onBlur={() => {
          setTimeout(() => {
            close()
          }, 150)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            setHighlightIndex(Math.min(highlightIndex + 1, renderIndex - 1))
          } else if (e.key === 'ArrowUp') {
            setHighlightIndex(Math.max(highlightIndex - 1, 0))
          } else if (e.key === 'Enter') {
            const elements = document.getElementsByClassName('asseti-select2-highlighted')
            if (elements.length > 0) {
              const div = elements[0] as HTMLDivElement
              div.click()
              close()
            }
          } else if (e.key === 'Escape') {
            close()
          }
        }}
        helperText={props.helperText}
      />
      {focused && (
        <div className='asseti-select2-options-container'>
          <SimpleBar style={{ maxHeight: '310px' }}>
            {optionsToRender.map((o) => {
              return renderItem(o, 0, [])
            })}
          </SimpleBar>
        </div>
      )}
    </div>
  )
}
