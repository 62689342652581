import React, { useEffect, useState } from 'react'
import { Helmet, useToasts } from '~/components'
import { Boundaries, Components, FilmStrip, MonitoringZones, Photos } from '~/components'
import {
  BoundaryRenderState,
  BoundaryRenderType,
  MonitoringZoneRenderState,
  MonitoringZoneRenderType,
  useAppState,
  useRefState,
} from '~/state'
import { expandRect } from '~/state/utils'
import { Photo, SiteQuerySite } from '~/models'
import { IssuesDrawerList } from '~/issues'
import { AssetError } from './asset-error'
import { AssetInfo } from './asset-info'
import { AssetNav } from './asset-nav'
import { AssetUpdate } from './asset-update'
import { DrawerList } from '~/components/drawer/drawer-list'
import { AnnotationsDrawerList } from '~/annotations'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { AnalysisDrawerList } from '~/analysis/analysis-list-drawer'
import { useUser } from '~/base'
import { SiteComponents } from '~/sites/site-components'

interface AssetProps {
  site?: SiteQuerySite
  refetch: () => Promise<void>
}

export const Asset = (props: AssetProps) => {
  const site = props.site
  const { view, boundaryState, monitoringZoneState, map, imageTagger, asset, drawer } = useAppState()
  const toasts = useToasts()
  const user = useUser()

  const [showUpdateAsset, setShowUpdateAsset] = useState<boolean>(false)
  const [photos, setPhotos] = useRefState<Photo[]>([])
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  useEffect(() => {
    boundaryState.setRenderState(BoundaryRenderState.SiteAndAssets)
    boundaryState.setRenderType(BoundaryRenderType.Lines)
    monitoringZoneState.setRenderState(MonitoringZoneRenderState.SiteAndAssets)
    monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)

    return () => {
      boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
      monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
    }
  }, [])

  useEffect(() => {
    if (!props.site || !asset.asset || firstLoadComplete) {
      return
    }
    const points: Cesium.Cartographic[] = []

    for (const boundary of asset.asset.boundaries) {
      for (const p of boundary.points) {
        points.push(
          Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
        )
      }
    }

    if (points.length > 0) {
      const rect = expandRect(Cesium.Rectangle.fromCartographicArray(points))
      map.jumpTo(rect)
    }
    setFirstLoadComplete(true)
  }, [asset.asset, props.site, firstLoadComplete])

  const refetch = (): Promise<any> => {
    return props.refetch()
  }

  if (asset.error) {
    return <AssetError />
  }

  if (showUpdateAsset) {
    return (
      <AssetUpdate
        asset={asset.asset}
        onComplete={(reload) => {
          if (reload) {
            refetch()
              .then(() => {
                toasts.addTopLeft('Asset Updated.')
              })
              .finally(() => {
                setShowUpdateAsset(false)
              })
          } else {
            setShowUpdateAsset(false)
          }
        }}
      />
    )
  }

  const isAI = user.isInstantAssessmentOrg
  

  return (
    <>
      <Helmet title={asset.asset && asset.asset.name} />
      <div className='site-panel' style={{ paddingBottom: '0.1rem' }}>
        <AssetNav />
        <AssetInfo
          refetch={props.refetch}
          updateAsset={() => {
            setShowUpdateAsset(true)
          }}
        />
      </div>
      <DrawerList noPadding>
        <div className='site-panel'>
          {isAI && <SiteComponents />}
          {!isAI && <Components site={site} asset={asset.asset} refetchAsset={refetch} />}
        </div>
        {!isAI && (
          <div className='site-panel'>
            <IssuesDrawerList target='asset' site={site} refetch={props.refetch} />
          </div>
        )}
        {!isAI && (
          <div className='site-panel'>
            <Photos type='asset' site={site} asset={asset.asset} assetId={view.assetID} photos={photos} />
          </div>
        )}

        {!isAI && (
          <div className='site-panel' style={{ paddingTop: '1rem', paddingBottom: '0.25rem' }}>
            <AnnotationsDrawerList />
            <AnalysisDrawerList />
            <Boundaries refetch={refetch} />
            <MonitoringZones refetch={refetch} />
          </div>
        )}
      </DrawerList>
      {drawer.expandedPanel === ExpandedPanel.Photos && (
        <FilmStrip
          site={props.site}
          asset={asset.asset}
          photos={photos}
          addToData={(photos) => {
            setPhotos(photos)
          }}
          dataLoaded={(photos, reload) => {
            setPhotos(photos)
            if (imageTagger.photo) {
              const selectedId = imageTagger.photo.imageID
              const newPhoto = photos.find((p) => p.imageID === selectedId)
              if (newPhoto) {
                imageTagger.setPhoto(newPhoto)
              }
            }

            imageTagger.setChangedCallback(() => reload)
          }}
          onHide={() => {
            boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
            monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
            drawer.closeExpandedPanel()
          }}
        />
      )}
      <div id='site-left-drawer-portal'></div>
    </>
  )
}
