fragment ActivityItemForSurveyItems on ActivityItemForSurveyItemT {
  id
  name
  time
  userName
}

query ActivityStreamForSurvey($input: QuerySurveyActivityStreamRequestT!) {
  activityStreamForSurvey(input: $input) {
    items {
      data {
        type
        value {
          ... on ActivityItemForSurveySiteCreatedT {
            time
            siteName
          }
          ... on ActivityItemForSurveySiteUpdatedT {
            time
            siteName
          }
          ... on ActivityItemForSurveyBoundariesCreatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyBoundariesUpdatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyBoundariesRemovedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyMonitoringZonesCreatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyMonitoringZonesUpdatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyMonitoringZonesRemovedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAnnotationsCreatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAnnotationsUpdatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAnnotationsRemovedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAssetsCreatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAssetsUpdatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyAssetsRemovedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyIssuesCreatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyIssuesUpdatedT { items { ...ActivityItemForSurveyItems } }
          ... on ActivityItemForSurveyIssuesRemovedT { items { ...ActivityItemForSurveyItems } }
        }
      }
    }
    total
  }
}
