import React from 'react'
import { classes } from '../helpers'

export enum DrawerState {
  Normal,
  Expanded,
  Hidden,
}

export enum DrawerPosition {
  Left,
  Right,
}

interface DrawerProps {
  children?: any
  state: DrawerState
  position: DrawerPosition
  transparent?: boolean
  className?: string
}

export const Drawer = (props: DrawerProps) => {
  return (
    <div
      className={classes({
        drawer: true,
        expanded: props.state === DrawerState.Expanded,
        hidden: props.state === DrawerState.Hidden,
        left: props.position === DrawerPosition.Left,
        right: props.position === DrawerPosition.Right,
        transparent: props.transparent,
        [props.className]: !!props.className,
      })}
    >
      {props.children}
    </div>
  )
}
