import { SignInResponseT } from '~/models'

interface EventInput {
  category: string // Typically the object that was interacted with (e.g. 'Form')
  action: string // The type of interaction (e.g. 'User removed a site')
  label: string // Useful for categorizing events (e.g. 'Site')
}

function trackEvent<T extends EventInput = EventInput>(props: T) {
  const data = JSON.parse(localStorage.getItem('asseti_auth') || '{}') as SignInResponseT
  const orgs = data?.orgs || []
  const org = orgs.find((x) => x.id === data.currentOrgId)

  // Pendo Tracking here.
  if (window.pendo) {
    try {
      window.pendo.track(props.label, {
        ...props,
        id: data?.userId || '',
        name: `${data?.firstName || ''} ${data?.lastName || ''}` || '',
        email: data?.email || '',
        systemRole: data?.systemRole || '',
        orgName: org?.name || '',
        orgRole: org?.role || '',
        requiresSubscription: org?.requiresSubscription === true,
        canManageAdvancedAccess: org?.canManageAdvancedAccess === true,
        orgRequires2FA: org?.requires2FA === true,
      })
    } catch {}
  }
}

export function getTracking() {
  return {
    event: trackEvent,
  }
}
