import React, { useEffect } from 'react'
import { useAppState } from '~/state'
import { Timeline } from './timeline'
import { TimelineSatellite } from './timeline-satellite'
import { TimelineLidar } from './timeline-lidar'

export const TimelineContainer = () => {
  const { map, timeline } = useAppState()

  const haveRecons =
    timeline.surveys.length > 0 && timeline.surveys.findIndex((s) => s.reconstructions.length > 0) !== -1

    useEffect(() => {

      if(!haveRecons && timeline.satelliteTimeline.length > 0 && !map.showSatellite) {
        map.setShowSatellite(true)
      }

    }, [timeline.surveys, timeline.satelliteTimeline])

  return (
    <>
      {!map.showLidar && haveRecons && <Timeline />}
      {!map.showLidar && map.showSatellite && timeline.visible && <TimelineSatellite haveTimeline={haveRecons} />}
      {map.showLidar && timeline.visible && <TimelineLidar />}
    </>
  )
}
