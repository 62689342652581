export function formatNumber(n: number) {
  // Million
  if (n >= 1000000) {
    return Math.round((n / (1000000)) * 100) / 100 + 'M'
  }

  // Thousand
  if (n > 1000) {
    return Math.round((n / (1000)) * 100) / 100 + 'k'
  }

  // Other
  return n
}