import React, { useEffect, useState } from 'react'
import {
  EpsgCodesQuery,
  EpsgCodesQueryVariables,
  ExportBoundaryDxfMutation,
  ExportBoundaryDxfMutationVariables,
  SiteQueryBoundary,
} from '~/models'
import { useMutation, useQuery } from '../data'
import QUERY_EPSG from './query-epsg.gql'
import MUTATION_EXPORT_DXF from './mutation-boundary-export-dxf.gql'
import { Select } from '../forms'
import { useAppState } from '~/state'
import { useToasts } from '../toast'

interface EPSG {
  id: string
  code: number
  description: string
}

interface BoundaryDownloadDXFModalProps {
  boundary: SiteQueryBoundary
  complete: () => void
  cancel: () => void
}

export const BoundaryDownloadDXFModal = (props: BoundaryDownloadDXFModalProps) => {
  const { view, site } = useAppState()
  const toasts = useToasts()
  const query = useQuery<EpsgCodesQuery, EpsgCodesQueryVariables>(QUERY_EPSG)
  const [exportDXFMutation] = useMutation<ExportBoundaryDxfMutation, ExportBoundaryDxfMutationVariables>(
    MUTATION_EXPORT_DXF
  )

  const [epsg, setEpsg] = useState<EPSG[]>()
  const [selectedDatum, setSelectedDatum] = useState<EPSG>()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!Array.isArray(query?.data?.epsgCodes)) {
      return
    }

    setEpsg(
      query.data.epsgCodes.map((e) => {
        return {
          id: e.code + '',
          code: e.code,
          description: e.description,
        }
      })
    )
  }, [query?.data])

  return (
    <div className='boundaries-draw'>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.cancel()
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Boundary Marker
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>Download DXF</h6>
        </div>
        <form
          autoComplete='off'
          className='drawer-panel-form-container'
          onSubmit={(e) => {
            e.preventDefault()
            setSubmitting(true)
            exportDXFMutation({
              variables: {
                input: {
                  siteID: view.siteID,
                  surveyID: view.surveyID,
                  assetID: view.assetID || '',
                  boundaryID: props.boundary.id,
                  epsgCode: selectedDatum.code,
                },
              },
            })
              .then((res) => {
                const link = document.createElement('a')
                link.id = 'download-dxf'
                link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.exportBoundaryDXF))
                link.setAttribute('download', `${site.site.name}-${props.boundary.name}.dxf`)
                document.body.appendChild(link)
                document.getElementById('download-dxf').click()
                document.body.removeChild(link)
              })
              .catch(() => {
                toasts.addTopLeft('Failed to generate the DXF file')
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
        >
          <Select<EPSG>
            id='epsg'
            label='Datum'
            placeholder='Select datum'
            onChange={(selected) => {
              setSelectedDatum(selected)
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedDatum}
            options={
              epsg
                ? epsg.map((e) => {
                    return {
                      id: e.id,
                      name: e.description,
                      value: e,
                      items: [],
                    }
                  })
                : []
            }
          />
          <div className='drawer-panel-form-container-action-container'>
            <button type='submit' className='btn submit mb-2' disabled={!epsg}>
              {submitting ? 'Downloading...' : 'Download'}
            </button>
            <button className='btn cancel' onClick={() => props.cancel()}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div></div>
    </div>
  )
}
