import React, { useEffect, useState } from 'react'
import { ReportPage } from '../layout'
import {
  Currency,
  InstantAssessmentSummaryQuery,
  InstantAssessmentSummaryQueryVariables,
  MeasurementSystem,
} from '~/models'
import { classes, formatCurrency, useQuery } from '~/components'
import LOGO from '../../images/reports/ia/asseti-logo.png'
import HERO from '../../images/reports/ia/hero.png'
import BUTTON_WHITE from '../../images/reports/ia/arrow-white.png'
import BUTTON_RED from '../../images/reports/ia/arrow-red.png'
import IA_SUMMARY_QUERY from './query-ia-summary.gql'

type Summary = InstantAssessmentSummaryQuery['instantAssessmentSummary']
type Site = Summary['sites'][0]
type Component = Summary['components'][0]

export const IAReport = () => {
  const [loaded, setLoaded] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [data, setData] = useState<InstantAssessmentSummaryQuery['instantAssessmentSummary']>()
  const query = useQuery<InstantAssessmentSummaryQuery, InstantAssessmentSummaryQueryVariables>(IA_SUMMARY_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const urlParams = new URLSearchParams(window.location.search)
  const measurementSystem = urlParams.get('m') as MeasurementSystem

  useEffect(() => {
    if (!measurementSystem) {
      ;(window as any).renderError = true
      return
    }

    query
      .refetch({
        input: {
          measurementSystem: measurementSystem,
        },
      })
      .then((res) => {
        setData(res.data.instantAssessmentSummary)
        setLoaded(true)
      })
      .catch(() => {
        ;(window as any).renderError = true
      })
  }, [measurementSystem])

  useEffect(() => {
    if (!loaded) {
      return
    }

    setTimeout(() => {
      setRendered(true)
    }, 1000)
  }, [loaded])

  useEffect(() => {
    if (!rendered) {
      return
    }

    ;(window as any).finishedIAReport = true
  }, [rendered])

  if (!loaded) {
    return <div>Loading...</div>
  }

  console.log(data.currency)

  return (
    <ReportPage className='ia-report'>
      <Heading orgName={data.orgName} />
      <Snapshot
        sites={data.numSites}
        totalSites={data.numSitesTotal}
        assets={data.numAssets}
        area={data.areaSqm}
        measurementSystem={measurementSystem}
        replacementCost={data.replacementCost}
        currency={data.currency as Currency}
        conditionScore={data.conditionPercent}
        conditionString={data.conditionString}
      />
      <Sites
        sites={data.sites}
        measurementSystem={measurementSystem}
        additionalSites={data.numSitesTotal - data.numSites}
        currency={data.currency as Currency}
      />
      <Components
        components={data.components}
        measurementSystem={measurementSystem}
        currency={data.currency as Currency}
      />
      <Footer />
    </ReportPage>
  )
}

interface HeadingProps {
  orgName: string
}

const Heading = (props: HeadingProps) => {
  return (
    <div className='ia-report-heading'>
      <div className='ia-report-heading-logo'>
        <img src={LOGO} />
      </div>
      <div
        className={classes({
          'ia-report-heading-title': true,
          smaller: props.orgName.length > 26,
        })}
      >
        {props.orgName}
      </div>
      <div className='ia-report-heading-ia'>INSTANT ASSESSMENT</div>
    </div>
  )
}

interface SnapshotProps {
  sites: number
  totalSites: number
  assets: number
  area: number
  measurementSystem: MeasurementSystem
  replacementCost: number
  currency: Currency
  conditionScore: number
  conditionString: string
}

const Snapshot = (props: SnapshotProps) => {
  const deltaSites = props.totalSites - props.sites
  const sitesMore = deltaSites > 0 ? `+${deltaSites} more` : undefined
  const areaUnit = props.measurementSystem === MeasurementSystem.Metric ? 'sqm' : 'sqft'

  const percent = props.conditionScore
  let backgroundColor = '#FF0000'
  if (percent > 80) {
    backgroundColor = '#00BF63'
  } else if (percent >= 60) {
    backgroundColor = '#FFBD59'
  } else if (percent >= 40) {
    backgroundColor = '#FF914D'
  } else if (percent >= 20) {
    backgroundColor = '#FF0000'
  }

  return (
    <div className='ia-report-section ia-report-snapshot'>
      <div className='ia-report-section-heading ia-report-snapshot-heading'>PORTFOLIO SNAPSHOT</div>
      <div className='ia-report-section-middle'>
        <div className='ia-report-section-middle-content'>
          <SnapshotItem title='Sites' value={props.sites} more={sitesMore} />
          <SnapshotItem title='Assets' value={props.assets} />
          <SnapshotItem title={`Area (${areaUnit})`} value={formatArea2(props.area, 2, props.measurementSystem)} />
          <SnapshotItem
            title={`Replacement Cost (${props.currency})`}
            value={formatCurrency(props.replacementCost, 2, props.currency).replace('M', 'm')}
          />
        </div>
        <div className='ia-report-section-middle-spacer'></div>
      </div>
      <div className='ia-report-snapshot-bottom'>
        <a href='https://www.asseti.co/book-a-demo'>
          CLAIM YOUR SITES <img src={BUTTON_WHITE} />
        </a>
      </div>
      <svg className='ia-report-snapshot-score-border'>
        <circle
          cx='50%'
          cy='50%'
          r='50%'
          pathLength='100'
          style={{
            strokeDashoffset: 100 - percent,
            stroke: backgroundColor,
          }}
        ></circle>
      </svg>
      <div className='ia-report-snapshot-score'>
        <div className='ia-report-snapshot-score-spacer'></div>
        <div className='ia-report-snapshot-score-title'>
          ASSETI
          <br />
          SCORE
        </div>
        <div className='ia-report-snapshot-score-value'>{Math.round(props.conditionScore)}%</div>
        <div className='ia-report-snapshot-score-condition'>{props.conditionString.replace('Average', 'Avg')}</div>
        <div className='ia-report-snapshot-score-spacer'></div>
      </div>
    </div>
  )
}

interface SnapshotItemProps {
  title: string
  value: string | number
  more?: string
}

const SnapshotItem = (props: SnapshotItemProps) => {
  return (
    <div className='ia-report-snapshot-item'>
      <div className='ia-report-snapshot-item-heading'>{props.title}</div>
      <div className='ia-report-snapshot-item-value'>{props.value}</div>
      {props.more && (
        <div
          className={classes({
            'ia-report-snapshot-item-more': true,
            'ia-report-snapshot-item-more-small': props.more.length > 8,
          })}
        >
          {props.more}
        </div>
      )}
    </div>
  )
}

interface SitesProps {
  sites: Site[]
  measurementSystem: MeasurementSystem
  additionalSites: number
  currency: Currency
}

const Sites = (props: SitesProps) => {
  return (
    <div className='ia-report-section ia-report-sites'>
      <div className='ia-report-section-heading ia-report-sites-heading'>SITE CONDITION BREAKDOWN</div>
      <div className='ia-report-section-middle'>
        <div className='ia-report-section-middle-content no-flex'>
          <table>
            <thead>
              <tr>
                <th>Site</th>
                <th className='center'>Assets</th>
                <th className='center'>
                  Area
                  <br />({props.measurementSystem === MeasurementSystem.Imperial ? 'sqft' : 'sqm'})
                </th>
                <th className='center'>
                  Replacement
                  <br />
                  cost ({props.currency})
                </th>
                <th>Condition</th>
                <th>Remaining useful life</th>
              </tr>
            </thead>
            <tbody>
              {props.sites.map((s) => {
                const replacementCost = s.replacementCost
                return (
                  <tr key={s.siteName}>
                    <td>{s.siteName}</td>
                    <td className='center'>{s.numAssets}</td>
                    <td className='center'>{formatArea(Math.ceil(s.areaSqm), true, props.measurementSystem)}</td>
                    <td className='center'>
                      {formatCurrency(
                        Math.ceil(replacementCost),
                        replacementCost >= 100000000 ? 2 : 0,
                        props.measurementSystem === MeasurementSystem.Metric ? Currency.Aud : Currency.Usd
                      ).replace('M', 'm')}
                    </td>
                    <td>{s.conditionString.replace('Average', 'Avg')}</td>
                    <td>
                      <ProgressBar percent={Math.floor(s.conditionPercent)} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {props.additionalSites > 0 && (
            <div className='ia-report-sites-more'>+{props.additionalSites} additional sites detected</div>
          )}
        </div>
      </div>
    </div>
  )
}

interface ComponentsProps {
  components: Component[]
  measurementSystem: MeasurementSystem
  currency: Currency
}

const Components = (props: ComponentsProps) => {
  return (
    <div className='ia-report-section ia-report-sites'>
      <div className='ia-report-section-heading ia-report-sites-heading'>COMPONENT BREAKDOWN SAMPLE</div>
      <div className='ia-report-section-middle'>
        <div className='ia-report-section-middle-content no-flex'>
          <table>
            <thead>
              <tr>
                <th>Component</th>
                <th className='center'>QTY</th>
                <th className='center'>Material cost</th>
                <th>Condition</th>
                <th>Remaining useful life</th>
              </tr>
            </thead>
            <tbody>
              {props.components.map((s) => {
                const materialCosts = s.materialCost
                return (
                  <tr key={s.componentName}>
                    <td>{s.componentName}</td>
                    <td className='center' style={{ minWidth: '70px' }}>
                      {formatQuantity(s.quantity, 0)}{' '}
                      {/* {s.unit === 'ea' ? '' : s.unit === 'sqm' ? 'm²' : s.unit === 'sqft' ? 'ft²' : s.unit} */}
                      {s.unit === 'ea' ? '' : s.unit}
                    </td>
                    <td className='center'>
                      {formatCurrency(
                        Math.ceil(materialCosts),
                        materialCosts >= 100000000 ? 2 : 0,
                        props.measurementSystem === MeasurementSystem.Metric ? Currency.Aud : Currency.Usd
                      ).replace('M', 'm')}
                    </td>
                    <td>{s.conditionString.replace('Average', 'Avg')}</td>
                    <td>
                      <ProgressBar percent={Math.floor(s.conditionPercent)} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='ia-report-sites-more'>+100s of additional component types available</div>
        </div>
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <div className='ia-report-section ia-report-footer'>
      <div className='ia-report-footer-top'>
        <div className='ia-report-footer-left'>
          <div className='ia-report-footer-left-title'>
            POWER UP YOUR ASSET MANAGEMENT
            <br />
            WORKFLOW WITH ASSETI
          </div>
          <div className='ia-report-footer-left-text'>
            Asseti builds and manages your asset registry on autopilot. We
            <br />
            deliver actionable asset condition data and insights for your asset portfolio.{' '}
            <i>No more spreadsheets. No more manual inspections.</i>
          </div>
          <a className='ia-report-footer-left-link' href='https://www.asseti.co/book-a-demo'>
            BOOK A DEMO <img src={BUTTON_RED} />
          </a>
        </div>
        <div className='ia-report-footer-right'>
          <img src={HERO} />
        </div>
      </div>
      <div className='ia-report-footer-bottom'>
        <a href='https://www.asseti.co'>www.asseti.co</a>
      </div>
    </div>
  )
}

interface ProgressBarProps {
  percent: number
}

const ProgressBar = (props: ProgressBarProps) => {
  const percent = Math.floor(props.percent)
  let backgroundColor = '#FF0000'
  if (percent > 80) {
    backgroundColor = '#00BF63'
  } else if (percent >= 60) {
    backgroundColor = '#FFBD59'
  } else if (percent >= 40) {
    backgroundColor = '#FF914D'
  } else if (percent >= 20) {
    backgroundColor = '#FF0000'
  }

  return (
    <div className='ia-report-progress-bar'>
      <div className='ia-report-progress-bar-left'>
        <div className='ia-report-progress-bar-background'></div>
        <div
          className='ia-report-progress-bar-foreground'
          style={{
            backgroundColor,
            width: (props.percent / 100) * 150 + 'px',
          }}
        ></div>
      </div>
      <div className='ia-report-progress-bar-right'>{props.percent}%</div>
    </div>
  )
}

function formatArea(squareMeters: number, commas: boolean, measurementSystem: MeasurementSystem) {
  if (measurementSystem === MeasurementSystem.Imperial) {
    const squareFeet = squareMeters * 3.280839895 * 3.280839895

    if (commas) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notation: 'standard',
        compactDisplay: 'short',
      })
        .format(Math.round(squareFeet))
        .replace('$', '')
        .replace('K', 'k')
        .replace('.00', '')
    }

    return squareFeet.toFixed(2) + 'ft²'
  }

  if (commas) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'standard',
      compactDisplay: 'short',
    })
      .format(Math.round(squareMeters))
      .replace('$', '')
      .replace('K', 'k')
      .replace('.00', '')
  }

  if (squareMeters >= 1000) {
    return Math.round(squareMeters) + 'm²'
  }
  return squareMeters.toFixed(2) + 'm²'
}

export function formatArea2(sqm: number, fractionDigits: number, measurementSystem: MeasurementSystem) {
  const base: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }
  let toUse = sqm
  if (measurementSystem === MeasurementSystem.Imperial) {
    toUse = sqm * 3.280839895 * 3.280839895
  }

  return new Intl.NumberFormat('en-US', base)
    .format(Math.round(toUse))
    .replace('$', '')
    .replace('K', 'k')
    .replace('.00', '')
}

function formatQuantity(qty: number, fractionDigits: number) {
  const base: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    notation: 'standard',
    compactDisplay: 'short',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }

  return new Intl.NumberFormat('en-US', base)
    .format(Math.round(qty))
    .replace('$', '')
    .replace('K', 'k')
    .replace('.00', '')
}
