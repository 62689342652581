export function getPolygonBounds(points: number[][]) {
  const bbox = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY]
  return points.reduce(function (prev, coord) {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.max(coord[0], prev[2]),
      Math.max(coord[1], prev[3]),
    ]
  }, bbox)
}

export function getPolygonBounds3D(points: number[][]) {
  const bbox = [
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
  ]
  return points.reduce(function (prev, coord) {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.min(coord[2], prev[2]),
      Math.max(coord[0], prev[3]),
      Math.max(coord[1], prev[4]),
      Math.max(coord[2], prev[5]),
    ]
  }, bbox)
}

export function extendPolygonBounds(points: number[], amountLng = 40, amountLat = 20) {
  return [points[0] - amountLng, points[1] - amountLat, points[2] + amountLng, points[3] + amountLat]
}

export function getPolygonCenter(points: number[][]) {
  const bounds = getPolygonBounds(points)
  return [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2]
}

export function getPolygonCenter3D(points: number[][]) {
  const bounds = getPolygonBounds3D(points)
  return [(bounds[0] + bounds[3]) / 2, (bounds[1] + bounds[4]) / 2, (bounds[2] + bounds[5]) / 2]
}

export function getPolygonCenter3DMaxHeight(points: number[][]) {
  const bounds = getPolygonBounds3D(points)
  return [(bounds[0] + bounds[3]) / 2, (bounds[1] + bounds[4]) / 2, Math.max(bounds[2], bounds[5])]
}

export function getPolygonLongestLineCenter3DMaxHeight(points: number[][]) {
  let longestPoints: Cesium.Cartesian3[]
  let longestLength = 0
  
  if(points.length === 1) {
    return Cesium.Cartesian3.fromDegrees(points[0][0], points[0][1], points[0][2])
  }

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1]
    const curPoint = points[i]


    const prev = Cesium.Cartesian3.fromDegrees(prevPoint[0], prevPoint[1], prevPoint[2])
    const cur = Cesium.Cartesian3.fromDegrees(curPoint[0], curPoint[1], curPoint[2])
    const length = Cesium.Cartesian3.distanceSquared(prev, cur)
    if(length > longestLength) {
      longestLength = length
      longestPoints = [prev, cur]
    }

  }
  const geodesic = new Cesium.EllipsoidGeodesic()
  return getMidpoint(geodesic, longestPoints[0], longestPoints[1])
}

export function getMidpoint(
  geodesic: Cesium.EllipsoidGeodesic,
  point1: Cesium.Cartesian3,
  point2: Cesium.Cartesian3
) {
  const c1 = Cesium.Cartographic.fromCartesian(point1)
  const c2 = Cesium.Cartographic.fromCartesian(point2)
  geodesic.setEndPoints(c1, c2)
  const midpointCartographic = geodesic.interpolateUsingFraction(0.5, new Cesium.Cartographic())
  return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, (c1.height + c2.height) / 2)
}
