export function expandRect(rect: Cesium.Rectangle, minimumSize = 0, padding = 0.2) {
  const sw = Cesium.Rectangle.southwest(rect)
  const ne = Cesium.Rectangle.northeast(rect)
  let width = Cesium.Rectangle.computeWidth(rect)
  let height = Cesium.Rectangle.computeHeight(rect)
  const swCarto = Cesium.Cartographic.fromRadians(sw.longitude, sw.latitude)
  const neCarto = Cesium.Cartographic.fromRadians(ne.longitude, ne.latitude)

  if (width > Cesium.Math.PI_OVER_TWO || height > Cesium.Math.PI_OVER_FOUR) {
    width *= padding
    height *= padding

    if(neCarto.longitude + width > Cesium.Math.PI) {
      width -= Cesium.Math.PI
    }

    return Cesium.Rectangle.fromCartographicArray([
      new Cesium.Cartographic(neCarto.longitude + width, neCarto.latitude + height),
      new Cesium.Cartographic(swCarto.longitude - width, swCarto.latitude - height),
    ])
  } else {
    width *= padding
    height *= padding

    if(minimumSize !== 0) {
      width = Math.max(width, 0.002)
      height = Math.max(height, 0.002)
    }
  }

  return Cesium.Rectangle.fromCartographicArray([
    new Cesium.Cartographic(neCarto.longitude + width, neCarto.latitude + height),
    new Cesium.Cartographic(swCarto.longitude - width, swCarto.latitude - height),
  ])
}

export function expandRectWithExtraLeft(rect: Cesium.Rectangle, minimumSize = 0, padding = 0.2) {
  const sw = Cesium.Rectangle.southwest(rect)
  const ne = Cesium.Rectangle.northeast(rect)
  let width = Cesium.Rectangle.computeWidth(rect)
  let height = Cesium.Rectangle.computeHeight(rect)
  const swCarto = Cesium.Cartographic.fromRadians(sw.longitude, sw.latitude)
  const neCarto = Cesium.Cartographic.fromRadians(ne.longitude, ne.latitude)

  if (width > Cesium.Math.PI_OVER_TWO || height > Cesium.Math.PI_OVER_FOUR) {
    width *= padding
    height *= padding

    if(neCarto.longitude + width > Cesium.Math.PI) {
      width -= Cesium.Math.PI
    }

    return Cesium.Rectangle.fromCartographicArray([
      new Cesium.Cartographic(neCarto.longitude + width, neCarto.latitude + height),
      new Cesium.Cartographic(swCarto.longitude - width, swCarto.latitude - height),
    ])
  } else {
    width *= padding
    height *= padding

    if(minimumSize !== 0) {
      width = Math.max(width, 0.002)
      height = Math.max(height, 0.002)
    }
  }

  return Cesium.Rectangle.fromCartographicArray([
    new Cesium.Cartographic(neCarto.longitude + width, neCarto.latitude + height),
    new Cesium.Cartographic(swCarto.longitude - width*2, swCarto.latitude - height),
  ])
}