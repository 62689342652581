import React from 'react'
import { AnnotationsFormCreateProps } from './annotations-form-create-props'
import { AnnotationRulerVolume } from './rulers'

export const AnnotationsFormCreateVolume = (props: AnnotationsFormCreateProps) => {
  return (
    <AnnotationRulerVolume
      pointsChanged={(points, measurements) => {
        props.setModel({
          ...props.model,
          points: points.map((p) => ({
            longitude: Cesium.Math.toDegrees(p.longitude),
            latitude: Cesium.Math.toDegrees(p.latitude),
            altitude: p.height,
          })),
          measurements,
        })
      }}
      points={props.model.points.map((p) => Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, p.altitude))}
      annotations={props.model.measurements}
    />
  )
}
