import { ActivityIndicator, Avatar, getTracking, useMutation, useToasts } from '~/components'
import { InviteMutation, MutationInviteArgs, SitesQuerySiteGroupWithExtra, SitesQuerySiteWithExtra } from '~/models'
import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { ClientPermission, useUser } from '~/base'
import { string } from 'yup'
import MUTATION_INVITE from './mutation-invite.gql'
import { useAppState } from '~/state'
import { SiteAccessTable } from './site-access-table'
import SimpleBar from 'simplebar-react'
import { GroupAccessTable } from './group-access-table'

interface TeamInviteModalProps {
  inviteModal: boolean
  close: () => void
  reload: () => void
  addingTeamLoading: boolean
  changeTeamLoading: (change?: boolean) => void
}

enum InviteModalViewType {
  ADD_EMAILS,
  CONFIRM_EMAILS,
  CONFIRMED,
}

export const TeamInviteModal = (props: TeamInviteModalProps) => {
  const user = useUser()
  const toasts = useToasts()
  const { sites } = useAppState()
  const [step, setStep] = useState<InviteModalViewType>(InviteModalViewType.ADD_EMAILS)
  const [emails, setEmails] = useState([])
  const [execute] = useMutation<InviteMutation, MutationInviteArgs>(MUTATION_INVITE)
  const currentOrg = user.orgs.find((o) => o.id === user.org.id)
  const canManage = user.hasPermission(ClientPermission.ManageOrg) && currentOrg.canManageAdvancedAccess
  const allSites = useMemo(() => sites.sortSites(sites.sites.filter((s) => !s.s.isDemoSite)), [])
  const [excludedSites, setExcludedSites] = useState<SitesQuerySiteWithExtra[]>(allSites)
  const [includedSites, setIncludedSites] = useState<SitesQuerySiteWithExtra[]>([])
  const [excludedGroups, setExcludedGroups] = useState<SitesQuerySiteGroupWithExtra[]>(sites.groups)
  const [includedGroups, setIncludedGroups] = useState<SitesQuerySiteGroupWithExtra[]>([])

  const handleEmailChange = (emails: string) => {
    const v = string().email().max(255).required()
    let emailArray = emails.split('\n').map((e) => e.trim())
    if (emailArray.length === 1) {
      if (emails.indexOf('@') != emails.lastIndexOf('@')) {
        emailArray = emails.split(' ').map((e) => e.trim())
      }
    }

    setEmails(emailArray.filter((e) => v.isValidSync(e)))
  }

  useEffect(() => {
    setStep(InviteModalViewType.ADD_EMAILS)
  }, [])

  const executeInvite = () => {
    props.changeTeamLoading(true)
    execute({
      variables: {
        input: {
          users: emails.map((e) => ({
            email: e,
            whitelistedSiteIDs: includedSites.map((x) => x.s.id),
            whitelistedGroupIDs: includedGroups.map(g => g.group.id),
          })),
          orgId: user.org.id,
        },
      },
    })
      .then(() => {
        getTracking().event({
          category: 'Team',
          action: `User invited team member`,
          label: 'Team',
        })
        props.reload()
        setStep(InviteModalViewType.CONFIRMED)
      })
      .catch(() => {
        toasts.addTopLeft('An error occurred while inviting team members')
      })
  }

  const closeTeamModal = () => {
    setEmails([])
    setStep(InviteModalViewType.ADD_EMAILS)
    props.close()
  }

  return createPortal(
    <div className='modal team-invite-modal' tabIndex={-1} style={{ display: props.inviteModal ? 'block' : 'none' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            {step === 1 && (
              <div
                className='back'
                aria-label='Back'
                onClick={() => {
                  setStep(InviteModalViewType.ADD_EMAILS)
                  setEmails([])
                }}
              >
                <span className='material-icons'>keyboard_backspace</span>
              </div>
            )}
            <div
              className='close'
              aria-label='Close'
              onClick={() => {
                props.close()
                setEmails([])
                setStep(InviteModalViewType.ADD_EMAILS)
              }}
            >
              <span className='material-icons'>close</span>
            </div>
          </div>
          <div className='modal-body'>
            {props.addingTeamLoading ? (
              <ActivityIndicator />
            ) : (
              <>
                {step === InviteModalViewType.ADD_EMAILS && (
                  <>
                    <h5>Invite people to Asseti</h5>
                    <form>
                      <textarea
                        placeholder={'name@asseti.co' + '\n' + 'name2@asseti.co'}
                        onChange={(e) => handleEmailChange(e.target.value)}
                      />
                    </form>
                    <button
                      type='button'
                      className='modal-btn btn'
                      onClick={() => setStep(InviteModalViewType.CONFIRM_EMAILS)}
                      disabled={emails.length == 0 ? true : false}
                    >
                      Next
                    </button>
                  </>
                )}
                {step === InviteModalViewType.CONFIRM_EMAILS && (
                  <>
                    <h5>
                      Invite {emails.length} {emails.length === 1 ? 'person' : 'people'} to Asseti
                    </h5>
                    <ul className='team-invite-list'>
                      <SimpleBar style={{ maxHeight: '180px' }}>
                        {emails.map((e) => (
                          <li className='team-invite-list-item' key={e}>
                            <Avatar className='team-invite-list-avatar' title='Avatar' width={48} height={48} />
                            <div className='team-invite-list-item-email'>{e}</div>
                            <div className='team-invite-list-item-role'>
                              Member
                              <div>Edit access only</div>
                            </div>
                          </li>
                        ))}
                      </SimpleBar>
                    </ul>

                    {canManage && (
                      <div className='team-invite-access-container'>
                        {sites.groups.length > 0 && (
                          <div className='team-invite-access-left'>
                            <GroupAccessTable
                              height={400}
                              includedGroups={includedGroups}
                              setIncludedGroups={setIncludedGroups}
                              excludedGroups={excludedGroups}
                              setExcludedGroups={setExcludedGroups}
                            />
                          </div>
                        )}
                        <div className='team-invite-access-right'>
                          <SiteAccessTable
                            height={400}
                            includedSites={includedSites}
                            setIncludedSites={setIncludedSites}
                            excludedSites={excludedSites}
                            setExcludedSites={setExcludedSites}
                          />
                        </div>
                      </div>
                    )}

                    <button type='button' className='modal-btn btn' onClick={executeInvite}>
                      {props.addingTeamLoading ? 'Loading...' : 'Invite'}
                    </button>
                  </>
                )}
                {step === InviteModalViewType.CONFIRMED && (
                  <>
                    <h5>Successfully invited {emails.length} people to Asseti</h5>
                    <button type='button' className='modal-btn btn' onClick={closeTeamModal}>
                      Ok
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  ) as JSX.Element
}
