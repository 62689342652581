import { useEffect, useState } from 'react'
import { useUser } from '~/base'
import { useQuery } from '~/components'
import { AnalysisResultQuery, AnalysisResultQueryVariables, Analysis } from '~/models'
import ANALYSIS_QUERY from './query-analysis.gql'
import { Config } from '~/config'

export function useAnalysis(surveyID?: string, siteID?: string, assetID?: string) {
  const [selectedAnalyses, setSelectedAnalyses] = useState<Analysis[]>([])
  const user = useUser()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectedHeightIndex, setSelectedHeightIndex] = useState(1)
  const [analysis, setAnalysis] = useState<AnalysisResultQuery['analysisResult']['items']>([])

  const query = useQuery<AnalysisResultQuery, AnalysisResultQueryVariables>(ANALYSIS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  const reload = () => {
    if (!user.org.canAccessDeflections || !Config.UseAnalysis) {
      return Promise.resolve()
    }
    setLoading(true)
    return query
      .refetch({
        input: {
          siteID: siteID,
          surveyID: surveyID,
        },
      })
      .then((res) => {
        const items = [...res.data.analysisResult.items].sort((a, b) => {
          return a.updatedAt < b.updatedAt ? 1 : -1
        })
        if (assetID) {
          setAnalysis(items.filter((m) => m.assetID === assetID))
        } else {
          setAnalysis(items)
        }
        setError(false)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!surveyID) {
      return
    }

    reload()
  }, [surveyID, assetID])

  return {
    analysis,
    loading,
    error,
    reload,
    selectedAnalyses,
    setSelectedAnalyses,
    selectedHeightIndex,
    setSelectedHeightIndex,
  }
}
