import React from 'react'

interface AuthButtonProps {
  children?: any
  disabled?: boolean
  onClick?: () => void
  form?: string
  style?: any
}

export const AuthButton = (props: AuthButtonProps) => {
  return (
    <button
      className='btn btn-auth mt-4 mb-4'
      type='submit'
      form={props.form}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </button>
  )
}
