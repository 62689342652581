export function formatBytes(bytes: number) {
  // GB
  if (bytes > 1024 * 1024 * 1024) {
    return Math.round((bytes / (1024 * 1024 * 1024)) * 100) / 100 + 'GB'
  }

  // MB
  if (bytes > 1024 * 1024) {
    return Math.round((bytes / (1024 * 1024)) * 100) / 100 + 'MB'
  }

  // KB
  return Math.round((bytes / 1024) * 100) / 100 + 'KB'
}