import { PickFromPositionResult } from '../measure/utils'

/**
 * Pick an entity from a 2D window position.
 * @param viewer Cesium.Viewer to use for picking.
 * @param windowPos The window position to use for picking.
 * @param objectsToExclude A list of objects to exclude for picking.
 * @returns A picked entity, if available.
 */
export function pickFromPosition(
  viewer: Cesium.Viewer,
  windowPos: Cesium.Cartesian2,
  objectsToExclude: any[],
  rayWidth = 0.01
): PickFromPositionResult {
  // Pick a position from the depth buffer.
  let cartesian = viewer.scene.pickPosition(windowPos)
  if (!cartesian) {
    return {
      onModel: false,
    }
  }

  let onModel = false
  let model = undefined

  // Pick again using an array which takes into account excluded entities.
  const ray = viewer.camera.getPickRay(windowPos)
  const pickedFromScene = viewer.scene.pickFromRay(ray, objectsToExclude, rayWidth)

  if (pickedFromScene && pickedFromScene.position) {
    // Potential pick from an included entity.
    // cartesian = pickedFromScene.position
    if (pickedFromScene.object) {
      onModel = true
      model = pickedFromScene.object
    }
  } else {
    // Potentially pick from terrain.
    const picked = viewer.scene.globe.pick(ray, viewer.scene)
    if (picked) {
      cartesian = picked
    }
  }

  return {
    cartesian,
    onModel,
    model,
  }
}

const scratchPickRay = new Cesium.Ray()

export function pickFromPositionAll(
  viewer: Cesium.Viewer,
  windowPos: Cesium.Cartesian2,
  objectsToExclude: any[],
  rayWidth = 0.01
): PickFromPositionResult[] {
  // Get a pick raw from the camera and window coordinates.
  viewer.camera.getPickRay(windowPos, scratchPickRay)

  // Pick again using an array which takes into account excluded entities.
  const allPickedFromScene = viewer.scene.drillPickFromRay(scratchPickRay, 1, objectsToExclude, rayWidth) as Array<{
    position: Cesium.Cartesian3
    object: object
  }>
  if (!Array.isArray(allPickedFromScene)) {
    return []
  }

  return allPickedFromScene.map((pickedFromScene) => {
    if (pickedFromScene.object !== undefined) {
      return {
        onModel: !!pickedFromScene.position,
        model: pickedFromScene.object,
        cartesian: pickedFromScene.position,
      }
    }
  }).filter(x => !!x)
}
