import React from 'react'
import { useLocation, getTracking } from '~/components'
import {  } from '~/state'

export const SiteNotFound = () => {
  const location = useLocation()
  getTracking().event({
    category: 'Error',
    action: `User site not found`,
    label: 'Site',
  })

  return (
    <div className='site-not-found'>
      Site not found
      <button className='btn mt-3' onClick={() => location.setLocation('/')}>
        Back
      </button>
    </div>
  )
}
