import React, { useEffect, useRef, useState } from 'react'
import { classes, Measure, TimelineContainer } from '~/components'
import { useAppState, useCesium } from '~/state'
import { ImageTagger } from './image-tagger-new'
import { MapControls } from './map-controls'
import { CesiumCompass } from '~/components/compass/compass'
import { CesiumCrashedDialog } from './map-crash-dialog'
import { EntityContext } from './entity-context'
import { useUser } from '~/base'
import { Config } from '~/config'

enum KeyboardAction {
  CAMERA_FORWARD = 1,
  CAMERA_BACKWARD,
  CAMERA_RIGHT,
  CAMERA_LEFT,
  CAMERA_UP,
  CAMERA_DOWN,
  CAMERA_LOOK_RIGHT,
  CAMERA_LOOK_LEFT,
  CAMERA_LOOK_UP,
  CAMERA_LOOK_DOWN,
  CAMERA_TWIST_RIGHT,
  CAMERA_TWIST_LEFT,
  CAMERA_ROTATE_RIGHT,
  CAMERA_ROTATE_LEFT,
  CAMERA_ROTATE_UP,
  CAMERA_ROTATE_DOWN,
}

const CAMERA_MOVEMENT_DEFAULT_FACTOR = 150.0
const CAMERA_LOOK_DEFAULT_FACTOR = 0.2
const CAMERA_TWIST_DEFAULT_FACTOR = 0.01
const CAMERA_ROTATE_DEFAULT_FACTOR = 0.314159 / 8
const MIN_MOVE_AMOUNT = 0.05
const MAX_MOVE_AMOUNT = 3000

type Map = ReturnType<typeof useCesium>

function getHeight(map: Map): number {
  if (map.viewer.scene.mode === Cesium.SceneMode.SCENE3D) {
    const sampledHeight = map.viewer.scene.sampleHeight(map.viewer.camera.positionCartographic.clone(), [], 0.5)
    if (!sampledHeight) {
      return 40
    }
    return map.viewer.camera.positionCartographic.height - sampledHeight
  }

  return map.viewer.camera.positionCartographic.height
}

const PREDEFINED_KEYBOARD_ACTIONS: { [key: number]: (map: Map, delta: number, ctrl: boolean) => void } = {
  /**
   * Moves the camera forward, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_FORWARD]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    // const cameraHeight = getHeight(map)
    // const moveRate = Math.abs(cameraHeight) / CAMERA_MOVEMENT_DEFAULT_FACTOR
    // camera.moveForward(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))

    const cameraHeight = map.viewer.scene.globe.ellipsoid.cartesianToCartographic(camera.position).height
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveForward(moveRate)
  },
  /**
   * Moves the camera backward, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_BACKWARD]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    // const cameraHeight = getHeight(map)
    // const moveRate = Math.abs(cameraHeight) / CAMERA_MOVEMENT_DEFAULT_FACTOR
    // camera.moveBackward(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))

    const cameraHeight = map.viewer.scene.globe.ellipsoid.cartesianToCartographic(camera.position).height
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveBackward(moveRate)
  },
  /**
   * Moves the camera up, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_UP]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    const cameraHeight = getHeight(map)
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveUp(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))
  },
  /**
   * Moves the camera down, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_DOWN]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    const cameraHeight = getHeight(map)
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveDown(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))
  },
  /**
   * Moves the camera right, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_RIGHT]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    // const cameraHeight = getHeight(map)
    // const moveRate = Math.abs(cameraHeight) / CAMERA_MOVEMENT_DEFAULT_FACTOR
    // camera.moveRight(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))

    const cameraHeight = map.viewer.scene.globe.ellipsoid.cartesianToCartographic(camera.position).height
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveRight(moveRate)
  },
  /**
   * Moves the camera left, accepts a numeric parameter named `moveRate` that controls
   * the factor of movement, according to the camera height.
   */
  [KeyboardAction.CAMERA_LEFT]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    // const cameraHeight = getHeight(map)
    // const moveRate = Math.abs(cameraHeight) / CAMERA_MOVEMENT_DEFAULT_FACTOR
    // camera.moveLeft(Math.min(Math.max(moveRate, MIN_MOVE_AMOUNT), MAX_MOVE_AMOUNT))

    const cameraHeight = map.viewer.scene.globe.ellipsoid.cartesianToCartographic(camera.position).height
    const moveRate = cameraHeight / CAMERA_MOVEMENT_DEFAULT_FACTOR
    camera.moveLeft(moveRate)
  },
  /**
   * Changes the camera to look to the right, accepts a numeric parameter named `lookFactor` that controls
   * the factor of looking, according to the camera current position.
   */
  [KeyboardAction.CAMERA_LOOK_RIGHT]: (map: Map, delta: number, ctrl: boolean) => {
    if (!ctrl) {
      const camera = map.viewer.camera
      const currentPosition = camera.positionCartographic
      const lookFactor = CAMERA_LOOK_DEFAULT_FACTOR * delta
      camera.lookLeft(currentPosition.latitude * lookFactor)
    } else {
      const camera = map.viewer.camera
      const rotateFactor = CAMERA_ROTATE_DEFAULT_FACTOR * delta
      camera.rotateRight(rotateFactor)
    }
    //camera.twistRight(camera.roll)
  },
  /**
   * Changes the camera to look to the left, accepts a numeric parameter named `lookFactor` that controls
   * the factor of looking, according to the camera current position.
   */
  [KeyboardAction.CAMERA_LOOK_LEFT]: (map: Map, delta: number, ctrl: boolean) => {
    if (!ctrl) {
      const camera = map.viewer.camera
      const currentPosition = camera.positionCartographic
      const lookFactor = CAMERA_LOOK_DEFAULT_FACTOR * delta
      camera.lookRight(currentPosition.latitude * lookFactor)
    } else {
      const camera = map.viewer.camera
      const rotateFactor = CAMERA_ROTATE_DEFAULT_FACTOR * delta
      camera.rotateLeft(rotateFactor)
    }
  },
  /**
   * Changes the camera to look up, accepts a numeric parameter named `lookFactor` that controls
   * the factor of looking, according to the camera current position.
   */
  [KeyboardAction.CAMERA_LOOK_UP]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    const currentPosition = camera.positionCartographic
    const lookFactor = (CAMERA_LOOK_DEFAULT_FACTOR / 2) * delta
    camera.lookUp(currentPosition.longitude * lookFactor)
  },
  /**
   * Changes the camera to look down, accepts a numeric parameter named `lookFactor` that controls
   * the factor of looking, according to the camera current position.
   */
  [KeyboardAction.CAMERA_LOOK_DOWN]: (map: Map, delta: number, ctrl: boolean) => {
    if (ctrl) {
      return
    }
    const camera = map.viewer.camera
    const currentPosition = camera.positionCartographic
    const lookFactor = (CAMERA_LOOK_DEFAULT_FACTOR / 2) * delta
    camera.lookDown(currentPosition.longitude * lookFactor)
  },
  /**
   * Twists the camera to the right, accepts a numeric parameter named `amount` that controls
   * the twist amount
   */
  [KeyboardAction.CAMERA_TWIST_RIGHT]: (map: Map) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_TWIST_DEFAULT_FACTOR
    camera.twistRight(lookFactor)
  },
  /**
   * Twists the camera to the left, accepts a numeric parameter named `amount` that controls
   * the twist amount
   */
  [KeyboardAction.CAMERA_TWIST_LEFT]: (map: Map) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_TWIST_DEFAULT_FACTOR
    camera.twistLeft(lookFactor)
  },
  /**
   * Rotates the camera to the right, accepts a numeric parameter named `angle` that controls
   * the rotation angle
   */
  [KeyboardAction.CAMERA_ROTATE_RIGHT]: (map: Map, delta: number) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_ROTATE_DEFAULT_FACTOR * delta
    camera.rotateRight(lookFactor)
  },
  /**
   * Rotates the camera to the left, accepts a numeric parameter named `angle` that controls
   * the rotation angle
   */
  [KeyboardAction.CAMERA_ROTATE_LEFT]: (map: Map, delta: number) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_ROTATE_DEFAULT_FACTOR * delta
    camera.rotateLeft(lookFactor)
  },
  /**
   * Rotates the camera upwards, accepts a numeric parameter named `angle` that controls
   * the rotation angle
   */
  [KeyboardAction.CAMERA_ROTATE_UP]: (map: Map) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_ROTATE_DEFAULT_FACTOR
    camera.rotateUp(lookFactor)
  },
  /**
   * Rotates the camera downwards, accepts a numeric parameter named `angle` that controls
   * the rotation angle
   */
  [KeyboardAction.CAMERA_ROTATE_DOWN]: (map: Map) => {
    const camera = map.viewer.camera
    const lookFactor = CAMERA_ROTATE_DEFAULT_FACTOR
    camera.rotateDown(lookFactor)
  },
}

const keyToKeyboardActionMap: { [key: number]: { [key: string]: KeyboardAction } } = {
  [Cesium.SceneMode.SCENE2D]: {
    e: KeyboardAction.CAMERA_FORWARD,
    E: KeyboardAction.CAMERA_FORWARD,
    q: KeyboardAction.CAMERA_BACKWARD,
    Q: KeyboardAction.CAMERA_BACKWARD,
    d: KeyboardAction.CAMERA_RIGHT,
    D: KeyboardAction.CAMERA_RIGHT,
    a: KeyboardAction.CAMERA_LEFT,
    A: KeyboardAction.CAMERA_LEFT,
    w: KeyboardAction.CAMERA_UP,
    W: KeyboardAction.CAMERA_UP,
    s: KeyboardAction.CAMERA_DOWN,
    S: KeyboardAction.CAMERA_DOWN,
    ArrowUp: KeyboardAction.CAMERA_LOOK_UP,
    ArrowDown: KeyboardAction.CAMERA_LOOK_DOWN,
    ArrowRight: KeyboardAction.CAMERA_LOOK_LEFT,
    ArrowLeft: KeyboardAction.CAMERA_LOOK_RIGHT,
  },
  [Cesium.SceneMode.SCENE3D]: {
    w: KeyboardAction.CAMERA_FORWARD,
    W: KeyboardAction.CAMERA_FORWARD,
    s: KeyboardAction.CAMERA_BACKWARD,
    S: KeyboardAction.CAMERA_BACKWARD,
    d: KeyboardAction.CAMERA_RIGHT,
    D: KeyboardAction.CAMERA_RIGHT,
    a: KeyboardAction.CAMERA_LEFT,
    A: KeyboardAction.CAMERA_LEFT,
    e: KeyboardAction.CAMERA_UP,
    E: KeyboardAction.CAMERA_UP,
    q: KeyboardAction.CAMERA_DOWN,
    Q: KeyboardAction.CAMERA_DOWN,
    ArrowUp: KeyboardAction.CAMERA_LOOK_UP,
    ArrowDown: KeyboardAction.CAMERA_LOOK_DOWN,
    ArrowRight: KeyboardAction.CAMERA_LOOK_RIGHT,
    ArrowLeft: KeyboardAction.CAMERA_LOOK_LEFT,
  },
}

const keyStateMap: {
  [key: number]: {
    active: boolean
    ctrl: boolean
  }
} = {}
const focusMap: { focused: boolean } = { focused: false }

interface MapContainerProps {
  disableWidthSetting?: boolean
}

export const MapContainer = (props: MapContainerProps) => {
  const { map, drawer } = useAppState()
  const user = useUser()
  const cesiumContainer = useRef(null)
  const requestRef = useRef<number>()
  const setTransformRef = useRef<boolean>()

  const [sideBarsVisible, setSideBarsVisible] = useState(true)

  useEffect(() => {
    if (map.initialized) {
      return
    }
    map.initialize(Config.DefaultMapRectangle.clone())
  }, [cesiumContainer, map.initialized])

  const toggleSideBarVariable = (isVisible: boolean) => {
    setSideBarsVisible(isVisible)
  }

  const isBulkTagging = map.positionForBulkImageTagging.current
  const isBulkMoving = map.positionForBulkImageMoving.current

  function onFocus() {
    focusMap.focused = true
    requestRef.current = requestAnimationFrame(animate)
  }

  function onBlur() {
    focusMap.focused = false
  }

  function onKeyDown(e: KeyboardEvent) {
    if(!focusMap.focused) {
      return
    }
    e.preventDefault()

    const t = keyToKeyboardActionMap[map.viewer.scene.mode]
    if (t) {
      const u = t[e.key]
      if (u) {
        if (u === KeyboardAction.CAMERA_LOOK_LEFT || u === KeyboardAction.CAMERA_LOOK_RIGHT) {
          if (e.shiftKey && !setTransformRef.current) {
            const windowPosition = new Cesium.Cartesian2(
              map.viewer.container.clientWidth / 2,
              map.viewer.container.clientHeight / 2
            )
            const pickRay = map.viewer.scene.camera.getPickRay(windowPosition)
            let pickPosition = map.viewer.scene.pickFromRay(pickRay)
            if (!pickPosition) {
              const newPick = map.viewer.scene.globe.pick(pickRay, map.viewer.scene)
              if (newPick) {
                pickPosition = {
                  position: newPick,
                }
              }
            }

            if (pickPosition && pickPosition.position) {
              const range = Cesium.Cartesian3.distance(pickPosition.position, map.viewer.camera.position)
              const hpr = new Cesium.HeadingPitchRange(map.viewer.camera.heading, map.viewer.camera.pitch, range)
              // console.log('picked', pickPosition.position, map.viewer.camera.position, range)
              const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pickPosition.position)
              map.viewer.scene.camera.lookAtTransform(transform, hpr)
              setTransformRef.current = true
            }
          }
        } else if (setTransformRef.current) {
          return
        }

        keyStateMap[u] = {
          active: true,
          ctrl: setTransformRef.current,
        }
      }
    }
  }

  function onKeyUp(e: KeyboardEvent) {
    const t = keyToKeyboardActionMap[map.viewer.scene.mode]
    if (t) {
      const u = t[e.key]
      if (u) {
        if (setTransformRef.current) {
          if (u === KeyboardAction.CAMERA_LOOK_LEFT || u === KeyboardAction.CAMERA_LOOK_RIGHT) {
            setTransformRef.current = false
            map.viewer.scene.camera.lookAtTransform(Cesium.Matrix4.IDENTITY)
          }
        }
        keyStateMap[u] = {
          active: false,
          ctrl: false,
        }
      }
    }
  }

  const animate = (time: number) => {
    if (!focusMap.focused) {
      return
    }

    for (const k in keyStateMap) {
      if (keyStateMap[k] && keyStateMap[k].active) {
        PREDEFINED_KEYBOARD_ACTIONS[k](map, time / 1000 / 1000, setTransformRef.current)
      }
    }

    // The 'state' will always be the initial value here
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    if (!map.initialized) {
      return
    }

    map.viewer.scene.canvas.setAttribute('tabIndex', '1')
    map.viewer.scene.canvas.addEventListener('focus', onFocus)
    map.viewer.scene.canvas.addEventListener('blur', onBlur)
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    map.viewer.screenSpaceEventHandler.setInputAction(function () {
      map.viewer.scene.canvas.focus()
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

    return () => {
      map.viewer.canvas.removeEventListener('keydown', onKeyDown)
      map.viewer.canvas.removeEventListener('keyup', onKeyUp)
      cancelAnimationFrame(requestRef.current)
    }
  }, [map.initialized])

  // useEffect(() => {
  //   if (map.projectionType !== ProjectionType.Projection3D) {
  //     return
  //   }

  //   const firstPersonCameraController = new FirstPersonCameraController({ cesiumViewer: map.viewer })

  //   firstPersonCameraController.start()

  //   return () => {
  //     firstPersonCameraController.stop()
  //   }
  // }, [map.projectionType])

  return (
    <div
      id='mapBoxContainer'
      className={classes({
        'bulk-tagging': isBulkTagging,
        'bulk-moving': isBulkMoving,
        'bulk-fullscreen': map.bulkTaggingFullscreen.current,
      })}
      // onFocus={onFocus}
      // onBlur={onBlur}
    >
      {map.initialized && map.showControls && <MapControls sideBarsVisibleToggle={toggleSideBarVariable} />}
      {map.showMeasure && <Measure />}
      {map.initialized && (map.showControls || isBulkTagging || isBulkMoving) && (
        <CesiumCompass
          scene={map.viewer.scene}
          clock={map.viewer.clock}
          projectionType={map.projectionType}
          offset={!drawer.rightClosed}
          forBasic={user.org.isBasic}
        />
      )}
      <EntityContext />
      {map.initialized && (
        <div className='image-controls'>
          <div
            title='Offset images 5m up'
            onClick={() => map.setImageOffsetForBulkTagging(map.imageOffsetForBulkTagging.current + 5)}
          >
            <i className='material-icons'>add</i>
          </div>
          <div
            title='Offset images 5m down'
            onClick={() => map.setImageOffsetForBulkTagging(map.imageOffsetForBulkTagging.current - 5)}
          >
            <i className='material-icons'>remove</i>
          </div>
          <span>{map.imageOffsetForBulkTagging.current}m</span>
        </div>
      )}
      <div
        id='cesiumContainer'
        style={
          props.disableWidthSetting
            ? {}
            : isBulkTagging || isBulkMoving
            ? {}
            : { width: sideBarsVisible ? screen.width : screen.width }
        }
        className={classes({
        })}
        ref={cesiumContainer}
      />
      <ImageTagger />
      {!isBulkTagging && !isBulkMoving && <TimelineContainer />}
      {map.crashInfo && <CesiumCrashedDialog err={map.crashInfo.err} extra={map.crashInfo.extra} />}
    </div>
  )
}
