import React from 'react'
import ASSETI_ICON from '../../images/asseti_icon_red.png'

interface AvatarProps {
  width?: number
  height?: number
  imgPath?: string
  alt?: string
  className: string
  title: string
  isAsseti?: boolean
}

export const Avatar = (props: AvatarProps) => (
  <div
    className={props.className}
    style={{ width: props.width ? props.width : 40, height: props.height ? props.height : 40 }}
  >
    {props.isAsseti ? (
      <img
        className='avatar-img'
        src={ASSETI_ICON}
        alt={props.alt}
        title={props.title}
      />
    ) : (
      <i className='material-icons'>person</i>
    )}
  </div>
)
