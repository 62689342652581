import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { ConfirmEmailMutation, ConfirmEmailRequestT, MutationConfirmEmailArgs } from '~/models/gql-types'
import React, { useEffect, useState } from 'react'
import { AuthInput, object, ref, string, useForm, useMutation } from '~/components'
import { Helmet, AuthButton, useLocation, getTracking } from '~/components'
import { AuthError } from './auth-error'
import { useUser } from '~/base'
import mutation from './mutation-confirm-email.gql'

enum ConfirmationState {
  Loading,
  OrgPendingApproval,
  SupplierPendingApproval,
  RequiresPassword,
  Error,
}

export const AuthEmailConfirmationSuccess = () => {
  const location = useLocation()
  const user = useUser()
  const token = new URLSearchParams(window.location.search).get('token')
  const [state, setState] = useState<ConfirmationState>(ConfirmationState.Loading)

  if (!token) {
    location.setLocation('/404')
  }

  const [execute] = useMutation<ConfirmEmailMutation, MutationConfirmEmailArgs>(mutation)

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    ConfirmEmailMutation,
    ConfirmEmailRequestT & { confirmPassword: string },
    ConfirmEmailRequestT
  >({
    initialValues: {
      token,
      password: '',
      confirmPassword: '',
    },
    validationSchema: object().shape({
      password: string()
        .required('Required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\`])(?=.{8,})/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'
        ),
      confirmPassword: string()
        .required('Required')
        .equals([ref('password'), null], 'Passwords must match'),
    }),
    mutation,
    mapInput: (input: ConfirmEmailRequestT & { confirmPassword: string }): ConfirmEmailRequestT => {
      delete input['confirmPassword']
      return input
    },
    onSuccess: (result: ConfirmEmailMutation) => {
      if (result.confirmEmail.orgPendingApproval) {
        setState(ConfirmationState.OrgPendingApproval)
        return
      } else if (result.confirmEmail.supplierPendingApproval) {
        setState(ConfirmationState.SupplierPendingApproval)
        return
      }
      user.setSignIn(result.confirmEmail)
      getTracking().event({
        category: 'Button',
        action: `User confirmed their email`,
        label: 'Auth',
      })
      location.setLocation('/')
    },
  })

  useEffect(() => {
    execute({
      variables: {
        input: {
          token,
          password: '',
        },
      },
    })
      .then((result) => {
        if (result.data.confirmEmail.orgPendingApproval) {
          setState(ConfirmationState.OrgPendingApproval)
          return
        }
        user.setSignIn(result.data.confirmEmail)
        getTracking().event({
          category: 'Button',
          action: `User confirmed their email`,
          label: 'Auth',
        })
        location.setLocation('/')
      })
      .catch((e) => {
        if (e.message === 'a password is required for the user') {
          setState(ConfirmationState.RequiresPassword)
        } else {
          setState(ConfirmationState.Error)
        }
      })
  }, [token])

  if (state === ConfirmationState.OrgPendingApproval) {
    return (
      <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
        <Helmet title='Confirm Email' />
        <>
          <div className='subheader-dark mt-5 mb-5'>Email Confirmed</div>
          <div className='auth-description-text padding'>
            Your email address has been successfully confirmed. Your workspace is currently pending approval. An email
            will be sent to your registered email address as soon as this is complete.
          </div>
        </>
      </AuthLayout>
    )
  }

  if (state === ConfirmationState.SupplierPendingApproval) {
    return (
      <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
        <Helmet title='Confirm Email' />
        <>
          <div className='subheader-dark mt-5 mb-5'>Email Confirmed</div>
          <div className='auth-description-text padding'>
            Your email address has been successfully confirmed. Your workspace is currently pending approval. An email
            will be sent to your registered email address as soon as this is complete.
          </div>
        </>
      </AuthLayout>
    )
  }

  if (state === ConfirmationState.RequiresPassword) {
    return (
      <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN}>
        <Helmet title='Sign Up' />
        <div className='subheader-dark mt-5 mb-5'>Join Asseti</div>
        {error && <AuthError className='alert alert-warning mb-4' error={error} />}
        <form autoComplete='off' name='sign-up-form' className='auth-form-container mb-3' onSubmit={handleSubmit}>
          <p className='auth-description-text mb-4'>Create a password to complete your sign up</p>
          <AuthInput
            id='password'
            label='Password'
            type='password'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.password ? errors.password : ''}
            error={touched.password && Boolean(errors.password)}
          />
          <AuthInput
            id='confirmPassword'
            label='Confirm Password'
            type='password'
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.confirmPassword ? errors.confirmPassword : ''}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          />
          <AuthButton disabled={isSubmitting}>{isSubmitting ? 'Loading...' : 'Complete Sign Up'}</AuthButton>
        </form>
      </AuthLayout>
    )
  }

  if (state === ConfirmationState.Error) {
    return (
      <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
        <Helmet title='Confirm Email' />
        <>
          <div className='subheader-dark mt-5 mb-5'>Email Confirmation</div>
          <div className='auth-description-text padding'>
            An unknown error has occurred. Please try again or contact support
          </div>
        </>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_UP}>
      <Helmet title='Confirm Email' />
      <>
        <div className='subheader-dark mt-5 mb-5'>Email Confirmation</div>
        <div className='auth-description-text padding'>Loading</div>
      </>
    </AuthLayout>
  )
}
