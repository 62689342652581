import { useState } from 'react'
import { SiteQueryComponent } from '~/models'

export function useComponentState() {
  const drawBoundaries = localStorage.getItem('asseti-components-draw-boundaries')
  const fillBoundaries = localStorage.getItem('asseti-components-fill-boundaries')
  const colorByConditionState = localStorage.getItem('asseti-components-color-by-condition')

  const [componentToUpdate, setComponentToUpdate] = useState<SiteQueryComponent>()
  const [componentToDelete, setComponentToDelete] = useState<SiteQueryComponent>()
  const [selectedComponent, setSelectedComponent] = useState<SiteQueryComponent>()
  const [componentContext, setComponentContext] = useState<{
    component: SiteQueryComponent
    windowPos: Cesium.Cartesian2
  }>()
  const [draw, setDraw] = useState<boolean>(drawBoundaries === 'true' || drawBoundaries === null)
  const [fill, setFill] = useState<boolean>(fillBoundaries === 'true')
  const [hidden, setHidden] = useState(false)
  const [colorByCondition, setColorByCondition] = useState(colorByConditionState === 'true')
  const [selectedAssetID, setSelectedAssetID] = useState('')

  return {
    componentToUpdate,
    setComponentToUpdate: (component: SiteQueryComponent) => {
      setComponentToUpdate(component)
    },
    draw,
    setDraw: (b: boolean) => {
      localStorage.setItem('asseti-components-draw-boundaries', b + '')
      setDraw(b)
    },
    fill,
    setFill: (b: boolean) => {
      localStorage.setItem('asseti-components-fill-boundaries', b + '')
      setFill(b)
    },
    selectedComponent,
    setSelectedComponent,
    hidden,
    setHidden,
    selectedAssetID,
    setSelectedAssetID,
    componentToDelete,
    setComponentToDelete,
    colorByCondition,
    setColorByCondition: (b: boolean) => {
      localStorage.setItem('asseti-components-color-by-condition', b + '')
      setColorByCondition(b)
    },
    componentContext,
    setComponentContext,
  }
}
