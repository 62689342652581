import { AuthError } from './auth-error'
import { Helmet, getWebsitePath, sanitizeLocation } from '~/components'
import React, { useEffect, useState } from 'react'
import { useUser } from '~/base'
import mutation from './mutation-sign-up-as-supplier.gql'
import { string, object } from '~/components'
import { AuthButton, AuthInput, useForm, useLocation, getTracking } from '~/components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { AuthLink, AuthLinks } from './auth-links'
import { LocationInputT, SignUpAsSupplierMutation, SignUpAsSupplierRequestT } from '../models/gql-types'
import { Config } from '~/config'
import { AuthGeocoder } from './auth-geocoder'

export const AuthSignUpSupplier = () => {
  const location = useLocation()
  const user = useUser()
  const requireAddress = new URLSearchParams(window.location.search).get('address') !== 'false'
  const [geocoderResult, setGeocoderResult] = useState<LocationInputT | undefined>()

  useEffect(() => {
    // Add event listener.
    window.hsConversationsOnReady = []

    // Don't show widget immediately.
    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    // Create script component.
    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${Config.HubspotPortalId}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      window.hsConversationsOnReady = []
    }
  }, [])

  if (user.isLoggedIn) {
    location.setLocation('/')
    return null
  }

  let addressValidation = object().shape({})
  if (requireAddress) {
    addressValidation = addressValidation.required('Required')
  }

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<SignUpAsSupplierMutation, SignUpAsSupplierRequestT, SignUpAsSupplierRequestT>({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        supplierName: '',
        fromURL: window.location.href,
      },
      validationSchema: object().shape({
        firstName: string().max(15, 'Must be 15 characters or less').required('Required'),
        lastName: string().max(20, 'Must be 20 characters or less').required('Required'),
        email: string().email('Invalid email address').required('Required'),
        supplierName: string().max(64, 'Must be 64 characters or less').required('Required'),
        address: addressValidation,
      }),
      mutation,
      mapInput: (input: SignUpAsSupplierRequestT & { confirmPassword: string }): SignUpAsSupplierRequestT => {
        delete input['confirmPassword']
        input.fromURL = window.location.href
        input.address = geocoderResult
        return input
      },
      onSuccess: (result: SignUpAsSupplierMutation) => {
        if (result.signUpAsSupplier.success) {
          getTracking().event({
            category: 'Form',
            action: `Supplier signed up`,
            label: 'Auth',
          })

          const _hsq = (window._hsq = window._hsq || [])
          _hsq.push([
            'identify',
            {
              id: result.signUpAsSupplier.userID,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          ])
          _hsq.push(['trackPageView'])

          setTimeout(() => {
            if (result.signUpAsSupplier.userExists) {
              location.setLocation('/signin?sid=' + result.signUpAsSupplier.supplierID + '&sc=1')
            } else {
              location.setLocation('/email-confirmation')
            }
          }, 250)
        }
      },
    })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN}>
      <Helmet title='Sign Up' />
      <div className='subheader-dark mt-5 mb-5'>Join Asseti</div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
      <form autoComplete='off' name='sign-up-form' className='auth-form-container mb-3' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col'>
            <AuthInput
              id='firstName'
              label='First Name'
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.firstName ? errors.firstName : ''}
              error={touched.firstName && Boolean(errors.firstName)}
              halfWidth
            />
          </div>
          <div className='col'>
            <AuthInput
              id='lastName'
              label='Last Name'
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.lastName ? errors.lastName : ''}
              error={touched.lastName && Boolean(errors.lastName)}
              halfWidth
            />
          </div>
        </div>
        <AuthInput
          id='email'
          label='Email'
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <AuthInput
          id='supplierName'
          label='Business Name'
          value={values.supplierName}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.supplierName ? errors.supplierName : ''}
          error={touched.supplierName && Boolean(errors.supplierName)}
        />
        {requireAddress && (
          <AuthGeocoder
            value={geocoderResult}
            setSelected={(s) => {
              setGeocoderResult(sanitizeLocation(s))
              setFieldValue('address', sanitizeLocation(s))
            }}
            helperText={touched.supplierName ? errors.supplierName : ''}
            error={touched.supplierName && Boolean(errors.supplierName)}
          />
        )}
        <AuthButton disabled={isSubmitting}>{isSubmitting ? 'Loading...' : 'Sign Up'}</AuthButton>
        <p className='auth-links body2 mb-4'>
          By clicking the "Sign Up" button, you are creating a Asseti account, and you agree to Asseti's
          <br />
          &nbsp;
          <a href={`${getWebsitePath()}/terms`} target='blank' rel='noopener noreferrer' className='auth-layout-link'>
            Terms of Use
          </a>
          &nbsp;and&nbsp;
          <a
            href={`${getWebsitePath()}/privacy-policy`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Privacy Policy
          </a>
          .
        </p>
      </form>
      <AuthLinks>
        <AuthLink to='/signin' className='mb-3'>
          I have an account
        </AuthLink>
      </AuthLinks>
    </AuthLayout>
  )
}
