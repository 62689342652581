import React from 'react'
import { string, object, getWebsitePath } from '~/components'
import { AcceptInviteMutation, UserInvitationsAcceptRequestT } from '~/models'
import { AuthButton, AuthInput, useForm } from '../components'
import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import { useLocation, getTracking } from '~/components'
import { AuthError } from './auth-error'
import { Helmet } from '~/components'
import { useUser } from '~/base'
import ACCEPT_INVITE_MUTATION from './mutation-accept-invite.gql'

interface AuthAcceptInviteExistsProps {
  token: string
}

export const AuthAcceptInviteExists = (props: AuthAcceptInviteExistsProps) => {
  const location = useLocation()
  const user = useUser()
  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    AcceptInviteMutation,
    UserInvitationsAcceptRequestT & { confirmPassword: string }
  >({
    initialValues: {
      token: props.token,
      jobTitle: '',
      confirmPassword: '',
    },
    validationSchema: object({
      jobTitle: string().max(15, 'Must be 15 characters or less').required('Required'),
    }),
    mutation: ACCEPT_INVITE_MUTATION,
    mapInput: (input) => {
      const toRet = { ...input }
      delete toRet['confirmPassword']
      return toRet
    },
    onSuccess: (result: AcceptInviteMutation) => {
      user.setSignIn(result.acceptInvite)
      getTracking().event({
        category: 'Form',
        action: `User accepted invite`,
        label: 'Auth',
      })
      location.setLocation('/')
    },
  })

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Accept Team Invite' />
      <div className='subheader-dark mt-5 mb-5'>Team Invitation Confirmation</div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
      <form autoComplete='off' className='auth-form-container mb-3' onSubmit={handleSubmit}>
        <label htmlFor='jobTitle'>Job Title</label>
        <AuthInput
          id='jobTitle'
          label='Job Title'
          value={values.jobTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.jobTitle ? errors.jobTitle : ''}
          error={touched.jobTitle && Boolean(errors.jobTitle)}
        />
        <AuthButton disabled={isSubmitting}>Accept Invitation</AuthButton>
        <p className='auth-links body2 mb-4'>
          By clicking the "Accept Invitation" button, you are creating a Asseti account, and you agree to Asseti's
          <br />
          &nbsp;
          <a
            style={{ overflowWrap: 'anywhere' }}
            href={`${getWebsitePath()}/terms`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Terms of Use
          </a>
          &nbsp;and&nbsp;
          <a
            href={`${getWebsitePath()}/privacy-policy`}
            target='blank'
            rel='noopener noreferrer'
            className='auth-layout-link'
          >
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </AuthLayout>
  )
}
