import React, { MutableRefObject, useEffect, useState } from 'react'
import { SiteQueryAsset, Photo, SiteQuerySite, SitesQuerySite } from '~/models'
import { BoundaryRenderType, MonitoringZoneRenderType, useAppState } from '~/state'
import { getImageURL } from '../helpers'
import { DrawerListContent } from '../drawer'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { getTracking } from '../tracking'

interface PhotosProps {
  site?: SiteQuerySite | SitesQuerySite
  asset?: SiteQueryAsset
  siteId?: string
  assetId?: string
  type: 'site' | 'asset'
  photos: MutableRefObject<Photo[]>
  loaded?: boolean
}

export const Photos = (props: PhotosProps) => {
  const { timeline, map, boundaryState, monitoringZoneState, drawer } = useAppState()
  const [boundingBox, setBoundingBox] = useState<Cesium.Rectangle | undefined>(map.viewer.camera.computeViewRectangle())

  useEffect(() => {
    function moveListener() {
      setBoundingBox(map.viewer.camera.computeViewRectangle())
    }

    map.viewer.camera.moveEnd.addEventListener(moveListener)
    return () => {
      map.viewer.camera.moveEnd.removeEventListener(moveListener)
    }
  }, [])

  const isVisible = drawer.expandedPanel === ExpandedPanel.Photos
  const activeImages = isVisible
    ? (props.photos.current || []).filter((p) => {
        if (!boundingBox) {
          return true
        }
        return Cesium.Rectangle.contains(boundingBox, Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
      })
    : []

  let total = 0
  if (isVisible) {
    total = activeImages.length
    if (total === 0) {
      total = (timeline.activeImages || []).reduce((a, b) => a + b.numImages, 0)
    }
  } else {
    total = (timeline.activeImages || []).reduce((a, b) => a + b.numImages, 0)
  }

  return (
    <DrawerListContent
      loading={!!timeline.activeImages}
      numEntities={total}
      panelType={ExpandedPanel.Photos}
      plural='photos'
      singular='photo'
      title='Photos'
      onShow={() => {
        boundaryState.setRenderType(BoundaryRenderType.Lines)
        monitoringZoneState.setRenderType(MonitoringZoneRenderType.Lines)
        getTracking().event({
          category: 'Button',
          action: `User clicked view more photos`,
          label: 'Photos',
        })
      }}
      onHide={() => {
        boundaryState.setRenderType(BoundaryRenderType.LinesAndLabels)
        monitoringZoneState.setRenderType(MonitoringZoneRenderType.LinesAndLabels)
        getTracking().event({
          category: 'Button',
          action: `User clicked hide photos`,
          label: 'Photos',
        })
      }}
      emptyButCanAddText=''
      emptyButCantAddComponent={<span>Upload photos or select a period<br />on the timeline with photos</span>}
    >
      <div className='photos-grid'>
        {total > 0 &&
          (timeline.activeImages || []).length > 0 &&
          timeline.activeImages[0].hashes
            .slice(0, 4)
            .map((d, i) => <img key={d} src={getImageURL({ hash: d }, props.site?.orgID, i)} />)}
      </div>
      {timeline.activeImages && total === 0 && (
        <div style={{ height: 'auto', textDecoration: 'none' }} className='no-item-tag'>
          Upload photos or select a period on the timeline with photos.
        </div>
      )}
    </DrawerListContent>
  )
}
